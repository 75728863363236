import { getSafeLocale } from '@vacationtracker/shared/i18n'
import dayjs from 'dayjs'
import getDateInUserTimezone from '@vacationtracker/shared/functions/get-date-in-user-timezone'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { LocaleEnum } from '../../types/i18n'
import { IPartDay } from '../../types/leave-request'
import { calculatePartDayHours, computeDaysAndHours, formatTimeString } from '../../functions/get-days-or-hours'
import { DEFAULT_WORKING_HOURS_IN_DAY } from '../../data/app-parameters'

interface IValues {
  locale: LocaleEnum
  startDate: string
  isPartDay: boolean
  workingHoursInDay?: number | undefined
  endDate: string
  partDay?: IPartDay
  partDayStartHour?: number
  partDayEndHour?: number
  hourFormat: HourFormatEnum
}

export const formatToilPeriod = (values: IValues, formatMessage: any): string => {
  const safeLocale = getSafeLocale(values.locale)
  const startDate = dayjs(getDateInUserTimezone(values.startDate)).locale(safeLocale).format('MMMM Do YYYY')
  const endDate = dayjs(getDateInUserTimezone(values.endDate)).locale(safeLocale).format('MMMM Do YYYY')
  let convertedStartHour
  let convertedEndHour
  if (values.isPartDay) {
    const { value: startHour, minute: startMinute, amOrPm: startAmOrPm } = convertHourFormats(
      values.hourFormat,
      values.partDay ? values.partDay.startHour : values.partDayStartHour as number,
      values.partDay ? values.partDay.startMinute as number : 0
    )
    const { value: endHour, minute: endMinute, amOrPm: endAmOrPm } = convertHourFormats(
      values.hourFormat,
      values.partDay ? values.partDay.endHour : values.partDayEndHour as number,
      values.partDay ? values.partDay.endMinute as number : 0
    )
    convertedStartHour = formatTimeString({hour: startHour, minute: startMinute, showZeroPadding: false, hourFormat: values.hourFormat, amOrPm: startAmOrPm})
    convertedEndHour = formatTimeString({hour: endHour, minute: endMinute, showZeroPadding: false, hourFormat: values.hourFormat, amOrPm: endAmOrPm})
  }

  return values.isPartDay ?
    formatMessage({id: 'components.toil.partDayPeriod'}, {
      date: startDate,
      partDayStartHour: `${convertedStartHour}`,
      partDayEndHour: `${convertedEndHour}`,
    }) :
    formatMessage({id: 'components.toil.fullDayLeavePeriod'}, {
      date: startDate,
      endDate: values.endDate !== values.startDate ? ` - ${endDate}` : '',
    })
}

export const calculateToilPeriod = (values: IValues, formatMessage: any): string => {
  if (values.partDay) {
    const numberOfWorkingHoursPerDay = values.workingHoursInDay || DEFAULT_WORKING_HOURS_IN_DAY

    const calculatedPartDayHours = calculatePartDayHours(values.partDay)
    const calculatedPartDayTime = computeDaysAndHours(calculatedPartDayHours, numberOfWorkingHoursPerDay)
    const partDayTime = calculatedPartDayTime.minutes ? `${calculatedPartDayTime.hours}.${calculatedPartDayTime.minutes}` : `${calculatedPartDayTime.hours}`
    return `${partDayTime || 0} ${formatMessage({id: 'dashboard.hour'})}`
  }
  if (typeof values.partDayStartHour === 'number' && typeof values.partDayEndHour === 'number') {
    return `${(Number(values?.partDayEndHour) - Number(values.partDayStartHour)) || 0} ${formatMessage({id: 'dashboard.hour'})}`
  }
  const dateDiff = (dayjs(values.endDate).diff(dayjs(values.startDate), 'days') + 1) || 0
  return `${dateDiff} ${formatMessage({id: 'dashboard.days'})}`
}
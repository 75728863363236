import React, { useState, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { App, Button, Card, Select, Table } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { isNumber, capitalize } from 'lodash'

import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'

import {
  columnsIndex,
  columnsStartEndDate,
  columnsLeaveTypeName,
  columnsDuration,
  columnsReason,
  columnsDenyReason,
  columnsStatus,
  columnsApprover
} from '../leaves-columns'
import { shouldResendLeaveRequest } from '@vacationtracker/shared/functions/resend-leave-request-condition'

import DenyWithReasonForm from '../deny-with-reason-form'
import IntlMessages from '../../util/IntlMessages'

import { IUserPendingLeaves, IUserToday, IUserUpcomingLeaves, IUserHistory } from '../../types/custom-queries'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { isToilLeave } from '@vacationtracker/shared/functions/is-toil-leave-request'
import { IResendLeaveRequest } from '@vacationtracker/shared/types/leave-request'

const { Option } = Select

export interface IUserLeavesTab {
  amIAdmin: boolean
  amIApprover: boolean
  pendingRequests: IUserPendingLeaves[]
  currentLeaves: IUserToday[]
  scheduledLeaves: IUserUpcomingLeaves[]
  leaveHistory: IUserHistory[]
  user: {
    id: string
    name: string
    locale?: LocaleEnum
    hourFormat?: HourFormatEnum
  }
  onCancelLeave: (leaveRequestId: string) => Promise<void>
  onDeleteLeave: (leaveRequestId: string) => Promise<void>
  onLeaveRequestUpdate: (leaveRequestId: string, leaveStatus: boolean, statusReason?: string) => Promise<void>
  onResendLeaveRequest?: (leaveRequest: IResendLeaveRequest) => Promise<void>
  isResendingLeaveRequest?: boolean
}

const UserLeavesTab = ({
  pendingRequests,
  currentLeaves,
  scheduledLeaves,
  leaveHistory,
  user,
  amIAdmin,
  amIApprover,
  onCancelLeave,
  onDeleteLeave,
  onLeaveRequestUpdate,
  onResendLeaveRequest,
  isResendingLeaveRequest,
}: IUserLeavesTab): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { modal } = App.useApp()

  const [isDenyWithReasonModalVisible, showDenyWithReasonModal] = useState(false)
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState<IUserPendingLeaves | null>(null)

  const [leaveKindFilter, setLeaveKindFilter] = useState('all')
  const [leaveTypeFilter, setLeaveTypeFilter] = useState('all')
  const [leaveStatusFilter, setLeaveStatusFilter] = useState('all')

  const onLeaveKindFilterChange = (value: string) => {
    setLeaveKindFilter(value)
  }

  const onLeaveTypeFilterChange = (value: string) => {
    setLeaveTypeFilter(value)
  }

  const onLeaveStatusFilterChange = (value: string) => {
    setLeaveStatusFilter(value)
  }

  const filteredHistory = leaveHistory.filter((item) => {
    const leaveKindCondition = leaveKindFilter === 'all' || item.id.startsWith(leaveKindFilter)
    const leaveTypeCondition = leaveTypeFilter === 'all' || item.leaveType.name.startsWith(leaveTypeFilter)
    const leaveStatusCondition = leaveStatusFilter === 'all' || item.status === leaveStatusFilter
    return leaveKindCondition && leaveStatusCondition && leaveTypeCondition
  })

  const pendingRequestsColumns = [
    columnsIndex,
    columnsStartEndDate(authUser?.locale as LocaleEnum, user.hourFormat as HourFormatEnum),
    columnsLeaveTypeName,
    columnsDuration(formatMessage),
    columnsReason,
    {
      title: <IntlMessages id="app.actions" />,
      dataIndex: 'id',
      className: 'actions',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id, row: IUserPendingLeaves) => {
        return (
          <>
            <Button style={{ marginLeft: 5 }} type="link" size="small" >
              <Link key={`${id}-edit`} to={isToilLeave(id as string) ? `/app/leaves/toil/${id}/edit` : `/app/leaves/${id}/edit`}>
                <IntlMessages id="app.edit" />
              </Link>
            </Button>
            <Button style={{ marginLeft: 5 }} onClick={() => handleDeny(row)} type="primary" size="small" danger>
              <IntlMessages id="app.deny" />
            </Button>
            <Button style={{ marginLeft: 5 }} onClick={() => handleDenyWithReasonModalOpen(row)} type="primary" size="small" danger>
              <IntlMessages id="app.denyWithReason" />
            </Button>
            <Button style={{ marginLeft: 5 }} onClick={() => handleApprove(row)} type="primary" size="small">
              <IntlMessages id="app.approve" />
            </Button>
          </>
        )
      },
    },
  ]

  const pendingRequestsUserColumns = [
    columnsIndex,
    columnsStartEndDate(authUser?.locale as LocaleEnum, user.hourFormat as HourFormatEnum),
    columnsLeaveTypeName,
    columnsDuration(formatMessage),
    columnsReason,
    {
      title: <IntlMessages id="app.actions" />,
      dataIndex: 'id',
      className: 'actions',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id: string, row: IUserPendingLeaves) => {
        return (
          <>
            <Button style={{ marginLeft: 5 }} type="link" size="small" >
              <Link key={`${id}-edit`} to={isToilLeave(id) ? `/app/leaves/toil/${id}/edit` : `/app/leaves/${id}/edit`}>
                <IntlMessages id="app.edit" />
              </Link>
            </Button>
            <Button style={{ marginLeft: 5 }} onClick={() => handleCancelLeave(row)} type="primary" size="small" danger>
              <IntlMessages id="app.cancel" />
            </Button>
          </>
        )
      },
    },
  ]

  const scheduledLeavesColumns = [
    columnsIndex,
    columnsStartEndDate(authUser?.locale as LocaleEnum, user.hourFormat as HourFormatEnum),
    columnsLeaveTypeName,
    columnsDuration(formatMessage),
    columnsReason,
    columnsDenyReason,
    columnsApprover,
    {
      title: <IntlMessages id="app.actions" />,
      dataIndex: 'id',
      className: 'actions',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id, row: IUserUpcomingLeaves | IUserToday) => {
        return (
          <>
            <Button size="small" style={{ margin: 3 }}>
              <Link key={`${id}-edit`} to={`/app/leaves/${id}/edit`}>
                <IntlMessages id="app.edit" />
              </Link>
            </Button>
            <Button danger size="small" style={{ margin: 3 }} onClick={() => handleCancelLeave(row)}>
              <IntlMessages id="app.cancel" />
            </Button>
          </>
        )
      },
    },
  ]

  const getPartDay = (leaveRequest: IUserHistory) => {
    if (leaveRequest.partDay) {
      return leaveRequest.partDay
    } else {
      return {
        partDayStartHour: leaveRequest.partDayStartHour || undefined,
        partDayEndHour: leaveRequest.partDayEndHour || undefined,
      }
    }
  }
  const handleResendLeaveRequest = (leaveRequest: IUserHistory) => {
    onResendLeaveRequest && onResendLeaveRequest({
      resentLeaveRequestId: leaveRequest.id,
      userId: leaveRequest.user.id,
      isPartDay:  leaveRequest.isPartDay,
      leaveTypeId: leaveRequest.leaveType.id,
      ...getPartDay(leaveRequest),
      startDate: leaveRequest.startDate,
      endDate: leaveRequest.endDate,
      reason: leaveRequest.reason && leaveRequest.reason !== '' ? leaveRequest.reason : leaveRequest.leavePolicy?.isReasonRequired ? 'Re-send' : undefined,
    })
  }

  const leaveHistoryColumns = [
    columnsIndex,
    columnsStartEndDate(authUser?.locale as LocaleEnum, user.hourFormat as HourFormatEnum),
    columnsLeaveTypeName,
    columnsDuration(formatMessage),
    columnsReason,
    columnsDenyReason,
    columnsStatus(formatMessage),
    columnsApprover,
    {
      title: <IntlMessages id="app.actions" />,
      dataIndex: 'id',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id: string, row: IUserHistory): JSX.Element => {
        return (
          <Fragment>
            { !isToilLeave(id) && (amIAdmin || (row.status === 'APPROVED' && row.leavePolicy.allowLeaveInPast)) && row.status !== 'CANCELLED' &&
              <Button size="small" style={{ margin: 3 }}>
                <Link key={`${id}-edit`} to={`/app/leaves/${id}/edit`}>
                  <IntlMessages id="app.edit" />
                </Link>
              </Button>
            }
            {(amIAdmin || amIApprover) &&
              <Button danger size="small" style={{ margin: 3 }} onClick={() => handleDeleteLeave(row)}>
                <IntlMessages id="app.delete" />
              </Button>
            }
            {!row.isResent && row?.user?.id === user.id && authUser?.id === row?.user?.id && shouldResendLeaveRequest(row, row.leavePolicy) &&
              <Button
                size="small"
                style={{ margin: 3 }}
                disabled={isResendingLeaveRequest}
                onClick={() => handleResendLeaveRequest(row)}>
                <IntlMessages id="user.resend" />
              </Button>
            }
          </Fragment>
        )
      },
    },
  ]

  const handleDeny = (leaveRequest: IUserPendingLeaves) => {
    modal.confirm({
      title: formatMessage({ id: 'app.denyLeaveRequest' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'app.denyLeaveRequestText' }),
      okText: formatMessage({ id: 'app.yes' }),
      okType: 'danger',
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        onLeaveRequestUpdate(leaveRequest.id, false)
      },
    })
  }

  const handleDenyWithReasonModalOpen = (leaveRequest: IUserPendingLeaves) => {
    setSelectedLeaveRequest(leaveRequest)
    showDenyWithReasonModal(true)
  }

  const handleDenyWithReason = async (data) => {
    await onLeaveRequestUpdate(data.leaveRequest.id as string, false, data.statusReason as string | undefined)
    showDenyWithReasonModal(false)
  }

  const handleApprove = (leaveRequest: IUserPendingLeaves) => {
    modal.confirm({
      title: formatMessage({ id: 'app.approveLeaveTitle' }),
      icon: <CheckCircleOutlined />,
      content: formatMessage({ id: 'app.approveLeaveText' }),
      okText: formatMessage({ id: 'app.yes' }),
      okType: 'primary',
      cancelText: formatMessage({ id: 'app.no' }),
      async onOk() {
        await onLeaveRequestUpdate(leaveRequest.id, true)
      },
    })
  }

  const handleCancelLeave = (leave: IUserPendingLeaves | IUserUpcomingLeaves | IUserToday) => {
    modal.confirm({
      title: formatMessage({ id: 'app.cancelLeave' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'app.cancelLeaveConfirmText' }),
      okText: formatMessage({ id: 'app.yes' }),
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        onCancelLeave(leave.id)
      },
    })
  }

  const handleDeleteLeave = (leave: IUserHistory) => {
    const isToil = isToilLeave(leave.id)
    modal.confirm({
      title: formatMessage({ id: isToil ? 'user.deleteToil' : 'user.deleteLeave' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: isToil ? 'user.deleteToilConfirmText' : 'user.deleteLeaveConfirmText' }),
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      onOk() {
        onDeleteLeave(leave.id)
      },
    })
  }

  return (
    <div className='vt-table-wrapper-user-page'>
      {(amIAdmin || amIApprover) && pendingRequests.length > 0 &&
        <Card title={<IntlMessages id="app.pendingLeaveRequests" />} style={{ marginBottom: 30 }} styles={{ body: { padding: 0 } }}>
          <Table
            locale={{ emptyText: <IntlMessages id="user.noUpcomingLeaves" /> }}
            dataSource={pendingRequests}
            columns={pendingRequestsColumns}
            rowKey={record => record.id}
            pagination={false}
            className="responsive-table"
          />
        </Card>
      }
      {!(amIAdmin || amIApprover) && pendingRequests.length > 0 &&
        <Card title={<IntlMessages id="app.pendingLeaveRequests" />} style={{ marginBottom: 30 }} styles={{ body: { padding: 0 } }}>
          <Table
            locale={{ emptyText: <IntlMessages id="user.noUpcomingLeaves" /> }}
            dataSource={pendingRequests}
            columns={pendingRequestsUserColumns}
            rowKey={record => record.id}
            pagination={false}
            className="responsive-table"
          />
        </Card>
      }
      {currentLeaves.length > 0 &&
        <Card title={<IntlMessages id="user.currentLeaves" />} style={{ marginBottom: 30 }} styles={{ body: { padding: 0 } }}>
          <Table
            locale={{ emptyText: <IntlMessages id="user.noUpcomingLeaves" /> }}
            dataSource={currentLeaves}
            columns={scheduledLeavesColumns}
            rowKey={record => record?.id}
            pagination={false}
            className="responsive-table"
          />
        </Card>
      }

      <Card title={<IntlMessages id="app.scheduledLeaves" />} style={{ marginBottom: 30 }} styles={{ body: { padding: 0 } }}>
        <Table
          locale={{ emptyText: <IntlMessages id="user.noUpcomingLeaves" /> }}
          dataSource={scheduledLeaves}
          columns={scheduledLeaves.length > 0 ?
            scheduledLeavesColumns :
            scheduledLeavesColumns.filter(leaveColumn => leaveColumn.key !== 'statusReason')
          }
          rowKey={record => record.id}
          pagination={false}
          className="responsive-table"
        />
      </Card>

      <Card id="userHistoryLeaves" title={<IntlMessages id="user.historyLeaves" />} style={{ marginBottom: 30 }} styles={{ body: { padding: 0, position: 'relative' } }}>
        <div id="userLeaveFilters">
          <label htmlFor="leaveKindFilter"><IntlMessages id="components.leaveForm.request"/></label>
          <Select
            id="leaveKindFilter"
            className="leave-history-filter"
            defaultValue="all"
            onChange={onLeaveKindFilterChange}
          >
            <Option value="all"><IntlMessages id="app.all"/></Option>
            <Option value="leaveRequest-"><IntlMessages id="app.leave"/></Option>
            <Option value="toilRequest-"><IntlMessages id="app.toil"/></Option>
          </Select>
          <label htmlFor="leaveTypeFilter"><IntlMessages id="events.logs.LEAVE_TYPE"/></label>
          <Select
            id="leaveTypeFilter"
            className="leave-history-filter"
            defaultValue="all"
            onChange={onLeaveTypeFilterChange}
          >
            <Option value="all"><IntlMessages id="app.all"/></Option>
            {Array.from(new Set(leaveHistory.map(item => item.leaveType.name))).map((leaveType) => (
              <Option key={leaveType} value={leaveType}>{leaveType}</Option>
            ))}
          </Select>
          <label htmlFor="leaveStatusFilter"><IntlMessages id="app.status"/></label>
          <Select
            id="leaveStatusFilter"
            className="leave-history-filter"
            defaultValue="all"
            onChange={onLeaveStatusFilterChange}
          >
            <Option value="all"><IntlMessages id="app.all"/></Option>
            {Array.from(new Set(leaveHistory.map(item => item.status))).map((leaveStatus) => (
              <Option key={leaveStatus} value={leaveStatus}>{
                capitalize(formatMessage({
                  id: leaveStatus === 'EXPIRED' ? 'app.expired' : `leaveRequestStatus.${leaveStatus}`,
                }))
              }</Option>
            ))}
          </Select>
        </div>
        <Table
          locale={{ emptyText: <IntlMessages id="user.dontHaveAnyHistory" values={{ name: user.name }} /> }}
          dataSource={filteredHistory}
          columns={leaveHistoryColumns}
          rowKey={record => record.id}
          pagination={false}
          tableLayout="auto"
          className="responsive-table"
        />
      </Card>

      {isDenyWithReasonModalVisible && selectedLeaveRequest &&
        <DenyWithReasonForm
          visibleModal={isDenyWithReasonModalVisible}
          leaveRequest={selectedLeaveRequest}
          handleCancel={() => (showDenyWithReasonModal(false))}
          onSave={handleDenyWithReason}
        />
      }
    </div>
  )
}

export default UserLeavesTab
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { IEmailInvite } from '@vacationtracker/shared/types/user'
import { List, Input, Form, Button, InputRef, Select, Alert } from 'antd'
import {  DeleteOutlined } from '@ant-design/icons'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import * as Sentry from '@sentry/react'

import IntlMessages from '../../util/IntlMessages'
import axios from 'axios'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { useAppSelector } from '../../store/hooks'
import { validEmailWithRegex } from '@vacationtracker/shared/functions/valid-email-with-regex'

interface IInviteAndManageUsersWithEmail {
  invitedUsers: IEmailInvite[]
  authUserEmail: string
  addInvite: (data) => void
  removeInvite: (data) => void
  changeLocale: (newLocale: LocaleEnum) => void
  selectedLocale?: LocaleEnum
  showInputField?: boolean
}

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const { Option } = Select


export const InviteAndManageUsersWithEmail = ({
  invitedUsers,
  authUserEmail,
  selectedLocale,
  addInvite,
  removeInvite,
  changeLocale,
  showInputField = true,
}: IInviteAndManageUsersWithEmail): React.ReactElement => {
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const [userExistsError, setUserExistsError] = useState(false)
  const [isCheckingIfUserExists, setIsCheckingIfUserExists] = useState(false)
  const emailInputRef = useRef<InputRef | null>(null)

  useEffect(() => {
    emailInputRef.current?.focus()
  }, [invitedUsers])

  const handleOnAddInvite = async () => {
    try {
      const values = await form.validateFields()
      const invite = {
        email: values.inviteEmail,
        isAdmin: false,
        isOwner: false,
        name: '',
      }
      addInvite(invite)
      form.resetFields()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const validation = async (rule, email) => {
    if (email === authUserEmail) {
      return Promise.reject(new Error(formatMessage({ id: 'components.inviteAndManageUsersWithEmail.canNotInviteYourself' })))
    }
    setIsCheckingIfUserExists(true)
    setUserExistsError(false)

    if (validEmailWithRegex(email)) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/core/auth-info`, { email })
        setUserExistsError(true)
        setIsCheckingIfUserExists(false)
        return Promise.reject(new Error(formatMessage({ id: 'components.inviteAndManageUsersWithEmail.userAlreadyExists' })))
      } catch (error) {
        setIsCheckingIfUserExists(false)
        if (error.code === 'UserNotFound') {
          return Promise.resolve()
        }
      }
    } else {
      setIsCheckingIfUserExists(false)
      return Promise.reject(new Error(formatMessage({ id: 'connect.pleaseInputValidEmail' })))
    }
  }

  return <div>
    <Form
      {...formItemLayout}
      form={form}
      layout="horizontal"
      onFinish={handleOnAddInvite}
    >
      <Form.Item
        name="inviteEmail"
        hidden={!showInputField}
        rules={[ { required: true, type: 'email', validator: validation } ]}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: '12px',
        }}>
          <Input className="email-input" size="large" allowClear ref={emailInputRef} placeholder={formatMessage({ id: 'app.email' })} />
          <Button
            size="large"
            type="primary"
            block
            htmlType="submit"
            disabled={isCheckingIfUserExists || userExistsError}
            style={{ maxWidth: '150px' }}
          >
            <IntlMessages id="app.add" />
          </Button>
        </div>
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'users.sendInvitationsInLanguage' })}
      >
        <Select
          style={{ width: 150 }}
          defaultValue={ selectedLocale || authUser.locale || LocaleEnum.en }
          onChange={(newLocale: LocaleEnum) => changeLocale(newLocale)}
        >
          {
            Object.values(availableLanguages).map(language =>
              <Option key={language.locale} value={language.locale}>{language.name}</Option>
            )
          }
        </Select>
      </Form.Item>

    </Form>
    <List
      style={{ marginTop: '10px' }}
      dataSource={invitedUsers}
      header={
        (!showInputField ? <Alert
          type="info"
          message={<IntlMessages id='users.inviteUsersDescription' />}
        /> : <div className="invite-users-modal-header">
          <IntlMessages id='components.inviteAndManageUsersWithEmail.header' />
        </div>)
      }
      renderItem={item => {
        return <List.Item
          actions={[
            <Button key='remove-invite' onClick={() => removeInvite(item)}>
              <DeleteOutlined />
            </Button>,
          ]}
        >
          {item.email}
        </List.Item>
      }}
    />
  </div>
}

{
  "accountSettings.connectYourPersonalCalendar": "Verbinden Sie Ihren persönlichen Kalender",
  "accountSettings.connectYourPersonalCalendar.description": "Verbinden Sie Ihren persönlichen Kalender automatisch mit Ihren Abwesenheiten. Wenn Sie eine Abwesenheit beantragen, können Sie festlegen, ob diese als verfügbar oder beschäftigt in Ihrem Kalender angezeigt werden soll. Besuchen Sie unser <helpdesk>Helpdesk</helpdesk> für weitere Informationen.",
  "actionNotifications.companyAnnouncement.success": "Ankündigung gesendet",
  "actionNotifications.leave.editedRequestDenied": "Bearbeitung des Urlaubsantrags abgelehnt.",
  "actionNotifications.leave.editedRequestDeniedDescription": "Ihr bearbeiteter Urlaubsantrag wurde abgelehnt, Ihr ursprünglicher Antrag bleibt jedoch aktiv.",
  "actionNotifications.leave.requestApproved": "Urlaubsantrag ist genehmigt",
  "actionNotifications.leave.requestDenied": "Urlaubsantrag wurde abgelehnt.",
  "actionNotifications.leaveType.created": "Der Abwesenheitstyp {leaveTypeName} wurde erstellt",
  "actionNotifications.leaveType.deleted": "Der Abwesenheitstyp wurde gelöscht",
  "actionNotifications.leaveType.updated": "Der Abwesenheitstyp {leaveTypeName} wurde aktualisiert",
  "actionNotifications.location.createdTitle": "Der Standort {locationName} wurde erstellt",
  "actionNotifications.location.deletedTitle": "Der Standort wurde gelöscht",
  "actionNotifications.location.updatedTitle": "Der Standort {locationName} wurde aktualisiert",
  "actionNotifications.location.view": "Standort anzeigen",
  "actionNotifications.notification.created": "Benachrichtigung wurde erstellt",
  "actionNotifications.notification.deleted": "Benachrichtigung wurde gelöscht",
  "actionNotifications.notification.updated": "Benachrichtigung wurde aktualisiert",
  "actionNotifications.outOfOfficeSetting.success": "Ihre Abwesenheitseinstellung wurde aktualisiert",
  "actionNotifications.scheduledReport.createdTitle": "Der geplante Bericht {scheduledReportName} wurde erstellt",
  "actionNotifications.scheduledReport.deletedTitle": "Der geplante Bericht wurde gelöscht",
  "actionNotifications.scheduledReport.updatedTitle": "Der geplante Bericht {scheduledReportName} wurde aktualisiert",
  "actionNotifications.scheduledReport.viewScheduledReport": "Geplanten Bericht anzeigen",
  "actionNotifications.team.createdTitle": "Die Abteilung {teamName} wurde erstellt",
  "actionNotifications.team.deletedTitle": "Die Abteilung wurde gelöscht",
  "actionNotifications.team.updatedTitle": "Die Abteilung {teamName} wurde aktualisiert",
  "actionNotifications.team.view": "Abteilung anzeigen",
  "addLeave.addLeave": "Urlaub hinzufügen",
  "addLeave.handleSubmitErrorTitle": "Fehler beim Einreichen des Urlaubs",
  "addLeave.inProgress": "Urlaub wird hinzugefügt",
  "addLeave.noApproverUsers": "Wenn Sie nicht Teil einer Abteilung sind, können Sie für sich selbst keinen Urlaub hinzufügen. Um Urlaub zu beantragen, auch wenn Sie nicht Teil einer Abteilung sind, für die Sie Genehmigungsrechte haben, öffnen Sie das Formular \"Urlaub beantragen\" und reichen Sie einen Urlaubsantrag ein.",
  "addLeave.title": "Urlaub hinzugefügt",
  "addons.openApi.apiKeyName": "API-Schlüsselname",
  "addons.openApi.apiKeyNamePlaceholder": "API-Schlüsselname",
  "addons.openApi.apiKeySecret": "API-Schlüssel-Geheimnis",
  "addons.openApi.apiKeysInfo": "Generieren und verwalten Sie Ihre API-Schlüssel.",
  "addons.openApi.apiKeysTitle": "API-Schlüssel",
  "addons.openApi.buyAppiAddon": "API-Add-on kaufen",
  "addons.openApi.buyOpenApiInfoPrice": "Das API-Add-on ist ein kostenpflichtiges Add-on, das {price}$/Monat/aktiver Benutzer kostet. Der Kauf erhöht Ihre monatliche Rechnung um {totalPaid}$ für {userCount, plural, =1 {Ihr} other {Ihre}} {userCount} {userCount, plural, =1 {aktiver} other {aktiven}} Benutzer (wir geben Ihnen eine genaue Schätzung, wenn Sie auf die Schaltfläche \"Kaufen\" klicken).",
  "addons.openApi.buyOpenApiInfoPrice2line": "Nach dem Kauf des Add-ons können Sie oder jeder andere Administrator in Ihrer Organisation Ihren API-Schlüssel generieren und verwalten.",
  "addons.openApi.buyOpenApiTitle": "Kaufen Sie das API-Add-on, um API-Schlüssel zu generieren",
  "addons.openApi.createInProgress": "API-Schlüssel wird erstellt",
  "addons.openApi.createInProgressCompleted": "API-Schlüssel-Erstellung abgeschlossen",
  "addons.openApi.createNewApiKey": "Neuen API-Schlüssel erstellen",
  "addons.openApi.currentMonthUsage": "Aktuelle Monatsnutzung",
  "addons.openApi.documentation": "API-Dokumentation",
  "addons.openApi.documentationInfo": "Die API-Dokumentation beschreibt, wie unsere API funktioniert und welche Methoden derzeit verfügbar sind.",
  "addons.openApi.dontHaveApiKeys": "Sie haben keine API-Schlüssel, klicken Sie auf die Schaltfläche unten, um einen zu erstellen",
  "addons.openApi.dpenDocumentation": "Dokumentation öffnen",
  "addons.openApi.feedback": "Wenn Sie Feedback haben oder Daten benötigen, die unsere API nicht bereitstellt, <link>klicken Sie hier, um uns das mitzuteilen</link>.",
  "addons.openApi.limitRequestPerMonth": "Sie können bis zu 30000 Anfragen pro Monat senden",
  "addons.openApi.limitRequestPerSecound": "Anfragen sind auf 10 pro Sekunde mit einem Burst-Modus von 50 Anfragen/Sekunde begrenzt",
  "addons.openApi.notificationDeleteConfirm": "Bitte bestätigen Sie, dass Sie den API-Schlüssel <strong>{name}</strong> löschen möchten.",
  "addons.openApi.notificationDeleteTitle": "API-Schlüssel löschen",
  "addons.openApi.openApiDeleted": "Open API-Schlüssel gelöscht",
  "addons.openApi.quotasAndRateLimitsInfo": "Hier sind unsere API-Kontingente und Ratenbegrenzungen pro API-Schlüssel:",
  "addons.openApi.quotasAndRateLimitsTitle": "Kontingente und Ratenbegrenzungen",
  "addons.openApiInfo": "Unser API-Add-on ermöglicht es Ihnen, Vacation Tracker programmatisch mit anderen Anwendungen zu verbinden. Derzeit bietet unsere API Ihnen nur Lesezugriff auf Ihre Benutzer, deren Urlaube, Standorte, Abteilungen, Urlaubsarten und Etiketten.",
  "addonsPaymentStatus.activateForFree": "Kostenlos aktivieren",
  "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Aktiv bis zum Ende der Abrechnungsperiode",
  "addonsPaymentStatus.canceled": "Storniert",
  "addonsPaymentStatus.grantPeriod": "Bewilligungszeitraum",
  "addonsPaymentStatus.paymentActive": "Zahlung aktiv",
  "ai.assistant.completedConversationTooltip": "Diese Unterhaltung ist abgeschlossen. Sie können keine neuen Nachrichten senden, aber Sie können die bestehende Unterhaltung sehen.",
  "ai.assistant.defaultMessage": "Womit kann ich Ihnen helfen?",
  "ai.assistant.disclaimer": "KI-Assistent ist in der Beta-Phase. Es kann Fehler machen. Bei Fehlern oder verwirrenden Antworten <support>kontaktieren Sie bitte unseren Support</support>.",
  "ai.assistant.inputLockedTooltip": "Dies ist eine frühe Version unseres KI-Assistenten. Derzeit hilft er Ihnen nur beim Onboarding. Bald werden Sie jedoch neue Gespräche beginnen und mit dem Vacation Tracker KI-Assistenten sprechen können. Danke für Ihre Geduld!",
  "ai.assistant.lastMessageTimestampTooltip": "Die letzte Nachricht wurde gesendet am: {date}",
  "ai.assisted.onboarding.companyLogUserNameDecorator": "(durch KI-Assistent)",
  "ai.assisted.onboarding.finalAssistantMessage": "Ihr Onboarding-Prozess ist abgeschlossen. Vacation Tracker wird jetzt Ihr Firmenkonto mit dieser Konfiguration einrichten. Denken Sie daran, dass Sie Ihr Setup später jederzeit ändern können, wenn nötig.",
  "ai.assisted.onboarding.initialAssistantMessage": "Während Sie sich angemeldet haben, haben wir bereits einige Dinge für Sie eingerichtet:\n\nStandorte ({locations}), \nAbteilungen ({departments}), und \nUrlaubsarten ({leaveTypes}).\n\nArbeiten alle Ihre Mitarbeiter an diesem Standort?",
  "ai.assisted.onboarding.initialUserMessage": "Hallo! Ich bin Ihr KI-Assistent. Ich bin hier, um Ihnen bei der Einrichtung Ihres neuen Firmenkontos zu helfen.\n\nDieses KI-Setup ist natürlich optional. Sie können es überspringen und das System selbst einrichten. (Sie möchten wahrscheinlich im Abschnitt \"Einstellungen\" unseres Menüs beginnen). Ich werde Sie jedoch durch einige wesentliche Teile von Vacation Tracker führen und alles in wenigen Minuten für Sie einrichten. Wir speichern keine Ihrer privaten Daten (aber bitte teilen Sie keine sensiblen Informationen mit mir).",
  "ai.assisted.onboarding.noTopics": "Hey, es scheint, dass Sie gerade ein Osterei gefunden haben! 🐣 Wir arbeiten an etwas Neuem, das Ihnen viel Zeit sparen sollte, aber es ist noch nicht fertig. Also lassen Sie uns das bitte als unser kleines Geheimnis bewahren. Danke!",
  "ai.assisted.onboarding.openSetupNotification": "Sehen Sie, was bisher erledigt wurde",
  "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Vielen Dank für die Bereitstellung aller erforderlichen Informationen. Ich beginne jetzt mit der Erstellung Ihres Vacation Tracker-Setups. Sie werden in Kürze Live-Updates sehen...",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "{leaveTypeName} Urlaubsart zugewiesen an {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "Weise {leaveTypeName} Urlaubsart zu an {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} wurde erfolgreich {action}",
  "ai.assisted.onboarding.openSetupNotificationNoData": "Setup-Daten können nicht angezeigt werden",
  "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "Ich kann die Setup-Daten im Moment nicht anzeigen. Dies kann aufgrund einer kurzen Netzwerkunterbrechung oder weil das System die Datenverarbeitung noch nicht abgeschlossen hat, passieren. Bitte laden Sie diese Seite neu oder überprüfen Sie die Einstellungen im Einstellungsbereich des Menüs.",
  "ai.assisted.onboarding.openSetupNotificationTitle": "Onboarding-Setup-Status",
  "ai.assisted.onboarding.sendMessageError": "Es scheint, dass die Antwort des KI-Assistenten zu lange dauert. Bitte laden Sie die Seite neu.",
  "ai.assisted.onboarding.summary.intro": "Sie sind jetzt komplett eingerichtet. Sie können nun das Dashboard öffnen und die App nutzen.",
  "ai.assisted.onboarding.summary.introSummary": "Hier ist die Zusammenfassung Ihres Setups:",
  "ai.assisted.onboarding.summary.leavePoliciesInfo": "Urlaubsrichtlinien sind standortspezifische Konfigurationen für Urlaubsarten. Um die erstellten Urlaubsrichtlinien zu sehen, gehen Sie zur Standortseite, wählen Sie einen Standort aus und öffnen Sie dann den Tab für Urlaubsrichtlinien.",
  "ai.assisted.onboarding.summary.view.departments": "Abteilungen anzeigen",
  "ai.assisted.onboarding.summary.view.leaveTypes": "Urlaubsarten anzeigen",
  "ai.assisted.onboarding.summary.view.locations": "Standorte anzeigen",
  "app.12h": "12h",
  "app.24h": "24h",
  "app.aboutMe": "Über mich:",
  "app.accountPreferences": "Einstellungen anzeigen",
  "app.accountSettings": "Kontoeinstellungen",
  "app.accruals": "Ansammlungen",
  "app.actions": "Aktionen",
  "app.activate": "Aktivieren",
  "app.active": "Aktiv",
  "app.add": "Hinzufügen",
  "app.addAvatar": "Avatar hinzufügen",
  "app.addLeave": "Urlaub hinzufügen",
  "app.addNewEmail": "Empfänger hinzufügen",
  "app.addons": "Erweiterungen",
  "app.addToGoogleCalendar": "Google Kalender",
  "app.addToMicrosoft365Calendar": "Microsoft 365 Kalender",
  "app.AIAssistant": "KI-Assistent",
  "app.all": "Alle",
  "app.allDay": "Ganztägig",
  "app.allowHalfDays": "Halbe Tage erlauben",
  "app.allowHourlyIncrements": "Stündliche Inkremente erlauben",
  "app.and": "und",
  "app.announcementsHaveBeenPaused": "Ihre Ankündigungen wurden für 2 Monate pausiert.",
  "app.applyTo": "Anwenden auf",
  "app.approvalReason": "Genehmigungsgrund",
  "app.approvalRequired": "Genehmigung erforderlich",
  "app.approve": "Genehmigen",
  "app.approved": "Genehmigt",
  "app.approveLeaveText": "Bitte bestätigen Sie, dass Sie den Urlaubsantrag genehmigen möchten.",
  "app.approveLeaveTitle": "Urlaubsantrag genehmigen",
  "app.april": "April",
  "app.areYouSure": "Sind Sie sicher?",
  "app.askMeLater": "Frag mich später",
  "app.assignLeavePolicy": "Urlaubsrichtlinie zuweisen",
  "app.august": "August",
  "app.autoApproved": "Automatisch genehmigt",
  "app.autoDeleted": "<Automatisch gelöscht>",
  "app.autoImport": "<Automatischer Import>",
  "app.automations": "Automatisierungen",
  "app.avatar": "Avatar",
  "app.back": "Zurück",
  "app.backToMenu": "Zurück zum Menü",
  "app.bi-weekly": "Zweiwöchentlich",
  "app.billing.periodAnnual": "Jährlich",
  "app.billingStatus": "Abrechnungsstatus",
  "app.billingStatus.ACTIVE": "Zahlung aktiv",
  "app.billingStatus.CANCEL_AT_PERIOD_END": "Aktiv bis zum Ende der Abrechnungsperiode",
  "app.billingStatus.CANCELED": "Zahlung storniert",
  "app.billingStatus.GRANT_PERIOD": "Gewährungsperiode",
  "app.broughtForward": "Übertragen",
  "app.by": "von",
  "app.calendar": "Kalender",
  "app.cancel": "Abbrechen",
  "app.cancelLeave": "Urlaub stornieren",
  "app.cancelLeaveConfirmText": "Sind Sie sicher, dass Sie diesen geplanten Urlaub stornieren möchten?",
  "app.cancelled": "Storniert",
  "app.change": "Ändern",
  "app.changePassword": "Passwort ändern",
  "app.close": "Schließen",
  "app.code": "Code",
  "app.color": "Farbe",
  "app.comingSoon": "Demnächst verfügbar",
  "app.company": "Unternehmen",
  "app.companyLogs": "Unternehmensprotokoll",
  "app.companyNotExist": "Hoppla! Es scheint, dass Sie die Anmeldung bei Vacation Tracker nicht abgeschlossen haben. Bitte besuchen Sie: app.vacationtracker.io, um die Anmeldung abzuschließen.",
  "app.companyNotExist.description1": "Sie haben jetzt den Vacation Tracker-Bot und die Registerkarte zu Ihrem Microsoft Teams-Arbeitsbereich hinzugefügt.",
  "app.companyNotExist.description2": "Bitte gehen Sie auf unsere Website, um Ihre Registrierung abzuschließen und die Einstellungen Ihrer Organisation zu konfigurieren.",
  "app.companyNotExist.getStarted": "Anmeldung abschließen",
  "app.completed": "Abgeschlossen",
  "app.completeRegistration": "Registrierung abschließen",
  "app.configuration.moreInfo": "Für weitere Informationen besuchen Sie unseren Helpdesk: <link>https://vacationtracker.crisp.help/en/</link>",
  "app.configuration.option.overview": "Übersicht",
  "app.configuration.options.info": "Wählen Sie den Registerkartentyp: ",
  "app.configuration.welcome": "Willkommen bei Vacation Tracker! Mit dieser Registerkarte können Sie einfach Urlaub beantragen, ohne Microsoft Teams zu verlassen, Ihre Abwesenheiten verwalten, den Status Ihrer Urlaubsanträge überprüfen, sehen, wer außer Haus ist, und bevorstehende Feiertage für Ihr Team einsehen.",
  "app.configure": "Konfigurieren",
  "app.confirm": "Bestätigen",
  "app.confirmNewPassword": "Neues Passwort bestätigen",
  "app.confirmPassword": "Passwort bestätigen",
  "app.consentRequired": "Microsoft Teams Admin-Zustimmung erforderlich. Es scheint, dass Sie kein Administrator Ihrer Microsoft Teams-Organisation sind. Um auf die Vacation Tracker-Registerkarte zugreifen zu können, benötigen Sie die Admin-Zustimmung. Bitte senden Sie den folgenden Link an den Administrator Ihrer Organisation, um die Admin-Zustimmung zu erhalten: {link}",
  "app.consentRequired.title": "Microsoft Teams Admin-Zustimmung erforderlich",
  "app.contactSupport": "Support kontaktieren",
  "app.continue": "Fortfahren",
  "app.copyright": "Copyright VacationTracker",
  "app.correlationIdError": "Ein Fehler ist aufgetreten. Bitte kontaktieren Sie unseren Kundensupport unter hello@vacationtracker.io mit der folgenden ID: {correlationId}",
  "app.country": "Land",
  "app.create": "Erstellen",
  "app.created": "Erstellt",
  "app.createdAt": "Erstellt am",
  "app.createdBy": "Erstellt von",
  "app.createdSuccessfully": "Anfrage eingereicht",
  "app.creating": "Wird erstellt",
  "app.creditCard": "Kreditkarte",
  "app.currentYear": "Aktuelles Jahr",
  "app.customDays": "Benutzerdefinierte Tage",
  "app.customWorkHours": "Benutzerdefinierte Arbeitszeiten",
  "app.daily": "Täglich",
  "app.dashboard": "Dashboard",
  "app.dateRange": "Start- und Enddatum",
  "app.day": "Tag",
  "app.days": "Tage",
  "app.december": "Dezember",
  "app.delete": "Löschen",
  "app.deleted": "Gelöscht",
  "app.deleteFailed": "Löschen fehlgeschlagen",
  "app.deleteUser": "Benutzer löschen",
  "app.deleting": "Wird gelöscht",
  "app.deliveryFailed": "Zustellung fehlgeschlagen",
  "app.denialReason": "Ablehnungsgrund",
  "app.denied": "Abgelehnt",
  "app.deny": "Ablehnen",
  "app.denyLeaveRequest": "Urlaubsantrag ablehnen",
  "app.denyLeaveRequestText": "Bitte bestätigen Sie, dass Sie den Urlaubsantrag ablehnen möchten.",
  "app.denyWithReason": "Mit Begründung ablehnen",
  "app.department": "Abteilung",
  "app.departments": "Abteilungen",
  "app.departmentsSub": "Unterabteilungen",
  "app.deselectAll": "Alle abwählen",
  "app.displayDaysHoursTooltip.days": "Das sind {value} Tage",
  "app.displayDaysHoursTooltip.hours": "Das sind {value} Stunden",
  "app.documentation": "Dokumentation",
  "app.done": "Erledigt",
  "app.dontSend": "Nicht senden",
  "app.downgradeWithoutUnsubscribe": "Herabstufen ohne Abmeldung",
  "app.downgradeWithSubscribe": "Herabstufen und abmelden",
  "app.download": "Herunterladen",
  "app.downloadProgressReport": "Ihre Datei wird erstellt, dies kann einen Moment dauern...",
  "app.downloadReady": "Ihre Datei ist bereit. Klicken Sie hier, um sie herunterzuladen.",
  "app.downloadTemplate": "Vorlage herunterladen",
  "app.edit": "Bearbeiten",
  "app.edited": "Bearbeitet",
  "app.editLeave": "Urlaub bearbeiten",
  "app.email": "E-Mail",
  "app.emailDeleted": "E-Mail gelöscht",
  "app.employeeEndDate": "Enddatum des Mitarbeiters",
  "app.enable": "Aktivieren",
  "app.enabled": "Aktiviert",
  "app.enterWorkEmail": "Geben Sie Ihre Arbeits-E-Mail ein",
  "app.error": "Fehler",
  "app.every": "jede(n)",
  "app.expired": "Abgelaufen",
  "app.expires": "Läuft ab",
  "app.export": "Exportieren",
  "app.export.comingSoon": "Unser Team arbeitet an dieser Funktion. Sie können sie bald erwarten.",
  "app.feature.access": "Ausschließlich im Complete-Plan verfügbar.",
  "app.february": "Februar",
  "app.firstAndLastName": "Vor- und Nachname",
  "app.friday": "Freitag",
  "app.friday.short": "Fr",
  "app.full-day": "Ganzer Tag",
  "app.full-name": "Vollständiger Name",
  "app.general": "Allgemein",
  "app.goodAfternoon": "Guten Nachmittag",
  "app.goodEvening": "Guten Abend",
  "app.goodMorning": "Guten Morgen",
  "app.gotIt": "Verstanden",
  "app.half-day": "Halber Tag",
  "app.halfDay": "Halber Tag",
  "app.halfDayInParenthesis": " (Halber Tag)",
  "app.happyVacationing": "Schönen Urlaub 🌴",
  "app.helpdesk": "Helpdesk",
  "app.holidays": "Feiertage",
  "app.home": "Startseite",
  "app.hour": "Stunde",
  "app.hours": "Stunden",
  "app.import": "Importieren",
  "app.importFile": "Datei importieren",
  "app.importFormat": "Importformat",
  "app.importLeaves": "Urlaub importieren",
  "app.importLeaves.alerts.confirmClose.bodyText": "Sind Sie sicher? Ihre aktuellen Informationen werden nicht gespeichert.",
  "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "Abbrechen",
  "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Beenden",
  "app.importLeaves.alerts.confirmClose.headerTitle": "Importvorgang beenden",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "Es gibt noch einige Zeilen, die Fehler enthalten. Zeilen mit Fehlern werden beim Absenden ignoriert.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "Es gibt noch einige Zeilen, die Fehler enthalten. Bitte entfernen Sie ungültige Zeilen oder beheben Sie die Fehler und versuchen Sie es erneut.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "Abbrechen",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Absenden",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Fehler erkannt",
  "app.importLeaves.alerts.toast": "Fehler",
  "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "Es gibt erforderliche Spalten, die nicht zugeordnet oder ignoriert wurden. Sie müssen alle erforderlichen Spalten zuordnen, um fortzufahren.",
  "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "Abbrechen",
  "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Fortfahren",
  "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "Nicht alle Spalten zugeordnet",
  "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Erforderliche Spalten nicht zugeordnet:",
  "app.importLeaves.downloadTemplateFileTooltip": "Hier können Sie unsere Import-Vorlagendatei herunterladen, die als Beispiel für das Ausfüllen der zu importierenden Daten dient. Stellen Sie beim Erstellen Ihrer Importdatei sicher, dass sie eine Kopfzeile enthält, damit das System Ihre Daten korrekt erkennen kann.<br></br><br></br>Überprüfen Sie, ob Ihre Daten mit der Vorlage übereinstimmen, um einen erfolgreichen Import zu gewährleisten. Für zusätzliche Anweisungen können Sie sich gerne an unser Support-Team wenden.",
  "app.importLeaves.dropzone.activeDropzoneTitle": "Datei hier ablegen",
  "app.importLeaves.dropzone.buttonTitle": "Datei auswählen",
  "app.importLeaves.dropzone.errorToastDescription": "Laden Sie eine .xlsx, .xls oder .csv Datei hoch",
  "app.importLeaves.dropzone.loadingTitle": "Verarbeitung...",
  "app.importLeaves.dropzone.title": "Laden Sie eine .xlsx, .xls oder .csv Datei hoch",
  "app.importLeaves.errors.dateFromAfterDateTo": "Startdatum liegt nach Enddatum",
  "app.importLeaves.errors.dateFromNotValid": "Startdatum ist ungültig",
  "app.importLeaves.errors.dateFromRequired": "Startdatum ist erforderlich",
  "app.importLeaves.errors.dateToNotValid": "Enddatum ist ungültig",
  "app.importLeaves.errors.dateToRequired": "Enddatum ist erforderlich",
  "app.importLeaves.errors.halfDay": "Urlaub ist länger als ein Tag",
  "app.importLeaves.errors.hourFromAfterHourTo": "Startstunde liegt nach Endstunde",
  "app.importLeaves.errors.hourFromNotValid": "Startstunde ist ungültig, geben Sie einen Wert zwischen 0 und 24 ein",
  "app.importLeaves.errors.hourFromRequired": "Startstunde ist erforderlich",
  "app.importLeaves.errors.hourRange": "Die ausgewählten Stunden überschreiten die Arbeitstag-Länge dieses Benutzers ({hours} Stunden). Bitte passen Sie Ihre Auswahl an.",
  "app.importLeaves.errors.hourToNotValid": "Endstunde ist ungültig, geben Sie einen Wert zwischen 0 und 24 ein",
  "app.importLeaves.errors.hourToRequired": "Endstunde ist erforderlich",
  "app.importLeaves.errors.leavePolicyError": "Urlaubsrichtlinie erlaubt nur ganze Tage",
  "app.importLeaves.errors.leaveTypeNotFound": "Urlaubsart nicht gefunden",
  "app.importLeaves.errors.leaveTypeNotInUserLocation": "Urlaubsart nicht am Standort des Benutzers verfügbar",
  "app.importLeaves.helpdesk": "Für detaillierte Anleitung zum Importieren Ihrer Dateien, konsultieren Sie bitte die schrittweisen Anweisungen auf <helpdesk>der Helpdesk-Seite</helpdesk>.",
  "app.importLeaves.importFormatTooltip": "Wählen Sie das Importformat aus, das zu Ihrem Datentyp und Ihren Präferenzen passt.",
  "app.importLeaves.leaveStatusWarning": "Der Urlaubsstatus wird für alle importierten Urlaube auf \"Genehmigt\" gesetzt.",
  "app.importLeaves.manifestDescription": "(Sie haben die Möglichkeit, Spalten in den nächsten Schritten umzubenennen oder zu entfernen)",
  "app.importLeaves.manifestTitle": "Daten, die wir erwarten:",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "Spalten dürfen nicht dupliziert werden",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "Eine andere Spalte wurde abgewählt",
  "app.importLeaves.matchColumnsStep.ignoredColumnText": "Spalte ignoriert",
  "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Zuordnen",
  "app.importLeaves.matchColumnsStep.nextButtonTitle": "Weiter",
  "app.importLeaves.matchColumnsStep.selectPlaceholder": "Spalte auswählen...",
  "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Auswählen...",
  "app.importLeaves.matchColumnsStep.templateTitle": "Wird zu",
  "app.importLeaves.matchColumnsStep.title": "Spalten zuordnen",
  "app.importLeaves.matchColumnsStep.unmatched": "Nicht zugeordnet",
  "app.importLeaves.matchColumnsStep.userTableTitle": "Ihre Tabelle",
  "app.importLeaves.maxRecordsExceeded": "Zu viele Datensätze. Bis zu {maxRecords} erlaubt",
  "app.importLeaves.nextButtonTitle": "Weiter",
  "app.importLeaves.selectHeaderStep.title": "Kopfzeile auswählen",
  "app.importLeaves.selectSheet.title": "Arbeitsblatt auswählen",
  "app.importLeaves.validationStep.discardButtonTitle": "Ausgewählte Zeilen verwerfen",
  "app.importLeaves.validationStep.filterSwitchTitle": "Nur Zeilen mit Fehlern anzeigen",
  "app.importLeaves.validationStep.nextButtonTitle": "Bestätigen",
  "app.importLeaves.validationStep.noRowsMessage": "Keine Daten gefunden",
  "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "Keine Daten mit Fehlern",
  "app.importLeaves.validationStep.title": "Daten validieren",
  "app.importType": "Importtyp",
  "app.integrations": "Integrationen",
  "app.invite": "Einladen",
  "app.january": "Januar",
  "app.july": "Juli",
  "app.june": "Juni",
  "app.label": "Etikett",
  "app.labels": "Etiketten",
  "app.lastMonth": "Letzter Monat",
  "app.learnMore": "Mehr erfahren",
  "app.leave": "Urlaub",
  "app.leaveBalanceReport": "Urlaubsguthaben-Bericht",
  "app.leaveCanceled": "Urlaub storniert",
  "app.leaveHistory": "Urlaubsverlauf",
  "app.leaveHistoryReport": "Urlaubsantrags-Bericht",
  "app.leavePolicies": "Urlaubsrichtlinien",
  "app.leaveRequestCalendarStatusLabel": "In Ihrem Kalender anzeigen als ",
  "app.leaveRequestCreated": "Urlaubsantrag erstellt",
  "app.leaveRequests": "Urlaubsanträge",
  "app.leaveType": "Urlaubsart",
  "app.leaveTypes": "Urlaubsarten",
  "app.link": "Link",
  "app.load": "Laden",
  "app.loading": "Wird geladen",
  "app.loadMore": "Mehr laden",
  "app.location": "Standort",
  "app.locations": "Standorte",
  "app.login": "Anmelden",
  "app.logInWithMicrosoft": "Mit Microsoft anmelden",
  "app.logout": "Abmelden",
  "app.logoutNow": "Jetzt abmelden",
  "app.logs": "Protokolle",
  "app.manageLabels": "Etiketten verwalten",
  "app.march": "März",
  "app.may": "Mai",
  "app.maybe": "Vielleicht",
  "app.message": "Nachricht",
  "app.meta.description": "Von über 1.000 Unternehmen weltweit geliebt, macht Vacation Tracker die Verwaltung Ihrer Urlaubszeit bekanntermaßen einfach. Verfügbar für Slack, Microsoft Teams und Google Workspace.",
  "app.meta.title": "Vacation Tracker - Remote-freundliche Lösung zur Urlaubsverfolgung",
  "app.mfa": "Mehrfaktor-Authentifizierung",
  "app.mfaCode": "Code",
  "app.mfaCodeErrorGeneric": "Code-Validierung fehlgeschlagen",
  "app.mfaCodeErrorMissmatch": "Code stimmt nicht überein",
  "app.mfaCodeErrorNetwork": "Netzwerkfehler",
  "app.mfaCodePlaceholder": "Bitte geben Sie den in Ihrer Authentifizierungs-App angezeigten Code ein",
  "app.mfaDisableAnswerNo": "Nein, MFA beibehalten",
  "app.mfaDisableAnswerYes": "Ja, MFA deaktivieren",
  "app.mfaDisableError": "MFA konnte nicht deaktiviert werden. Bitte versuchen Sie es später erneut.",
  "app.mfaDisableSuccess": "Sie haben MFA erfolgreich deaktiviert.",
  "app.mfaDisableWarning": "Sind Sie sicher, dass Sie MFA deaktivieren möchten?",
  "app.mfaDisableWarningTitle": "Mehrfaktor-Authentifizierung deaktivieren",
  "app.mfaEnableError": "MFA konnte nicht aktiviert werden. Bitte versuchen Sie es später erneut.",
  "app.mfaEnableSuccess": "Sie haben MFA erfolgreich eingerichtet.",
  "app.mfaEnterCode": "Code eingeben",
  "app.mfaLabel": "MFA aktivieren",
  "app.mfaLoginFailed": "Anmeldung fehlgeschlagen",
  "app.mfaSecretKey": "Oder geben Sie den geheimen Schlüssel manuell ein:",
  "app.mfaSubTitle": "Scannen Sie den folgenden QR-Code in Ihrer MFA-Anwendung (z.B. Authy oder Google Authenticator):",
  "app.mfaTitle": "Mehrfaktor-Authentifizierung aktivieren",
  "app.mfaTooltip": "Nachdem Sie Vacation Tracker zu Ihrer MFA-Anwendung hinzugefügt haben, warten Sie, bis ein Code generiert wird, und geben Sie ihn unten ein:",
  "app.microsoft.manageLicenses": "Lizenzen verwalten",
  "app.microsoftTeams": "Microsoft Teams",
  "app.monday": "Montag",
  "app.monday.short": "Mo",
  "app.monthly": "Monatlich",
  "app.monthlyLeaveBalanceReport": "Monatlicher Urlaubskontobericht",
  "app.moreDetails": "Mehr Details",
  "app.myProfile": "Mein Profil",
  "app.myStatus": "Mein Status",
  "app.name": "Name",
  "app.newPassword": "Neues Passwort",
  "app.next": "Weiter",
  "app.nextInvoice": " Ihre nächste Rechnung beträgt {amount} (ohne MwSt.) am {startDate}",
  "app.nextMonth": "Nächster Monat",
  "app.nextYear": "Nächstes Jahr",
  "app.no": "Nein",
  "app.noLeaves": "Keine Urlaube",
  "app.none": "Keine",
  "app.nonWorkingDay": "Arbeitsfreier Tag",
  "app.notDone": "Nicht erledigt",
  "app.note": "Notiz",
  "app.notificationError": "Oh nein! 😕 Etwas ist schiefgelaufen. ",
  "app.notifications": "Benachrichtigungen",
  "app.notSet": "Nicht festgelegt",
  "app.notValidEmail": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  "app.november": "November",
  "app.october": "Oktober",
  "app.of": "von",
  "app.ofThat": "davon",
  "app.ok": "Ok",
  "app.oldPassword": "Altes Passwort",
  "app.one-hour": "1 Stunde",
  "app.openCalendar": "Kalender öffnen",
  "app.openDashboard": "Dashboard öffnen",
  "app.openWebDashboard": "Web-Dashboard öffnen",
  "app.or": "Oder",
  "app.password": "Passwort",
  "app.passwordAndSecurity": "Passwort und Sicherheit",
  "app.pastDue": "Aufgrund einer erfolglosen Zahlung wird Ihr Zugang zu Vacation Tracker bald geschlossen. Wenn Sie Vacation Tracker weiter nutzen möchten, bitte <a>geben Sie eine gültige Karte ein</a>.",
  "app.pending": "Ausstehend",
  "app.pendingLeaveRequests": "Ausstehende Urlaubsanträge",
  "app.pleaseSelect": "Bitte auswählen",
  "app.prev": "Zurück",
  "app.previous": "Vorherige",
  "app.proceed": "Fortfahren",
  "app.processingRequest": "Anfrage wird verarbeitet...",
  "app.profilePicture": "Profilbild",
  "app.reason": "Grund",
  "app.recipients": "Empfänger",
  "app.recommended": "empfohlen",
  "app.reconnect": "Erneut verbinden",
  "app.reload": "Neu laden",
  "app.removed": "entfernt",
  "app.rename": "Umbenennen",
  "app.reportType": "Berichtstyp",
  "app.requestLeave": "Urlaub beantragen",
  "app.requestToil": "TOIL beantragen",
  "app.required": "Erforderlich",
  "app.resendCode": "Code erneut senden",
  "app.resent": "Erneut gesendet",
  "app.reset": "Zurücksetzen",
  "app.resetPassword": "Passwort zurücksetzen",
  "app.reviewAndConfirm": "Überprüfen & Bestätigen",
  "app.role.administrator": "Administrator",
  "app.role.approver": "Genehmiger",
  "app.role.approvers": "Genehmiger",
  "app.saturday": "Samstag",
  "app.saturday.short": "Sa",
  "app.save": "Speichern",
  "app.scheduledLeaves": "Geplante Abwesenheiten",
  "app.scheduledReports": "Geplante Berichte",
  "app.seeInCalendar": "🗓️ Im Kalender anzeigen",
  "app.seeMoreOptions": "Weitere Optionen anzeigen",
  "app.select": "Auswählen",
  "app.selectAll": "Alle auswählen",
  "app.semi-monthly": "Halbmonatlich",
  "app.send": "Senden",
  "app.sendNow": "Jetzt senden",
  "app.september": "September",
  "app.set": "festlegen",
  "app.setOn": "festgelegt am",
  "app.shortestLeaveDuration": "Kürzeste Urlaubsdauer",
  "app.showLess": "Weniger anzeigen",
  "app.showMore": "Mehr anzeigen",
  "app.skip": "Überspringen",
  "app.slack": "Slack",
  "app.snooze": "Schlummern",
  "app.snoozeForMonths": "Für 2 Monate schlummern",
  "app.startFreeTrial": "Starten Sie Ihre 7-tägige kostenlose Testversion",
  "app.state": "Bundesland",
  "app.status": "Status",
  "app.submit": "Absenden",
  "app.substituteApprover": "Stellvertretender Genehmiger",
  "app.substituteApproverSet": "Sie wurden als stellvertretender Genehmiger festgelegt.",
  "app.sunday": "Sonntag",
  "app.sunday.short": "So",
  "app.switch": "Wechseln",
  "app.sync": "Synchronisieren",
  "app.takeQuickTour": "Machen Sie eine kurze Tour",
  "app.text": "Text",
  "app.thisMonth": "Diesen Monat",
  "app.thisWeek": "Diese Woche",
  "app.thursday": "Donnerstag",
  "app.thursday.short": "Do",
  "app.time": "Zeit",
  "app.timeOffInLieu": "Zeitausgleich",
  "app.timeOffInLieuTooltip": "Möchten Sie den Zeitausgleich bearbeiten? <helpDesk>Erfahren Sie, wie das geht</helpDesk>",
  "app.timezone": "Zeitzone",
  "app.today": "Heute",
  "app.toil": "TOIL",
  "app.trialExpired": "Die Testversion läuft in {days} {daysInfo} ab. Wenn Sie uns mögen, <a>abonnieren</a> Sie bitte, um Vacation Tracker nach Ablauf der Testphase weiter zu nutzen.",
  "app.trialExtend": "Benötigen Sie eine Verlängerung der Testphase?",
  "app.trialPeriod": "Testzeitraum",
  "app.tryAgain": "Erneut versuchen",
  "app.tryLateOrContactSupport": "Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundensupport unter hello@vacationtracker.io mit der folgenden ID: {code}",
  "app.tuesday": "Dienstag",
  "app.tuesday.short": "Di",
  "app.type": "Typ",
  "app.unknown": "<Unbekannt>",
  "app.unlimited": "Unbegrenzt",
  "app.update": "Aktualisieren",
  "app.updated": "aktualisiert",
  "app.updatedInProgress": "Antwort wird verarbeitet",
  "app.updatedSuccessfully": "Anfrage eingereicht.",
  "app.updating": "Aktualisierung läuft",
  "app.upload": "Hochladen",
  "app.user": "Benutzer",
  "app.users": "Benutzer",
  "app.users.role": "Rolle",
  "app.via": "über",
  "app.view": "Ansehen",
  "app.viewAll": "Alle ansehen",
  "app.visitHelpdesk": "Helpdesk besuchen",
  "app.wallchart": "Wandplan",
  "app.was": "war",
  "app.watchVideo": "Video ansehen",
  "app.wednesday": "Mittwoch",
  "app.wednesday.short": "Mi",
  "app.weekly": "Wöchentlich",
  "app.willBe": "wird sein",
  "app.workday": "Länge des Arbeitstages",
  "app.workdayWarning": "Die Option für halbtägigen Urlaub ist nur für einen 8-Stunden-Arbeitstag verfügbar. Wenn Sie den Standardarbeitstag von 8 Stunden ändern, wird diese Option deaktiviert.",
  "app.workweek": "Arbeitswoche",
  "app.year": "Jahr",
  "app.years": "Jahre",
  "app.yes": "Ja",
  "automation.feedback.thankYou": "Vielen Dank, dass Sie Ihre Gedanken mit uns teilen. Ihr Feedback treibt unser ständiges Wachstum an.",
  "automations.activateInProgress": "In Bearbeitung - Aktivierung",
  "automations.activateInProgressDescription": "Ihre Anfrage zur Aktivierung der Automatisierung wird derzeit bearbeitet. Wir arbeiten daran, diese Funktion für Sie zu aktivieren.",
  "automations.add": "Automatisierung erstellen",
  "automations.addAddonsDescirptionForAdmin": "Möchten Sie mit dem Kauf des ausgewählten Add-ons fortfahren? Der Betrag wird anteilig mit ${prorateAmount} berechnet, wobei Ihre nächste Rechnung insgesamt ${nextInvoiceAmount} betragen wird. Klicken Sie auf 'Bestätigen', um abzuschließen.",
  "automations.addAddonsDescirptionForAdminInTrial": "Dieses Add-on wird auf Ihrer nächsten Rechnung berechnet: ${total} basierend auf {userCount} Benutzern zu ${addonsPrice} pro Add-on. Während der Testphase können Sie es kostenlos aktivieren und deaktivieren, wenn am Ende keine Add-ons aktiv sind.",
  "automations.addAddonsDescirptionForApprover": "Nur Benutzer mit Administratorrechten können Add-ons kaufen oder abbestellen. Wenn Änderungen an Ihren Add-on-Abonnements erforderlich sind, wenden Sie sich bitte an Ihren Administrator.",
  "automations.addFreeAddonsDescirptionForAdmin": "Dieses Add-on ist in Ihrem aktuellen Plan ohne zusätzliche Kosten enthalten. Sie können es während Ihres aktuellen Planabonnements ohne zusätzliche Gebühren frei aktivieren und deaktivieren.",
  "automations.addpnSuccessfullyAdded": "Abgeschlossen - Aktivierung",
  "automations.addpnSuccessfullyAddedDescription": "Die Automatisierung wurde erfolgreich aktiviert. Sie können nun alle ihre Funktionen und Eigenschaften nutzen.",
  "automations.addpnSuccessfullyDeactivated": "Abgeschlossen - Deaktivierung",
  "automations.addpnSuccessfullyDeactivatedDescription": "Die Automatisierung wurde erfolgreich deaktiviert. Sie wird in Ihrem System nicht mehr aktiv sein.",
  "automations.ADP_INTEGRATION": "ADP-Integration",
  "automations.anythingMissing": "Fehlt etwas?",
  "automations.automationBlackoutPeriodDeleted": "Sperrzeit gelöscht",
  "automations.automationEntitlementByRoleDeleted": "Anspruch nach Rolle gelöscht",
  "automations.automationProbationPeriodDeleted": "Probezeit gelöscht",
  "automations.automationSeniorityEntitlementDeleted": "Dienstaltersanspruch gelöscht",
  "automations.automationType": "Automatisierungstyp",
  "automations.BAMBOOHR_INTEGRATION": "BambooHR-Integration",
  "automations.BLACKOUT_PERIOD": "Sperrzeit",
  "automations.blackoutPeriod.autoRejectReasonDefault": "Während unserer aktuellen Hochphase ist unser gesamtes Team für die Aufrechterhaltung des Betriebs unerlässlich. Leider können wir keine Freizeit genehmigen, da dies unsere Geschäftsziele und Kundenverpflichtungen beeinträchtigen würde.",
  "automations.blackoutPeriod.createInProgress": "Erstellung der Sperrzeit in Bearbeitung",
  "automations.blackoutPeriod.createInProgressCompleted": "Erstellung der Sperrzeit abgeschlossen",
  "automations.blackoutPeriod.updateInProgress": "Aktualisierung der Sperrzeit in Bearbeitung",
  "automations.blackoutPeriod.updateInProgressCompleted": "Aktualisierung der Sperrzeit abgeschlossen",
  "automations.blackoutPeriodFromTo": "Sperrzeit {fromDate} <endDate>bis {toDate}</endDate>",
  "automations.cantOpenAutomations": "Diese Automatisierung wurde von einem anderen Benutzer erstellt und Sie können sie nicht bearbeiten.",
  "automations.createAutomation": "Automatisierung erstellen",
  "automations.datePeriod": "{fromDate} <endDate>und {toDate}</endDate><recurring> jedes Jahr</recurring>",
  "automations.deactivateInPgoress": "In Bearbeitung - Deaktivierung",
  "automations.deactivateInPgoressDescription": "Ihre Anfrage zur Deaktivierung der Automatisierung wird gerade bearbeitet. Bitte warten Sie, während wir Ihre Einstellungen aktualisieren",
  "automations.deactivateVisibilityWarn": "Das Deaktivieren einer Automatisierung wird das Abonnement nicht kündigen. Wenn Sie das Abonnement beenden möchten, müssen Sie auf die Schaltfläche \"Abbestellen\" klicken.",
  "automations.deleteInProgress": "Löschen der {automationName} Automatisierung",
  "automations.departmentVisibilityLevelInfo": "Benutzer sehen Urlaubsinformationen nur innerhalb ihrer eigenen Abteilung. Genehmiger sehen Urlaubsinformationen innerhalb ihrer eigenen Abteilung und jeder Abteilung, für die sie Genehmiger sind, auch wenn sie keine Mitglieder sind. Administratoren sehen immer alles.",
  "automations.discoveryMoreAddons": "Entdecken Sie weitere Add-ons",
  "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Urlaubsbeschränkung für neue Mitarbeiter",
  "automations.emptyViewButton": "Verfügbare Add-ons anzeigen",
  "automations.emptyViewMessage": "Fügen Sie PTO-Tage basierend auf der Betriebszugehörigkeit hinzu, erstellen Sie Sperrzeiten, automatisieren Sie den Genehmigungs- oder Ablehnungsprozess und vieles mehr mit unseren Automatisierungen, Add-ons und Integrationen.",
  "automations.emptyViewTitle": "Automatisieren Sie Ihre zeitaufwendigen Aufgaben und integrieren Sie externe Systeme",
  "automations.ENTITLEMENT_BY_ROLE": "Anspruch nach Rolle",
  "automations.entitlementByRole.createInProgress": "Erstellung des Anspruchs nach Rolle in Bearbeitung",
  "automations.entitlementByRole.createInProgressCompleted": "Erstellung des Anspruchs nach Rolle abgeschlossen",
  "automations.entitlementByRole.days": "Tage",
  "automations.entitlementByRole.updateInProgress": "Aktualisierung des Anspruchs nach Rolle in Bearbeitung",
  "automations.entitlementByRole.updateInProgressCompleted": "Aktualisierung des Anspruchs nach Rolle abgeschlossen",
  "automations.LEAVE_DURATION_LIMIT": "Urlaubsdauerbegrenzung",
  "automations.message": "Nachricht",
  "automations.messagePlaceholder": "Ihre Nachricht",
  "automations.myAddons": "Meine Add-ons",
  "automations.OPEN_API": "Offene API",
  "automations.OPEN_API_DOCUMENTATION": "Offene API-Dokumentation",
  "automations.period": "Zeitraum",
  "automations.PROBATION_PERIOD": "Probezeit",
  "automations.probationPeriod.autoRejectReasonDefault": "Ihr Urlaubsantrag wurde automatisch abgelehnt, da Sie sich in Ihrer frühen Beschäftigungsphase befinden, einer Zeit, in der kein Urlaub erlaubt ist. Diese Richtlinie hilft neuen Mitarbeitern, sich vollständig in ihre neuen Rollen und Teamdynamiken einzufinden und anzupassen.",
  "automations.probationPeriod.createInProgress": "Erstellung der Probezeit in Bearbeitung",
  "automations.probationPeriod.createInProgressCompleted": "Erstellung der Probezeit abgeschlossen",
  "automations.probationPeriod.lengthDays": "Dauer (Tage)",
  "automations.probationPeriod.name": "Name der Probezeit-Richtlinie",
  "automations.probationPeriod.namePlaceholder": "Geben Sie den Namen der Probezeit-Richtlinie ein",
  "automations.probationPeriod.settings.applyToMDescription": "Sie können wählen, ob Sie diese Automatisierung universell für alle Benutzer anwenden möchten oder sie basierend auf spezifischen Abteilungen, Standorten oder Etiketten anpassen möchten.",
  "automations.probationPeriod.settings.applyToMDescriptionTwo": "Sie können auch einen oder mehrere Urlaubstypen auswählen, auf die Sie diese Automatisierung anwenden möchten.",
  "automations.probationPeriod.settings.lengthDaysDescription": "Geben Sie die Anzahl der Tage ein, für die neuen Mitarbeitern bestimmte Arten von Urlaub untersagt sind, gerechnet ab ihrem Einstellungsdatum.",
  "automations.probationPeriod.settings.nameDescription": "Dieser Abschnitt ermöglicht es Ihnen, eine Richtlinie festzulegen, die Neueinstellungen während ihrer anfänglichen Beschäftigungszeit von der Urlaubsnahme abhält.",
  "automations.probationPeriod.settings.nameDescriptionThree": "Stellen Sie sicher, dass Benutzer eine automatische Nachricht erhalten, die erklärt, warum ihr Urlaubsantrag abgelehnt wurde.",
  "automations.probationPeriod.settings.nameDescriptionTwo": "Geben Sie die Anzahl der Tage ein, und wir berechnen die Probezeit basierend auf dem Einstellungsdatum jedes Benutzers.",
  "automations.probationPeriod.settings.nameTitle": "Richtlinie definieren",
  "automations.probationPeriod.updateInProgress": "Aktualisierung der Probezeit in Bearbeitung",
  "automations.probationPeriod.updateInProgressCompleted": "Aktualisierung der Probezeit abgeschlossen",
  "automations.probationPeriodMessages": "Ihre Probezeit dauert bis {date}",
  "automations.removeAddons": "Add-ons entfernen",
  "automations.removeAddonsDescirptionForAdmin": "Sind Sie sicher, dass Sie dieses Add-on abbestellen möchten? Sie behalten den Zugriff auf alle seine Funktionen bis zum Ende Ihrer aktuellen Abrechnungsperiode. Danach wird es deaktiviert, was sich entsprechend auf Ihre Urlaubsverwaltungseinstellungen auswirkt.",
  "automations.removeAddonsDescirptionForApprover": "Nur Benutzer auf Administratorebene haben die Möglichkeit, Add-ons zu kaufen oder abzubestellen. Wenn Anpassungen an Ihren Add-on-Abonnements erforderlich sind, wenden Sie sich bitte an Ihren Administrator.",
  "automations.requestAnAutomation": "Ein Add-On anfordern",
  "automations.requestAutomationTitle": "Können Sie ein benötigtes Add-On nicht finden?",
  "automations.reSubscribeAddons": "Add-on erneut abonnieren",
  "automations.reSubscribeAddonsDescirptionForAdmin": "Sind Sie sicher, dass Sie dieses Add-on erneut abonnieren möchten? Ab Ihrer nächsten Rechnung werden Ihnen ${addonsPrice} pro Benutzer pro Monat in Rechnung gestellt. Die Gebühr für den ersten Monat wird anteilig basierend auf der Anzahl der Tage berechnet, an denen das Add-on verwendet wird.",
  "automations.reSubscribeAddonsDescirptionForAdminInTrial": "Sind Sie sicher, dass Sie dieses Add-on erneut abonnieren möchten? Es bleibt bis zum Ende der Testperiode kostenlos. Danach fällt eine Gebühr von ${addonsPrice} pro Benutzer pro Monat an.",
  "automations.reSubscribeInfo": "Erneuern Sie Ihr Add-on-Abonnement und nutzen Sie weiterhin unsere erweiterten Funktionen. Klicken Sie auf Erneut abonnieren, um fortzufahren.",
  "automations.SENIORITY_ENTITLEMENT": "Dienstaltersberechtigung",
  "automations.seniorityEntitlement.createInProgress": "Erstellung der Dienstaltersberechtigung in Bearbeitung",
  "automations.seniorityEntitlement.createInProgressCompleted": "Erstellung der Dienstaltersberechtigung abgeschlossen",
  "automations.seniorityEntitlement.periodsViewTable": "{days} Tage nach {years} Jahren",
  "automations.seniorityEntitlement.updateInProgress": "Aktualisierung der Dienstaltersberechtigung in Bearbeitung",
  "automations.seniorityEntitlement.updateInProgressCompleted": "Aktualisierung der Dienstaltersberechtigung abgeschlossen",
  "automations.showCaneledAddons": "Stornierte Add-ons anzeigen",
  "automations.stepByStepHelpDesk": "Für eine detaillierte Anleitung zur Einrichtung Ihrer <automationName></automationName> Automatisierung, konsultieren Sie bitte die Schritt-für-Schritt-Anweisungen auf <link>der Helpdesk-Seite</link>.",
  "automations.subscripteToAddons": "Add-ons abonnieren",
  "automations.TEAM_LEAVE_BALANCING": "Team-Urlaubsausgleich",
  "automations.unsubscribeForFreeAddonsInfo": "Sie haben dieses Add-on derzeit ohne zusätzliche Kosten abonniert, da es in Ihrem Plan enthalten ist. Wenn Sie es abbestellen möchten, klicken Sie auf die Schaltfläche 'Abbestellen' auf der rechten Seite.",
  "automations.unsubscribeInfo": "Sie haben dieses Add-on derzeit für ${addonsPrice} pro Benutzer abonniert. Wenn Sie es abbestellen möchten, klicken Sie auf die Schaltfläche 'Abbestellen' auf der rechten Seite.",
  "automations.unsubscripteAddons": "Add-on abbestellen?",
  "automations.update": "Automatisierungen aktualisieren",
  "automations.VISIBILITY": "Sichtbarkeitsmanager",
  "automations.visibility.createInProgress": "Einschränkung der Sichtbarkeit in Bearbeitung",
  "automations.visibility.createInProgressCompleted": "Einschränkung der Sichtbarkeit abgeschlossen",
  "automations.visibility.updateInProgress": "Aktualisierung des Sichtbarkeits-Add-ons",
  "automations.visibility.updateInProgressCompleted": "Aktualisierung des Sichtbarkeits-Add-ons abgeschlossen",
  "automations.visibilityForm.DEPARTMENT": "Ihre Abteilung",
  "automations.visibilityForm.limitVisibility": "Sichtbarkeit für Benutzer einschränken",
  "automations.visibilityForm.visibilityLevel": "Benutzer können sehen",
  "automations.visibilityForm.visibilityTypeValidationRequired": "Sie müssen mindestens einen Sichtbarkeitstyp auswählen",
  "automations.visibilityInfo.settings.nameTitle": "Sichtbarkeitseinstellungen",
  "automations.visibilityManagerNotificationsInfo": "Geteilte Kalender und Benachrichtigungen, die vor der Änderung der Sichtbarkeitseinstellungen erstellt wurden, müssen manuell gelöscht werden, wenn sie nicht den neuen Regeln entsprechen.",
  "automationsForm.advancedFilter.tooltipInfo": "Sie können die Benutzer filtern, auf die diese Automatisierung angewendet wird, basierend auf Abteilungen, Standorten oder Etiketten.",
  "automationsForm.applyToAllUsers": "Auf alle Benutzer anwenden?",
  "automationsForm.applyToAllUsersTooltip": "Bitte wählen Sie, ob Sie diese Automatisierung universell auf alle Benutzer anwenden oder sie basierend auf bestimmten Abteilungen, Standorten oder Etiketten anpassen möchten.",
  "automationsForm.autoRejectReason": "Automatische Ablehnungsantwort",
  "automationsForm.autoRejectReasonTooltip": "Geben Sie dem Benutzer eine automatische Antwort, warum seine Urlaubsanfrage während dieser Zeit abgelehnt wurde.",
  "automationsForm.blackoutPeriodShortText": "Ein Zeitraum, in dem es Mitarbeitern untersagt ist, Urlaub zu nehmen",
  "automationsForm.period": "Zeitraum",
  "automationsForm.recurring": "Jährlich wiederkehrend",
  "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "Möchten Sie diese Dienstaltersberechtigung auf alle Benutzer in Ihrem Unternehmen oder auf Benutzer an einem bestimmten Standort anwenden? Wenn Sie unterschiedliche Dienstaltersberechtigungsregeln an verschiedenen Standorten haben, müssen Sie mehrere Dienstaltersberechtigungs-Automatisierungsregeln erstellen, eine für jeden Standort.",
  "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Beginn des Geschäftsjahres",
  "automationsForm.seniorityEntitlement.employeeStartDate": "Eintrittsdatum des Mitarbeiters",
  "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "Ende des Geschäftsjahres",
  "billing.applyingCoupon": "Gutschein wird angewendet",
  "billing.applyingCouponAndResubscribing": "Gutschein wird angewendet & Abonnement wird erneuert",
  "billing.cancelSubscription": "Abonnement kündigen",
  "billing.cancelTrial": "Ihre Testphase bleibt bis zum {stripeCurrentPeriodEnd} aktiv. Um Vacation Tracker weiterhin zu nutzen, geben Sie bitte Ihre Zahlungsinformationen ein.",
  "billing.cancelTrialButton": "Testphase beenden",
  "billing.cancelUpdateInProgress": "Wird storniert...",
  "billing.linkPaymentMethod": "Stripe-Verknüpfung",
  "billing.microsoft.billedLabel": "Abgerechnet",
  "billing.microsoft.billingCycle": "Aktueller Abrechnungszeitraum endet am {date}",
  "billing.microsoft.billingCycleEnds": "Ihr Abonnement wird nach Ende des Abrechnungszeitraums gekündigt und Sie können Vacation Tracker nicht mehr nutzen.",
  "billing.microsoft.billingCycleLabel": "Abrechnungszeitraum",
  "billing.microsoft.buyLessLicensesConfirmModalContent": "Sie haben {currentNoOfLicenses} Lizenzen. Sie sind dabei, {additionalLicences} zu entfernen. Sind Sie sicher?",
  "billing.microsoft.buyMoreLicensesConfirmModalContent": "Sie haben {currentNoOfLicenses} Lizenzen. Sie sind dabei, {additionalLicences} weitere zu kaufen. Sind Sie sicher?",
  "billing.microsoft.canceledSubscriptionMessage": "Ihr Abonnement wird in ca. 15 Minuten gekündigt",
  "billing.microsoft.changePlanModalTitle": "Aktuellen <strong>{plan}</strong> Plan ändern",
  "billing.microsoft.info": "Ihre Zahlungsinformationen werden von Microsoft verwaltet. Besuchen Sie das <adminCenterLink>Admin Center</adminCenterLink>, um Ihre {labelInfo} zu aktualisieren",
  "billing.microsoft.licensesSideActionLabel": "Verlauf anzeigen",
  "billing.microsoft.licensesTitle": "Lizenzen",
  "billing.microsoft.pricingPlanLabel": "Preisplan",
  "billing.microsoft.switchPlanModal.confirm": "Bitte bestätigen Sie, dass Sie zu diesem Plan wechseln möchten.",
  "billing.paymentInformation": "Zahlungsinformationen",
  "billing.paymentMethod": "Zahlungsmethode",
  "billing.pricingPlanPeriodAnnual": "Jährlich",
  "billing.pricingPlanPeriodMonthly": "Monatlich",
  "billing.pricingPlansText": "Dieser Abschnitt zeigt Ihren Abonnementplan an. Sie können den Plan, den Sie zuvor hatten, erneut abonnieren oder den Plan vor dem erneuten Abonnieren ändern.",
  "billing.pricingPlansTitle": "Preispläne",
  "billing.promoCodeErrorDescription": "Der Promo-Code {code} ist nicht gültig.",
  "billing.resubscribeCTA": "Erneut abonnieren",
  "billing.resubscribingUpdateInProgress": "Erneutes Abonnieren...",
  "billing.seats": "Plätze",
  "billing.seats.buyLessSeatsConfirmModalContent": "Sie haben {currentNoOfSeats} Plätze. Sie sind dabei, {additionalSeats} zu entfernen.",
  "billing.seats.buyMoreSeatsConfirmModalContent": "Sie haben {currentNoOfSeats} Plätze. Sie sind dabei, {additionalSeats} weitere zu kaufen.",
  "billing.seats.canNotDowngrade": "Sie können die Anzahl Ihrer Plätze ({currentNumberOfSeats}) nicht reduzieren, ohne einen oder mehrere derzeit aktive Benutzer zu deaktivieren.",
  "billing.seats.info": "Die Anzahl der Plätze, die Sie gekauft haben.",
  "billing.seats.loadingUpcomingPaymentInformation": "Lade kommende Zahlungsinformationen...",
  "billing.seats.manage": "Plätze verwalten",
  "billing.seats.noBillingChange": "Sie sind dabei, {noOfSeats} {noOfSeats, plural, =1 {Platz} other {Plätze}} zu kaufen. Ihre Abrechnung wird nicht beeinflusst.",
  "billing.seats.warningOfProrateAdditional": "Eine geschätzte anteilige Gebühr von {proratedAmount} (ohne MwSt.) wird heute berechnet.",
  "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "Das Hinzufügen von {noOfSeats} {noOfSeats, plural, =1 {Platz} other {Plätzen}} erhöht Ihre nächste Rechnung auf {amount} (ohne MwSt.) ab {startDate}.",
  "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "Der Kauf von {noOfSeats} {noOfSeats, plural, =1 {Platz} other {Plätzen}} wird Ihre Abrechnung beeinflussen. Ihre nächste Rechnung beträgt {amount} (ohne MwSt.) am {startDate}",
  "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "Das Entfernen von {noOfSeats} {noOfSeats, plural, =1 {Platz} other {Plätzen}} wird Ihre nächste Rechnung auf {amount} (ohne MwSt.) ab {startDate} reduzieren.",
  "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "Das Entfernen von {noOfSeats} {noOfSeats, plural, =1 {Platz} other {Plätzen}} wird Ihre Abrechnung beeinflussen. Ihre nächste Rechnung beträgt {amount} (ohne MwSt.) am {startDate}",
  "billing.subscriptionCanceled": "Abonnement gekündigt",
  "billing.subscriptionExpired": "Abonnement nicht aktiv",
  "billing.subscriptionExpiredDescription": "Ihr Abonnement ist gekündigt oder abgelaufen. Aktualisierungen sind derzeit nicht möglich.",
  "billing.subscriptionExpiredText": "Ihr Abonnement ist abgelaufen. Abonnieren Sie erneut, um Vacation Tracker weiterhin zu nutzen. Bei Problemen oder Fragen kontaktieren Sie bitte unseren {supportLink}.",
  "billing.SubscriptionPlan": "Abonnementplan",
  "billing.subscriptionUpdateInProgress": "Abonnement-Aktualisierung in Bearbeitung",
  "billing.subscriptionUpdateInProgressDescription": "Der vorherige Vorgang ist noch in Bearbeitung. Bitte versuchen Sie es in einer Minute erneut.",
  "billing.updateBillingInfo": "Abrechnungsinformationen aktualisieren",
  "billing.updatedFaild": "Aktualisierung der Abrechnungsinformationen fehlgeschlagen",
  "billing.updatedSuccessfully": "Abrechnungsinformationen wurden aktualisiert",
  "billing.updateInProgress": "Abrechnungsinformationen werden aktualisiert",
  "bot.announce.slack": "Hallo <@{slackUserId}>\n\nMein Name ist Vacation Tracker. Ich helfe Ihnen, Urlaub über Slack zu buchen und halte alle über bevorstehende Abwesenheiten in Ihrer Organisation auf dem Laufenden.\n\nGeben Sie `/vacation` irgendwo in Slack ein, um zu beginnen, oder sehen Sie sich mein YouTube-Tutorial an, um mehr zu erfahren:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.announce.slack.info": "Hallo <@{slackUserId}>, mein Name ist Vacation Tracker.",
  "bot.announce.slackCompany": "Hallo Team,\n\nMein Name ist Vacation Tracker Bot!\n\nIch ermögliche es Ihnen, Ihren Urlaub über Slack zu buchen und halte alle über die bevorstehenden Abwesenheiten ihrer Teammitglieder auf dem Laufenden. Lassen Sie uns mit der Urlaubsverfolgung beginnen!\n\nGeben Sie `/vacation` irgendwo in Slack ein oder sehen Sie sich das YouTube-Tutorial an, um mehr zu erfahren:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.message.about.commandDescription": "Vacation Tracker vereinfacht den Prozess der Urlaubsanfrage und -buchung in Ihrer Organisation. Teammitglieder können jede Art von Urlaub oder bezahlten/unbezahlten freien Tag buchen, ohne Slack zu verlassen und ohne sich ein weiteres Login oder Passwort merken zu müssen.\n\nRichten Sie benutzerdefinierte Urlaubstypen ein und lassen Sie jede Person in Ihrer Organisation die Kontrolle über ihre freie Zeit haben. Vacation Tracker bietet jedem Teammitglied Zugriff auf das Dashboard (http://app.vacationtracker.io), wo sie Urlaub beantragen, ihren aktuellen Urlaubsstatus oder -verlauf überprüfen und geplante Abwesenheiten für ihr gesamtes Team einsehen können.\n\nEntsprechend den Einstellungen des Administrators oder Genehmigenden erhalten Teammitglieder tägliche/wöchentliche Benachrichtigungen über bevorstehende Abwesenheiten. Kein Ärger und keine unangenehmen Überraschungen.\n\nMöchten Sie mehr über Vacation Tracker erfahren? Unser <https://vacationtracker.crisp.help/en/|Helpdesk> ist Ihre zweitbeste Anlaufstelle nach der <https://vacationtracker.io/request-a-demo/|Demo>.\n\nWenn Ihre Fragen oder Anliegen unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen, ist das Beste an unserem Tag: hello@vacationtracker.io",
  "bot.message.accessExpired": "Oh nein! Ihr Zugang zu Vacation Tracker ist abgelaufen. Bitte kontaktieren Sie Ihren Administrator für weitere Informationen.",
  "bot.message.accruedDaysInfoForApprover": "ℹ️ Diese Urlaubsanfrage basiert auf zukünftig angesammelten freien Tagen, nicht auf dem aktuellen Stand. <@{name}> hat heute noch {amount} Tag(e) übrig. Sie werden am {earningDate} {accrued} ansammeln.",
  "bot.message.accruedDaysInfoForUser": "ℹ️ Ihre Urlaubsanfrage basiert auf zukünftig angesammelten freien Tagen, nicht auf dem aktuellen Stand. Sie haben heute noch {amount} Tag(e) übrig und werden am {earningDate} {accrued} ansammeln.",
  "bot.message.approveLeave": "Urlaub genehmigen",
  "bot.message.approveLeaves.approvedRequest": "✅ Sie haben die Anfrage genehmigt. Eine Benachrichtigung wird an <@{userSlackId}> gesendet, um sie zu informieren.",
  "bot.message.approveLeaves.deniedRequest": "❌ Sie haben die Anfrage abgelehnt. Eine Benachrichtigung wird an <@{userSlackId}> gesendet, um sie zu informieren.",
  "bot.message.approver": "*Genehmiger:*\n<@{approverSlackId}>",
  "bot.message.approver.autoApproved": "*Genehmiger:*\nAutomatisch genehmigt",
  "bot.message.approveToil": "TOIL genehmigen",
  "bot.message.backToOptions": "Zurück zu den Optionen",
  "bot.message.blackoutPeriod": "*Sperrfrist*: {startDate} - {endDate} \n \n_{message}_",
  "bot.message.couldNotProcessRequest": "Oh je! Wir konnten Ihre Anfrage nicht verarbeiten.",
  "bot.message.date": "*Datum*",
  "bot.message.dateEdited": "*Datum [Bearbeitet]*",
  "bot.message.dates": "*Daten*",
  "bot.message.datesEdited": "*Daten [Bearbeitet]*",
  "bot.message.deniedLeave": "Abgelehnte Freistellung",
  "bot.message.deniedLeaveDate": "*Datum*\n{leaveDate}",
  "bot.message.deniedLeaveDates": "*Daten*\n{leaveDate}",
  "bot.message.deniedLeaveLeaveType": "*Freistellungsart:*\n{leaveTypeName}",
  "bot.message.deniedLeaveWithReason": "Hallo, <@{userSlackId}> hat folgende Freistellungsanfrage eingereicht:",
  "bot.message.deniedToil": "Abgelehntes TOIL",
  "bot.message.error.endDateCantBeInPast": "Das Enddatum kann nicht vor dem Start liegen. Bitte wählen Sie ein Datum in der Zukunft",
  "bot.message.error.endTimeCantBeBeforeStartTime": "Es sieht so aus, als wäre die von Ihnen gewählte Startzeit nach der Endzeit.*\n *Bitte wählen Sie eine Startzeit, die vor der Endzeit liegt.",
  "bot.message.error.leaveRequestOverlap": "Die von Ihnen gewählten Daten überschneiden sich mit einer bestehenden Freistellung.*\n *Bitte wählen Sie einen anderen Zeitraum.",
  "bot.message.error.negativeBallanceNotAllowed": "Negativer Saldo ist für diese Freistellungsart nicht erlaubt.",
  "bot.message.error.oneHoureIntervalNotAllowed": "Das kürzeste Zeitintervall, das Sie für diese Freistellungsart beantragen können, beträgt 4 Stunden (halber Tag).",
  "bot.message.error.partTimeIsntAllowed": "Teilzeit ist nicht erlaubt",
  "bot.message.error.reasonRequired": "Das Feld 'Grund' ist erforderlich.",
  "bot.message.error.requestedMoreThanFullDay": "Sie haben mehr als einen vollen Tag Freistellung an einem Arbeitstag beantragt.*\n *Bitte wählen Sie einen anderen Tag.",
  "bot.message.error.startDateCantBeInPast": "Das Startdatum kann nicht in der Vergangenheit liegen. Bitte wählen Sie ein Datum in der Zukunft",
  "bot.message.error.userCannotBeFound": "Der ausgewählte Benutzer konnte in Ihrer Organisation nicht gefunden werden.*\n *Bitte kontaktieren Sie Ihren Organisationsadministrator oder den Vacation Tracker Support für weitere Informationen.",
  "bot.message.error.userEndDateInFuture": "Die angeforderten Daten liegen nach dem Beschäftigungsende des Benutzers.*\n *Bitte kontaktieren Sie Ihren Organisationsadministrator oder den Vacation Tracker Support für weitere Informationen.",
  "bot.message.error.userStartDateInFuture": "Die angeforderten Daten liegen vor dem Beschäftigungsbeginn des Benutzers. Bitte kontaktieren Sie Ihren Organisationsadministrator oder den Vacation Tracker Support für weitere Informationen.",
  "bot.message.error.zeroDaysLeaveRequest": "Sie haben einen Feiertag oder einen arbeitsfreien Tag ausgewählt.*\n *Bitte wählen Sie einen gültigen Datumsbereich.",
  "bot.message.feedback.chooseAnOption": "Wählen Sie eine Option...",
  "bot.message.feedback.form": "Feedback-Formular",
  "bot.message.feedback.haveBeenSend": "Feedback wurde gesendet",
  "bot.message.feedback.howDoYouLikeOurApp": "Wie gefällt Ihnen unsere App?",
  "bot.message.feedback.info": "Geben Sie uns Feedback :heart:",
  "bot.message.feedback.itMakesMeCry": "Es bringt mich zum Weinen 😢",
  "bot.message.feedback.itsOk": "Es ist okay 😐",
  "bot.message.feedback.likeIt": "Ich mag es 😀",
  "bot.message.feedback.loveIt": "Ich liebe es 😍",
  "bot.message.feedback.message": "Feedback-Nachricht",
  "bot.message.feedback.nah": "Nee 🙁",
  "bot.message.feedback.response": "Wenn Ihr Feedback eine Antwort erfordert, geben Sie bitte Ihre E-Mail-Adresse ein, damit wir Sie kontaktieren können.",
  "bot.message.feedback.thanks": "Danke für Ihr Feedback! Sie können uns jederzeit unter support@vacationtracker.io kontaktieren.",
  "bot.message.feedback.youEmailAddress": "Ihre E-Mail-Adresse",
  "bot.message.genericError": "Oh je! Ein unerwarteter Fehler ist aufgetreten. 😱 Wenn dieses Problem weiterhin besteht, kontaktieren Sie bitte den Support und geben Sie ihnen den folgenden Fehlercode: {requestId}.",
  "bot.message.goToTheMyProfileTab": "Gehen Sie zum Mein Profil-Tab",
  "bot.message.helpMessage": "Hallo, \nMit Vacation Tracker können Sie eine der folgenden Aktionen ausführen:\n- Senden Sie **Urlaub beantragen** in einer Direktnachricht, um einen neuen Urlaub über den Microsoft Teams-Bot zu beantragen.\n- Senden Sie **TOIL beantragen** in einer Direktnachricht, um ein neues TOIL über den Microsoft Teams-Bot zu beantragen.\n- Besuchen Sie den **Mein Profil**-Tab, um Ihre persönlichen Informationen wie Ihre bevorstehenden Urlaube und Feiertage sowie Ihren Urlaubsverlauf zu sehen.\n- Heften Sie den **Übersicht**-Tab an einen Ihrer Kanäle, um die Teamübersicht einschließlich bevorstehender Urlaube und Feiertage für Ihr Team zu sehen.\n- Besuchen Sie das [**Vacation Tracker Web Dashboard ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard).\nFür weitere Hilfe und Tutorials besuchen Sie unser [**Helpdesk ⧉**](https://vacationtracker.crisp.help/en/). Sie können auch eine persönliche Demo von Vacation Tracker unter diesem Link planen: [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
  "bot.message.holidays": "*Feiertage:*",
  "bot.message.leaveDate": "*Datum:*\n{leaveDate}",
  "bot.message.leaveDateEdited": "*Datum [Bearbeitet]:*\n{leaveDate}",
  "bot.message.leaveDates": "*Daten:*\n{leaveDate}",
  "bot.message.leaveDatesEdited": "*Daten [Bearbeitet]:*\n{leaveDate}",
  "bot.message.leaveDuration1": "*{word} beantragt für das laufende Jahr:*\n{requestedThisYearNumber}",
  "bot.message.leaveDuration2": "*{word} verbleibend für das laufende Jahr:*\n{currentYearNumber}",
  "bot.message.leaveDuration2Unlimited": "*{word} verbleibend für das laufende Jahr:*\nUnbegrenzt",
  "bot.message.leaveDuration3": "*{word} beantragt für das nächste Jahr:*\n{requestedNextYearNumber}",
  "bot.message.leaveDuration4": "*{word} verbleibend für das nächste Jahr:*\n{nextYearNumber}",
  "bot.message.leaveDuration4Unlimited": "*{word} verbleibend für das nächste Jahr:*\nUnbegrenzt",
  "bot.message.leaveRemaining": "Verbleibender Urlaub",
  "bot.message.leaveRemainingCurrent": "Verbleibender Urlaub (Aktuelles Jahr)",
  "bot.message.leaveRemainingDays": "Verbleibende Urlaubstage",
  "bot.message.leaveRemainingDaysCurrent": "Verbleibende Urlaubstage (Aktuelles Jahr)",
  "bot.message.leaveRemainingDaysNext": "Verbleibende Urlaubstage (Folgendes Jahr)",
  "bot.message.leaveRemainingHours": "Verbleibende Urlaubsstunden",
  "bot.message.leaveRemainingHoursNext": "Verbleibende Urlaubsstunden (Folgendes Jahr)",
  "bot.message.leaveRemainingNext": "Verbleibender Urlaub (Folgendes Jahr)",
  "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
  "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Halber Tag)",
  "bot.message.leaveRequest.halfDay": "(Halber Tag)",
  "bot.message.leaveRequest.hourly": "(Stündlich)",
  "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
  "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (Stündlich)",
  "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Stündlich)",
  "bot.message.leaveRequestCreated.error": "Urlaubsantrag kann nicht eingereicht werden",
  "bot.message.leaveRequestCreated.errorBadTime": "❌ Urlaubsantrag kann nicht eingereicht werden, da er länger als die Arbeitszeit ist oder das Zeitintervall ungültig ist.",
  "bot.message.leaveRequestCreated.errorDateAfter": "❌ Urlaubsantrag kann nicht eingereicht werden, da das von Ihnen gewählte Startdatum nach dem Enddatum liegt. Stellen Sie sicher, dass das Startdatum vor dem Enddatum liegt, und reichen Sie den Antrag erneut ein.",
  "bot.message.leaveRequestCreated.errorDateInThePast": "❌ Urlaubsantrag kann nicht eingereicht werden, da das von Ihnen gewählte Startdatum in der Vergangenheit liegt. Stellen Sie sicher, dass die gewählten Daten in der Zukunft liegen, und reichen Sie den Antrag erneut ein.",
  "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ Urlaubsantrag kann nicht eingereicht werden, da Sie kein Enddatum ausgewählt haben oder das Datum ungültig ist.",
  "bot.message.leaveRequestCreated.errorNoStartDate": "❌ Urlaubsantrag kann nicht eingereicht werden, da Sie kein Startdatum ausgewählt haben oder das Datum ungültig ist.",
  "bot.message.leaveRequestCreated.errorNoStartTime": "❌ Urlaubsantrag kann nicht eingereicht werden, da Sie keine Startzeit ausgewählt haben oder die Zeit ungültig ist.",
  "bot.message.leaveRequestCreated.errorReasonRequired": "❌ Urlaubsantrag kann nicht eingereicht werden, ein Grund ist erforderlich.",
  "bot.message.leaveRequested": "Urlaub beantragt",
  "bot.message.leaveRequestedCurrent": "Urlaub beantragt (Aktuelles Jahr)",
  "bot.message.leaveRequestedDays": "Urlaubstage beantragt",
  "bot.message.leaveRequestedDaysCurrent": "Urlaubstage beantragt (Aktuelles Jahr)",
  "bot.message.leaveRequestedDaysNext": "Urlaubstage beantragt (Folgendes Jahr)",
  "bot.message.leaveRequestedHours": "Urlaubsstunden beantragt",
  "bot.message.leaveRequestedHoursCurrent": "Urlaubsstunden beantragt (Aktuelles Jahr)",
  "bot.message.leaveRequestedHoursNext": "Urlaubsstunden beantragt (Folgendes Jahr)",
  "bot.message.leaveRequestedNext": "Urlaub beantragt (Folgendes Jahr)",
  "bot.message.leaveRequestNext.endDate": "Enddatum:",
  "bot.message.leaveRequestNext.info": "Großartig! 🐨 Wählen Sie den Zeitraum aus und reichen Sie den Antrag ein.",
  "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (Halber Tag)",
  "bot.message.leaveRequestNext.leaveTime": "Urlaubszeit:",
  "bot.message.leaveRequestNext.reasonOptional": "Grund (Optional)",
  "bot.message.leaveRequestNext.reasonRequired": "Grund (Erforderlich)",
  "bot.message.leaveRequestNext.startDate": "Startdatum:",
  "bot.message.leaveRequestNext.title": "Urlaub beantragen",
  "bot.message.leaves": "*Urlaub:*",
  "bot.message.leavesHistory": "Ihr Urlaubsverlauf:",
  "bot.message.leavesHistory.leaveMessageFullDay": "- Urlaub (*{leaveTypeName}*) von {start} bis {end} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOn": "- Urlaub (*{leaveTypeName}*) am {start} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOnPartDay": "- Urlaub (*{leaveTypeName}*) am {start} von {partDayStartHour} - {partDayEndHour} *[{leaveStatus}]*\n",
  "bot.message.leavesHistoryNot": "Hmm, es scheint, dass Sie noch keinen Urlaub genommen haben.",
  "bot.message.leaveTypeName": "*Urlaubsart:*\n{leaveTypeName}",
  "bot.message.leaveTypeWithHalfDay": "*Urlaubsart:*\n{leaveTypeName} (halber Tag)",
  "bot.message.manageLeaves.cancelRequest": "Anfrage stornieren",
  "bot.message.manageLeaves.confirmCancellation": "Bitte bestätigen Sie, dass Sie Ihren Urlaubsantrag stornieren möchten",
  "bot.message.manageLeaves.summary": "Hier ist eine Zusammenfassung Ihrer bevorstehenden Urlaube und ausstehenden Anträge.",
  "bot.message.manageLeaves.summaryNoLeaves": "Sie haben keine bevorstehenden geplanten Urlaube",
  "bot.message.manageLeaves.upcomingLeaves": "*Sie haben {upcomingLeaves} bestätigten Antrag:*",
  "bot.message.manageLeaves.upcomingLeavesPlural": "*Sie haben {upcomingLeaves} bestätigte Anträge:*",
  "bot.message.manageLeaves.waitingLeaves": "*Sie haben {waitingLeaves} ausstehenden Antrag:*",
  "bot.message.manageLeaves.waitingLeavesPlural": "*Sie haben {waitingLeaves} ausstehende Anträge:*",
  "bot.message.membersAway": "Abwesende Mitglieder",
  "bot.message.membersAway.holiday": "- *{holidayLocationName}* wird am {date} wegen *{holidayName}* abwesend sein.\n",
  "bot.message.membersAway.leaveMessage": "- *{user}* ist heute im Urlaub{leaveTypeName} {partDay}\n",
  "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* ist im Urlaub{leaveTypeName} vom {startDate} bis {endDate}\n",
  "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {verb} am {startDate}{partDay} im Urlaub{leaveTypeName}\n",
  "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {verb} vom {startDate} bis {endDate} im Urlaub{leaveTypeName}\n",
  "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Teammitglieder, die diesen Monat abwesend sind.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Teammitglieder, die nächsten Monat abwesend sein werden.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Teammitglieder, die nächste Woche abwesend sein werden.",
  "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Teammitglieder, die heute abwesend sind.",
  "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Teammitglieder, die diese Woche abwesend sind.",
  "bot.message.membersAway.multiHoliday": "- *{holidayLocationName}* wird vom {start} bis {end} wegen *{holidayName}* abwesend sein.\n",
  "bot.message.membersAway.noLeaves": "Derzeit gibt es keine geplanten Urlaube für diesen Zeitraum in Ihrem Team 😎.",
  "bot.message.memersAway.noLeaves": "Derzeit gibt es keine geplanten Urlaube für diesen Zeitraum in Ihrem Team 😎.",
  "bot.message.menageLeaves": "Urlaube verwalten",
  "bot.message.moreOptions": "Hallo <@{username}>!\nWomit kann ich Ihnen helfen?",
  "bot.message.moreOptions.about": "Mehr über mich :koala:",
  "bot.message.moreOptions.leaveHistoryTitle": "Urlaubsverlauf",
  "bot.message.moreOptions.leavesAndHolidaysTitle": "Ihre Urlaube und Feiertage:",
  "bot.message.moreOptions.membersAway": "Sehen Sie, welche Teammitglieder abwesend sind:",
  "bot.message.moreOptions.menageLeavesTitle": "Urlaube verwalten",
  "bot.message.moreOptions.showHolidaysTitle": "Feiertage anzeigen",
  "bot.message.myStatus.currentYear": "_Aktuelles Jahr, bis {yearEnd}_",
  "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nVerbleibend: {current}\nGenutzt: {used}\nGesamt: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nVerbleibende Tage: {current}\nGenutzte Tage: {used}\nGesamttage: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nVerbleibende Stunden: {current}\nGenutzte Stunden: {used}\nGesamtstunden: {earned}",
  "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nVerbleibende Tage: Unbegrenzt\nGenutzte Tage: {usedDays}\nGesamttage: Unbegrenzt",
  "bot.message.myStatus.nextYear": "_Nächstes Jahr, von {startYear} bis {endYear}_",
  "bot.message.myStatus.summary": "Hier ist die Zusammenfassung Ihrer Urlaube für das aktuelle und das nächste Jahr.",
  "bot.message.noLeavePolicies": "Es scheint, dass Ihrem Standort keine Urlaubsrichtlinien zugewiesen sind. 🤔 Kontaktieren Sie Ihren Administrator für weitere Informationen.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "Der Benutzer beantragt {leaveDurationTotal} Urlaubstage, was {overLimitCurrentYear} Tag über dem Limit liegt, das der Benutzer für das aktuelle Jahr hat, und {overLimitNextYear} Tag über dem Limit für das folgende Jahr.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "Der Benutzer beantragt {leaveDurationTotal} {leaveDurationTotal, plural, =1 {Tag} other {Tage}}, was {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {Tag} other {Tage}} über dem Limit liegt, das der Benutzer für das aktuelle Jahr hat, und {overLimitNextYear} {overLimitNextYear, plural, =1 {Tag} other {Tage}} über dem Limit für das folgende Jahr.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "Der Benutzer beantragt {leaveDurationTotal} {leaveDurationTotal, plural, =1 {Stunde} other {Stunden}}, was {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {Stunde} other {Stunden}} über dem Limit liegt, das der Benutzer für das aktuelle Jahr hat, und {overLimitNextYear} {overLimitNextYear, plural, =1 {Stunde} other {Stunden}} über dem Limit für das folgende Jahr.",
  "bot.message.notification.overAllowedLimitForCurrentYear": "Der Benutzer beantragt {leaveDurationCurrentYear} Urlaubstag, was {overLimit} Tag über dem erlaubten Limit liegt, das dieser Benutzer für das aktuelle Jahr hat.",
  "bot.message.notification.overAllowedLimitForCurrentYearDays": "Der Benutzer beantragt {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {Tag} other {Tage}}, was {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {Tag} other {Tage}} über dem erlaubten Limit liegt, das dieser Benutzer für das aktuelle Jahr hat.",
  "bot.message.notification.overAllowedLimitForCurrentYearHours": "Der Benutzer beantragt {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {Stunde} other {Stunden}}, was {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {Stunde} other {Stunden}} über dem erlaubten Limit liegt, das dieser Benutzer für das aktuelle Jahr hat.",
  "bot.message.notification.overAllowedLimitForCurrentYearPlural": "Der Benutzer beantragt {leaveDurationCurrentYear} Urlaubstage, was {overLimit} Tag über dem erlaubten Limit liegt, das dieser Benutzer für das aktuelle Jahr hat.",
  "bot.message.notification.overAllowedLimitForFollowingYear": "Der Benutzer beantragt {leaveDurationNextYear} Urlaubstag, was {overLimit} Tag über dem zulässigen Limit liegt, das dieser Benutzer für das nächste Jahr hat.",
  "bot.message.notification.overAllowedLimitForFollowingYearDays": "Der Benutzer beantragt {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {Tag} other {Tage}}, was {overLimitNextYear} {overLimitNextYear, plural, =1 {Tag} other {Tage}} über dem zulässigen Limit liegt, das dieser Benutzer für das nächste Jahr hat.",
  "bot.message.notification.overAllowedLimitForFollowingYearHours": "Der Benutzer beantragt {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {Stunde} other {Stunden}}, was {overLimitNextYear} {overLimitNextYear, plural, =1 {Stunde} other {Stunden}} über dem zulässigen Limit liegt, das dieser Benutzer für das nächste Jahr hat.",
  "bot.message.notification.overAllowedLimitForFollowingYearPlural": "Der Benutzer beantragt {leaveDurationNextYear} Urlaubstage, was {overLimit} Tag über dem zulässigen Limit liegt, das dieser Benutzer für das nächste Jahr hat.",
  "bot.message.notSignedUpError": "Es scheint, dass Sie sich nicht für Vacation Tracker angemeldet haben. Bitte navigieren Sie zu [{link}]({utmLink}) und erstellen Sie zuerst ein Konto. Es dauert weniger als eine Minute!",
  "bot.message.notSignedUpError.slack": "Es scheint, dass Sie sich nicht für Vacation Tracker angemeldet haben. Bitte klicken Sie auf den Button unten, um ein Konto zu erstellen. Es dauert weniger als eine Minute!",
  "bot.message.on": "Am",
  "bot.message.openTheWebDashboard": "Öffnen Sie das Web-Dashboard",
  "bot.message.partDay": "von {partDayStartHour} - {partDayEndHour}",
  "bot.message.probationPeriod": "*Probezeit*: \nIhre Probezeit dauert bis {date} \n \nGrund: _{message}_",
  "bot.message.reminder": "Hey Team! Eine freundliche Erinnerung vom Vacation Tracker Bot",
  "bot.message.reminderSnoozed": "Anfrageerinnerungen schlummern",
  "bot.message.requestLeave": "Bitte wählen Sie einen der verfügbaren Urlaubstypen aus der Liste unten aus und wählen Sie das Start- und Enddatum Ihres Urlaubs. Wenn Sie auf den Button 'Anfrage senden' klicken, wird Ihre Anfrage zur Genehmigung gesendet.",
  "bot.message.requestLeave.error": "*Fehler: {errorMessage}*",
  "bot.message.requestLeave.errorAutomation": "{errorMessage}",
  "bot.message.requestLeave.initialHelp": "Ich bin hier, um Ihnen bei der Einreichung eines Urlaubsantrags zu helfen. 🐨 Bitte wählen Sie einen Urlaubstyp aus und klicken Sie dann auf Weiter.",
  "bot.message.requestLeave.leaveDuration1": "Das ist 1 Tag Urlaub.",
  "bot.message.requestLeave.leaveDurationHalfDay": "Das ist ein halber Tag Urlaub",
  "bot.message.requestLeave.leaveDurationYear1": "Das sind {year1Value} Urlaub.",
  "bot.message.requestLeave.leaveDurationYear1And2": "Das sind {year1Value} Urlaub für das laufende Jahr und {year2Value} Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear1And2Days": "Das sind {year1Value} {year1Value, plural, =1 {Tag} other {Tage}} Urlaub für das laufende Jahr und {year2Value} {year2Value, plural, =1 {Tag} other {Tage}} Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear1And2Hours": "Das sind {year1Value} {year1Value, plural, =1 {Stunde} other {Stunden}} Urlaub für das laufende Jahr und {year2Value} {year2Value, plural, =1 {Stunde} other {Stunden}} Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear1Days": "Das sind {year1Value} {year1Value, plural, =1 {Tag} other {Tage}} Urlaub.",
  "bot.message.requestLeave.leaveDurationYear1Hours": "Das sind {year1Value} {year1Value, plural, =1 {Stunde} other {Stunden}} Urlaub.",
  "bot.message.requestLeave.leaveDurationYear1PluralYear2": "Das sind {year1Days} Tage Urlaub für das laufende Jahr und {year2Days} Tag Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "Das sind {year1Days} Tage Urlaub für das laufende Jahr und {year2Days} Tage Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear2": "Das sind {year2Value} Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear2Days": "Das sind {year2Value} {year2Value, plural, =1 {Tag} other {Tage}} Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear2Hours": "Das sind {year2Value} {year2Value, plural, =1 {Stunde} other {Stunden}} Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear2Plural": "Das sind {year2Days} Tage Urlaub für das nächste Jahr.",
  "bot.message.requestLeave.leaveDurationYear2PluralYear1": "Das ist {year1Days} Tag Urlaub für das laufende Jahr und {year2Days} Tage Urlaub für das nächste Jahr",
  "bot.message.requestLeave.selectCalendarStatus": "Kalenderstatus auswählen",
  "bot.message.requestLeave.selectCalendarStatus.info": "*Kalenderstatus*\nIn Ihrem Kalender anzeigen als",
  "bot.message.requestLeave.selectDate.from": "*Startdatum*\nBitte wählen Sie ein Startdatum",
  "bot.message.requestLeave.selectDate.info": "*Startdatum*\nBitte wählen Sie ein Startdatum",
  "bot.message.requestLeave.selectDate.oneDay": "*Datum*\nBitte wählen Sie ein Datum",
  "bot.message.requestLeave.selectDate.to": "*Enddatum*\nBitte wählen Sie ein Enddatum",
  "bot.message.requestLeave.selectLeaveType": "Urlaubstyp auswählen",
  "bot.message.requestLeave.selectLeaveType.info": "*Urlaubstyp*\nBitte wählen Sie einen Urlaubstyp",
  "bot.message.requestLeave.selectTimeFormat": "*AM/PM*",
  "bot.message.requestLeave.selectTimeRange": "Zeitbereich auswählen",
  "bot.message.requestLeave.selectTimeRange.end": "*Enddatum*\nBitte wählen Sie ein Enddatum",
  "bot.message.requestLeave.selectTimeRange.from": "*Startstunde*",
  "bot.message.requestLeave.selectTimeRange.info": "*Zeitbereich*\n Bitte wählen Sie den Zeitbereich",
  "bot.message.requestLeave.selectTimeRange.range": "*Zeitbereich*",
  "bot.message.requestLeave.selectTimeRange.to": "*Endstunde*",
  "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\n Bis zum nächsten Mal!",
  "bot.message.requestLeave.submitted.letTeamKnow": "P.S. Lassen Sie mein Team wissen, ob ich hilfreich war!",
  "bot.message.requestLeave.submitted.withApproval": "Die von Ihnen beantragten Daten wurden zur Genehmigung an Ihren Manager gesendet",
  "bot.message.requestLeave.submitted.withoutApproval": "Die von Ihnen beantragten Daten wurden automatisch genehmigt",
  "bot.message.requestLeaveModal.requestALeave": "Urlaub beantragen",
  "bot.message.requestLeaveModal.requestingLeave": "Urlaub wird beantragt",
  "bot.message.requestLeaveModal.sendFeedback": "Feedback senden",
  "bot.message.requestLeaveModal.sendRequest": "Anfrage senden",
  "bot.message.requestToil.initialHelp": "Ich bin hier, um Ihnen bei der Einreichung eines TOIL-Antrags zu helfen. 🐨 Bitte wählen Sie einen Urlaubstyp aus und klicken Sie dann auf Weiter.",
  "bot.message.selectADate": "Wählen Sie ein Datum",
  "bot.message.selectSubstituteApprover": "*Stellvertretenden Genehmiger auswählen*\nWählen Sie eine Person aus, die Ihre eingehenden Urlaubsanfragen verwaltet, während Sie abwesend sind.",
  "bot.message.showHolidays.holidaysHalfDay": "- *{holidayLocationName}* wird am {date} für *{holidayName}* (halber Tag) geschlossen sein.\n",
  "bot.message.showHolidays.noUpcomingHolidays": "Es gibt keine bevorstehenden Feiertage in Ihrer Organisation oder Ihr Administrator hat noch keine Feiertage festgelegt.",
  "bot.message.showHolidays.one": "- *{holidayLocationName}* wird vom {startDate} bis {endDate} für *{holidayName}* geschlossen sein.\n",
  "bot.message.showHolidays.two": "- *{holidayLocationName}* wird am {date} für *{holidayName}* geschlossen sein.\n",
  "bot.message.showHolidays.upcomingHolidays": "Bevorstehende Feiertage",
  "bot.message.slack.userChanged": "Der Benutzer hat diese Anfrage geändert",
  "bot.message.slashCommand.helpCommand1": "*Hier sind ein paar weitere Informationen über mich:*",
  "bot.message.slashCommand.helpCommand2": "Von über 1000 Organisationen weltweit vertraut, *ermöglicht Vacation Tracker Ihrem Team, mühelos zu verfolgen, wer im Büro anwesend oder abwesend ist.*",
  "bot.message.slashCommand.helpCommand3": "Erfahren Sie mehr über unsere Geschichte auf unserer <https://vacationtracker.io/about|Über uns-Seite>.",
  "bot.message.slashCommand.helpCommand4": "Für Schritt-für-Schritt-Anleitungen besuchen Sie unseren <https://vacationtracker.crisp.help/en/|Helpdesk>.",
  "bot.message.slashCommand.helpCommand5": "Um sich beim Online-Dashboard anzumelden, besuchen Sie <{dashboardUrl}/connect?platform=slack>.",
  "bot.message.slashCommand.helpCommand6": "Und wenn Sie uns unterwegs jemals brauchen, sind wir nur eine E-Mail entfernt unter hello@vacationtracker.io.",
  "bot.message.slashCommand.noPermissions": "Es tut mir leid, der Überbringer schlechter Nachrichten zu sein, aber es scheint, dass Sie keine Berechtigung haben, auf Vacation Tracker zuzugreifen. 😟 Bitte kontaktieren Sie Ihren Administrator.",
  "bot.message.slashCommand.title": "Hallo <@{userId}>! Möchten Sie sich freinehmen? Lassen Sie mich Ihnen helfen!",
  "bot.message.sorryLetsSpokePriveate": "Hmm, lass uns privat sprechen. 🤫",
  "bot.message.sorryYouAreNotActive": "Entschuldigung, Ihr Konto ist inaktiv",
  "bot.message.sorryYouAreNotExist": "Entschuldigung, Ihr Konto wurde nicht gefunden",
  "bot.message.submittedLeaveRequest": "Hallo, <@{userSlackId}> hat folgende Urlaubsanfrage eingereicht:",
  "bot.message.submittedLeaveRequestReminder": "Hallo, nur zur Erinnerung, dass <@{name}> eine Anfrage eingereicht hat, die Ihre Aufmerksamkeit erfordert.",
  "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Bitte beachten Sie, diese Anfrage läuft in {days} Tag(en) ab. Dies ist Erinnerung {reminderNo} von 3.",
  "bot.message.substituteApproverPlaceholder": "🔍 Keine",
  "bot.message.unableToRequestLeave": "Urlaub kann nicht beantragt werden.",
  "bot.message.unknownCommand": "Es tut mir leid, ich verstehe diesen Befehl nicht.\nGeben Sie **help** ein, um die Liste der verfügbaren Befehle zu sehen.",
  "bot.microsoft.whatsNewInfoMessages": "Dies ist eine Ankündigung von **Vacation Tracker**. Sie erhalten diese, weil Sie ein Administrator oder ein Genehmiger sind.",
  "bot.notification.accruedDaysInfoForApprover": "ℹ️ Diese Urlaubsanfrage basiert auf zukünftig angesammelten freien Tagen, nicht auf dem aktuellen Betrag. @{name} hat heute noch {amount} Tag(e) übrig. Sie werden {accrued} am {earningDate} ansammeln.",
  "bot.notification.accruedDaysInfoForUser": "ℹ️ Ihre Urlaubsanfrage basiert auf zukünftig angesammelten freien Tagen, nicht auf dem aktuellen Betrag. Sie haben heute noch {amount} Tag(e) übrig und werden {accrued} am {earningDate} ansammeln.",
  "bot.notification.adminToApprovers": "Sie wurden als neuer Genehmiger für {teamName} ernannt, da das Konto des vorherigen Genehmigers deaktiviert wurde.",
  "bot.notification.approverCard.approved": "✅ @{approverName} hat die Anfrage genehmigt, keine weitere Aktion erforderlich.",
  "bot.notification.approverCard.autoApproved": "Die Anfrage wurde automatisch genehmigt, keine weitere Aktion erforderlich.",
  "bot.notification.approverCard.cancelled": "⚠️ Es scheint, dass {requesterName} seine Meinung geändert hat. Die Anfrage wurde storniert.",
  "bot.notification.approverCard.cancelledLeave": "Hallo, {requesterName} hat seinen Urlaub storniert.",
  "bot.notification.approverCard.cancelledTitle": "⚠️ Dieser Urlaub wurde von einem Genehmiger storniert.",
  "bot.notification.approverCard.denied": "❌ @{approverName} hat die Anfrage abgelehnt, keine weitere Aktion erforderlich.",
  "bot.notification.approverCard.deniedEdited": "❌ @{approverName} hat die bearbeitete Anfrage abgelehnt, keine weitere Aktion erforderlich.",
  "bot.notification.approverCard.editedByApprover": "@{approverName} hat eine von @{requesterName} eingereichte Urlaubsanfrage bearbeitet",
  "bot.notification.approverCard.editedByApproverForUser": "@{approverName} hat eine Urlaubsanfrage für @{requesterName} bearbeitet.",
  "bot.notification.approverCard.info": "Fügen Sie einen Grund für diese Genehmigung oder Ablehnung hinzu",
  "bot.notification.approverCard.notActive": "⚠️ Diese Urlaubsanfrage ist nicht mehr aktiv.",
  "bot.notification.changeOutOfOfficeMessage": "Vorlage bearbeiten",
  "bot.notification.daily.info": "Es ist Zeit für Ihr tägliches Update! 🐨 Hier sind die bevorstehenden Urlaube:",
  "bot.notification.daily.noLeaves.isNotForToday": "Es sind keine Urlaube für {dayName} geplant",
  "bot.notification.daily.noLeaves.isToday": "Es sind heute keine Urlaube geplant",
  "bot.notification.holiday.plural": "- **{locationNames}** Standorte werden für **{holidayName}** {holidayPeriodText} abwesend sein",
  "bot.notification.holiday.singular": "- Der Standort **{locationNames}** wird wegen **{holidayName}** {holidayPeriodText} abwesend sein",
  "bot.notification.leave.info.isInTheFuture": "wird {leaveTypeText} {leavePeriodText} im Urlaub sein",
  "bot.notification.leave.info.isInThePast": "war {leaveTypeText} {leavePeriodText} im Urlaub",
  "bot.notification.leave.info.isToday": "ist heute {leaveTypeText} {leavePeriodText} im Urlaub",
  "bot.notification.leaveDates.partDay": "{start} von {partDayStartHour} - {partDayEndHour}",
  "bot.notification.leavePeriod.fullDay": "am {day}",
  "bot.notification.leavePeriod.halfDay": "am {day} (Halber Tag)",
  "bot.notification.leavePeriod.moreDays": "vom {start} bis {end}",
  "bot.notification.leavePeriod.partDay": "am {start} von {partDayStartHour} - {partDayEndHour}",
  "bot.notification.leavePolicyInfo": "*Bitte beachten Sie: Für diesen Benutzer oder diese Urlaubsart ist ein Kontingent von 0 für den beantragten Urlaub festgelegt.*",
  "bot.notification.leaveRequestError.general": "Fehler bei einer Urlaubsanfrage",
  "bot.notification.leaveRequestError.notApprover": "Diese Aktion ist nur für Team-Genehmiger/Administratoren zulässig",
  "bot.notification.leaveRequestError.notOpen": "Urlaubsantrag wurde {leaveRequestStatus}",
  "bot.notification.leaveRequestError.notOpenIsSameAppover": "Urlaubsantrag wurde {leaveRequestStatus}",
  "bot.notification.leaveRequestExpired": "Ihr Antrag ist abgelaufen",
  "bot.notification.leaveRequestExpiredGeneral": "Antrag ist abgelaufen",
  "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} - Halber Tag)",
  "bot.notification.oooDefaultMessage": "Hallo,\n\nVielen Dank für Ihre Nachricht. Ich bin derzeit nicht im Büro und kann bis zum {firstWorkingDay} keine E-Mails beantworten.\n\nIch werde mich bemühen, nach meiner Rückkehr umgehend auf Ihre E-Mail zu antworten.\n\nVielen Dank für Ihr Verständnis.\n\nMit freundlichen Grüßen",
  "bot.notification.processingApproval": "Genehmigung wird bearbeitet...",
  "bot.notification.processingDenial": "Ablehnung wird bearbeitet...",
  "bot.notification.processingResend": "Urlaubsantrag wird erneut gesendet...",
  "bot.notification.processingSnooze": "Erinnerungen werden schlummern...",
  "bot.notification.reconnectCalendar": "Erneut verbinden",
  "bot.notification.reminderSent": "Erinnerung für Urlaubsantrag gesendet",
  "bot.notification.reminderSentToil": "TOIL-Antrag Erinnerung gesendet",
  "bot.notification.requesterCard.addedLeave": "Urlaub wurde für Sie erstellt",
  "bot.notification.requesterCard.addedLeaveBy": "Hinzugefügt von {approverName}",
  "bot.notification.requesterCard.addedLeaveToil": "TOIL wurde für Sie erstellt",
  "bot.notification.requesterCard.approvedTitle": "Ihr Urlaubsantrag wurde genehmigt",
  "bot.notification.requesterCard.approvedTitleToil": "Ihr TOIL-Antrag wurde genehmigt",
  "bot.notification.requesterCard.approverInfo": "Genehmigt von {approverName}",
  "bot.notification.requesterCard.autoApprovedInfo": "Der Antrag wurde automatisch genehmigt.",
  "bot.notification.requesterCard.blackoutPeriod": "Zeitraum: {startDate} - {endDate}",
  "bot.notification.requesterCard.blackoutPeriodReason": "Grund: {message}",
  "bot.notification.requesterCard.blackoutPeriodTitle": "Sperrzeit",
  "bot.notification.requesterCard.cancelled": "⚠️ Storniert",
  "bot.notification.requesterCard.cancelledEdited": "⚠️ Änderungen wurden am ursprünglichen Antrag vorgenommen.",
  "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} hat diesen Urlaubsantrag bearbeitet.",
  "bot.notification.requesterCard.cancelledTitle": "⚠️ Ihr Urlaub wurde von einem Genehmiger storniert.",
  "bot.notification.requesterCard.deniedBy": "Ihr Urlaubsantrag wurde genehmigt",
  "bot.notification.requesterCard.deniedEditedDescription": "Ihr bearbeiteter Urlaubsantrag wurde abgelehnt, aber Ihr ursprünglicher Antrag bleibt aktiv.",
  "bot.notification.requesterCard.deniedEditedTitle": "Bearbeitung des Urlaubsantrags abgelehnt.",
  "bot.notification.requesterCard.deniedOpenDescription": "Sowohl Ihr ursprünglicher Urlaubsantrag als auch der bearbeitete Urlaubsantrag wurden abgelehnt.",
  "bot.notification.requesterCard.deniedOpenTitle": "Urlaubsantrag abgelehnt",
  "bot.notification.requesterCard.deniedTitle": "Ihr Urlaubsantrag wurde abgelehnt",
  "bot.notification.requesterCard.edited": "@{requesterName} hat ihren zuvor genehmigten Antrag bearbeitet und reicht ihn erneut zur Genehmigung ein:",
  "bot.notification.requesterCard.editedOpen": "@{requesterName} hat ihren Urlaubsantrag bearbeitet und erneut eingereicht.",
  "bot.notification.requesterCard.moreDays": "❌ Sie haben mehr Tage beantragt als verfügbar sind.",
  "bot.notification.requesterCard.moreThanFullDay": "❌ Der Urlaubsantrag kann nicht eingereicht werden, da Sie mehr als einen vollen Tag Urlaub an einem Arbeitstag beantragt haben.",
  "bot.notification.requesterCard.noWorkingDays": "❌ Der Urlaubsantrag kann nicht eingereicht werden, da Sie keine Arbeitstage eingeschlossen haben. Überprüfen Sie die Arbeitstage Ihres Teams, bevor Sie den Antrag einreichen.",
  "bot.notification.requesterCard.originalLeaveRequest": "Ursprünglicher Urlaubsantrag:",
  "bot.notification.requesterCard.overlap": "❌ Der Urlaubsantrag kann nicht eingereicht werden, da sich die von Ihnen beantragten Daten mit den Daten eines bestehenden Urlaubsantrags überschneiden.",
  "bot.notification.requesterCard.probationPeriod": "Ihre Probezeit dauert bis zum {date}",
  "bot.notification.requesterCard.probationPeriodReason": "Grund: {message}",
  "bot.notification.requesterCard.probationPeriodTitle": "Probezeit",
  "bot.notification.requesterCard.requestInfo": "Ihr Antrag ist auf dem Weg zum Genehmiger. Bis zum nächsten Mal! 🐨",
  "bot.notification.requesterCard.requestPossibleActions": "Um den Antrag zu bearbeiten oder zu stornieren, besuchen Sie das Dashboard",
  "bot.notification.requesterCardapp.tryAnotherDates": "Versuchen Sie andere Termine",
  "bot.notification.requesterInfo": "Hallo, @{userName} hat einen Antrag zur Überprüfung eingereicht.",
  "bot.notification.requesterInfoAutoApproved": "Hallo, @{userName} hat einen Antrag eingereicht.",
  "bot.notification.requesterInfoEdited": "@{userName} hat ihren Antrag bearbeitet und erneut eingereicht.",
  "bot.notification.requesterInfoEditedByApprover": "@{approverName} hat einen von @{userName} eingereichten Antrag bearbeitet",
  "bot.notification.requesterInfoEditedForUser": "Ihr Antrag wurde bearbeitet.",
  "bot.notification.requesterInfoEditedForUserByApprover": "Genehmiger @{approverName} hat Änderungen an Ihrem Antrag vorgenommen.",
  "bot.notification.requesterInfoEditedOpen": "@{userName} hat ihren Antrag bearbeitet und erneut eingereicht.",
  "bot.notification.setOutOfOffice": "Ihr {leaveTypeName} beginnt in weniger als {days} {days, plural, =1 {Tag} other {Tagen}} am {leavePeriodStart}. Möchten Sie eine automatische Abwesenheitsnachricht einrichten, die während Ihrer Abwesenheit gesendet wird?",
  "bot.notification.setOutOfOfficeButton": "Abwesenheitsnachricht einrichten",
  "bot.notification.setOutOfOfficeFallbackText": "Abwesenheitsnachricht für Ihren {leaveTypeName} einrichten",
  "bot.notification.slack.additional": "... und mehr.\nWeitere Urlaube konnten aufgrund der Größenbeschränkung nicht angezeigt werden. Bitte öffnen Sie das Dashboard für eine vollständige Übersicht.",
  "bot.notification.slack.approved": "✅ <@{approverName}> hat den Antrag genehmigt, keine weiteren Maßnahmen erforderlich.",
  "bot.notification.slack.approverChanged": "Hallo, Genehmiger <@{approverSlackId}> hat einen Urlaubsantrag geändert.",
  "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "Der Genehmiger <@{approverSlackId}> hat die Daten des Antrags geändert",
  "bot.notification.slack.approverChangedLeaveReqest": "Hallo, Genehmiger <@{approverSlackId}> hat den folgenden Urlaubsantrag für <@{userSlackId}> geändert:",
  "bot.notification.slack.approverChangedYour": "Genehmiger <@{approverSlackId}> hat Ihren Urlaubsantrag geändert.",
  "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> hat diesen Antrag abgelehnt, keine weiteren Maßnahmen erforderlich",
  "bot.notification.slack.calendar": ":calendar: |  *{dayName}*  | :calendar: ",
  "bot.notification.slack.cancelled": "Hallo, <@{userName}> hat ihren Urlaub storniert.",
  "bot.notification.slack.cancelledByApprover": "Ihr Urlaub wurde von einem Genehmiger storniert",
  "bot.notification.slack.cancelledInfo": "Dieser Urlaub wurde von einem Genehmiger storniert",
  "bot.notification.slack.created": "Ein Urlaub wurde von einem Genehmiger für Sie erstellt",
  "bot.notification.slack.holiday.plural": "- *{locationNames}* Standorte werden für *{holidayName}* {holidayPeriodText} abwesend sein",
  "bot.notification.slack.holiday.singular": "- *{locationNames}* Standort wird für *{holidayName}* {holidayPeriodText} abwesend sein",
  "bot.notification.slack.holidays": ":airplane: *Feiertage: *",
  "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* wird im Urlaub sein{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isInThePast": "- *{userName}* war im Urlaub{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isToday": "- *{userName}* ist im Urlaub{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leaveRequestCreated": "Hallo, <@{userName}> hat einen Urlaubsantrag eingereicht.",
  "bot.notification.slack.leaveRequestInfo": "Hallo, <@{userName}> bitte bestätigen Sie die Urlaubsantragsinformationen.",
  "bot.notification.slack.leaveRequestInfoDateFrom": "Startdatum: {date}",
  "bot.notification.slack.leaveRequestInfoDateTo": "Enddatum: {date}",
  "bot.notification.slack.leaveRequestInfoError": "Wir konnten keinen Urlaub aus den bereitgestellten Daten vorschlagen",
  "bot.notification.slack.leaveRequestInfoLeaveType": "Urlaubsart: {leaveType}",
  "bot.notification.slack.leaveRequestInfoReason": "Grund: {reason}",
  "bot.notification.slack.leaveRequestSuggestionCancelled": "Urlaubsantragsformular storniert ❌",
  "bot.notification.slack.leaves": ":loud_sound: *Urlaube: *",
  "bot.notification.slack.reason": "*Grund*\n{reason}",
  "bot.notification.slack.reasonEdited": "*Grund [Bearbeitet] *\n{reason}",
  "bot.notification.slack.requestedBy": "*Beantragt von:*\n<@{userSlackId}>",
  "bot.notification.slack.statusApproved": "*Status:*\n✅ Genehmigt",
  "bot.notification.slack.statusAutoApproved": "*Status*\nAutomatisch genehmigt",
  "bot.notification.slack.statusDenied": "*Status*\n❌ Abgelehnt",
  "bot.notification.slack.statusInfo": "👋 Wir können jetzt Ihren Slack-Status automatisch für diesen Urlaubstyp einstellen! Klicken Sie auf die Schaltfläche 'Aktivieren', um uns zu erlauben, Ihren Status zu aktualisieren, wenn Ihr Urlaub beginnt. Wir löschen ihn, wenn er endet.",
  "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "Dieser Urlaub wurde von einem Genehmiger storniert. Weitere Informationen finden Sie unten.",
  "bot.notification.slack.title": "Benachrichtigung: \"_{title}_\"",
  "bot.notification.slack.userCancelledLeaveRequest": "Es sieht so aus, als hätte <@{userSlackId}> seine Meinung geändert. Die Anfrage wurde storniert.",
  "bot.notification.slack.warning": ":warning: *Warnung: {infoMessage}*",
  "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "Ihr Urlaub wurde von einem Genehmiger storniert. Weitere Informationen finden Sie unten.",
  "bot.notification.slack.yourLeaveRequestApproved": "Ihre Anfrage wurde genehmigt",
  "bot.notification.slack.yourRequestHasBeenDenied": "Ihre Anfrage wurde abgelehnt",
  "bot.notification.snoozeOutOfOfficeButton": "Frag mich nicht noch einmal",
  "bot.notification.snoozeOutOfOfficeMessage": "Wir werden Sie nicht mehr auffordern, die Abwesenheitsnotiz für {leaveTypeName} einzurichten. Sie können diese Einstellung jederzeit auf der Vacation Tracker Web Dashboard - Kontoeinstellungen-Seite ändern",
  "bot.notification.submittedLeaveRequestReminder": "Hallo, nur zur Erinnerung: @{name} hat eine Anfrage eingereicht, die Ihre Aufmerksamkeit erfordert.",
  "bot.notification.substituteApproverDeleted": "Ihre Rolle als Stellvertretender Genehmiger für den Zeitraum {startDate} - {endDate} wurde entfernt.",
  "bot.notification.substituteApproverSelected": "Sie wurden von {creatorName} als stellvertretender Genehmiger für die Abteilung(en) {teamNames} im Namen von {approverName} während deren Abwesenheit ausgewählt. Als stellvertretender Genehmiger sind Sie für die Überprüfung und Bearbeitung von Urlaubsanträgen verantwortlich, die an {approverName} von {startDate} bis {endDate} gesendet werden.",
  "bot.notification.substituteApproverUpdated": "Ihr Zeitraum als Stellvertretender Genehmiger wurde von {startDate} bis {endDate} aktualisiert.",
  "bot.notification.title": "Benachrichtigung: {title}",
  "bot.notification.weekly.current.info": "Hallo!\n 🐨 Ich bin hier, um Sie über geplante Urlaube für die aktuelle Woche ({start} - {end}) zu informieren",
  "bot.notification.weekly.next.info": "Hallo!\n 🐨 Ich bin hier, um Sie über geplante Urlaube für die nächste Woche ({start} - {end}) zu informieren",
  "bot.notification.weekly.noHolidays": "Es gibt keine Feiertage für diese Woche.",
  "bot.notification.weekly.noLeaves": "Es gibt keine geplanten Urlaube für diese Woche.",
  "bot.slack.whatsNewInfoMessages": "Dies ist eine Ankündigung von *Vacation Tracker*. Sie erhalten diese, weil Sie ein Administrator oder ein Genehmiger sind.",
  "bot.slackFuckedUpAgain": "Slack hat wieder etwas geändert, bitte kontaktieren Sie unseren Support, um Slack-Fehler zu beheben.",
  "bot.somethingNewFromVacationTracker": "Etwas Neues von Vacation Tracker",
  "bot.welcomeMessage.furtherAssistance": "Fühlen Sie sich von all diesen Informationen überfordert? Keine Sorge! Sie müssen sich nicht alles merken. Geben Sie einfach 'Hilfe' in unserem Chat ein, und unser Bot sendet Ihnen diese Informationen. Für weitere Unterstützung können Sie gerne mit unserem Kundensupport-Team über unsere Website chatten oder unser Helpdesk besuchen.",
  "bot.welcomeMessage.goToMyProfile": "Zum Mein Profil-Tab gehen",
  "bot.welcomeMessage.greeting": "Grüße {name}, ich bin der Vacation Tracker Bot! Ich bin hier, um Ihnen bei der Planung von Urlauben über Microsoft Teams zu helfen und alle darüber zu informieren, wer im Büro ist und wer nicht. Lassen Sie uns ohne Umschweife mit der Urlaubsverfolgung beginnen!",
  "bot.welcomeMessage.happyVacationing": "Schönen Urlaub 🌴",
  "bot.welcomeMessage.howToAccessTabs": "Wie man auf Tabs zugreift",
  "bot.welcomeMessage.noCompany": "Hallo, \n\n Vielen Dank, dass Sie den Vacation Tracker Bot und Tab in Microsoft Teams installiert haben. Als letzten Schritt gehen Sie bitte auf unsere Website, um die Registrierung abzuschließen und die Einstellungen Ihrer Organisation zu konfigurieren: [{dashboardLink}]({utmDashboardLink})",
  "bot.welcomeMessage.noCompanyInfo": "Wenn Sie Fragen haben, können Sie gerne einen Chat mit unserem Kundensupport-Team auf unserer Website starten oder unser Helpdesk besuchen.",
  "bot.welcomeMessage.openWebDashboardInfo": "Die gleichen Informationen sind auch über unser Web-Dashboard zugänglich. Klicken Sie einfach auf die Schaltfläche 'Web-Dashboard öffnen' unten und melden Sie sich mit Ihrem Microsoft-Konto an, um darauf zuzugreifen.",
  "bot.welcomeMessage.requestLeaveInfo": "Um zu beginnen, senden Sie mir einfach eine direkte Nachricht mit 'Urlaub beantragen' oder schauen Sie sich dieses kurze Tutorial über Vacation Tracker an:",
  "bot.welcomeMessage.restInfo": "Neben dem Bot können Sie auch Urlaubsanträge senden, Ihre Urlaubskontingente überprüfen, Team-Kalender einsehen und mehr von Microsoft Teams aus, indem Sie Tabs verwenden:",
  "bot.welcomeMessage.title": "Hallo von Vacation Tracker",
  "botUpdatePermissions.thanks": "Danke! Alles ist eingerichtet. Sie können das Dashboard schließen.",
  "button.no": "Nein",
  "button.yes": "Ja",
  "calendar.all": "Alle",
  "calendar.calendar": "Kalender",
  "calendar.filterByLocation": "Nach Standort filtern:",
  "calendar.filterByTeam": "Nach Abteilung filtern:",
  "calendar.forAllUsers": "Für alle Benutzer",
  "calendar.getCalendarLink": "Kalenderlink erhalten",
  "calendar.halfdayTags": "Urlaube und Feiertage, die kürzer als ein ganzer Tag sind, werden durch gestreifte Blöcke dargestellt.",
  "calendar.halfdayTagsShort": "Urlaube kürzer als ein Tag",
  "calendar.labels": "Etikett(en)",
  "calendar.listView": "Listenansicht",
  "calendar.location": "Standort(e)",
  "calendar.monthView": "Monatsansicht",
  "calendar.sync.calendarAlreadyConnected": "Dieser Kalender ist bereits verbunden",
  "calendar.sync.calendarNotFound": "Der Kalender konnte nicht gefunden werden. Er wurde möglicherweise gelöscht oder ist nicht mehr verfügbar. Bitte überprüfen Sie Ihre Kalendereinstellungen.",
  "calendar.sync.canNotUpdateSharedCalendar": "Sie haben den {calendarName} {provider}-Kalender verbunden. Eine Änderung des Kalenders ist nicht erlaubt. Um den verbundenen Kalender zu ändern, löschen Sie bitte diese Integration und erstellen Sie eine neue.",
  "calendar.sync.confirmDelete": "Bitte bestätigen Sie, dass Sie den Kalender <strong>{name}</strong> löschen möchten.",
  "calendar.sync.connect": "Verbinden Sie Ihren {provider}-Kalender",
  "calendar.sync.connectCalendar": "Kalender verbinden",
  "calendar.sync.connectCalendarCompletePlanyOnly": "NUR IM COMPLETE-PLAN",
  "calendar.sync.connectCalendarIcalOptionLabel": "Beliebiger Kalender (über iCal-Link)",
  "calendar.sync.connectCalendarOptionLabel": "{platform}-Kalender",
  "calendar.sync.connectInfo": "Verbinden Sie Ihr {provider}-Konto und wählen Sie einen gemeinsamen Kalender aus, in den alle genehmigten Abwesenheiten automatisch übertragen und synchronisiert werden.",
  "calendar.sync.createICalButton": "iCal-Link erstellen",
  "calendar.sync.createICalFiltersInfo": "Die folgenden Filter werden auf diesen iCal-Kalenderlink angewendet:",
  "calendar.sync.createICalFiltersWarning": "Ihr Kalender aktualisiert iCal-Daten nicht in Echtzeit. Die Verzögerung kann bis zu 24 Stunden betragen (abhängig von Ihrem Kalenderanbieter). Für Echtzeit-Updates verbinden Sie Ihren Google Kalender oder Microsoft Outlook Kalender direkt.",
  "calendar.sync.createICalInfo": "Der iCal-Kalenderlink ermöglicht es Ihnen, die geplanten Abwesenheiten Ihres Teams aus Vacation Tracker in jedem Kalender zu abonnieren und anzuzeigen (einschließlich Google Kalender, Microsoft Outlook Kalender, Apple Kalender und viele mehr).",
  "calendar.sync.createPushToSharedInfo": "Und übertragen und synchronisieren Sie die gefilterten genehmigten Abwesenheiten in den folgenden gemeinsamen {provider}-Kalender:",
  "calendar.sync.createSyncCalendarButton": "Kalendersynchronisierung erstellen",
  "calendar.sync.createSyncCalendarFiltersInfo": "Vacation Tracker wendet die folgenden Filter auf alle genehmigten Abwesenheiten an:",
  "calendar.sync.creating": "Kalendersynchronisierung wird erstellt",
  "calendar.sync.creatingError": "Fehler beim Erstellen der Kalendersynchronisierung",
  "calendar.sync.creatingSuccess": "Kalendersynchronisierung erfolgreich erstellt",
  "calendar.sync.deletedCalendar": "Diese Kalendersynchronisierung wurde gelöscht",
  "calendar.sync.deleteTitle": "Kalender löschen",
  "calendar.sync.deleting": "Kalendersynchronisierung wird gelöscht",
  "calendar.sync.deletingError": "Fehler beim Löschen der Kalendersynchronisierung",
  "calendar.sync.deletingSuccess": "Kalendersynchronisierung erfolgreich gelöscht",
  "calendar.sync.emptyDataMessage": "Synchronisieren und betrachten Sie alle Team-Abwesenheiten in Ihrem gemeinsamen Kalender. Sie können Ihren Outlook-Kalender oder Google Kalender verbinden oder iCal verwenden, um Vacation Tracker mit jedem anderen Kalendertyp zu synchronisieren.",
  "calendar.sync.emptyDataTitle": "Synchronisieren Sie Abwesenheiten mit Ihrem Team-Kalender",
  "calendar.sync.loginWithDifferentAccountInfo": "Sehen Sie den Kalender nicht?",
  "calendar.sync.loginWithDifferentAccountLink": "Mit einem anderen {provider}-Konto anmelden",
  "calendar.sync.reconnect": "Verbinden Sie Ihren {provider}-Kalender erneut",
  "calendar.sync.reconnectInfo": "Verbinden Sie Ihr {provider}-Konto erneut, um die automatische Synchronisierung von Abwesenheiten mit Ihrem gemeinsamen Kalender fortzusetzen.",
  "calendar.sync.reconnecting": "Kalendersynchronisierung wird wiederhergestellt",
  "calendar.sync.reconnectingError": "Fehler bei der Wiederherstellung der Kalendersynchronisierung",
  "calendar.sync.reconnectingSuccess": "Kalendersynchronisierung erfolgreich wiederhergestellt",
  "calendar.sync.selectSharedCalendarPlaceholder": "Gemeinsamen {provider}-Kalender auswählen",
  "calendar.sync.stepByStep": "Schritt-für-Schritt-Anleitung",
  "calendar.sync.title": "Synchronisieren Sie Ihren Team-Kalender",
  "calendar.sync.tooltipInfo": "Alle Ihre synchronisierten Kalender werden auf dieser Seite für eine einfachere Verwaltung zusammengeführt. Für weitere Informationen konsultieren Sie bitte unseren <helpDesk>Helpdesk-Artikel.</helpDesk>",
  "calendar.sync.update": "Aktualisieren Sie Ihren {provider}-Kalender",
  "calendar.sync.updateSyncCalendarButton": "Kalendersynchronisierung aktualisieren",
  "calendar.sync.updating": "Kalendersynchronisierung wird aktualisiert",
  "calendar.sync.updatingError": "Fehler beim Aktualisieren der Kalendersynchronisierung",
  "calendar.sync.updatingSuccess": "Kalendersynchronisierung erfolgreich aktualisiert",
  "calendar.syncButton": "Mit Ihrem Kalender synchronisieren (Google, Outlook, usw.)",
  "calendar.syncModal.description": "Kopieren Sie den untenstehenden Link und integrieren Sie ihn in Ihren Google Kalender, Outlook Kalender oder jeden anderen Kalender, der iCal-Kalenderfeeds unterstützt.",
  "calendar.syncModal.filters": "Die folgenden Filter werden auf diesen Kalenderlink angewendet:",
  "calendar.syncModal.helpdesk": "Besuchen Sie unser <helpDesk>Helpdesk</helpDesk> für Hilfe und Schritt-für-Schritt-Anleitungen.",
  "calendar.team": "Abteilung(en)",
  "calendar.timezone.info": "Die Zeit wird in Ihre Zeitzone umgerechnet",
  "calendar.week.monFri": "Mo-Fr",
  "calendar.week.sunThu": "So-Do",
  "calendarListModal.description": "Bitte wählen Sie einen Kalender aus der folgenden Liste der verfügbaren Kalender aus. Nachdem Sie einen Kalender verbunden haben, wird Vacation Tracker Ihre Abwesenheiten in den ausgewählten Kalender hinzufügen, bearbeiten oder stornieren.",
  "calendarListModal.descriptionGoogleFirstLine": "Bitte wählen Sie einen Kalender aus der folgenden Liste verfügbarer Kalender aus.",
  "calendarListModal.descriptionOutlookFirstLine": "Sie können nur Ihren Standardkalender verbinden.",
  "calendarListModal.descriptionSecondLine": "Nachdem Sie einen Kalender verbunden haben, wird Vacation Tracker Ihre Abwesenheiten im ausgewählten Kalender hinzufügen, bearbeiten oder stornieren.",
  "calendarListModal.hiddenLeaveTypesDescription": "Erfahren Sie mehr über <helpdesk>die Anzeige versteckter Abwesenheitsarten</helpdesk>.",
  "calendarListModal.reconnectDescription": "Verbinden Sie Ihren Kalender erneut, um die automatische Synchronisierung der Abwesenheiten mit Ihrem verbundenen Kalender fortzusetzen.",
  "calendarListModal.reconnectTitle": "Kalender erneut verbinden",
  "calendarListModal.selectPlaceHolder": "Bitte wählen Sie einen Kalender aus...",
  "calendarListModal.title": "Kalender auswählen",
  "channel_not_found": "Der ausgewählte Kanal wurde gelöscht oder ist nicht mehr verfügbar. Bitte wählen Sie einen anderen Kanal aus, um Benachrichtigungen zu erhalten.",
  "choiceAutomations.accessibleTo": "Zugänglich für <approver>Genehmiger </approver><and>und </and><admin>Administrator </admin><only>nur</only>",
  "choiceAutomations.activeSubscribedAddons": "Aktive abonnierte Add-ons",
  "choiceAutomations.activeSubscribedAutomations": "Aktive abonnierte Automatisierungen",
  "choiceAutomations.adpIntegration": "Bietet eine direkte Verbindung zu Ihrem Abrechnungssystem und gewährleistet präzise und aktuelle Abrechnungsdaten auf allen Plattformen.",
  "choiceAutomations.automationPrice": "Preis: ${price}/Benutzer/Monat",
  "choiceAutomations.automationPriceInfo": "Sie können jede der unten aufgeführten Automatisierungen für $0,50/Benutzer/Monat kaufen und aktivieren",
  "choiceAutomations.bambooHrIntegration": "Bietet eine direkte Verbindung zu Ihrem HR-System und hält Daten systemübergreifend präzise und konsistent.",
  "choiceAutomations.blackoutPeriodInfo": "Eine Sperrzeit ist ein festgelegter Zeitraum, in dem Mitarbeiter keinen Urlaub nehmen dürfen.",
  "choiceAutomations.cancelAtPeriodEnd": "Aktiv bis zum Ende der Abrechnungsperiode",
  "choiceAutomations.cancelAtPeriodEndTooltip": "Sie haben dieses Add-on abbestellt. Es bleibt bis zum Ende der aktuellen Abrechnungsperiode aktiv und wird danach deaktiviert.",
  "choiceAutomations.comingSoonScheduleCall": "Teilen Sie uns gerne Details zu Ihren spezifischen Anforderungen bezüglich der Automatisierung mit, an der wir arbeiten.",
  "choiceAutomations.comingSoonScheduleCallTitle": "Demnächst verfügbar",
  "choiceAutomations.deacticate": "Abbestellen",
  "choiceAutomations.employmentLeaveRestrictionInfo": "Die Urlaubsregelung für die frühe Beschäftigungszeit ermöglicht es, eine dedizierte Einarbeitungszeit während der ersten Beschäftigungstage durch Einschränkung der Urlaubsanträge neuer Mitarbeiter sicherzustellen.",
  "choiceAutomations.enitlementByRoleShortInfo": "Mitarbeitern werden zusätzliche Urlaubstage basierend auf ihrer Rolle zuerkannt, wie durch Label(s) definiert.",
  "choiceAutomations.entitlementByRoleInfo": "Mitarbeitern werden zusätzliche Urlaubstage basierend auf ihrer Rolle zuerkannt, wie durch Label(s) definiert. Zum Beispiel können Sie ein Label namens 'Manager' erstellen und es allen Managern in Ihrer Organisation zuweisen. Dann können Sie eine Automatisierung erstellen, die allen Mitarbeitern mit dem 'Manager'-Label 5 zusätzliche Urlaubstage gewährt. Sie können mehrere Automatisierungen für verschiedene Rollen erstellen. Diese Automatisierung gilt ab Ihrem aktuellen Geschäftsjahr. <link>Kontaktieren Sie uns</link>, wenn Sie diese Automatisierung auf vergangene Jahre anwenden müssen.",
  "choiceAutomations.includedInYourPlan": "In Ihrem Plan enthalten",
  "choiceAutomations.leaveDurationLimitInfo": "Urlaubsdauer anpassen: Legen Sie die minimale und maximale Anzahl von Tagen für den gewünschten Zeitraum fest.",
  "choiceAutomations.mySbscribedAddons": "Meine abonnierten Add-ons",
  "choiceAutomations.notActivatedAddons": "Verfügbar zum Abonnieren",
  "choiceAutomations.openApiInfo": "Ermöglicht eine nahtlose Integration mit externen Systemen und ermöglicht automatisierte Datensynchronisation und erweiterte Funktionalität",
  "choiceAutomations.reviewAndBuy": "Überprüfen und kaufen",
  "choiceAutomations.selectAutomations": "Wählen Sie Ihre bevorzugte Automatisierung",
  "choiceAutomations.seniorityEntitlementInfo": "Dienstaltersberechtigung ist ein System, bei dem Mitarbeiter basierend auf ihrer Betriebszugehörigkeit mehr bezahlte Freizeit (PTO) erhalten. Derzeit können Sie nur eine Dienstaltersberechtigung pro Standort erstellen. Diese Automatisierung gilt ab Ihrem aktuellen Geschäftsjahr. <link>Kontaktieren Sie uns</link>, wenn Sie mehr als eine Dienstaltersberechtigung an einem Standort benötigen oder diese Automatisierung auf vergangene Jahre anwenden müssen.",
  "choiceAutomations.seniorityEntitlementShortInfo": "Dienstaltersberechtigung ist ein System, bei dem Mitarbeiter basierend auf ihrer Betriebszugehörigkeit mehr bezahlte Freizeit (PTO) erhalten.",
  "choiceAutomations.teamLeaveBalancingInfo": "Verwalten Sie den prozentualen Anteil gleichzeitiger Teamabwesenheiten.",
  "choiceAutomations.visibilityInfo": "Ermöglicht Administratoren, die Sichtbarkeit von Urlaubsinformationen für reguläre Benutzer anzupassen und verbessert so die Privatsphäre und Datenrelevanz.",
  "company.platformChanged": "Migration zu einer neuen Plattform",
  "company.platformChanged.confirmDescription": "Sie sind dabei, zu einer neuen Plattform zu migrieren. Diese Aktion ist nicht rückgängig zu machen. Bitte überprüfen Sie die folgenden Informationen, bevor Sie fortfahren.",
  "company.platformChanged.confirmDescription.unmappedUsers.deleted": "Wir haben {unmappedUsers} Benutzer gefunden, die in Ihrem {newPlatform}-Konto nicht vorhanden sind. Diese Benutzer werden nach Abschluss der Migration als GELÖSCHT markiert.",
  "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "Wir haben {unmappedUsers} Benutzer gefunden, die in Ihrem {newPlatform}-Konto nicht vorhanden sind. Diese Benutzer werden sich nicht bei Vacation Tracker anmelden können.",
  "company.platformChanged.confirmQuestion": "Sind Sie sicher, dass Sie fortfahren möchten?",
  "company.platformChanged.confirmTitle": "Migration bestätigen",
  "company.platformChanged.description": "Bitte ordnen Sie Ihre Vacation Tracker-Benutzer ihren {newPlatform}-Konten zu. Wir werden Benutzer mit identischen E-Mail-Adressen automatisch zuordnen.",
  "company.platformChanged.googleAccount": "Google-Konto",
  "company.platformChanged.hideInactive": "Inaktive ausblenden:",
  "company.platformChanged.markUnmappedAsDeleted": "Nicht zugeordnete Benutzer als gelöscht markieren?",
  "company.platformChanged.microsoftAccount": "Microsoft-Konto",
  "company.platformChanged.slackAccount": "Slack-Konto",
  "company.platformChanged.title": "Migration von {oldPlatform} zu {newPlatform}",
  "company.platformChanged.unmatched": "Nicht gefunden in {platform}",
  "company.platformChanged.vacationTrackerUser": "Vacation Tracker-Benutzer",
  "component.filterSimple.filterBy": "Filtern nach",
  "component.filterSimple.selectType": "Typ auswählen",
  "component.filterSimple.tooltipInfo": "Wenn Sie keine Filter auswählen, wird der Export alle Benutzer einschließen.",
  "component.notificationDestination.label": "Senden an",
  "component.notificationDestination.tooltip": "Beachten Sie, dass Sie die Benachrichtigung an mehrere E-Mails, aber nur an einen Kanal gleichzeitig senden können.",
  "component.password.timeToHack.days": "{duration} Tage",
  "component.password.timeToHack.hours": "{duration} Stunden",
  "component.password.timeToHack.instantly": "Sofort",
  "component.password.timeToHack.label": "Zeit, die Hacker zum Knacken dieses Passworts benötigen:",
  "component.password.timeToHack.minutes": "{duration} Minuten",
  "component.password.timeToHack.perfect": "Länger als ein Leben",
  "component.password.timeToHack.seconds": "{duration} Sekunden",
  "component.password.timeToHack.years": "{duration} Jahre",
  "component.setPassword.enterOldPassword": "Altes Passwort eingeben",
  "component.setPassword.enterPassword": "Passwort eingeben",
  "component.setPassword.tooltip.description.line1": "Die obigen Informationen zeigen die geschätzte maximale Zeit, die Hacker benötigen, um Ihr ausgewähltes Passwort mit Brute-Force zu knacken. Die Schätzung basiert auf der Tabelle <italic>\"Passwort-Tabelle zum Knacken einer PBKDF2-Hashfunktion mit einer RTX 3090 GPU\"</italic> aus <link>diesem Artikel</link>.",
  "component.setPassword.tooltip.description.line2": "Wie wählt man ein gutes Passwort? Es gibt viele Möglichkeiten, ein gutes Passwort auszuwählen. Wir verwenden Passwort-Manager und verwenden Passwörter nicht mehrfach. Schauen Sie sich diesen <link>lustigen, aber korrekten Leitfaden</link> für weitere Inspiration an.",
  "component.setPassword.tooltip.title": "Zeit, die Hacker benötigen, um Ihr Passwort mit Brute-Force zu knacken",
  "component.setPassword.validation": "Das Passwort muss mindestens Folgendes enthalten: 8 Zeichen, einen Kleinbuchstaben, eine Zahl und ein Symbol.",
  "components.accrualsCalculator.calculate": "Berechnen",
  "components.accrualsCalculator.calculateAccrualsDays": "Anspruchstage berechnen",
  "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "Sie werden <strong>{days}</strong> Tage in der aktuellen Periode an diesem Datum verdienen",
  "components.accrualsCalculator.youWillEarnedInNextPeriod": "Sie werden <strong>{days}</strong> Tage in der nächsten Periode an diesem Datum verdienen",
  "components.addHolidayForm.addHolidays": "Feiertag hinzufügen",
  "components.addHolidayForm.addHolidays.cancel": "Abbrechen",
  "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Halbtägiger Feiertag",
  "components.addHolidayForm.addHolidays.isHolidayMultiday": "Mehr als ein Tag?",
  "components.addHolidayForm.addHolidays.saveHoliday": "Speichern",
  "components.addHolidayForm.date.error": "Bitte wählen Sie ein Datum aus",
  "components.addHolidayForm.editHoliday": "Feiertag bearbeiten",
  "components.addHolidayForm.editHolidays": "Feiertage bearbeiten",
  "components.addHolidayForm.endDate.error": "Bitte wählen Sie ein Enddatum aus",
  "components.addHolidayForm.holidaysName": "Feiertagsname",
  "components.addHolidayForm.holidaysName.error": "Bitte geben Sie einen Feiertagnamen ein",
  "components.addHolidayForm.startDate.error": "Bitte wählen Sie ein Startdatum aus",
  "components.announcementModal.announceToCompany": "Möchten Sie Ihrem Team mitteilen, dass Sie Vacation Tracker verwenden?",
  "components.announcementModal.send": "Ankündigung senden",
  "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} Benutzer<multiUsers></multiUsers> <link>(Benutzer anzeigen<multiUsers></multiUsers>)</link> können keine {leaveTypes} Urlaube nehmen,<endDate> zwischen</endDate> {fromDate} <endDate>und {toDate}</endDate><recurring> jedes Jahr.</recurring>",
  "components.billing.email": "Rechnungs-E-Mail",
  "components.billing.emailPlaceholder": "E-Mail-Adresse eingeben.",
  "components.billing.emailsValidationRequired": "Eine E-Mail ist für Abrechnungszwecke erforderlich.",
  "components.billing.resubscribe": "Erneut abonnieren",
  "components.billingCouponCode.activeCouponInfo": "<percentOffCover>{off}%</percentOffCover><amountOffCover>US${off}</amountOffCover> Rabatt <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} Monat</durationInMonthsCover>, ab {start}",
  "components.billingCouponCode.activeCouponInfoTitle": "Aktiver Gutscheincode",
  "components.billingCouponCode.applyCode": "Code anwenden",
  "components.billingCouponCode.applyCodeAndResubscribe": "Code anwenden & Erneut abonnieren",
  "components.billingCouponCode.couponCode": "Gutscheincode",
  "components.billingCouponCode.text": "Haben Sie einen Gutscheincode? Geben Sie ihn hier ein, um ihn auf Ihr Abonnement anzuwenden.",
  "components.billingCouponCode.title": "Gutscheine",
  "components.billingEmails.emailPlaceholder": "E-Mail-Adresse eingeben.",
  "components.billingEmails.emails": "E-Mails",
  "components.billingEmails.emailsValidationRequired": "Für Abrechnungszwecke ist eine E-Mail-Adresse erforderlich.",
  "components.billingEmails.title": "Abrechnungs-E-Mails",
  "components.billingForm.address1": "Adresszeile 1",
  "components.billingForm.address1ValidationRequired": "Adresse ist erforderlich.",
  "components.billingForm.address2": "Adresszeile 2",
  "components.billingForm.billingEmails": "Abrechnungs-E-Mail",
  "components.billingForm.billingEmailsPlaceholder": "E-Mail-Adresse eingeben.",
  "components.billingForm.billingEmailsValidationRequired": "Für Abrechnungszwecke ist eine E-Mail-Adresse erforderlich.",
  "components.billingForm.ccRequired": "Kartennummer ist erforderlich.",
  "components.billingForm.city": "Stadt",
  "components.billingForm.cityValidationRequired": "Stadt ist erforderlich.",
  "components.billingForm.companyName": "Firmenname",
  "components.billingForm.companyNameValidationRequired": "Firmenname ist erforderlich.",
  "components.billingForm.country": "Land",
  "components.billingForm.countryValidationRequired": "Land ist erforderlich.",
  "components.billingForm.couponCode": "Gutscheincode",
  "components.billingForm.coupons": "Gutscheine",
  "components.billingForm.description": "Bitte geben Sie Ihre Zahlungsinformationen in das obige Formular ein, um Ihr Abonnement mit einer Kreditkarte zu bezahlen.",
  "components.billingForm.invoiceDescription": "Wenn die für die Zahlung des Abonnements verantwortliche Person kein Slack- oder Microsoft Teams-Benutzer ist, können wir ihnen eine Rechnung per E-Mail zusenden, die mit einer Kreditkarte bezahlt werden kann.",
  "components.billingForm.invoiceLabel": "Rechnungen per E-Mail senden",
  "components.billingForm.invoiceTitle": "Zahlung mit per E-Mail versandter Rechnung",
  "components.billingForm.paymentCC": "Kreditkarte",
  "components.billingForm.paymentTitle": "Zahlungsinformationen",
  "components.billingForm.planTitle": "Abrechnungsplan ",
  "components.billingForm.state": "Bundesland",
  "components.billingForm.stateValidationRequired": "Bundesland ist erforderlich.",
  "components.billingForm.title": "Abrechnungsinformationen ",
  "components.billingInfo.address": "Adresse",
  "components.billingInfo.canceledDescription": "Sie haben Ihr Vacation Tracker-Abonnement gekündigt. Es bleibt bis zum {periodEnd} aktiv ",
  "components.billingInfo.canceledTrialDescription": "Ihre Testphase bleibt bis zum {periodEnd} aktiv, danach wird Ihr Vacation Tracker-Konto gekündigt. ",
  "components.billingInfo.companyName": "Firmenname",
  "components.billingInfo.email": "Abrechnungs-E-Mail",
  "components.billingInfo.resubscribe": "Erneut abonnieren",
  "components.billingInfo.text": "Ihre Abrechnungsinformationen werden zur Verarbeitung von Zahlungen für Ihr Abonnement verwendet. Sie können hier Ihre Abrechnungsinformationen aktualisieren und Ihre Kreditkarteninformationen hinzufügen.",
  "components.billingInfo.title": "Abrechnungsinformationen ",
  "components.billingInvoice.description": "Sie können Zahlungsmethoden und Rechnungen in unserem Kundenportal verwalten. <link>Klicken Sie hier, um das Kundenportal zu besuchen</link><creatingLink>Kundenportal-Link wird erstellt</creatingLink>",
  "components.billingInvoice.title": "Rechnungen",
  "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "Wir haben ein anderes Urlaubsverfolgungstool gefunden",
  "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "Können Sie uns bitte sagen, zu welchem Tool Sie wechseln?",
  "components.cancelSubscriptionModal.cancelText": "Abonnement nicht kündigen",
  "components.cancelSubscriptionModal.contactInfo": "Für alles andere kontaktieren Sie bitte unseren Support unter {email}",
  "components.cancelSubscriptionModal.info": "Können Sie uns bitte mitteilen, aus welchem Grund Sie Ihr Vacation Tracker-Abonnement kündigen?",
  "components.cancelSubscriptionModal.min20characters": "Mindestens 20 Zeichen",
  "components.cancelSubscriptionModal.missingFeatures": "Das Tool hat nicht die Funktionen, die mein Team benötigt",
  "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "Können Sie uns in wenigen Worten sagen, welche Funktion fehlt?",
  "components.cancelSubscriptionModal.moreInfo": "Was hätten wir tun können, um Sie als Kunden zu behalten?",
  "components.cancelSubscriptionModal.okText": "Jetzt kündigen",
  "components.cancelSubscriptionModal.option1": "Wir sind zu einer HR-Software gewechselt",
  "components.cancelSubscriptionModal.option2": "Wir haben ein anderes Urlaubsverfolgungstool gefunden",
  "components.cancelSubscriptionModal.option3": "Das Tool hat nicht die Funktionen, die mein Team benötigt",
  "components.cancelSubscriptionModal.option4": "Der Preis liegt über unserem Preisbudget",
  "components.cancelSubscriptionModal.option5": "Sonstiges",
  "components.cancelSubscriptionModal.other": "Sonstiges",
  "components.cancelSubscriptionModal.otherAdditionalInfo": "Sonstiges",
  "components.cancelSubscriptionModal.priceOverBudget": "Der Preis liegt über unserem Preisbudget",
  "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "Was ist Ihr Budget für ein solches Tool?",
  "components.cancelSubscriptionModal.title": "Es tut uns leid, dass Sie gehen...",
  "components.cancelSubscriptionModal.weAreMovingToPayroll": "Wir wechseln zu einem Lohnabrechnungssystem",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "Können Sie uns bitte mitteilen, zu welchem Lohnabrechnungssystem Sie wechseln?",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "Würden Sie bleiben, wenn wir eine Integration mit diesem Lohnabrechnungssystem hätten?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "Wir sind zu einer HR-Software gewechselt",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "Können Sie uns bitte mitteilen, zu welchem HR-System Sie wechseln?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "Würden Sie bleiben, wenn wir eine Integration mit diesem HR-System hätten?",
  "components.cancelTrialSubscriptionModal.cancelText": "Testphase fortsetzen",
  "components.cancelTrialSubscriptionModal.info": "Bitte erklären Sie in einem Satz, warum Sie Ihre Testphase beenden möchten.",
  "components.cancelTrialSubscriptionModal.okText": "Testphase beenden",
  "components.cancelTrialSubscriptionModal.title": "Es tut uns leid, dass Sie gehen...",
  "components.changeTeamForm.changeTeamWarning": "Wählen Sie die Abteilung aus, in die Sie den Benutzer versetzen möchten.",
  "components.changeTeamForm.changeUserTeam": "Abteilung des Benutzers ändern",
  "components.changeTeamForm.selectTeam": "Abteilung auswählen",
  "components.changeTeamForm.selectTeamError": "Bitte wählen Sie eine Abteilung aus.",
  "components.companyAlreadySignup.connectWith": " Mit {existsOnAnotherPlatform} verbinden",
  "components.companyAlreadySignup.info": "Wir haben festgestellt, dass Ihr Unternehmen bereits über ein Vacation Tracker-Konto verfügt. Wenn Sie sich anmelden möchten, klicken Sie einfach hier:",
  "components.companyAlreadySignup.signupWithMail": "Mit Ihrer Arbeits-E-Mail verbinden",
  "components.companyAlreadySignup.title": "Ihr Unternehmen verwendet bereits Vacation Tracker",
  "components.companyLogs.dateRangeLimit": "Protokolle, die älter als 3 Monate sind, sind nur im vollständigen Plan verfügbar.",
  "components.companyLogs.rangeLimit": "Der Datumsbereich kann höchstens ein Jahr betragen",
  "components.companySettings.contactEmailInfo": "Ihre neue Kontakt-E-Mail-Adresse für das Unternehmen lautet: {email}.",
  "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "Weitere Informationen finden Sie in unserem <helpDesk>Helpdesk</helpDesk>.",
  "components.comparePlatformsModal.info": "Unabhängig von der gewählten Plattform haben Sie Zugang zu allen wichtigen Funktionen von Vacation Tracker. Einige haben jedoch zusätzliche Vorteile, die bei anderen nicht verfügbar sind. Konsultieren Sie die Vergleichstabelle unten.",
  "components.comparePlatformsModal.table.comingSoon": "Demnächst verfügbar",
  "components.comparePlatformsModal.table.email": "E-Mail",
  "components.comparePlatformsModal.table.feature": "Funktion",
  "components.comparePlatformsModal.table.google": "Google",
  "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Benutzer aus Ihrem Google Workspace importieren",
  "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Benutzer aus Ihrem Microsoft Active Directory importieren",
  "components.comparePlatformsModal.table.importUsersFromSlack": "Benutzer aus Ihrem Slack-Workspace importieren",
  "components.comparePlatformsModal.table.inviteUsersViaEmail": "Benutzer per E-Mail einladen",
  "components.comparePlatformsModal.table.manageLeaveTypes": "Urlaubsarten, Feiertage und Urlaubsanträge verwalten",
  "components.comparePlatformsModal.table.microsoft": "Microsoft",
  "components.comparePlatformsModal.table.multiTeamLocation": "Mehrere Abteilungen und Standorte mit unterschiedlichen Urlaubsrichtlinien konfigurieren",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Urlaubsanträge direkt aus Microsoft Teams einreichen und verwalten",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Urlaubsanträge direkt aus Slack einreichen und verwalten",
  "components.comparePlatformsModal.table.slack": "Slack",
  "components.comparePlatformsModal.table.syncToGoogleCalendar": "Vacation Tracker mit Google Kalender synchronisieren",
  "components.comparePlatformsModal.table.syncToOutlookCalendar": "Vacation Tracker mit Outlook 365 Kalender synchronisieren",
  "components.comparePlatformsModal.title": "Welche Vacation Tracker-Integration ist die richtige für mich?",
  "components.configureImport.configureProratedLeaveTypesErrorMessage": "Sie müssen mindestens eine Urlaubsart auswählen",
  "components.configureImport.defaultDepartmentTooltip": "Alle importierten Benutzer werden dieser Abteilung hinzugefügt. Nach dem Import können Sie sie in eine andere Abteilung versetzen.",
  "components.configureImport.defaultLocationTooltip": "Alle importierten Benutzer werden diesem Standort hinzugefügt. Nach dem Import können Sie sie an einen anderen Standort versetzen.",
  "components.configureImport.guestUsers": "Möchten Sie Multichannel-Gastbenutzer importieren?",
  "components.configureImport.guestUsersTooltip": "Wenn diese Option aktiviert ist, werden Multichannel-Gastbenutzer aus Ihrem Arbeitsbereich in den Vacation Tracker importiert.",
  "components.configureImport.proRateLabel": "Das Kontingent der neuen Benutzer für dieses Jahr anteilig berechnen?",
  "components.configureImport.proRateLeaveTypes": "Anteilige Berechnung auf folgende Urlaubsarten anwenden",
  "components.configureImport.proRateLeaveTypesTooltip": "Bitte listen Sie die Urlaubsarten auf, für die Sie die verfügbaren Benutzerurlaubskontingente anteilig berechnen möchten.",
  "components.configureImport.proRateTooltip": "Bitte wählen Sie aus, ob unser System das verfügbare Urlaubskontingent der importierten Benutzer für das erste Jahr basierend auf Ihren Unternehmenseinstellungen und dem Startdatum des Benutzers berechnen soll.",
  "components.createLeaveType.assignToLocationManyInProgress": "Urlaubsrichtlinie wird Standorten zugewiesen",
  "components.createLeaveType.assignToLocationManyInProgressCompleted": "Zuweisung der Urlaubsrichtlinie zu Standorten abgeschlossen",
  "components.createLeaveType.assignToLocationOneInProgress": "Urlaubsrichtlinie wird Standort zugewiesen",
  "components.createLeaveType.assignToLocationOneInProgressCompleted": "Zuweisung der Urlaubsrichtlinie zu Standort abgeschlossen",
  "components.createLeaveType.createInProgress": "Erstellen des Urlaubstyps {leaveTypeName}",
  "components.createLeaveTypeForm.assignAndConfigure": "Zuweisen & Konfigurieren",
  "components.createLeaveTypeForm.assignToLocations": "Standorten zuweisen",
  "components.createLeaveTypeForm.calendarStatusBusy": "Beschäftigt",
  "components.createLeaveTypeForm.calendarStatusFree": "Frei",
  "components.createLeaveTypeForm.calendarStatusLabel": "Diesen Urlaubstyp in Ihrem Kalender anzeigen als",
  "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Wählen Sie 'Beschäftigt' für vollständige Abwesenheit wie Urlaub, um Nichtverfügbarkeit anzuzeigen, oder 'Frei' für Homeoffice-Tage, um anzuzeigen, dass Sie nicht im Büro, aber verfügbar sind.",
  "components.createLeaveTypeForm.continueCreatingLeaveType": "Ja, ich möchte den Urlaubstyp erstellen",
  "components.createLeaveTypeForm.createAndAssign": "Erstellen und Zuweisen",
  "components.createLeaveTypeForm.createLeaveType": "Urlaubstyp erstellen",
  "components.createLeaveTypeForm.justLastStep.assign": "Zuweisen",
  "components.createLeaveTypeForm.justLastStep.title": "Urlaubsrichtlinie zuweisen",
  "components.createLeaveTypeForm.Locations": "Standorte",
  "components.createLeaveTypeForm.locationsInfo": "<strong>{leaveTypeName}</strong> allen Standorten oder einem bestimmten Standort zuweisen.",
  "components.createLeaveTypeForm.locationsPolicyHint": "Um die Richtlinie (Regeln) für diesen Urlaubstyp zu bearbeiten, gehen Sie bitte zu den spezifischen {locations, plural, =1 {Standort} other {Standorten}}:",
  "components.createLeaveTypeForm.selectLocation": "Standort auswählen",
  "components.createLeaveTypeForm.similarLeaveTypeChecking": "Überprüfung auf vorhandene Urlaubstypen...",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "Möchten Sie trotzdem einen neuen Typ erstellen (<strong>{newLeaveTypeName}</strong>)?",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "Sie können einen Urlaubstyp mehreren Standorten zuweisen und Regeln pro Standort anpassen (wie Kontingente, Übertrag, Ansammlungen usw.). Es ist nicht nötig, separate Typen für jeden Ort zu erstellen!",
  "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "Wir empfehlen, einen einzigen Urlaubstyp für mehrere Standorte zu verwenden, um die Benutzerdaten und Konsistenz zu erhalten.",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Legen Sie die standortspezifischen Regeln fest für:",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "Ein ähnlicher Urlaubstyp existiert bereits (<strong>{leaveTypeName}</strong>).",
  "components.createLeaveTypeForm.similarLeaveTypeTitle": "Ähnlicher Urlaubstyp",
  "components.createLeaveTypeForm.title": "Urlaubstyp erstellen & Urlaubsrichtlinie zuweisen",
  "components.denyWithReasonForm.deny": "Ablehnen",
  "components.denyWithReasonForm.denyLeaveRequest": "Urlaubsantrag ablehnen",
  "components.denyWithReasonForm.reason": "Grund hinzufügen",
  "components.denyWithReasonForm.reasonIsRequired": "Dieses Feld ist erforderlich.",
  "components.downgradeAlert.cancelChangeToPlan": "Änderung zum <strong>{nextPlan}</strong>-Plan abbrechen",
  "components.downgradeAlert.cancelSwitchPlan": "Möchten Sie die geplante Änderung zum <strong>{nextPlan}</strong> {nextPeriod}-Plan abbrechen?",
  "components.downgradeAlert.noIWantToSwitch": "Nein, ich möchte zum <strong>{nextPlan}</strong> {nextPeriod}-Plan wechseln",
  "components.downgradeAlert.yesIWantToStay": "Ja, ich möchte beim <strong>{currentPlan}</strong> {currentPeriod}-Plan bleiben",
  "components.entitlementByRole.additionalDays": "zusätzliche(r) Tag(e)",
  "components.entitlementByRole.additionalHours": "zusätzliche Stunde(n)",
  "components.entitlementByRole.applayToUsersWithLabel": "Auf Benutzer mit Label anwenden",
  "components.entitlementByRole.applyToUsersWithLabel": "Auf Benutzer mit Label anwenden",
  "components.entitlementByRole.completePlanOnly": "Diese Funktion ist ausschließlich für Benutzer zugänglich, die unseren Complete-Plan abonniert haben.",
  "components.entitlementByRole.noLabels": "Es gibt keine erstellten Labels oder alle werden für andere Ansprüche nach Rolle-Automatisierungen verwendet.",
  "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Anspruch nach Rolle anwenden ab",
  "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Anspruch nach Rolle anwenden ab",
  "components.fakeDoorMailLoginModal.discount": "Als Teil der frühen Zugangsgruppe erhalten Sie in den ersten 3 Monaten auch 50% Rabatt auf Vacation Tracker.",
  "components.fakeDoorMailLoginModal.info": "Wir arbeiten derzeit daran, die E-Mail-Anmeldung zu ermöglichen. Um frühzeitigen Zugang zu erhalten, melden Sie sich bitte unten an und wir werden uns in Kürze bei Ihnen melden.",
  "components.fakeDoorMailLoginModal.no": "Nein, ich warte.",
  "components.fakeDoorMailLoginModal.title": "Interessiert an frühzeitigem Zugang? 🚀 ",
  "components.fakeDoorMailLoginModal.yes": "Ja, ich möchte frühzeitigen Zugang erhalten.",
  "components.feedbackModal.button": "Geben Sie uns Feedback",
  "components.feedbackModal.emailPlaceholder": "Wie lautet Ihre E-Mail-Adresse?",
  "components.feedbackModal.feedbackForm": "Feedback-Formular",
  "components.feedbackModal.feedbackMessage": "Feedback-Nachricht",
  "components.feedbackModal.howDoYouLikeApp": "Wie gefällt Ihnen unsere App?",
  "components.feedbackModal.howDoYouLikeAppPlaceholder": "Wählen Sie eine Option...",
  "components.feedbackModal.iLikeIt": "Ich mag es",
  "components.feedbackModal.iLoveIt": "Ich liebe es",
  "components.feedbackModal.ItMakesMeCry": "Es bringt mich zum Weinen",
  "components.feedbackModal.itsOk": "Es ist okay",
  "components.feedbackModal.messagePlaceholder": "Ihre Feedback-Nachricht",
  "components.feedbackModal.Nah": "Nee",
  "components.feedbackModal.namePlaceholder": "Geben Sie Ihren Namen ein",
  "components.feedbackModal.ratingRequired": "Bitte wählen Sie eine der angegebenen Bewertungen aus",
  "components.feedbackModal.sendFeedback": "Feedback senden",
  "components.feedbackModal.yourEmail": "Ihre E-Mail-Adresse",
  "components.feedbackModal.yourEmailInfo": "Wenn Ihr Feedback eine Antwort erfordert, geben Sie bitte Ihre E-Mail-Adresse ein, damit wir Sie kontaktieren können.",
  "components.feedbackModal.yourName": "Ihr Name",
  "components.filterSimple.filterBy": "Filtern nach",
  "components.filterSimple.selectType": "Typ auswählen",
  "components.filterSimple.tooltipInfo": "Wenn Sie keine Filter auswählen, wird der Export alle Benutzer einschließen.",
  "components.generalSettings.aiBotHelperLabel": "Kommunikation mit dem Vacation Tracker Bot erlauben",
  "components.generalSettings.aiBotHelperLabelDisable": "Kommunikation mit dem Vacation Tracker Bot deaktivieren",
  "components.generalSettings.aiBotHelperTooltip": "Das Messaging mit dem Bot ist eine bequeme und effiziente Möglichkeit für Mitarbeiter, Urlaub zu beantragen. Mitarbeiter können einfach eine Urlaubsanfrage an den Bot senden und dabei Details wie Start- und Enddatum, die Anzahl der beantragten Tage und die Art des Urlaubs angeben.",
  "components.heatmap.clickForMore": "Klicken Sie für mehr Informationen",
  "components.heatmap.holiday": "Feiertag",
  "components.heatmap.holidayDescription": "{location} Standort ist abwesend für {holiday}",
  "components.heatmap.loading": "Lade {day}",
  "components.heatmap.nextMonth": "Nächster Monat",
  "components.heatmap.nonWorkingDay": "arbeitsfreier Tag",
  "components.heatmap.previousMonth": "Vorheriger Monat",
  "components.heatmap.showLess": "Weniger anzeigen",
  "components.heatmap.showMore": "{amount} mehr anzeigen",
  "components.heatmap.today": "heute",
  "components.helpDrawer.adminProductTourInfo": "Als Administrator haben Sie die höchste Berechtigungsstufe in Vacation Tracker und können alle Einstellungen der Organisation über das Administrator-Dashboard anpassen.\n\nDas Dashboard ermöglicht Ihnen: \n- Urlaub beantragen oder für andere Abteilungsmitglieder hinzufügen. \n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender. \n- Mehrere Abteilungen erstellen und konfigurieren (Genehmiger zuweisen) \n- Benachrichtigungen einstellen  \n- Benutzer verwalten (Aktiv, Inaktiv, Gelöscht).\n- Standorte erstellen und verwalten (Urlaubsarten, Feiertage, Benachrichtigungen)\n\nDer Bot ermöglicht Ihnen: \n- Urlaub beantragen \n- Urlaubsanträge genehmigen/ablehnen\n- Tägliche/wöchentliche Benachrichtigungen erhalten\n\nWenn Ihre Fragen oder Anliegen unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen, ist das Beste an unserem Tag: ",
  "components.helpDrawer.adminProductTourTitle": "Ihre Rolle: Administrator",
  "components.helpDrawer.approverProductTourInfo": "Als Genehmiger haben Sie die zweithöchste Berechtigungsstufe in Vacation Tracker und können Urlaubsanträge genehmigen oder ablehnen, die von Benutzern aus der Abteilung eingereicht wurden, für die Sie als Genehmiger zugewiesen sind.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen oder für andere Abteilungsmitglieder hinzufügen.\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender. \n- Benachrichtigungen einstellen \n- Ihr Benutzerprofil anzeigen.\n- Anträge verwalten (Anträge genehmigen/ablehnen, Verlauf anzeigen).\n\nDer Bot ermöglicht Ihnen:\n- Urlaub beantragen\n- Urlaubsanträge genehmigen/ablehnen\n- Tägliche/wöchentliche Benachrichtigungen erhalten\n\nWenn Ihre Fragen oder Anliegen unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen, ist das Beste an unserem Tag: ",
  "components.helpDrawer.approverProductTourTitle": "Ihre Rolle: Genehmiger",
  "components.helpDrawer.google.adminProductTourInfo": "Als Administrator haben Sie die höchste Berechtigungsstufe in Vacation Tracker und können alle Einstellungen der Organisation über das Administrator-Dashboard anpassen.\n\nDas Dashboard ermöglicht Ihnen: \n- Urlaub beantragen oder für andere Abteilungsmitglieder hinzufügen. \n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender. \n- Mehrere Abteilungen erstellen und konfigurieren (Genehmiger zuweisen)  \n- Benachrichtigungen einstellen \n- Benutzer verwalten (Aktiv, Inaktiv, Gelöscht).\n- Standorte erstellen und verwalten (Urlaubsarten, Feiertage, Benachrichtigungen)\n\nWenn Ihre Fragen oder Anliegen unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen, ist das Beste an unserem Tag: ",
  "components.helpDrawer.google.approverProductTourInfo": "Als Genehmiger haben Sie die zweithöchste Berechtigungsstufe in Vacation Tracker und können Urlaubsanträge genehmigen oder ablehnen, die von Benutzern aus der Abteilung eingereicht wurden, für die Sie als Genehmiger zugewiesen sind.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen oder für andere Abteilungsmitglieder hinzufügen.\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender. \n- Benachrichtigungen einstellen \n- Ihr Benutzerprofil anzeigen.\n- Anträge verwalten (Anträge genehmigen/ablehnen, Verlauf anzeigen).\n\nWenn Ihre Fragen oder Anliegen unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen, ist das Beste an unserem Tag: ​​​​​​​​",
  "components.helpDrawer.google.userProductTourInfo": "Als Vacation Tracker Benutzer können Sie Ihre Urlaubsanträge über das Dashboard einreichen und verwalten sowie einen vollständigen Überblick über geplante Abwesenheiten innerhalb Ihrer Organisation erhalten.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender.\n- Ihr Benutzerprofil anzeigen.\n\nWenn Ihre Fragen oder Anliegen unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen, ist das Beste an unserem Tag: ​​​​​​​​",
  "components.helpDrawer.helpDeskInfo": "Besuchen Sie unser Helpdesk, um mehr über unser Produkt zu erfahren und Antworten auf einige der häufigsten Fragen zu erhalten.",
  "components.helpDrawer.learnMore": "Mehr erfahren",
  "components.helpDrawer.needhelp": "Brauchen Sie Hilfe?",
  "components.helpDrawer.quickTourInfo": "Wir haben einige kurze Schritte zusammengestellt, die wir Ihnen empfehlen, um Ihre Organisation schnell einzurichten.",
  "components.helpDrawer.quickTourTitle": "Kurzführung",
  "components.helpDrawer.supportInfo": "Wenn Sie Fragen haben oder Hilfe benötigen, starten Sie einen Chat mit unserem Kundensupport-Team oder schreiben Sie uns eine E-Mail an <link>hello@vacationtracker.io</link>",
  "components.helpDrawer.supportTitle": "Unterstützung",
  "components.helpDrawer.userProductTourInfo": "Als Vacation Tracker Benutzer können Sie Ihre Urlaubsanträge über das Dashboard oder den Bot einreichen und verwalten sowie einen vollständigen Überblick über geplante Abwesenheiten in Ihrer Organisation erhalten.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen\n- Einen vollständigen Überblick über die Dashboard-Startseite und den Kalender zu haben.\n- Ihr Benutzerprofil anzusehen.\n\nDer Bot ermöglicht Ihnen:\n- Urlaub zu beantragen \n- Tägliche/Wöchentliche Benachrichtigungen zu erhalten\n\nWenn Ihre Fragen oder Anliegen unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen, ist der schönste Teil unseres Tages: ",
  "components.helpDrawer.userProductTourTitle": "Ihre Rolle: Benutzer",
  "components.importHolidaysForm.backButton": "Zurück",
  "components.importHolidaysForm.selectCountry": "Land auswählen",
  "components.importHolidaysForm.selectHolidays": "Feiertage auswählen",
  "components.importHolidaysForm.selectYear": "Jahr auswählen",
  "components.importUsersForm.button.importAllUsers": "Alle {users} Benutzer importieren und aktivieren",
  "components.importUsersForm.button.importSelectedUsers": "{noOfUsers} ausgewählte {noOfUsers, plural, =1 {Benutzer} other {Benutzer}} importieren",
  "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "Möchten Sie alle Benutzer automatisch von {platform} importieren?",
  "components.importUsersForm.calculationStartYearInfo": "Vacation Tracker wird Urlaube ab {year} berechnen",
  "components.importUsersForm.cancel": "Abbrechen",
  "components.importUsersForm.chooseUsersPlaceholder": "Name des Teammitglieds",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "Vacation Tracker wird keine Multichannel-Gastbenutzer automatisch aus Ihrem Workspace importieren. Sie können sie bei Bedarf manuell importieren.",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "Vacation Tracker wird Multichannel-Gastbenutzer automatisch aus Ihrem Workspace importieren.",
  "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "Sie können diese Einstellungen später im Vacation Tracker Dashboard ändern",
  "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "Neue {noOfUsers, plural, =1 {Benutzer} other {Benutzer}} werden der Abteilung {chosenDepartment} und dem Standort {chosenLocation} hinzugefügt",
  "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "{noOfUsers} {noOfUsers, plural, =1 {Benutzer} other {Benutzer}} importieren",
  "components.importUsersForm.confirmImportUsersModalContentOpener": "Sie haben ausgewählt:",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Anteilige Berechnung des Urlaubskontingents für die folgenden Urlaubsarten: {leaveTypes}",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "Das Urlaubskontingent der Benutzer wird nicht mehr anteilig berechnet",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Eine Willkommensnachricht {channel, select, Email {} other {}} {channel} an {noOfUsers, plural, =1 {den neuen Benutzer} other {die neuen Benutzer}} senden",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "Wir werden keine Willkommensnachricht mehr an neue Benutzer senden",
  "components.importUsersForm.confirmImportUsersModalTitle": "Import von {noOfUsers} ausgewählten Benutzern bestätigen",
  "components.importUsersForm.consent.line1": "Um Benutzer zu importieren, benötigen Sie die Administratorzustimmung von Ihrem Microsoft Teams-Administrator. Um diese einmalige Zustimmung zu erhalten, senden Sie bitte den folgenden Link an Ihren Microsoft Teams-Administrator:",
  "components.importUsersForm.consent.line2": "Sobald Sie die Zustimmung erhalten haben, schließen und öffnen Sie dieses Modal erneut, und diese Nachricht wird verschwinden.",
  "components.importUsersForm.consent.warning": "Bitte schließen und öffnen Sie dieses Modal erneut, sobald Sie die Zustimmung erhalten haben, um alle Benutzer importieren zu können.",
  "components.importUsersForm.googleAPIErrorMessage": "Es scheint ein Problem mit den Einstellungen in Ihrem Google-Verzeichnis zu geben. Bitte kontaktieren Sie Ihren Administrator und senden Sie ihm den folgenden Fehler:",
  "components.importUsersForm.importAllUsers": "Möchten Sie alle Benutzer importieren?",
  "components.importUsersForm.importAllUsersExceedingLimt": "Sie haben eine große Anzahl von Benutzern in Ihrem Verzeichnis. Wenn Sie alle Benutzer importieren möchten, kontaktieren Sie bitte unser Support-Team, und wir können den Import ermöglichen. Alternativ können Sie spezifische Benutzer zum Importieren auswählen.",
  "components.importUsersForm.importAllUsersTooltip": "{noOfUsersToImport} {noOfUsersToImport, plural, =1 {Benutzer} other {Benutzer}} von {platform} werden in Vacation Tracker importiert. Dies kann Ihre Abrechnung beeinflussen.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "Sie haben eine große Anzahl von Benutzern in Ihrem Verzeichnis. Wenn Sie Benutzer automatisch importieren möchten, kontaktieren Sie bitte unser Support-Team, und wir können den Import ermöglichen.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "Sie haben eine große Anzahl von Benutzern in Ihrem Verzeichnis. Wenn Sie Benutzer automatisch importieren möchten, kontaktieren Sie bitte unser Support-Team, und wir können den Import ermöglichen.",
  "components.importUsersForm.importUsersAutomaticallyTooltip": "Sobald ein neu hinzugefügter Benutzer aus Ihrer {platform} in Vacation Tracker importiert wird, erhält er eine kurze Nachricht, die erklärt, was Vacation Tracker ist und wie man es navigiert.",
  "components.importUsersForm.importUsersTitle": "Benutzer importieren",
  "components.importUsersForm.newCompanyNameInfo": "Neuer Firmenname wird sein: {newName}",
  "components.importUsersForm.selectUsers": "Benutzer zum Importieren auswählen:",
  "components.importUsersForm.selectUsersNext": "Einstellungen für ausgewählte Benutzer konfigurieren",
  "components.importUsersForm.selectUsersStepName": "Benutzer auswählen",
  "components.importUsersForm.sendWelcomeMessageLabel": "Eine Willkommensnachricht an die neuen Benutzer senden?",
  "components.importUsersForm.trackingQuotaInfoDays": "Vacation Tracker wird die Dauer aller Urlaube und Kontingente in Tagen verfolgen.",
  "components.importUsersForm.trackingQuotaInfoHours": "Vacation Tracker wird die Dauer aller Urlaube und Kontingente in Stunden verfolgen.",
  "components.importUsersForm.userEndDateInfo": "Benutzer mit einem Enddatum können keine Urlaubsanträge nach diesem Datum einreichen, und wir werden sie automatisch deaktivieren.",
  "components.importUsersForm.userEndDateTurnOffInfo": "Benutzer mit einem Enddatum können Urlaubsanträge nach diesem Datum einreichen, und wir werden sie nicht automatisch deaktivieren.",
  "components.importUsersForm.usersIsRequired": "Dieses Feld ist erforderlich.",
  "components.importUsersForm.warningAllUsersImport": "Vacation Tracker kann Benutzer von Ihrer {platform} automatisch synchronisieren, importieren und löschen.",
  "components.importUsersForm.warningNumberOfUsersToImport": "Wenn Sie auf die Schaltfläche \"Alle {users} Benutzer importieren und aktivieren\" klicken, aktivieren Sie alle {users} Benutzer aus Ihrem Verzeichnis. Diese Aktion kann Ihre Abrechnung beeinflussen.",
  "components.importUsersForm.warningOfUsersImport": "Der Import von {noOfUsers} ausgewählten {noOfUsers, plural, =1 {Benutzer} other {Benutzern}} kann Ihre Abrechnung beeinflussen.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "Das Hinzufügen von {noOfUsers} {noOfUsers, plural, =1 {Benutzer} other {Benutzern}} wird Ihre nächste Rechnung ab {startDate} auf {amount} (ohne MwSt.) erhöhen.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "Eine geschätzte anteilige Gebühr von {proratedAmount} (ohne MwSt.) wird heute berechnet.",
  "components.importUsersForm.warningOfUsersImportNoChange": "Sie sind dabei, {noOfUsers} {noOfUsers, plural, =1 {Benutzer} other {Benutzer}} zu importieren. Ihre Abrechnung wird nicht beeinflusst.",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "Sie haben Ihr aktuelles Sitzplatzlimit ({seats}) erreicht. Möchten Sie mehr kaufen?",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "Sie können nicht alle Benutzer aus Ihrem Arbeitsbereich/Verzeichnis importieren, da Sie dann Ihr aktuelles Sitzplatzlimit ({seats}) überschreiten würden. Möchten Sie mehr kaufen?",
  "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "Der Import von {noOfUsers} {noOfUsers, plural, =1 {Benutzer} other {Benutzern}} wird Ihre Abrechnung beeinflussen. Ihre nächste Rechnung wird am {startDate} {amount} (ohne MwSt.) betragen",
  "components.importUsersForm.warningOfUsersImportTrial": "Sie befinden sich derzeit im Testmodus. Ihnen wird am {startDate} {amount} (ohne MwSt.) berechnet",
  "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "Sie befinden sich in einer Testphase und es werden Ihnen vor dem {stripeCurrentPeriodEnd} keine zusätzlichen Benutzerimporte in Rechnung gestellt. Fügen Sie Ihre Zahlungsinformationen hinzu, um die Kostenabschätzung zu sehen.",
  "components.insertHolidaysForm.back": "Zurück",
  "components.insertHolidaysForm.deselectAll": "Alle abwählen",
  "components.insertHolidaysForm.findHolidays": "Feiertage finden",
  "components.insertHolidaysForm.insertHolidays": "Feiertage importieren",
  "components.insertHolidaysForm.pleaseChooseACountry": "Wählen Sie ein Land",
  "components.insertHolidaysForm.saveHolidays": "Feiertage speichern",
  "components.insertHolidaysForm.selectAll": "Alle auswählen",
  "components.insertHolidaysForm.selectHolidays": "Feiertage auswählen",
  "components.insertHolidaysForm.subtitle": "Die Änderung kann die Anzahl der Urlaubstage für einige Benutzer beeinflussen. Diesen Benutzern werden Tag(e) zu ihrem verfügbaren Kontingent für ihren beantragten Urlaub hinzugefügt. Sind Sie sicher, dass Sie fortfahren möchten?",
  "components.insertHolidaysForm.title": "Das Importieren von Feiertagen überschreibt alle zuvor hinzugefügten Feiertage.",
  "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "Sie können sich nicht selbst einladen",
  "components.inviteAndManageUsersWithEmail.header": "E-Mail-Einladungen",
  "components.inviteAndManageUsersWithEmail.userAlreadyExists": "Benutzer mit dieser E-Mail-Adresse existiert bereits",
  "components.inviteBotToPrivateChannel.descriptionOne": "Um Vacation Tracker zu einem privaten Kanal hinzuzufügen, geben Sie bitte",
  "components.inviteBotToPrivateChannel.descriptionTwo": "in den Kanal ein und aktualisieren Sie die Liste",
  "components.inviteBotToPrivateChannel.text": "Möchten Sie einen privaten Kanal auswählen?",
  "components.inviteBotToPrivateChannel.title": "Privaten Kanal hinzufügen",
  "components.leaveBox.approve": "Genehmigen",
  "components.leaveBox.approvedBy": "Genehmigt von",
  "components.leaveBox.approveLeaveText": "Bitte bestätigen Sie, dass Sie den Urlaubsantrag genehmigen möchten.",
  "components.leaveBox.approveLeaveTitle": "Urlaubsantrag genehmigen",
  "components.leaveBox.autoApproved": "Automatisch genehmigt",
  "components.leaveBox.cancelLeave": "Urlaub stornieren",
  "components.leaveBox.cancelLeaveConfirm": "Sind Sie sicher, dass Sie den Urlaub stornieren möchten?",
  "components.leaveBox.cancelLeaveTitle": "Urlaub stornieren",
  "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {Tag} other {Tage}}",
  "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {Stunde} other {Stunden}}",
  "components.leaveBox.daysLeft": "Verbleibender Urlaub:",
  "components.leaveBox.daysOut": "Das sind {value} {value, plural, =1 {Tag} other {Tage}} außerhalb des Büros.",
  "components.leaveBox.deny": "Ablehnen",
  "components.leaveBox.denyLeaveConfirm": "Bitte bestätigen Sie, dass Sie den Urlaubsantrag ablehnen möchten.",
  "components.leaveBox.denyLeaveTitle": "Urlaubsantrag ablehnen",
  "components.leaveBox.denyWithReason": "Mit Begründung ablehnen",
  "components.leaveBox.edit": "Bearbeiten",
  "components.leaveBox.formattedLeaveInfo": "Das sind {leave} außerhalb des Büros.",
  "components.leaveBox.hoursOut": "Das sind {value} {value, plural, =1 {Stunde} other {Stunden}} außerhalb des Büros.",
  "components.leaveBox.leavesBoxHalfDay": "Wird am {leaveStartDate} für einen {leaveTypeName} von {startHour}{hourFormat24, select, true {:00} other {}} bis {endHour}{hourFormat24, select, true {:00} other {}} abwesend sein.",
  "components.leaveBox.leavesBoxHalfDayPending": "Antrag für {leaveTypeName} am {leaveStartDate} von {startHour}{hourFormat24, select, true {:00} other {}} bis {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayToday": "Ist heute von {startHour}{hourFormat24, select, true {:00} other {}} bis {endHour}{hourFormat24, select, true {:00} other {}} abwesend.",
  "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "Ist heute von {startHour}{hourFormat24, select, true {:00h} other {}} bis {endHour}{hourFormat24, select, true {:00h} other {}} abwesend.",
  "components.leaveBox.leavesBoxHalfDayWithoutReason": "Nimmt am {leaveStartDate} von {startHour}{hourFormat24, select, true {:00} other {}} bis {endHour}{hourFormat24, select, true {:00} other {}} Urlaub.",
  "components.leaveBox.leavesOffTodayInfo": "Ist für einen {leaveTypeName} vom {leaveStartDate} bis {leaveEndDate} abwesend. Beide Daten eingeschlossen.",
  "components.leaveBox.leavesOffTodayWithoutReasonInfo": "Ist abwesend von {leaveStartDate} bis {leaveEndDate}. Beide Daten eingeschlossen.",
  "components.leaveBox.leavesPendingInfo": "Anfrage für {leaveTypeName} von {leaveStartDate} bis {leaveEndDate}. Beide Daten eingeschlossen.",
  "components.leaveBox.leavesPendingOneDay": "Anfrage für {leaveTypeName} am {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayInfo": "Wird am {leaveStartDate} für {leaveTypeName} abwesend sein.",
  "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "Wird am {leaveStartDate} abwesend sein.",
  "components.leaveBox.reason": "Grund",
  "components.leaveBox.toilDuration": "Dauer: {leave}",
  "components.leaveForm.addLeave": "Urlaub hinzufügen",
  "components.leaveForm.addLeaveDescription": "Verwenden Sie dieses Formular, wenn Sie Urlaub für einen anderen Benutzer hinzufügen oder wenn Sie Urlaub für sich selbst hinzufügen, der keine Genehmigung erfordert.",
  "components.leaveForm.connectedCalendarTimezone": "Verbundene {calendarType}-Kalender-Zeitzone ist {timezone}",
  "components.leaveForm.date": "Datum",
  "components.leaveForm.editLeave": "Urlaub bearbeiten",
  "components.leaveForm.editLeaveDescription.adminAndApprover": "Sie können die Daten für diesen Urlaub bearbeiten. Wenn Sie den Urlaubstyp ändern möchten, stornieren Sie bitte den Urlaub und fügen Sie einen neuen hinzu.",
  "components.leaveForm.editLeaveDescription.user": "Sie können die Daten und den Grund für diesen Urlaub bearbeiten. Wenn Sie den Urlaubstyp ändern möchten, stornieren Sie bitte den Urlaub und erstellen Sie eine neue Anfrage.",
  "components.leaveForm.endTime": "Endzeit",
  "components.leaveForm.halfDay": "{leaveTypeName} (Halbtags)",
  "components.leaveForm.hourly": "{leaveTypeName} (Stündlich)",
  "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (Stündlich)",
  "components.leaveForm.pleaseInputReason": "Grund ist erforderlich",
  "components.leaveForm.pleaseSelectEndDate": "Wählen Sie ein Enddatum",
  "components.leaveForm.pleaseSelectLeaveType": "Wählen Sie einen Urlaubstyp",
  "components.leaveForm.pleaseSelectRangeTime": "Wählen Sie einen Zeitbereich",
  "components.leaveForm.pleaseSelectstartDate": "Wählen Sie ein Startdatum",
  "components.leaveForm.pleaseSelectUser": "Wählen Sie einen Benutzer",
  "components.leaveForm.pleaseSelectValidRangeTime": "Wählen Sie einen gültigen Zeitbereich",
  "components.leaveForm.rangeDate": "Start- und Enddatum",
  "components.leaveForm.reason": "Grund",
  "components.leaveForm.request": "Anfragen",
  "components.leaveForm.requestLeave": "Urlaub beantragen",
  "components.leaveForm.requestLeaveDescription": "Verwenden Sie dieses Formular, wenn Ihre Anfrage von jemand anderem als Ihnen selbst genehmigt werden muss.",
  "components.leaveForm.selectSubstituteApprover": "Stellvertretenden Genehmiger auswählen",
  "components.leaveForm.selectSubstituteApproverInfoMessage": "Wählen Sie eine Person aus, die Ihre eingehenden Urlaubsanfragen während Ihrer Abwesenheit verwaltet. Der stellvertretende Genehmiger hat eingeschränkte Rechte und kann nur Urlaubsanfragen genehmigen oder ablehnen. Dieser Schritt ist optional.",
  "components.leaveForm.selectUser": "Wählen Sie einen Benutzer",
  "components.leaveForm.startTime": "Startzeit",
  "components.leaveForm.time": "Zeit",
  "components.leaveForm.user": "Wählen Sie einen Benutzer",
  "components.leavePolicyForm.accrualBiWeeklySummary": "Benutzer erhalten alle zwei {day} {amount} ({quota}/26) Tage",
  "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Benutzer erhalten alle zwei {day} {amount} ({quota}/26) Stunden",
  "components.leavePolicyForm.accrualCap": "Ansammlungsobergrenze aktivieren",
  "components.leavePolicyForm.accrualCapRate": "Verdiente Tage begrenzen auf",
  "components.leavePolicyForm.accrualCapRateTimes": "mal die jährliche Ansammlungsrate",
  "components.leavePolicyForm.accrualCapTooltip": "Die Ansammlungsobergrenze begrenzt, wie viel bezahlte Freizeit ein Mitarbeiter in einem bestimmten Zeitraum ansammeln kann. Sie wird als Vielfaches der jährlichen Ansammlungsrate definiert, nicht als genaue Zahl.",
  "components.leavePolicyForm.accrualCapWarning": "Ansammlungsobergrenze für {leaveTypeName} erreicht. Die Ansammlung neuer Tage wird fortgesetzt, sobald einige der vorhandenen Tage genutzt wurden.",
  "components.leavePolicyForm.accrualCapWarning2": "Dieser Benutzer hat die Ansammlungsobergrenze für {leaveTypeName} erreicht und wird keine weiteren Tage ansammeln, bis ein Teil des aktuellen Guthabens genutzt wurde.",
  "components.leavePolicyForm.accrualDay": "Ansammlungstag",
  "components.leavePolicyForm.accrualDayBiWeekly": "Ansammlung gewährt am",
  "components.leavePolicyForm.accrualDisabledInfo": "Deaktivieren Sie das unbegrenzte Kontingent und setzen Sie das Kontingent auf eine Zahl größer als 0, um Ansammlungen zu aktivieren.",
  "components.leavePolicyForm.accrualMonthlySummary": "Benutzer erhalten am {date}. jeden Monats {amount} ({quota}/12) Tage",
  "components.leavePolicyForm.accrualMonthlySummaryHours": "Benutzer erhalten am {date}. jeden Monats {amount} ({quota}/12) Stunden",
  "components.leavePolicyForm.accruals": "Ansammlungen",
  "components.leavePolicyForm.accrualSemiMonthlySummary": "Benutzer erhalten {amount} ({quota}/24) Tage am {firstDate} und {secondDate} eines Monats",
  "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Benutzer erhalten {amount} ({quota}/24) Stunden am {firstDate} und {secondDate} eines Monats",
  "components.leavePolicyForm.accrualType": "Ansammlungstyp",
  "components.leavePolicyForm.accrualTypeError": "Erforderlich",
  "components.leavePolicyForm.accrualTypeInfo": "Angesammelter Urlaub akkumuliert sich im Laufe des Jahres basierend auf der Unternehmenspolitik. Wenn Ihr Unternehmen Ansammlungen hat, wählen Sie den von Ihrem Management festgelegten Ansammlungstyp.",
  "components.leavePolicyForm.accrualWeeklySummary": "Benutzer erhalten {amount} ({quota}/52) Tage jeden {day}",
  "components.leavePolicyForm.accrualWeeklySummaryHours": "Benutzer erhalten {amount} ({quota}/52) Stunden jeden {day}",
  "components.leavePolicyForm.addTitle": "Urlaubsrichtlinie zuweisen",
  "components.leavePolicyForm.allDays": "Alle",
  "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Urlaub in der Vergangenheit bearbeiten & beantragen",
  "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Wählen Sie, ob Sie regulären Benutzern erlauben möchten, Urlaub in der Vergangenheit zu bearbeiten oder zu beantragen.",
  "components.leavePolicyForm.allowAdvanceAccrualUsage": "Beantragung von ansammlungsbasiertem Urlaub im Voraus erlauben",
  "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Erlauben Sie Mitarbeitern, Urlaub basierend auf ihrer prognostizierten Ansammlung von freien Tagen zu beantragen, anstatt nur auf der Grundlage dessen, was sie zum Zeitpunkt der Anfrage angesammelt haben. Das bedeutet, wenn ein Mitarbeiter weiß, dass er zum Zeitpunkt des beantragten Urlaubs genügend angesammelte Tage haben wird, kann er den Antrag im Voraus stellen, auch wenn er zum Zeitpunkt der Antragstellung nicht genügend Tage hat.",
  "components.leavePolicyForm.approvalRequired": "Genehmigung erforderlich",
  "components.leavePolicyForm.assignLeavePolicy": "Urlaubsrichtlinie zuweisen",
  "components.leavePolicyForm.back": "Zurück",
  "components.leavePolicyForm.BI-WEEKLY": "Zweiwöchentlich",
  "components.leavePolicyForm.cancel": "Abbrechen",
  "components.leavePolicyForm.copyLeavePolicy": "Richtlinie kopieren von",
  "components.leavePolicyForm.copyLeavePolicyDivider": "Sie können eine Vorlage von einem anderen Urlaub oder Standort verwenden.",
  "components.leavePolicyForm.copyLeavePolicyInfo": "Kopieren Sie die Einstellungen der Urlaubsrichtlinie von einem zuvor festgelegten Urlaubstyp. Wählen Sie im Dropdown-Menü den Urlaubstyp aus, von dem Sie die Urlaubsrichtlinie kopieren möchten, und die Einstellungen werden automatisch konfiguriert. Wenn Sie dies manuell tun möchten, können Sie dies durch Konfigurieren der Einstellungen unten tun.",
  "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": "Ansammlungen sind beim Erstellen eines Urlaubstyps inaktiv. Um sie zu aktivieren, passen Sie die Urlaubsrichtlinie für einen bestimmten Standort an.",
  "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "TOIL (Zeitausgleich) ist beim Erstellen eines Urlaubstyps inaktiv. Um es zu aktivieren, passen Sie die Urlaubsrichtlinie für einen bestimmten Standort an.",
  "components.leavePolicyForm.currentAccrualPeriod": "Aktuelle Ansammlungsperiode",
  "components.leavePolicyForm.daysPerYear": "Jährliches Urlaubskontingent",
  "components.leavePolicyForm.defaultLeavePolicy": "Nicht kopieren, ich erstelle eine neue Richtlinie",
  "components.leavePolicyForm.editTitle": "{leavePolicyName} Urlaubsrichtlinie für {locationName} bearbeiten",
  "components.leavePolicyForm.futureBroughtForward": "Zukünftig übertragen",
  "components.leavePolicyForm.halfDays": "Halbe Tage erlauben",
  "components.leavePolicyForm.hasUnlimitedDays": "Unbegrenztes Kontingent",
  "components.leavePolicyForm.hideLeaveType": "Urlaubstyp verbergen",
  "components.leavePolicyForm.hideLeaveTypeInfo": "Wählen Sie, ob Sie diesen Urlaubstyp im Kalender und in Benachrichtigungen verbergen möchten. Nur Administratoren, Genehmiger und der Benutzer, der den Urlaubstyp beantragt hat, können ihn im Kalender identifizieren.",
  "components.leavePolicyForm.hourlyIncrementsTooltip": "Erlauben Sie Ihren Teammitgliedern, stundenbasierten Urlaub zu beantragen.",
  "components.leavePolicyForm.isActive": "Aktiv",
  "components.leavePolicyForm.leavePolicy": "Urlaubsrichtlinie",
  "components.leavePolicyForm.limitedNumberOfDays": "Begrenzt",
  "components.leavePolicyForm.maxRolloverDays": "Maximale übertragbare Tage",
  "components.leavePolicyForm.maxRolloverDaysError": "Erforderlich",
  "components.leavePolicyForm.maxRolloverDaysInfo": "Wählen Sie die Anzahl der Tage, die für diesen Urlaubstyp in das folgende Kalenderjahr übertragen werden können.",
  "components.leavePolicyForm.maxRolloverDaysInfoHours": "Wählen Sie die Anzahl der Stunden, die für diesen Urlaubstyp in das folgende Kalenderjahr übertragen werden können.",
  "components.leavePolicyForm.MONTHLY": "Monatlich",
  "components.leavePolicyForm.negativeBalance": "Negativen Saldo erlauben",
  "components.leavePolicyForm.negativeBalanceInfo": "Wählen Sie, ob Sie den Benutzern erlauben möchten, das von Ihnen festgelegte Urlaubskontingent für diesen Urlaubstyp zu überschreiten. Wenn Sie diese Option nicht zulassen möchten, lassen Sie die Schaltfläche deaktiviert.",
  "components.leavePolicyForm.nextAccrualDay": "Nächste Ansammlung wird vergeben am",
  "components.leavePolicyForm.noLeaveTypes": "Es sind keine Urlaubstypen verfügbar. Um einen neuen Urlaubstyp mit einer benutzerdefinierten Urlaubsrichtlinie hinzuzufügen, gehen Sie bitte zum Reiter Urlaubstypen, um einen neuen Urlaubstyp zu erstellen, und fügen Sie ihn dann dem Standort hinzu.",
  "components.leavePolicyForm.NONE": "Keine",
  "components.leavePolicyForm.noRollover": "Deaktiviert",
  "components.leavePolicyForm.override.affectedUsers": "Betroffene Benutzer: <strong>{totalUsers}</strong>",
  "components.leavePolicyForm.override.change": "Änderung: <unlimited>Änderung der unbegrenzten Urlaubsrichtlinie</unlimited><customDays>Aktualisierung des Urlaubskontingents</customDays>",
  "components.leavePolicyForm.override.customLeaveQuotaInfo": "Einige der betroffenen Benutzer (<strong>{totalUsers}</strong>) haben benutzerdefinierte Urlaubskontingente. Möchten Sie diese Einstellung überschreiben?",
  "components.leavePolicyForm.override.leaveType": "Urlaubsart: <strong>{name}</strong>",
  "components.leavePolicyForm.override.location": "Standort: <strong>{name}</strong>",
  "components.leavePolicyForm.override.note": "Hinweis: Diese Änderung betrifft alle Benutzer ohne individuelles Urlaubskontingent, unabhängig von der gewählten Option.",
  "components.leavePolicyForm.override.overrideDisable": "Individuelles Urlaubskontingent überschreiben",
  "components.leavePolicyForm.override.overrideEnable": "Bestehendes Urlaubskontingent beibehalten",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Ansammlungen pausieren, wenn Benutzer deaktiviert ist",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Aktivieren Sie diese Funktion, um die Ansammlung von Urlaubstagen für deaktivierte Benutzer vorübergehend auszusetzen. Sobald der Benutzer reaktiviert wird, wird die Ansammlung von Urlaubstagen automatisch fortgesetzt, um eine genaue Verfolgung der Urlaubsansprüche zu gewährleisten",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "Gemäß den Richtlinien Ihrer Organisation wird die Urlaubsansammlung pausiert, während das Konto dieses Benutzers inaktiv ist. Bitte wenden Sie sich für weitere Details an Ihren Administrator.",
  "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Vorherige Überträge und zukünftige",
  "components.leavePolicyForm.reasonRequired": "Grund erforderlich",
  "components.leavePolicyForm.required": "Erforderlich",
  "components.leavePolicyForm.rolloverDaysPerYear": "Maximale Anzahl der Tage",
  "components.leavePolicyForm.rolloverPerYearDays": "Maximale Anzahl der Tage",
  "components.leavePolicyForm.rolloverPerYearHours": "Maximale Anzahl der Stunden",
  "components.leavePolicyForm.rolloverSettings": "Übertrag-Einstellungen",
  "components.leavePolicyForm.rolloverType": "Übertrag",
  "components.leavePolicyForm.save": "Speichern",
  "components.leavePolicyForm.saveLeavePolicy": "Speichern",
  "components.leavePolicyForm.selectLeaveType": "Urlaubsart auswählen",
  "components.leavePolicyForm.SEMI-MONTHLY": "Halbmonatlich",
  "components.leavePolicyForm.updateLeavePolicy": "Aktualisieren",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "Wann möchten Sie diese Änderungen anwenden?",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "Wählen Sie den Zeitrahmen für die Anwendung Ihrer Urlaubsänderungen. Wählen Sie 'Zukünftige Überträge' um nur kommende Urlaubstermine anzupassen, oder 'Vorherige Überträge und zukünftige' um sowohl vergangene als auch zukünftige Urlaubstermine zu ändern.",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "Wann möchten Sie diese Änderungen anwenden?",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "Wählen Sie den Zeitrahmen für die Anwendung Ihrer Urlaubsänderungen. Wählen Sie 'Zukünftige Überträge' um nur kommende Urlaubstermine anzupassen, oder 'Vorherige Überträge und zukünftige' um sowohl vergangene als auch zukünftige Urlaubstermine zu ändern.",
  "components.leavePolicyForm.yearlyQuota": "Jährliches Urlaubskontingent",
  "components.leavePolicyForm.yearlyQuotaHours": "Jährliches Urlaubskontingent (Stunden)",
  "components.leavePolicyForm.yearlyQuotaInfo": "Dieses Feld repräsentiert das Basis-Jahresurlaubskontingent. Sie können individuelle Kontingente manuell über Benutzerprofile anpassen oder Anpassungen für verschiedene Benutzer mit unserer Automatisierungsfunktion vornehmen.",
  "components.leaveRequestAction.added": "Urlaub <forUser>für </forUser>{requestor} hinzugefügt <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.addToCalendar": "Zum Kalender hinzufügen",
  "components.leaveRequestAction.addToCalendarMoreInfo": "Mehr Informationen über die Funktion \"Zum Kalender hinzufügen\"",
  "components.leaveRequestAction.approved": "Urlaub von {requestor} genehmigt <byActionCreator>durch {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.approversLabel": "Genehmiger: ",
  "components.leaveRequestAction.autoApproved": "Urlaub von {requestor} wurde automatisch genehmigt",
  "components.leaveRequestAction.cancelled": "Urlaub von {requestor} storniert <byActionCreator>durch {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.created": "Urlaub beantragt <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.deleted": "Urlaub gelöscht <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.denied": "Urlaub von {requestor} abgelehnt <byActionCreator>durch {actionCreator}</byActionCreator> ❌",
  "components.leaveRequestAction.edited": "Urlaub von {requestor} bearbeitet <byActionCreator>durch {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedApproved": "Ein geplanter Urlaub wurde bearbeitet <byActionCreator>von {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.editedAutoApproved": "Bearbeiteter Urlaub automatisch genehmigt <byActionCreator>durch {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedDenied": "Bearbeiteter Urlaub abgelehnt <byActionCreator>durch {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate} ",
  "components.leaveRequestAction.leaveRemaining": "Verbleibender Urlaub: ",
  "components.leaveRequestAction.leaveRequested": "Beantragter Urlaub: ",
  "components.leaveRequestAction.leaveTypeLabel": "Urlaubsart: ",
  "components.leaveRequestAction.partDayLeavePeriod": "{date} - von {partDayStartHour}{hourFormat24, select, true {:00} other {}}  bis {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.leaveRequestAction.periodLabel": "Datum: ",
  "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "Sie haben den Antrag genehmigt. Eine Benachrichtigung wird an {name} gesendet, um sie zu informieren.",
  "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "Urlaubsantrag wurde von {approverName} genehmigt.",
  "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "Der Urlaubsantrag wurde storniert.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "Sie haben den Antrag abgelehnt. Eine Benachrichtigung wird an {name} gesendet, um sie zu informieren.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "Der Urlaubsantrag wurde von {approverName} abgelehnt.",
  "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "Der Antrag ist abgelaufen.",
  "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "Der Urlaubsantrag wurde genehmigt.",
  "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "Der Urlaubsantrag wurde abgelehnt.",
  "components.leavesColumns.approver": "Genehmiger",
  "components.leavesColumns.autoApproved": "Automatisch genehmigt",
  "components.leavesColumns.cancelledBeforeReview": "Vor der Überprüfung storniert",
  "components.leavesColumns.dates": "Datum/Daten",
  "components.leavesColumns.days": "{value} Tage",
  "components.leavesColumns.denyReason": "Ablehnungsgrund des Genehmigers",
  "components.leavesColumns.duration": "Dauer",
  "components.leavesColumns.halfDayHoliday": "(Halbtags-Feiertag)",
  "components.leavesColumns.hours": "{value} Stunden",
  "components.leavesColumns.leaveType": "Urlaubsart",
  "components.leavesColumns.reason": "Grund des Antragstellers",
  "components.leavesColumns.reviewedby": "Überprüft von",
  "components.leavesColumns.status": "Status",
  "components.leaveTypeForm.cancel": "Abbrechen",
  "components.leaveTypeForm.color": "Farbe",
  "components.leaveTypeForm.editLeaveTypeTitle": "{leaveTypeName} Urlaubsart bearbeiten",
  "components.leaveTypeForm.forbiddenCharacters": "Der Name der Urlaubsart darf keine Satzzeichen oder Sonderzeichen enthalten.",
  "components.leaveTypeForm.isActive": "Aktiv",
  "components.leaveTypeForm.maxLength": "Der Name der Urlaubsart ist zu lang.",
  "components.leaveTypeForm.name": "Name",
  "components.leaveTypeForm.nameIsRequired": "Dieses Feld ist erforderlich.",
  "components.leaveTypeForm.save": "Speichern",
  "components.leaveTypeForm.setSlackStatus": "Slack-Status festlegen",
  "components.leaveTypeForm.setSlackStatusPlaceholder": "Bitte legen Sie den Slack-Status fest",
  "components.leaveTypeForm.slackStatus": "Slack-Status",
  "components.leaveTypeForm.title": "Urlaubsart",
  "components.leaveTypeForm.tooltipElement1": "<strong>Genehmigung erforderlich</strong>: Diese Option kann aktiviert werden, wenn ein Urlaub die Genehmigung eines Managers erfordert.",
  "components.leaveTypeForm.tooltipElement2": "<strong>Negativen Saldo erlauben</strong>: Diese Option ermöglicht es Benutzern, Urlaub zu beantragen, auch wenn sie ihr Kontingent bereits aufgebraucht haben.",
  "components.leaveTypeForm.tooltipElement3": "<strong>Halbe Tage erlauben</strong>: Diese Option kann je nach Bedarf Ihrer Organisation aktiviert werden.",
  "components.leaveTypeForm.tooltipFooter": "Denken Sie daran, dass Urlaubsrichtlinien pro Standort definiert werden. Sie können diese Richtlinien entsprechend den Bedürfnissen Ihrer Organisation anpassen und den entsprechenden Urlaubsarten zuweisen.",
  "components.leaveTypeForm.tooltipHeader": "In Vacation Tracker beziehen sich 'Urlaubsarten' auf globale Kategorien von Urlauben wie bezahlter Urlaub, Krankheitstag, Homeoffice usw. Andererseits sind 'Urlaubsrichtlinien' spezifische Regeln und Einstellungen, die jeder Urlaubsart pro Standort zugewiesen werden. Diese Richtlinien können verschiedene Optionen beinhalten, wie zum Beispiel:",
  "components.legacyPlanAlert.warning": "Sie nutzen derzeit den Legacy-Plan. Wenn Sie den Plan ändern, können Sie ihn nicht mehr erneut aktivieren. Für weitere Informationen kontaktieren Sie bitte unseren <link>Support</link>.",
  "components.locationForm.changeLocationWarning": "Wählen Sie den Standort aus, zu dem Sie den Benutzer transferieren möchten.",
  "components.locationForm.changeUserLocation": "Standort des Benutzers ändern",
  "components.locationForm.days": "Tage",
  "components.locationForm.daysAfterEmploymentDate": "Tage nach dem Einstellungsdatum.",
  "components.locationForm.daysInfo": "Der Übertrag von nicht genutztem Urlaub in das folgende Kalenderjahr verfällt nach der Anzahl von Tagen, die Sie nach dem Einstellungsdatum des Benutzers festgelegt haben. Das Urlaubskontingent für jede Urlaubsart wird über die Registerkarte 'Urlaubsarten' in den Standorteinstellungen konfiguriert, einschließlich der Option, den Übertrag zu aktivieren oder zu deaktivieren.",
  "components.locationForm.defaultLocation": "Diesen Standort als Standard festlegen",
  "components.locationForm.firstDayOfWeek": "Woche beginnt am",
  "components.locationForm.fiscalYear": "Geschäftsjahr",
  "components.locationForm.fiscalYearStart": "Beginn des Geschäftsjahres",
  "components.locationForm.name": "Name",
  "components.locationForm.noRolloverExpiryDate": "Kein Verfallsdatum für übertragene Tage",
  "components.locationForm.overrideUserSettings": "Benutzereinstellungen überschreiben",
  "components.locationForm.overrideUserSettingsInfo": "Die Arbeitswochenkonfiguration wird nur auf Benutzer angewendet, die keine benutzerdefinierten Einstellungen in ihrem Benutzerprofil haben. Wenn Sie die neuen Arbeitswocheneinstellungen auf alle Benutzer anwenden möchten, einschließlich derer mit benutzerdefinierter Arbeitswoche, wählen Sie die Option \"Benutzereinstellungen überschreiben\".",
  "components.locationForm.resetQuotas": "Urlaubskontingent-Zurücksetzung basierend auf",
  "components.locationForm.resetQuotasInfo": "Diese Einstellung legt fest, ob Ihr Urlaubskonto basierend auf dem Geschäftsjahr (Geschäftsjahr des Unternehmens) oder dem Eintrittsdatum des Mitarbeiters zurückgesetzt wird. Neben den Kontingenten wird auch Ihre Übertragungsrichtlinie entsprechend dieser Einstellung beeinflusst.",
  "components.locationForm.rolloverExpiryAfterDays": "Kein Verfallsdatum für übertragene Tage",
  "components.locationForm.rolloverExpiryAfterDaysInfo": "Jedes Jahr verfallen die übertragenen Urlaubstage des Benutzers nach dieser Anzahl von Tagen nach ihrem Eintrittsdatum. Die Kontingente für jeden Urlaubstyp werden im Abschnitt Urlaubstypen für diesen Standort konfiguriert und können individuell eingestellt werden, um Übertragungen zu erlauben oder nicht.",
  "components.locationForm.rolloverExpiryDay": "Kein Verfallsdatum für übertragene Tage",
  "components.locationForm.rolloverExpiryDayInfo": "Jedes Jahr verfallen die übertragenen Urlaubstage des Benutzers an dem von Ihnen festgelegten Datum. Die Kontingente für jeden Urlaubstyp werden im Abschnitt Urlaubstypen für diesen Standort konfiguriert und können individuell eingestellt werden, um Übertragungen zu erlauben oder nicht.",
  "components.locationForm.selectLocation": "Standort auswählen",
  "components.locationForm.selectLocationError": "Bitte wählen Sie einen Standort aus.",
  "components.locationForm.settings.makeDefaultDescription": "Wenn Sie diesen Standort zum Standardstandort machen, werden alle neuen Teammitglieder automatisch diesem Standort hinzugefügt.",
  "components.locationForm.settings.makeDefaultTitle": "Standardstandort",
  "components.locationForm.settings.nameDescription": "Legen Sie den Namen Ihres Standorts fest und wählen Sie dessen Benutzer aus. Wenn Sie den Standort vor dem Hinzufügen von Benutzern konfigurieren möchten, können Sie den Standort zuerst erstellen und später Benutzer hinzufügen.",
  "components.locationForm.settings.nameDescription2": "Diese Standard-Zeitzone wird im gesamten System verwendet. Zum Beispiel für die genaue Anzeige von Urlaubsinformationen im Kalender und für die im Protokoll aufgeführten Systemereignisse.",
  "components.locationForm.settings.namePlaceholder": "Standortnamen eingeben",
  "components.locationForm.settings.nameTitle": "Standorteinstellungen",
  "components.locationForm.settings.quotasDescription": "Diese Einstellung legt fest, ob Ihr Urlaubskonto basierend auf dem Geschäftsjahr (Geschäftsjahr des Unternehmens) oder dem Eintrittsdatum des Mitarbeiters zurückgesetzt wird. Neben den Kontingenten wird auch Ihre Übertragungsrichtlinie entsprechend dieser Einstellung beeinflusst.",
  "components.locationForm.settings.quotasTitle": "Urlaubskontingent und Geschäftsjahr",
  "components.locationForm.settings.workWeekDescription": "Bitte wählen Sie die Arbeitstage der Woche aus, um eine genaue Erfassung der Urlaubsdaten zu gewährleisten.",
  "components.locationForm.settings.workWeekTitle": "Arbeitswoche und Arbeitstag",
  "components.locationForm.users": "Benutzer",
  "components.locationForm.userYear": "Eintrittsdatum des Benutzers",
  "components.locationForm.warningInitialRollover": "Änderungen an den Standorteinstellungen können sich auf die Benutzerkonfiguration und Urlaubsinformationen wie Urlaubskontingent und -berechnungen auswirken. \nBitte beachten Sie, dass wenn Sie ein Verfallsdatum für übertragene Tage in einer Urlaubsrichtlinie an diesem Standort festgelegt haben, es deaktiviert wird.",
  "components.locationForm.warningInitialRolloverFiscalYear": "Änderungen an den Standorteinstellungen können sich auf die Benutzerkonfiguration und Urlaubsinformationen wie Urlaubskontingent und -berechnungen auswirken.",
  "components.locationForm.warningInitialRolloverResetQuotas": "Änderungen an den Standorteinstellungen können sich auf die Benutzerkonfiguration und Urlaubsinformationen wie Urlaubskontingent und -berechnungen auswirken.",
  "components.locationForm.warningInitialRolloverUsers": "Das Hinzufügen oder Entfernen eines Benutzers kann sich auf die Konfiguration und Urlaubsinformationen des Benutzers auswirken (z.B. die anfänglich übertragenen Tage).",
  "components.locationForm.workWeek": "Arbeitswoche",
  "components.locationHolidays.addHolidays": "Feiertag hinzufügen",
  "components.locationHolidays.deleteHolidaysConfirm": "Sind Sie sicher, dass Sie den Feiertag {name} löschen möchten?",
  "components.locationHolidays.deleteHolidaysTitle": "Feiertag löschen",
  "components.locationHolidays.holidaysForLocation": "Feiertage für den Standort {locationName}",
  "components.locationHolidays.insertHolidays": "Feiertage importieren",
  "components.locationHolidays.overlap": "Dieser Feiertag überschneidet sich mit einem bestehenden",
  "components.locationLeavePolicies.activatingLeaveTypeInfo": "Durch die Aktivierung dieses Urlaubstyps erlauben Sie den Benutzern, diesen spezifischen Urlaubstyp anzufordern. \n\nVorherige Einstellungen und Daten werden wiederhergestellt.",
  "components.locationLeavePolicies.activatingLeaveTypeTitle": "Urlaubstyp aktivieren",
  "components.locationLeavePolicies.allowHalfDays": "Halbe Tage erlauben",
  "components.locationLeavePolicies.createNewLeavePolicy": "Urlaubsrichtlinie zuweisen",
  "components.locationLeavePolicies.createNewLeavePolicyInfo": "Um einen benutzerdefinierten Urlaubstyp zu einem Standort hinzuzufügen, gehen Sie zu Urlaubsrichtlinie zuweisen. Wählen Sie den Urlaubstyp aus, den Sie bereits über den Reiter Urlaubstypen erstellt haben, und konfigurieren Sie die Einstellungen der Urlaubsrichtlinie.",
  "components.locationLeavePolicies.daysPerYear": "Jährliches Urlaubskontingent",
  "components.locationLeavePolicies.deactivateLeaveTypeInfo": "Sobald Sie diesen Urlaubstyp deaktivieren, können die Benutzer an diesem Standort den spezifischen Urlaubstyp nicht mehr anfordern. Wenn Sie ihn wieder aktivieren, wird der Urlaubstyp mit seinen vorherigen Einstellungen und Daten wiederhergestellt. \n\nWenn Sie diesen Urlaubstyp für alle Standorte deaktivieren möchten, können Sie dies über den Reiter Urlaubstypen tun.",
  "components.locationLeavePolicies.deactivateLeaveTypeTitle": "Urlaubstyp deaktivieren",
  "components.locationLeavePolicies.hasUnlimitedDays": "Unbegrenzt",
  "components.locationLeavePolicies.isApprovalRequired": "Genehmigung erforderlich",
  "components.locationLeavePolicies.leaveTypeName": "Name des Urlaubstyps",
  "components.locationLeavePolicies.status": "Status",
  "components.locationLeavePolicy.createInProgress": "Erstellung der Urlaubsrichtlinie {name}",
  "components.locationLeavePolicy.successTitle": "Die Urlaubsrichtlinie ist festgelegt",
  "components.locationLeavePolicy.updateInProgress": "Aktualisierung der Urlaubsrichtlinie",
  "components.locationLeaveTypesTag.hiddenLeaveType": "Versteckter Urlaubstyp",
  "components.locationLeaveTypesTag.holidays": "Feiertage",
  "components.logs.accrual": "Benutzer hat {earned} {leaveTypeName} {earnedDayInGrammaticalNumber} angesammelt (von {defaultDaysPerYear} {defaultDayInGrammaticalNumber} Kontingent)",
  "components.logs.accrualDays": "Benutzer erwarb {earned} {leaveTypeName} {earned, plural, =1 {Tag} other {Tage}} (von {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {Tag} other {Tage}} Quote)",
  "components.logs.accrualDisplay": "Benutzer erwarb {earned} {leaveTypeName} (von {defaultDaysPerYear} Quote)",
  "components.logs.accrualHours": "Benutzer erwarb {earned} {leaveTypeName} {earned, plural, =1 {Stunde} other {Stunden}} (von {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {Stunde} other {Stunden}} Quote)",
  "components.logs.automationAddonsUpdatedPurchased": "{user} hat Add-On gekauft: {automationType}",
  "components.logs.automationAddonsUpdatedUnsubscribed": "{user} hat Add-On abbestellt: {automationType}",
  "components.logs.billingCancelled": "Abrechnung auf {paymentProcessor} storniert",
  "components.logs.billingUpdated": "Abrechnung auf {paymentProcessor} aktualisiert",
  "components.logs.blackoutPeriodCreated": "{user} hat eine Sperrfrist <b>{name}</b> erstellt.<br></br>Aktiviert: {enabled}.<br></br>Wiederkehrend: {recurring}.<br></br>Startdatum: {startDate}.<br></br>Enddatum: {endDate}.<br></br>Urlaubsarten: {leaveTypes}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Labels: {labels}.<br></br>",
  "components.logs.blackoutPeriodDeleted": "{user} hat eine Sperrfrist gelöscht.",
  "components.logs.blackoutPeriodUpdated": "{user} hat eine Sperrfrist <b>{name}</b> aktualisiert.<br></br>Aktiviert: {enabled}.<br></br>Wiederkehrend: {recurring}.<br></br>Startdatum: {startDate}.<br></br>Enddatum: {endDate}.<br></br>Urlaubsarten: {leaveTypes}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Labels: {labels}.<br></br>",
  "components.logs.companyCreated": "Unternehmen <b>{name}</b> wurde erstellt",
  "components.logs.companySettingsUpdated": "<b>{user}</b> hat die Unternehmenseinstellungen aktualisiert.<br></br>Neue Benutzer ankündigen: {announceNewUsers}.<br></br>Stündliche Urlaubsabrechnung: {hourlyLeaveAccounting}.<br></br>Benutzer-Enddatum: {userEndDate}.<br></br>Berechnungs-Startjahr: {calculationStartYear}<br></br>Benutzer automatisch importieren: {importUsersAutomatically}",
  "components.logs.deleted": "<Gelöscht>",
  "components.logs.earned": "Benutzer hat {total} Tage {leaveTypeName} durch Überstundenarbeit (TOIL) {period} verdient. {showExpiration, plural, =1 {Tage verfallen am } other {}}{expiration}",
  "components.logs.entitlementByRoleCreated": "{user} hat eine Berechtigung nach Rolle <b>{name}</b> erstellt.<br></br>Aktiviert: {enabled}.<br></br>Tage: {days}.<br></br>Label: {label}.<br></br>Urlaubsart: {leaveType}.<br></br>",
  "components.logs.entitlementByRoleDeleted": "{user} hat eine Berechtigung nach Rolle gelöscht.",
  "components.logs.entitlementByRoleUpdated": "{user} hat eine Berechtigung nach Rolle <b>{name}</b> aktualisiert.<br></br>Aktiviert: {enabled}.<br></br>Tage: {days}.<br></br>Label: {label}.<br></br>Urlaubsart: {leaveType}.<br></br>",
  "components.logs.failedToLoadLog": "Log konnte nicht geladen werden",
  "components.logs.importLeaves": "<b>{user}</b> hat {totalLeaves} {totalLeaves, plural, =1 {Urlaub} other {Urlaube}} importiert.",
  "components.logs.importUsers": "<b>{user}</b> hat {totalUsers} {totalUsers, plural, =1 {Benutzer} other {Benutzer}} importiert.",
  "components.logs.initialRolloverDeleted": "Anfänglicher Übertrag für Urlaubsart: {leaveTypeName} auf 0 zurückgesetzt von <link>{creatorName}</link>",
  "components.logs.initialRolloverUpdated": "Anfänglicher Übertrag für Urlaubsart: {leaveTypeName} geändert auf {customRolloverDays} von <link>{creatorName}</link>",
  "components.logs.leavePolicyDisabled": "{user} hat die Urlaubsrichtlinie <b>{leaveTypeName}</b> am Standort <b>{locationName}</b> deaktiviert",
  "components.logs.leavePolicyEnabled": "{user} hat die Urlaubsrichtlinie <b>{leaveTypeName}</b> am Standort <b>{locationName}</b> aktiviert.<br></br>Tage pro Jahr: {daysPerYear}.<br></br>Maximale Übertragetage: {maxRolloverDays}.<br></br>Sollen Übertragetage verfallen: {enableRolloverExpiry}.<br></br>Übertragetage verfallen am: {rolloverExpiryDate}.<br></br>Übertragetage verfallen nach Tagen: {rolloverExpiryAfterDays}.<br></br>Genehmigung erforderlich: {isApprovalRequired}.<br></br>Unbegrenzte Tage: {hasUnlimitedDays}.<br></br>Kürzestes Urlaubsintervall: {shortestLeaveInterval}.<br></br>Grund erforderlich: {isReasonRequired}.<br></br>Ansammlungsart: {accrualType}.<br></br>Negativer Saldo erlaubt: {negativeBallanceAllowed}.<br></br>Urlaub in der Vergangenheit erlauben: {allowLeaveInPast}.<br></br>Ansammlungsobergrenze: {accrualCapRate, plural, =0 {Deaktiviert} other {Aktiviert mit Rate {accrualCapRate}}}.<br></br>Urlaubsart ausblenden: {hideLeaveType}.<br></br>Toil aktiviert: {toil}.<br></br>Toil kürzestes Intervall: {toilShortestInterval}.<br></br>Toil für alle Benutzer: {toilRequestsAllowedForUsers}.<br></br>Toil Ablauf: {toilExpiration}.<br></br>Toil Ablaufdatum: {toilExpirationDate}.<br></br>Toil Ablaufmonate: {toilExpirationMonths}.",
  "components.logs.leaveTypeCreated": "{user} hat Urlaubsart <b>{name}</b> erstellt und Standort(en) zugewiesen: <b>{locations}</b>.<br></br>Farbe: {color}.<br></br>Tage pro Jahr: {daysPerYear}.<br></br>Maximale Anzahl an Übertragetagen: {maxRolloverDays}.<br></br>Genehmigung erforderlich: {isApprovalRequired}.<br></br>Unbegrenzte Tage: {hasUnlimitedDays}.<br></br>Kürzestes Urlaubsintervall: {shortestLeaveInterval}.<br></br>Grund erforderlich: {isReasonRequired}.<br></br>Ansammlungsart: {accrualType}.<br></br>Negativer Saldo erlaubt: {negativeBallanceAllowed}.<br></br>Urlaub in der Vergangenheit hinzufügen erlauben: {allowLeaveInPast}.<br></br>Urlaubsart ausblenden: {hideLeaveType}",
  "components.logs.leaveTypeDeleted": "{user} hat Urlaubsart <b>{name}</b> gelöscht",
  "components.logs.leaveTypeUpdated": "{user} hat Urlaubsart <b>{name}</b> aktualisiert.<br></br>Aktiv: {isActive}.<br></br>Farbe: {color}",
  "components.logs.locationCreated": "{user} hat einen Standort <b>{name}</b> erstellt.<br></br>Jahresbeginn Monat: {yearStartMonth}.<br></br>Jahresbeginn Tag: {yearStartDay}.<br></br>Standardstandort: {isDefault}.<br></br>Erster Tag der Woche: {firstDayOfWeek}.<br></br>Quotenrücksetzung basiert auf: {resetQuotas}.<br></br>Übertrag Verfallsmonat: {rolloverExpiryMonth}.<br></br>Übertrag Verfallstag: {rolloverExpiryDay}.<br></br>Übertrag verfällt nach Tagen: {rolloverExpiryAfterDays}.<br></br>Zeitzone: {timezone}.<br></br>Benutzer: {users}.<br></br>Tage: {days}",
  "components.logs.locationDeleted": "{user} hat Standort gelöscht",
  "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> hat den automatischen Import von Feiertagen für Standort <b>{location}</b> deaktiviert",
  "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> hat den automatischen Import von Feiertagen für Standort <b>{location}</b> aktiviert<br></br>Feiertage: {holidays}",
  "components.logs.locationUpdated": "{user} hat Standort <b>{name}</b> aktualisiert.<br></br>Jahresbeginn Monat: {yearStartMonth}.<br></br>Jahresbeginn Tag: {yearStartDay}.<br></br>Standardstandort: {isDefault}.<br></br>Erster Tag der Woche: {firstDayOfWeek}.<br></br>Quoten zurücksetzen: {resetQuotas}.<br></br>Übertrag Verfallsmonat: {rolloverExpiryMonth}.<br></br>Übertrag Verfallstag: {rolloverExpiryDay}.<br></br>Übertrag verfällt nach Tagen: {rolloverExpiryAfterDays}.<br></br>Zeitzone: {timezone}.<br></br>Benutzer: {users}",
  "components.logs.locationWorkWeekUpdated": "{user} hat die Arbeitswoche am Standort <b>{name}</b> aktualisiert.<br></br>Tage: {days}.<br></br>Stunden: {hours}",
  "components.logs.locationYearHolidaysChanged": "<b>{user}</b> hat Feiertag(e) für das Jahr {year} am Standort <b>{name}</b> geändert.<br></br>Feiertage: {holidays}",
  "components.logs.locationYearRollover": "Standortjahreswechsel erfolgte am Standort <b>{name}</b>",
  "components.logs.nonAccrualLeaveType": "Benutzer erhielt {earned} <b>{leaveTypeName}</b> für {period}",
  "components.logs.nonAccrualLeaveTypeDays": "Benutzer erhielt {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {Tag} other {Tage}} für {period}",
  "components.logs.nonAccrualLeaveTypeHours": "Benutzer erhielt {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {Stunde} other {Stunden}} für {period}",
  "components.logs.notificationCreated": "Benutzer {user} hat eine Benachrichtigung <b>{name}</b> erstellt.<br></br>Häufigkeit: {frequency}.<br></br>Aktiviert: {enabled}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Labels: {labels}.<br></br>Erster Tag der Woche: {firstDayOfWeek}.<br></br>Zeitzone: {timezone}.<br></br>Gruppieren nach {groupBy}.<br></br>Leere senden: {sendEmpty}.<br></br>Ziele: {destinations}.<br></br>Minute: {minute}.<br></br>Stunde: {hour}.<br></br>Tag: {day}.<br></br>Sprache: {locale}.<br></br>An Tagen senden: {sendOnDays}.<br></br>Aktuelle Woche: {currentWeek}",
  "components.logs.notificationDeleted": "Benutzer {user} hat Benachrichtigung gelöscht",
  "components.logs.notificationDetailsDaily": "Benutzer {user} {action} Benachrichtigung <b>{name}</b>.<br></br>Häufigkeit: {frequency}.<br></br>Aktiviert: {enabled}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Bezeichnungen: {labels}.<br></br>Zeitzone: {timezone}.<br></br>Gruppieren nach {groupBy}.<br></br>Leer senden: {sendEmpty}.<br></br>Ziele: {destinations}.<br></br>Minute: {minute}.<br></br>Stunde: {hour}.<br></br>Sprache: {locale}.<br></br>Senden an Tagen: {sendOnDays}.",
  "components.logs.notificationDetailsMonthly": "Benutzer {user} {action} Benachrichtigung <b>{name}</b>.<br></br>Häufigkeit: {frequency}.<br></br>Aktiviert: {enabled}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Bezeichnungen: {labels}.<br></br>Erster Tag der Woche: {firstDayOfWeek}.<br></br>Zeitzone: {timezone}.<br></br>Gruppieren nach {groupBy}.<br></br>Leer senden: {sendEmpty}.<br></br>Ziele: {destinations}.<br></br>Minute: {minute}.<br></br>Stunde: {hour}.<br></br>Tag: {day}.<br></br>Sprache: {locale}.<br></br>Aktuelle Woche: {currentWeek}",
  "components.logs.notificationUpdated": "Benutzer {user} hat eine Benachrichtigung <b>{name}</b> aktualisiert.<br></br>Häufigkeit: {frequency}.<br></br>Aktiviert: {enabled}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Bezeichnungen: {labels}.<br></br>Erster Tag der Woche: {firstDayOfWeek}.<br></br>Zeitzone: {timezone}.<br></br>Gruppieren nach {groupBy}.<br></br>Leer senden: {sendEmpty}.<br></br>Ziele: {destinations}.<br></br>Minute: {minute}.<br></br>Stunde: {hour}.<br></br>Tag: {day}.<br></br>Sprache: {locale}.<br></br>Senden an Tagen: {sendOnDays}.<br></br>Aktuelle Woche: {currentWeek}",
  "components.logs.probationPeriodCreated": "{user} hat eine Probezeit <b>{name}</b> erstellt.<br></br>Aktiviert: {enabled}.<br></br>Länge (Tage): {lengthDays}.<br></br>Urlaubsarten: {leaveTypes}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Bezeichnungen: {labels}.<br></br>",
  "components.logs.probationPeriodDeleted": "{user} hat eine Probezeit gelöscht.",
  "components.logs.probationPeriodUpdated": "{user} hat eine Probezeit <b>{name}</b> aktualisiert.<br></br>Aktiviert: {enabled}.<br></br>Länge (Tage): {lengthDays}.<br></br>Urlaubsarten: {leaveTypes}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Bezeichnungen: {labels}.<br></br>",
  "components.logs.seniorityEntitlementCreated": "{user} hat einen Dienstaltersanspruch <b>{name}</b> erstellt.<br></br>Aktiviert: {enabled}.<br></br>Anteilig: {prorated}.<br></br>Startdatum: {startDate}.<br></br>Urlaubsart: {leaveType}.<br></br>Gewährt: {entitlementPeriods}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Bezeichnungen: {labels}.<br></br>",
  "components.logs.seniorityEntitlementDeleted": "{user} hat einen Dienstaltersanspruch gelöscht.",
  "components.logs.seniorityEntitlementUpdated": "{user} hat den Dienstaltersanspruch <b>{name}</b> aktualisiert.<br></br>Aktiviert: {enabled}.<br></br>Anteilig: {prorated}.<br></br>Startdatum: {startDate}.<br></br>Urlaubsart: {leaveType}.<br></br>Gewährt: {entitlementPeriods}.<br></br>Standorte: {locations}.<br></br>Abteilungen: {teams}.<br></br>Bezeichnungen: {labels}.<br></br>",
  "components.logs.subscriptionCancelled": "<b>{user}</b> hat das Abonnement gekündigt.<br></br>Grund: {reason}.<br></br>Kommentar: {comment}",
  "components.logs.subscriptionChanged": "Abonnement geändert.<br></br>Neuer Plan: {plan}.<br></br>Zeitraum: {period}.<br></br>Status: {status}.<br></br>Zahlungsabwickler: {paymentProcessor}",
  "components.logs.substituteApproverCreated": "{substituteApproverName} wurde von {creator} als Stellvertretender Genehmiger für {approverFor} vom {startDate} bis {endDate} festgelegt",
  "components.logs.substituteApproverDeleted": "{creator} hat {substituteApproverName} als Stellvertretenden Genehmiger entfernt.",
  "components.logs.substituteApproverUpdated": "{creator} hat den stellvertretenden Genehmiger aktualisiert.<br></br>Name des Stellvertretenden Genehmigers: {substituteApproverName}<br></br>Startdatum: {startDate}<br></br>Enddatum: {endDate}<br></br>",
  "components.logs.teamApproverStatusChanged": "{user} hat Genehmiger {approvers} für Team {team} festgelegt",
  "components.logs.teamCreated": "{user} hat eine Abteilung <b>{team}</b> erstellt.<br></br>Standardabteilung: {isDefault}.<br></br>Benutzer: {users}.<br></br>Genehmiger: {approvers} <br></br>Übergeordnete Abteilung: {parentDepartment}",
  "components.logs.teamDeleted": "{user} hat Team gelöscht",
  "components.logs.teamUpdated": "{user} hat eine Abteilung <b>{team}</b> aktualisiert.<br></br>Standardabteilung: {isDefault}.<br></br>Benutzer: {users}.<br></br>Genehmiger: {approvers} <br></br>Übergeordnete Abteilung: {parentDepartment}",
  "components.logs.userActivatedBy": "Benutzer wurde aktiviert von <link>{creatorName}</link>",
  "components.logs.userApproverStatus": "Benutzer ist <status></status> als Genehmiger für die Abteilung <team>{teamName}</team> durch <link>{creatorName}</link>",
  "components.logs.userChangedAvatar": "Benutzer hat sein Profilbild aktualisiert",
  "components.logs.userDeactivatedBy": "Benutzer wurde deaktiviert von <link>{creatorName}</link>",
  "components.logs.userDeletedBy": "Benutzer wurde gelöscht von <link>{creatorName}</link>",
  "components.logs.userEntitlementByRole": "Benutzer wurde {totalDays} Tag(e) {leaveTypeName} durch Anspruch nach Rolle gewährt",
  "components.logs.userEntitlementByRoleDeleted": "Die Anspruch-nach-Rolle-Tage des Benutzers für {leaveTypeName} wurden aufgrund der Entfernung oder Löschung der Bezeichnung <b>{labelName}</b> entfernt.",
  "components.logs.userEntitlementByRoleOverride": "Die Anspruchstage des Benutzers für {leaveTypeName} wurden aufgrund von Anpassungen des Anspruchs nach Rolle auf {totalDays} Tag(e) überschrieben",
  "components.logs.userInvitationAccepted": "Benutzer hat die Einladung von <link>{creatorName}</link> angenommen",
  "components.logs.userLeaveTypeDeleteQuota": "Kontingent für Urlaubsart <b>{leaveTypeName}</b> wurde auf den Standardwert zurückgesetzt von <link>{creatorName}</link>",
  "components.logs.userLeaveTypeUnlimitedDays": "unbegrenzt",
  "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> hat das Kontingent für diesen Benutzer für <b>{leaveTypeName}</b> auf <quota></quota> Tage geändert",
  "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> hat das Kontingent für diesen Benutzer für <b>{leaveTypeName}</b> auf <quota></quota> Tage geändert",
  "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> hat das Kontingent für diesen Benutzer für <b>{leaveTypeName}</b> auf <quota></quota> Stunden geändert",
  "components.logs.userMovedToLocation": "Benutzer wurde zum Standort <locationLink>{location}</locationLink> verschoben von <link>{creatorName}</link>",
  "components.logs.userMovedToTeam": "Benutzer wurde zur Abteilung <teamLink>{team}</teamLink> verschoben von <link>{creatorName}</link>",
  "components.logs.userRolloverDays": "Übertrag aus dem Vorjahr: {leaveTypeName} - {value} Tag{s}",
  "components.logs.userRolloverDisplay": "Übertrag aus dem Vorjahr: {leaveTypeName} - {value}",
  "components.logs.userRolloverExpired": "<b>{total}</b> <b>{leaveTypeName}</b> Tage aus dem Vorjahr sind verfallen",
  "components.logs.userRolloverExpiredDays": "Tage aus dem Vorjahr sind verfallen: <b>{leaveTypeName}</b> - {value} Tag{s}",
  "components.logs.userRolloverExpiredHours": "Tage aus dem Vorjahr sind verfallen: <b>{leaveTypeName}</b> - {value} Stunde{s}",
  "components.logs.userRolloverHours": "Übertrag aus dem Vorjahr: <b>{leaveTypeName}</b> - {value} Stunde{s}",
  "components.logs.userSeniorityEntitlement": "Dem Benutzer wurden {totalDays} Tag(e) {leaveTypeName} durch Dienstalter-Anspruch gewährt",
  "components.logs.userSeniorityEntitlementOverride": "Die Anspruchstage des Benutzers für {leaveTypeName} wurden aufgrund von Anpassungen des Dienstalter-Anspruchs auf {totalDays} Tag(e) überschrieben",
  "components.logs.userUpdated": "Benutzer <name></name> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> von <link>{creatorName}</link>",
  "components.logs.userUpdatedChangeStatusToAdmin": "Rolle zu Administrator geändert",
  "components.logs.userUpdatedChangeStatusToUser": "Rolle zu Benutzer geändert",
  "components.logs.userUpdatedEndDate": "Enddatum auf {endDate} geändert",
  "components.logs.userUpdatedName": "Name geändert zu <wrappedName>{name}</wrappedName>",
  "components.logs.userUpdatedStartDate": "Startdatum auf {startDate} geändert",
  "components.logs.userWorkWeek": "Arbeitswoche des Benutzers wurde auf {workDays} geändert von <link>{creatorName}</link>",
  "components.logs.userWorkWeekDefaultLocation": "Arbeitswoche des Benutzers wurde auf den Standort-Standard zurückgesetzt von <link>{creatorName}</link>",
  "components.logs.visibilityAddonCreated": "{user} hat die Sichtbarkeit für das Unternehmen eingeschränkt.<br></br>Aktiviert: {enabled}.<br></br> Sichtbarkeitsstufe: {visibilityLevel}.<br></br>",
  "components.logs.visibilityAddonUpdated": "{user} hat die eingeschränkte Sichtbarkeit für das Unternehmen aktualisiert.<br></br>Aktiviert: {enabled}.<br></br> Sichtbarkeitsstufe: {visibilityLevel}.<br></br>",
  "components.manageAssignLicenses.assignLicenses": "{numberOfToAssignLicenses} Lizenzen zuweisen",
  "components.manageAssignLicenses.avaliableToAssign": "Verfügbar zur Zuweisung: {avaliableLicenses}",
  "components.manageAssignLicenses.microsoftTeamsMessage": "Microsoft Teams Nachricht",
  "components.manageAssignLicenses.moreUserThenLicenses": "Sie haben {users} Lizenzen mehr zugewiesen als verfügbar",
  "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Massenvorgang auswählen",
  "components.manageAssignLicenses.placeholderSelectRole": "Rolle auswählen",
  "components.manageAssignLicenses.roleTooltipInfo": "Benutzerrollen in Vacation Tracker zuweisen. Weitere Informationen finden Sie in unserem <helpDesk>Helpdesk</helpDesk>.",
  "components.manageAssignLicenses.sendWelcomeMessage": "Willkommensnachricht senden",
  "components.manageAssignLicenses.totalLicenses": "Gesamtlizenzen: {numberOflicenses}",
  "components.modal.confirm": "Bestätigen",
  "components.modal.verify": "Verifizieren",
  "components.notificationDestination.label": "Senden an",
  "components.notificationDestination.tooltip": "Beachten Sie, dass Sie die Benachrichtigung an mehrere E-Mails, aber nur an einen Kanal gleichzeitig senden können.",
  "components.notificationForm.channel": "Kanal",
  "components.notificationForm.channelSlackInfo": "Benachrichtigungen können nur an die Kanäle gesendet werden, denen Sie angehören.",
  "components.notificationForm.daily": "Täglich",
  "components.notificationForm.day": "Tag",
  "components.notificationForm.firstDayOfWeek": "Woche beginnt am",
  "components.notificationForm.refreshTheList": "Liste aktualisieren",
  "components.notificationForm.team": "Microsoft Teams Team",
  "components.notificationForm.time": "Zeit",
  "components.notificationForm.timezone": "Zeitzone",
  "components.notificationForm.weekly": "Wöchentlich",
  "components.notificationSummaryInfo.notificationSummary": "Die Benachrichtigung wird <to>an</to> <channelWrapper>{channelName} </channelWrapper> <and>und</and> <addressesWrapper>{numberOfEmailAddress} E-Mail-Adresse<multiEmailAddress>n</multiEmailAddress></addressesWrapper> am {sendingDaysInfo} um {time} Uhr gesendet. Sie wird Urlaubsinformationen von <danger>{noOfUsers} Benutzer<multiUsers>n</multiUsers></danger> enthalten <link>(Benutzer anzeigen)</link>",
  "components.onboarding.assignLicensesTitle": "<icon></icon> Lizenzen zuweisen",
  "components.onboarding.installMSBotButton": "Bot installieren",
  "components.onboarding.installMSBotDescription": "Installieren Sie den Vacation Tracker Bot und Tabs, um den Urlaub Ihres Teams in Microsoft Teams einfach zu verwalten.",
  "components.onboarding.installMSBotTitle": "<icon></icon> Bot-Einrichtung abschließen",
  "components.onboarding.inviteFriendsDescription": "Holen Sie Ihr Team an Bord für eine genaue und zeitnahe Urlaubsverwaltung. (Fügen Sie mindestens 3 Teammitglieder hinzu, um diesen Schritt abzuschließen.)",
  "components.onboarding.inviteFriendsTitle": "<icon></icon> Laden Sie Ihre Teammitglieder ein",
  "components.onboarding.joyride.admin.assignLeavePolicy.info1": "Wie bereits erwähnt, werden Urlaubsarten für die gesamte Organisation erstellt. Urlaubsrichtlinien sind an Standorte gebunden. Jede Urlaubsart kann für jeden Standort festgelegt werden, indem ihr eine individuelle Urlaubsrichtlinie zugewiesen wird.",
  "components.onboarding.joyride.admin.assignLeavePolicy.info2": "Sobald Sie die Urlaubsart ausgewählt haben, die Sie dem Standort hinzufügen möchten, können Sie die Urlaubsrichtlinie zuweisen. Sie haben die Möglichkeit, Einstellungen von einer bestehenden Urlaubsrichtlinie zu kopieren oder eine völlig neue Urlaubsrichtlinie mit neuen Werten zu erstellen.",
  "components.onboarding.joyride.admin.assignLeavePolicy.title": "Urlaubsrichtlinien zuweisen",
  "components.onboarding.joyride.admin.createLocation.info1": "Wenn Sie Teammitglieder an verschiedenen Standorten haben, haben Sie die Möglichkeit, mehrere Standorte in Vacation Tracker zu erstellen und Benutzer dem entsprechenden Standort hinzuzufügen.",
  "components.onboarding.joyride.admin.createLocation.info2": "Jeder Standort hat seine eigenen Urlaubsrichtlinien, die Sie einer bestimmten Urlaubsart zuweisen müssen. Feiertage sind ebenfalls an Standorte gebunden.",
  "components.onboarding.joyride.admin.createLocation.title": "Einen Standort erstellen",
  "components.onboarding.joyride.admin.createNotifications.info1": "Nachdem Sie die oben genannten Schritte abgeschlossen haben, ist es Zeit, Benachrichtigungen zu erstellen.",
  "components.onboarding.joyride.admin.createNotifications.title": "Benachrichtigungen erstellen und bearbeiten",
  "components.onboarding.joyride.admin.createTeams.info1": "Nachdem Sie das Dashboard, das Ihnen einen Überblick über die heutigen und geplanten Urlaube innerhalb Ihrer Organisation gibt, abgeschlossen haben, ist es Zeit, Abteilungen zu erstellen.",
  "components.onboarding.joyride.admin.createTeams.info2": "Bei der Erstellung einer Abteilung müssen Sie die Genehmiger hinzufügen. Genehmiger haben die zweithöchste Berechtigungsstufe in Vacation Tracker und können Urlaubsanträge genehmigen/ablehnen, die von Benutzern aus der Abteilung gesendet werden, in der sie Genehmiger sind.",
  "components.onboarding.joyride.admin.createTeams.title": "Abteilungen erstellen und Genehmiger zuweisen",
  "components.onboarding.joyride.admin.helpDesk.info": "Wenn Sie Fragen haben oder weitere Informationen benötigen, schauen Sie in unserem Help Desk nach oder starten Sie einen Chat mit unserem Kundenservice-Team.",
  "components.onboarding.joyride.admin.helpDesk.title": "Help Desk",
  "components.onboarding.joyride.admin.importHolidays.info1": "Feiertage in Vacation Tracker werden für jeden Standort und nicht für die gesamte Organisation festgelegt.",
  "components.onboarding.joyride.admin.importHolidays.info2": "Sie haben die Möglichkeit, Feiertage aus unserer Liste voreingestellter Feiertage für jedes Land zu importieren und die Option, einen Feiertag manuell zu importieren.",
  "components.onboarding.joyride.admin.importHolidays.title": "Feiertage importieren",
  "components.onboarding.joyride.admin.leaveTypesMenu.info1": "Wir empfehlen immer, Ihre Vacation Tracker-Reise mit der Erstellung von Urlaubsarten zu beginnen. Sie werden diese Urlaubsarten später zuweisen, wenn Sie alle Standorte erstellen, an denen Ihr Unternehmen Mitarbeiter hat.",
  "components.onboarding.joyride.admin.leaveTypesMenu.info2": "Besuchen Sie den <link>Helpdesk</link> für eine Schritt-für-Schritt-Anleitung zur Erstellung und Bearbeitung von Urlaubsarten.",
  "components.onboarding.joyride.admin.leaveTypesMenu.title": "Urlaubsarten erstellen",
  "components.onboarding.joyride.admin.newLeaveType.info1": "Zusätzlich zur Standard-Urlaubsart (PTO) können Sie bis zu 24 weitere Urlaubsarten erstellen.",
  "components.onboarding.joyride.admin.start.title": "Erfahren Sie mehr über Vacation Tracker! 🏖️",
  "components.onboarding.joyride.admin.start1": "Um Ihnen den Einstieg zu erleichtern, haben wir einige kurze Schritte zusammengestellt, die wir Ihnen empfehlen, um Ihre Organisation schnell einzurichten.",
  "components.onboarding.joyride.admin.start2": "Wenn Sie unterwegs unsere Hilfe benötigen, sind wir nur eine E-Mail entfernt unter <link>hello@vacationtracker.io</link>",
  "components.onboarding.joyride.admin.teams.cta1": "Microsoft Teams-Bot installieren",
  "components.onboarding.joyride.admin.teams.cta2": "Erfahren Sie mehr in unserem Helpdesk",
  "components.onboarding.joyride.admin.teams.info1": "Sie sind fertig mit dem Dashboard. Der letzte Schritt ist die Installation des Microsoft Teams-Bots, damit Ihr Team Anfragen direkt in Microsoft Teams stellen kann.",
  "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
  "components.onboarding.joyride.approver.dashboard.info1": "Diese Seite ist Ihre Kommandozentrale. Sie gibt Ihnen einen Überblick über ausstehende Urlaube, wer heute frei hat und bevorstehende Urlaube für Ihre Organisation.",
  "components.onboarding.joyride.approver.leaveRequests.info1": "Als Genehmiger können Sie \"Urlaub hinzufügen\" für jedes Mitglied des Teams, für das Sie Genehmiger sind. Sie können auch \"Urlaub beantragen\" für sich selbst, der dann zur Genehmigung an Ihren Genehmiger gesendet wird.",
  "components.onboarding.joyride.approver.notifications.info1": "Diese Seite bietet einen Überblick über alle Benachrichtigungen, die in Ihrer Organisation versendet werden.",
  "components.onboarding.joyride.approver.notifications.info2": "Sie können Benachrichtigungen für Benutzer von Abteilungen erstellen oder bearbeiten, für die Sie Genehmiger sind.",
  "components.onboarding.joyride.approver.notifications.info3": "Beachten Sie, dass Sie nur die von Ihnen erstellten Benachrichtigungen verwalten können.",
  "components.onboarding.joyride.approver.users.info1": "Die 'Benutzer'-Seite gibt Ihnen einen vollständigen Überblick über alle aktiven Benutzer innerhalb Ihrer Abteilung sowie einen schreibgeschützten Zugriff auf ihre Benutzerprofile. Ihre ausstehenden Anfragen sind auf der 'Dashboard'-Seite verfügbar.",
  "components.onboarding.joyride.user.calendar.info1": "Auf dieser Seite können Sie die Verlaufsdaten und bevorstehenden Urlaube für Ihre Organisation in einem Kalenderformat sehen.",
  "components.onboarding.joyride.user.dashboard.info1": "Das Dashboard gibt Ihnen einen Überblick über die heutigen und geplanten Urlaube innerhalb Ihrer Organisation.",
  "components.onboarding.joyride.user.holidays.info1": "Hier können Sie Feiertage innerhalb Ihres Standorts überprüfen, damit Sie sicher wissen, welche Tage Sie bereits frei haben.",
  "components.onboarding.joyride.user.myProfile.info1": "Hier können Sie Ihre verfügbaren Urlaubsarten, Kontingente, Arbeitstage und Ihren Urlaubsverlauf sehen.",
  "components.onboarding.joyride.user.requestLeave.info1": "Um Urlaub über das Vacation Tracker-Dashboard zu beantragen, wählen Sie eine der verfügbaren Urlaubsarten aus, fügen Sie den Zeitraum und gegebenenfalls einen Grund hinzu und reichen Sie den Antrag ein. Nach dem Einreichen wird er zur Genehmigung an den Genehmiger gesendet.",
  "components.onboarding.joyride.user.start1": "Um Ihnen den Einstieg zu erleichtern, haben wir eine Liste mit fünf Schritten zusammengestellt, die wir Ihnen empfehlen, um Ihre Organisation schnell einzurichten.",
  "components.onboarding.openNotificationsButton": "Benachrichtigungen öffnen",
  "components.onboarding.requestALeaveDescription": "Reichen Sie Ihren ersten Urlaubsantrag in 3 einfachen Schritten ein.",
  "components.onboarding.requestALeaveTitle": "<icon></icon> Urlaub beantragen",
  "components.onboarding.setNotificationsDescription": "Wählen Sie aus, wie oft Sie über den Urlaub Ihres Teams informiert werden möchten.",
  "components.onboarding.setNotificationsTitle": "<icon></icon> Benachrichtigungen einrichten",
  "components.onboarding.skipOnboarding": "Onboarding überspringen",
  "components.onboarding.syncTheCalendarDescription": "Integrieren Sie den Vacation Tracker-Kalender in Ihren Kalender, damit der Urlaub Ihres Teams immer aktuell ist.",
  "components.onboarding.syncTheCalendarTitle": "<icon></icon> Kalender synchronisieren",
  "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Video zur Synchronisierung mit Google Kalender",
  "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "Video zur Synchronisierung mit iCal",
  "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "Video zur Synchronisierung mit MS Windows",
  "components.onboarding.title": "Onboarding-Checkliste <progressBar>{progressStatus}</progressBar>",
  "components.paymentInformation.expiryDate": "Ablaufdatum",
  "components.paymentInformation.labelCreditCard": "Kreditkarte",
  "components.paymentInformation.title": "Zahlungsinformationen ",
  "components.platformSwitchModal.content": "Sie müssen sich zuerst mit Ihrem {platform}-Konto anmelden, um zur {platform}-Plattform zu wechseln. Bitte klicken Sie unten auf den Button, um fortzufahren. Nach der Anmeldung und Erteilung der erforderlichen Berechtigungen leiten wir Sie zum Prozess der Benutzerzuordnung weiter.",
  "components.platformSwitchModal.title": "Migration zur {platform}-Plattform",
  "components.pricePlanCard.accruals": "Urlaub ansammeln",
  "components.pricePlanCard.APISupport": "API-Unterstützung",
  "components.pricePlanCard.calendarIntegrations": "Kalender-Integration",
  "components.pricePlanCard.contactUs": "Kontaktieren Sie uns",
  "components.pricePlanCard.coreDepartmentsAndLocations": "Erstellen Sie bis zu 3 Standorte und 10 Abteilungen",
  "components.pricePlanCard.currentPlan": "Aktueller Plan",
  "components.pricePlanCard.customContract": "Individueller Vertrag",
  "components.pricePlanCard.customizeLeaveTypes": "Urlaubsarten anpassen",
  "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Unbegrenzte Standorte, Abteilungen und benutzerdefinierte Urlaubsarten",
  "components.pricePlanCard.customOnboardingAndSetup": "Individuelles Onboarding und Setup",
  "components.pricePlanCard.dedicatedSupport": "Dedizierter Support",
  "components.pricePlanCard.disableDowngradeToCore": "Das Downgrade auf den Core-Plan erfordert {noOfActions} {noOfActions, plural, =1 {Aktion} other {Aktionen}}",
  "components.pricePlanCard.disableDowngradeToCoreDescription": "Um zum Core-Plan zu wechseln, nehmen Sie bitte folgende Anpassungen vor:",
  "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "Der Core-Plan erlaubt maximal 10 Abteilungen. Bitte entfernen Sie zusätzliche Abteilungen.",
  "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "Der Core-Plan erlaubt maximal 3 Standorte. Bitte entfernen Sie zusätzliche Standorte.",
  "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "Im Core-Plan können Abteilungen nur eine Ebene tief verschachtelt werden. Sie haben derzeit {total} Unterabteilungen. Bitte löschen Sie einige Unterabteilungen, um zum Core-Plan zu wechseln.",
  "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "Ein Wechsel zum Core-Plan ist nicht möglich, solange benutzerdefinierte Arbeitszeiten verwendet werden. Bitte setzen Sie die benutzerdefinierten Arbeitszeiten in den Standorteinstellungen auf den Standardwert (8) zurück",
  "components.pricePlanCard.disableDowngradeToCoreTitle": "Plan-Downgrade. Anpassungen erforderlich",
  "components.pricePlanCard.displayLeaveInformationInDaysHours": "Urlaubsinformationen in Tagen/Stunden anzeigen",
  "components.pricePlanCard.downgradePlan": "Der Plan wird im nächsten Abrechnungszeitraum herabgestuft",
  "components.pricePlanCard.duringTheTrial": "Während der Testphase wird jede Planänderung sofort umgesetzt",
  "components.pricePlanCard.everythingInTheCompletePlan": "Alles im Complete-Plan",
  "components.pricePlanCard.EverythingInTheCorePlan": "Alles im Core-Plan",
  "components.pricePlanCard.exportsAndReports": "Exporte und Berichte",
  "components.pricePlanCard.forSeats": "für weniger als {users} Plätze",
  "components.pricePlanCard.forUsers": "für weniger als {users} Benutzer",
  "components.pricePlanCard.hourlyLeaves": "Urlaub in Stunden verfolgen",
  "components.pricePlanCard.HRFields": "HR-Felder (demnächst verfügbar)",
  "components.pricePlanCard.labels": "Benutzer mit Labels organisieren",
  "components.pricePlanCard.managePTO": "PTO-Anfragen verwalten",
  "components.pricePlanCard.minimumPlanPrice": "mindestens <price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>",
  "components.pricePlanCard.notifications": "PTO-Benachrichtigungen senden",
  "components.pricePlanCard.pricePerSeat": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>pro Platz/Monat",
  "components.pricePlanCard.pricePerUser": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>pro Benutzer/Monat",
  "components.pricePlanCard.reports": "Berichte generieren",
  "components.pricePlanCard.reportsSchedule": "Berichte planen",
  "components.pricePlanCard.requestHourlyLeaveIncrements": "Stündliche Urlaubsinkremente beantragen",
  "components.pricePlanCard.scheduleAConsultation": "Eine Beratung planen",
  "components.pricePlanCard.scheduledReport": "Geplante Berichte",
  "components.pricePlanCard.selectPlan": "Plan auswählen",
  "components.pricePlanCard.sharedCalendars": "Geteilte Kalender",
  "components.pricePlanCard.SSOIntegration": "Enterprise SSO-Integration (Okta, etc.)",
  "components.pricePlanCard.startFreeTrial": "Starten Sie Ihre 14-tägige kostenlose Testversion",
  "components.pricePlanCard.substituteApprovers": "Stellvertretende Genehmiger",
  "components.pricePlanCard.support": "Prioritäts-Support",
  "components.pricePlanCard.switchPlan": "Wechseln Sie <period> zum {newPeriod} Plan</period>",
  "components.pricePlanCard.totalPrice": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/Monat für {totalUsers} aktive Benutzer",
  "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/Monat für weniger als 25 Benutzer",
  "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/Jahr",
  "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/Jahr",
  "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/Monat für {totalUsers} Plätze",
  "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Unbegrenzte Abteilungen und Standorte erstellen",
  "components.pricePlanCard.upgradePlan": "Der Plan wird sofort aktiviert und der Preis anteilig berechnet",
  "components.requestAutomation.anythingElse": "Gibt es sonst noch etwas, das wir wissen sollten?",
  "components.requestAutomation.automationExtraCharge": "Würden Sie dieses Add-On nutzen, wenn es mit Zusatzkosten verbunden wäre?",
  "components.requestAutomation.automationFunctionalities": "Welche Funktionen oder Aufgaben wird das Add-On Ihnen ermöglichen?",
  "components.requestAutomation.automationShouldWeAdd": "Welches Add-On sollten wir hinzufügen?",
  "components.requestAutomation.submiteRequest": "Anfrage einreichen",
  "components.requestAutomation.suggestAnAutomation": "Ein Add-On vorschlagen",
  "components.resetQuotas.fiscalYear": "Geschäftsjahr ({date})",
  "components.resetQuotas.userYear": "Einstellungsdatum des Benutzers",
  "components.selectChannelForm.addNewEmail": "Empfänger hinzufügen",
  "components.selectChannelForm.channel": "Kanal",
  "components.selectChannelForm.channelSlackInfo": "Benachrichtigungen können nur an Kanäle gesendet werden, denen Sie angehören.",
  "components.selectChannelForm.email": "Empfänger",
  "components.selectChannelForm.errorNotification": "Benachrichtigungsfehler",
  "components.selectChannelForm.errorNotInTeams": "Zugriff verweigert. Um diesen Kanal zu nutzen, müssen Sie Mitglied dieser Abteilung sein.",
  "components.selectChannelForm.notFound": "Ihre Organisation hat sich mit einem einzelnen Microsoft Teams-Team angemeldet, und Sie sind kein Mitglied des Teams mit der folgenden ID: {msTeamId}.\n\nSie können diesem Team in Ihrer Microsoft Teams-Anwendung beitreten und es erneut versuchen, oder kontaktieren Sie unseren Support, um Ihren Installationstyp zu ändern.",
  "components.selectChannelForm.notFoundTitle": "Sie sind kein Mitglied des ausgewählten Microsoft Teams-Teams",
  "components.selectChannelForm.team": "Team",
  "components.selectChannelFormMicrosoft.channelPlaceholder": "Bitte wählen Sie einen Kanal",
  "components.selectChannelFormMicrosoft.errorNotInTeams": "Zugriff verweigert. Um diesen Kanal zu nutzen, müssen Sie Mitglied dieser Abteilung sein.",
  "components.selectChannelFormMicrosoft.notFound": "Ihre Organisation hat sich mit einem einzelnen Microsoft Teams-Team angemeldet, und Sie sind kein Mitglied des Teams mit der folgenden ID: {msTeamId}.\n\nSie können diesem Team in Ihrer Microsoft Teams-Anwendung beitreten und es erneut versuchen, oder kontaktieren Sie unseren Support, um Ihren Installationstyp zu ändern.",
  "components.selectChannelFormMicrosoft.notFoundTitle": "Sie sind kein Mitglied des ausgewählten Microsoft Teams-Teams",
  "components.selectChannelFormMicrosoft.teamPlaceholder": "Bitte wählen Sie ein Team",
  "components.selectChannelFormSlack.errorNotification": "Benachrichtigungsfehler",
  "components.selectChannelFormSlack.missingSlackScopes": "Fehlende Slack-Berechtigungen",
  "components.selectChannelFormSlack.privateChannel": "(privater Kanal)",
  "components.selectChannelFormSlack.slackBotIdIsRequred": "Slack-Fehler: Slack Bot-ID ist erforderlich! Versuchen Sie, die Seite zu aktualisieren!",
  "components.selectChannelFormSlack.slackError": "Slack-Fehler",
  "components.selectChannelFormSlack.toProccedPleaseLogin": "Um fortzufahren, melden Sie sich bitte bei Ihrem Slack-Konto an",
  "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "Sie sind kein Mitglied von {channelName}. Um die Benachrichtigung an diesen Kanal zu senden, müssen Sie Mitglied sein.",
  "components.seniorityEntitlement.addDaysFrom": "Tage hinzufügen ab",
  "components.seniorityEntitlement.allFiledsAreRequired": "Alle Felder sind erforderlich",
  "components.seniorityEntitlement.confirmDescription": "Wenn Sie sich dafür entscheiden, die Berechnung des Dienstalteranspruchs ab dem Eintrittsdatum des Mitarbeiters zu beginnen, werden die Benutzerquoten rückwirkend ab dem Berechnungsstarjahr angepasst.",
  "components.seniorityEntitlement.confirmTitle": "Automatisierungserstellung bestätigen",
  "components.seniorityEntitlement.daysAfter": "Tag(e) nach",
  "components.seniorityEntitlement.disableEditInfo": "Sie können die Dienstalter-Anspruchsautomatisierung nicht bearbeiten. Wenn Sie Änderungen vornehmen müssen, löschen Sie diese Automatisierung und erstellen Sie eine neue. <link>Kontaktieren Sie uns</link>, wenn Sie Fragen haben oder Hilfe benötigen.",
  "components.seniorityEntitlement.hoursAfter": "Stunde(n) nach",
  "components.seniorityEntitlement.noLocations": "Sie haben eine Automatisierung, die auf alle Benutzer angewendet wurde. Sie können nicht mehrere Automatisierungen für alle Benutzer haben.",
  "components.seniorityEntitlement.notSortedYearInPeriodError": "Jahre müssen chronologisch sortiert sein.",
  "components.seniorityEntitlement.prorateForTheFirstYear": "Möchten Sie die anteilige Berechnung aktivieren?",
  "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "Wenn das Eintrittsdatum des Mitarbeiters nicht mit dem Beginn des Geschäftsjahres übereinstimmt, berechnen wir die dienstaltersbasierten Tage im Jahr der Zuteilung anteilig. Beispiel: Wenn Ihr Geschäftsjahr am 1. Januar beginnt, der Mitarbeiter vor 5 Jahren am 1. Juli angefangen hat und Ihr Unternehmen nach 5 Dienstjahren am 5. Juli 5 zusätzliche Urlaubstage gewährt, erhält der Mitarbeiter am 1. Juli aufgrund der anteiligen Berechnung 2,5 Tage. Dieser Mitarbeiter wird im nächsten Geschäftsjahr alle 5 dienstaltersbasierten Tage erhalten.",
  "components.seniorityEntitlement.sameYearError": "Sie können nicht mehrere Regeln für die gleiche Anzahl von Jahren zuweisen.",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Beginnen Sie mit der Anwendung des Dienstalteranspruchs ab",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "Wenn Sie sich dafür entscheiden, den Dienstalteranspruch ab dem Eintrittsdatum des Mitarbeiters anzuwenden, wirkt sich diese Änderung auf die Quoten des Benutzers in der Vergangenheit aus.",
  "components.seniorityEntitlement.summaryInfoPrefix": "Die Dienstalter-Automatisierung gewährt zusätzlichen Urlaub nach einem meilensteinbasierten Ansatz. Zum Beispiel: ",
  "components.seniorityEntitlement.summaryInfoSuffix": ", insgesamt {amount} <showDays>Tage</showDays><showHour>Stunde(n)</showHour> Dienstalterurlaub.",
  "components.seniorityEntitlement.summeryInfoFirstRow": "{amount} <showDays>Tage</showDays><showHour>Stunde(n)</showHour> werden beim {year}-jährigen Jubiläum hinzugefügt",
  "components.seniorityEntitlement.summeryInfoMoreDays": ", {amount} weitere <showDays>Tage</showDays><showHour>Stunde(n)</showHour> beim {year}-jährigen Jubiläum",
  "components.seniorityEntitlement.yearsInTheCOmpany": "Jahre im Unternehmen",
  "components.setupMsBot.followDirectLink": "1) Klicken Sie auf <directLink>diesen Link</directLink>, um die Vacation Tracker-Anwendung im Microsoft Teams Store zu finden.",
  "components.setupMsBot.inMSAppPreviewWindows": "2) Klicken Sie im App-Vorschaufenster auf den Pfeil neben der Schaltfläche \"Öffnen\" und dann auf \"Zu einem Team hinzufügen\"",
  "components.setupMsBot.setUpInMS": "3) Sie werden aufgefordert, das Team und den Kanal auszuwählen, in dem Sie Vacation Tracker installieren möchten. Sie haben auch die Möglichkeit, Vacation Tracker als Tab anzuheften, um einen einfacheren und schnelleren Zugriff zu ermöglichen!",
  "components.setupMsBot.stepByStep": "Für detaillierte Schritt-für-Schritt-Anleitungen schauen Sie sich dieses Video an oder besuchen Sie unseren <helpDesk>Helpdesk</helpDesk>.",
  "components.setupMsBot.title": "Richten Sie den Microsoft Teams Bot und die Tabs ein",
  "components.signupWithAnotherPlatform.connectWith": " Registrieren Sie sich mit {platform}",
  "components.signupWithAnotherPlatform.info": "Möchten Sie sich mit Ihrem {platform}-Konto verbinden? Dadurch können Sie Benutzer aus Ihrem {importUsersPlatform} importieren und synchronisieren sowie Urlaube direkt aus Ihrer {platform}-Anwendung verwalten. Weitere Informationen finden Sie in unserem <helpDesk>Helpdesk</helpDesk>.",
  "components.signupWithAnotherPlatform.or": "Oder",
  "components.signupWithAnotherPlatform.title": "Es scheint, dass Sie {platform} verwenden",
  "components.switchPlanModal.downgradeProbationPeriodWarning": "Das Probezeit-Add-On ist eine kostenpflichtige Funktion für den Core-Plan. Wenn Sie herunterstufen und dieses Add-On nicht abbestellen, wird es mit 0,50 $ pro Benutzer pro Monat berechnet.",
  "components.switchPlanModal.switch": "Wechseln",
  "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "Der <strong>Complete</strong> Monatsplan wird sofort aktiviert und der Preis wird für den aktuellen Abrechnungszeitraum anteilig berechnet.<legacyPlan> Bitte beachten Sie, dass Sie nicht zum Legacy-Plan zurückwechseln können.</legacyPlan>",
  "components.switchPlanModal.switchFromAnnuallyToMonthly": "Der <strong>{newPlan}</strong> Monatsplan wird nach Ablauf des aktuellen jährlichen Abrechnungszeitraums ({endDatePeriod}.) aktiviert und berechnet.<legacyPlan> Bitte beachten Sie, dass Sie nicht zum Legacy-Plan zurückwechseln können.</legacyPlan>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "Der <strong>Core</strong> Jahresplan wird nach Ablauf des aktuellen jährlichen Abrechnungszeitraums ({endDatePeriod}.) aktiviert. Bis dahin können Sie weiterhin die Funktionen des <strong>Complete</strong> Plans nutzen",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "Der <strong>Core</strong> Plan wird nach Ablauf des aktuellen jährlichen Abrechnungszeitraums ({endDatePeriod}.) aktiviert. Bis dahin können Sie weiterhin die Funktionen des <strong>Complete</strong> Plans nutzen.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "Der <strong>Core</strong> Jahresplan wird nach Ablauf des aktuellen monatlichen Abrechnungszeitraums ({endDatePeriod}.) aktiviert. Bis dahin können Sie weiterhin die Funktionen des <strong>Complete</strong> Plans nutzen.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "Der <strong>Core</strong> Monatsplan wird nach Ablauf des aktuellen Abrechnungszeitraums ({endDatePeriod}.) aktiviert. Bis dahin können Sie weiterhin die Funktionen des <strong>Complete</strong> Plans nutzen.",
  "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "Der <strong>Complete</strong> Jahresplan wird sofort aktiviert und Sie werden für die anteilige Preisdifferenz bis zum Ende des aktuellen jährlichen Abrechnungszeitraums berechnet.<legacyPlan> Bitte beachten Sie, dass Sie nicht zum Legacy-Plan zurückwechseln können.</legacyPlan>",
  "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "Der <strong>Complete</strong> Jahresplan wird sofort aktiviert. Ihr Jahrespreis wird um den verbleibenden Betrag auf Ihrer aktuellen Monatsrechnung reduziert.<legacyPlan> Bitte beachten Sie, dass Sie nicht zum Legacy-Plan zurückwechseln können.</legacyPlan>",
  "components.switchPlanModal.switchFromMonthlyToAnnually": "Der <strong>{newPlan}</strong> Jahresplan wird sofort aktiviert. Ihr Jahrespreis wird um den verbleibenden Betrag auf Ihrer aktuellen Monatsrechnung reduziert.<legacyPlan> Bitte beachten Sie, dass Sie nicht zum Legacy-Plan zurückwechseln können.</legacyPlan>",
  "components.switchPlanModal.title": "Wechseln zum <strong>{plan}</strong> Plan",
  "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Probezeit-Add-On abbestellen",
  "components.teamForm.approvers": "Genehmiger",
  "components.teamForm.generalSettings": "Allgemeine Einstellungen",
  "components.teamForm.isDefault": "Diese Abteilung zur Standardabteilung machen",
  "components.teamForm.isDefaultExtra": "Wenn Sie diese Abteilung zur Standardabteilung machen, werden alle neuen Teammitglieder automatisch dieser Abteilung hinzugefügt",
  "components.teamForm.name": "Name",
  "components.teamForm.pleaseEnterTeamName": "Geben Sie den Abteilungsnamen ein",
  "components.teamForm.users": "Benutzer",
  "components.toil.accrualsToilsTotal": "Insgesamt verdiente {hours, plural, =1 {Stunden} other {Tage}} (TOIL + Ansammlung): {total}",
  "components.toil.added": "TOIL hinzugefügt",
  "components.toil.addToil": "TOIL hinzufügen",
  "components.toil.addToilProgress": "TOIL wird hinzugefügt",
  "components.toil.anotherAdd": "Weitere TOIL hinzufügen",
  "components.toil.anotherRequest": "Weitere TOIL beantragen",
  "components.toil.approveInProgress": "TOIL-Antrag wird genehmigt",
  "components.toil.bot.message.editedToilRequest": "Hallo, <@{userSlackId}> hat den folgenden TOIL-Antrag bearbeitet:",
  "components.toil.bot.message.leaveTypeName": "*Urlaubsart:*\nTOIL - {leaveTypeName}",
  "components.toil.bot.message.requestToil": "TOIL beantragen",
  "components.toil.bot.message.requestToilSummary": "Bitte beginnen Sie Ihren Antrag auf Zeitausgleich, indem Sie die Daten angeben, an denen Sie Überstunden geleistet haben, und fügen Sie relevante Bemerkungen hinzu. Beachten Sie, dass Anträge auf Zeitausgleich nur für Daten in der Vergangenheit, einschließlich heute, gestellt werden können.",
  "components.toil.bot.message.submittedToilRequest": "Hallo, <@{userSlackId}> hat die folgende TOIL-Anfrage eingereicht:",
  "components.toil.bot.notification.slack.toilRequestAdded": "Ein TOIL-Urlaub wurde von einem Genehmiger für Sie erstellt",
  "components.toil.bot.notification.slack.toilRequestAddedSelf": "Sie haben einen TOIL-Urlaub hinzugefügt",
  "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> hat die Anfrage genehmigt, keine weitere Aktion erforderlich.",
  "components.toil.bot.notification.slack.toilRequestApprovedTitle": "Ihre Urlaubsanfrage wurde genehmigt",
  "components.toil.bot.notification.slack.toilRequestCreated": "Hallo, <@{userSlackId}> hat eine TOIL-Anfrage eingereicht.",
  "components.toil.bot.notification.slack.toilRequestEdited": "Hallo, <@{userSlackId}> hat eine TOIL-Anfrage bearbeitet.",
  "components.toil.cancelInProgress": "Die TOIL-Anfrage wird storniert",
  "components.toil.deleteInProgress": "TOIL wird gelöscht",
  "components.toil.denied": "Ihre TOIL-Anfrage wurde abgelehnt",
  "components.toil.deniedBy": "TOIL-Anfrage wurde von {approverName} abgelehnt.",
  "components.toil.denyInProgress": "TOIL-Anfrage wird abgelehnt",
  "components.toil.durationLabel": "Kürzeste Dauer für Zeitausgleich",
  "components.toil.durationLabelTooltip": "Bitte wählen Sie die kürzeste Dauer, die für die TOIL-Anfrage gilt. Diese Auswahl sollte die Mindestmenge an Überstunden widerspiegeln, die ein Benutzer protokollieren kann.",
  "components.toil.earned": "TOIL verdient",
  "components.toil.edit": "TOIL aktualisieren",
  "components.toil.edited": "TOIL aktualisiert",
  "components.toil.editRequestToil": "Zeitausgleich-Anfrage bearbeiten",
  "components.toil.editToilProgress": "Zeitausgleich wird bearbeitet",
  "components.toil.enableLabel": "Zeitausgleich aktivieren:",
  "components.toil.enableLabelTooltip": "TOIL ist eine Praxis, bei der Mitarbeiter für Überstunden mit entsprechender Freizeit entschädigt werden, anstatt zusätzlich für die geleisteten Stunden bezahlt zu werden.",
  "components.toil.expiration": "Ablauf",
  "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {Monat} other {Monate}}",
  "components.toil.expirationDurationCalendar": "Ende des Kalenderjahres",
  "components.toil.expirationLabel": "Sollen Zeitausgleich-Tage verfallen?",
  "components.toil.expirationLabelTooltip": "Aktivieren Sie diese Einstellung, wenn Sie möchten, dass TOIL-Tage ein Ablaufdatum haben, nach dem sie nicht mehr verwendet werden können. Bei Auswahl von 'Ja' werden Sie aufgefordert, Ablaufregeln festzulegen. Wenn 'Nein' ausgewählt ist, verfallen TOIL-Tage nicht und bleiben verfügbar, bis sie genutzt werden.",
  "components.toil.featurePlan": "Die TOIL-Funktion ist nur im Complete-Plan verfügbar.",
  "components.toil.fullDayLeavePeriod": "{date}{endDate} ",
  "components.toil.halfDay": "Halbtägiger TOIL",
  "components.toil.hourly": "Stündlicher TOIL",
  "components.toil.lapsed": "abgelaufen",
  "components.toil.locationNotHaveToil": "Keine Urlaubsrichtlinie mit TOIL ist für {me, plural, =1 {Ihren} other {den Benutzer}} Standort eingerichtet",
  "components.toil.notification.added": "TOIL <forUser>für </forUser>{requestor} hinzugefügt <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.toil.notification.approved": "{requestor} TOIL genehmigt <byActionCreator>von {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.cancelled": "{requestor} TOIL storniert <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.toil.notification.created": "TOIL angefordert <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.toil.notification.deleted": "TOIL gelöscht <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.toil.notification.denied": "{requestor} TOIL abgelehnt <byActionCreator>von {actionCreator}</byActionCreator> ❌",
  "components.toil.notification.edited": "{requestor} TOIL bearbeitet <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.toil.notification.editedApproved": "Ein geplanter TOIL wurde bearbeitet <byActionCreator>von {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.editedDenied": "Bearbeiteter TOIL-Antrag abgelehnt <byActionCreator>von {actionCreator}</byActionCreator>",
  "components.toil.partDayPeriod": "{date} - von {partDayStartHour}{hourFormat24, select, true {:00} other {}} bis {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.toil.reasonDefault": "Zusätzliche Arbeitsstunden während des oben angegebenen Zeitraums.",
  "components.toil.request": "TOIL-Antrag",
  "components.toil.requested": "TOIL beantragt",
  "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Beantragt} other {Hinzugefügt}} {period}",
  "components.toil.requester": "Wer kann Time Off In Lieu beantragen?",
  "components.toil.requesterAdmin": "Nur Administratoren und Genehmiger",
  "components.toil.requesterAll": "Alle Benutzer",
  "components.toil.requesterTooltip": "Wählen Sie 'Alle Benutzer', um jedem Mitarbeiter zu erlauben, seine eigenen TOIL-Anträge nach Überstunden einzureichen. Wählen Sie 'Nur Administratoren und Genehmiger', wenn Sie die TOIL-Verwaltung zentralisieren und eine strengere Kontrolle über die Genehmigung und Erfassung behalten möchten.",
  "components.toil.requestNotFound": "TOIL-Antrag nicht gefunden",
  "components.toil.requestToil": "Time Off In Lieu beantragen",
  "components.toil.requestToilProgress": "Time Off In Lieu wird beantragt",
  "components.toil.submitError": "TOIL-Übermittlungsfehler",
  "components.toil.submitErrorDate": "Der TOIL-Antrag kann nicht übermittelt werden, da das von Ihnen gewählte Startdatum nach dem Enddatum liegt. Stellen Sie sicher, dass das Startdatum vor dem Enddatum liegt, und reichen Sie den Antrag erneut ein",
  "components.toil.submitErrorDateFuture": "Das beantragte TOIL-Datum muss in der Vergangenheit liegen",
  "components.toil.submitErrorLongerThanWorkday": "Die beantragten TOIL-Stunden sind länger als die Arbeitszeit",
  "components.toil.submitErrorNotOpen": "TOIL ist nicht geöffnet",
  "components.toil.submitErrorOverlap": "Die beantragten TOIL-Tage überschneiden sich mit bestehendem TOIL",
  "components.toil.submitErrorPlan": "Diese Funktion ist ausschließlich für Benutzer zugänglich, die unseren Complete-Plan abonniert haben.",
  "components.toil.submitErrorPolicy": "Die Urlaubsrichtlinie für diesen Urlaubstyp erlaubt kein TOIL",
  "components.toil.submitErrorPolicyDuration": "Benutzer mit benutzerdefinierten Arbeitszeiten dürfen kein mehrtägiges TOIL beantragen",
  "components.toil.submitErrorPolicyLocation": "Die TOIL-Option ist für Ihren Standort nicht aktiviert. Sie können sie auf der Seite für Urlaubsrichtlinien aktivieren oder sich für Unterstützung an Ihren Administrator wenden.",
  "components.toil.submitErrorReasonNotSet": "TOIL-Grund ist erforderlich",
  "components.toil.submitErrorRole": "TOIL-Antrag nicht erlaubt",
  "components.updateWorkweekForm.cancel": "Abbrechen",
  "components.updateWorkweekForm.form.error": "Bitte wählen Sie mindestens einen Arbeitstag aus",
  "components.updateWorkweekForm.title": "Arbeitswoche aktualisieren",
  "components.updateWorkweekForm.update": "Aktualisieren",
  "components.updateWorkweekForm.workWeek": "Arbeitswoche",
  "components.userBroughtForwardForm.customRolloverDays": "Tage",
  "components.userBroughtForwardForm.leaveTypeEditInfo": "Sie bearbeiten den Urlaubstyp \"{leaveTypeName}\" für den Zeitraum {dateRangeName}.",
  "components.userBroughtForwardForm.update": "Aktualisieren",
  "components.userBroughtForwardForm.updateUserLeaveType": "Übertragene Tage für Urlaubstyp {name} aktualisieren",
  "components.userLabes.addLabel": "Label hinzufügen",
  "components.userLabes.manageLabels": "Labels verwalten",
  "components.userLabes.newLabel": "(neues Label)",
  "components.userLabes.noLabelsFound": "Keine Labels gefunden",
  "components.userLeaveQuotas.accrualsTooltipTitle": "Ansammlung ist die Akkumulation oder schrittweise Erhöhung Ihrer bezahlten Urlaubsstunden im Laufe des Jahres.",
  "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "Eine zweiwöchentliche {leaveType}-Ansammlung erfolgt alle zwei Wochen",
  "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "Eine monatliche {leaveType}-Ansammlung erfolgt einmal im Monat.",
  "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "Eine halbmonatliche {leaveType}-Ansammlung erfolgt zweimal im Monat.",
  "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "Eine wöchentliche {leaveType}-Ansammlung erfolgt jede Woche",
  "components.userLeaveQuotas.accruedDays": "Angesammelte Tage",
  "components.userLeaveQuotas.accruedHours": "Angesammelte Stunden",
  "components.userLeaveQuotas.accruedInfo1": "{userName} hat {earnedDays} Tage ({earnedDaysInHours} Stunden) von {defaultDays} Tagen ({defaultDaysInHours} Stunden) angesammelt",
  "components.userLeaveQuotas.accruedInfo1Days": "{userName} hat {earnedDays} von {defaultDays} Tagen angesammelt",
  "components.userLeaveQuotas.accruedInfo1Hours": "{userName} hat {earnedDaysInHours} von {defaultDaysInHours} Stunden angesammelt",
  "components.userLeaveQuotas.accruedInfo2": "{userName} wird am {earnDate} {toAccrueDays} Tage ({toAccrueHours} Stunden) ansammeln",
  "components.userLeaveQuotas.accruedInfo2Days": "{userName} wird am {earnDate} {toAccrueDays} Tage ansammeln",
  "components.userLeaveQuotas.accruedInfo2Hours": "{userName} wird am {earnDate} {toAccrueHours} Stunden ansammeln",
  "components.userLeaveQuotas.baseQuota": "Basisquote",
  "components.userLeaveQuotas.broughtForward": "Übertragen",
  "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>Maximal {maxRolloverDays} Tage<br></br></rolloverDays><expiresDate>Verfällt am {date}</expiresDate>",
  "components.userLeaveQuotas.broughtForwardInfo": "Übertragen repräsentiert die Anzahl der nicht genutzten Tage, die aus der vorherigen Periode übernommen wurden.",
  "components.userLeaveQuotas.broughtForwardInfoDetails": "Für {leaveTypeName} am Standort {locationName} können Mitarbeiter bis zu {broughtForwardLimit} ungenutzte {broughtForwardLimit, plural, =1 {Tag} other {Tage}} übertragen.",
  "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "Für {leaveTypeName} am Standort {locationName} können alle ungenutzten Tage in die nächste Periode übertragen werden.",
  "components.userLeaveQuotas.broughtForwardValue": "Übertragen",
  "components.userLeaveQuotas.editInitialQuotaTitle": "Anfängliche übertragene Tage für {leaveTypeName} bearbeiten",
  "components.userLeaveQuotas.editInitialQuotaTooltip": "Anfänglicher Übertrag ermöglicht es Ihnen, die Startguthaben der Mitarbeiter aus einem vorherigen Zeiterfassungs- oder HR-System manuell einzugeben",
  "components.userLeaveQuotas.editQuotaButton": "Ändern",
  "components.userLeaveQuotas.expirationTooltip": "Tage, die der Mitarbeiter aus der vorherigen Periode übertragen hat, aber vor dem Verfallsdatum nicht genutzt wurden",
  "components.userLeaveQuotas.expiredDateInfo": "am {date}",
  "components.userLeaveQuotas.forMoreDetails": "Für weitere Details siehe",
  "components.userLeaveQuotas.initialBroughtForward": "Anfänglicher Übertrag",
  "components.userLeaveQuotas.initialBroughtForwardInfo": "Anfänglicher Übertrag ist das Urlaubsguthaben, das ein Benutzer aus dem letzten Jahr übertragen hat, in dem Vacation Tracker noch nicht Teil Ihrer Organisation war. Beachten Sie, dass Sie nur das anfängliche übertragene Urlaubsguthaben bearbeiten können, während das verfügbare Guthaben, das Sie in den folgenden Jahren übertragen können, von Vacation Tracker berechnet wird.",
  "components.userLeaveQuotas.leavePoliciesNotExistInfo": "Es sind derzeit keine Urlaubsarten verfügbar. Bitte weisen Sie dem Standort, zu dem dieser Benutzer gehört, Urlaubsrichtlinien zu.",
  "components.userLeaveQuotas.leaveTypes": "Urlaubsarten",
  "components.userLeaveQuotas.logsTab": "Protokoll-Tab.",
  "components.userLeaveQuotas.noLimit": "Sie haben eine unbegrenzte Quote.",
  "components.userLeaveQuotas.ofDefaultDays": "von {value} Tagen",
  "components.userLeaveQuotas.ofDefaultHours": "von {value} Stunden",
  "components.userLeaveQuotas.overlapDaysContactSupport": "Überschneidung von Daten, bitte kontaktieren Sie den Support für weitere Informationen.",
  "components.userLeaveQuotas.paidDays": "Ausbezahlte Tage",
  "components.userLeaveQuotas.quota": "Quote",
  "components.userLeaveQuotas.quotaInfo": "Die Quote repräsentiert die Anzahl der bezahlten Urlaubstage, die für den aktuellen Zeitraum verfügbar sind. Diese Zahl beinhaltet die Basisquote, Zeitausgleich und Tage, die basierend auf der Rolle oder Seniorität des Mitarbeiters gewährt wurden.",
  "components.userLeaveQuotas.quotaInfoSummary": "Quote für den aktuellen Zeitraum:",
  "components.userLeaveQuotas.remaining": "Verbleibend",
  "components.userLeaveQuotas.remainingAfterRequest": "Verbleibend nach Anfrage",
  "components.userLeaveQuotas.remainingTooltip": "Verbleibende {leaveTypeName}-Tage für den aktuellen Zeitraum. Insgesamt:",
  "components.userLeaveQuotas.remainingTooltipFooter": "Bis zu {broughtForwardLimit} ungenutzte {period} werden in den nächsten Zeitraum übertragen",
  "components.userLeaveQuotas.remainingTooltipFooterAll": "Alle ungenutzten Tage können in den nächsten Zeitraum übertragen werden.",
  "components.userLeaveQuotas.remainingTooltipFooterZero": "Es können keine ungenutzten Tage in den nächsten Zeitraum übertragen werden.",
  "components.userLeaveQuotas.requested": "Beantragt",
  "components.userLeaveQuotas.saveQuotaButton": "Speichern",
  "components.userLeaveQuotas.scheduled": "Geplant",
  "components.userLeaveQuotas.scheduledDays": "Geplante Tage",
  "components.userLeaveQuotas.sectionTitle": "Urlaubskontingente",
  "components.userLeaveQuotas.selectPeriod": "Zeitraum auswählen:",
  "components.userLeaveQuotas.unlock": "Entsperren",
  "components.userLeaveQuotas.unlockBroughtForwardInfo": "Zum Entsperren gehen Sie zu Einstellungen → Standorte → {locationName} → Urlaubsrichtlinien anzeigen und bearbeiten Sie die {leaveTypeName}-Richtlinie, um Übertrag zu erlauben.",
  "components.userLeaveQuotas.used": "Genutzt",
  "components.userLeaveQuotas.usedAlready": "Bereits genutzt",
  "components.userLeaveQuotas.usedDays": "Genutzte Tage",
  "components.userLeaveQuotas.usedTooltip": "Genutzte, geplante und ausgezahlte {leaveTypeName}-Tage für den aktuellen Zeitraum. Insgesamt:",
  "components.userLeaveQuotas.userInactiveInfo": "Da das Konto jetzt inaktiv ist, werden keine weiteren Tage hinzugefügt, bis es reaktiviert wird.",
  "components.userLeaveQuotasCompact.title": "Ihr Urlaubsguthaben",
  "components.userLeaveQuotasCompact.titleForApprover": "Urlaubsguthaben",
  "components.userLeaveTypeForm.applyForFutureYears": "Möchten Sie das Basis-Urlaubskontingent für zukünftige Jahre anwenden?",
  "components.userLeaveTypeForm.cancel": "Abbrechen",
  "components.userLeaveTypeForm.daysPerYear": "Was ist Ihr neues Urlaubskontingent?",
  "components.userLeaveTypeForm.hasUnlimitedDays": "Möchten Sie unbegrenzten Urlaub erlauben?",
  "components.userLeaveTypeForm.leaveTypeEditInfo": "Sie bearbeiten den Urlaubstyp \"{leaveTypeName}\" für den Zeitraum {dateRangeName}.",
  "components.userLeaveTypeForm.prorateInfo": "{user} wird {proratedQuota} ({preciseQuota} Tage, um genau zu sein) für das erste Jahr ({yearPeriod}) und {fullQuota} für alle Jahre ab {startOfNextYear} haben.",
  "components.userLeaveTypeForm.prorateInfoDays": "{user} wird {proratedQuota} ({preciseQuota} Tage, um genau zu sein) für das erste Jahr ({yearPeriod}) und {fullQuota} Tage für alle Jahre ab {startOfNextYear} haben.",
  "components.userLeaveTypeForm.prorateInfoHours": "{user} wird {proratedQuota} ({preciseQuota} Stunden, um genau zu sein) für das erste Jahr ({yearPeriod}) und {fullQuota} Stunden für alle Jahre ab {startOfNextYear} haben.",
  "components.userLeaveTypeForm.prorateLabel": "Anteilig für das erste Jahr basierend auf dem Startdatum des Benutzers ({startDate})",
  "components.userLeaveTypeForm.quotaAdditionalRole": "Zusätzliche Tage basierend auf der Rolle festlegen",
  "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Weisen Sie Mitarbeitern zusätzliche Urlaubstage je nach ihrer Jobposition zu. Dieses Feld ermöglicht es Ihnen, Urlaubsansprüche anzupassen, um die Verantwortlichkeiten oder spezifischen Anforderungen verschiedener Positionen innerhalb der Organisation widerzuspiegeln.",
  "components.userLeaveTypeForm.quotaAdditionalSeniority": "Zusätzliche Tage basierend auf Dienstalter-Anspruch festlegen",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "Dienstalter-basierte Tage werden durch die Dienstalter-Anspruch-Automatisierung hinzugefügt. Sie können dienstalter-basierte {leaveTypeName}-Tage überschreiben, indem Sie den Wert in diesem Feld festlegen.",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "Dienstalter-basierte Tage werden durch die Dienstalter-Anspruch-Automatisierung hinzugefügt. Sie können dienstalter-basierte {leaveTypeName}-Tage überschreiben, indem Sie den Wert in diesem Feld festlegen. Dieses Feld ist jedoch gesperrt, da Sie diesen Wert für zukünftige Jahre nicht bearbeiten können, bevor die Tage zuerkannt werden.",
  "components.userLeaveTypeForm.quotaAdditionalSummary": "Sie legen das {leaveTypeName}-Urlaubskontingent für {user} für den ausgewählten Zeitraum auf {total} {hourlyLeaveAccounting, plural, =1 {Stunden} other {Tage}} fest. Das Basiskontingent und die zusätzlichen Tage basierend auf Dienstalter-Anspruch und rollenbasiertem Anspruch ({total} {hourlyLeaveAccounting, plural, =1 {Stunden} other {Tage}} insgesamt) werden für {future, plural, =1 {den ausgewählten Zeitraum und zukünftige Zeiträume} other {den ausgewählten Zeitraum}} angewendet. {prorateText}",
  "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "Sie legen das {leaveTypeName}-Urlaubskontingent für {user} für den ausgewählten Zeitraum auf Unbegrenzt fest.",
  "components.userLeaveTypeForm.quotaBase": "Basis-Urlaubskontingent festlegen",
  "components.userLeaveTypeForm.quotaBaseTooltip": "Das Basiskontingent ist das Standardkontingent des Benutzers, das zusätzliche Tage aufgrund von Dienstalter, Rolle, Überstundenausgleich oder andere Extratage ausschließt. Sie können das Basiskontingent für alle an einem Standort auf der Urlaubsrichtlinienseite ändern oder dieses Feld verwenden, um ein benutzerdefiniertes Kontingent für einen ausgewählten Benutzer festzulegen.",
  "components.userLeaveTypeForm.required": "Dieses Feld ist erforderlich.",
  "components.userLeaveTypeForm.update": "Aktualisieren",
  "components.userLeaveTypeForm.updateUserLeaveType": "Aktualisieren Sie die Kontingente des Benutzers für den Urlaubstyp {name}",
  "components.userLogsTab.ACCRUED_DAYS": "Angesammelte Tage",
  "components.userLogsTab.ALL": "Alle",
  "components.userLogsTab.APPROVER_STATUS": "Rollenänderung",
  "components.userLogsTab.filter": "Filter:",
  "components.userLogsTab.LOCATION_USER_MOVED": "Standortänderung",
  "components.userLogsTab.logsNotFound": "Für den ausgewählten Zeitraum wurden keine Protokolle gefunden.",
  "components.userLogsTab.ROLLOVER_EXPIRED": "Übertragene Tage abgelaufen",
  "components.userLogsTab.selectFromTo": "Datumsbereich auswählen:",
  "components.userLogsTab.TEAM_USER_MOVED": "Abteilungsänderung",
  "components.userLogsTab.TOIL_REQUEST_APPROVED": "Zeitausgleich",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Anspruch nach Rolle",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Anspruch nach Rolle gelöscht",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Anspruch nach Rolle überschrieben",
  "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "Urlaubskontingent auf Standard zurückgesetzt",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Anfänglicher Übertrag gelöscht",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Anfänglicher Übertrag aktualisiert",
  "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "Urlaubskontingent aktualisiert",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Dienstaltersanspruch",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Dienstaltersanspruch überschrieben",
  "components.userLogsTab.USER_STATUS_CHANGED": "Statusänderung",
  "components.userLogsTab.USER_UPDATED": "Benutzerinformationen aktualisiert",
  "components.userLogsTab.USER_WORK_WEEK_DELETED": "Benutzerdefinierte Arbeitswoche gelöscht",
  "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Arbeitswoche aktualisiert",
  "components.userLogsTab.YEAR_START": "Jahresbeginn",
  "components.userManageLabels.actions": "Aktionen",
  "components.userManageLabels.color": "Farbe",
  "components.userManageLabels.deleteLabel": "Sind Sie sicher, dass Sie dieses Etikett löschen möchten?\n\nDies wird das Etikett für alle zugewiesenen Benutzer löschen.",
  "components.userManageLabels.findLabel": "Etikett suchen",
  "components.userManageLabels.isRequired": "{title} ist erforderlich",
  "components.userManageLabels.manageLabels": "Etiketten verwalten",
  "components.userManageLabels.name": "Name",
  "components.userManageLabels.userCount": "Benutzeranzahl",
  "components.userProfileTab.admin": "Administrator",
  "components.userProfileTab.approver": "Genehmiger",
  "components.userProfileTab.approvers": "Genehmiger:",
  "components.userProfileTab.approverSuffix": " (Genehmiger)",
  "components.userProfileTab.customWorkWeekEditedBefore": "Die Arbeitswoche von {userName} wurde in der vorherigen Version von Vacation Tracker (vor dem 1. Januar 2021) geändert",
  "components.userProfileTab.customWorkWeekLabel": "benutzerdefinierte Arbeitswoche",
  "components.userProfileTab.customWorkWeekTooltip": "Die Arbeitswoche von {userName} wurde von {adminName} am {timestamp} bearbeitet",
  "components.userProfileTab.employeeIdNotSet": "Nicht festgelegt",
  "components.userProfileTab.employeeIdTooltip": "ID des Mitarbeiters in einem externen System",
  "components.userProfileTab.employeeSince": "Mitarbeiter seit:",
  "components.userProfileTab.endDate": "Enddatum:",
  "components.userProfileTab.location": "Standort:",
  "components.userProfileTab.name": "Name:",
  "components.userProfileTab.nameEdited": "Der Name wurde manuell geändert und wird nicht mehr automatisch synchronisiert. Dieser Name wird nur innerhalb von Vacation Tracker verwendet.",
  "components.userProfileTab.role": "Rolle:",
  "components.userProfileTab.status": "Status:",
  "components.userProfileTab.team": "Abteilung:",
  "components.userProfileTab.user": "Benutzer",
  "components.userProfileTab.userProfile": "Benutzerprofil",
  "components.userProfileTab.workingDays": "Arbeitstage",
  "components.userTodayOff.cancelLeave": "Urlaub stornieren",
  "components.userTodayOff.editLeave": "Urlaub bearbeiten",
  "components.userTodayOff.leaveInfo": "Urlaubsinfo",
  "components.userTodayOff.offFromTo": "Benutzer hat {leaveTypeName} von {startDate} bis {endDate} genommen",
  "components.userTodayOff.offTodayFullDay": "Benutzer hat heute, am {today}, {leaveTypeName} genommen",
  "components.userTodayOff.offTodayPartDay": "Benutzer hat heute {leaveTypeName} von {from}:00 Uhr bis {to}:00 Uhr genommen.",
  "components.userTodayOff.offTodayReason": "Grund: {reason}",
  "components.userUpdateForm.active": "Aktiv",
  "components.userUpdateForm.admin": "Administrator",
  "components.userUpdateForm.approver": "Genehmiger",
  "components.userUpdateForm.approverSuffix": " (Genehmiger)",
  "components.userUpdateForm.cancel": "Abbrechen",
  "components.userUpdateForm.employeeSince": "Mitarbeiter seit",
  "components.userUpdateForm.location": "Standort",
  "components.userUpdateForm.name": "Name",
  "components.userUpdateForm.pleaseSelectALocation": "Wählen Sie einen Standort",
  "components.userUpdateForm.pleaseSelectATeam": "Wählen Sie eine Abteilung",
  "components.userUpdateForm.pleaseSelectAUserRole": "Wählen Sie eine Benutzerrolle",
  "components.userUpdateForm.pleaseSelectEmployeeSince": "Fügen Sie das Datum 'Mitarbeiter seit' hinzu",
  "components.userUpdateForm.pleaseSelectUserEndDate": "Fügen Sie das Enddatum des Benutzers hinzu",
  "components.userUpdateForm.role": "Rolle",
  "components.userUpdateForm.team": "Abteilung",
  "components.userUpdateForm.update": "Aktualisieren",
  "components.userUpdateForm.updateUser": "Benutzer aktualisieren",
  "components.userUpdateForm.user": "Benutzer",
  "components.userUpdateForm.userEndDate": "Enddatum des Mitarbeiters",
  "components.userUpdateForm.warningInitialRolloverForChangeLocation": "Eine Änderung des Standorts des Benutzers kann sich auf die Konfiguration und Urlaubsinformationen des Benutzers auswirken (z. B. die anfänglich übertragenen Tage).",
  "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "Eine Änderung des Startdatums eines Benutzers kann sich auf die Urlaubsinformationen des Benutzers auswirken (z. B. die anfänglich übertragenen Tage).",
  "components.whatsNew.newMessage": "Neue Nachricht",
  "components.whatsNew.whatsNew": "Was gibt's Neues",
  "connect.alreadyHaveAnAccount": "Haben Sie bereits ein Konto?",
  "connect.alreadyHaveAnAccountWithLink": "Haben Sie bereits ein Konto? <b><link>Anmelden</link></b>",
  "connect.backToLoginWithOtherPlatofrm": "Zurück zur Anmeldung mit anderer Plattform",
  "connect.companyExists.line1": "{companyName} ist bereits bei Vacation Tracker registriert",
  "connect.companyExists.line2Other": "Verwenden Sie die richtige E-Mail-Adresse?",
  "connect.companyExists.line2Slack": "Verwenden Sie die richtigen Anmeldedaten?",
  "connect.companyExists.line3aSlack": "Ihr Slack-Arbeitsbereich: {workspaceName}",
  "connect.companyExists.line3Other": "Ihre E-Mail-Adresse: {email}",
  "connect.companyExists.line3Slack": "Ihre E-Mail-Adresse: {email}",
  "connect.companyExists.line4Other": "Wenn diese E-Mail-Adresse korrekt ist, ist Ihr Konto nicht aktiviert. Bitte kontaktieren Sie den Administrator Ihrer Vacation Tracker-Organisation:",
  "connect.companyExists.line4Slack": "Wenn diese Anmeldedaten korrekt sind, ist Ihr Konto nicht aktiviert. Bitte kontaktieren Sie den Administrator Ihrer Vacation Tracker-Organisation:",
  "connect.companyExists.line5": "Andernfalls melden Sie sich bitte mit einem anderen <capitalize>{platform}</capitalize>-Konto an oder melden Sie sich über ein Inkognito-Fenster in Ihrem Browser an.",
  "connect.companyExists.line6Microsoft": "Kontaktieren Sie den Support, um eine neue Abteilung in {companyName} zu erstellen",
  "connect.companyExists.lineEmailAddress": "Ihre E-Mail-Adresse",
  "connect.companyExists.title": "Unternehmen existiert bereits",
  "connect.connect": "Verbinden",
  "connect.connectWithWorkEmail": "Mit Arbeits-E-Mail anmelden",
  "connect.connectWithYourWorkEmail": "Melden Sie sich mit Ihrer Arbeits-E-Mail an",
  "connect.copyEmail": "Klicken Sie zum Kopieren der E-Mail",
  "connect.copyTextEnd": "Schönen Urlaub! 🌴",
  "connect.createAnOrganization": "Eine Organisation erstellen",
  "connect.description": "Um auf das Vacation Tracker-Dashboard zuzugreifen, wählen Sie die Schaltfläche \"Verbinden mit\", die der von Ihrem Team verwendeten Plattform entspricht.",
  "connect.dontHaveAnAccount": "Sie haben noch kein Konto?",
  "connect.doYouWantToLoginInToOrganization": "Möchten Sie sich bei {existingOrganizationName} anmelden?",
  "connect.forgotYourPassword": "Passwort vergessen?",
  "connect.forgotYourPasswordWhatsYourEmail": "Wie lautet Ihre E-Mail-Adresse?",
  "connect.google.notWorkspaceUserDescription": "Es scheint, dass Sie versuchen, sich mit einem persönlichen Konto und nicht mit einem Google Workspace-Konto anzumelden. Derzeit unterstützt Vacation Tracker nur Google Workspace-Konten.",
  "connect.google.notWorkspaceUserTitle": "Ein Google Workspace-Konto ist erforderlich",
  "connect.google.sharedPermissionsConsent": "Durch Klicken auf die Schaltfläche unten erteilen Sie Vacation Tracker die Erlaubnis, auf Ihr Google-Administratorbenutzverzeichnis zuzugreifen.",
  "connect.google.sharedPermissionsError": "Fehler beim Teilen der Google-Berechtigungen, Ihr Link ist abgelaufen.",
  "connect.google.sharedPermissionsRequired": "Unzureichende Berechtigungen",
  "connect.google.sharedPermissionsRequiredDescription": "Um mit dem Importieren von Benutzern fortzufahren, müssen Sie Berechtigungen von Ihrem Google-Administrator einholen. Bitte teilen Sie diesen Link mit Ihrem Administrator, um die erforderliche Zustimmung zu erteilen: ",
  "connect.google.sharedPermissionsSuccess": "Google-Berechtigungen erfolgreich geteilt!",
  "connect.google.sharedPermissionsSuccessRedirect": "Sie werden in wenigen Sekunden zur Vacation Tracker-Startseite weitergeleitet.",
  "connect.howShouldIConnect": "Wie soll ich mich verbinden?",
  "connect.inputEmailPlaceholder": "Geben Sie Ihre Arbeits-E-Mail ein",
  "connect.inputPasswordPlaceholder": "Geben Sie Ihr Passwort ein",
  "connect.interactionRequiredMSErrorDescription1": "Es scheint, dass Ihr Microsoft-Konto zusätzliche Berechtigungen benötigt. Bitte klicken Sie unten auf die Schaltfläche 'Anmelden', um zusätzliche Berechtigungen zu erteilen.",
  "connect.interactionRequiredMSErrorDescription2": "Wenn dieses Problem weiterhin besteht, kontaktieren Sie bitte unseren Support und senden Sie ihnen folgenden Code:",
  "connect.interactionRequiredMSErrorTitle": "Microsoft benötigt zusätzliche Berechtigungen",
  "connect.login": "Anmelden",
  "connect.meta.description": "Greifen Sie auf das Vacation Tracker Dashboard zu und nutzen Sie die volle Leistungsfähigkeit der mühelosen Urlaubsverwaltung.",
  "connect.meta.title": "Registrierung/Anmeldung - Vacation Tracker",
  "connect.meta.title.signin": "Anmelden - Vacation Tracker",
  "connect.meta.title.signup": "Registrierung - Vacation Tracker",
  "connect.or": "Oder",
  "connect.platformErrorDescription1": "Der folgende Fehler ist aufgetreten:",
  "connect.platformErrorDescription2": "Dies könnte ein {platform}-Fehler sein. Zögern Sie jedoch nicht, unseren Support für weitere Hilfe zu kontaktieren.",
  "connect.platformErrorTitle": "Etwas ist schiefgelaufen",
  "connect.pleaseInputValidEmail": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
  "connect.pleaseInputValidPassword": "Bitte geben Sie ein gültiges Passwort ein",
  "connect.pleaseLogIn": "Bitte melden Sie sich an",
  "connect.readMore": "Für weitere Informationen besuchen Sie unser <helpdesk>Helpdesk</helpdesk> oder gehen Sie zu unserer <website>Website</website>.",
  "connect.signInWithEmail": "Mit E-Mail anmelden",
  "connect.signInWithGoogle": "Mit Google anmelden",
  "connect.signInWithGoogleToOrganization": "Bei Google Workspaces für {existingOrganizationName} anmelden",
  "connect.signInWithGoogleVerification": "Mit Google anmelden",
  "connect.signInWithMS": "Mit Microsoft Teams anmelden",
  "connect.signInWithMSToOrganization": "Mit Microsoft Teams bei {existingOrganizationName} anmelden",
  "connect.signInWithSlack": "Mit Slack anmelden",
  "connect.signInWithSlackInstallBot": "Vacation Tracker zu Slack hinzufügen",
  "connect.signInWithSlackToOrganization": "Mit Slack bei {existingOrganizationName} anmelden",
  "connect.signUp": "Registrieren Sie sich mit Ihrer Arbeits-E-Mail",
  "connect.signUpInfo": "Melden Sie sich für Ihre kostenlose Testversion an",
  "connect.signupNewOrganizationUsingNewPlatform": "Registrieren Sie sich als neue Organisation mit Ihrem {selectedPlatform}-Konto",
  "connect.signUpWithEmail": "Mit E-Mail registrieren",
  "connect.signUpWithGoogle": "Mit Google registrieren",
  "connect.signUpWithMicrosoft": "Mit Microsoft registrieren",
  "connect.signUpWithSlack": "Mit Slack registrieren",
  "connect.slack.usersLoginRequiredButton": "Slack-Berechtigungen erteilen",
  "connect.slack.usersLoginRequiredDescription1": "Ihr Slack-Bot-Token ist abgelaufen oder nicht mehr gültig. Sie können nicht auf den Vacation Tracker Bot zugreifen oder Benachrichtigungen erhalten, bis Sie ein neues Token aktivieren.",
  "connect.slack.usersLoginRequiredDescription2": "Um ein neues Slack-Bot-Token zu aktivieren, klicken Sie bitte auf OK.",
  "connect.slack.usersLoginRequiredDescription3": "Um diesen Schritt zu überspringen und zum Online-Dashboard zu gelangen, klicken Sie auf Überspringen.",
  "connect.slack.usersLoginRequiredTitle": "Anmeldung bei Ihrem Slack-Konto erforderlich",
  "connect.slackPostInstallation1": "Sie haben die Vacation Tracker Slack-Anwendung erfolgreich installiert 🎉",
  "connect.slackPostInstallation2": "Um die App zu nutzen, melden Sie sich bitte beim Vacation Tracker Dashboard an und schließen Sie die Einrichtung ab.",
  "connect.slackPostInstallation3": "Wir werden Ihnen einige einfache Fragen stellen. Es sollte nicht länger als ein paar Minuten dauern. Ein Wimpernschlag und es ist erledigt (genau wie Ihr letzter Urlaub).",
  "connect.slackPostInstallationError.generic": "Hey, wir müssen zuerst den Vacation Tracker Bot installieren. Sie können die Berechtigungen der Vacation Tracker Slack-App im nächsten Schritt überprüfen und erteilen.",
  "connect.slackPostInstallationError.invalidCode": "Der Weiterleitungscode ist nicht mehr gültig. Dies kann passieren, wenn der Code abgelaufen ist, bereits verwendet wurde oder Sie die Seite aktualisiert haben, bevor wir den Anmeldeprozess abgeschlossen haben. Aber keine Sorge, lassen Sie uns einen neuen bekommen!",
  "connect.subscriptionExpired": "Ups, es scheint, dass das Abonnement Ihres Unternehmens für Vacation Tracker abgelaufen ist! Bitte kontaktieren Sie einen Administrator, um das Abonnement Ihres Unternehmens zu reaktivieren.",
  "connect.subscriptionExpiredTitle": "Abonnement abgelaufen",
  "connect.thisOrganizationUsePlatform": "Allerdings verwendet {existingOrganizationName} {existsOnAnotherPlatform}, daher müssen Sie sich mit {existsOnAnotherPlatform} anmelden, um auf Ihr Konto zuzugreifen",
  "connect.welcome": "Willkommen, {name}!",
  "connect.youAreAlreadyMember": "Sie sind bereits Mitglied der Organisation {existingOrganizationName}",
  "connectCalendar.connect": "Verbinden mit",
  "connectCalendar.disconnect": "Trennen",
  "connectCalendar.disconnectDescription": "Sind Sie sicher, dass Sie den Kalender trennen möchten? Vacation Tracker wird ihn nicht mehr mit Ihren zukünftigen Urlauben aktualisieren",
  "connectCalendar.disconnectError": "Fehler beim Trennen des Kalenders",
  "connectCalendar.disconnectErrorDescription": "Es gab einen Fehler beim Versuch, Ihren Kalender von Vacation Tracker zu trennen. Bitte klicken Sie auf OK, um es erneut zu versuchen.",
  "connectCalendar.disconnectSuccess": "Sie haben den {calendar}-Kalender erfolgreich getrennt.",
  "connectCalendar.disconnectTitle": "{calendarPlatform}-Kalender trennen",
  "connectCalendar.error": "Fehler beim Verbinden des Kalenders",
  "connectCalendar.errorDescription": "Es gab einen Fehler beim Versuch, Ihren Kalender mit Vacation Tracker zu verbinden. Bitte klicken Sie auf OK, um es erneut zu versuchen.",
  "connectCalendar.errorReconnectDescription": "Es gab einen Fehler beim Versuch, Ihren Kalender mit Vacation Tracker neu zu verbinden. Bitte klicken Sie auf OK, um es erneut zu versuchen.",
  "connectCalendar.reconnect": "Erneut verbinden",
  "connectCalendar.reconnectError": "Fehler beim erneuten Verbinden des Kalenders",
  "connectCalendar.reconnectInfo": "Um sicherzustellen, dass Ihr Urlaub sichtbar ist, verbinden Sie sich bitte erneut mit Ihrem Kalender.",
  "connectCalendar.reconnectNotification": "Die Verbindung zu Ihrem Kalender ist abgelaufen. Bitte klicken Sie hier, um sich erneut zu verbinden und sicherzustellen, dass Ihr Urlaub in Ihrem Kalender sichtbar ist.",
  "connectCalendar.reconnectNotificationSharedCalendar": "Ihre Verbindung zum geteilten Kalender: {calendarName} ist abgelaufen. Bitte klicken Sie hier, um sich erneut zu verbinden und sicherzustellen, dass Urlaube in Ihrem Kalender sichtbar sind.",
  "connectCalendar.reconnectSuccess": "Sie haben den {calendar}-Kalender erfolgreich neu verbunden.",
  "connectCalendar.success": "Sie haben den {calendar}-Kalender erfolgreich verbunden.",
  "connectedCalendar.eventDescription": "Hinzugefügt von <link>Vacation Tracker</link>",
  "connectedCalendar.eventDescriptionShared": "Geteiltes Kalenderereignis hinzugefügt von <link>Vacation Tracker</link>",
  "containers.google.topbar.adminProductTourInfo": "Als Administrator haben Sie die höchste Berechtigungsstufe in Vacation Tracker und können alle Einstellungen der Organisation über das Administrator-Dashboard anpassen.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen oder für andere Abteilungsmitglieder hinzufügen.\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender haben.\n- Mehrere Abteilungen erstellen und konfigurieren (Genehmiger zuweisen)\n- Benachrichtigungen einstellen\n- Benutzer verwalten (Aktiv, Inaktiv, Gelöscht).\n- Standorte erstellen und verwalten (Urlaubsarten, Feiertage, Benachrichtigungen)\n\nWenn Ihre Fragen oder Bedenken unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen ist der beste Teil unseres Tages:",
  "containers.google.topbar.approverProductTourInfo": "Als Genehmiger haben Sie die zweithöchste Berechtigungsstufe in Vacation Tracker und können Urlaubsanträge genehmigen oder ablehnen, die von Benutzern aus der Abteilung eingereicht wurden, für die Sie als Genehmiger zugewiesen wurden.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen oder für andere Abteilungsmitglieder hinzufügen.\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender haben.\n- Benachrichtigungen einstellen\n- Ihr Benutzerprofil anzeigen.\n- Anfragen verwalten (Anfragen genehmigen/ablehnen, Verlauf anzeigen).\n\nWenn Ihre Fragen oder Bedenken unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen ist der beste Teil unseres Tages:",
  "containers.google.topbar.userProductTourInfo": "Als Vacation Tracker-Benutzer können Sie Ihre Urlaubsanträge über das Dashboard einreichen und verwalten sowie einen vollständigen Überblick über geplante Abwesenheiten innerhalb Ihrer Organisation haben.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender haben.\n- Ihr Benutzerprofil anzeigen.\n\nWenn Ihre Fragen oder Bedenken unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen ist der beste Teil unseres Tages:",
  "containers.topbar.adminProductTourInfo": "Als Administrator haben Sie die höchste Berechtigungsstufe in Vacation Tracker und können alle Einstellungen der Organisation über das Administrator-Dashboard anpassen.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen oder für andere Abteilungsmitglieder hinzufügen.\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender haben.\n- Mehrere Abteilungen erstellen und konfigurieren (Genehmiger zuweisen)\n- Benachrichtigungen einstellen\n- Benutzer verwalten (Aktiv, Inaktiv, Gelöscht).\n- Standorte erstellen und verwalten (Urlaubsarten, Feiertage, Benachrichtigungen)\n\nDer Bot ermöglicht Ihnen:\n- Urlaub beantragen\n- Urlaubsanträge genehmigen/ablehnen\n- Tägliche/Wöchentliche Benachrichtigungen erhalten\n\nWenn Ihre Fragen oder Bedenken unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen ist der beste Teil unseres Tages:",
  "containers.topbar.adminProductTourTitle": "Administrator",
  "containers.topbar.announcement": "Ankündigung",
  "containers.topbar.approverProductTourInfo": "Als Genehmiger haben Sie die zweithöchste Berechtigungsstufe in Vacation Tracker und können Urlaubsanträge genehmigen oder ablehnen, die von Benutzern aus der Abteilung eingereicht wurden, für die Sie als Genehmiger zugewiesen wurden.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen oder für andere Abteilungsmitglieder hinzufügen.\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender haben.\n- Benachrichtigungen einstellen\n- Ihr Benutzerprofil anzeigen.\n- Anfragen verwalten (Anfragen genehmigen/ablehnen, Verlauf anzeigen).\n\nDer Bot ermöglicht Ihnen:\n- Urlaub beantragen\n- Urlaubsanträge genehmigen/ablehnen\n- Tägliche/Wöchentliche Benachrichtigungen erhalten\n\nWenn Ihre Fragen oder Bedenken unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen ist der beste Teil unseres Tages:",
  "containers.topbar.approverProductTourTitle": "Genehmiger",
  "containers.topbar.giveUsFeedback": "Geben Sie uns Feedback",
  "containers.topbar.help": "Hilfe",
  "containers.topbar.logout": "Abmelden",
  "containers.topbar.profile.info": "Sie können auf Ihr Profil zugreifen, das Design wechseln und die Schnelltour von diesem neuen Menü aus starten.",
  "containers.topbar.setUpTheBot": "Bot einrichten",
  "containers.topbar.userProductTourInfo": "Als Vacation Tracker-Benutzer können Sie Ihre Urlaubsanträge über das Dashboard oder den Bot einreichen und verwalten sowie einen vollständigen Überblick über geplante Abwesenheiten innerhalb Ihrer Organisation haben.\n\nDas Dashboard ermöglicht Ihnen:\n- Urlaub beantragen\n- Einen vollständigen Überblick durch die Dashboard-Startseite und den Kalender haben.\n- Ihr Benutzerprofil anzeigen.\n\nDer Bot ermöglicht Ihnen:\n- Urlaub beantragen\n- Tägliche/Wöchentliche Benachrichtigungen erhalten\n\nWenn Ihre Fragen oder Bedenken unbeantwortet bleiben, kontaktieren Sie uns jederzeit. Mit unseren Kunden zu sprechen ist der beste Teil unseres Tages:",
  "containers.topbar.userProductTourTitle": "Benutzer",
  "containers.topbar.whatsNewUnread": "Was gibt's Neues - {unread} ungelesen",
  "Conversation not found.": "Der ausgewählte Kanal wurde gelöscht oder ist nicht mehr verfügbar. Bitte wählen Sie einen anderen Kanal aus, um Benachrichtigungen zu erhalten.",
  "createCompany.signupError": "Etwas ist schiefgelaufen",
  "createCompany.steps.back": "Zurück",
  "createCompany.steps.cancel": "Abbrechen",
  "createCompany.steps.companyForm": "Unternehmensdetails",
  "createCompany.steps.companyForm.agreeTerms": "Ich akzeptiere die <aPP>Datenschutzrichtlinie</aPP>, die <aTOS>Nutzungsbedingungen</aTOS> und die <aDPA>Datenverarbeitungsvereinbarung</aDPA>. Ich bestätige und stimme auch der Verarbeitung meiner persönlichen Daten gemäß den in der Datenschutzrichtlinie dargelegten Bedingungen zu",
  "createCompany.steps.companyForm.agreeToTerms.error": "Sie müssen unseren Nutzungsbedingungen und der Datenschutzrichtlinie zustimmen, um fortzufahren.",
  "createCompany.steps.companyForm.companyInfoSectionDescription": "Erzählen Sie uns von Ihrem Unternehmen und Standort. Wir werden diese Informationen verwenden, um Ihr Firmenkonto und Ihren ersten Standort zu erstellen. Sie können später jederzeit weitere Standorte hinzufügen.",
  "createCompany.steps.companyForm.companyLeaveSection": "Standard-Urlaubsart",
  "createCompany.steps.companyForm.companyName": "Firmenname",
  "createCompany.steps.companyForm.companyNameInput.validation.required": "Dies ist ein Pflichtfeld.",
  "createCompany.steps.companyForm.contactEmail.validation.country": "Dies ist ein Pflichtfeld.",
  "createCompany.steps.companyForm.contactEmail.validation.required": "Erforderlich für wichtige Produktkommunikation.",
  "createCompany.steps.companyForm.contactEmail.validation.state": "Dies ist ein Pflichtfeld.",
  "createCompany.steps.companyForm.contactEmail.validation.type": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  "createCompany.steps.companyForm.contactEmailExtra": "Die von Ihnen angegebene E-Mail-Adresse wird nur für wichtige Produktkommunikation verwendet. Kein Spam, versprochen.",
  "createCompany.steps.companyForm.country": "Land",
  "createCompany.steps.companyForm.defaultPtoQuota": "Bezahlter Urlaub (PTO) Kontingent",
  "createCompany.steps.companyForm.defaultPtoQuota.validation": "Bitte geben Sie Ihr Standard-PTO-Kontingent ein",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "Ihr Standard-PTO-Kontingent kann nicht mehr als 366 Tage betragen",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "Ihr Standard-PTO-Kontingent kann nicht weniger als 0 Tage betragen",
  "createCompany.steps.companyForm.defaultPtoQuotaExtra": "Sie können dies später in Ihren Einstellungen ändern.",
  "createCompany.steps.companyForm.email": "Kontakt-E-Mail",
  "createCompany.steps.companyForm.location": "Standortinformationen: ",
  "createCompany.steps.companyForm.state": "Bundesland",
  "createCompany.steps.companyForm.step1Title": "Unternehmensdetails",
  "createCompany.steps.companyForm.step2Title": "Benutzer auswählen",
  "createCompany.steps.companyForm.step3Title": "Plan auswählen",
  "createCompany.steps.companyForm.unlimitedLeavePolicy": "Unbegrenzter PTO",
  "createCompany.steps.companyForm.UserInfoSectionDescription": "Erzählen Sie uns, wer Sie sind. Wir werden diese Informationen verwenden, um Ihr Benutzerprofil zu erstellen. Standardmäßig erhalten Sie die Rolle eines Administrators und Genehmigers. Sie können später weitere Benutzer hinzufügen und deren Rollen ändern.",
  "createCompany.steps.inviteUsers.next": "{noOfUsers} Benutzer{postfix} einladen",
  "createCompany.steps.inviteUsers.title": "Laden Sie Ihre Teammitglieder ein",
  "createCompany.steps.inviteUsers.title.info": "Laden Sie die Benutzer ein, die Sie zum Vacation Tracker-Konto Ihrer Organisation hinzufügen möchten. Sie können später jederzeit Änderungen über das Dashboard vornehmen.",
  "createCompany.steps.next": "Weiter",
  "createCompany.steps.selectPlan.noCC": "Keine Kreditkarte für Ihre 7-tägige Testphase erforderlich",
  "createCompany.steps.selectPlan.title": "Lass uns loslegen!",
  "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "Sie können während der Testphase jederzeit den Plan wechseln",
  "createCompany.steps.selectUsers": "Benutzer importieren",
  "createCompany.steps.selectUsers.deselectAll": "Alle abwählen",
  "createCompany.steps.selectUsers.doneButton": "{noOfUsers} Benutzer{postfix} importieren",
  "createCompany.steps.selectUsers.doneButtonTryItOut": "Starten Sie Ihre 14-tägige kostenlose Testversion",
  "createCompany.steps.selectUsers.loadingAll": "Alle Benutzer werden geladen",
  "createCompany.steps.selectUsers.searchUsers": "Nemo finden...",
  "createCompany.steps.selectUsers.selectAll": "Alle auswählen",
  "createCompany.steps.selectUsers.title": "Wählen Sie Ihre Teammitglieder aus",
  "createCompany.steps.selectUsers.title.info": "Wählen Sie die Benutzer aus, die Sie dem Vacation Tracker-Konto Ihrer Organisation hinzufügen möchten. Sie können später jederzeit Änderungen über das Dashboard vornehmen. Die ausgewählten Benutzer werden erst benachrichtigt, wenn Sie die Ankündigung machen.",
  "dashboard.accountSettingsLanguageLabel": "Wählen Sie Ihre bevorzugte Sprache",
  "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "Vacation Tracker wird alle Ihre zukünftigen Abwesenheitsnachrichten für die E-Mail-Adresse {email} festlegen.\n Wenn Sie jedoch vor dieser Änderung eine Abwesenheitsnachricht geplant haben, werden wir sie weiterhin für die alte E-Mail-Adresse einrichten.",
  "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "E-Mail-Adresse für zukünftige Abwesenheitsnachrichten ändern",
  "dashboard.accountSettingsOutOfOfficeConnectedWith": "Verbunden mit",
  "dashboard.accountSettingsOutOfOfficeTitle": "Automatische Abwesenheitsnotiz (OOO) konfigurieren",
  "dashboard.accountSettingsPageOOODescription": "Sobald aktiviert, wird Vacation Tracker Sie zwei Tage vor Ihrem geplanten Urlaub benachrichtigen und Ihnen die Möglichkeit bieten, eine automatisierte Abwesenheitsnotiz (OOO) einzurichten.",
  "dashboard.accountSettingsTimeDisplayLabel": "Wählen Sie Ihr bevorzugtes Zeitanzeigeformat",
  "dashboard.accountSettingsUIModeLabel": "Dunkelmodus vs. Hellmodus",
  "dashboard.b2bSoftware": "Bitte beachten Sie, dass dies eine B2B-Software ist und wir daher Ihre Daten auf Anfrage nicht ändern oder löschen können. Für weitere Informationen wenden Sie sich bitte an Ihren zuständigen Manager. Sollten Sie Hilfe benötigen, steht Ihnen unser Support-Team unter <mail>hello@vacationtracker.io</mail> zur Verfügung",
  "dashboard.days": "Tag(e)",
  "dashboard.daysSmallCase": "tag(e)",
  "dashboard.daysSmallCaseSingle": "t",
  "dashboard.googlePermissionInfo2": "- Zugriff auf Ihre Google Kalender-Einstellungen ist erforderlich, um Ihre Google Zeitzonen-Einstellungen zu erhalten.",
  "dashboard.googlePermissionInfo3": "- Zugriff auf Ihre Gmail-Einstellungen ist notwendig, um Ihre OOO-Antwort einzurichten und den relevanten Datumsbereich festzulegen.",
  "dashboard.hour": "Stunde(n)",
  "dashboard.hourSingle": "h",
  "dashboard.minuteSingle": "m",
  "dashboard.noLeaveRequest": "Diese Urlaubsanfrage ist nicht aktiv.",
  "dashboard.noOneIsOffToday": "Es gibt heute keine geplanten Abwesenheiten.",
  "dashboard.noOneIsTackingLeaves": "Derzeit sind keine Abwesenheiten geplant.",
  "dashboard.noOpenLeavesRequests": "Es gibt keine ausstehenden Anfragen.",
  "dashboard.noScheduledLeavesHolidays": "Keine geplanten Urlaube oder Feiertage",
  "dashboard.openLeavesRequests": "Ausstehende Anfragen",
  "dashboard.outlookPermissionInfo2": "- Offline-Zugriff: Dies ist notwendig, damit Vacation Tracker Ihre OOO-Antwort einrichten kann.",
  "dashboard.outlookPermissionInfo3": "- Outlook-Postfacheinstellungen: Wir benötigen Zugriff auf Ihre Outlook-Zeitzoneneinstellungen, um eine genaue Zeitplanung zu gewährleisten. Zusätzlich benötigen wir die Erlaubnis, Ihre OOO-Nachricht festzulegen und den gewünschten Datumsbereich zu definieren.",
  "dashboard.outOfOffice": "OOO-Nachricht festlegen",
  "dashboard.outOfOffice.requestIntegrationButton": "Eine Integration anfordern",
  "dashboard.outOfOfficeActiveLabel": "Abwesend",
  "dashboard.outOfOfficeGoogleMailButton": "Google E-Mail",
  "dashboard.outOfOfficeGoogleSigninModalTitle": "Google-Konto-Anmeldung erforderlich",
  "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Microsoft-Konto-Anmeldung erforderlich",
  "dashboard.outOfOfficeNotActiveInfo": "Es scheint, dass Sie die Funktion für automatische Abwesenheitsnotizen in Ihrem Konto noch nicht aktiviert haben. Mit diesem Tool können Sie automatisierte E-Mails für bestimmte Arten von Abwesenheiten einrichten, um Ihre Kontakte darüber zu informieren, wenn Sie nicht verfügbar sind. Möchten Sie diese Funktion aktivieren?",
  "dashboard.outOfOfficeOtherEmail": "Haben Sie einen anderen E-Mail-Anbieter?",
  "dashboard.outOfOfficeOtherEmailInfo": "Derzeit unterstützen wir Gmail und Outlook. Wir arbeiten jedoch ständig an der Verbesserung unserer Software. Ihr Feedback zum E-Mail-Anbieter, den Sie nutzen, wäre äußerst hilfreich, um unsere Entwicklungsprioritäten festzulegen. Bis wir unsere Unterstützung auf andere Anbieter ausweiten, können Sie Ihre Abwesenheitsnotiz manuell in Ihrem E-Mail-Dienst einrichten.",
  "dashboard.outOfOfficeOtherEmailPlaceholder": "Geben Sie Ihren Anbieter ein, z.B. ProtonMail, Yahoo! Mail...",
  "dashboard.outOfOfficeOutlookButton": "Outlook",
  "dashboard.outOfOfficePageSettingsTitle": "WÄHLEN SIE IHREN E-MAIL-ANBIETER",
  "dashboard.outOfOfficePageTitle": "Abwesenheitsnotiz",
  "dashboard.outOfOfficeSet": "Abwesenheitsnotiz festlegen",
  "dashboard.outOfOfficeSetError": "Beim Einrichten Ihrer automatischen Abwesenheitsnotiz ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "dashboard.outOfOfficeSetInfo": "Hier können Sie Ihre Abwesenheitsnotiz festlegen. Während Ihrer Abwesenheit wird diese Nachricht automatisch an Personen gesendet, die versuchen, Sie per E-Mail zu erreichen. Sollten Sie diese Nachricht in Zukunft ändern wollen, kann dies einfach über Ihre E-Mail-Einstellungen erfolgen.",
  "dashboard.outOfOfficeSetInfoBot": "Ihre Abwesenheitsnotiz ist eingerichtet. Während Ihrer Abwesenheit wird diese Nachricht automatisch an Personen gesendet, die versuchen, Sie per E-Mail zu erreichen. Sollten Sie diese Nachricht in Zukunft ändern wollen, kann dies einfach über Ihre E-Mail-Einstellungen erfolgen.",
  "dashboard.outOfOfficeSetSuccess": "Glückwunsch – Sie haben Ihre automatische Abwesenheitsnotiz erfolgreich eingerichtet. Sie wird aktiv, sobald Ihr Urlaub beginnt.",
  "dashboard.peopleOffToday": "Heutige Abwesenheiten",
  "dashboard.permissionModalInfo": "Damit Vacation Tracker Ihre Abwesenheitsnotiz (OOO) automatisch einstellen kann, benötigen wir weitere Berechtigungen:",
  "dashboard.selectSnoozeLeaveTypesPlaceholder": "Urlaubsarten auswählen...",
  "dashboard.snoozedLeaveTypesLabel": "Deaktivieren für ",
  "dashboard.upcomingDaysOff": "Geplante Abwesenheiten ({total})",
  "dashboard.upcomingHolidays": "Bevorstehende Feiertage",
  "departments.viewDepartment": "Abteilung anzeigen",
  "disconnectCalendar.success": "Sie haben den Kalender erfolgreich getrennt.",
  "editLeave.editLeave": "Urlaub bearbeiten",
  "editLeave.handleSubmitErrorTitle": "Fehler beim Bearbeiten des Urlaubs",
  "editLeave.inProgress": "Urlaub wird bearbeitet",
  "editLeave.requestLeave": "Urlaub bearbeiten",
  "editLeave.title": "Urlaub bearbeitet",
  "email.notification.accruedDaysInfoForApprover": "ℹ️ Dieser Urlaubsantrag basiert auf zukünftig angesammelten Urlaubstagen, nicht auf dem aktuellen Bestand. {name} hat heute noch {amount} Tag(e) übrig. Sie/Er wird am {earningDate} {accrued} ansammeln.",
  "email.notification.accruedDaysInfoForUser": "ℹ️ Ihr Urlaubsantrag basiert auf zukünftig angesammelten Urlaubstagen, nicht auf dem aktuellen Bestand. Sie haben heute noch {amount} Tag(e) übrig und werden am {earningDate} {accrued} ansammeln.",
  "email.notification.holiday.plural": "<strong>{locationNames}</strong> Standorte werden wegen <strong>{holidayName}</strong> abwesend sein {holidayPeriodText}",
  "email.notification.holiday.singular": "<strong>{locationNames}</strong> Standort wird wegen <strong>{holidayName}</strong> abwesend sein {holidayPeriodText}",
  "email.notification.title": "Urlaubsbenachrichtigung",
  "error.auth.companyExists.line1": "Ihr Konto ist nicht aktiviert. Um Vacation Tracker zu nutzen, kontaktieren Sie bitte den Administrator und bitten Sie ihn, Ihr Konto zu aktivieren.",
  "error.auth.companyExists.line2": "Administratoren:",
  "error.auth.companyExists.line3": "Oder kontaktieren Sie unseren Support ({support}), wenn Sie Vacation Tracker als unabhängige Abteilung im selben Unternehmen nutzen möchten.",
  "error.auth.companyExists.title": "Ihre Organisation ist bereits registriert",
  "error.automations.automationSubmitError": "Fehler beim Einreichen der Automatisierung",
  "error.automations.insufficientPrivileges.description": "Sie haben keine Berechtigung, diese Aktion durchzuführen. Bitte kontaktieren Sie Ihren Administrator.",
  "error.automations.insufficientPrivileges.title": "Berechtigungsfehler",
  "error.blackOutPeriod.isntCreated": "Sperrzeit wurde nicht erstellt",
  "error.blackOutPeriod.isntDeleted": "Sperrzeit wurde nicht gelöscht",
  "error.blackOutPeriod.isntUpdated": "Sperrzeit wurde nicht aktualisiert",
  "error.blackOutPeriod.UserNotAdminOrApprover": "Benutzer ist kein Administrator oder Genehmiger",
  "error.blackOutPeriod.UserNotFound": "Benutzer nicht gefunden",
  "error.botTokenNotFound": "Bot-Token nicht gefunden, bitte melden Sie sich in unserem Dashboard an und versuchen Sie es erneut.",
  "error.CannotDuplicateName": "Der eingegebene Name wird bereits verwendet. Bitte geben Sie einen anderen Namen ein.",
  "error.channelNotFound": "Der ausgewählte Kanal wurde gelöscht oder ist nicht mehr verfügbar. Bitte wählen Sie einen anderen Kanal für den Empfang von Benachrichtigungen aus.",
  "error.codeDeliveryFailureException": "Fehler bei der Codeübermittlung",
  "error.codeDeliveryFailureExceptionMessage": "Wir können keinen Verifizierungscode senden. Bitte überprüfen Sie, ob Sie eine gültige E-Mail-Adresse angegeben haben. Wenn Ihre E-Mail-Adresse gültig ist, versuchen Sie es bitte später erneut.",
  "error.codeMismatchException": "Ungültiger Verifizierungscode",
  "error.codeMismatchExceptionMessage": "Der angegebene Verifizierungscode ist ungültig. Bitte versuchen Sie es erneut oder lassen Sie sich eine neue E-Mail zusenden.",
  "error.companyExists": "Unternehmen existiert bereits",
  "error.CustomWorkday": "Eine Herabstufung auf einen Plan ohne benutzerdefinierte Arbeitszeiten ist nicht möglich. Bitte entfernen Sie die benutzerdefinierten Arbeitszeiten aus den Einstellungen oder kontaktieren Sie den Support.",
  "error.deletedLocation": "Der ausgewählte Standort wurde gelöscht oder ist nicht mehr verfügbar. Bitte wählen Sie einen anderen Standort für den Empfang von Benachrichtigungen aus.",
  "error.deletedTeam": "Die ausgewählte Abteilung wurde gelöscht oder ist nicht mehr verfügbar. Bitte wählen Sie eine andere Abteilung für den Empfang von Benachrichtigungen aus.",
  "error.DepartmentWithChildrenDeleteForbidden": "Sie können keine Abteilung löschen, die Unterabteilungen hat. Bitte löschen Sie zuerst die Unterabteilungen.",
  "error.emailError": "Ungültige E-Mail",
  "error.emailError.description": "Bitte geben Sie eine gültige E-Mail-Adresse an",
  "error.endTimeCantBeBeforeStartTime": "Es sieht so aus, als ob die von Ihnen gewählte Startzeit nach der Endzeit liegt. Bitte wählen Sie eine Startzeit, die vor der Endzeit liegt.",
  "error.entitlementByRole.isntCreated": "Berechtigung nach Rolle wurde nicht erstellt",
  "error.entitlementByRole.isntDeleted": "Berechtigung nach Rolle wurde nicht gelöscht",
  "error.entitlementByRole.isntUpdated": "Berechtigung nach Rolle wurde nicht aktualisiert",
  "error.expiredCodeException": "Verifizierungscode abgelaufen",
  "error.expiredCodeExceptionMessage": "Der angegebene Verifizierungscode ist abgelaufen.",
  "error.fetchingConnectedCalendarInfo": "Beim Abrufen Ihres verbundenen Kalenders ist ein Problem aufgetreten. Bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte unseren Kundensupport",
  "error.fetchingUserOutOfOfficeSettings": "Beim Abrufen Ihrer Abwesenheitseinstellungen ist ein Problem aufgetreten. Bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte unseren Kundensupport",
  "error.fieldIsRequired": "Dieses Feld ist erforderlich.",
  "error.firstEarningDateMissing": "Erstes Erwerbsdatum fehlt",
  "error.generic": "Ein Fehler ist aufgetreten",
  "error.generic.description": "Bitte kontaktieren Sie den Support mit der folgenden Nachricht:",
  "error.google.directoryApiDisabledP1": "Vacation Tracker benötigt Zugriff auf Verzeichnisdaten, um neue Benutzer zu importieren.",
  "error.google.directoryApiDisabledP2": "Der Administrator Ihres Google Workspace kann den Verzeichniszugriff erlauben, indem er die Schritte aus dem Support-Artikel befolgt: ",
  "error.google.directoryApiDisabledP3": "Sie können sich anmelden, ohne diese Funktion zu aktivieren. Sie werden jedoch nicht in der Lage sein, Benutzer aus Ihrem Google Workspace zu importieren.",
  "error.google.directoryApiDisabledP4": "Wenn Sie zusätzliche Hilfe benötigen, kontaktieren Sie uns unter hello@vacationtracker.io",
  "error.google.directoryApiDisabledTitle": "Kontaktfreigabe ausgeschaltet",
  "error.google.directoryApiPermissionDeniedP1": "Vacation Tracker muss in der Lage sein, Benutzer aus Ihrem Google Workspace-Verzeichnis aufzulisten. Bitte klicken Sie auf die Schaltfläche \"Mit Google anmelden\" und aktivieren Sie das Kontrollkästchen, wie im Bild unten gezeigt, um sich anmelden zu können.",
  "error.google.directoryApiPermissionDeniedP2": "Wir speichern nur die folgenden Informationen für die Benutzer, die Sie explizit zum Import auswählen: Name, Profilbild und E-Mail.",
  "error.google.directoryApiPermissionDeniedP3": "Wenn Sie das Kontrollkästchen nicht sehen, wenn Sie auf die Schaltfläche \"Mit Google anmelden\" klicken, klicken Sie bitte auf die Schaltfläche \"Fortfahren\" im Google-Berechtigungsmodal, um mit dem Anmeldeprozess fortzufahren.",
  "error.google.directoryApiPermissionDeniedTitle": "Fehlende Berechtigungen erteilen, um fortzufahren",
  "error.google.loginRequiredTitle": "Anmeldung bei Ihrem Google-Konto erforderlich",
  "error.google.usersLoginRequiredDescription1": "Die Verwaltung von Benutzern erfordert Google-Berechtigungen. Wenn Sie auf die OK-Schaltfläche klicken, leiten wir Sie zur Google-Anmeldeseite weiter. Je nach Ihren Browsereinstellungen sind Sie möglicherweise bereits angemeldet und werden automatisch zurückgeleitet.",
  "error.google.usersLoginRequiredDescription2": "Nach der Anmeldung bei Ihrem Google-Konto bringen wir Sie zurück auf diese Seite, damit Sie die Benutzer verwalten können.",
  "error.goToRequestLeaveTab": "Fehler beim Übergang zum Tab 'Urlaub beantragen'",
  "error.holidayDateInvalid": "Ungültiges Feiertags-Datum {date} für {year} ",
  "error.incorrectPassword": "Falsches Passwort",
  "error.incorrectPasswordMessage": "Das eingegebene Passwort ist falsch oder abgelaufen. Bitte versuchen Sie es erneut (stellen Sie sicher, dass die Feststelltaste ausgeschaltet ist). Passwort vergessen?",
  "error.invalidFileType": "Ungültiger Dateityp",
  "error.invalidFileType.description": "Sie haben einen ungültigen Dateityp hochgeladen. Bitte laden Sie ein JPEG-, PNG- oder GIF-Bild hoch.",
  "error.invalidPasswordException": "Ungültiges Passwort",
  "error.invalidPasswordExceptionMessage": "Bitte geben Sie ein gültiges Passwort mit mindestens 8 Zeichen ein.",
  "error.leavePolicyMissing": "Urlaubsrichtlinie fehlt",
  "error.leaveRequestNotEditable": "Sie dürfen diesen Urlaubsantrag nicht bearbeiten.",
  "error.leaveRequestOverlap": "Die von Ihnen gewählten Daten überschneiden sich mit einem bestehenden Urlaub. Bitte wählen Sie einen anderen Zeitraum.",
  "error.leaveRequestsLoadingError.description": "Ihre Urlaube existieren, aber wir konnten sie aufgrund eines technischen Problems unsererseits nicht laden. Bitte versuchen Sie, die Seite neu zu laden. Wenn das Problem weiterhin besteht, kontaktieren Sie unseren <link>Kundensupport</link>.",
  "error.leaveRequestsLoadingError.title": "Urlaube können nicht geladen werden",
  "error.leaveRequestStatusNotApproved": "Status des Urlaubsantrags: nicht genehmigt.",
  "error.leaveRequestStatusNotOpen": "Status des Urlaubsantrags: nicht offen",
  "error.leaveSubmitError": "Fehler beim Einreichen des Urlaubs",
  "error.leaveTypeMissing": "Urlaubsart fehlt",
  "error.LocationLimitReached": "Es tut uns leid, aber Sie können nicht auf den CORE-Plan herunterstufen, da Sie derzeit das Limit von {limitNumber} Standorten überschreiten. Bitte passen Sie Ihre Standorte an, um die Kriterien des CORE-Plans zu erfüllen, oder kontaktieren Sie den Support für weitere Hilfe.",
  "error.microsoft.channelNotFound": "Sie sind kein Mitglied des ausgewählten Microsoft Teams-Teams",
  "error.microsoft.logInWithDifferentAccount": "Mit anderem Konto anmelden",
  "error.microsoft.microsoftPermissionsNotGranted": "Microsoft-Berechtigungen nicht erteilt",
  "error.microsoft.notificationsLoginRequiredTitle": "Anmeldung bei Ihrem Microsoft-Konto erforderlich",
  "error.microsoft.notMemberOfTeam": "Ihre Organisation hat sich mit einem einzigen Microsoft Teams-Team angemeldet, und Sie sind kein Mitglied des Teams mit der folgenden ID: {msTeamId}.\n\nSie können diesem Team in Ihrer Microsoft Teams-Anwendung beitreten und es erneut versuchen, oder kontaktieren Sie unseren Support, um Ihren Installationstyp zu ändern.",
  "error.microsoft.wrongAccount": "Falsches Microsoft-Konto",
  "error.missingSlackBotToken": "Die Aktion wurde verarbeitet, aber es fehlt der Bot-Token, um den Benutzer zu benachrichtigen. Bitte melden Sie sich ab und wieder an, um den Bot-Token zu erhalten.",
  "error.missingSlackBotTokenError": "Wir können die Anfrage nicht verarbeiten, da der Bot-Token fehlt. Bitte melden Sie sich ab und wieder an, um den Bot-Token zu erhalten, und versuchen Sie es erneut.",
  "error.mustBeApproverForTeam": "Sie dürfen diese Aktion nicht durchführen, da Sie ein Genehmiger für diese Abteilung sein müssen.",
  "error.mustBeCreatorOrApprover": "Sie müssen der Ersteller oder Genehmiger sein, um den Status des Urlaubsantrags zu ändern.",
  "error.negativeBallanceNotAllowed": "Negativer Saldo ist für diese Urlaubsart nicht erlaubt.",
  "error.notificationGeneral": "Benachrichtigungsfehler",
  "error.oneHoureIntervalNotAllowed": "Das kürzeste Zeitintervall, das Sie für diese Urlaubsart beantragen können, beträgt 4 Stunden (halber Tag).",
  "error.openApi.isntCreated": "Open API-Schlüssel wurde nicht erstellt",
  "error.openApi.isntDeleted": "Open API-Schlüssel wurde nicht gelöscht",
  "error.partTimeIsntAllowed": "Teilzeit ist nicht erlaubt",
  "error.passwordResetLimitExceededException": "Limit überschritten",
  "error.passwordResetLimitExceededExceptionMessage": "Limit für Passwortänderungsversuche überschritten. Bitte versuchen Sie es in 15 Minuten erneut.",
  "error.paymentFailed": "Zahlung fehlgeschlagen",
  "error.paymentNotProcessed": "Zahlung fehlgeschlagen, bitte versuchen Sie es mit einer anderen Karte.",
  "error.probationPeriod.isntCreated": "Probezeit wurde nicht erstellt",
  "error.probationPeriod.isntDeleted": "Probezeit wurde nicht gelöscht",
  "error.probationPeriod.isntUpdated": "Probezeit wurde nicht aktualisiert",
  "error.PromoCodeDoesntExist": "Promo-Code {promoCode} existiert nicht",
  "error.PromoCodeIsntValid": "Der Promo-Code {promoCode} ist ungültig",
  "error.reasonRequired": "Das Begründungsfeld ist erforderlich.",
  "error.requestedMoreThanFullDay": "Sie haben mehr als einen vollen Tag Urlaub an einem Arbeitstag beantragt. Bitte wählen Sie einen anderen Tag.",
  "error.samePasswordException": "Passwort nicht geändert",
  "error.samePasswordExceptionMessage": "Das neue Passwort ist dasselbe wie das alte. Bitte geben Sie ein anderes Passwort ein, wenn Sie es ändern möchten.",
  "error.saveFailed": "Speichern fehlgeschlagen",
  "error.seniorityEntitlement.isntCreated": "Dienstaltersberechtigung wurde nicht erstellt",
  "error.seniorityEntitlement.isntDeleted": "Dienstaltersberechtigung wurde nicht gelöscht",
  "error.seniorityEntitlement.isntUpdated": "Dienstaltersberechtigung wurde nicht aktualisiert",
  "error.signin.inactiveUser": "Benutzer inaktiv",
  "error.signup.alreadySignedUp.descriptionLine1": "Sie sind bereits mit folgender E-Mail-Adresse registriert: <text>{ email }</text>. Bitte melden Sie sich an.",
  "error.signup.alreadySignedUp.descriptionLine2": "Falls Sie Ihr Passwort vergessen haben, klicken Sie bitte auf den Button unten, um es zurückzusetzen.",
  "error.signup.alreadySignedUp.title": "Sie sind bereits registriert",
  "error.signup.alreadySignedUpInactive.descriptionLine1": "Sie sind bereits mit folgender E-Mail-Adresse registriert: <text>{ email }</text>.",
  "error.signup.alreadySignedUpInactive.descriptionLine2": "Allerdings ist Ihr Konto inaktiv. Bitte kontaktieren Sie unseren Support für weitere Informationen und senden Sie ihnen folgende Angaben:",
  "error.slack.allowConversationWithSlackBotDescription": "Diese Funktion erfordert eine zusätzliche Slack-Berechtigung, die es dem Vacation Tracker-Bot für Slack ermöglicht, Direktnachrichten zu empfangen. Unser Bot wird in der Lage sein, die Nachrichten zu lesen, die Sie und andere Benutzer aus Ihrem Slack-Workspace an ihn senden, aber er wird keine Berechtigung haben, Ihre Kanäle, Gruppen oder Direktnachrichten zu lesen.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine1": "Der Bot von Vacation Tracker unterstützt einen Slash-Befehl und eine Verknürzung für Urlaubsanträge. Zusätzlich können Sie die menschliche Sprachkonversation im Web-Dashboard aktivieren (und sich für die LLM-Nutzung entscheiden).",
  "error.slack.allowConversationWithSlackBotLlmWarningLine2": "Das Einschalten dieser Funktion kann dazu führen, dass der Bot gelegentlich falsche Daten oder Urlaubsarten vorschlägt. Es ist wichtig, seine Empfehlungen zu überprüfen, bevor Sie einen Urlaubsantrag stellen.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Weder Vacation Tracker noch seine Drittanbieter verwenden Ihre Informationen zum Training von großen Sprachmodellen.",
  "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Haftungsausschluss für große Sprachmodelle (LLM)",
  "error.slack.allowConversationWithSlackBotTitle": "Erlaube Konversation mit dem Vacation Tracker Bot",
  "error.slack.logInWithDifferentAccount": "Mit anderem Konto anmelden",
  "error.slack.usersLoginRequiredTitle": "Anmeldung bei Ihrem Slack-Konto erforderlich",
  "error.slack.wrongAccount": "Falsches Slack-Konto",
  "error.somethingWentWrong": "Oh nein! 😕 Etwas ist schief gelaufen. ",
  "error.startDateCantBeInPast": "Das Startdatum kann nicht in der Vergangenheit liegen, bitte wählen Sie ein Datum in der Zukunft",
  "error.subscriptionExpired": "Ups, es scheint, dass das Abonnement Ihres Unternehmens für Vacation Tracker abgelaufen ist! Bitte kontaktieren Sie einen Administrator, um das Abonnement Ihres Unternehmens zu reaktivieren.",
  "error.subscriptionExpiredTitle": "Abonnement abgelaufen",
  "error.team.delete": "Abteilung kann nicht gelöscht werden",
  "error.TeamLimitReached": "Ihre aktuelle Anzahl an Abteilungen übersteigt das im CORE-Plan erlaubte Limit. Um herabzustufen, reduzieren Sie bitte Ihre Abteilungen auf {limitNumber} oder weniger, oder kontaktieren Sie unseren Support für Unterstützung.",
  "error.token.button": "Anmelden mit {platform}",
  "error.token.description": "Ihr {platform}-Token ist abgelaufen. Bitte klicken Sie auf den Button unten, um ein neues Token zu erhalten",
  "error.token.permissionDenied": "Im Google-Anmelde-Prompt haben Sie auf Fortfahren geklickt, ohne die erforderlichen Authentifizierungsberechtigungen zu erlauben. Bitte klicken Sie auf 'Alle auswählen' oder wählen Sie die entsprechenden Berechtigungen aus (Alle Kalender, auf die Sie über Google Kalender zugreifen können, ansehen, bearbeiten, freigeben und dauerhaft löschen und E-Mail-Einstellungen und Filter in Gmail ansehen, bearbeiten, erstellen oder ändern) und klicken Sie dann auf Fortfahren.",
  "error.userEndDateInFuture": "Die angeforderten Daten liegen nach dem Beschäftigungsende des Benutzers. Bitte kontaktieren Sie den Administrator Ihrer Organisation oder den Vacation Tracker-Support für weitere Informationen.",
  "error.UserNotAdminOrApprover": "Benutzer ist kein Administrator oder Genehmiger",
  "error.userNotFound": "Benutzer nicht gefunden",
  "error.UserNotFound": "Benutzer nicht gefunden",
  "error.UserNotFoundApprover": "Genehmiger nicht gefunden",
  "error.userSignup.invitationAccepted.description": "Einladungscode bereits akzeptiert, bitte melden Sie sich an",
  "error.userSignup.invitationAccepted.title": "Einladungscode bereits akzeptiert",
  "error.userSignup.invitationExpired.description": "Einladungscode abgelaufen, bitte bitten Sie Ihren Administrator, die Einladung erneut zu senden",
  "error.userSignup.invitationExpired.title": "Einladungscode abgelaufen",
  "error.userSignup.invitationNotFound.description": "Bitte überprüfen Sie, ob Ihr Einladungslink korrekt ist, oder kontaktieren Sie Ihren Administrator, um die Einladung erneut zu senden.",
  "error.userSignup.invitationNotFound.title": "Einladung nicht gefunden",
  "error.userStartDateInFuture": "Die angeforderten Daten liegen vor dem Beschäftigungsbeginn des Benutzers. Bitte kontaktieren Sie den Administrator Ihrer Organisation oder den Vacation Tracker Support für weitere Informationen.",
  "error.zeroDaysLeaveRequest": "Sie haben einen Feiertag oder einen arbeitsfreien Tag ausgewählt. Bitte wählen Sie einen gültigen Datumsbereich.",
  "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "Es scheint, dass Sie die Seite \"Mit Microsoft anmelden\" abgebrochen oder geschlossen haben, bevor Sie die erforderlichen Berechtigungen erteilt haben. Bitte klicken Sie auf den Button unten, um sich mit Microsoft anzumelden und die Einrichtung von Vacation Tracker abzuschließen.\n\nWenn Sie keine Berechtigung haben, sich mit Microsoft anzumelden, wenden Sie sich bitte an den Microsoft 365-Administrator Ihrer Organisation.",
  "errors.microsoft.subscriptionExpiredDescription": "Es scheint, dass Ihr Abonnement abgelaufen ist. Bitte setzen Sie sich mit dem Administrator Ihrer Vacation Tracker-Organisation in Verbindung, um weitere Details zu erhalten.\n\nKontaktieren Sie unseren Support, wenn Sie den Administrator ändern müssen oder andere Fragen haben.",
  "errors.microsoft.wrongMicrosoftAccountDescription": "Es scheint, dass Sie sich mit einem anderen Microsoft-Konto angemeldet haben. Sie haben Lizenzen mit der folgenden E-Mail-Adresse gekauft:\n\n{email}.\n\nBitte melden Sie sich mit dem Konto an, mit dem Sie die Lizenzen gekauft haben, um die Einrichtung abzuschließen.",
  "errors.slack.wrongSlackAccountDescription": "Es scheint, dass Sie sich mit einem anderen Slack-Konto angemeldet haben.",
  "events.logs.AUTOMATION": "Automatisierungen",
  "events.logs.BILLING": "Abrechnung",
  "events.logs.BULK_ACTIONS": "Benutzer importieren",
  "events.logs.COMPANY": "Unternehmen",
  "events.logs.LABEL": "Etikett",
  "events.logs.LEAVE_POLICY": "Urlaubsrichtlinie",
  "events.logs.LEAVE_TYPE": "Urlaubsart",
  "events.logs.LOCATION": "Standort",
  "events.logs.LOCATION_YEAR_ROLLOVER": "Standort Jahreswechsel",
  "events.logs.NOTIFICATION": "Benachrichtigungen",
  "events.logs.REPORT": "Bericht",
  "events.logs.TEAM": "Abteilung",
  "events.logs.TEAM_POLICY": "Teamrichtlinie",
  "export.approved": "Genehmigt",
  "export.currentYear": "Aktuelles Jahr",
  "export.denied": "Abgelehnt",
  "export.export": "Exportieren",
  "export.exportPeriod": "Exportzeitraum",
  "export.fileType": "Exportformat",
  "export.fileTypeCsv": "CSV",
  "export.fileTypeExcelXlsx": "Excel",
  "export.labels": "Etiketten",
  "export.lastMonth": "Letzter Monat",
  "export.leaveBalanceReport": "Urlaubssaldo-Bericht",
  "export.leaveBalanceReportCustomDaysInfo": "Bitte beachten Sie, dass der Bericht nur die verwendeten Tage oder Stunden anzeigt, wenn Sie einen benutzerdefinierten Zeitraum auswählen. Sie können das Gesamtguthaben oder das verfügbare Guthaben nur exportieren, wenn Sie eine der vordefinierten Optionen wählen.",
  "export.leaveBalanceReportDescription": "Die Anzahl der berechtigten und genommenen Urlaubstage pro Urlaubsart für jeden Mitarbeiter für einen bestimmten Zeitraum.",
  "export.leaveHistoryReport": "Urlaubsanfragen-Bericht",
  "export.leaveHistoryReportDescription": "Verlauf aller Urlaubsanfragen (genehmigt, abgelehnt, abgelaufen) pro Urlaubsart für jeden Mitarbeiter für einen bestimmten Zeitraum.",
  "export.locations": "Standorte",
  "export.monthlyLeaveBalanceReport": "Monatlicher Urlaubssaldo-Bericht",
  "export.monthlyLeaveBalanceReportDescription": "Die Anzahl der genommenen (oder geplanten) Urlaubstage pro Urlaubsart für jeden Mitarbeiter für den aktuellen und die folgenden 11 Monate.",
  "export.nextYear": "Nächstes Jahr",
  "export.previousYear": "Vorheriges Jahr",
  "export.rangeDate": "Start- und Enddatum",
  "export.reportType": "Berichtstyp",
  "export.required": "Dieses Feld ist erforderlich.",
  "export.status": "Status",
  "export.teams": "Abteilungen",
  "externalConnect.faildLogin": "Ihr einmaliger Token ist abgelaufen oder wurde bereits verwendet. Wir leiten Sie in wenigen Sekunden zur Zielseite weiter, aber Sie müssen sich möglicherweise erneut anmelden.\n\nWenn Sie es eilig haben, klicken Sie auf den Button unten.",
  "externalConnect.parseError": "Oh nein! 😕 Etwas ist schiefgelaufen.\n\nSie werden in 10 Sekunden zur Anmeldeseite weitergeleitet.",
  "externalConnect.pleaseWait": "Bitte warten Sie, Ihr PC ist kein Superheld!",
  "form.confirmPasswordInfo": "Bitte bestätigen Sie Ihr Passwort!",
  "form.department.generalDescriptionCreate": "Legen Sie den Namen Ihrer Abteilung fest und wählen Sie ihre Benutzer und Genehmiger aus. Wenn Sie die Abteilung vor dem Hinzufügen von Benutzern konfigurieren möchten, können Sie die Abteilung zuerst erstellen und später Benutzer hinzufügen, aber das Hinzufügen von mindestens einem Genehmiger ist erforderlich.",
  "form.department.generalDescriptionEdit": "Ändern Sie den Namen Ihrer Abteilung oder ihre Benutzer und Genehmiger.",
  "form.department.generalTitle": "Abteilungseinstellungen",
  "form.department.setDefaultDescription": "Standardabteilung",
  "form.department.setDefaultTitle": "Standardabteilung",
  "form.department.subdepartmentDescription": "Sie können Abteilungen verschachteln, um eine baumartige Organisationsstruktur zu erstellen. Wenn dies eine Unterabteilung ist, können Sie in diesem Abschnitt ihre übergeordnete Abteilung festlegen.",
  "form.department.subdepartmentTitle": "Unterabteilungseinstellungen",
  "form.general.companySettingsDescription": "Legen Sie den Namen Ihres Unternehmens und die primäre Kontakt-E-Mail-Adresse fest. Die von Ihnen angegebene E-Mail-Adresse wird nur für wichtige Produktkommunikation verwendet. Kein Spam, versprochen.",
  "form.general.companySettingsTitle": "Unternehmenseinstellungen",
  "form.general.configurationDescription": "Dieser Abschnitt ermöglicht es Ihnen, spezifische Aspekte der Urlaubsverfolgung für Ihr Unternehmen feinabzustimmen. Aktivieren Sie Benutzer-Enddaten, um Mitarbeiter mit einem Enddatum automatisch zu deaktivieren und zu verhindern, dass sie Urlaubsanträge über ihr Ausscheiden hinaus einreichen. Wählen Sie, ob Sie Urlaubskontigente in Tagen oder Stunden gemäß den Richtlinien Ihres Unternehmens verfolgen möchten. Definieren Sie zusätzlich das Startjahr für die Urlaubsverfolgung. Standardmäßig beginnen die Urlaubsberechnungen ab dem Jahr Ihrer Anmeldung, aber Sie können dies anpassen, um historische Daten aus anderen Systemen oder Tabellenkalkulationen einzubeziehen.",
  "form.general.configurationTitle": "Konfiguration",
  "form.general.migrationDescription": "Verwenden Sie Slack, Microsoft Teams oder Google Workspace? Sie können von der E-Mail- und passwortbasierten Authentifizierung zu einer plattformspezifischen Integration wechseln. Der Wechsel zu einer unserer Integrationen würde den Anmelde- und Benutzereinladungsprozess vereinfachen (Importieren von Benutzern aus Ihrem Benutzerverzeichnis) und Ihnen zusätzliche Integrationen wie Microsoft Teams-Tabs und Microsoft Teams- oder Slack-Bots bieten.",
  "form.general.migrationFeatureDescription": "Dies startet den Migrationsprozess. Sie werden zu einer Plattform weitergeleitet, wo Sie Ihr Konto verbinden können.",
  "form.general.migrationFeatureTitle": "Migration zu einer anderen Plattform starten",
  "form.general.migrationTitle": "Zu einer anderen Plattform wechseln",
  "form.inputRequired": "Dieses Feld ist erforderlich",
  "form.inputSize": "Das Feld muss {len} Ziffern haben",
  "form.passwordDoNotMatch": "Die beiden eingegebenen Passwörter stimmen nicht überein!",
  "form.userNameRequired": "Bitte geben Sie Ihren Namen ein",
  "form.validEmailRequired": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  "general.announceNewUsers": "Neue Benutzer ankündigen",
  "general.announceNewUsersInfo": "Ankündigungsnachricht über Vacation Tracker an neue Slack/Teams/Google Workspaces-Benutzer senden",
  "general.announceNewUsersInfoTooltip": "Wenn dieses Kästchen aktiviert ist, erhalten neue Benutzer, die Sie zu Ihrer Slack/Teams/GW-Organisation hinzufügen, eine kurze Nachricht von Vacation Tracker, die erklärt, wie es funktioniert. Wenn dieses Kästchen nicht aktiviert ist, erhalten neue Benutzer keine Ankündigungsnachricht von Vacation Tracker.",
  "general.announceNewUsersTooltip": "Wenn Sie einen neuen Benutzer zu Ihrer {platform} hinzufügen, erhalten sie eine kurze Nachricht von Vacation Tracker, die erklärt, wie es funktioniert. Wenn Sie die Option 'Nicht senden' wählen, erhalten neue Benutzer keine Ankündigungsnachricht von Vacation Tracker.",
  "general.calculationStartYear": "Vacation Tracker verfolgt Ihre Urlaubstage ab",
  "general.calculationStartYearTooltipLine1": "Sie müssen diese Einstellung nur ändern, wenn Sie historische Urlaubstage aus einem anderen System oder Tabellenkalkulationen importieren und diese Urlaubstage in die Berechnungen von Vacation Tracker einbeziehen möchten.",
  "general.calculationStartYearTooltipLine2": "Vacation Tracker berechnet Urlaubstage ab dem Jahr Ihrer Anmeldung. Das bedeutet, wir berechnen automatisch die verbleibenden und übertragenen Tage gemäß Ihren Einstellungen, und Sie können die Übertragungen aus Ihrem vorherigen System für jeden Benutzer auf deren Profilseiten festlegen. Wenn Sie jedoch möchten, dass Vacation Tracker Urlaubstage für vergangene Jahre berechnet, können Sie dieses Datum ändern. Zögern Sie nicht, <support>unseren Support zu kontaktieren</support> für weitere Informationen.",
  "general.clickToSelectPlatform": "Klicken Sie, um eine Plattform auszuwählen",
  "general.companyId": "Unternehmens-ID:",
  "general.companyName": "Firmenname",
  "general.confirmModalTitle": "Bestätigen Sie die Aktualisierungen der Unternehmenseinstellungen",
  "general.connectSync": "Synchronisierung verbinden",
  "general.connectToAnotherPlatform": "Zu einer anderen Plattform wechseln",
  "general.connectWith": "Wechseln zu {platform}",
  "general.connectWithHelpdeskInfo": "Unsicher, welche Plattform Sie wählen sollen? Dieser <link>Artikel</link> könnte helfen.",
  "general.days": "Tage",
  "general.deleteAccount": "Wenn Sie Ihre Daten ändern oder löschen möchten, wenden Sie sich bitte an unser Support-Team unter <mail>hello@vacationtracker.io</mail>",
  "general.enableUserEndDate": "Benutzer-Enddatum aktivieren",
  "general.enableUserEndDateInfo": "Wenn aktiviert, können Benutzer mit einem Enddatum keine Urlaubsanträge nach diesem Datum einreichen, und wir deaktivieren sie automatisch.",
  "general.enableUserEndDateTooltip": "Wenn das Benutzer-Enddatum aktiviert ist, können Benutzer keine Urlaubsanträge für den Zeitraum nach ihrem Enddatum einreichen. Sie werden automatisch deaktiviert und können sich nicht mehr beim Vacation Tracker anmelden.",
  "general.googleConsent": "Google-Anmeldung",
  "general.googleConsentInfo": "Google-Zustimmung",
  "general.googleMorePermission": "Google zeza sa imenima ako hocete da budete sigurni da imate puno ime i prezime treba nam jos permission-a, kliknite ovde da nam date jos permission-a",
  "general.hourlyLeaveAccounting": "Urlaubskontingente festlegen in",
  "general.hourlyLeaveAccountingTooltip": "Wenn aktiviert, verfolgt diese Option die Dauer aller Urlaube und Kontingente in Stunden. Nur im Complete-Plan verfügbar.",
  "general.hours": "Stunden",
  "general.importUsersAutomatically": "Benutzer automatisch importieren",
  "general.importUsersAutomaticallyInfo": "Wenn der automatische Import von Benutzern aktiviert ist, importieren und aktivieren wir automatisch alle neuen regulären Benutzer von {platform}.",
  "general.importUsersAutomaticallyInfoTooltip": "Wenn Sie beispielsweise einen neuen Benutzer zu Ihrem Arbeitsbereich einladen, wird dieser Benutzer wenige Minuten nach der Einladung im Vacation Tracker verfügbar sein. Wenn Sie einen Multichannel-Gastbenutzer aktivieren möchten, müssen Sie diesen manuell einladen.",
  "general.importUsersAutomaticallyTurnOffInfo": "Wir werden nicht länger automatisch alle neuen regulären Benutzer von {platform} importieren und aktivieren.",
  "general.importUsersConfigSection": "Konfiguration für Benutzerimport",
  "general.importUsersConfigSectionDescription": "Dieser Abschnitt ermöglicht es, den automatischen Benutzerimport aus Ihrem Benutzerverzeichnis zu aktivieren und die Einstellungen für den Benutzerimport zu konfigurieren, wie z.B. die Ankündigungsnachricht, die Standardabteilung und den Standardstandort für neue Benutzer.",
  "general.pleaseEnterCompanyName": "Bitte geben Sie den Firmennamen ein",
  "general.pleaseEnterContactEmail": "Bitte geben Sie die Firmen-E-Mail-Adresse ein",
  "general.syncGoogleUsers": "Google-Benutzer synchronisieren",
  "general.syncGoogleUsersInfo": "Sync google users automatsko brisanje, update i dodavanje usera",
  "general.updateInProgress": "Aktualisierung der Unternehmenseinstellungen",
  "general.updateSuccessTitle": "Unternehmenseinstellungen aktualisiert",
  "general.usefulLinks": "<healthcheck>Gesundheitscheck</healthcheck>, <helpdesk>Helpdesk</helpdesk>",
  "general.usefulLinksTitle": "Nützliche Links: ",
  "holidays.filterByType": "Nach Feiertagstyp filtern",
  "holidays.holidaysName": "Feiertagsname",
  "holidays.holidaysTab": "Feiertage für {year}",
  "holidays.holidaysType": "Feiertagstyp",
  "holidays.importHolidays": "Feiertage importieren",
  "holidays.importHolidaysAutomaticallyQuestion": "Möchten Sie ausgewählte Feiertage für zukünftige Jahre automatisch importieren?",
  "holidays.importHolidaysAutomaticallyTooltip": "Vacation Tracker wird die Tage für öffentliche Feiertage, die nicht jedes Jahr am gleichen Datum stattfinden, wie z.B. Thanksgiving, Ostern usw., automatisch anpassen.",
  "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Leider wird diese Funktion für dieses Land derzeit nicht unterstützt. Wir arbeiten daran, unsere Dienste zu erweitern. Bitte bleiben Sie für Updates auf dem Laufenden.",
  "holidays.importHolidaysAutomaticallyTurnedOff": "Automatischer Import von Feiertagen deaktiviert",
  "holidays.importHolidaysAutomaticallyTurnedOn": "Automatischer Import von Feiertagen aktiviert",
  "holidays.importHolidaysCount": "{count} ausgewählte Feiertage importieren",
  "holidays.importHolidaysFor": "Feiertage für <b>{year}</b> für <b>{countryState}</b> importieren",
  "holidays.importHolidaysSummary": "Sie haben folgende Feiertage für <b>{year}</b> für <b>{countryState}</b> ausgewählt",
  "holidays.pastHolidays": "Vergangene Feiertage",
  "holidays.totalSelectedHolidays": "Sie haben {total} Feiertag(e) ausgewählt",
  "holidays.totalSelectedHolidaysTip": "({total} aufgrund des angewendeten Filters nicht sichtbar)",
  "holidays.typeBank": "Bank",
  "holidays.typeObservance": "Gedenktag",
  "holidays.typeOptional": "Optional",
  "holidays.typePublic": "Öffentlich",
  "holidays.typeSchool": "Schule",
  "importLeaves.importFail": "Import fehlgeschlagen mit folgender Fehlermeldung:",
  "importLeaves.importSuccess": "Sie haben Urlaube zum Import eingereicht. Überprüfen Sie in ein paar Minuten, ob sie erfolgreich importiert wurden.",
  "importUsers.allUsersImported.description": "Es scheint, dass Sie bereits alle Benutzer aus Ihrem Verzeichnis importiert haben! Sie sollten alle Benutzer auf der Benutzerseite sehen. Wenn Sie jemanden nicht sehen, schauen Sie im Inaktiv-Tab nach. Zögern Sie nicht, unseren Support zu kontaktieren, wenn Sie Fragen haben oder Hilfe benötigen.",
  "importUsers.allUsersImported.goToUsersPageButton": "Zur Benutzerseite gehen",
  "importUsers.allUsersImported.title": "Alle Benutzer bereits importiert!",
  "importUsers.form.backButton": "Zurück zur Benutzerseite",
  "importUsers.form.searchGoogleUsersPlaceholder": "Benutzer in Ihrem Google Workspace suchen",
  "importUsers.form.searchMicrosoftUsersPlaceholder": "Geben Sie mindestens ein Zeichen ein, um Benutzer in Ihrem Microsoft 365 Active Directory zu suchen",
  "importUsers.form.searchSlackUsersPlaceholder": "Benutzer in Ihrem Slack Workspace suchen",
  "importUsers.form.searchUsersNotFound": "Benutzer nicht gefunden oder bereits importiert",
  "importUsers.form.searchUsersPlaceholder": "Benutzer in Ihrem Microsoft 365 Active Directory suchen",
  "importUsers.form.selectedUsersLabel": "Ausgewählte Benutzer ({selectedUsers})",
  "importUsers.form.showSelectedUsersAsNumber": "Ausgewählte Benutzer",
  "importUsers.statistics.imported": "In Vacation Tracker importierte Benutzer",
  "importUsers.statistics.total": "Gesamtanzahl der Benutzer in Ihrem {directoryName}",
  "leaveRequest.halfDay": "Halber Tag",
  "leaveRequest.hourly": "Stündlich",
  "leaveRequest.sent": "Urlaubsantrag gesendet",
  "leaveRequest.simpleQuota.addAnotherLeave": "Weiteren Urlaub hinzufügen",
  "leaveRequest.simpleQuota.info": "Hier ist Ihr Urlaubssaldo für {leaveTypeName} für {start} - {end}.",
  "leaveRequest.simpleQuota.requestAnotherLeave": "Einen weiteren Urlaub beantragen",
  "leaveRequest.simpleQuota.summaryInfo": "Wir haben den folgenden Antrag zur Genehmigung an Ihren Vorgesetzten gesendet",
  "leaveRequests.addedCurrentYear": "Für das laufende Jahr hinzugefügt",
  "leaveRequests.addeddNextYear": "Für das nächste Jahr hinzugefügt",
  "leaveRequests.cancelThisAction": "Diese Aktion abbrechen",
  "leaveRequests.dates": "Datum{plural}",
  "leaveRequests.daysRemainingForCurrentYear": "Verbleibende Tage für das laufende Jahr",
  "leaveRequests.daysRemainingForNextYear": "Verbleibende Tage für das nächste Jahr",
  "leaveRequests.daysRequestedForCurrentYear": "Beantragte Tage für das laufende Jahr",
  "leaveRequests.daysRequestedForNextYear": "Beantragte Tage für das nächste Jahr",
  "leaveRequests.denyWithReason": "Mit Begründung ablehnen",
  "leaveRequests.hoursRemainingForCurrentYear": "Verbleibende Stunden für das laufende Jahr",
  "leaveRequests.hoursRemainingForNextYear": "Verbleibende Stunden für das nächste Jahr",
  "leaveRequests.hoursRequestedForCurrentYear": "Beantragte Stunden für das laufende Jahr",
  "leaveRequests.hoursRequestedForNextYear": "Beantragte Stunden für das nächste Jahr",
  "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> hat den folgenden Urlaubsantrag eingereicht:",
  "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> hat den folgenden TOIL-Antrag eingereicht:",
  "leaveRequests.leaveTypeName": "Urlaubsart",
  "leaveRequests.pending": "Ausstehender Antrag",
  "leaveRequests.reason": "Grund",
  "leaveRequests.reasonForDenying": "Grund für die Ablehnung",
  "leaveRequests.remainingCurrentYear": "Verbleibend für das laufende Jahr",
  "leaveRequests.remainingNextYear": "Verbleibend für das nächste Jahr",
  "leaveRequests.requestedCurrentYear": "Beantragt für das laufende Jahr",
  "leaveRequests.requestedNextYear": "Beantragt für das nächste Jahr",
  "leaveRequests.requestSummary": "Antragsübersicht",
  "leaveRequests.wrongAction": "Diese Aktion ist uns unbekannt...🤔 Bitte versuchen Sie eine andere! Sie werden in 7 Sekunden zum Dashboard weitergeleitet.",
  "leaveRequests.wrongLeaveRequestId": "Ups! Dieser Urlaubsantrag ist nicht auffindbar! 🔎 Sie werden in 7 Sekunden zum Dashboard weitergeleitet.",
  "leaveRequestStatus.approved": "genehmigt",
  "leaveRequestStatus.APPROVED": "genehmigt",
  "leaveRequestStatus.cancelled": "storniert",
  "leaveRequestStatus.CANCELLED": "storniert",
  "leaveRequestStatus.deleted": "gelöscht",
  "leaveRequestStatus.DELETED": "gelöscht",
  "leaveRequestStatus.denied": "abgelehnt",
  "leaveRequestStatus.DENIED": "abgelehnt",
  "leaveRequestStatus.open": "offen",
  "leaveRequestStatus.OPEN": "offen",
  "leaveTypes.activatingLeaveType": "Urlaubsart aktivieren",
  "leaveTypes.activatingLeaveTypeInfo": "Durch die Aktivierung dieser Urlaubsart stellen Sie deren vorherige Platzierung in den Standorten sowie ihre früheren Einstellungen und Daten wieder her.",
  "leaveTypes.activatingLeaveTypeTitle": "Urlaubsart aktivieren",
  "leaveTypes.addLeaveTypeInfo": "Erstellen Sie eine benutzerdefinierte Urlaubsart, indem Sie einen Namen, eine Farbe für einfachere Navigation und einen Status (Aktiv/Inaktiv) hinzufügen. Keine Sorge, Sie können alle Einstellungen der Urlaubsart später bearbeiten. \n\nUm einer Urlaubsart eine benutzerdefinierte Urlaubsrichtlinie zuzuweisen, gehen Sie zu Standorte -> Urlaubsrichtlinie zuweisen.",
  "leaveTypes.addTypeToLocationButton": "Hinzufügen",
  "leaveTypes.assignedToLocationsColumn": "Zugewiesen zu Standorten",
  "leaveTypes.assignedToLocationsColumnWarning": "Dieser Urlaubstyp ist keinem Standort zugewiesen.",
  "leaveTypes.color": "Farbe",
  "leaveTypes.createInProgress": "Erstellung des Urlaubstyps {leaveTypeName}",
  "leaveTypes.createNewLeaveType": "Einen neuen Urlaubstyp erstellen",
  "leaveTypes.deactivateLeaveType": "Urlaubstyp deaktivieren",
  "leaveTypes.deactivateLeaveTypeInfo": "Durch die Deaktivierung dieses Urlaubstyps entfernen Sie ihn von allen Standorten, die ihn derzeit verwenden. Wenn Sie den Urlaubstyp erneut aktivieren, erscheint er an denselben Standorten mit den vorhandenen Einstellungen und Daten.",
  "leaveTypes.deactivateLeaveTypeTitle": "Urlaubstyp deaktivieren",
  "leaveTypes.deleteInProgress": "Löschen des Urlaubstyps {leaveTypeName}",
  "leaveTypes.deleteLeaveType": "Urlaubstyp löschen",
  "leaveTypes.deleteLeaveTypeConfirm": "Sind Sie sicher, dass Sie den Urlaubstyp {name} löschen möchten?",
  "leaveTypes.deleteLeaveTypeTitle": "Urlaubstyp löschen",
  "leaveTypes.editLeaveType": "Urlaubstyp bearbeiten",
  "leaveTypes.isActive": "Aktiv",
  "leaveTypes.sort": "Sortieren",
  "leaveTypes.typeName": "Name",
  "leaveTypes.updateInProgress": "Aktualisierung des Urlaubstyps {leaveTypeName}",
  "location.createInProgress": "Erstellung des Standorts {locationName}",
  "location.deleteInProgress": "Löschen des Standorts {locationName}",
  "location.editLocation": "Standort bearbeiten",
  "location.fiscalYearStart": "Beginn des Geschäftsjahres",
  "location.general": "Allgemein",
  "location.hasNoUsers": "Der Standort hat keine Benutzer.",
  "location.holidays": "Feiertage",
  "location.holidays.autoimportTitle": "Einstellungen für den automatischen Import von Feiertagen geändert",
  "location.holidays.brokenHolidays": "Dieser Feiertag kann nicht hinzugefügt werden. Bitte kontaktieren Sie den Support, um das Problem zu lösen",
  "location.holidays.successTitle": "Feiertage wurden erfolgreich aktualisiert",
  "location.holidays.updateInProgress": "Aktualisierung der Feiertage",
  "location.holidaysUpdated": "Feiertage aktualisiert",
  "location.leavePolicies": "Urlaubsrichtlinien",
  "location.leavePoliciesNotExistInfo": "Es sind derzeit keine Urlaubsrichtlinien verfügbar. Bitte weisen Sie dem Standort eine Urlaubsrichtlinie zu.",
  "location.name": "Name",
  "location.never": "Nie",
  "location.resetQuotas": "Zurücksetzen des Urlaubskontingents basierend auf",
  "location.resetQuotasInfo": "Diese Einstellung bestimmt, ob Ihr jährliches Urlaubsguthaben basierend auf dem Geschäftsjahr (Geschäftsjahr des Unternehmens) oder basierend auf dem Eintrittsdatum des Mitarbeiters zurückgesetzt wird. Neben den Kontingenten wird auch Ihre Übertragungsrichtlinie entsprechend dieser Einstellung beeinflusst.",
  "location.rolloverExpiryAfterDays": "Verfall der übertragenen Tage",
  "location.rolloverExpiryAfterHours": "Verfall der übertragenen Stunden",
  "location.rolloverExpiryDay": "Sollen übertragene Tage verfallen?",
  "location.rolloverExpiryHour": "Sollen übertragene Stunden verfallen?",
  "location.rolloverExpiryOnDate": "Übertragene Tage verfallen am",
  "location.rolloverExpiryOnDate.extra": "Tage verfallen am Ende des Tages.",
  "location.rolloverExpiryOnDateHours": "Übertragene Stunden verfallen am",
  "location.rolloverExpiryOnDateHours.extra": "Stunden verfallen am Ende des Tages.",
  "location.startYear": "Beginn des Geschäftsjahres",
  "location.startYearInfo": "Das Geschäftsjahr einer Organisation ist ein Jahr, das für Steuer- oder Buchhaltungszwecke berechnet wird. Die Rücksetztermine für Urlaubszeiträume hängen vom Beginn und Ende des Geschäftsjahres der Organisation ab.",
  "location.timezone": "Zeitzone",
  "location.timezoneDefault": "(GMT+00:00) GMT (keine Sommerzeit)",
  "location.updateInProgress": "Aktualisiere den Standort {locationName}",
  "location.users": "Benutzer",
  "location.workWeek": "Arbeitswoche",
  "locationForm.assignLeaveTypesInfo": "Nachdem Sie einen Standort erstellt haben, weisen Sie dem Standort eine Urlaubsrichtlinie zu, damit Benutzer Urlaub beantragen können. \nUm eine Urlaubsrichtlinie zuzuweisen, gehen Sie zu Standort -> Urlaubsrichtlinien -> Urlaubsrichtlinie zuweisen.",
  "locationForm.createdSuccessfully": "Bitte geben Sie uns einen Moment, wir erstellen Ihren Standort.",
  "locationForm.createLocation": "Standort erstellen",
  "locationForm.editLocation": "Standort bearbeiten",
  "locationForm.locationLimitReached": "Hoppla! Es sieht so aus, als hätten Sie das Limit von {limitNumber} Standorten für Ihren aktuellen Plan erreicht. Um Ihren Plan zu verwalten oder zu aktualisieren, besuchen Sie bitte die <billingPage>Abrechnungsseite</billingPage>.",
  "locationForm.updateLocation": "Standort aktualisieren",
  "locations.automaticallyGeneratedName": "Wir haben diesen Standort automatisch für Sie generiert, indem wir die folgenden Teams gruppiert haben: <t>{team}</t>.",
  "locations.automaticallyTransferredLeavePoliciesAndHolidays": "Urlaubsrichtlinien und Feiertage werden automatisch für Sie übertragen, sodass Sie sie <b>{not}</b> neu erstellen müssen.",
  "locations.created": "Erstellt",
  "locations.createNewLocation": "Neuen Standort erstellen",
  "locations.default": "Standard",
  "locations.defaultLocation": "Standardstandort",
  "locations.defaultLocationDescription": "Alle neuen Benutzer werden automatisch diesem Standort hinzugefügt.",
  "locations.deleteLocation": "Standort löschen",
  "locations.deleteLocation.disabled": "Sie können den Standardstandort nicht löschen",
  "locations.deleteLocationConfirm": "Bitte bestätigen Sie, dass Sie den Standort {name} löschen möchten.",
  "locations.deleteLocationConfirmInitialRollover": "Das Löschen eines Standorts könnte sich auf die Konfiguration und Urlaubsinformationen der Benutzer auswirken (z.B. die anfänglichen übertragenen Tage).",
  "locations.deleteLocationTitle": "Standort {name} löschen",
  "locations.editLocation": "Bearbeiten",
  "locations.hasNoUsers": "Keine",
  "locations.holidays": "Feiertage",
  "locations.leavePolicies": "Urlaubsrichtlinien",
  "locations.leavePolicies.quotaDays": "{value} Tage",
  "locations.leavePolicies.quotaHours": "{value} Stunden",
  "locations.leavePolicies.yearlyQuota": "Jährliches Urlaubskontingent",
  "locations.locationLimitReached": "Sie nutzen derzeit alle {limitNumber} Ihrer im CORE-Plan verfügbaren Standortplätze. Um Ihren Plan zu verwalten oder zu aktualisieren, besuchen Sie bitte die <billingPage>Abrechnungsseite</billingPage>.",
  "locations.name": "Name",
  "locations.pleaseRenameLocation": "Bitte benennen Sie diesen Standort um!",
  "locations.pleaseRenameLocationName": "Bitte benennen Sie automatisch generierte Standortnamen um *",
  "locations.resetQuotas": "Urlaubskontingent-Rücksetzung basierend auf",
  "locations.selectLocation": "Standort auswählen",
  "locations.unableToNameThisLocation": "Unser System konnte diesen Standort jedoch nicht richtig benennen.",
  "locations.users": "Benutzer",
  "locations.viewHolidays": "Feiertage anzeigen",
  "locations.viewLeavePolicies": "Urlaubsrichtlinien anzeigen",
  "locations.viewLocation": "Anzeigen",
  "locations.weAutomaticallyCreatedLocations": "Wir haben automatisch Standorte für Ihre Organisation erstellt, indem wir Ihre Teams nach Feiertagen gruppiert haben. Alle Urlaubsrichtlinien wurden übertragen, Sie müssen nur Ihre Standorte umbenennen.",
  "manageMicrosoftLicenses.assignLicencesSuccessTitle": "Die Lizenzen wurden erfolgreich zugewiesen.",
  "manageMicrosoftLicenses.weAreAssignLicenses": "Wir weisen die Lizenzen zu.",
  "microsoft.adminConsentErrorButton": "Zugriff erhalten",
  "microsoft.adminConsentErrorDescription1": "Es scheint, dass Sie kein Administrator Ihrer Microsoft Teams-Organisation sind. Das Einrichten von Benachrichtigungen erfordert die Zustimmung des Administrators. Bitte senden Sie den folgenden Link an den Administrator Ihrer Organisation, um die Administratorzustimmung zu erhalten: ",
  "microsoft.adminConsentErrorDescription2": "Wenn Sie die Administratorzustimmung erhalten haben, klicken Sie auf den Button unten, um Zugriff auf Benachrichtigungen zu erhalten. Wenn Sie Administrator sind, besuchen Sie denselben Link, erteilen Sie die Administratorzustimmung, und Sie können die Benachrichtigungen einrichten.",
  "microsoft.adminConsentErrorTitle": "Microsoft Teams Administrator-Zustimmung erforderlich.",
  "microsoft.createCompanyTeamLoginAdminConsentError1": "Es scheint, dass Sie kein Administrator Ihrer Microsoft Teams-Organisation sind. Das Importieren von Teammitgliedern aus einem einzelnen Microsoft Teams-Team erfordert die Zustimmung des Administrators. Bitte senden Sie den folgenden Link an den Administrator Ihrer Organisation, um die Administratorzustimmung zu erhalten: ",
  "microsoft.createCompanyTeamLoginAdminConsentError2": "Wenn Sie die Administratorzustimmung erhalten haben, können Sie mit dem Anmeldeprozess fortfahren. Wenn Sie Administrator sind, besuchen Sie bitte denselben Link, erteilen Sie die Administratorzustimmung, und Sie können mit der Anmeldung fortfahren.",
  "microsoft.createCompanyTeamLoginRequiredDescription1": "Wir benötigen zusätzliche Berechtigungen von Microsoft Teams, um Benutzer aus dem von Ihnen ausgewählten Team aufzulisten. Wenn Sie auf den OK-Button klicken, leiten wir Sie zur Microsoft-Anmeldeseite weiter. Je nach Ihren Browsereinstellungen sind Sie möglicherweise bereits angemeldet und werden automatisch zurückgeleitet.",
  "microsoft.createCompanyTeamLoginRequiredDescription2": "Nach der Anmeldung bei Ihrem Microsoft-Konto bringen wir Sie zurück auf diese Seite, damit Sie Benutzer zum Importieren auswählen können. Wenn Sie auf den Abbrechen-Button klicken, können Sie sich trotzdem mit Ihrer gesamten Organisation anmelden.",
  "microsoft.createCompanyTeamLoginRequiredTitle": "Zusätzliche Microsoft Teams-Berechtigung erforderlich.",
  "microsoft.manageLicenses.loginMicrosoft": "Melden Sie sich mit Ihrem Microsoft 365-Konto an, um Lizenzen zuzuweisen.",
  "microsoft.manageLicenses.loginMicrosoftInfo": "Durch die Anmeldung mit Ihrem Konto werden Benutzer in Ihrem Microsoft Active Directory aufgelistet. Sie können gekaufte Lizenzen zuweisen und optional importierte Benutzer benachrichtigen.",
  "microsoft.notificationsLoginRequiredDescription1": "Die Verwaltung von Benachrichtigungen erfordert ein Microsoft Teams-Token. Wenn Sie auf den OK-Button klicken, leiten wir Sie zur Microsoft-Anmeldeseite weiter. Je nach Ihren Browsereinstellungen sind Sie möglicherweise bereits angemeldet und werden automatisch zurückgeleitet.",
  "microsoft.notificationsLoginRequiredDescription2": "Nach der Anmeldung bei Ihrem Microsoft-Konto bringen wir Sie zurück auf diese Seite, damit Sie die Benachrichtigungen verwalten können. Wenn Sie auf den Abbrechen-Button klicken, kehren Sie zur zuletzt besuchten Seite in unserem Dashboard zurück.",
  "microsoft.notificationsLoginRequiredTitle": "Anmeldung bei Ihrem Microsoft-Konto erforderlich",
  "microsoft.universalLoginRequiredDescription": "Es scheint, dass Ihr Token abgelaufen ist. Bitte melden Sie sich bei Ihrem Microsoft Teams-Konto an, um fortzufahren.",
  "microsoft.universalLoginRequiredTitle": "Token abgelaufen",
  "microsoft.usersLoginRequiredDescription1": "Die Verwaltung von Benutzern erfordert ein Microsoft Teams-Token. Wenn Sie auf den OK-Button klicken, leiten wir Sie zur Microsoft-Anmeldeseite weiter. Je nach Ihren Browsereinstellungen sind Sie möglicherweise bereits angemeldet und werden automatisch zurückgeleitet.",
  "microsoft.usersLoginRequiredDescription2": "Nach der Anmeldung bei Ihrem Microsoft-Konto bringen wir Sie zurück auf diese Seite, damit Sie die Benutzer verwalten können.",
  "microsoft.usersLoginRequiredTitle": "Anmeldung bei Ihrem Microsoft-Konto erforderlich",
  "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "Ihr Unternehmen ist bereits angemeldet, aber Sie sind nicht aktiv. Verwenden Sie die richtige E-Mail-Adresse?\nIhre E-Mail-Adresse: {email}\nWenn diese E-Mail-Adresse korrekt ist, ist Ihr Konto nicht aktiviert. Bitte kontaktieren Sie den Administrator Ihrer Vacation Tracker-Organisation:\n{admins}\nOder kontaktieren Sie unseren Support, wenn Sie sich als separates unabhängiges Team im selben Mandanten anmelden möchten.\nWenn Ihre E-Mail-Adresse nicht die richtige ist, melden Sie sich bitte mit einem anderen Microsoft-Konto an oder melden Sie sich über ein Inkognito-Fenster in Ihrem Browser an.",
  "microsoftSass.createCompany.alertError.errorTitle": "Fehler",
  "microsoftSass.createCompany.alertError.goToAdminCenter": "Zum Admin Center gehen",
  "microsoftSass.createCompany.alertError.goToConnectPage": "Zur Verbindungsseite gehen",
  "microsoftSass.createCompany.alertError.logInWithPlatform": "Anmelden mit {platform}",
  "microsoftSass.createCompany.alertError.resubscribedDescription": "Sie wurden erfolgreich als Unternehmen - {companyName} - wieder angemeldet.\n\nBitte klicken Sie auf den Button, um zur Anmeldeseite weitergeleitet zu werden.",
  "microsoftSass.createCompany.alertError.resubscribedTitle": "Sie haben sich erfolgreich wieder angemeldet",
  "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Ihr Kauftoken ist abgelaufen. Bitte gehen Sie zum Microsoft Teams Admin Center und klicken Sie auf den Button \"Konto einrichten\", um fortzufahren.",
  "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Kauftoken abgelaufen",
  "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Ihr Microsoft-Kauftoken ist ungültig. Wenn Sie den Vacation Tracker-Plan über den Microsoft Teams App Store gekauft haben, gehen Sie bitte zum Microsoft Teams Admin Center und klicken Sie auf den Button \"Konto einrichten\", um fortzufahren. Andernfalls gehen Sie zu unserer Anmeldeseite, um sich anzumelden oder ein Konto zu erstellen.",
  "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Kauftoken ungültig",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "Sie haben bereits ein Konto. Ihr Unternehmen ({companyName}) hat sich mit {platform} angemeldet. Klicken Sie auf den Button unten, um sich mit Ihrem {platform}-Konto anzumelden.\n\nKeine Sorge. Wir haben Ihren Kauf storniert und Ihnen werden die gerade gekauften Lizenzen nicht in Rechnung gestellt. Das alte Abonnement für Ihr Unternehmen ist noch aktiv. Sie können das Microsoft Admin Center besuchen, um zu überprüfen, ob Ihr neues Abonnement storniert wurde.\n\nWenn Sie absichtlich ein weiteres Abonnement kaufen möchten, kontaktieren Sie bitte unseren Support.",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "Sie haben bereits ein Konto",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "GEKAUFTE LIZENZEN ZUWEISEN",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "Lassen Sie uns die von Ihnen gekauften Lizenzen zuweisen! Sie haben {totalLicenses} Lizenzen. Weisen Sie sie Benutzern zu, die Vacation Tracker verwenden können.",
  "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "Sie können später auf der Benutzerseite des Vacation Tracker-Dashboards weitere Lizenzen erwerben.",
  "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "Keine Sorge! Wir werden niemanden ohne Ihre Zustimmung importieren oder benachrichtigen.",
  "microsoftSass.createCompany.completed": "Abgeschlossen",
  "microsoftSass.createCompany.createCompanySetupInfo": "Lassen Sie uns die Kontakt-E-Mail und die Standardeinstellungen für Ihre Vacation Tracker-Organisation festlegen.",
  "microsoftSass.createCompany.createCompanyTitle": "UNTERNEHMENSEINRICHTUNG ABSCHLIESSEN",
  "microsoftSass.createCompany.finishFristStep": "Bitte schließen Sie die Unternehmenseinrichtung ab, um fortzufahren.",
  "microsoftSass.createCompany.finishSecondStep": "Bitte weisen Sie Lizenzen zu, um fortzufahren.",
  "microsoftSass.createCompany.finishSetup": "Einrichtung abschließen",
  "microsoftSass.createCompany.help": "Wenn Sie Hilfe benötigen, besuchen Sie unser <helpdesk>Helpdesk</helpdesk> oder <support>kontaktieren Sie unseren Support</support>.",
  "microsoftSass.createCompany.importAllUsers": "Allen Benutzern zugewiesen",
  "microsoftSass.createCompany.letsCompleteSetup": "Lassen Sie uns die Einrichtung abschließen, Lizenzen Ihren Benutzern zuweisen und die Vacation Tracker-Integration für Microsoft Teams installieren.",
  "microsoftSass.createCompany.numberOfLicensesAssigned": "{assignedLicenses} Lizenzen zugewiesen",
  "microsoftSass.createCompany.setupMsBot.areYouAdmin": "Sind Sie der Administrator Ihrer Microsoft Teams-Integration",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "Es scheint, dass Sie die zusätzlichen zwei Microsoft Teams-Berechtigungen, die wir benötigen, um den Bot und die Kanal-Tabs einzurichten, nicht erteilt haben. Ohne diesen Schritt können wir keine Willkommensnachricht an Ihre ausgewählten Teammitglieder senden, und Sie werden die Vacation Tracker-App nicht von Ihrer Microsoft Teams-Anwendung aus verwenden können.",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "Sie können diesen Schritt überspringen und den Bot und die Tabs manuell installieren. Siehe unseren Helpdesk für die Schritt-für-Schritt-Anleitung.",
  "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Bot und Tabs installieren",
  "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "Ich werde Bot und Tabs manuell installieren",
  "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "Wir benötigen zusätzliche Berechtigungen, um den Vacation Tracker-Chatbot und die Tabs in Ihrem Microsoft Teams zu installieren. Wir leiten Sie zum Microsoft-Berechtigungsbildschirm weiter, wenn Sie unten auf die Schaltfläche 'Fortfahren' klicken.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Bitte installieren Sie den Vacation Tracker Microsoft Teams-Bot und die Tabs manuell, indem Sie den Schritten folgen, die in <helpdesk>diesem Helpdesk-Artikel</helpdesk> beschrieben sind. Sie sollten keine Administratorrechte für die manuelle Einrichtung benötigen. Sie sollten keine Administratorhilfe für die manuelle Einrichtung benötigen. Wenn Sie Hilfe benötigen, kontaktieren Sie <support>unseren Support</support>.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "Sie müssen die Microsoft-Integration manuell installieren",
  "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "Wir benötigen die Zustimmung des Administrators, um den Bot und die Tabs einzurichten. Wir können keine Willkommensnachrichten senden, bis Sie den Bot installiert haben.",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Wählen Sie den Microsoft Teams-Kanal aus, in dem Sie Tabs installieren möchten",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Microsoft Teams-Kanal auswählen",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Wählen Sie das Microsoft Teams-Team aus, in dem Sie den Bot installieren möchten",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Microsoft Teams-Team auswählen",
  "microsoftSass.createCompany.setupMsBotAndTabs": "VACATION TRACKER BOT UND TABS EINRICHTEN",
  "microsoftSass.createCompany.setupMsBotAndTabsInfo": "Lassen Sie uns den Vacation Tracker-Bot und die Tabs für Microsoft Teams installieren!",
  "microsoftSass.createCompany.stepAssignLicenses": "2. Lizenzen zuweisen",
  "microsoftSass.createCompany.stepCreateComapny": "1. Unternehmenseinrichtung abschließen",
  "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Microsoft Teams-Bot und -Tabs einrichten",
  "microsoftSass.createCompany.welcomeMessage": "Willkommen bei Vacation Tracker! Vielen Dank für den Kauf von <bold>{totalLicenses} Lizenzen</bold>.",
  "microsoftSass.createCompany.welcomeTitle": "LASSEN SIE UNS VACATION TRACKER EINRICHTEN",
  "microsoftSubscription.adminInitialContent": "Ihr Abonnement ist abgelaufen. Bitte gehen Sie zum Microsoft AppSource Store, um das Abonnement zu reaktivieren. Wenn Sie Fragen haben oder Hilfe benötigen, kontaktieren Sie unseren Support.",
  "microsoftSubscription.goToAppSource": "Neues Abonnement kaufen",
  "modal.dontHaveAnAccount.accountNotFound": "Konto nicht gefunden",
  "modal.dontHaveAnAccount.asCompany": "Als Unternehmen",
  "modal.dontHaveAnAccount.asCompanyDescription": "Sie sind ein Administrator oder Geschäftsinhaber, der Vacation Tracker ausprobieren möchte.",
  "modal.dontHaveAnAccount.asEmployee": "Als Mitarbeiter",
  "modal.dontHaveAnAccount.asEmployeeDescription": "Ihr Unternehmen verwendet Vacation Tracker, aber Sie haben keinen Zugang dazu.",
  "modal.dontHaveAnAccount.employeeSignUp": "Mitarbeiter-Registrierung",
  "modal.dontHaveAnAccount.employeeSignupDescription": "Wenn Ihr Unternehmen Vacation Tracker bereits verwendet, müssen Sie sich nicht registrieren! Kontaktieren Sie Ihren Administrator, um aktiviert zu werden, und Sie erhalten vollen Zugriff auf das Vacation Tracker-Dashboard und die Integrationen.",
  "modal.dontHaveAnAccount.employeeSignupSupport": "Wenn Sie Hilfe benötigen oder sich nicht sicher sind, wer Ihr Administrator ist, können Sie sich gerne an unseren Support wenden.",
  "modal.dontHaveAnAccount.introParagraph": "Es scheint, dass Sie kein Konto haben. Wie möchten Sie Vacation Tracker nutzen?",
  "modal.forgotPassword.step2Intro": "Wir haben einen Code an Ihre E-Mail-Adresse gesendet. Bitte geben Sie ihn unten ein und setzen Sie ein neues Passwort. Wenn Sie innerhalb von 5 Minuten keine E-Mail erhalten haben, überprüfen Sie Ihren Spam-Ordner und ob Sie die richtige E-Mail-Adresse eingegeben haben.",
  "myProfile.cancel": "Abbrechen",
  "myProfile.cancelLeave": "Stornieren",
  "myProfile.cancelLeaveConfirmTitle": "Urlaub stornieren",
  "myProfile.dontHaveAnyHistory": "{name} hat noch keinen Urlaub genommen.",
  "myProfile.edit.title": "Mein Profil bearbeiten",
  "myProfile.editLeave": "Urlaub bearbeiten",
  "myProfile.editLeaveWord": "Bearbeiten",
  "myProfile.general": "Allgemein",
  "myProfile.history": "Verlauf",
  "myProfile.historyLeaves": "Verlauf",
  "myProfile.leaveHistory": "Urlaubsverlauf",
  "myProfile.leaveTypes": "Urlaubsarten",
  "myProfile.noUpcomingLeaves": "Keine geplanten Urlaube",
  "myProfile.openMyProfile": "Mein Profil anzeigen",
  "myProfile.upcomingLeaves": "Geplante Urlaube",
  "myProfile.userWorkWeek": "Arbeitswoche",
  "not_in_channel": "Der ausgewählte Kanal wurde gelöscht oder ist nicht mehr verfügbar. Bitte wählen Sie einen anderen Kanal aus, um Benachrichtigungen zu erhalten.",
  "notification.codeResent": "Verifizierungscode erneut gesendet",
  "notification.codeResentMessage": "Bitte überprüfen Sie Ihre E-Mails",
  "notification.passwordReset": "Passwort erfolgreich zurückgesetzt",
  "notification.passwordResetMessage": "Sie können sich jetzt mit Ihrem neuen Passwort anmelden.",
  "notifications.add": "Neue Benachrichtigung",
  "notifications.cantOpenNoptification": "Diese Benachrichtigung wurde von einem anderen Benutzer erstellt und Sie können sie nicht bearbeiten.",
  "notifications.createDailyRequestSubmitted": "Tägliche Benachrichtigungen werden eingerichtet",
  "notifications.createWeeklyRequestSubmitted": "Wöchentliche Benachrichtigungen werden eingerichtet",
  "notifications.deleteAutomationConfirm": "Bitte bestätigen Sie, dass Sie die Automatisierung <strong>{name}</strong> löschen möchten.",
  "notifications.deleteAutomationTitle": "Automatisierung löschen",
  "notifications.deleteDailyRequestSubmitted": "Tägliche Benachrichtigungen werden gelöscht",
  "notifications.deleteEntitlementByRoleAutomationConfirm": "Sind Sie sicher, dass Sie diese Anspruchsautomatisierung nach Rolle löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden. Das Löschen der Automatisierung wird jedoch keine Auswirkungen auf bereits zugewiesene {leaveTypeName}-Tage haben.",
  "notifications.deleteNotificationConfirm": "Bitte bestätigen Sie, dass Sie die Benachrichtigung <strong>{name}</strong> löschen möchten.",
  "notifications.deleteNotificationOk": "Löschen",
  "notifications.deleteNotificationTitle": "Benachrichtigung löschen",
  "notifications.deleteSeniorityEntitlementAutomationConfirm": "Sind Sie sicher, dass Sie diese Dienstalter-Anspruchsautomatisierung löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden. Das Löschen der Automatisierung wird jedoch keine Auswirkungen auf bereits zugewiesene {leaveTypeName}-Tage haben.",
  "notifications.deleteWeeklyRequestSubmitted": "Wöchentliche Benachrichtigungen werden gelöscht",
  "notifications.emptyViewButton": "Tägliche oder wöchentliche Benachrichtigung erstellen",
  "notifications.emptyViewMessage": "durch das Senden automatisierter Benachrichtigungen über bevorstehende Abwesenheiten",
  "notifications.emptyViewTitle": "Erhöhen Sie die Sichtbarkeit und halten Sie Ihr Team informiert",
  "notifications.emptyViewVideoLink": "Für weitere Informationen besuchen Sie unser <link>Helpdesk</link> oder sehen Sie sich das Video unten an:",
  "notifications.error": "Wenn dieses Problem weiterhin besteht, wenden Sie sich bitte an den Support und geben Sie ihnen den folgenden Fehlercode: {correlationId}.",
  "notifications.frequency": "Häufigkeit",
  "notifications.handleSubmitErrorTitle": "Benachrichtigungsfehler",
  "notifications.microsoftTokenMissing": "Wir können keine Benachrichtigungen einrichten, da Ihnen das Microsoft-Token fehlt. Bitte melden Sie sich ab und wieder an, um das Microsoft-Token zu erhalten, und versuchen Sie es erneut.",
  "notifications.notificationInfo": "Wenn Sie Benachrichtigungen für eine bestimmte Abteilung einrichten möchten, gehen Sie bitte zur Seite Abteilungen und bearbeiten Sie die Abteilung, für die Sie die Benachrichtigungen einrichten möchten.",
  "notifications.notificationSendNow": "Ihre Benachrichtigung ist unterwegs! 🚀",
  "notifications.notificationSendNowFailed": "Zustellung fehlgeschlagen",
  "notifications.notificationSendNowFailedInfo": "Oh nein! 😕 Etwas ist schiefgelaufen. ",
  "notifications.notificationTitle": "Benachrichtigungen",
  "notifications.passwordChangedDescription": "Sie haben Ihr Passwort erfolgreich geändert, bitte melden Sie sich an",
  "notifications.passwordChangedTitle": "Passwort geändert",
  "notifications.sendNotificationNowCofirmModalDesc": "Benachrichtigung <strong>{name}</strong> ist nicht aktiv. Bitte bestätigen Sie, dass Sie sie trotzdem jetzt senden möchten.",
  "notifications.sendNotificationNowCofirmModalTitle": "Benachrichtigung senden",
  "notifications.sendNotificationNowOk": "Senden",
  "notifications.table.actions": "Aktionen",
  "notifications.table.active": "Aktiv",
  "notifications.table.DAILY": "Täglich",
  "notifications.table.frequency": "Häufigkeit",
  "notifications.table.name": "Name",
  "notifications.table.owner": "Besitzer",
  "notifications.table.scheduledTime": "Geplante Zeit",
  "notifications.table.sendNotificationNow": "Jetzt senden",
  "notifications.table.WEEKLY": "Wöchentlich",
  "notifications.title": "Benachrichtigungen",
  "notifications.tooltipInfo": "Alle Ihre Benachrichtigungen sind auf dieser Seite für eine einfachere Verwaltung zusammengefasst. Um mehr zu erfahren, konsultieren Sie bitte unseren <helpDesk>Helpdesk-Artikel.</helpDesk>",
  "notifications.update": "Benachrichtigung aktualisieren",
  "notifications.updateAutomationConfirm": "Das Deaktivieren einer Automatisierung wird das Abonnement nicht kündigen. Wenn Sie das Abonnement beenden möchten, müssen Sie auf die Liste der Automatisierungen zugreifen, indem Sie auf \"Automatisierung erstellen\" klicken und dann auf die Schaltfläche \"Abmelden\" klicken",
  "notifications.updateAutomationTitle": "Automatisierung aktualisieren",
  "notifications.updateNotification": "Aktualisieren",
  "notifications.weekStartsOn": "Woche beginnt am",
  "notificationsForm.advancedFilter.tooltipInfo": "Wenn keine Filter ausgewählt sind, wird die Benachrichtigung alle Benutzer einschließen.",
  "notificationsForm.createInProgress": "Die Benachrichtigung {notificationName} wird erstellt",
  "notificationsForm.currentWeek": "Aktuelle Woche",
  "notificationsForm.DAILY": "Täglich",
  "notificationsForm.deleteFailed": "Löschen fehlgeschlagen",
  "notificationsForm.deleteInProgress": "Die Benachrichtigung {notificationName} wird gelöscht",
  "notificationsForm.frequency": "Häufigkeit",
  "notificationsForm.groupBy": "Urlaub gruppieren nach",
  "notificationsForm.groupBy.tooltip": "Urlaube können nach Ihren Präferenzen angezeigt werden. Sie können nach Standort der Benutzer, Abteilung oder alphabetisch (Keine) sortiert werden.",
  "notificationsForm.locale": "Sprache",
  "notificationsForm.LOCATION": "Standort",
  "notificationsForm.name": "Name der Benachrichtigung",
  "notificationsForm.newNotificationTitle": "Neue Benachrichtigung",
  "notificationsForm.nextWeek": "Nächste Woche",
  "notificationsForm.NONE": "Keine",
  "notificationsForm.periodCovered": "Abgedeckter Zeitraum",
  "notificationsForm.saveFailed": "Speichern fehlgeschlagen",
  "notificationsForm.selectChannel": "Kanal auswählen",
  "notificationsForm.sendEmpty": "Bei keinem bevorstehenden Urlaub",
  "notificationsForm.sendEmptyNo": "Nein, keine Benachrichtigung senden",
  "notificationsForm.sendEmptyYes": "Ja, Benachrichtigung senden",
  "notificationsForm.sendOnDays": "Senden am",
  "notificationsForm.sendOnDaysInfo": "Die Benachrichtigung wird an den ausgewählten Tag(en) versendet.",
  "notificationsForm.submit": "Benachrichtigung speichern",
  "notificationsForm.TEAM": "Abteilung",
  "notificationsForm.time": "Zeit",
  "notificationsForm.timezone": "Zeitzone",
  "notificationsForm.update": "Benachrichtigung aktualisieren",
  "notificationsForm.updateInProgress": "Die Benachrichtigung {notificationName} wird aktualisiert",
  "notificationsForm.updateNotificationTitle": "Benachrichtigung aktualisieren",
  "notificationsForm.WEEKLY": "Wöchentlich",
  "notificationsForm.weekStartsOn": "Woche beginnt am",
  "overlapInfo.moreUsersAreOnLeaves": "ℹ️ Es scheint, dass {overlapperNames} im gleichen Zeitraum im Urlaub sein werden.",
  "overlapInfo.youCanSafelyAcceptTheRequest": "Sie können die Anfrage bedenkenlos annehmen, niemand sonst aus {teamName} ist in diesem Zeitraum im Urlaub.",
  "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "Niemand sonst aus {teamName} ist in diesem Zeitraum im Urlaub.",
  "page404.message": "Die Seite, nach der Sie suchen, macht gerade wohlverdienten Urlaub.",
  "page404.title": "Seite im Urlaub.",
  "quickTour.admin.buttonTitle": "Schnelle Tour",
  "quickTour.buttons.back": "Zurück",
  "quickTour.buttons.close": "Schließen",
  "quickTour.buttons.last": "Schließen",
  "quickTour.buttons.next": "Weiter",
  "quickTour.buttons.skip": "Überspringen",
  "registration.flowB.progress": "Schritt {current} von {total}",
  "registration.flowB.step1.list1": "<b>Kostenlose 7-Tage-Testversion</b>, alle Funktionen inbegriffen",
  "registration.flowB.step1.list2": "Während der Testphase ist keine Kreditkarte erforderlich",
  "registration.flowB.step1.list3": "Integration mit Ihrem Collaboration-Tool",
  "registration.flowB.step1.submitButton": "Loslegen",
  "registration.flowB.step1.subtitle": "Wie lautet Ihre Firmen-E-Mail-Adresse?",
  "registration.flowB.step1.title": "Schließen Sie sich Tausenden von Teams weltweit an, die ihre Urlaubsverfolgung mit Vacation Tracker optimieren.",
  "registration.flowB.step2.submitButton": "Konto erstellen",
  "registration.flowB.step2.subtitle": "Willkommen bei Vacation Tracker",
  "registration.flowB.step3.continueWithEmailPlatform": "Mit Arbeits-E-Mail fortfahren",
  "registration.flowB.step3.continueWithPlatform": "Mit {platform} fortfahren",
  "registration.flowB.step3.list1": "Urlaubsanträge verwalten",
  "registration.flowB.step3.list10": "und mehr!",
  "registration.flowB.step3.list2": "Abwesenheitsbenachrichtigungen senden",
  "registration.flowB.step3.list3": "Urlaubsarten anpassen",
  "registration.flowB.step3.list4": "Standorte & Abteilungen erstellen",
  "registration.flowB.step3.list5": "Berichte generieren",
  "registration.flowB.step3.list6": "Urlaubsansprüche festlegen",
  "registration.flowB.step3.list7": "Teammitglieder nach Labels gruppieren",
  "registration.flowB.step3.list8": "Berichte planen",
  "registration.flowB.step3.list9": "Stundengenauen Urlaub beantragen",
  "registration.flowB.step3.notice": "Wir werden erst mit Ihrem Team kommunizieren, wenn Sie bereit sind.",
  "registration.flowB.step3.submitButton": "LETZTER SCHRITT",
  "registration.flowB.step3.subtitle": "Mit welchem Tool möchten Sie integrieren, {name}?",
  "registration.flowB.step3.title": "7-tägige Testphase des vollständigen Plans",
  "registration.flowB.step3.title2": "Sie erhalten eine <b>kostenlose Testversion</b> unseres <b>Complete-Plans</b>, der Folgendes umfasst:",
  "registration.flowB.testimonial1.profession": "Produzent",
  "registration.flowB.testimonial1.title": "\"Die Leichtigkeit, mit der alle auf die Nutzung von Vacation Tracker umgestiegen sind, ohne eine Million Fragen zu stellen, war beeindruckend!\"",
  "registration.flowB.testimonial2.profession": "CEO",
  "registration.flowB.testimonial2.title": "\"Sehr einfach einzurichten, macht es super einfach, Urlaubstage zu beantragen und zu genehmigen, und das Team ist sehr reaktionsschnell und nimmt das Feedback ernst.\"",
  "registration.flowB.testimonial3.profession": "Direktor für Verwaltungsdienste",
  "registration.flowB.testimonial3.title": "\"Ich kann mich jetzt auf meine Mitarbeiter und zahlreiche andere Dinge konzentrieren - Vacation Tracker hat mir mindestens 10 Stunden pro Woche zurückgegeben, plus zahlreiche Stunden für unsere Manager und Mitarbeiter, da alles elektronisch ist.\"",
  "requestLeave.handleSubmitErrorTitle": "Fehler beim Einreichen des Urlaubs",
  "requestLeave.inProgress": "Urlaub wird beantragt",
  "requestLeave.requestLeave": "Urlaub beantragen",
  "requestLeave.title": "Urlaub beantragt",
  "scheduledReportForm.addNewEmail": "Empfänger hinzufügen",
  "scheduledReportForm.approved": "Genehmigt",
  "scheduledReportForm.createAScheduledReport": "Neuer Bericht",
  "scheduledReportForm.createInProgress": "Der geplante Bericht {scheduledReportName} wird erstellt",
  "scheduledReportForm.deleteFailed": "Löschen fehlgeschlagen",
  "scheduledReportForm.deleteInProgress": "Der geplante Bericht {scheduledReportName} wird gelöscht",
  "scheduledReportForm.deleteReport": "Bericht löschen",
  "scheduledReportForm.deleteScheduledReport": "Geplanten Bericht löschen",
  "scheduledReportForm.deleteScheduledReportConfirm": "Bitte bestätigen Sie, dass Sie den geplanten Bericht {name} löschen möchten.",
  "scheduledReportForm.deleteScheduledReportTitle": "Geplanten Bericht {name} löschen",
  "scheduledReportForm.denied": "Abgelehnt",
  "scheduledReportForm.emails": "Empfänger",
  "scheduledReportForm.lastMonth": "Letzter Monat",
  "scheduledReportForm.leaveBalanceReport": "Urlaubssaldo-Bericht",
  "scheduledReportForm.leaveHistoryReport": "Urlaubsanfragen-Bericht",
  "scheduledReportForm.leaveStatus": "Urlaubsstatus",
  "scheduledReportForm.locations": "Standort(e)",
  "scheduledReportForm.monthlyLeaveBalanceReport": "Monatlicher Urlaubssaldo-Bericht",
  "scheduledReportForm.period": "Berichtszeitraum",
  "scheduledReportForm.periodInfo": "Letzter Monat ",
  "scheduledReportForm.periodInfoTootltip": "Der Bericht ist derzeit auf den Vormonat beschränkt. Für weitere Optionen senden Sie bitte eine E-Mail an hello@vacationtracker.io und wir werden Ihre Anfrage für mögliche zukünftige Verbesserungen notieren.",
  "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Bitte geben Sie eine gültige E-Mail-Adresse ein oder löschen Sie dieses Feld.",
  "scheduledReportForm.pleaseInputValidEmail": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  "scheduledReportForm.reportName": "Name",
  "scheduledReportForm.reportSummary": "Berichtszusammenfassung",
  "scheduledReportForm.reportSummaryInfo": "Berichtszeitraum: {from} - {to}\nBerichtstyp: {reportType}\nNächster Bericht wird am {dateReport} um {timeReport} gesendet",
  "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Berichtszeitraum: Nächste 12 Monate. \nBerichtstyp: {reportType}\nNächster Bericht wird am {dateReport} um {timeReport} gesendet",
  "scheduledReportForm.reportType": "Berichtstyp",
  "scheduledReportForm.required": "Dieses Feld ist erforderlich.",
  "scheduledReportForm.saveFailed": "Speichern fehlgeschlagen",
  "scheduledReportForm.saveReport": "Bericht planen",
  "scheduledReportForm.sendDayOfMonth": "Lieferdatum/Tag",
  "scheduledReportForm.sendDayOfMonthInfo": "Konfigurieren Sie Ihre Berichtszustellungspräferenzen, indem Sie den genauen Tag angeben, an dem der Bericht an die zugewiesenen Empfänger gesendet wird.",
  "scheduledReportForm.sendHourAndMinute": "Lieferzeit",
  "scheduledReportForm.sendHourAndMinuteInfo": "Konfigurieren Sie Ihre Berichtszustellungspräferenzen, indem Sie die genaue Tageszeit angeben, zu der der Bericht an die zugewiesenen Empfänger gesendet wird.",
  "scheduledReportForm.sendReportFailed": "Zustellung fehlgeschlagen",
  "scheduledReportForm.sendReportFailedInfo": "Oh nein! 😕 Etwas ist schief gelaufen. ",
  "scheduledReportForm.sendReportNow": "Jetzt senden",
  "scheduledReportForm.sendReportSuccessfully": "Ihr Bericht ist unterwegs! 🚀",
  "scheduledReportForm.teams": "Abteilung(en)",
  "scheduledReportForm.timezone": "Zeitzone",
  "scheduledReportForm.updateAScheduledReport": "Bericht aktualisieren",
  "scheduledReportForm.updateInProgress": "Aktualisierung des geplanten Berichts {scheduledReportName}",
  "scheduledReportForm.updateReport": "Bericht aktualisieren",
  "scheduledReports.add": "Neuer Bericht",
  "scheduledReports.allLocations": "Alle Standorte",
  "scheduledReports.allTeams": "Alle Abteilungen",
  "scheduledReports.createScheduledReports": "Neuer Bericht",
  "scheduledReports.deleteFailed": "Löschen fehlgeschlagen",
  "scheduledReports.deleteInProgress": "Lösche den geplanten Bericht {scheduledReportName}",
  "scheduledReports.deleteReportConfirm": "Den Bericht {name} löschen?",
  "scheduledReports.deleteReportOkText": "Löschen",
  "scheduledReports.deleteReportTitle": "Löschen bestätigen",
  "scheduledReports.deliveryDate": "Lieferdatum",
  "scheduledReports.edit": "Bearbeiten",
  "scheduledReports.lastMonth": "Letzter Monat",
  "scheduledReports.leaveBalanceReport": "Urlaubssaldo-Bericht",
  "scheduledReports.leaveHistoryReport": "Urlaubsanfragen-Bericht",
  "scheduledReports.locations": "Standort(e)",
  "scheduledReports.monthlyLeaveBalanceReport": "Monatlicher Urlaubssaldo-Bericht",
  "scheduledReports.name": "Name",
  "scheduledReports.period": "Berichtszeitraum",
  "scheduledReports.receivers": "Empfänger",
  "scheduledReports.reportSentSuccessfully": "Ihr Bericht ist unterwegs! 🚀",
  "scheduledReports.reportType": "Berichtstyp",
  "scheduledReports.sendReportFailed": "Zustellung fehlgeschlagen",
  "scheduledReports.sendReportFailedInfo": "Oh nein! 😕 Etwas ist schiefgelaufen.",
  "scheduledReports.sendReportNow": "Jetzt senden",
  "scheduledReports.sendReportSuccessfully": "Ihr Bericht ist unterwegs! 🚀",
  "scheduledReports.teams": "Abteilung(en)",
  "sidebar.addLeave": "Urlaub hinzufügen",
  "sidebar.addons": "Add-ons",
  "sidebar.billing": "Abrechnung",
  "sidebar.calendar": "Kalender",
  "sidebar.changelog": "Änderungsprotokoll",
  "sidebar.dashboard": "Dashboard",
  "sidebar.export": "Exportieren",
  "sidebar.faq": "Hilfe-Center",
  "sidebar.general": "Allgemein",
  "sidebar.holidays": "Feiertage",
  "sidebar.leaves": "Urlaubsanfragen",
  "sidebar.leaveTypes": "Urlaubsarten",
  "sidebar.location": "Standort",
  "sidebar.locations": "Standorte",
  "sidebar.main": "Hauptmenü",
  "sidebar.myProfile": "Mein Profil",
  "sidebar.notifications": "Benachrichtigungen",
  "sidebar.onboarding": "Einarbeitung",
  "sidebar.reports": "Berichte",
  "sidebar.requestLeave": "Urlaub beantragen",
  "sidebar.scheduledReports": "Geplante Berichte",
  "sidebar.settings": "Einstellungen",
  "sidebar.teams": "Abteilungen",
  "sidebar.user": "Benutzer",
  "sidebar.users": "Benutzer",
  "signInAsUser.codeIsRequired": "Ein Code ist erforderlich",
  "signInAsUser.email": "Geben Sie Ihre E-Mail-Adresse ein",
  "signInAsUser.emailIsRequired": "Eine gültige E-Mail-Adresse ist erforderlich",
  "signInAsUser.enterTheCode": "Geben Sie den Code ein",
  "signInAsUser.userId": "Benutzer-ID eingeben",
  "signInAsUser.userIdIsRequired": "Eine Benutzer-ID ist erforderlich",
  "slack.notificationsLoginRequiredDescription1": "Für die Verwaltung von Benachrichtigungen sind Slack-Berechtigungen erforderlich. Wenn Sie auf die OK-Schaltfläche klicken, leiten wir Sie zur Slack-Anmeldeseite weiter. Je nach Ihren Browsereinstellungen sind Sie möglicherweise bereits angemeldet und werden automatisch zurückgeleitet.",
  "slack.notificationsLoginRequiredDescription2": "Nach der Anmeldung bei Ihrem Slack-Konto bringen wir Sie zurück zu dieser Seite, damit Sie die Benachrichtigungen verwalten können. Wenn Sie auf die Schaltfläche Abbrechen klicken, gelangen Sie zurück zur letzten Seite, die Sie in unserem Dashboard besucht haben.",
  "slack.notificationsLoginRequiredTitle": "Anmeldung bei Ihrem Slack-Konto ist erforderlich",
  "slack.usersLoginRequiredDescription1": "Für die Verwaltung von Benutzern sind Slack-Berechtigungen erforderlich. Wenn Sie auf die OK-Schaltfläche klicken, leiten wir Sie zur Slack-Anmeldeseite weiter. Je nach Ihren Browsereinstellungen sind Sie möglicherweise bereits angemeldet und werden automatisch zurückgeleitet.",
  "slack.usersLoginRequiredDescription2": "Nach der Anmeldung bei Ihrem Slack-Konto bringen wir Sie zurück zu dieser Seite, damit Sie die Benutzer verwalten können.",
  "slack.usersLoginRequiredTitle": "Anmeldung bei Ihrem Slack-Konto ist erforderlich",
  "stripe.dynamicMessage": "{message}",
  "subscription.annual.info": "Sie aktivieren den {plan} {period} Plan - ${price}",
  "subscription.hidePlansButton": "Pläne ausblenden",
  "subscription.logout": "Abmelden",
  "subscription.monthly.info": "Sie aktivieren den {plan} {period} Plan - ${usersPrice} ({noOfActiveUsers} Benutzer x {dollarValue})",
  "subscription.monthly.infoUnderUserPLanLimit": "Sie aktivieren den {plan} {period} Plan - ${usersPrice}",
  "subscription.showPlansButton": "Pläne anzeigen",
  "subscription.stripeCustomerPortalLink": "Klicken Sie hier, um das Kundenportal zu besuchen",
  "subscription.subscribe": "Abonnieren",
  "subscription.title": "Abonnement",
  "subscription.updateInProgress": "Zahlungsversuch",
  "subscriptions.switchPlanTitle": "Wechseln Sie zum <strong>{plan}</strong> Plan",
  "surveyAboutUs.description": "Können Sie uns sagen, wie Sie von uns erfahren haben? Wir würden gerne mehr über Ihren Weg zu Vacation Tracker erfahren. Dies ist optional, wird uns aber sehr helfen. Vielen Dank!",
  "surveyAboutUs.OptionQuestion1-1": "Google-Suche",
  "surveyAboutUs.OptionQuestion1-1-1": "Wonach haben Sie bei Google gesucht (z.B. \"Urlaubsverfolgung\")?",
  "surveyAboutUs.OptionQuestion1-2": "App Store (Slack - App-Verzeichnis, MS Teams - App Source, usw...)",
  "surveyAboutUs.OptionQuestion1-3": "Soziale Medien, Bewertungsplattform oder andere Website",
  "surveyAboutUs.OptionQuestion1-3-1": "Welche Plattform oder Website?",
  "surveyAboutUs.OptionQuestion1-4": "Empfehlung",
  "surveyAboutUs.OptionQuestion1-4-1": "Das ist großartig! Wer hat uns empfohlen?",
  "surveyAboutUs.OptionQuestion1-4-1-1": "Ein Kollege oder Freund",
  "surveyAboutUs.OptionQuestion1-4-1-2": "Ein anderes Unternehmen hat es vorgeschlagen",
  "surveyAboutUs.OptionQuestion1-4-1-3": "Ich habe es in der Vergangenheit benutzt",
  "surveyAboutUs.OptionQuestion1-5": "Andere",
  "surveyAboutUs.Question1": "Wie haben Sie von Vacation Tracker erfahren?",
  "surveyAboutUs.textarea": "Bitte spezifizieren",
  "surveyAboutUs.title": "Wie haben Sie von Vacation Tracker erfahren?",
  "team.approvers": "Genehmiger",
  "team.createInProgress": "Erstelle die Abteilung {teamName}",
  "team.editTeam": "Abteilung bearbeiten",
  "team.hasNoUsers": "Abteilung hat keine Benutzer.",
  "team.name": "Name",
  "team.team": "Abteilung",
  "team.updateInProgress": "Aktualisiere die Abteilung {teamName}",
  "team.users": "Benutzer",
  "teamForm.createTeam": "Abteilung erstellen",
  "teamForm.createTeamError": "Oh nein! 😕 Etwas ist schief gelaufen.",
  "teamForm.editTeam": "Abteilung bearbeiten",
  "teamForm.name": "Name",
  "teamForm.pleaseEnterTeamName": "Geben Sie einen Abteilungsnamen ein",
  "teamForm.setAsSubdepartment": "Übergeordnete Abteilung auswählen",
  "teamForm.teamLimitReached": "Hoppla! Sie haben das Limit von {limitNumber} Abteilungen in Ihrem aktuellen Plan erreicht. Um Ihren Plan zu verwalten oder zu aktualisieren, besuchen Sie bitte die <billingPage>Abrechnungsseite</billingPage>.",
  "teamForm.updateTeam": "Abteilung aktualisieren",
  "teams.approvers": "Genehmiger",
  "teams.created": "Erstellt",
  "teams.createNewTeam": "Neue Abteilung erstellen",
  "teams.defaultTeam": "Alle neuen Benutzer werden automatisch dieser Abteilung hinzugefügt.",
  "teams.deleteInProgress": "Lösche die Abteilung {teamName}",
  "teams.deleteTeam": "Löschen",
  "teams.deleteTeamConfirm": "Sind Sie sicher, dass Sie die Abteilung {name} löschen möchten?",
  "teams.deleteTeamTitle": "Abteilung löschen",
  "teams.editTeam": "Abteilung bearbeiten",
  "teams.handleDeleteError": "Löschfehler",
  "teams.handleDeleteErrorTitle": "Fehler beim Löschen der Abteilung",
  "teams.name": "Name",
  "teams.removeUserInfo": "Sie können den Benutzer nicht aus der Standardabteilung entfernen. Wenn Sie den Benutzer in eine andere Abteilung verschieben möchten, können Sie eine andere Abteilung bearbeiten und den Benutzer dort hinzufügen.",
  "teams.teamsLimitReached": "Sie nutzen derzeit alle {limitNumber} Ihrer verfügbaren Abteilungsplätze im CORE-Plan. Um Ihren Plan zu verwalten oder zu aktualisieren, besuchen Sie bitte die <billingPage>Abrechnungsseite</billingPage>.",
  "teams.users": "Benutzer",
  "teams.viewTeam": "Abteilung anzeigen",
  "user.accrualsCalculator": "Berechnen",
  "user.accrualsCalculatorInfo": "Wie viele Tage habe ich bis zu einem bestimmten Datum angesammelt?",
  "user.addLeave": "Urlaub hinzufügen",
  "user.approve": "Genehmigen",
  "user.approveInProgress": "Urlaubsantrag wird genehmigt",
  "user.approveLeaveText": "Bitte bestätigen Sie, dass Sie den Urlaubsantrag genehmigen möchten.",
  "user.approveLeaveTitle": "Urlaubsantrag genehmigen",
  "user.approveSuccessTitle": "Urlaubsantrag genehmigt",
  "user.bulkChangeStatusInProgress": "Benutzerstatus werden aktualisiert.",
  "user.bulkChangeStatusSuccessTitle": "Benutzerstatus wurden aktualisiert.",
  "user.cancelLeave": "Urlaub stornieren",
  "user.cancelLeave.buttonLink": "Stornieren",
  "user.cancelLeaveConfirmTitle": "Urlaub stornieren",
  "user.cancelLeaveRequestInProgress": "Urlaubsantrag wird storniert",
  "user.cancelLeaveRequestTitle": "Urlaubsantrag ist storniert",
  "user.currentLeaves": "Aktuelle Urlaube",
  "user.deleteLeave": "Urlaub löschen",
  "user.deleteLeave.buttonLabel": "Löschen",
  "user.deleteLeaveConfirmText": "Bitte bestätigen Sie, dass Sie den Urlaub löschen möchten.",
  "user.deleteLeaveConfirmTitle": "Urlaub löschen",
  "user.deleteLeaveInProgress": "Urlaub wird gelöscht",
  "user.deleteLeaveSuccessTitle": "Urlaub wurde gelöscht",
  "user.deleteToil": "TOIL löschen",
  "user.deleteToilConfirmText": "Bitte bestätigen Sie, dass Sie TOIL löschen möchten.",
  "user.deny": "Ablehnen",
  "user.denyInProgress": "Urlaubsantrag wird abgelehnt",
  "user.denyLeaveConfirm": "Bitte bestätigen Sie, dass Sie den Urlaubsantrag ablehnen möchten.",
  "user.denyLeaveTitle": "Urlaubsantrag ablehnen",
  "user.denySuccessTitle": "Urlaubsantrag abgelehnt",
  "user.denyWithReason": "Mit Begründung ablehnen",
  "user.dontHaveAnyHistory": "{name} hat noch keinen Urlaub genommen.",
  "user.editLeave": "Urlaub bearbeiten",
  "user.editLeave.buttonLabel": "Bearbeiten",
  "user.editLeaveWord": "Bearbeiten",
  "user.editUser": "Bearbeiten",
  "user.editWorkWeek": "Bearbeiten",
  "user.employeeEndDate": "Enddatum des Mitarbeiters",
  "user.employeeId": "Mitarbeiter-ID",
  "user.employeeSince": "Mitarbeiter seit",
  "user.general": "Allgemein",
  "user.history": "Verlauf",
  "user.historyLeaves": "Verlauf",
  "user.invitationDeletedTitle": "Einladung gelöscht",
  "user.invitationSuccessTitle": "Einladungen gesendet",
  "user.leaves": "Urlaube",
  "user.leaveTypes": "Urlaubsarten",
  "user.location": "Standort",
  "user.location.moveInProgress": "Benutzer wird zum Standort {name} verschoben",
  "user.location.moveSuccessTitle": "Benutzer wurde zum Standort {name} verschoben",
  "user.logs": "Protokolle",
  "user.noUpcomingLeaves": "Keine geplanten Urlaube",
  "user.pendingLeaves": "Ausstehende Urlaubsanträge",
  "user.probationPeriodDescription": "Der Benutzer befindet sich in einer Probezeit unter der <probationPeriodName></probationPeriodName>-Richtlinie für <leaveTypeNames></leaveTypeNames>, die <lengthDays></lengthDays> Tag(e) dauert, bis zum <date></date>.",
  "user.profile": "Profil",
  "user.resend": "Anfrage erneut senden",
  "user.resending": "Urlaubsanfrage wird erneut gesendet...",
  "user.resent": "Anfrage erneut gesendet",
  "user.role": "Rolle",
  "user.signUp": "Registrieren",
  "user.signup.intro": "{invitedByName} hat Sie eingeladen, ihrer Vacation Tracker-Organisation beizutreten. Bitte registrieren Sie sich unten, um die Einladung anzunehmen und auf das Vacation Tracker-Dashboard zuzugreifen.",
  "user.signup.introHelpdesk": "Wenn Sie Fragen haben oder Hilfe benötigen, kontaktieren Sie unseren Support oder besuchen Sie <helpdesk>den Helpdesk</helpdesk>.",
  "user.status": "Status",
  "user.status.updateInProgress": "Benutzerstatus wird aktualisiert.",
  "user.status.updateSuccessTitle": "Benutzerstatus wurde aktualisiert.",
  "user.statusReason": "Grund hinzufügen",
  "user.team.moveInProgress": "Benutzer wird in die Abteilung {name} verschoben",
  "user.team.moveSuccessTitle": "Benutzer wurde in die Abteilung {name} verschoben",
  "user.upcomingLeaves": "Geplante Urlaube",
  "user.updateInProgress": "Benutzer wird aktualisiert",
  "user.updateSuccessTitle": "Benutzer aktualisiert",
  "user.userWorkWeek": "Arbeitswoche des Benutzers",
  "user.workWeek.updateInProgress": "Arbeitswoche wird aktualisiert",
  "user.workWeek.updateSuccessTitle": "Arbeitswoche wurde aktualisiert",
  "users.aboutToSync": "Durch die Synchronisierung aller bestehenden Benutzer gleichen Sie die Benutzerdaten von der von Ihrem Team verwendeten Kollaborationsplattform mit ihrem Vacation Tracker-Profil ab (z.B. Name, Profilbild usw.)",
  "users.activateUsers": "Aktivieren",
  "users.activeUser": "Aktivieren",
  "users.activeUsers": "Aktiv",
  "users.assignLicenses": "Lizenzen zuweisen",
  "users.cancel": "Abbrechen",
  "users.changeUserLocation": "Standort ändern",
  "users.changeUserTeam": "Abteilung ändern",
  "users.deactivate": "Deaktivieren",
  "users.deactivateUser": "Deaktivieren",
  "users.deactivateUserConfirm": "Sind Sie sicher, dass Sie {name} deaktivieren möchten?",
  "users.deactivateUsers": "Deaktivieren",
  "users.deactivateUserTitle": "Deaktivieren",
  "users.deletedUsers": "Gelöscht",
  "users.deleteInvite": "Einladung löschen",
  "users.deleteInviteConfirm": "Sind Sie sicher, dass Sie die an {email} gesendete Einladung löschen möchten?",
  "users.deleteUser": "Benutzer löschen",
  "users.deleteUserUserConfirm": "Sind Sie sicher, dass Sie {name} löschen möchten?",
  "users.employmentDate": "Einstellungsdatum des Benutzers",
  "users.endDate": "Enddatum",
  "users.import.description": "Der Import kann einige Zeit dauern. Falls Sie keine neuen Benutzer in der Liste sehen, aktualisieren Sie bitte diese Seite nach 10-15 Sekunden.",
  "users.importedSuccessfully": "Benutzer erfolgreich importiert",
  "users.importMany.InProgress": "Benutzer werden importiert",
  "users.importMany.successTitle": "Benutzer erfolgreich importiert",
  "users.importNewUsers": "Benutzer hinzufügen",
  "users.importOne.InProgress": "Neuer Benutzer wird importiert",
  "users.importOne.successTitle": "Benutzer erfolgreich importiert",
  "users.importUsers": "Benutzer importieren",
  "users.inactiveUsers": "Inaktiv",
  "users.invitations": "Einladungen",
  "users.inviteUsers": "Benutzer einladen",
  "users.location": "Standort",
  "users.name": "Name",
  "users.next": "Weiter",
  "users.prev": "Zurück",
  "users.reinvite": "Erneut einladen",
  "users.reInviteConfirm": "Sind Sie sicher, dass Sie {email} erneut einladen möchten?",
  "users.role": "Rolle",
  "users.search": "Benutzer suchen",
  "users.sendInvitationsInLanguage": "Einladungen senden in",
  "users.startDate": "Startdatum",
  "users.syncExistingUsers": "Bestehende Benutzer synchronisieren",
  "users.syncExistingUsers.InProgress": "Bestehende Benutzer werden synchronisiert",
  "users.syncExistingUsers.successTitle": "Bestehende Benutzer wurden erfolgreich synchronisiert.",
  "users.syncWithGoogleConsent": "Mit Google-Zustimmung synchronisieren",
  "users.syncWithoutGoogleConsent": "Ohne Google-Zustimmung synchronisieren",
  "users.team": "Abteilung",
  "users.updateQuota.InProgress": "Aktualisierung des Benutzer-Kontingents läuft",
  "users.updateQuota.successTitle": "Benutzer-Kontingent wurde erfolgreich aktualisiert",
  "users.updateRolloverQuota.successTitle": "Anfängliches Übertragskontingent wurde erfolgreich aktualisiert.",
  "users.viewUser": "Benutzer anzeigen",
  "wallchart.range.next4Weeks": "Nächste 4 Wochen",
  "wallchart.range.next5Weeks": "Nächste 5 Wochen",
  "wallchart.range.nextMonth": "Nächster Monat",
  "wallchart.range.previousMonth": "Vorheriger Monat",
  "wallchart.range.thisMonth": "Dieser Monat"
}

import React, { Fragment } from 'react'
import { Alert } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import IntlMessages from '../../util/IntlMessages'
import { IUserToday } from '../../types/custom-queries'
import { useAppSelector } from '../../store/hooks'
import { selectLocaleSlice } from '../../store/locale-slice'
import { getSafeLocale } from '@vacationtracker/shared/i18n'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { formatTimeString } from '@vacationtracker/shared/functions/get-days-or-hours'

interface IUserTodayOff {
  todayLeaves: IUserToday[]
}

const UserTodayOff = ({ todayLeaves }: IUserTodayOff): React.ReactElement => {
  const { locale } = useAppSelector(selectLocaleSlice)
  const safeLocale = getSafeLocale(locale.locale)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  return (
    <Alert type="success"
      icon={<InfoCircleOutlined />}
      showIcon
      style={{ marginBottom: 20 }}
      message={<IntlMessages id="components.userTodayOff.leaveInfo" />}
      description={todayLeaves.map( todayOff => {
        let convertedEndHour
        let convertedStartHour
        if (todayOff.isPartDay) {
          const partDay = todayOff.partDay
          const { value: startHourValue, minute: startMinute, amOrPm: amOrPmStart } = convertHourFormats(
            authUser.hourFormat as HourFormatEnum,
            partDay ? partDay?.startHour as number : todayOff.partDayStartHour as number,
            partDay ? partDay?.startMinute as number : 0
          )
          const { value: endHourValue, minute: endMinute, amOrPm: amOrPmEnd } = convertHourFormats(
            authUser.hourFormat as HourFormatEnum,
            partDay ? partDay?.endHour as number : todayOff.partDayEndHour as number,
            partDay ? partDay?.endMinute as number : 0
          )
          convertedStartHour = formatTimeString({hour: startHourValue, minute: startMinute, showZeroPadding: false, hourFormat: authUser.hourFormat, amOrPm: amOrPmStart})
          convertedEndHour = formatTimeString({hour: endHourValue, minute: endMinute, showZeroPadding: false, hourFormat: authUser.hourFormat, amOrPm: amOrPmEnd})
        }

        return (<Fragment key={todayOff.id}>
          <div className="user-offtoday">
            {dayjs(todayOff.startDate).format('YYYY-MM-DD') === dayjs(todayOff.endDate).format('YYYY-MM-DD') ?
              <>
                {
                  todayOff.isPartDay ?
                    <IntlMessages id="components.userTodayOff.offTodayPartDay" values={{
                      leaveTypeName: todayOff.leaveType.name,
                      from: convertedStartHour,
                      to: convertedEndHour,
                    }} /> :
                    <IntlMessages id="components.userTodayOff.offTodayFullDay" values={{
                      leaveTypeName: todayOff.leaveType.name,
                      today: dayjs(todayOff.startDate).locale(safeLocale).format('MMMM D, YYYY'),
                    }} />
                }
              </> :
              <IntlMessages id="components.userTodayOff.offFromTo" values={{
                leaveTypeName: todayOff.leaveType.name,
                startDate: dayjs(todayOff.startDate).locale(safeLocale).format('MMMM D, YYYY'),
                endDate: dayjs(todayOff.endDate).locale(safeLocale).format('MMMM D, YYYY'),
              }} />
            }
          </div>
          {todayOff.reason &&
            <p className="user-offtoday-reason">
              <IntlMessages id="components.userTodayOff.offTodayReason" values={{ reason: todayOff.reason }} />
            </p>
          }
        </Fragment>)
      })}
    />
  )
}

export default UserTodayOff

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { Form, Row, Col, Button, Typography } from 'antd'

import IntlMessages from '../../../util/IntlMessages'
import countries, { ICountry } from '@vacationtracker/shared/data/countries'
import { track } from '../../../services/analytics/analytics'
import CreateCompanyForm from '../../../components/create-company-form'
import { actions } from '../actions'

const { Title, Text } = Typography

import { IMicrosoftSaasCreateCompanyState } from '../types'
import { validEmailWithRegex } from '@vacationtracker/shared/functions/valid-email-with-regex'
interface ICompanyDetailsProps {
  state: IMicrosoftSaasCreateCompanyState
  dispatch: Function
  platform: string
  variation: string
  paymentProcessor: string
}

export const CompanyDetails = ({ state, dispatch, platform, variation, paymentProcessor }: ICompanyDetailsProps): React.ReactElement => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { formatMessage } = useIntl()
  const [ selectedCountry, setSelectedCountry ] = useState<ICountry|undefined>()
  const [ hasUnlimitedDays, setHasUnlimitedDays ] = useState(state.createCompany.hasUnlimitedDays)
  const [ agreeToTerms, setAgreeToTerms ] = useState(false)

  const { createCompany, createUser } = state

  useEffect(() => {
    track('SIGNUP_COMPANY_CREATE_FORM_VIEWED', {
      platform: 'microsoft',
      variation,
      paymentProcessor,
      plan: createCompany.plan,
      email: createUser.mail,
    })

    setAgreeToTerms(state.agreeToTerms)
    setHasUnlimitedDays(createCompany.hasUnlimitedDays)

    if (createCompany && createCompany.name && createCompany.country) {
      form.setFieldsValue({
        contactEmail: createCompany.contactEmail.includes('onmicrosoft.com') ? '' : createCompany.contactEmail,
        name: createCompany.name,
        country: createCompany.country,
        state: createCompany.state,
        daysPerYear: createCompany.daysPerYear,
        hasUnlimitedDays: createCompany.hasUnlimitedDays,
        agreeToTerms: state.agreeToTerms,
      })
      if (createCompany.country) {
        setSelectedCountry(countries.find(country => country.iso === createCompany.country))
      }
    }
  }, [createCompany])

  const onCountryChange = (countryId) => {
    track('SIGNUP_COMPANY_CREATE_FORM_LOCATION_SET', {
      platform,
      variation,
      paymentProcessor,
      country: countryId,
      plan: createCompany.plan,
      email: createUser.mail,
    })
    setSelectedCountry(countries.find(country => country.iso === countryId))
    form.setFieldsValue({ state: '' })
  }

  const onUnlimitedPTOChange = () => {
    track('SIGNUP_COMPANY_CREATE_FORM_PTO_UNLIMITED', {
      platform,
      variation,
      paymentProcessor,
      hasUnlimitedDays: !hasUnlimitedDays,
      plan: createCompany.plan,
      email: createUser.mail,
    })
    setHasUnlimitedDays(!hasUnlimitedDays)
  }

  const onAgreeToTermsChange = () => {
    setAgreeToTerms(!agreeToTerms)
  }

  const onChangeDaysPerYear = (days) => {
    form.setFieldsValue({
      daysPerYear: Math.round(days),
    })
  }

  const validation = () => {
    if (agreeToTerms) {
      track('SIGNUP_COMPANY_CREATE_FORM_TOS_ACCEPTED', {
        platform,
        variation,
        paymentProcessor,
        plan: createCompany.plan,
        email: createUser.mail,
      })
      return Promise.resolve()
    }
    return Promise.reject(new Error(formatMessage({ id: 'createCompany.steps.companyForm.agreeToTerms.error' })))
  }

  const initialEmail = createCompany.contactEmail || createUser.mail
  const initialValues = {
    contactEmail: initialEmail.includes('onmicrosoft.com') ? '' : initialEmail,
    name: createCompany.name,
    country: createCompany.country,
    state: createCompany.state,
    daysPerYear: createCompany.daysPerYear,
    hasUnlimitedDays: createCompany.hasUnlimitedDays,
    agreeToTerms: state.agreeToTerms,
  }

  const handleCreateCompany = async () => {
    try {
      const values = await form.validateFields()
      if (values.daysPerYear !== initialValues.daysPerYear) {
        track('SIGNUP_COMPANY_CREATE_FORM_PTO_QUOTA_CHANGED', {
          platform,
          daysPerYear: values.daysPerYear,
          variation,
          paymentProcessor,
          plan: createCompany.plan,
          email: createUser.mail,
        })
      }
      dispatch(actions.setStepsCreateCompany(true))
      dispatch(actions.setCreateCompanyDetails(values))
      dispatch(actions.onNextStepChange())
      history.push('/microsoft-saas/create-company/assign-licenses')
    } catch(error) {
      console.log('ERROR HANDLE CREATE COMPANY', error)
    }
  }

  const backToWelcomePage = () => {
    track('SIGNUP_COMPANY_CREATE_FORM_BACK', {
      platform,
      variation,
      paymentProcessor,
      plan: createCompany.plan,
      email: createUser.mail,
    })
    dispatch(actions.backtToWelcomePage())
    history.push('/microsoft-saas/create-company/welcome')
  }

  const onBlurContactEmail = () => {
    const email = form.getFieldValue('contactEmail')

    if (email && validEmailWithRegex(email as string)) {
      // Send to analytics only when is mail valid
      track('SIGNUP_COMPANY_CREATE_FORM_EMAIL_SET', {
        platform,
        variation,
        paymentProcessor,
        plan: createCompany.plan,
        email: email,
      })
    }
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const xxl = { span: 12, offset: 0 }
  const xl = { span: 13, offset: 0 }
  const lg = { span: 18, offset: 0 }
  const md = { span: 20, offset: 0 }
  const xs = { span: 24, offset: 0}

  return (
    <>
      <Title level={5} style={{ marginBottom: 25 }}><IntlMessages id="microsoftSass.createCompany.createCompanyTitle" /></Title>
      <Text>
        <IntlMessages id="microsoftSass.createCompany.createCompanySetupInfo" />
      </Text>
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleCreateCompany}
        initialValues={initialValues}
        {...layout}
      >
        <CreateCompanyForm
          onBlurContactEmail={onBlurContactEmail}
          onCountryChange={onCountryChange}
          selectedCountry={selectedCountry}
          hasUnlimitedDays={hasUnlimitedDays}
          onChangeDaysPerYear={onChangeDaysPerYear}
          onUnlimitedPTOChange={onUnlimitedPTOChange}
          validation={validation}
          onAgreeToTermsChange={onAgreeToTermsChange}
          col={{
            xxl: { span: 12, offset: 0 },
            xl: { span: 14, offset: 0 },
            lg: { span: 18, offset: 0 },
            md: { span: 20, offset: 0 },
            xs: { span: 24, offset: 0},
          }}
          platform='microsoft'
          form={form}
        />
        <Row>
          <Col xxl={xxl} xl={xl} lg={lg} md={md} xs={xs} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={backToWelcomePage}>
              <IntlMessages id="app.back" />
            </Button>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="createCompany.steps.next" />
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
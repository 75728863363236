import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { App, Tag, Button, Typography, Space, Divider, Badge } from 'antd'
import { Link } from 'react-router-dom'
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import format from 'date-fns/format'

import getDateInUserTimezone from '../../functions/get-date-in-user-timezone'
import { invertHexColor } from '@vacationtracker/shared/components/utils/invert-color-wrapper'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'
import { getDateFnsLocale } from '../../i18n'

import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { DisplayLeaveDuration } from '../display-leave-in-days-and-hours'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'

import { LocaleEnum } from '../../types/i18n'
import { HourFormatEnum } from '../../types/user'
import { ILeaveDayDetails, IPartDay } from '../../types/leave-request'
import { formatTimeString } from '../../functions/get-days-or-hours'

interface ILeavesBox {
  leaveRequestId: string
  userId: string
  userImage: string
  userName: string
  leaveTypeName: string
  leaveTypeColor: string
  isHiddenLeaveType: boolean
  leaveStatus: string
  type: string
  approver?: string
  autoApproved?: boolean
  startDate: string
  endDate: string
  role: string
  workingDays: number
  isPartDay: boolean
  onUpdateLeaveRequest?: (e) => void
  onCancelLeave?: (e) => void
  handleDenyWithReason: (e) => void
  reason?: string
  partDay?: IPartDay
  partDayStartHour?: number
  partDayEndHour?: number
  editLeaves?: boolean
  approverToUsers: {
    id: string
  }[]
  isEdited?: boolean
  hourlyLeaveAccounting: boolean
  locale: LocaleEnum
  hourFormat?: HourFormatEnum
  isToil?: boolean
  approvedBySubstituteApprover?: boolean
  substituteApproverSet?: boolean
  daysList?: ILeaveDayDetails[]
}

const { Text } = Typography


const LeavesBox = ({
  leaveRequestId,
  userId,
  userImage,
  userName,
  leaveTypeName,
  leaveTypeColor,
  isHiddenLeaveType,
  leaveStatus,
  type,
  approver,
  autoApproved = false,
  startDate,
  endDate,
  role,
  workingDays,
  daysList,
  isPartDay,
  onUpdateLeaveRequest,
  handleDenyWithReason,
  onCancelLeave,
  reason,
  partDayStartHour,
  partDayEndHour,
  partDay,
  approverToUsers,
  isEdited,
  locale,
  hourFormat,
  isToil = false,
  approvedBySubstituteApprover = false,
  substituteApproverSet = false,
}: ILeavesBox): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { modal } = App.useApp()
  const [apprveBtnLoading, setApprveBtnLoading] = useState(false)
  const [denyBtnLoading, setDenyBtnLoading] = useState(false)
  const [isAdminOrUsersApprover] = useState(role === 'Admin' || (role === 'Approver' && approverToUsers.find(user => user.id === userId)))
  const [hideThisLeaveType] = useState(isHiddenLeaveType && !isAdminOrUsersApprover)
  let convertedStartHour
  let convertedEndHour
  if (isPartDay && (partDay || (typeof partDayStartHour === 'number' && typeof partDayEndHour === 'number'))) {
    const { value: startHourValue, minute: startMinute, amOrPm: amOrPmStart } = convertHourFormats(
      hourFormat as HourFormatEnum,
      partDay ? partDay.startHour : partDayStartHour as number,
      partDay ? partDay.startMinute as number : 0
    )
    const { value: endHourValue, minute: endMinute, amOrPm: amOrPmEnd } = convertHourFormats(
      hourFormat as HourFormatEnum,
      partDay ? partDay.endHour : partDayEndHour as number,
      partDay ? partDay.endMinute as number : 0
    )
    convertedStartHour = formatTimeString({hour: startHourValue, minute: startMinute, showZeroPadding: false, hourFormat, amOrPm: amOrPmStart})
    convertedEndHour = formatTimeString({hour: endHourValue, minute: endMinute, showZeroPadding: false, hourFormat, amOrPm: amOrPmEnd})
  }

  const showCancelConfirm = () => {
    void modal.confirm({
      title: formatMessage({ id: 'app.cancelLeave' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'components.leaveBox.cancelLeaveConfirm' }),
      okText: formatMessage({ id: 'app.yes' }),
      okType: 'danger',
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        if (onCancelLeave) {
          onCancelLeave({
            id: leaveRequestId,
            user: {
              id: userId,
            },
          })
        }
      },
    })
  }

  const showDenyConfirm = () => {
    void modal.confirm({
      title: formatMessage({ id: 'app.denyLeaveRequest' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'app.denyLeaveRequestText' }),
      okText: formatMessage({ id: 'app.yes' }),
      okType: 'danger',
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        setDenyBtnLoading(true)
        if (onUpdateLeaveRequest) {
          onUpdateLeaveRequest(false)
        }
      },
    })
  }

  const handleApprove = () => {
    void modal.confirm({
      title: formatMessage({ id: 'app.approveLeaveTitle' }),
      icon: <CheckCircleOutlined />,
      content: formatMessage({ id: 'app.approveLeaveText' }),
      okText: formatMessage({ id: 'app.yes' }),
      okType: 'primary',
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        setApprveBtnLoading(true)
        if (onUpdateLeaveRequest) {
          onUpdateLeaveRequest(true)
        }
      },
    })
  }

  const is24HourFormat = hourFormat === HourFormatEnum.twentyFour
  const leaveMessage = () => {
    const leaveStartDateInTimezone = getDateInUserTimezone(startDate)
    const leaveStartDate = format(new Date(leaveStartDateInTimezone), 'MMMM do yyyy', {locale: getDateFnsLocale(locale)})
    const leaveEndDateInTimezone = getDateInUserTimezone(endDate)
    const leaveEndDate = format(new Date(leaveEndDateInTimezone), 'MMMM do yyyy', {locale: getDateFnsLocale(locale)})
    const today = getDateInUserTimezone(new Date())
    const todayDate = format(today, 'MMMM do yyyy.', {locale: getDateFnsLocale(locale)})
    return (
      <>
        {(leaveStatus === 'APPROVED' && leaveStartDate === leaveEndDate && !isPartDay) &&
          <>
            {hideThisLeaveType ?
              <IntlMessages id="components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo" values={{ leaveTypeName, leaveStartDate }} /> :
              <IntlMessages id="components.leaveBox.leavesUpcomingOneDayInfo" values={{ leaveTypeName, leaveStartDate }} />
            }
          </>
        }
        {(leaveStatus === 'APPROVED' && leaveStartDate !== leaveEndDate && !isPartDay) &&
          <>
            {hideThisLeaveType ?
              <IntlMessages id="components.leaveBox.leavesOffTodayWithoutReasonInfo" values={{ leaveTypeName, leaveStartDate, leaveEndDate }} /> :
              <IntlMessages id="components.leaveBox.leavesOffTodayInfo" values={{ leaveTypeName, leaveStartDate, leaveEndDate }} />
            }
          </>
        }
        {(leaveStatus === 'APPROVED' && isPartDay && leaveStartDate !== todayDate) &&
          <>
            {hideThisLeaveType ?
              <IntlMessages id="components.leaveBox.leavesBoxHalfDayWithoutReason"
                values={{
                  hourFormat24: is24HourFormat,
                  leaveTypeName, leaveStartDate,
                  startHour: convertedStartHour,
                  endHour: convertedEndHour,
                }} /> :
              <IntlMessages id="components.leaveBox.leavesBoxHalfDay"
                values={{
                  hourFormat24: is24HourFormat,
                  leaveTypeName,
                  leaveStartDate,
                  startHour: convertedStartHour,
                  endHour: convertedEndHour,
                }}
              />
            }
          </>
        }
        {(leaveStatus === 'APPROVED' && isPartDay && leaveStartDate === todayDate) &&
          <>
            {hideThisLeaveType ?
              <IntlMessages id="components.leaveBox.leavesBoxHalfDayTodayWithoutReason" values={{ leaveTypeName, startHour: convertedStartHour, endHour: convertedEndHour }} /> :
              <IntlMessages id="components.leaveBox.leavesBoxHalfDay" values={{
                hourFormat24: is24HourFormat,
                leaveTypeName,
                leaveStartDate,
                startHour: convertedStartHour,
                endHour: convertedEndHour,
              }} />
            }
          </>
        }
        {(leaveStatus === 'OPEN' && leaveStartDate !== leaveEndDate && !isPartDay) && <>
          <IntlMessages id="components.leaveBox.leavesPendingInfo" values={{ leaveTypeName, leaveStartDate, leaveEndDate }} />
        </>
        }
        {(leaveStatus === 'OPEN' && leaveStartDate === leaveEndDate && !isPartDay) && <>
          <IntlMessages id="components.leaveBox.leavesPendingOneDay" values={{ leaveTypeName, leaveStartDate, leaveEndDate }} />
        </>
        }
        {(leaveStatus === 'OPEN' && isPartDay) && <>
          <IntlMessages id="components.leaveBox.leavesBoxHalfDayPending" values={{
            hourFormat24: is24HourFormat,
            leaveTypeName, leaveStartDate,
            startHour: convertedStartHour,
            endHour: convertedEndHour,
          }} />
        </>
        }
      </>
    )
  }

  const reviewedBy = () => {
    if (leaveStatus === 'APPROVED' && approvedBySubstituteApprover) {
      return (
        <Badge.Ribbon
          text={formatMessage({id: 'app.substituteApprover'})}
          style={{top: '-20px', right: '-30px'}}
          color='#7f00ff'
        >
          <IntlMessages id='components.leaveBox.approvedBy' />:{' '}
          <strong>
            {autoApproved ? <IntlMessages id='app.autoApproved' /> : approver}
          </strong>
        </Badge.Ribbon>
      )
    }
    if (leaveStatus === 'APPROVED') {
      return (
        <>
          <IntlMessages id='components.leaveBox.approvedBy' />:{' '}
          <strong>
            {autoApproved ? <IntlMessages id='app.autoApproved' /> : approver}
          </strong>
        </>
      )
    }
    return <></>
  }

  return (
    <>
      <div className="leaves-box">
        <div className="avatar">
          <UserAvatar id={userId} name={userName} shape="square" avatarSize={70} avatar={userImage} isLinkToPage={role === 'Admin'}></UserAvatar>
        </div>
        <div className="content">
          <div className="info">
            <div className="user">
              {!hideThisLeaveType &&
                <Tag color={leaveTypeColor} style={{ color: invertHexColor(leaveTypeColor, true) }}>{leaveTypeName}</Tag>
              }
              {isToil && <Tag color={'green'}><IntlMessages id="app.toil" /></Tag>}
              <h4>{
                role === 'Admin' ? (
                  <Link to={`/app/users/${userId}`}>{userName}</Link>
                ) : userName
              }</h4>
            </div>
            <div>{reviewedBy()}</div>
          </div>
          <div className="leaves-info">
            <Space className="leaves-text" size={5} direction="vertical">
              <Text>{leaveMessage()} {isEdited && <Tag color="#7f00ff"><IntlMessages id="app.edited" /></Tag>}</Text>
              {( reason && isAdminOrUsersApprover )
                && <Text><b><IntlMessages id="app.reason" />:</b>  {reason}</Text>}
              <Text type="secondary">
                {!isToil ?
                  <IntlMessages
                    id='components.leaveBox.formattedLeaveInfo'
                    values={{ leave: <DisplayLeaveDuration value={workingDays} daysList={daysList}/> } }
                  /> :
                  <IntlMessages
                    id='components.leaveBox.toilDuration'
                    values={{ leave: <DisplayLeaveDuration value={workingDays} daysList={daysList}/> }}
                  />
                }
              </Text>
              {substituteApproverSet && <Text type="warning"><IntlMessages id="app.substituteApproverSet" /></Text>}
            </Space>
            {type !== 'OFFTODAY' &&
              <div className="leave-action">
                <Space size={8} direction="horizontal">
                  {(leaveStatus === 'APPROVED' && isAdminOrUsersApprover ) &&
                    <Button onClick={showCancelConfirm} type="primary" danger><IntlMessages id="app.cancelLeave" /></Button>
                  }
                  {(leaveStatus === 'OPEN') &&
                    <>
                      {isAdminOrUsersApprover &&
                      <Button type="default" >
                        <Link key={`${leaveRequestId}-edit`} to={`/app/leaves/${isToil ? 'toil/' : ''}${leaveRequestId}/edit`}>
                          <IntlMessages id="app.edit" />
                        </Link>
                      </Button>
                      }
                      <Button loading={denyBtnLoading} onClick={showDenyConfirm} type="primary" danger>
                        <IntlMessages id="app.deny" />
                      </Button>
                      <Button
                        onClick={(leave) => {
                          handleDenyWithReason(leave)
                        }}
                        type="primary" danger>
                        <IntlMessages id="app.denyWithReason" />
                      </Button>
                      <Button loading={apprveBtnLoading} onClick={handleApprove} type="primary">
                        <IntlMessages id="app.approve" />
                      </Button>
                    </>
                  }
                </Space>
              </div>
            }
          </div>
        </div>
      </div>
      <Divider style={{ margin: '10px 0' }} />
    </>
  )
}

export default LeavesBox

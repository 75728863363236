/* eslint-disable max-len */
import { Platform } from '@vacationtracker/shared/data/platforms'

export const getOnboardingLink = (platform: Platform, userRole: string): string => {
  if (platform === 'slack' && userRole === 'Admin') {
    return 'https://www.canva.com/design/DAGVuThEf68/w7iDTVmUVC0MYH59SJAWAw/view?utm_content=DAGVuThEf68&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h8f513633c4'
  } else if (platform === 'slack' && userRole === 'Approver') {
    return 'https://www.canva.com/design/DAGVuL7CHWA/qBDK28q0D1V5Y62PK2Svvg/view?utm_content=DAGVuL7CHWA&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h025c5c0a53'
  } else if (platform === 'slack' && userRole === 'User') {
    return 'https://www.canva.com/design/DAGVoFAbzeU/rKdg657l8RbbV1c6JPg1Og/view?utm_content=DAGVoFAbzeU&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hb25db758c0'
  } else if (platform === 'microsoft' && userRole === 'Admin') {
    return 'https://www.canva.com/design/DAGV1A_TRJA/QfFBSijMI5KahkIRcav8VQ/view?utm_content=DAGV1A_TRJA&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hc5185e7a6f'
  } else if (platform === 'microsoft' && userRole === 'Approver') {
    return 'https://www.canva.com/design/DAGV1Lc2xPk/YeBjeAaJpDXLwCXBz_jbWA/view?utm_content=DAGV1Lc2xPk&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=haf17dd053c'
  } else if (platform === 'microsoft' && userRole === 'User') {
    return 'https://www.canva.com/design/DAGVu2wY3pk/igN_8TbvfSLjq6Ty4ucusw/view?utm_content=DAGVu2wY3pk&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h4f86607cc1'
  } else if (platform === 'google' && userRole === 'Admin') {
    return 'https://www.canva.com/design/DAGV6010_2M/THt58s7BKDazL8SKLzhdvA/view?utm_content=DAGV6010_2M&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h25b5c987d1'
  } else if (platform === 'google' && userRole === 'Approver') {
    return 'https://www.canva.com/design/DAGV1Y9_I2E/lKH1F9vMlzJcDMRDveaq0g/view?utm_content=DAGV1Y9_I2E&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h51ec8fb8b5'
  } else if (platform === 'google' && userRole === 'User') {
    return 'https://www.canva.com/design/DAGV1Al1aS8/o-7Ir7NA2-R-xdvUiaZjmg/view?utm_content=DAGV1Al1aS8&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h4176a99a91'
  } else if (platform === 'email' && userRole === 'Admin') {
    return 'https://www.canva.com/design/DAGV7LvXCSQ/4fGR1auDgshV1UQa_tZmuQ/view?utm_content=DAGV7LvXCSQ&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h26f177555e'
  } else if (platform === 'email' && userRole === 'Approver') {
    return 'https://www.canva.com/design/DAGV7Myg54U/tpyh_rtXLO6K3iQ1GPEd2w/view?utm_content=DAGV7Myg54U&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hf919ac245d'
  } else if (platform === 'email' && userRole === 'User') {
    return 'https://www.canva.com/design/DAGV6wR6g5Q/Ddk31U3hoeYl8ZIvnEJvmQ/view?utm_content=DAGV6wR6g5Q&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hac3ac7d272'
  } else {
    return 'error'
  }
}
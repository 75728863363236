export const allPlatforms = ['slack', 'microsoft', 'google', 'email']

export type Platform = typeof allPlatforms[number]

export enum PlatformEnum {
  Slack = 'slack',
  Microsoft = 'microsoft',
  Google = 'google',
  Email = 'email',
}

export const getQuotasPart = () => `
  quotas {
    rolloverFromPreviousYear {${quotaFields}}
    earnedWithoutRollover {${quotaFields}}
    baseQuota {${quotaFields}}
    toil {${quotaFields}}
    toilLapsed {${quotaFields}}
    seniorityEntitlementForYear {${quotaFields}}
    entitlementByRoleForYear {${quotaFields}}
    expired {${quotaFields}}
    used {${quotaFields}}
    scheduled {${quotaFields}}
    remaining {${quotaFields}}
  }
`
const quotaFields = `
  days
  hours
  minutes
  isNegative
  raw {
    days
    hours
  }
`
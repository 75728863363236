import { ToilRequestErrorTypeEnum } from '../../types/toil-request'

export function showErrors(responseError: ToilRequestErrorTypeEnum) {
  if (responseError === 'TOIL_REQUEST_OVERLAP_DAYS') {
    return 'components.toil.submitErrorOverlap'
  }
  if (responseError === 'TOIL_REQUEST_INVALID_DATES') {
    return 'components.toil.submitErrorDate'
  }
  if (responseError === 'TOIL_REQUEST_LONGER_THAN_WORKDAY') {
    return 'components.toil.submitErrorLongerThanWorkday'
  }
  if (responseError === 'TOIL_REQUEST_DATES_IN_FUTURE') {
    return 'components.toil.submitErrorDateFuture'
  }
  if (responseError === 'TOIL_REQUEST_NOT_OPEN') {
    return 'components.toil.submitErrorNotOpen'
  }
  if (responseError === 'TOIL_REQUEST_INVALID_POLICY') {
    return 'components.toil.submitErrorPolicy'
  }
  if (responseError === 'TOIL_REQUEST_NOT_SET_ON_LOCATION') {
    return 'components.toil.submitErrorPolicyLocation'
  }
  if (responseError === 'TOIL_REQUEST_INVALID_PLAN') {
    return 'components.toil.submitErrorPlan'
  }
  if (responseError === 'TOIL_REQUEST_NOT_ALLOWED') {
    return 'components.toil.submitErrorRole'
  }
  if (responseError === 'TOIL_REQUEST_NO_REASON_SET') {
    return 'components.toil.submitErrorReasonNotSet'
  }
  if (responseError === 'TOIL_REQUEST_INVALID_POLICY_DURATION') {
    return 'components.toil.submitErrorPolicyDuration'
  }
  if (responseError === 'TOIL_REQUEST_INVALID_MINUTES') {
    return 'components.leaveForm.minutesMustMatch'
  }
  return responseError
}
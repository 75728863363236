import React, { useState, useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { App, Breadcrumb } from 'antd'
import omit from 'lodash/omit'
import { LoadingOutlined } from '@ant-design/icons'
import { useManualQuery } from 'graphql-hooks'
import dayjs from 'dayjs'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../../services/logger'
import { getUserForLeaveRequest, getUsersForAdminWithPagination, getUsersForApprover } from '../../../graphql/custom-queries'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { selectUserIdSlice } from '../../../store/user-id-slice'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectToilRequestActionEventSlice, setToilRequestActionEvent } from '../../../store/toil-request-action-event-slice'

import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '../../../util/IntlMessages'
import ToilForm from '@vacationtracker/shared/components/toil-form'
import { showErrors } from '@vacationtracker/shared/components/toil-form/errors'

import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import {
  IGetUserForLeaveRequest,
  IGetUserForLeaveReueqstUserData,
  IGetUserForLeaveReueqstUserDataLocationLeavePolicies,
  IGetUsersForAdminAddLeaveData,
  IGetUsersForApproverData,
  IUserIdAndName
} from '../../../types/custom-queries'
import { IToilFormData } from '@vacationtracker/shared/components/toil-form/types'
import { ToilRequestSummary } from '@vacationtracker/shared/components/toil-form/summary'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { IApprover } from '@vacationtracker/shared/types/leave-request'
import { FrontendUrls } from '../../../types/urls'
import { DEFAULT_WORKING_HOURS_IN_DAY } from '@vacationtracker/shared/data/app-parameters'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'

interface IRequestToilProps {
  history: {
    push: Function
    goBack: Function
  }
}

const RequestToilPage = ({ history }: IRequestToilProps) => {
  const dispatch = useAppDispatch()
  const { notification } = App.useApp()
  const { userId } = useAppSelector(selectUserIdSlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { toilRequestActionEvent } = useAppSelector(selectToilRequestActionEventSlice)
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const enableMinutePickerLeave = useFeatureFlagEnabled(FeatureFlagEnum.enableMinutePickerLeave)

  const [isFormBeingSubmitted, setFormIsBeingSubmitted] = useState(false)
  const [userList, setUserList] = useState<IUserIdAndName[]>([])
  const [selectedUser, setSelectedUser] = useState<IGetUserForLeaveReueqstUserData | null>(null)
  const [selectedLeaveType, setSelectedLeaveType] = useState<IGetUserForLeaveReueqstUserDataLocationLeavePolicies | null>(null)
  const [toilFormData, setToilFormData] = useState<IToilFormData|null>()
  const [isLoading, setIsLoading] = useState(true)
  const [showSummary, setShowSummary] = useState(false)
  const [correlationId, setCorrelationId] = useState(null)
  const [allowAddToilForAuthUser, setAllowAddToilForAuthUser] = useState(true)
  const [isMinutePickerEnabled, setIsMinutePickerEnabled] = useState(false)

  const abortSignalRef = useRef(new AbortController())

  const [ getUsersForAdminWithPaginationQuery ] = useManualQuery<IGetUsersForAdminAddLeaveData, {
    limit: number
    nextToken?: string
  }>(getUsersForAdminWithPagination)
  const [ getUserForLeaveRequestQuery ] = useManualQuery<IGetUserForLeaveRequest, { id: string }>(getUserForLeaveRequest)
  const [getUsersForApproverQuery] = useManualQuery<IGetUsersForApproverData, { id: string }>(getUsersForApprover)

  useEffect(() => {
    if (typeof enableMinutePickerLeave === 'undefined') {
      return
    }
    setIsMinutePickerEnabled(enableMinutePickerLeave)
  }, [enableMinutePickerLeave])

  useEffect(() => {
    abortSignalRef.current = new AbortController()
    return () => {
      abortSignalRef.current.abort()
    }
  }, [])

  useEffect(() => {
    if (isLoading) {
      getUsersList(userId)
    }
  }, [isLoading, userId])

  useEffect(() => {
    if (toilRequestActionEvent && correlationId === toilRequestActionEvent.correlationId) {
      setShowSummary(true)
      dispatch(setToilRequestActionEvent(null))
    }
  }, [toilRequestActionEvent])

  const getUsersWithPagination = async (limit: number, nextToken?: string, accumulatedResults: IUserIdAndName[] = []) => {
    const response = await getUsersForAdminWithPaginationQuery({ variables: { limit, nextToken } })
    if (!response.data || response.error) throw response.error

    const { users, nextToken: newNextToken } = response.data.getActiveUsersWithPagination

    const updatedResults = [...accumulatedResults, ...users]

    if (newNextToken) {
      return getUsersWithPagination(limit, newNextToken, updatedResults)
    } else {
      return updatedResults
    }
  }

  const getUsersList = async (id) => {
    try {
      const response = await getUserForLeaveRequestQuery({
        variables: {
          id,
        },
        fetchOptionsOverrides: {
          signal: abortSignalRef.current.signal,
        },
      })
      if (!response.data || response.error) throw response.error
      let users: IUserIdAndName[] = []
      if (authUser.role === 'Admin') {
        users = await getUsersWithPagination(300)
      }
      if (authUser.role === 'Approver') {
        const response = await getUsersForApproverQuery({
          variables: {
            id: authUser.id,
          },
          fetchOptionsOverrides: {
            signal: abortSignalRef.current.signal,
          },
        })
        if (!response.data || response.error) throw response.error
        users = response.data.getUser.approverTo
      }
      if (authUser.role === 'User') {
        if (response.data.getUser.location.leavePolicies?.find(lp => lp.toil && lp.toilRequestsAllowedForUsers)) {
          users = [{
            id: authUser.id,
            name: authUser.name,
          }]
          setSelectedUser(response.data.getUser)
        }
      } else {
        // If the user is not their own approver, then they cannot perform the 'add toil' action.
        if (!users.find(user => user.id === authUser.id)) {
          setAllowAddToilForAuthUser(false)
          // If there is available TOIL in the system but it's not allowed for requesting for users, there's no need to add the authorized user to request TOIL.
          if (response.data.getUser.location.leavePolicies?.find(lp => lp.toil && lp.toilRequestsAllowedForUsers)) {
            users.push({
              id: authUser.id,
              name: authUser.name,
            })
            setSelectedUser(response.data.getUser)
          }
        }
      }

      setUserList(users.sort((a: IUserIdAndName, b: IUserIdAndName) => a.name < b.name ? -1 : 1))
      setIsLoading(false)
    } catch (err) {
      if (err.fetchError?.name === 'AbortError') {
        return
      }
      logger.error('error fetching users list', err)
    }
  }

  const handleOnSelectUser = async (userId: string) => {
    const user = userList.find(user => user.id === userId)
    if (user && 'leaveDays' in user) {
      setSelectedUser(user as IGetUserForLeaveReueqstUserData)
    } else {
      const response = await getUserForLeaveRequestQuery({
        variables: {
          id: userId,
        },
        fetchOptionsOverrides: {
          signal: abortSignalRef.current.signal,
        },
      })
      if (!response.data || response.error) throw response.error
      setSelectedUser(response.data.getUser as IGetUserForLeaveReueqstUserData)
    }
  }

  const handleSubmit = async (data: IToilFormData) => {
    setToilFormData(data)
    setFormIsBeingSubmitted(true)
    setCorrelationId(null)
    let response
    const isAdding = data.addToil
    const params = omit(data, ['addToil'])

    const body = {
      eventType: isAdding ? 'TOIL_REQUEST_ADDED' : 'TOIL_REQUEST_CREATED',
      eventGroup: 'USER_TOIL_REQUEST',
      ...params,
    }

    try {
      response = await Api.post('/core/toil-request-validate', body)
      response = await Api.post('/core/event', body)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isAdding ? 'components.toil.addToilProgress' : 'components.toil.requestToilProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ... (actionNotifications ?? []), response.correlationId ])
      setCorrelationId(response.correlationId)
      setFormIsBeingSubmitted(false)
    } catch (error) {
      setCorrelationId(null)
      setFormIsBeingSubmitted(false)
      if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'components.toil.submitError' }),
          description: formatMessage({ id: showErrors(error.response.data.message || error.response?.data?.error) }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'components.toil.submitError' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const handleOnLeaveTypeSelected = (selectedLeaveType) => {
    setSelectedLeaveType(selectedLeaveType)
  }

  const resetToilRequestForm = () => {
    setShowSummary(false)
    setFormIsBeingSubmitted(false)
    setToilFormData(null)
    setIsLoading(false)
  }

  const sendFeedback = async (body) => {
    await Api.post('/core/event', body)
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id='components.toil.requestToil' />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id='components.toil.requestToil' />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {isLoading ?
          <CircularProgress /> :
          <>
            {showSummary &&
              <ToilRequestSummary
                userId={userId}
                isAdding={Boolean(toilFormData?.addToil)}
                toilRequest={{
                  startDate: toilFormData?.startDate as string,
                  endDate: toilFormData?.endDate as string,
                  isPartDay: toilFormData?.isPartDay as boolean,
                  partDayEndHour: toilFormData?.partDayEndHour,
                  partDayStartHour: toilFormData?.partDayStartHour,
                  partDay: toilFormData?.partDay,
                  workingHoursInDay: DEFAULT_WORKING_HOURS_IN_DAY,
                  requestedDays: dayjs(toilFormData?.endDate).diff(dayjs(toilFormData?.startDate), 'day') + 1,
                  userName: selectedUser?.name as string,
                  leaveTypeName: selectedLeaveType?.leaveType?.name as string,
                }}
                approvers={selectedUser?.team.approvers as IApprover[]}
                locale={authUser?.locale as LocaleEnum}
                hourFormat={authUser?.hourFormat || HourFormatEnum.twentyFour}
                resetToilRequestForm={resetToilRequestForm}
                onSave={sendFeedback}
              />
            }
            {!showSummary &&
              <ToilForm
                authUserId={authUser.id}
                authUserRole={authUser.role}
                loading={isFormBeingSubmitted}
                listOfUsers={userList}
                hourFormat={authUser.hourFormat as HourFormatEnum}
                onSave={(data: IToilFormData) => {
                  (async () => {
                    await handleSubmit(data)
                  })()
                }}
                onSelectUserToAddToilRequest={handleOnSelectUser}
                selectedUser={selectedUser}
                onCancel={() => { history.goBack(); history.goBack() }}
                onLeaveTypeSelected={handleOnLeaveTypeSelected}
                allowAddToilForAuthUser={allowAddToilForAuthUser}
                isMinutePickerEnabled={isMinutePickerEnabled}
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default RequestToilPage

import { HourFormatEnum } from '../types/user'
import timezones from '../data/timezone'
import { TIME_CONSTANTS } from '../constants/time'

/**
 * Generates a 24-hour map with corresponding 12-hour format and AM/PM indicators
 * @param {number} [minute] - Optional minute value to include in each hour mapping
 * @returns {Array<{value: number, minute: number, amOrPm: string}>} Array of hour mappings
 * @example
 * getHoursAndAmOrPmMap()
 * // Returns: [
 * //   { value: 12, minute: 0, amOrPm: 'AM' }, // 12 AM
 * //   { value: 1, minute: 0, amOrPm: 'AM' },  // 1 AM
 * //   ...
 * //   { value: 12, minute: 0, amOrPm: 'PM' }, // 12 PM
 * //   { value: 1, minute: 0, amOrPm: 'PM' }   // 1 PM
 * // ]
 */
export const getHoursAndAmOrPmMap = (minute?: number | undefined) => Array.from(Array(24).keys()).map(v => ({
  value: v === 0 || v === 12 ? 12 : v % 12,
  minute: minute || 0,
  amOrPm: v < 12 ? 'AM' : 'PM',
}))

/**
 * Converts time between 12-hour and 24-hour formats.
 *
 * @param hourFormat - The target hour format (12h or 24h)
 * @param hour - Hour value to convert (1-24)
 * @param minute - Optional minutes (0-59)
 * @param hoursMap - Optional pre-computed hour mapping for performance
 *
 * @returns Object containing:
 *  - value: Converted hour value
 *  - minute: Minutes component
 *  - amOrPm: AM/PM indicator for 12h format (empty for 24h)
 *
 * @example
 * // Convert 13:30 to 12h format
 * convertHourFormats(HourFormatEnum.twelve, 13, 30)
 * // Returns { value: 1, minute: 30, amOrPm: 'PM' }
 *
 * // Convert 9:00 to 24h format
 * convertHourFormats(HourFormatEnum.twentyFour, 9)
 * // Returns { value: 9, minute: 0, amOrPm: '' }
 */
export const convertHourFormats = (
  hourFormat: HourFormatEnum,
  hour: number,
  minute?: number,
  hoursMap = getHoursAndAmOrPmMap(minute)
): {
  value: number
  minute: number
  amOrPm: string
} => {
  if (typeof minute !== 'number') {
    minute = 0
  }
  if (hourFormat === HourFormatEnum.twentyFour) {
    return {value: hour, minute, amOrPm: ''}
  } else {
    return hoursMap[hour]
  }
}

export const getHourFormatBasedOnTimezone = (timezoneId?: string) => {
  if (!timezoneId) {
    return HourFormatEnum.twentyFour
  }
  const timezone = timezones.find(timezone => timezone.id === timezoneId)
  return timezone?.hourFormat || HourFormatEnum.twentyFour
}


export const formatHourForHalfDaySelect = (
  hour: number,
  minute = 0,
  hourFormat: HourFormatEnum
): string => {
  const endHour = hour + 4
  const paddedMinute = minute.toString().padStart(2, '0')

  // Only prevent blocks that cross midnight
  if (endHour > 24) {
    return ''
  }

  if (hourFormat === HourFormatEnum.twentyFour) {
    const startTime = `${hour.toString().padStart(2, '0')}:${paddedMinute}`
    const endTime = `${endHour.toString().padStart(2, '0')}:${paddedMinute}`
    return `${startTime} to ${endTime}`
  }

  // 12h format
  const startH = hour === 0 || hour === 12 ? 12 : hour % 12
  const endH = endHour === 0 || endHour === 12 ? 12 : endHour % 12
  const startPeriod = hour < 12 ? 'AM' : 'PM'
  const endPeriod = endHour < 12 ? 'AM' : 'PM'

  return `${startH}:${paddedMinute} ${startPeriod} to ${endH}:${paddedMinute} ${endPeriod}`
}

export interface ITimeOption {
  id?: string
  name?: string
  hour?: number
  hour24?: number
  minute?: number
  amPm?: string
}

/**
 * Generates time options for either Slack or Microsoft time pickers in 12 or 24-hour format.
 * Handles business hours (7:00-23:00) and early morning hours (1:00-6:00).
 *
 * @param {boolean | undefined} isMinuteIncrementEnabled - Whether to include 15-minute increments
 * @param {'slack' | 'microsoft'} optionFor - Target platform format
 * @param {HourFormatEnum} [hourFormat=HourFormatEnum.twentyFour] - Hour format (12 or 24-hour)
 * @returns {ITimeOption[]} Array of time options
 *
 * @example
 * // Slack 12-hour format with minutes
 * generateTimeOptions(true, 'slack', HourFormatEnum.twelve)
 * // Returns: [
 * //   { id: '420', name: '7:00', hour24: 7, minute: 0 },
 * //   { id: '435', name: '7:15', hour24: 7, minute: 15 },
 * //   ...
 * // ]
 *
 * @example
 * // Slack 24-hour format without minutes
 * generateTimeOptions(false, 'slack', HourFormatEnum.twentyFour)
 * // Returns: [
 * //   { id: '420', name: '07:00', hour24: 7, minute: 0 },
 * //   { id: '480', name: '08:00', hour24: 8, minute: 0 },
 * //   ...
 * // ]
 *
 * @example
 * // Microsoft 12-hour format with minutes
 * generateTimeOptions(true, 'microsoft', HourFormatEnum.twelve)
 * // Returns: [
 * //   { hour: 7, hour24: 7, minute: 0, amPm: 'AM' },
 * //   { hour: 7, hour24: 7, minute: 15, amPm: 'AM' },
 * //   ...
 * // ]
 */
export const generateTimeOptions = (
  isMinuteIncrementEnabled: boolean | undefined,
  optionFor: 'slack' | 'microsoft',
  hourFormat: HourFormatEnum = HourFormatEnum.twentyFour
): ITimeOption[] => {
  const { MINUTES_INCREMENT } = TIME_CONSTANTS
  const options: ITimeOption[] = []

  // Simple hour range based on format
  // const hoursLength = hourFormat === HourFormatEnum.twelve ? 12 : 24
  const hourSequence = getBusinessHourSequence()

  // for (let hour = 0; hour < hoursLength; hour++) {
  for (const hour of hourSequence) {
    const hour24 = hour
    const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour
    const isPM = hour >= 12
    const displayHour = hourFormat === HourFormatEnum.twentyFour ? hour24 : hour12

    if (isMinuteIncrementEnabled) {
      // Add 15-min increments
      for (let minute = 0; minute < 60; minute += MINUTES_INCREMENT) {
        if (optionFor === 'slack') {
          options.push({
            id: `${hour24 * 60 + minute}`,
            name: `${displayHour}:${minute.toString().padStart(2, '0')}`,
            hour24,
            minute,
          })
        } else {
          options.push({
            hour: displayHour,
            hour24,
            minute,
            ...(hourFormat === HourFormatEnum.twelve && { amPm: isPM ? 'PM' : 'AM' }),
          })
        }
      }
    } else {
      // Just hours
      if (optionFor === 'slack') {
        options.push({
          id: `${hour24 * 60}`,
          name: `${displayHour}:00`,
          hour24,
          minute: 0,
        })
      } else {
        options.push({
          hour: displayHour,
          hour24,
          minute: 0,
          ...(hourFormat === HourFormatEnum.twelve && { amPm: isPM ? 'PM' : 'AM' }),
        })
      }
    }
  }

  return options
}

/**
 * Converts hours and minutes to total minutes, handling both 12h and 24h formats
 * @param {number} hour - Hour value (1-12 for 12h format, 0-23 for 24h format)
 * @param {number} minute - Minutes value (0-59)
 * @param {boolean} is24HourFormat - Whether input is in 24h format
 * @param {string} [amPm] - Optional AM/PM indicator for 12h format
 * @returns {number} Total minutes from midnight
 * @example
 * // 24h format
 * convertToMinutes(13, 30, true)  // Returns: 810 (13:30)
 *
 * // 12h format
 * convertToMinutes(1, 30, false, 'PM')  // Returns: 810 (1:30 PM)
 * convertToMinutes(12, 30, false, 'AM') // Returns: 30 (12:30 AM)
 */
export const convertToMinutes = (hour: number, minute: number, is24HourFormat: boolean, amPm?: string) => {
  if (is24HourFormat) {
    return hour * 60 + minute
  }
  const hour24 = amPm === 'PM' && hour < 12
    ? hour + 12
    : (amPm === 'AM' && hour === 12 ? 0 : hour)
  return hour24 * 60 + minute
}

/**
 * Converts total minutes back to hours and minutes
 * @param {number} totalMinutes - Minutes from midnight
 * @returns {{hours: number, minutes: number}} Object containing hours and minutes
 * @example
 * convertTotalMinutesToHoursAndMinutes(90)  // Returns: { hours: 1, minutes: 30 }
 * convertTotalMinutesToHoursAndMinutes(810) // Returns: { hours: 13, minutes: 30 }
 */
export const convertTotalMinutesToHoursAndMinutes = (totalMinutes: number) => ({
  hours: Math.floor(totalMinutes / 60),
  minutes: totalMinutes % 60,
})

/**
 * Validates if a given time slot is valid for a half-day leave request
 * Half-day leaves must:
 * - Start during business hours (7:00-19:00) or early morning (1:00-2:00)
 * - Last exactly 4 hours
 * - Not cross midnight
 *
 * @param {number} hour24 - Start hour in 24-hour format (0-23)
 * @param {number} [minute=0] - Start minute (0-59)
 * @returns {boolean} True if time slot is valid for half-day leave
 *
 * @example
 * // Valid business hours slot
 * isValidHalfDayTimeSlot(9, 0)  // Returns: true (9:00-13:00)
 * isValidHalfDayTimeSlot(15, 30) // Returns: true (15:30-19:30)
 *
 * @example
 * // Valid early morning slot
 * isValidHalfDayTimeSlot(1, 0)  // Returns: true (1:00-5:00)
 *
 * @example
 * // Invalid: Outside business hours
 * isValidHalfDayTimeSlot(22, 0) // Returns: false
 *
 * @example
 * // Invalid: Crosses midnight
 * isValidHalfDayTimeSlot(22, 0) // Returns: false (22:00-02:00)
 */
export const isValidHalfDayTimeSlot = (hour24: number, minute = 0): boolean => {
  const startTimeInMinutes = (hour24 * 60) + minute
  const endTimeInMinutes = startTimeInMinutes + (4 * 60)

  return (
    // Day/evening hours (7:00-23:00)
    ((hour24 >= 7 && hour24 <= 19) ||
     // Early morning (1:00-2:00)
     (hour24 >= 1 && hour24 <= 2)) &&
    // No overnight blocks
    endTimeInMinutes <= (24 * 60) // Allow up to midnight
  )
}

/**
 * Generates business hours sequence (7AM-11PM, 1AM-6AM)
 * @returns {number[]} Array of hours in 24h format
 */
export const getBusinessHourSequence = (): number[] => [
  ...Array.from({ length: 17 }, (_, i) => i + 7),  // 7:00-23:00 (7AM-11PM)
  ...Array.from({ length: 6 }, (_, i) => i + 1),   // 1:00-6:00 (1AM-6AM)
]

import { PlatformEnum } from '../data/platforms'
import { EventTypeEnum, ICoreEvent, Platform } from './core-event'
import { IMicrosoftUser } from './dynamodb/microsoft-ids-repository'
import { IGoogleUserId } from './google'
import { LocaleEnum } from './i18n'
import { QuotaUnits } from './leave-policy'
import { IMicrosoftUserDetails } from './microsoft'
import { ISlackUserDetails } from './slack'

export type UserStatus = 'ACTIVE' | 'INACTIVE' | 'DELETED'
export type UserRole = 'Admin' | 'Approver' | 'User'

export enum UserImageContentTypeEnum {
  jpeg = 'image/jpeg',
  png = 'image/png',
  gif = 'image/gif',
}

export interface IUser {
  companyId: string
  id: string
  name: string
  email: string
  startDate: string
  platform: Platform
  status: UserStatus
  teamId: string
  locationId: string
  isAdmin: boolean
  initialUser: boolean
  imageUrl?: string
  endDate?: string
  isNameLocked?: boolean
  employeeId?: string
  isAnnounced?: boolean
  locale?: LocaleEnum
  hourFormat?: HourFormatEnum
}

export type IUserMap = { [key: string]: IUser }

export interface IUserLeaveType {
  userId: string
  leaveTypeId: string
  daysPerYear: number
  hoursPerYear?: number
  quotaSetIn?: QuotaUnits
  hasUnlimitedDays: boolean
  ignoreRolloverFromPreviousYear?: boolean
}

export interface IUserLeaveTypeRollover {
  userId: string
  leaveTypeId: string
  initialRollover: boolean
  customRolloverDays: number
}

export interface ICognitoUser {
  id: string
  email: string
  platform: string
  companyId: string
}

export interface IUserStatusChangedEvent extends ICoreEvent {
  eventType: 'USER_STATUS_CHANGED'
  eventGroup: 'USER'
  userId: string
  status: UserStatus
  email?: string // only for first activation, because Cognito requires it
}

export interface IUserUpdatedEvent extends ICoreEvent {
  eventType: 'USER_UPDATED'
  eventGroup: 'USER'
  userId: string
  name: string
  email: string
  startDate?: string
  isAdmin: boolean
  changeEmail?: boolean
  initialUser: boolean
  endDate?: string
  imageUrl?: string
  timezone?: string
  status?: UserStatus
  isNameLocked?: boolean
  slack?: ISlackUserDetails
  employeeId?: string
  announce?: 'none' | 'email' | 'chat'
  locale?: LocaleEnum
  defaultDepartment?: string
  defaultLocation?: string
  hourFormat?: HourFormatEnum
  microsoft?: IMicrosoftUserDetails
  google?: IGoogleUserId
}

export interface IMicrosoftUsersUpdatedBatchEvent extends ICoreEvent {
  eventType: 'MICROSOFT_USERS_UPDATED_BATCH'
  eventGroup: 'BULK_ACTIONS'
  users: Pick<IMicrosoftUser, 'vacationTrackerId' | 'microsoftUserId' | 'organizationId' | 'teamId'>[]
}

export interface IUsersInvitedEvent extends ICoreEvent {
  eventType: 'USERS_INVITED'
  eventGroup: 'USER'
  emails: string[]
  code?: string
  locale?: LocaleEnum
}

export interface IUserInviteDeletedEvent extends ICoreEvent {
  eventType: 'USER_INVITATION_DELETED'
  eventGroup: 'USER'
  email: string
}

export interface IUserInviteAcceptedEvent extends ICoreEvent {
  eventType: 'USER_INVITATION_DELETED'
  eventGroup: 'USER'
  userId: string
  code: string
}

export interface IUserLeaveTypesUpdatedEvent extends ICoreEvent {
  eventType: 'USER_LEAVE_TYPES_UPDATED'
  eventGroup: 'USER'
  userId: string
  leaveTypeId: string
  hasUnlimitedDays: boolean
  currentYearOnly: boolean
  ignoreRolloverFromPreviousYear: boolean
  seniorityEntitlementDaysForYear?: number
  seniorityEntitlementHoursForYear?: number
  entitlementByRoleDaysForYear?: number
  entitlementByRoleHoursForYear?: number
  initialRollover?: boolean
  customRolloverDays?: number
  shouldProrate?: boolean
  proratedLeaveTypes?: string[]
  daysPerYear: number
  hoursPerYear?: number
  validityStartForQuota: string
  quotaSetIn?: QuotaUnits
}

export interface IUserLeaveTypesDeletedEvent extends ICoreEvent {
  eventType: 'USER_LEAVE_TYPES_DELETED'
  eventGroup: 'USER'
  userId: string
  leaveTypeId: string
}

export interface IUserLeaveTypesRolloverUpdatedEvent extends ICoreEvent {
  eventType: 'USER_LEAVE_TYPES_ROLLOVER_UPDATED'
  eventGroup: 'USER'
  userId: string
  leaveTypeId: string
  initialRollover: boolean
  customRolloverDays: number
  customRolloverHours?: number
  quotaSetIn?: QuotaUnits
}

export interface IUserLeaveTypesRolloverDeletedEvent extends ICoreEvent {
  eventType: 'USER_LEAVE_TYPES_ROLLOVER_DELETED'
  eventGroup: 'USER'
  userId: string
  leaveTypeId: string
}

export interface ILocationUserMoved extends ICoreEvent {
  eventType: 'LOCATION_USER_MOVED'
  eventGroup: 'USER'
  userId: string
  locationId: string
}

export interface ITeamUserMoved extends ICoreEvent {
  eventType: 'TEAM_USER_MOVED'
  eventGroup: 'USER'
  userId: string
  teamId: string
}

export interface IUserImageRefreshEvent extends ICoreEvent {
  eventType: 'USER_IMAGE_REFRESH'
  eventGroup: 'USER'
  userId: string
  /**
   * Required when platform is microsoft
   */
  token?: string
  /**
   * When the platform is email, just ask the user for an image link so we can save it
   */
  imageUrl?: string
}

export interface IUserSeniorityEntitlementEvent extends ICoreEvent {
  eventType: 'USER_SENIORITY_ENTITLEMENT' | 'USER_SENIORITY_ENTITLEMENT_OVERRIDE'
  eventGroup: 'USER'
  userId: string
  days: number
  units?: QuotaUnits
  hours?: number
  automationId: string
  leaveTypeId: string
  prorateType: string
  receivedProratedAmount?: number
  years?: number
}

export interface IUserEntitlementByRoleEvent extends ICoreEvent {
  eventType: 'USER_ENTITLEMENT_BY_ROLE'
  eventGroup: 'USER'
  userId: string
  days: number
  hours: number
  units: QuotaUnits
  automationId: string
  leaveTypeId: string
  labelId: string
}

export interface IUserEntitlementByRoleOverrideEvent extends ICoreEvent {
  eventType: 'USER_ENTITLEMENT_BY_ROLE_OVERRIDE'
  eventGroup: 'USER'
  userId: string
  days: number
  hours: number
  units: QuotaUnits
  leaveTypeId: string
}

export interface IUserEntitlementByRoleDeleteEvent extends ICoreEvent {
  eventType: 'USER_ENTITLEMENT_BY_ROLE_DELETED'
  eventGroup: 'USER'
  automationId: string
  userId: string
  leaveTypeId: string
  labelId: string
  days: number
  hours: number
  units: QuotaUnits
}

export interface  ISyncExistingUser {
  companyId: string
  token: string
  userId: string
  creator: string
  correlationId: string
  organizationId?: string
  isMicrosoftTeamBased?: boolean
}

export interface ISendNotificationToAdmins {
  companyId: string
  userId: string
  correlationId: string
  teamId: string
}

export interface ISendNotificationToSubstitute {
  companyId: string
  userId: string
  notificationType: 'created' | 'deleted' | 'updated'
  selectedBy?: string
  creatorId?: string
  correlationId: string
  teamIds?: string[]
  startDate?: string
  endDate?: string
}

export interface IImportUser {
  id: string
  name: string
  email: string
  msUserId?: string
  slackId?: string
  googleId?: string
  isOwner?: boolean
  isAdmin?: boolean
  image?: string
  announce?: string
}


export interface IEmailInvite {
  companyId: string
  email: string
  status: EmailInviteStatusEnum
  timestamp: string
  code: string
  invitedBy: {
    id: string
    name: string
  }
  name?: string
}

export enum EmailInviteStatusEnum {
  pending = 'pending',
  accepted = 'accepted',
  expired = 'expired'
}

export interface IUserLeaveTypesProratingUpdatedEvent extends ICoreEvent {
  eventType: 'USER_LEAVE_TYPES_PRORATING_UPDATED'
  eventGroup: 'USER'
  userId: string
  allow: boolean
  leaveTypes: string[]
}

export interface IOutOfOfficeSettingsParams {
  companyId: string
  userId: string
  oooPlatform: string
  snoozedLeaveTypes: string[]
  active: boolean
  refreshToken: string
  msTenantId?: string
  googleUserId?: string
  timezone?: string
  outlookEmail?: string
}

export interface IUserOutOfOfficeSettingsUpdatedEvent extends ICoreEvent {
  eventType: EventTypeEnum.USER_OUT_OF_OFFICE_SETTINGS_UPDATED
  eventGroup: 'USER'
  userId: string
  oooPlatform: OutOfOfficePlatform
  snoozedLeaveTypes: string[]
  active: boolean
  refreshToken: string
  msTenantId?: string
  googleUserId?: string
  timezone?: string
  outlookEmail?: string
}

export type OutOfOfficePlatform = 'gmail' | 'outlook' | string

export interface IUserOpenApiResponse {
  data: IUserOpenApi[]
  nextToken?: string
}
export interface IUserOpenApi {
  id: string
  name: string
  email: string
  isAdmin: boolean
  locationId: string
  teamId: string
  status: UserStatus
  employeeId?: string
  startDate: string
  endDate?: string
  labels?: string[]
}

export interface IUserPlatformUpdatedEvent extends ICoreEvent {
  eventType: 'USER_PLATFORM_UPDATED'
  eventGroup: 'USER'
  userId: string
  email?: string
  newPlatform: PlatformEnum
}

export enum HourFormatEnum {
  twelve = 'twelve',
  twentyFour = 'twentyFour'
}
import { Tooltip } from 'antd'
import { UnlockOutlined } from '@ant-design/icons'
import React from 'react'
import { IQuotaBoxLockedProps } from './types'
import IntlMessages from '../utils/IntlMessages'
import { LeaveDisplayFormatEnum } from '../../types/display-in-days-hours'
import { IQuotaAmount } from '../../types/leave-policy'
import { useIntl } from 'react-intl'

import { getFormattedDaysAndHours } from '../../functions/get-days-or-hours'

const QuotaBox = ({ isLocked, title, lockedTooltip, dataTestId, footer, value }: IQuotaBoxLockedProps): React.ReactElement => {
  return (
    <Tooltip title={isLocked ? lockedTooltip : ''}>
      <div className={`quota quota-box ${isLocked ? 'lock' : ''}`}>
        {isLocked && (
          <div className='quota-card-lock'>
            <div><UnlockOutlined /><br /><IntlMessages id='components.userLeaveQuotas.unlock' /></div>
          </div>
        )}
        <div className='quota-box-title' data-testid={`${dataTestId}-title`}>{title}</div>
        <div className='quota-box-value' data-testid={`${dataTestId}-value`}>{formatValue(value)}</div>
        <div className='quota-box-footer' data-testid={`${dataTestId}-footer`}>{footer}</div>
      </div>
    </Tooltip>
  )
}

function formatValue(value: IQuotaAmount): React.ReactElement {
  const { formatMessage } = useIntl()
  return <>{getFormattedDaysAndHours({ leave: value, formatMessage, displayFormat: LeaveDisplayFormatEnum.short})}</>
}

export default QuotaBox

import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { App, Breadcrumb } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useManualQuery } from 'graphql-hooks'
import { useFeatureFlagEnabled } from 'posthog-js/react'

import Api from '@vacationtracker/shared/services/api'
import {
  getLeaveTypesForUser, getLeaveRequestData, getConnectedCalendars, getTeamsForApprover, getAutomations, getSuggestedSubstituteApprovers
} from '../../../graphql/custom-queries'
import { notificationStore } from '../../../context/notificationsContext/store'
import * as logger from '../../../services/logger'

import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import LeaveForm from '@vacationtracker/shared/components/leave-form'
import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '../../../util/IntlMessages'

import {
  IGetLeaveRequest, IGetLeaveRequestData, IUserLeaveRequest, IGetConnectedCalendarsResponse, IGetAutomations, IGetUsersForAdminAddLeaveData
} from '../../../types/custom-queries'
import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import { IConnectedGoogleCalendar, IConnectedOutlookCalendar } from '@vacationtracker/shared/types/calendar'
import { GetTeamsForApproverData, IGetLeaveTypesForUserLeaveRequestData } from '@vacationtracker/shared/components/add-request-leave-additional-info/types'
import { useSubstituteApprover } from '../../../util/hooks/use-substitute-approver'
import { FrontendUrls } from '../../../types/urls'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'

interface IEditLeavePage {
  match: {
    params: {
      id: string
      userId: string
    }
  }
}

const EditLeavePage = ({ match }: IEditLeavePage): React.ReactElement => {
  const { notification } = App.useApp()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const enableMinutePickerLeave = useFeatureFlagEnabled(FeatureFlagEnum.enableMinutePickerLeave)

  const [createLoader, setCreateLoader] = useState(false)
  const [user, setUser] = useState<IUserLeaveRequest>({
    id: '',
    name: '',
    location: {
      id: '',
      timezone: '',
      leavePolicies: [],
    },
  })
  const [leaveRequestId, setLeaveRequestId] = useState(match.params.id)
  const [userId, setUserId] = useState(match.params.userId)
  const [leaveRequest, setLeaveRequest] = useState<IGetLeaveRequest>()
  const [isLoading, setIsLoading] = useState(true)
  const [connectedGoogleCalendar, setConnectedGoogleCalendar] = useState<IConnectedGoogleCalendar | null>(null)
  const [connectedOutlookCalendar, setConnectedOutlookCalendar] = useState< IConnectedOutlookCalendar | null>(null)
  const [getTeamsForApproverQuery] = useManualQuery<GetTeamsForApproverData, {
    id: string
    date: string
  }>(getTeamsForApprover)
  const [getAutomationsQuery] = useManualQuery<IGetAutomations>(getAutomations)
  const [getSuggestedSubstituteApproversQuery] = useManualQuery<IGetUsersForAdminAddLeaveData>(getSuggestedSubstituteApprovers)
  const {
    setCheckIfSubstituteNeeded,
    suggestedSubstituteApprovers,
    shouldShowSubstituteApprovers,
    isSubstituteListLoading,
  } = useSubstituteApprover(
    {
      userId,
      startDate: leaveRequest?.startDate,
      endDate: leaveRequest?.endDate,
    },
    {
      getTeamsForApproverQuery,
      getAutomationsQuery,
      getSuggestedSubstituteApproversQuery,
    }
  )

  const history = useHistory()

  const [ getLeaveRequestDataQuery ] = useManualQuery<IGetLeaveRequestData, { leaveRequestId: string }>(getLeaveRequestData)
  const [ getConnectedCalendarsQuery ] = useManualQuery<IGetConnectedCalendarsResponse, { userId: string }>(getConnectedCalendars)
  const [ getLeaveTypesForUserQuery ] = useManualQuery<IGetLeaveTypesForUserLeaveRequestData, { id: string }>(getLeaveTypesForUser)
  const [isMinutePickerEnabled, setIsMinutePickerEnabled] = useState(false)

  useEffect(() => {
    if (typeof enableMinutePickerLeave === 'undefined') {
      return
    }
    setIsMinutePickerEnabled(enableMinutePickerLeave)
  }, [enableMinutePickerLeave])

  useEffect(() => {
    fetchData(match.params.id)
    setLeaveRequestId(match.params.id)
  }, [match.params.id])

  const fetchData = async (leaveRequestId: string) => {
    try {
      const leaveRequestResponse = await getLeaveRequestDataQuery({ variables: { leaveRequestId }})
      if (!leaveRequestResponse.data || leaveRequestResponse.error) throw leaveRequestResponse.error
      const connectedCalendarsResponse = await getConnectedCalendarsQuery({ variables: { userId: authUser.id }})
      if (!connectedCalendarsResponse.data || connectedCalendarsResponse.error) throw connectedCalendarsResponse.error
      if (connectedCalendarsResponse.data?.getConnectedGooogleCalendar?.calendarId) {
        setConnectedGoogleCalendar(connectedCalendarsResponse.data.getConnectedGooogleCalendar)
      }
      if (connectedCalendarsResponse.data?.getConnectedOutlookCalendar?.calendarId) {
        setConnectedOutlookCalendar(connectedCalendarsResponse.data.getConnectedOutlookCalendar)
      }

      setLeaveRequest(leaveRequestResponse.data.getLeaveRequest)

      const userIdLocal = leaveRequestResponse.data.getLeaveRequest.userId
      setUserId(userIdLocal)

      const userResponse = await getLeaveTypesForUserQuery({ variables: { id: userIdLocal }})
      if (!userResponse.data || userResponse.error) throw userResponse.error
      setUser(userResponse.data.getUser)
      if(leaveRequestResponse.data.getLeaveRequest.substituteApproverId) {
        setCheckIfSubstituteNeeded(true)
      }
      setIsLoading(false)
    } catch (err) {
      logger.error('error fetching leave types list', err)
    }
  }

  const handleSubmit = async (data: ILeaveFormSaveData) => {
    setCreateLoader(true)
    if (!data.reason) {
      data.reason = ''
    }
    let response
    try {
      response = await Api.post('/core/leave-request-validate', {
        eventType: 'LEAVE_REQUEST_UPDATED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
        leaveRequestId,
        userId,
      })
      response = await Api.post('/core/event', {
        eventType: 'LEAVE_REQUEST_UPDATED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
        leaveRequestId,
        userId,
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'editLeave.inProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setCreateLoader(false)
      history.goBack()
    } catch (error) {
      logger.debug(error)
      setCreateLoader(false)
      if (error?.response?.data?.code === 'BLACKOUT_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage(
            { id: 'automations.blackoutPeriodFromTo' },
            {
              fromDate: dayjs(data.startDate).format('MMMM Do YYYY.'),
              toDate: dayjs(data.endDate).format('MMMM Do YYYY.'),
              endDate: (...chunks) => data.startDate !== data.endDate ? <>{chunks}</> : '',
            }
          ),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error?.response?.data?.code === 'PROBATION_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage({ id: 'automations.probationPeriodMessages' }, { date: dayjs(data.date as string).format('MMMM Do YYYY.')}),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'editLeave.handleSubmitErrorTitle' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'editLeave.handleSubmitErrorTitle' }),
          description,
          duration: 0,
        })
      }
    }
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id='app.editLeave' />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id='app.editLeave' />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {isLoading ?
          <CircularProgress /> :
          <LeaveForm
            authUserId={authUser.id}
            authUserRole={authUser.role}
            hourFormat={authUser.hourFormat}
            loading={createLoader}
            listOfUsers={[user]}
            onSave={(data: ILeaveFormSaveData) => {
              (async () => {
                await handleSubmit(data)
              })()
            }}
            onCancel={() => { history.goBack(); history.goBack() }}
            formType={'edit'}
            isMinutePickerEnabled={isMinutePickerEnabled}
            leaveRequest={leaveRequest}
            substituteApprovers={suggestedSubstituteApprovers}
            shouldShowSubstituteApprovers={shouldShowSubstituteApprovers}
            isSubstituteListLoading={isSubstituteListLoading}
            // TODO: disable calendar status editing for now
            // connectedGoogleCalendar={connectedGoogleCalendar}
            // connectedOutlookCalendar={connectedOutlookCalendar}
          />
        }
      </div>
    </div>
  )
}

export default EditLeavePage

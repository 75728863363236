{
  "accountSettings.connectYourPersonalCalendar": "Kişisel takviminizi bağlayın",
  "accountSettings.connectYourPersonalCalendar.description": "Kişisel takviminizi izinlere otomatik olarak bağlayın. İzin talep ettiğinizde, istenen iznin takviminizde müsait veya meşgul olarak görünmesini ayarlayabilirsiniz. Daha fazla bilgi için <helpdesk>Yardım Masası</helpdesk>'mızı ziyaret edin.",
  "actionNotifications.companyAnnouncement.success": "Duyuru gönderildi",
  "actionNotifications.leave.editedRequestDenied": "İzin talebi düzenleme reddedildi.",
  "actionNotifications.leave.editedRequestDeniedDescription": "Düzenlenen izin talebiniz reddedildi, ancak orijinal talebiniz aktif durumda kalmaya devam ediyor.",
  "actionNotifications.leave.requestApproved": "İzin Talebi onaylandı",
  "actionNotifications.leave.requestDenied": "İzin talebi reddedildi.",
  "actionNotifications.leaveType.created": "{leaveTypeName} izin türü oluşturuldu",
  "actionNotifications.leaveType.deleted": "İzin türü silindi",
  "actionNotifications.leaveType.updated": "{leaveTypeName} izin türü güncellendi",
  "actionNotifications.location.createdTitle": "{locationName} konumu oluşturuldu",
  "actionNotifications.location.deletedTitle": "Konum silindi",
  "actionNotifications.location.updatedTitle": "{locationName} konumu güncellendi",
  "actionNotifications.location.view": "Konumu görüntüle",
  "actionNotifications.notification.created": "Bildirim oluşturuldu",
  "actionNotifications.notification.deleted": "Bildirim silindi",
  "actionNotifications.notification.updated": "Bildirim güncellendi",
  "actionNotifications.outOfOfficeSetting.success": "Ofis dışı ayarınız güncellendi",
  "actionNotifications.scheduledReport.createdTitle": "{scheduledReportName} planlı raporu oluşturuldu",
  "actionNotifications.scheduledReport.deletedTitle": "Planlı rapor silindi",
  "actionNotifications.scheduledReport.updatedTitle": "{scheduledReportName} planlı raporu güncellendi",
  "actionNotifications.scheduledReport.viewScheduledReport": "Planlı raporu görüntüle",
  "actionNotifications.team.createdTitle": "{teamName} departmanı oluşturuldu",
  "actionNotifications.team.deletedTitle": "Departman silindi",
  "actionNotifications.team.updatedTitle": "{teamName} departmanı güncellendi",
  "actionNotifications.team.view": "Departmanı görüntüle",
  "addLeave.addLeave": "İzin Ekle",
  "addLeave.handleSubmitErrorTitle": "İzin Gönderme Hatası",
  "addLeave.inProgress": "İzin ekleniyor",
  "addLeave.noApproverUsers": "Bir departmanın parçası değilseniz, kendiniz için izin ekleyemezsiniz. Onay yetkileriniz olan bir departmanın parçası olmasanız bile izin talep etmek için \"İzin talep et\" formunu açın ve bir izin talebi gönderin.",
  "addLeave.title": "İzin eklendi",
  "addons.openApi.apiKeyName": "API Anahtarı Adı",
  "addons.openApi.apiKeyNamePlaceholder": "API anahtarı adı",
  "addons.openApi.apiKeySecret": "API Anahtarı Gizli Kodu",
  "addons.openApi.apiKeysInfo": "API anahtarlarınızı oluşturun ve yönetin.",
  "addons.openApi.apiKeysTitle": "API Anahtarları",
  "addons.openApi.buyAppiAddon": "API eklentisini satın al",
  "addons.openApi.buyOpenApiInfoPrice": "API eklentisi, aktif kullanıcı başına aylık {price}$ maliyeti olan ücretli bir eklentidir. Satın almanız, {userCount} aktif {userCount, plural, =1 {kullanıcı} other {kullanıcınız}} için aylık faturanızı {totalPaid}$ artıracaktır (\"Satın Al\" düğmesine tıkladığınızda size kesin tahmini vereceğiz).",
  "addons.openApi.buyOpenApiInfoPrice2line": "Eklentiyi satın aldıktan sonra, siz veya kuruluşunuzdaki herhangi bir yönetici API anahtarınızı oluşturabilir ve yönetebilir.",
  "addons.openApi.buyOpenApiTitle": "API anahtarları oluşturmak için API eklentisini satın alın",
  "addons.openApi.createInProgress": "API Anahtarı oluşturma devam ediyor",
  "addons.openApi.createInProgressCompleted": "API Anahtarı oluşturma tamamlandı",
  "addons.openApi.createNewApiKey": "Yeni API Anahtarı Oluştur",
  "addons.openApi.currentMonthUsage": "Bu Ayki Kullanım",
  "addons.openApi.documentation": "API Dokümantasyonu",
  "addons.openApi.documentationInfo": "API dokümantasyonu, API'mizin nasıl çalıştığını ve şu anda mevcut olan yöntemleri açıklar.",
  "addons.openApi.dontHaveApiKeys": "API anahtarınız yok, bir tane oluşturmak için aşağıdaki butona tıklayın",
  "addons.openApi.dpenDocumentation": "Dokümantasyonu Aç",
  "addons.openApi.feedback": "Geri bildiriminiz varsa veya API'mizin sunmadığı verilere ihtiyacınız varsa, <link>bize bildirmek için buraya tıklayın</link>.",
  "addons.openApi.limitRequestPerMonth": "Ayda 30000'e kadar istek gönderebilirsiniz",
  "addons.openApi.limitRequestPerSecound": "İstekler, 50 istek/saniye ani artış moduyla saniyede 10 ile sınırlıdır",
  "addons.openApi.notificationDeleteConfirm": "Lütfen <strong>{name}</strong> API Anahtarını silmek istediğinizi onaylayın.",
  "addons.openApi.notificationDeleteTitle": "API Anahtarını Sil",
  "addons.openApi.openApiDeleted": "Açık API Anahtarı silindi",
  "addons.openApi.quotasAndRateLimitsInfo": "API anahtarı başına API kotalarımız ve oran sınırlarımız şunlardır:",
  "addons.openApi.quotasAndRateLimitsTitle": "Kotalar ve oran sınırları",
  "addons.openApiInfo": "API eklentimiz, Vacation Tracker'ı diğer uygulamalara programlı olarak bağlamanıza olanak tanır. Şu anda, API'miz kullanıcılarınıza, izinlerine, konumlarına, departmanlarına, izin türlerine ve etiketlerine salt okunur erişim sağlar.",
  "addonsPaymentStatus.activateForFree": "Ücretsiz etkinleştir",
  "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Fatura dönemi sonuna kadar aktif",
  "addonsPaymentStatus.canceled": "İptal edildi",
  "addonsPaymentStatus.grantPeriod": "Hibe dönemi",
  "addonsPaymentStatus.paymentActive": "Ödeme aktif",
  "ai.assistant.completedConversationTooltip": "Bu konuşma tamamlandı. Yeni mesaj gönderemezsiniz, ancak mevcut konuşmayı görebilirsiniz.",
  "ai.assistant.defaultMessage": "Size nasıl yardımcı olabilirim?",
  "ai.assistant.disclaimer": "AI Asistan Beta aşamasındadır. Hatalar yapabilir. Hatalar veya kafa karıştırıcı yanıtlar durumunda lütfen <support>desteğimizle iletişime geçin</support>.",
  "ai.assistant.inputLockedTooltip": "Bu, AI Asistanımızın erken sürümüdür. Şu anda sadece başlangıç sürecinde size yardımcı olur. Ancak yakında yeni konuşmalar başlatabilecek ve Vacation Tracker AI Asistanı ile konuşabileceksiniz. Sabrınız için teşekkürler!",
  "ai.assistant.lastMessageTimestampTooltip": "Son mesaj gönderilme tarihi: {date}",
  "ai.assisted.onboarding.companyLogUserNameDecorator": "(AI Asistan aracılığıyla)",
  "ai.assisted.onboarding.finalAssistantMessage": "Başlangıç süreciniz tamamlandı. Vacation Tracker şimdi şirket hesabınızı bu yapılandırma ile ayarlayacak. Unutmayın, daha sonra kurulumunuzda her zaman değişiklik yapabilirsiniz.",
  "ai.assisted.onboarding.initialAssistantMessage": "Kayıt olurken, sizin için bazı şeyleri zaten ayarladık:\n\nKonumlar ({locations}), \nDepartmanlar ({departments}), ve \nİzin Türleri ({leaveTypes}).\n\nTüm çalışanlarınız bu konumda mı çalışıyor?",
  "ai.assisted.onboarding.initialUserMessage": "Merhaba! Ben sizin AI Asistanınızım. Yeni şirket hesabınızla başlamanıza yardımcı olmak için buradayım.\n\nBu AI kurulumu elbette isteğe bağlıdır. Bunu atlayabilir ve sistemi kendiniz kurabilirsiniz. (Muhtemelen menümüzün \"Ayarlar\" bölümünden başlamak istersiniz). Ancak, ben sizi Vacation Tracker'ın birkaç temel kısmı boyunca yönlendireceğim ve her şeyi birkaç dakika içinde sizin için ayarlayacağım. Özel verilerinizi saklamıyoruz (ama lütfen benimle hassas bilgiler paylaşmayın).",
  "ai.assisted.onboarding.noTopics": "Hey, görünüşe göre bir Paskalya yumurtası buldunuz! 🐣 Size çok zaman kazandıracak yeni bir şey üzerinde çalışıyoruz, ancak henüz hazır değil. Bu yüzden, lütfen bunu küçük sırrımız olarak saklayalım. Teşekkürler!",
  "ai.assisted.onboarding.openSetupNotification": "Şimdiye kadar yapılanları gör",
  "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Gerekli tüm bilgileri sağladığınız için teşekkürler. Şimdi Vacation Tracker kurulumunuzu oluşturmaya başlıyorum. Canlı güncellemeleri her an görmeye başlayacaksınız...",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "{leaveTypeName} izin türü {locationName} konumuna atandı",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "{leaveTypeName} izin türü {locationName} konumuna atanıyor",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} başarıyla {action}",
  "ai.assisted.onboarding.openSetupNotificationNoData": "Kurulum verileri görüntülenemiyor",
  "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "Şu anda kurulum verilerini gösteremiyorum. Bu, kısa bir ağ kesintisi nedeniyle veya sistem hala verileri işlemeyi bitirmediği için olabilir. Lütfen bu sayfayı yenileyin veya menünün Ayarlar bölümündeki ayarları kontrol edin.",
  "ai.assisted.onboarding.openSetupNotificationTitle": "Başlangıç Kurulum Durumu",
  "ai.assisted.onboarding.sendMessageError": "Görünüşe göre AI Asistanın yanıtı çok uzun sürüyor. Lütfen sayfayı yenileyin.",
  "ai.assisted.onboarding.summary.intro": "Artık her şey ayarlandı. Şimdi paneli açıp uygulamayı kullanmaya başlayabilirsiniz.",
  "ai.assisted.onboarding.summary.introSummary": "İşte kurulumunuzun özeti:",
  "ai.assisted.onboarding.summary.leavePoliciesInfo": "İzin politikaları, bir konuma özgü izin türü yapılandırmalarıdır. Oluşturulan izin politikalarını görmek için konumlar sayfasına gidin, konum seçin ve ardından izin politikaları sekmesini açın.",
  "ai.assisted.onboarding.summary.view.departments": "Departmanları Görüntüle",
  "ai.assisted.onboarding.summary.view.leaveTypes": "İzin Türlerini Görüntüle",
  "ai.assisted.onboarding.summary.view.locations": "Konumları Görüntüle",
  "app.12h": "12 saat",
  "app.24h": "24 saat",
  "app.aboutMe": "Hakkımda:",
  "app.accountPreferences": "Tercihleri Görüntüle",
  "app.accountSettings": "Hesap Ayarları",
  "app.accruals": "Tahakkuklar",
  "app.actions": "Eylemler",
  "app.activate": "Etkinleştir",
  "app.active": "Aktif",
  "app.add": "Ekle",
  "app.addAvatar": "Avatar Ekle",
  "app.addLeave": "İzin Ekle",
  "app.addNewEmail": "Alıcı Ekle",
  "app.addons": "Eklentiler",
  "app.addToGoogleCalendar": "Google Takvim",
  "app.addToMicrosoft365Calendar": "Microsoft 365 Takvim",
  "app.AIAssistant": "AI Asistanı",
  "app.all": "Tümü",
  "app.allDay": "Tüm Gün",
  "app.allowHalfDays": "Yarım Günlere İzin Ver",
  "app.allowHourlyIncrements": "Saatlik Artışlara İzin Ver",
  "app.and": "ve",
  "app.announcementsHaveBeenPaused": "Duyurularınız 2 ay boyunca duraklatıldı.",
  "app.applyTo": "Uygula",
  "app.approvalReason": "Onay nedeni",
  "app.approvalRequired": "Onay Gerekli",
  "app.approve": "Onayla",
  "app.approved": "Onaylandı",
  "app.approveLeaveText": "Lütfen izin talebini onaylamak istediğinizi onaylayın.",
  "app.approveLeaveTitle": "İzin Talebini Onayla",
  "app.april": "Nisan",
  "app.areYouSure": "Emin misiniz?",
  "app.askMeLater": "Daha sonra sor",
  "app.assignLeavePolicy": "İzin Politikası Ata",
  "app.august": "Ağustos",
  "app.autoApproved": "Otomatik onaylandı",
  "app.autoDeleted": "<Otomatik Silindi>",
  "app.autoImport": "<Otomatik İçe Aktarma>",
  "app.automations": "Otomasyonlar",
  "app.avatar": "Profil Resmi",
  "app.back": "Geri",
  "app.backToMenu": "Menüye dön",
  "app.bi-weekly": "İki haftada bir",
  "app.billing.periodAnnual": "Yıllık",
  "app.billingStatus": "Fatura Durumu",
  "app.billingStatus.ACTIVE": "Ödeme aktif",
  "app.billingStatus.CANCEL_AT_PERIOD_END": "Fatura dönemi sonuna kadar aktif",
  "app.billingStatus.CANCELED": "Ödeme iptal edildi",
  "app.billingStatus.GRANT_PERIOD": "Hibe dönemi",
  "app.broughtForward": "Devredilen",
  "app.by": "tarafından",
  "app.calendar": "Takvim",
  "app.cancel": "İptal",
  "app.cancelLeave": "İzni İptal Et",
  "app.cancelLeaveConfirmText": "Bu planlanmış izni iptal etmek istediğinizden emin misiniz?",
  "app.cancelled": "İptal edildi",
  "app.change": "Değiştir",
  "app.changePassword": "Şifre değiştir",
  "app.close": "Kapat",
  "app.code": "Kod",
  "app.color": "Renk",
  "app.comingSoon": "Yakında",
  "app.company": "Şirket",
  "app.companyLogs": "Şirket Kayıtları",
  "app.companyNotExist": "Ups! Görünüşe göre Vacation Tracker ile kaydınızı tamamlamadınız. Lütfen kaydınızı tamamlamak için app.vacationtracker.io adresini ziyaret edin.",
  "app.companyNotExist.description1": "Şimdi Vacation Tracker botunu ve sekmesini Microsoft Teams çalışma alanınıza eklediniz.",
  "app.companyNotExist.description2": "Lütfen kaydınızı tamamlamak ve organizasyon ayarlarınızı yapılandırmak için web sitemizi ziyaret edin.",
  "app.companyNotExist.getStarted": "Kaydı Tamamla",
  "app.completed": "Tamamlandı",
  "app.completeRegistration": "Kaydı Tamamla",
  "app.configuration.moreInfo": "Daha fazla bilgi için yardım merkezimizi ziyaret edin: <link>https://vacationtracker.crisp.help/en/</link>",
  "app.configuration.option.overview": "Genel Bakış",
  "app.configuration.options.info": "Sekme türünü seçin: ",
  "app.configuration.welcome": "Vacation Tracker'a hoş geldiniz! Bu sekme, Microsoft Teams'ten çıkmadan kolayca izin talep etmenizi, izinlerinizi yönetmenizi, izin durumlarınızı kontrol etmenizi, kimin ofis dışında olduğunu görmenizi ve ekibiniz için yaklaşan tatilleri görmenizi sağlar.",
  "app.configure": "Yapılandır",
  "app.confirm": "Onayla",
  "app.confirmNewPassword": "Yeni Şifreyi Onayla",
  "app.confirmPassword": "Şifreyi Onayla",
  "app.consentRequired": "Microsoft Teams Yönetici Onayı Gerekli. Microsoft Teams organizasyonunuzun yöneticisi olmadığınız görünüyor. Vacation Tracker sekmesine erişmek için yönetici onayına ihtiyacınız olacak. Lütfen yönetici onayını almak için aşağıdaki bağlantıyı organizasyonunuzun yöneticisine gönderin: {link}",
  "app.consentRequired.title": "Microsoft Teams Yönetici Onayı Gerekli",
  "app.contactSupport": "Destek ile iletişime geç",
  "app.continue": "Devam et",
  "app.copyright": "Telif Hakkı VacationTracker",
  "app.correlationIdError": "Bir hata oluştu. Lütfen hello@vacationtracker.io adresinden müşteri desteğimizle iletişime geçin ve şu kimliği belirtin: {correlationId}",
  "app.country": "Ülke",
  "app.create": "Oluştur",
  "app.created": "Oluşturuldu",
  "app.createdAt": "Oluşturulma",
  "app.createdBy": "Oluşturan",
  "app.createdSuccessfully": "Talep gönderildi",
  "app.creating": "Oluşturuluyor",
  "app.creditCard": "Kredi kartı",
  "app.currentYear": "Mevcut Yıl",
  "app.customDays": "Özel Günler",
  "app.customWorkHours": "Özel Çalışma Saatleri",
  "app.daily": "Günlük",
  "app.dashboard": "Gösterge Paneli",
  "app.dateRange": "Başlangıç ve Bitiş Tarihi",
  "app.day": "gün",
  "app.days": "gün",
  "app.december": "Aralık",
  "app.delete": "Sil",
  "app.deleted": "Silindi",
  "app.deleteFailed": "Silme başarısız",
  "app.deleteUser": "Kullanıcıyı Sil",
  "app.deleting": "Siliniyor",
  "app.deliveryFailed": "Teslimat Başarısız",
  "app.denialReason": "Reddetme nedeni",
  "app.denied": "Reddedildi",
  "app.deny": "Reddet",
  "app.denyLeaveRequest": "İzin Talebini Reddet",
  "app.denyLeaveRequestText": "İzin talebini reddetmek istediğinizi lütfen onaylayın.",
  "app.denyWithReason": "Nedenle Reddet",
  "app.department": "Departman",
  "app.departments": "Departmanlar",
  "app.departmentsSub": "Alt departmanlar",
  "app.deselectAll": "Tümünün Seçimini Kaldır",
  "app.displayDaysHoursTooltip.days": "Bu {value} gün",
  "app.displayDaysHoursTooltip.hours": "Bu {value} saat",
  "app.documentation": "Dokümantasyon",
  "app.done": "Tamamlandı",
  "app.dontSend": "Gönderme",
  "app.downgradeWithoutUnsubscribe": "Aboneliği iptal etmeden düşür",
  "app.downgradeWithSubscribe": "Düşür ve aboneliği iptal et",
  "app.download": "İndir",
  "app.downloadProgressReport": "Dosyanız oluşturuluyor, bu biraz zaman alabilir...",
  "app.downloadReady": "Dosyanız hazır. İndirmek için buraya tıklayın.",
  "app.downloadTemplate": "Şablonu İndir",
  "app.edit": "Düzenle",
  "app.edited": "Düzenlendi",
  "app.editLeave": "İzni Düzenle",
  "app.email": "E-posta",
  "app.emailDeleted": "E-posta silindi",
  "app.employeeEndDate": "Çalışan Bitiş Tarihi",
  "app.enable": "Etkinleştir",
  "app.enabled": "Etkinleştirildi",
  "app.enterWorkEmail": "İş e-postanızı girin",
  "app.error": "Hata",
  "app.every": "her",
  "app.expired": "Süresi Doldu",
  "app.expires": "Süresi Doluyor",
  "app.export": "Dışa Aktar",
  "app.export.comingSoon": "Ekibimiz bu özelliği geliştiriyor. Yakında kullanıma sunulacak.",
  "app.feature.access": "Yalnızca Complete planında mevcuttur.",
  "app.february": "Şubat",
  "app.firstAndLastName": "Ad ve Soyad",
  "app.friday": "Cuma",
  "app.friday.short": "Cu",
  "app.full-day": "Tam Gün",
  "app.full-name": "Tam Ad",
  "app.general": "Genel",
  "app.goodAfternoon": "İyi günler",
  "app.goodEvening": "İyi akşamlar",
  "app.goodMorning": "Günaydın",
  "app.gotIt": "Anladım",
  "app.half-day": "Yarım gün",
  "app.halfDay": "Yarım Gün",
  "app.halfDayInParenthesis": " (Yarım Gün)",
  "app.happyVacationing": "İyi tatiller 🌴",
  "app.helpdesk": "Yardım Masası",
  "app.holidays": "Tatiller",
  "app.home": "Ana Sayfa",
  "app.hour": "saat",
  "app.hours": "saat",
  "app.import": "İçe Aktar",
  "app.importFile": "Dosya İçe Aktar",
  "app.importFormat": "İçe Aktarma Formatı",
  "app.importLeaves": "İzinleri İçe Aktar",
  "app.importLeaves.alerts.confirmClose.bodyText": "Emin misiniz? Mevcut bilgileriniz kaydedilmeyecek.",
  "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "İptal",
  "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Çıkış",
  "app.importLeaves.alerts.confirmClose.headerTitle": "İçe aktarma akışından çık",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "Hala hata içeren satırlar var. Hata içeren satırlar gönderim sırasında göz ardı edilecek.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "Hala hata içeren satırlar var. Lütfen geçersiz satırları kaldırın veya hataları düzeltin ve tekrar deneyin.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "İptal",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Gönder",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Hatalar Tespit Edildi",
  "app.importLeaves.alerts.toast": "Hata",
  "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "Eşleştirilmemiş veya yok sayılmış zorunlu sütunlar var. Devam etmek için tüm zorunlu sütunları eşleştirmelisiniz.",
  "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "İptal",
  "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Devam Et",
  "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "Tüm sütunlar eşleştirilmedi",
  "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Eşleştirilmemiş zorunlu sütunlar:",
  "app.importLeaves.downloadTemplateFileTooltip": "Burada, içe aktarmak istediğiniz verileri doldurmak için örnek olarak kullanabileceğiniz içe aktarma şablon dosyamızı indirebilirsiniz. İçe aktarma dosyanızı oluştururken, sistemin verilerinizi doğru şekilde tanıması için bir başlık sütunu içerdiğinden emin olun.<br></br><br></br>Başarılı bir içe aktarma için verilerinizin şablonla eşleştiğini kontrol edin. Ek talimatlar için destek ekibimize başvurmaktan çekinmeyin.",
  "app.importLeaves.dropzone.activeDropzoneTitle": "Dosyayı buraya bırakın",
  "app.importLeaves.dropzone.buttonTitle": "Dosya seç",
  "app.importLeaves.dropzone.errorToastDescription": ".xlsx, .xls veya .csv dosyası yükleyin",
  "app.importLeaves.dropzone.loadingTitle": "İşleniyor...",
  "app.importLeaves.dropzone.title": ".xlsx, .xls veya .csv dosyası yükleyin",
  "app.importLeaves.errors.dateFromAfterDateTo": "Başlangıç Tarihi, Bitiş Tarihinden sonra",
  "app.importLeaves.errors.dateFromNotValid": "Başlangıç Tarihi geçerli değil",
  "app.importLeaves.errors.dateFromRequired": "Başlangıç Tarihi gerekli",
  "app.importLeaves.errors.dateToNotValid": "Bitiş Tarihi geçerli değil",
  "app.importLeaves.errors.dateToRequired": "Bitiş Tarihi gereklidir",
  "app.importLeaves.errors.halfDay": "İzin bir günden fazla",
  "app.importLeaves.errors.hourFromAfterHourTo": "Başlangıç Saati, Bitiş Saatinden sonra",
  "app.importLeaves.errors.hourFromNotValid": "Başlangıç Saati geçerli değil, 0 ile 24 arasında bir değer girin",
  "app.importLeaves.errors.hourFromRequired": "Başlangıç Saati gereklidir",
  "app.importLeaves.errors.hourRange": "Seçilen saatler bu kullanıcının çalışma günü uzunluğunu aşıyor ({hours} saat). Lütfen seçiminizi ayarlayın.",
  "app.importLeaves.errors.hourToNotValid": "Bitiş Saati geçerli değil, 0 ile 24 arasında bir değer girin",
  "app.importLeaves.errors.hourToRequired": "Bitiş Saati gereklidir",
  "app.importLeaves.errors.leavePolicyError": "İzin politikası yalnızca tam gün izin veriyor",
  "app.importLeaves.errors.leaveTypeNotFound": "İzin türü bulunamadı",
  "app.importLeaves.errors.leaveTypeNotInUserLocation": "İzin türü kullanıcı konumunda yok",
  "app.importLeaves.helpdesk": "Dosyalarınızı içe aktarma konusunda ayrıntılı rehberlik için lütfen <helpdesk>yardım masası sayfasındaki</helpdesk> adım adım talimatları inceleyin.",
  "app.importLeaves.importFormatTooltip": "Veri türünüze ve tercihlerinize uyan içe aktarma formatını seçin.",
  "app.importLeaves.leaveStatusWarning": "İçe aktardığınız tüm izinler için izin durumu \"Onaylandı\" olarak ayarlanacaktır.",
  "app.importLeaves.manifestDescription": "(Sonraki adımlarda sütunları yeniden adlandırma veya kaldırma şansınız olacak)",
  "app.importLeaves.manifestTitle": "Beklediğimiz veriler:",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "Sütunlar çoğaltılamaz",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "Başka bir sütun seçimi kaldırıldı",
  "app.importLeaves.matchColumnsStep.ignoredColumnText": "Sütun yok sayıldı",
  "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Eşleştir",
  "app.importLeaves.matchColumnsStep.nextButtonTitle": "İleri",
  "app.importLeaves.matchColumnsStep.selectPlaceholder": "Sütun seç...",
  "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Seç...",
  "app.importLeaves.matchColumnsStep.templateTitle": "Şu hale gelecek",
  "app.importLeaves.matchColumnsStep.title": "Sütunları Eşleştir",
  "app.importLeaves.matchColumnsStep.unmatched": "Eşleşmemiş",
  "app.importLeaves.matchColumnsStep.userTableTitle": "Tablonuz",
  "app.importLeaves.maxRecordsExceeded": "Çok fazla kayıt. En fazla {maxRecords} kayıt izin veriliyor",
  "app.importLeaves.nextButtonTitle": "İleri",
  "app.importLeaves.selectHeaderStep.title": "Başlık satırını seç",
  "app.importLeaves.selectSheet.title": "Kullanılacak sayfayı seç",
  "app.importLeaves.validationStep.discardButtonTitle": "Seçili satırları sil",
  "app.importLeaves.validationStep.filterSwitchTitle": "Sadece hatalı satırları göster",
  "app.importLeaves.validationStep.nextButtonTitle": "Onayla",
  "app.importLeaves.validationStep.noRowsMessage": "Veri bulunamadı",
  "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "Hata içeren veri yok",
  "app.importLeaves.validationStep.title": "Verileri doğrula",
  "app.importType": "İçe Aktarma Türü",
  "app.integrations": "Entegrasyonlar",
  "app.invite": "Davet Et",
  "app.january": "Ocak",
  "app.july": "Temmuz",
  "app.june": "Haziran",
  "app.label": "Etiket",
  "app.labels": "Etiketler",
  "app.lastMonth": "Geçen ay",
  "app.learnMore": "Daha fazla bilgi edin",
  "app.leave": "İzin",
  "app.leaveBalanceReport": "İzin Bakiyesi Raporu",
  "app.leaveCanceled": "İzin İptal Edildi",
  "app.leaveHistory": "İzin Geçmişi",
  "app.leaveHistoryReport": "İzin Talebi Raporu",
  "app.leavePolicies": "İzin Politikaları",
  "app.leaveRequestCalendarStatusLabel": "Takviminizde şu şekilde göster ",
  "app.leaveRequestCreated": "İzin Talebi Oluşturuldu",
  "app.leaveRequests": "İzin Talepleri",
  "app.leaveType": "İzin Türü",
  "app.leaveTypes": "İzin Türleri",
  "app.link": "Bağlantı",
  "app.load": "Yükle",
  "app.loading": "Yükleniyor",
  "app.loadMore": "Daha fazla yükle",
  "app.location": "Konum",
  "app.locations": "Konumlar",
  "app.login": "Giriş yap",
  "app.logInWithMicrosoft": "Microsoft ile giriş yap",
  "app.logout": "Çıkış yap",
  "app.logoutNow": "Şimdi Çıkış Yap",
  "app.logs": "Kayıtlar",
  "app.manageLabels": "Etiketleri Yönet",
  "app.march": "Mart",
  "app.may": "Mayıs",
  "app.maybe": "Belki",
  "app.message": "mesaj",
  "app.meta.description": "Dünya çapında 1.000'den fazla şirket tarafından sevilen Vacation Tracker, PTO'nuzu yönetmeyi son derece kolaylaştırır. Slack, Microsoft Teams ve Google Workspace için kullanılabilir.",
  "app.meta.title": "Vacation Tracker - Uzaktan çalışma dostu izin takip çözümü",
  "app.mfa": "Çok faktörlü kimlik doğrulama",
  "app.mfaCode": "Kod",
  "app.mfaCodeErrorGeneric": "Kod doğrulanamadı",
  "app.mfaCodeErrorMissmatch": "Kod uyuşmazlığı",
  "app.mfaCodeErrorNetwork": "Ağ Hatası",
  "app.mfaCodePlaceholder": "Lütfen kimlik doğrulayıcı uygulamanızda görüntülenen kodu girin",
  "app.mfaDisableAnswerNo": "Hayır, MFA'yı koru",
  "app.mfaDisableAnswerYes": "Evet, MFA'yı devre dışı bırak",
  "app.mfaDisableError": "MFA devre dışı bırakılamadı. Lütfen daha sonra tekrar deneyin.",
  "app.mfaDisableSuccess": "MFA'yı başarıyla devre dışı bıraktınız.",
  "app.mfaDisableWarning": "MFA'yı devre dışı bırakmak istediğinizden emin misiniz?",
  "app.mfaDisableWarningTitle": "Çok faktörlü kimlik doğrulamayı devre dışı bırak",
  "app.mfaEnableError": "MFA etkinleştirilemedi. Lütfen daha sonra tekrar deneyin.",
  "app.mfaEnableSuccess": "MFA'yı başarıyla kurdunuz.",
  "app.mfaEnterCode": "Kodu girin",
  "app.mfaLabel": "MFA'yı etkinleştir",
  "app.mfaLoginFailed": "Giriş Başarısız",
  "app.mfaSecretKey": "Veya gizli anahtarı manuel olarak girin:",
  "app.mfaSubTitle": "Aşağıdaki QR kodunu MFA uygulamanızda tarayın (örn. Authy veya Google Authenticator):",
  "app.mfaTitle": "Çok faktörlü kimlik doğrulamayı etkinleştir",
  "app.mfaTooltip": "Vacation Tracker'ı MFA uygulamanıza ekledikten sonra, kodun oluşturulmasını bekleyin ve aşağıya girin:",
  "app.microsoft.manageLicenses": "Lisansları yönet",
  "app.microsoftTeams": "Microsoft Teams",
  "app.monday": "Pazartesi",
  "app.monday.short": "Pzt",
  "app.monthly": "Aylık",
  "app.monthlyLeaveBalanceReport": "Aylık İzin Bakiyesi Raporu",
  "app.moreDetails": "Daha Fazla Detay",
  "app.myProfile": "Profilim",
  "app.myStatus": "Durumum",
  "app.name": "İsim",
  "app.newPassword": "Yeni Şifre",
  "app.next": "İleri",
  "app.nextInvoice": " Bir sonraki faturanız {startDate} tarihinde {amount} olacak (KDV hariç)",
  "app.nextMonth": "Gelecek ay",
  "app.nextYear": "Gelecek Yıl",
  "app.no": "Hayır",
  "app.noLeaves": "İzin yok",
  "app.none": "Hiçbiri",
  "app.nonWorkingDay": "Çalışma dışı gün",
  "app.notDone": "Tamamlanmadı",
  "app.note": "Not",
  "app.notificationError": "Hay aksi! 😕 Bir şeyler yanlış gitti. ",
  "app.notifications": "Bildirimler",
  "app.notSet": "Ayarlanmadı",
  "app.notValidEmail": "Lütfen geçerli bir e-posta adresi girin.",
  "app.november": "Kasım",
  "app.october": "Ekim",
  "app.of": "'in",
  "app.ofThat": "onun",
  "app.ok": "Tamam",
  "app.oldPassword": "Eski Şifre",
  "app.one-hour": "1 saat",
  "app.openCalendar": "Takvimi Aç",
  "app.openDashboard": "Kontrol Panelini Aç",
  "app.openWebDashboard": "Web Kontrol Panelini Aç",
  "app.or": "Veya",
  "app.password": "Şifre",
  "app.passwordAndSecurity": "Şifre ve Güvenlik",
  "app.pastDue": "Başarısız bir ödeme nedeniyle Vacation Tracker'a erişiminiz yakında kapanacak. Vacation Tracker'ı kullanmaya devam etmek istiyorsanız, lütfen <a>geçerli bir kart girin</a>.",
  "app.pending": "Beklemede",
  "app.pendingLeaveRequests": "Bekleyen İzin Talepleri",
  "app.pleaseSelect": "Lütfen seçin",
  "app.prev": "Önceki",
  "app.previous": "Önceki",
  "app.proceed": "Devam Et",
  "app.processingRequest": "İstek işleniyor...",
  "app.profilePicture": "Profil Fotoğrafı",
  "app.reason": "Neden",
  "app.recipients": "Alıcılar",
  "app.recommended": "önerilen",
  "app.reconnect": "Yeniden Bağlan",
  "app.reload": "Yeniden Yükle",
  "app.removed": "kaldırıldı",
  "app.rename": "Yeniden Adlandır",
  "app.reportType": "Rapor türü",
  "app.requestLeave": "İzin Talebi",
  "app.requestToil": "TOIL Talebi",
  "app.required": "Gerekli",
  "app.resendCode": "Kodu yeniden gönder",
  "app.resent": "Yeniden gönderildi",
  "app.reset": "Sıfırla",
  "app.resetPassword": "Şifreyi sıfırla",
  "app.reviewAndConfirm": "İncele ve Onayla",
  "app.role.administrator": "Yönetici",
  "app.role.approver": "Onaylayıcı",
  "app.role.approvers": "Onaylayıcılar",
  "app.saturday": "Cumartesi",
  "app.saturday.short": "Cts",
  "app.save": "Kaydet",
  "app.scheduledLeaves": "Planlanmış İzinler",
  "app.scheduledReports": "Planlanmış Raporlar",
  "app.seeInCalendar": "🗓️ Takvimde Gör",
  "app.seeMoreOptions": "Daha fazla seçenek gör",
  "app.select": "Seç",
  "app.selectAll": "Tümünü Seç",
  "app.semi-monthly": "Yarı Aylık",
  "app.send": "Gönder",
  "app.sendNow": "Şimdi Gönder",
  "app.september": "Eylül",
  "app.set": "ayarla",
  "app.setOn": "şu tarihte ayarla",
  "app.shortestLeaveDuration": "En Kısa İzin Süresi",
  "app.showLess": "Daha az göster",
  "app.showMore": "Daha fazla göster",
  "app.skip": "Atla",
  "app.slack": "Slack",
  "app.snooze": "Ertele",
  "app.snoozeForMonths": "2 ay ertele",
  "app.startFreeTrial": "7 Günlük Ücretsiz Denemeyi Başlat",
  "app.state": "Eyalet",
  "app.status": "Durum",
  "app.submit": "Gönder",
  "app.substituteApprover": "Yedek Onaylayıcı",
  "app.substituteApproverSet": "Yedek Onaylayıcı olarak atandınız.",
  "app.sunday": "Pazar",
  "app.sunday.short": "Paz",
  "app.switch": "Değiştir",
  "app.sync": "Senkronize Et",
  "app.takeQuickTour": "Hızlı bir tur at",
  "app.text": "Metin",
  "app.thisMonth": "Bu ay",
  "app.thisWeek": "Bu hafta",
  "app.thursday": "Perşembe",
  "app.thursday.short": "Per",
  "app.time": "Zaman",
  "app.timeOffInLieu": "Telafi izni",
  "app.timeOffInLieuTooltip": "Telafi iznini düzenlemek mi istiyorsunuz? <helpDesk>Nasıl yapılacağını öğrenin</helpDesk>",
  "app.timezone": "Saat dilimi",
  "app.today": "Bugün",
  "app.toil": "TOIL",
  "app.trialExpired": "Deneme sürümü {days} {daysInfo} içinde sona eriyor. Bizi beğendiyseniz, deneme süresi sona erdikten sonra Vacation Tracker'ı kullanmaya devam etmek için lütfen <a>abone olun</a>.",
  "app.trialExtend": "Deneme süresini uzatmaya ihtiyacınız var mı?",
  "app.trialPeriod": "Deneme Süresi",
  "app.tryAgain": "Tekrar deneyin",
  "app.tryLateOrContactSupport": "Bir şeyler yanlış gitti. Lütfen daha sonra tekrar deneyin veya şu ID ile müşteri desteğimizle iletişime geçin: {code}",
  "app.tuesday": "Salı",
  "app.tuesday.short": "Sal",
  "app.type": "Tür",
  "app.unknown": "<Bilinmeyen>",
  "app.unlimited": "Sınırsız",
  "app.update": "Güncelle",
  "app.updated": "güncellendi",
  "app.updatedInProgress": "Yanıt İşleniyor",
  "app.updatedSuccessfully": "İstek gönderildi.",
  "app.updating": "Güncelleniyor",
  "app.upload": "Yükle",
  "app.user": "Kullanıcı",
  "app.users": "Kullanıcılar",
  "app.users.role": "Rol",
  "app.via": "aracılığıyla",
  "app.view": "Görüntüle",
  "app.viewAll": "Tümünü Görüntüle",
  "app.visitHelpdesk": "Yardım Masasını Ziyaret Et",
  "app.wallchart": "Duvar Takvimi",
  "app.was": "idi",
  "app.watchVideo": "Videoyu izle",
  "app.wednesday": "Çarşamba",
  "app.wednesday.short": "Çar",
  "app.weekly": "Haftalık",
  "app.willBe": "olacak",
  "app.workday": "İş Günü Uzunluğu",
  "app.workdayWarning": "Yarım gün izin seçeneği yalnızca 8 saatlik bir iş günü için kullanılabilir. Standart iş gününü 8 saatten değiştirmek bu seçeneği devre dışı bırakacaktır.",
  "app.workweek": "Çalışma haftası",
  "app.year": "Yıl",
  "app.years": "Yıllar",
  "app.yes": "Evet",
  "automation.feedback.thankYou": "Düşüncelerinizi bizimle paylaştığınız için teşekkür ederiz. Geri bildiriminiz sürekli gelişimimize katkı sağlıyor.",
  "automations.activateInProgress": "İşlem Devam Ediyor - Aktivasyon",
  "automations.activateInProgressDescription": "Otomasyonu etkinleştirme isteğiniz şu anda işleniyor. Bu özelliği sizin için etkinleştirmek üzere çalışıyoruz.",
  "automations.add": "Otomasyon oluştur",
  "automations.addAddonsDescirptionForAdmin": "Seçilen eklentiyi satın alma işlemine devam edilsin mi? Tutar ${prorateAmount} olarak oranlanacak ve bir sonraki faturanız toplam ${nextInvoiceAmount} olacak. Onaylamak için 'Onayla'ya tıklayın.",
  "automations.addAddonsDescirptionForAdminInTrial": "Bu eklenti bir sonraki faturanızda ücretlendirilecek: ${total}, {userCount} kullanıcı için eklenti başına ${addonsPrice} bazında. Deneme süresi boyunca, sonunda hiçbir eklenti etkin değilse ücretsiz olarak etkinleştirebilir ve devre dışı bırakabilirsiniz.",
  "automations.addAddonsDescirptionForApprover": "Yalnızca Yönetici seviyesindeki kullanıcılar Eklentileri satın alma veya abonelikten çıkma yetkisine sahiptir. Eklenti aboneliklerinizde değişiklik yapmanız gerekiyorsa, lütfen yöneticinizle iletişime geçin.",
  "automations.addFreeAddonsDescirptionForAdmin": "Bu eklenti, mevcut planınız kapsamında ek bir ücret olmadan dahildir. Mevcut plan aboneliğiniz boyunca herhangi bir ek ücret ödemeden serbestçe etkinleştirebilir ve devre dışı bırakabilirsiniz.",
  "automations.addpnSuccessfullyAdded": "Tamamlandı - Aktivasyon",
  "automations.addpnSuccessfullyAddedDescription": "Otomasyon başarıyla etkinleştirildi. Artık tüm özelliklerini ve işlevlerini kullanabilirsiniz.",
  "automations.addpnSuccessfullyDeactivated": "Tamamlandı - Deaktivasyon",
  "automations.addpnSuccessfullyDeactivatedDescription": "Otomasyon başarıyla devre dışı bırakıldı. Artık sisteminizde aktif olmayacak.",
  "automations.ADP_INTEGRATION": "ADP Entegrasyonu",
  "automations.anythingMissing": "Eksik bir şey var mı?",
  "automations.automationBlackoutPeriodDeleted": "Karartma dönemi silindi",
  "automations.automationEntitlementByRoleDeleted": "Role göre hak silindi",
  "automations.automationProbationPeriodDeleted": "Deneme süresi silindi",
  "automations.automationSeniorityEntitlementDeleted": "Kıdem hakkı silindi",
  "automations.automationType": "Otomasyon türü",
  "automations.BAMBOOHR_INTEGRATION": "BambooHR Entegrasyonu",
  "automations.BLACKOUT_PERIOD": "Karartma Dönemi",
  "automations.blackoutPeriod.autoRejectReasonDefault": "Mevcut yüksek iş hacmi dönemimiz boyunca, tüm ekibimiz operasyonları sürdürmek için gereklidir. Maalesef, iş hedeflerimizi ve müşteri taahhütlerimizi etkileyeceği için izin onaylayamıyoruz.",
  "automations.blackoutPeriod.createInProgress": "Karartma dönemi oluşturma devam ediyor",
  "automations.blackoutPeriod.createInProgressCompleted": "Karartma dönemi oluşturma tamamlandı",
  "automations.blackoutPeriod.updateInProgress": "Karartma dönemi güncelleme devam ediyor",
  "automations.blackoutPeriod.updateInProgressCompleted": "Karartma dönemi güncelleme tamamlandı",
  "automations.blackoutPeriodFromTo": "Karartma dönemi {fromDate} <endDate>{toDate} tarihine kadar</endDate>",
  "automations.cantOpenAutomations": "Bu Otomasyon başka bir kullanıcı tarafından oluşturuldu ve siz düzenleyemezsiniz.",
  "automations.createAutomation": "Otomasyon oluştur",
  "automations.datePeriod": "{fromDate} <endDate>ve {toDate}</endDate><recurring> her yıl</recurring>",
  "automations.deactivateInPgoress": "İşlem Devam Ediyor - Deaktivasyon",
  "automations.deactivateInPgoressDescription": "Otomasyonu devre dışı bırakma isteğiniz şu anda işleniyor. Ayarlarınızı güncellememiz için lütfen bekleyin",
  "automations.deactivateVisibilityWarn": "Bir otomasyonu devre dışı bırakmak aboneliği iptal etmez. Aboneliği kapatmak istiyorsanız, \"Abonelikten Çık\" düğmesine tıklamanız gerekir.",
  "automations.deleteInProgress": "{automationName} otomasyonu siliniyor",
  "automations.departmentVisibilityLevelInfo": "Kullanıcılar yalnızca kendi departmanlarındaki izin bilgilerini görecektir. Onaylayıcılar, kendi departmanlarındaki ve onaylayıcı oldukları diğer departmanlardaki izin bilgilerini görecektir, üye olmasalar bile. Yöneticiler her zaman her şeyi görecektir.",
  "automations.discoveryMoreAddons": "Daha Fazla Eklenti Keşfet",
  "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Erken İstihdam İzin Kısıtlaması",
  "automations.emptyViewButton": "Mevcut eklentileri gör",
  "automations.emptyViewMessage": "Otomasyonlarımız, eklentilerimiz ve entegrasyonlarımızla kıdeme dayalı PTO günleri ekleyin, karartma dönemleri oluşturun, onaylama veya reddetme sürecini otomatikleştirin ve daha fazlasını yapın.",
  "automations.emptyViewTitle": "Zaman alıcı görevlerinizi otomatikleştirin ve harici sistemlerle entegre edin",
  "automations.ENTITLEMENT_BY_ROLE": "Role Göre Hak Ediş",
  "automations.entitlementByRole.createInProgress": "Role göre hak ediş oluşturma devam ediyor",
  "automations.entitlementByRole.createInProgressCompleted": "Role göre hak ediş oluşturma tamamlandı",
  "automations.entitlementByRole.days": "Günler",
  "automations.entitlementByRole.updateInProgress": "Role göre hak ediş güncelleme devam ediyor",
  "automations.entitlementByRole.updateInProgressCompleted": "Role göre hak ediş güncelleme tamamlandı",
  "automations.LEAVE_DURATION_LIMIT": "İzin Süresi Sınırı",
  "automations.message": "Mesaj",
  "automations.messagePlaceholder": "Mesajınız",
  "automations.myAddons": "Eklentilerim",
  "automations.OPEN_API": "Açık API",
  "automations.OPEN_API_DOCUMENTATION": "Açık API Dokümantasyonu",
  "automations.period": "Dönem",
  "automations.PROBATION_PERIOD": "Deneme Süresi",
  "automations.probationPeriod.autoRejectReasonDefault": "İzin talebiniz otomatik olarak reddedildi çünkü erken istihdam aşamasındasınız, bu dönemde izin kullanılmasına izin verilmemektedir. Bu politika, yeni çalışanların yeni rollerine ve ekip dinamiklerine tamamen uyum sağlamasına yardımcı olur.",
  "automations.probationPeriod.createInProgress": "Deneme süresi oluşturma devam ediyor",
  "automations.probationPeriod.createInProgressCompleted": "Deneme süresi oluşturma tamamlandı",
  "automations.probationPeriod.lengthDays": "Süre (gün)",
  "automations.probationPeriod.name": "Deneme Süresi Politikası Adı",
  "automations.probationPeriod.namePlaceholder": "Deneme süresi politikası adını girin",
  "automations.probationPeriod.settings.applyToMDescription": "Bu otomasyonu tüm kullanıcılara evrensel olarak uygulamak mı yoksa belirli departmanlara, lokasyonlara veya etiketlere göre özelleştirmek mi istediğinizi seçebilirsiniz.",
  "automations.probationPeriod.settings.applyToMDescriptionTwo": "Ayrıca bu otomasyonu uygulamak istediğiniz bir veya daha fazla izin türünü de seçebilirsiniz.",
  "automations.probationPeriod.settings.lengthDaysDescription": "Yeni çalışanların işe başlama tarihlerinden itibaren belirli izin türlerini kullanmalarının kısıtlanacağı gün sayısını girin.",
  "automations.probationPeriod.settings.nameDescription": "Bu bölüm, yeni işe alınanların ilk istihdam dönemlerinde izin kullanmalarını kısıtlayan bir politika belirlemenizi sağlar.",
  "automations.probationPeriod.settings.nameDescriptionThree": "Kullanıcıların izin taleplerinin neden reddedildiğini açıklayan otomatik bir mesaj almalarını sağlayın.",
  "automations.probationPeriod.settings.nameDescriptionTwo": "Gün sayısını girin, biz de deneme süresini her kullanıcının işe başlama tarihine göre hesaplayacağız.",
  "automations.probationPeriod.settings.nameTitle": "Politika Tanımla",
  "automations.probationPeriod.updateInProgress": "Deneme süresi güncelleme devam ediyor",
  "automations.probationPeriod.updateInProgressCompleted": "Deneme süresi güncelleme tamamlandı",
  "automations.probationPeriodMessages": "Deneme süreniz {date} tarihine kadar devam ediyor",
  "automations.removeAddons": "Eklentileri kaldır",
  "automations.removeAddonsDescirptionForAdmin": "Bu Eklentiden aboneliğinizi iptal etmek istediğinizden emin misiniz? Mevcut fatura döneminin sonuna kadar tüm özelliklerine erişiminiz devam edecektir. Bundan sonra devre dışı bırakılacak ve izin yönetimi kurulumunuzu buna göre etkileyecektir.",
  "automations.removeAddonsDescirptionForApprover": "Yalnızca Yönetici seviyesindeki kullanıcılar Eklentileri satın alma veya abonelikten çıkma yetkisine sahiptir. Eklenti aboneliklerinizde değişiklik yapılması gerekiyorsa, lütfen yöneticinizle iletişime geçin.",
  "automations.requestAnAutomation": "Bir Eklenti Talep Et",
  "automations.requestAutomationTitle": "İhtiyacınız olan bir Eklenti bulamıyor musunuz?",
  "automations.reSubscribeAddons": "Eklentiye Yeniden Abone Ol",
  "automations.reSubscribeAddonsDescirptionForAdmin": "Bu Eklentiye yeniden abone olmak istediğinizden emin misiniz? Bir sonraki faturanızdan itibaren kullanıcı başına ayda ${addonsPrice} ücretlendirileceksiniz. İlk ayın ücreti, eklentinin kullanıldığı gün sayısına göre orantılı olarak hesaplanacaktır.",
  "automations.reSubscribeAddonsDescirptionForAdminInTrial": "Bu Eklentiye yeniden abone olmak istediğinizden emin misiniz? Deneme süresi sonuna kadar ücretsiz kalacaktır. Bundan sonra, kullanıcı başına ayda ${addonsPrice} ücret uygulanacaktır.",
  "automations.reSubscribeInfo": "Eklenti aboneliğinizi yenileyin ve gelişmiş özelliklerimizi kullanmaya devam edin. İlerlemek için Yeniden Abone Ol'a tıklayın.",
  "automations.SENIORITY_ENTITLEMENT": "Kıdem Hakkı",
  "automations.seniorityEntitlement.createInProgress": "Kıdem hakkı oluşturma devam ediyor",
  "automations.seniorityEntitlement.createInProgressCompleted": "Kıdem hakkı oluşturma tamamlandı",
  "automations.seniorityEntitlement.periodsViewTable": "{years} yıl sonra {days} gün",
  "automations.seniorityEntitlement.updateInProgress": "Kıdem hakkı güncelleme devam ediyor",
  "automations.seniorityEntitlement.updateInProgressCompleted": "Kıdem hakkı güncelleme tamamlandı",
  "automations.showCaneledAddons": "İptal edilen eklentileri göster",
  "automations.stepByStepHelpDesk": "<automationName></automationName> Otomasyonunuzu ayarlamak için ayrıntılı rehberlik için lütfen <link>yardım masası sayfasında</link> bulunan adım adım talimatları inceleyin.",
  "automations.subscripteToAddons": "Eklentilere abone ol",
  "automations.TEAM_LEAVE_BALANCING": "Ekip İzin Dengeleme",
  "automations.unsubscribeForFreeAddonsInfo": "Şu anda bu eklentiye planınıza dahil olduğu için ek bir ücret ödemeden abonesiniz. Aboneliğinizi iptal etmek isterseniz, sağdaki aboneliği iptal et düğmesine tıklayın.",
  "automations.unsubscribeInfo": "Şu anda bu eklentiye kullanıcı başına ${addonsPrice} karşılığında abonesiniz. Aboneliğinizi iptal etmek isterseniz, sağdaki aboneliği iptal et düğmesine tıklayın.",
  "automations.unsubscripteAddons": "Eklenti aboneliğini iptal et?",
  "automations.update": "Otomasyonları güncelle",
  "automations.VISIBILITY": "Görünürlük Yöneticisi",
  "automations.visibility.createInProgress": "Görünürlüğü sınırlama devam ediyor",
  "automations.visibility.createInProgressCompleted": "Görünürlüğü sınırlama tamamlandı",
  "automations.visibility.updateInProgress": "Görünürlük eklentisi güncelleniyor",
  "automations.visibility.updateInProgressCompleted": "Görünürlük eklentisi güncelleme tamamlandı",
  "automations.visibilityForm.DEPARTMENT": "Kendi departmanları",
  "automations.visibilityForm.limitVisibility": "Kullanıcılar için görünürlüğü sınırla",
  "automations.visibilityForm.visibilityLevel": "Kullanıcılar görebilir",
  "automations.visibilityForm.visibilityTypeValidationRequired": "En az bir görünürlük türü seçmelisiniz",
  "automations.visibilityInfo.settings.nameTitle": "Görünürlük Ayarları",
  "automations.visibilityManagerNotificationsInfo": "Görünürlük ayarları değişikliğinden önce oluşturulan Paylaşılan Takvimler ve Bildirimler, yeni kurallara uymuyorlarsa manuel olarak silinmelidir.",
  "automationsForm.advancedFilter.tooltipInfo": "Bu otomasyonun uygulanacağı kullanıcıları departmanlara, konumlara veya etiketlere göre filtreleyebilirsiniz.",
  "automationsForm.applyToAllUsers": "Tüm kullanıcılara uygulansın mı?",
  "automationsForm.applyToAllUsersTooltip": "Lütfen bu Otomasyonu tüm kullanıcılar genelinde evrensel olarak uygulamak mı yoksa belirli departmanlara, konumlara veya etiketlere göre özelleştirmek mi istediğinizi seçin.",
  "automationsForm.autoRejectReason": "Otomatik Ret Yanıtı",
  "automationsForm.autoRejectReasonTooltip": "Kullanıcıya, izin taleplerinin bu süre zarfında neden reddedildiğine dair otomatik bir yanıt sağlayın.",
  "automationsForm.blackoutPeriodShortText": "Çalışanların izin alamadığı bir zaman dilimi",
  "automationsForm.period": "Dönem",
  "automationsForm.recurring": "Yıllık olarak tekrarla",
  "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "Bu Kıdem hakkını şirketinizdeki tüm kullanıcılara mı yoksa belirli bir konumdaki kullanıcılara mı uygulamak istiyorsunuz? Farklı konumlarda farklı Kıdem hakkı kurallarınız varsa, her konum için birden fazla Kıdem hakkı otomasyon kuralı oluşturmanız gerekir.",
  "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Mali yılın başlangıcı",
  "automationsForm.seniorityEntitlement.employeeStartDate": "Çalışanın Başlangıç Tarihi",
  "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "Mali yılın sonu",
  "billing.applyingCoupon": "Kupon uygulanıyor",
  "billing.applyingCouponAndResubscribing": "Kupon uygulanıyor ve Yeniden abone olunuyor",
  "billing.cancelSubscription": "Aboneliği İptal Et",
  "billing.cancelTrial": "Deneme süreniz {stripeCurrentPeriodEnd} tarihine kadar aktif kalacaktır. Vacation Tracker'ı kullanmaya devam etmek için lütfen fatura bilgilerinizi girin.",
  "billing.cancelTrialButton": "Denemeyi İptal Et",
  "billing.cancelUpdateInProgress": "İptal ediliyor...",
  "billing.linkPaymentMethod": "Stripe Bağlantısı",
  "billing.microsoft.billedLabel": "Faturalandırıldı",
  "billing.microsoft.billingCycle": "Mevcut fatura dönemi {date} tarihinde sona eriyor",
  "billing.microsoft.billingCycleEnds": "Aboneliğiniz fatura dönemi sona erdikten sonra iptal edilecek ve artık Vacation Tracker'ı kullanamayacaksınız.",
  "billing.microsoft.billingCycleLabel": "Fatura dönemi",
  "billing.microsoft.buyLessLicensesConfirmModalContent": "{currentNoOfLicenses} lisansınız var. {additionalLicences} lisans kaldırmak üzeresiniz. Emin misiniz?",
  "billing.microsoft.buyMoreLicensesConfirmModalContent": "{currentNoOfLicenses} lisansınız var. {additionalLicences} lisans daha satın almak üzeresiniz. Emin misiniz?",
  "billing.microsoft.canceledSubscriptionMessage": "Aboneliğiniz yaklaşık 15 dakika içinde iptal edilecek",
  "billing.microsoft.changePlanModalTitle": "Mevcut <strong>{plan}</strong> planını değiştir",
  "billing.microsoft.info": "Ödeme bilgileriniz Microsoft tarafından yönetilmektedir. {labelInfo} güncellemek için <adminCenterLink>Yönetici Merkezi</adminCenterLink>'ni ziyaret edin",
  "billing.microsoft.licensesSideActionLabel": "Geçmişi görüntüle",
  "billing.microsoft.licensesTitle": "Lisanslar",
  "billing.microsoft.pricingPlanLabel": "Fiyatlandırma planı",
  "billing.microsoft.switchPlanModal.confirm": "Lütfen bu plana geçmek istediğinizi onaylayın.",
  "billing.paymentInformation": "Ödeme bilgileri",
  "billing.paymentMethod": "Ödeme Yöntemi",
  "billing.pricingPlanPeriodAnnual": "Yıllık",
  "billing.pricingPlanPeriodMonthly": "Aylık",
  "billing.pricingPlansText": "Bu bölüm abonelik planınızı gösterir. Daha önce sahip olduğunuz plana yeniden abone olabilir veya yeniden abone olmadan önce planı değiştirebilirsiniz.",
  "billing.pricingPlansTitle": "Fiyatlandırma Planları",
  "billing.promoCodeErrorDescription": "Promosyon kodu {code} geçerli değil.",
  "billing.resubscribeCTA": "Yeniden Abone Ol",
  "billing.resubscribingUpdateInProgress": "Yeniden abone olunuyor...",
  "billing.seats": "Koltuklar",
  "billing.seats.buyLessSeatsConfirmModalContent": "{currentNoOfSeats} koltuğunuz var. {additionalSeats} koltuk kaldırmak üzeresiniz.",
  "billing.seats.buyMoreSeatsConfirmModalContent": "{currentNoOfSeats} koltuğunuz var. {additionalSeats} koltuk daha satın almak üzeresiniz.",
  "billing.seats.canNotDowngrade": "Şu anda aktif olan bir veya daha fazla kullanıcıyı devre dışı bırakmadan koltuk sayınızı ({currentNumberOfSeats}) düşüremezsiniz.",
  "billing.seats.info": "Satın aldığınız koltuk sayısı.",
  "billing.seats.loadingUpcomingPaymentInformation": "Yaklaşan ödeme bilgileri yükleniyor...",
  "billing.seats.manage": "Koltukları yönet",
  "billing.seats.noBillingChange": "{noOfSeats} {noOfSeats, plural, =1 {koltuk} other {koltuk}} satın almak üzeresiniz. Faturanız etkilenmeyecek.",
  "billing.seats.warningOfProrateAdditional": "Bugün tahmini {proratedAmount} (KDV hariç) orantılı bir ücret uygulanacak.",
  "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "{noOfSeats} {noOfSeats, plural, =1 {koltuk} other {koltuk}} eklemek, {startDate} tarihinden itibaren bir sonraki faturanızı {amount} (KDV hariç) tutarına yükseltecek.",
  "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "{noOfSeats} {noOfSeats, plural, =1 {koltuk} other {koltuk}} satın almak faturalandırmanızı etkileyecek. Bir sonraki faturanız {startDate} tarihinde {amount} (KDV hariç) olacak",
  "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "{noOfSeats} {noOfSeats, plural, =1 {koltuk} other {koltuk}} kaldırmak, {startDate} tarihinden itibaren bir sonraki faturanızı {amount} (KDV hariç) tutarına düşürecek.",
  "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "{noOfSeats} {noOfSeats, plural, =1 {koltuk} other {koltuk}} kaldırmak faturalandırmanızı etkileyecek. Bir sonraki faturanız {startDate} tarihinde {amount} (KDV hariç) olacak",
  "billing.subscriptionCanceled": "Abonelik iptal edildi",
  "billing.subscriptionExpired": "Abonelik aktif değil",
  "billing.subscriptionExpiredDescription": "Aboneliğiniz iptal edildi veya süresi doldu. Şu anda güncellemeler mümkün değil.",
  "billing.subscriptionExpiredText": "Aboneliğinizin süresi doldu. Vacation Tracker'ı kullanmaya devam etmek için yeniden abone olun. Herhangi bir sorun veya sorunuz varsa, {supportLink} ile iletişime geçin.",
  "billing.SubscriptionPlan": "Abonelik planı",
  "billing.subscriptionUpdateInProgress": "Abonelik güncellemesi devam ediyor",
  "billing.subscriptionUpdateInProgressDescription": "Önceki işlem hala devam ediyor. Lütfen bir dakika içinde tekrar deneyin.",
  "billing.updateBillingInfo": "Fatura Bilgilerini Güncelle",
  "billing.updatedFaild": "Fatura bilgisi güncellemesi başarısız oldu",
  "billing.updatedSuccessfully": "Fatura bilgisi güncellendi",
  "billing.updateInProgress": "Fatura bilgisi güncelleniyor",
  "bot.announce.slack": "Merhaba <@{slackUserId}>\n\nBenim adım Vacation Tracker. Size Slack üzerinden izin almanıza yardımcı olacağım ve ayrıca organizasyonunuzdaki yaklaşan izinler hakkında herkesi bilgilendireceğim.\n\nBaşlamak için Slack'te herhangi bir yerde `/vacation` yazın veya daha fazla bilgi için YouTube eğitimimi izleyin:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.announce.slack.info": "Merhaba <@{slackUserId}>, benim adım Vacation Tracker.",
  "bot.announce.slackCompany": "Merhaba Ekip,\n\nBenim adım Vacation Tracker Bot!\n\nSlack üzerinden izinlerinizi ayarlamanıza ve ayrıca ekip üyelerinizin yaklaşan izinleri hakkında herkesi bilgilendirmenize olanak tanıyorum. Haydi bazı izinleri takip etmeye başlayalım!\n\nSlack'te herhangi bir yerde `/vacation` yazın veya daha fazla bilgi için YouTube eğitimini izleyin:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.message.about.commandDescription": "Vacation Tracker, kuruluşunuzun izin talep ve rezervasyon sürecini basitleştirir. Ekip üyeleri, Slack'ten hiç çıkmadan ve başka bir giriş veya şifre hatırlamak zorunda kalmadan herhangi bir İzin Türünü veya herhangi bir ücretli/ücretsiz izin gününü ayarlayabilir.\n\nÖzel İzin Türleri oluşturun ve kuruluşunuzdaki her bireyin izin sürelerini kontrol etmesine izin verin. Vacation Tracker, her ekip üyesine Dashboard'a (http://app.vacationtracker.io) erişim sağlar; burada izin talebinde bulunabilir, mevcut izin durumlarını veya geçmişlerini kontrol edebilir ve tüm ekipleri için planlanmış izinleri görebilirler.\n\nYönetici veya Onaylayıcının ayarlarına göre, Ekip Üyeleri yaklaşan izinler hakkında günlük/haftalık bildirimler alacaktır. Karmaşa ve hoş olmayan sürprizler yok.\n\nVacation Tracker hakkında daha fazla bilgi mi istiyorsunuz? <https://vacationtracker.io/request-a-demo/|demo>'dan sonra ikinci en iyi seçeneğiniz <https://vacationtracker.crisp.help/en/|Helpdesk>'imizdir.\n\nSorularınız veya endişeleriniz hala yanıtlanmadıysa, istediğiniz zaman bize ulaşın. Müşterilerimizle konuşmak günümüzün en iyi kısmıdır: hello@vacationtracker.io",
  "bot.message.accessExpired": "Eyvah! Vacation Tracker'a erişiminizin süresi doldu. Daha fazla bilgi için lütfen yöneticinizle iletişime geçin.",
  "bot.message.accruedDaysInfoForApprover": "ℹ️ Bu izin talebi, mevcut miktar değil, gelecekteki tahakkuk edecek izin günlerine dayanmaktadır. <@{name}> bugün {amount} gün(ler) kalmış durumda. {earningDate} tarihinde {accrued} tahakkuk edecek.",
  "bot.message.accruedDaysInfoForUser": "ℹ️ İzin talebiniz, mevcut miktar değil, gelecekteki tahakkuk edecek izin günlerine dayanmaktadır. Bugün {amount} gün(ler) kalmış durumdasınız ve {earningDate} tarihinde {accrued} tahakkuk edeceksiniz.",
  "bot.message.approveLeave": "İzni Onayla",
  "bot.message.approveLeaves.approvedRequest": "✅ Talebi onayladınız. <@{userSlackId}>'e bilgilendirmek için bir bildirim gönderilecek.",
  "bot.message.approveLeaves.deniedRequest": "❌ Talebi reddettiniz. <@{userSlackId}>'e bilgilendirmek için bir bildirim gönderilecek.",
  "bot.message.approver": "*Onaylayan:*\n<@{approverSlackId}>",
  "bot.message.approver.autoApproved": "*Onaylayan:*\nOtomatik onaylandı",
  "bot.message.approveToil": "TOIL'i Onayla",
  "bot.message.backToOptions": "Seçeneklere geri dön",
  "bot.message.blackoutPeriod": "*Kapalı dönem*: {startDate} - {endDate} \n \n_{message}_",
  "bot.message.couldNotProcessRequest": "Eyvah! İsteğinizi işleyemedik.",
  "bot.message.date": "*Tarih*",
  "bot.message.dateEdited": "*Tarih [Düzenlendi]*",
  "bot.message.dates": "*Tarihler*",
  "bot.message.datesEdited": "*Tarihler [Düzenlendi]*",
  "bot.message.deniedLeave": "Reddedilen İzin",
  "bot.message.deniedLeaveDate": "*Tarih*\n{leaveDate}",
  "bot.message.deniedLeaveDates": "*Tarihler*\n{leaveDate}",
  "bot.message.deniedLeaveLeaveType": "*İzin türü:*\n{leaveTypeName}",
  "bot.message.deniedLeaveWithReason": "Merhaba, <@{userSlackId}> aşağıdaki izin talebini gönderdi:",
  "bot.message.deniedToil": "Reddedilen TOIL",
  "bot.message.error.endDateCantBeInPast": "Bitiş tarihi başlangıçtan önce olamaz, lütfen gelecekte bir tarih seçin",
  "bot.message.error.endTimeCantBeBeforeStartTime": "Seçtiğiniz başlangıç saati bitiş saatinden sonra görünüyor.*\n *Lütfen bitiş saatinden önce bir başlangıç saati seçin.",
  "bot.message.error.leaveRequestOverlap": "Seçtiğiniz tarihler mevcut bir izinle çakışıyor.*\n *Lütfen farklı bir zaman aralığı seçin.",
  "bot.message.error.negativeBallanceNotAllowed": "Bu izin türü için negatif bakiye izin verilmiyor.",
  "bot.message.error.oneHoureIntervalNotAllowed": "Bu izin türü için isteyebileceğiniz en kısa süre aralığı 4 saattir (yarım gün).",
  "bot.message.error.partTimeIsntAllowed": "Yarı zamanlı çalışmaya izin verilmiyor",
  "bot.message.error.reasonRequired": "Sebep alanı zorunludur.",
  "bot.message.error.requestedMoreThanFullDay": "Bir iş gününde bir tam günden fazla izin talep ettiniz.*\n *Lütfen farklı bir gün seçin.",
  "bot.message.error.startDateCantBeInPast": "Başlangıç tarihi geçmişte olamaz, lütfen gelecekte bir tarih seçin",
  "bot.message.error.userCannotBeFound": "Seçilen kullanıcı organizasyonunuzda bulunamadı.*\n *Lütfen organizasyonunuzun yöneticisi veya Vacation Tracker desteği ile iletişime geçin.",
  "bot.message.error.userEndDateInFuture": "Talep edilen tarihler kullanıcının istihdam bitiş tarihinden sonradır.*\n *Lütfen organizasyonunuzun yöneticisi veya Vacation Tracker desteği ile iletişime geçin.",
  "bot.message.error.userStartDateInFuture": "Talep edilen tarihler kullanıcının istihdam başlangıç tarihinden öncedir. Lütfen organizasyonunuzun yöneticisi veya Vacation Tracker desteği ile iletişime geçin.",
  "bot.message.error.zeroDaysLeaveRequest": "Bir tatil günü veya çalışılmayan bir gün seçtiniz.*\n *Lütfen geçerli bir tarih aralığı seçin.",
  "bot.message.feedback.chooseAnOption": "Bir seçenek seçin...",
  "bot.message.feedback.form": "Geri bildirim formu",
  "bot.message.feedback.haveBeenSend": "Geri bildirim gönderildi",
  "bot.message.feedback.howDoYouLikeOurApp": "Uygulamamızı nasıl buldunuz?",
  "bot.message.feedback.info": "Bize geri bildirim verin :heart:",
  "bot.message.feedback.itMakesMeCry": "Beni ağlatıyor 😢",
  "bot.message.feedback.itsOk": "İdare eder 😐",
  "bot.message.feedback.likeIt": "Beğendim 😀",
  "bot.message.feedback.loveIt": "Bayıldım 😍",
  "bot.message.feedback.message": "Geri bildirim mesajı",
  "bot.message.feedback.nah": "Yok ya 🙁",
  "bot.message.feedback.response": "Geri bildiriminiz bir yanıt gerektiriyorsa, lütfen sizinle iletişime geçebilmemiz için e-posta adresinizi girin.",
  "bot.message.feedback.thanks": "Geri bildiriminiz için teşekkürler! Her zaman support@vacationtracker.io adresini kullanarak bizimle iletişime geçebilirsiniz.",
  "bot.message.feedback.youEmailAddress": "E-posta adresiniz",
  "bot.message.genericError": "Eyvah! Beklenmeyen bir hata oluştu. 😱 Bu sorun devam ederse, lütfen destek ekibiyle iletişime geçin ve onlara şu hata kodunu verin: {requestId}.",
  "bot.message.goToTheMyProfileTab": "Profilim sekmesine git",
  "bot.message.helpMessage": "Merhaba, \nVacation Tracker ile aşağıdaki işlemlerden birini yapabilirsiniz:\n- Microsoft Teams botunu kullanarak yeni bir izin talep etmek için doğrudan mesajda **İzin talep et** gönderin.\n- Microsoft Teams botunu kullanarak yeni bir TOIL talep etmek için doğrudan mesajda **TOIL talep et** gönderin.\n- Yaklaşan izinleriniz ve tatilleriniz ile izin geçmişiniz gibi kişisel bilgilerinizi görmek için **Profilim** sekmesini ziyaret edin.\n- Ekibinizin yaklaşan izinleri ve tatilleri de dahil olmak üzere ekip genel görünümünü görmek için **Genel Bakış** sekmesini kanallarınızdan birine sabitleyin.\n- [**Vacation Tracker Web Paneli ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard) adresini ziyaret edin.\nDaha fazla yardım ve öğretici için [**Yardım Masası ⧉**](https://vacationtracker.crisp.help/en/) adresini ziyaret edin. Ayrıca bu bağlantıdan Vacation Tracker'ın kişiselleştirilmiş bir demosunu planlayabilirsiniz: [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
  "bot.message.holidays": "*Tatiller:*",
  "bot.message.leaveDate": "*Tarih:*\n{leaveDate}",
  "bot.message.leaveDateEdited": "*Tarih [Düzenlendi]:*\n{leaveDate}",
  "bot.message.leaveDates": "*Tarihler:*\n{leaveDate}",
  "bot.message.leaveDatesEdited": "*Tarihler [Düzenlendi]:*\n{leaveDate}",
  "bot.message.leaveDuration1": "*Mevcut yıl için talep edilen {word}:*\n{requestedThisYearNumber}",
  "bot.message.leaveDuration2": "*Mevcut yıl için kalan {word}:*\n{currentYearNumber}",
  "bot.message.leaveDuration2Unlimited": "*Mevcut yıl için kalan {word}:*\nSınırsız",
  "bot.message.leaveDuration3": "*Gelecek yıl için talep edilen {word}:*\n{requestedNextYearNumber}",
  "bot.message.leaveDuration4": "*Gelecek yıl için kalan {word}:*\n{nextYearNumber}",
  "bot.message.leaveDuration4Unlimited": "*Gelecek yıl için kalan {word}:*\nSınırsız",
  "bot.message.leaveRemaining": "Kalan İzin",
  "bot.message.leaveRemainingCurrent": "Kalan İzin (Mevcut Yıl)",
  "bot.message.leaveRemainingDays": "Kalan İzin Günleri",
  "bot.message.leaveRemainingDaysCurrent": "Kalan İzin Günleri (Mevcut Yıl)",
  "bot.message.leaveRemainingDaysNext": "Kalan İzin Günleri (Gelecek Yıl)",
  "bot.message.leaveRemainingHours": "Kalan İzin Saatleri",
  "bot.message.leaveRemainingHoursNext": "Kalan İzin Saatleri (Gelecek Yıl)",
  "bot.message.leaveRemainingNext": "Kalan İzin (Gelecek Yıl)",
  "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
  "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Yarım Gün)",
  "bot.message.leaveRequest.halfDay": "(Yarım Gün)",
  "bot.message.leaveRequest.hourly": "(Saatlik)",
  "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
  "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (Saatlik)",
  "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Saatlik)",
  "bot.message.leaveRequestCreated.error": "İzin talebi gönderilemiyor",
  "bot.message.leaveRequestCreated.errorBadTime": "❌ İzin talebi, çalışma saatlerinden daha uzun olduğu veya zaman aralığı geçersiz olduğu için gönderilemiyor.",
  "bot.message.leaveRequestCreated.errorDateAfter": "❌ İzin talebi, seçtiğiniz başlangıç tarihi bitiş tarihinden sonra olduğu için gönderilemiyor. Başlangıç tarihinin bitiş tarihinden önce olduğundan emin olun ve talebi tekrar gönderin.",
  "bot.message.leaveRequestCreated.errorDateInThePast": "❌ İzin talebi, seçtiğiniz başlangıç tarihi geçmişte olduğu için gönderilemiyor. Seçtiğiniz tarihlerin gelecekte olduğundan emin olun ve talebi tekrar gönderin.",
  "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ İzin talebi, bitiş tarihini seçmediğiniz veya tarih geçersiz olduğu için gönderilemiyor.",
  "bot.message.leaveRequestCreated.errorNoStartDate": "❌ İzin talebi, başlangıç tarihini seçmediğiniz veya tarih geçersiz olduğu için gönderilemiyor.",
  "bot.message.leaveRequestCreated.errorNoStartTime": "❌ İzin talebi, başlangıç saatini seçmediğiniz veya saat geçersiz olduğu için gönderilemiyor.",
  "bot.message.leaveRequestCreated.errorReasonRequired": "❌ İzin talebi gönderilemiyor, sebep belirtilmesi gerekiyor.",
  "bot.message.leaveRequested": "Talep Edilen İzin",
  "bot.message.leaveRequestedCurrent": "Talep Edilen İzin (Mevcut Yıl)",
  "bot.message.leaveRequestedDays": "Talep Edilen İzin Günleri",
  "bot.message.leaveRequestedDaysCurrent": "Talep Edilen İzin Günleri (Mevcut Yıl)",
  "bot.message.leaveRequestedDaysNext": "Talep Edilen İzin Günleri (Gelecek Yıl)",
  "bot.message.leaveRequestedHours": "Talep Edilen İzin Saatleri",
  "bot.message.leaveRequestedHoursCurrent": "Talep Edilen İzin Saatleri (Mevcut Yıl)",
  "bot.message.leaveRequestedHoursNext": "Talep Edilen İzin Saatleri (Gelecek Yıl)",
  "bot.message.leaveRequestedNext": "Talep Edilen İzin (Gelecek Yıl)",
  "bot.message.leaveRequestNext.endDate": "Bitiş Tarihi:",
  "bot.message.leaveRequestNext.info": "Harika! 🐨 Zaman aralığını seçin ve talebi gönderin.",
  "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (Yarım Gün)",
  "bot.message.leaveRequestNext.leaveTime": "İzin Süresi:",
  "bot.message.leaveRequestNext.reasonOptional": "Sebep (İsteğe Bağlı)",
  "bot.message.leaveRequestNext.reasonRequired": "Sebep (Gerekli)",
  "bot.message.leaveRequestNext.startDate": "Başlangıç Tarihi:",
  "bot.message.leaveRequestNext.title": "İzin talebi",
  "bot.message.leaves": "*İzinler:*",
  "bot.message.leavesHistory": "İzin geçmişiniz:",
  "bot.message.leavesHistory.leaveMessageFullDay": "- İzin (*{leaveTypeName}*) {start} tarihinden {end} tarihine kadar *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOn": "- İzin (*{leaveTypeName}*) {start} tarihinde *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOnPartDay": "- İzin (*{leaveTypeName}*) {start} tarihinde {partDayStartHour} - {partDayEndHour} saatleri arasında *[{leaveStatus}]*\n",
  "bot.message.leavesHistoryNot": "Hmm, görünüşe göre henüz hiç izin kullanmamışsınız.",
  "bot.message.leaveTypeName": "*İzin türü:*\n{leaveTypeName}",
  "bot.message.leaveTypeWithHalfDay": "*İzin türü:*\n{leaveTypeName} (yarım gün)",
  "bot.message.manageLeaves.cancelRequest": "İsteği İptal Et",
  "bot.message.manageLeaves.confirmCancellation": "İzin talebinizi iptal etmek istediğinizi lütfen onaylayın",
  "bot.message.manageLeaves.summary": "İşte yaklaşan izinlerinizin ve bekleyen taleplerinizin özeti.",
  "bot.message.manageLeaves.summaryNoLeaves": "Yaklaşan planlanmış izniniz yok",
  "bot.message.manageLeaves.upcomingLeaves": "*{upcomingLeaves} onaylanmış talebiniz var:*",
  "bot.message.manageLeaves.upcomingLeavesPlural": "*{upcomingLeaves} onaylanmış talebiniz var:*",
  "bot.message.manageLeaves.waitingLeaves": "*{waitingLeaves} bekleyen talebiniz var:*",
  "bot.message.manageLeaves.waitingLeavesPlural": "*{waitingLeaves} bekleyen talebiniz var:*",
  "bot.message.membersAway": "İzinli üyeler",
  "bot.message.membersAway.holiday": "- *{holidayLocationName}* *{holidayName}* nedeniyle {date} tarihinde izinli olacak.\n",
  "bot.message.membersAway.leaveMessage": "- *{user}* bugün {leaveTypeName} izni kullanıyor {partDay}\n",
  "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* {startDate} tarihinden {endDate} tarihine kadar {leaveTypeName} izni kullanıyor\n",
  "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {startDate} tarihinde {leaveTypeName} izni {verb}{partDay}\n",
  "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {startDate} tarihinden {endDate} tarihine kadar {leaveTypeName} izni {verb}\n",
  "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Bu ay izinli olan takım üyeleri.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Gelecek ay izinli olacak takım üyeleri.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Gelecek hafta izinli olacak takım üyeleri.",
  "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Bugün izinli olan takım üyeleri.",
  "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Bu hafta izinli olan takım üyeleri.",
  "bot.message.membersAway.multiHoliday": "- *{holidayLocationName}* *{holidayName}* nedeniyle {start} tarihinden {end} tarihine kadar izinli olacak.\n",
  "bot.message.membersAway.noLeaves": "Ekibinizde bu dönem için şu anda planlanmış izin bulunmuyor 😎.",
  "bot.message.memersAway.noLeaves": "Ekibinizde bu dönem için şu anda planlanmış izin bulunmuyor 😎.",
  "bot.message.menageLeaves": "İzinleri Yönet",
  "bot.message.moreOptions": "Merhaba <@{username}>!\nSize nasıl yardımcı olabilirim?",
  "bot.message.moreOptions.about": "Hakkımda daha fazla bilgi :koala:",
  "bot.message.moreOptions.leaveHistoryTitle": "İzin Geçmişi",
  "bot.message.moreOptions.leavesAndHolidaysTitle": "İzinleriniz ve tatiller:",
  "bot.message.moreOptions.membersAway": "Hangi takım üyelerinin izinli olduğunu görün:",
  "bot.message.moreOptions.menageLeavesTitle": "İzinleri Yönet",
  "bot.message.moreOptions.showHolidaysTitle": "Tatilleri Göster",
  "bot.message.myStatus.currentYear": "_Mevcut yıl, {yearEnd} tarihine kadar_",
  "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nKalan: {current}\nKullanılan: {used}\nToplam: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nKalan günler: {current}\nKullanılan günler: {used}\nToplam günler: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nKalan saatler: {current}\nKullanılan saatler: {used}\nToplam saatler: {earned}",
  "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nKalan günler: Sınırsız\nKullanılan günler: {usedDays}\nToplam günler: Sınırsız",
  "bot.message.myStatus.nextYear": "_Gelecek yıl, {startYear} tarihinden {endYear} tarihine kadar_",
  "bot.message.myStatus.summary": "İşte cari yıl ve gelecek yıl için izinlerinizin özeti.",
  "bot.message.noLeavePolicies": "Görünüşe göre Konumunuza atanmış izin politikası yok. 🤔 Daha fazla bilgi için Yöneticinizle iletişime geçin.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "Kullanıcı {leaveDurationTotal} izin günü talep ediyor, bu da cari yıl için kullanıcının sahip olduğu limitin {overLimitCurrentYear} gün üzerinde ve gelecek yıl için limitin {overLimitNextYear} gün üzerinde.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "Kullanıcı {leaveDurationTotal} {leaveDurationTotal, plural, =1 {gün} other {gün}} izin talep ediyor, bu da cari yıl için kullanıcının sahip olduğu limitin {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {gün} other {gün}} üzerinde ve gelecek yıl için limitin {overLimitNextYear} {overLimitNextYear, plural, =1 {gün} other {gün}} üzerinde.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "Kullanıcı {leaveDurationTotal} {leaveDurationTotal, plural, =1 {saat} other {saat}} izin talep ediyor, bu da cari yıl için kullanıcının sahip olduğu limitin {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {saat} other {saat}} üzerinde ve gelecek yıl için limitin {overLimitNextYear} {overLimitNextYear, plural, =1 {saat} other {saat}} üzerinde.",
  "bot.message.notification.overAllowedLimitForCurrentYear": "Kullanıcı {leaveDurationCurrentYear} izin günü talep ediyor, bu da bu kullanıcının cari yıl için sahip olduğu izin limitinin {overLimit} gün üzerinde.",
  "bot.message.notification.overAllowedLimitForCurrentYearDays": "Kullanıcı {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {gün} other {gün}} izin talep ediyor, bu da bu kullanıcının cari yıl için sahip olduğu izin limitinin {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {gün} other {gün}} üzerinde.",
  "bot.message.notification.overAllowedLimitForCurrentYearHours": "Kullanıcı {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {saat} other {saat}} izin talep ediyor, bu da bu kullanıcının cari yıl için sahip olduğu izin limitinin {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {saat} other {saat}} üzerinde.",
  "bot.message.notification.overAllowedLimitForCurrentYearPlural": "Kullanıcı {leaveDurationCurrentYear} izin günü talep ediyor, bu da bu kullanıcının cari yıl için sahip olduğu izin limitinin {overLimit} gün üzerinde.",
  "bot.message.notification.overAllowedLimitForFollowingYear": "Kullanıcı {leaveDurationNextYear} izin günü talep ediyor, bu da bu kullanıcının gelecek yıl için sahip olduğu izin limitinin {overLimit} gün üzerinde.",
  "bot.message.notification.overAllowedLimitForFollowingYearDays": "Kullanıcı {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {gün} other {gün}} izin talep ediyor, bu da bu kullanıcının gelecek yıl için sahip olduğu izin limitinin {overLimitNextYear} {overLimitNextYear, plural, =1 {gün} other {gün}} üzerinde.",
  "bot.message.notification.overAllowedLimitForFollowingYearHours": "Kullanıcı {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {saat} other {saat}} izin talep ediyor, bu da bu kullanıcının gelecek yıl için sahip olduğu izin limitinin {overLimitNextYear} {overLimitNextYear, plural, =1 {saat} other {saat}} üzerinde.",
  "bot.message.notification.overAllowedLimitForFollowingYearPlural": "Kullanıcı {leaveDurationNextYear} izin günü talep ediyor, bu da bu kullanıcının gelecek yıl için sahip olduğu izin limitinin {overLimit} gün üzerinde.",
  "bot.message.notSignedUpError": "Görünüşe göre Vacation Tracker'a kaydolmamışsınız. Lütfen [{link}]({utmLink}) adresine gidin ve önce bir hesap oluşturun. Bir dakikadan az sürecek!",
  "bot.message.notSignedUpError.slack": "Görünüşe göre Vacation Tracker'a kaydolmamışsınız. Lütfen bir hesap oluşturmak için aşağıdaki düğmeye tıklayın. Bir dakikadan az sürecek!",
  "bot.message.on": "Tarihinde",
  "bot.message.openTheWebDashboard": "Web Kontrol Panelini Aç",
  "bot.message.partDay": "{partDayStartHour} - {partDayEndHour} arası",
  "bot.message.probationPeriod": "*Deneme süresi*: \nDeneme süreniz {date} tarihine kadar devam ediyor \n \nNeden: _{message}_",
  "bot.message.reminder": "Merhaba ekip! Vacation Tracker botundan dostça bir hatırlatma",
  "bot.message.reminderSnoozed": "İstek hatırlatmaları ertelendi",
  "bot.message.requestLeave": "Lütfen aşağıdaki listeden mevcut izin türlerinden birini seçin ve izninizin başlangıç ve bitiş tarihlerini belirleyin. İstek gönder düğmesine tıkladığınızda, isteğiniz onay için gönderilecektir.",
  "bot.message.requestLeave.error": "*Hata: {errorMessage}*",
  "bot.message.requestLeave.errorAutomation": "{errorMessage}",
  "bot.message.requestLeave.initialHelp": "İzin talebinizi göndermenize yardımcı olmak için buradayım. 🐨 Lütfen bir İzin Türü seçin, ardından İleri'ye tıklayın.",
  "bot.message.requestLeave.leaveDuration1": "Bu 1 günlük izin.",
  "bot.message.requestLeave.leaveDurationHalfDay": "Bu yarım günlük izin",
  "bot.message.requestLeave.leaveDurationYear1": "Bu {year1Value} izin.",
  "bot.message.requestLeave.leaveDurationYear1And2": "Bu cari yıl için {year1Value} ve gelecek yıl için {year2Value} izin.",
  "bot.message.requestLeave.leaveDurationYear1And2Days": "Bu cari yıl için {year1Value} {year1Value, plural, =1 {gün} other {gün}} ve gelecek yıl için {year2Value} {year2Value, plural, =1 {gün} other {gün}} izin.",
  "bot.message.requestLeave.leaveDurationYear1And2Hours": "Bu cari yıl için {year1Value} {year1Value, plural, =1 {saat} other {saat}} ve gelecek yıl için {year2Value} {year2Value, plural, =1 {saat} other {saat}} izin.",
  "bot.message.requestLeave.leaveDurationYear1Days": "Bu {year1Value} {year1Value, plural, =1 {gün} other {gün}} izin.",
  "bot.message.requestLeave.leaveDurationYear1Hours": "Bu {year1Value} {year1Value, plural, =1 {saat} other {saat}} izin.",
  "bot.message.requestLeave.leaveDurationYear1PluralYear2": "Bu cari yıl için {year1Days} gün ve gelecek yıl için {year2Days} gün izin.",
  "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "Bu cari yıl için {year1Days} gün ve gelecek yıl için {year2Days} gün izin.",
  "bot.message.requestLeave.leaveDurationYear2": "Bu gelecek yıl için {year2Value} izin.",
  "bot.message.requestLeave.leaveDurationYear2Days": "Bu gelecek yıl için {year2Value} {year2Value, plural, =1 {gün} other {gün}} izin.",
  "bot.message.requestLeave.leaveDurationYear2Hours": "Bu gelecek yıl için {year2Value} {year2Value, plural, =1 {saat} other {saat}} izin.",
  "bot.message.requestLeave.leaveDurationYear2Plural": "Bu gelecek yıl için {year2Days} gün izin.",
  "bot.message.requestLeave.leaveDurationYear2PluralYear1": "Bu, mevcut yıl için {year1Days} gün ve gelecek yıl için {year2Days} gün izin demektir",
  "bot.message.requestLeave.selectCalendarStatus": "Takvim durumunu seçin",
  "bot.message.requestLeave.selectCalendarStatus.info": "*Takvim durumu*\nTakviminizde şöyle göster",
  "bot.message.requestLeave.selectDate.from": "*Başlangıç tarihi*\nLütfen bir başlangıç tarihi seçin",
  "bot.message.requestLeave.selectDate.info": "*Başlangıç tarihi*\nLütfen bir başlangıç tarihi seçin",
  "bot.message.requestLeave.selectDate.oneDay": "*Tarih*\nLütfen bir tarih seçin",
  "bot.message.requestLeave.selectDate.to": "*Bitiş tarihi*\nLütfen bir bitiş tarihi seçin",
  "bot.message.requestLeave.selectLeaveType": "İzin türünü seçin",
  "bot.message.requestLeave.selectLeaveType.info": "*İzin türü*\nLütfen izin türünü seçin",
  "bot.message.requestLeave.selectTimeFormat": "*ÖÖ/ÖS*",
  "bot.message.requestLeave.selectTimeRange": "Zaman aralığını seçin",
  "bot.message.requestLeave.selectTimeRange.end": "*Bitiş tarihi*\nLütfen bir bitiş tarihi seçin",
  "bot.message.requestLeave.selectTimeRange.from": "*Başlangıç Saati*",
  "bot.message.requestLeave.selectTimeRange.info": "*Zaman aralığı*\n Lütfen zaman aralığını seçin",
  "bot.message.requestLeave.selectTimeRange.range": "*Zaman Aralığı*",
  "bot.message.requestLeave.selectTimeRange.to": "*Bitiş Saati*",
  "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\n Bir dahaki sefere görüşmek üzere!",
  "bot.message.requestLeave.submitted.letTeamKnow": "Not: Eğer yardımcı olduysam, lütfen ekibime haber verin!",
  "bot.message.requestLeave.submitted.withApproval": "Talep ettiğiniz tarihler onay için yöneticinize gönderildi",
  "bot.message.requestLeave.submitted.withoutApproval": "Talep ettiğiniz tarihler otomatik olarak onaylandı",
  "bot.message.requestLeaveModal.requestALeave": "İzin talep et",
  "bot.message.requestLeaveModal.requestingLeave": "İzin talep ediliyor",
  "bot.message.requestLeaveModal.sendFeedback": "Geri bildirim gönder",
  "bot.message.requestLeaveModal.sendRequest": "Talebi gönder",
  "bot.message.requestToil.initialHelp": "TOIL talebinizi göndermenize yardımcı olmak için buradayım. 🐨 Lütfen bir İzin Türü seçin, ardından İleri'ye tıklayın.",
  "bot.message.selectADate": "Bir tarih seçin",
  "bot.message.selectSubstituteApprover": "*Yedek Onaylayıcı Seç*\nSiz yokken gelen izin taleplerini yönetecek bir kişi seçin.",
  "bot.message.showHolidays.holidaysHalfDay": "- *{holidayLocationName}* *{holidayName}* için {date} tarihinde izinli olacak (Yarım Gün).\n",
  "bot.message.showHolidays.noUpcomingHolidays": "Organizasyonunuzda yaklaşan tatil yok veya yöneticiniz henüz herhangi bir tatil ayarlamadı.",
  "bot.message.showHolidays.one": "- *{holidayLocationName}* *{holidayName}* için {startDate} ile {endDate} arasında izinli olacak.\n",
  "bot.message.showHolidays.two": "- *{holidayLocationName}* *{holidayName}* için {date} tarihinde izinli olacak.\n",
  "bot.message.showHolidays.upcomingHolidays": "Yaklaşan tatiller",
  "bot.message.slack.userChanged": "Kullanıcı bu talebi değiştirdi",
  "bot.message.slashCommand.helpCommand1": "*İşte hakkımda biraz daha bilgi:*",
  "bot.message.slashCommand.helpCommand2": "Dünya çapında 1000'den fazla kuruluş tarafından güvenilen *Vacation Tracker, ekibinizin her zaman ofiste kimin olduğunu ve olmadığını zahmetsizce takip etmesini sağlar.*",
  "bot.message.slashCommand.helpCommand3": "Hikayemiz hakkında daha fazla bilgi için <https://vacationtracker.io/about|Hakkımızda sayfamızı> ziyaret edin.",
  "bot.message.slashCommand.helpCommand4": "Adım adım kılavuzlar için <https://vacationtracker.crisp.help/en/|Yardım Merkezimizi> ziyaret edin.",
  "bot.message.slashCommand.helpCommand5": "Çevrimiçi Gösterge Paneline giriş yapmak için <{dashboardUrl}/connect?platform=slack> adresini ziyaret edin.",
  "bot.message.slashCommand.helpCommand6": "Ve yolda bize ihtiyacınız olursa, hello@vacationtracker.io adresinden bize bir e-posta uzaklıktayız.",
  "bot.message.slashCommand.noPermissions": "Kötü haberi vermek zorunda olduğum için üzgünüm, ancak Vacation Tracker'a erişim izniniz yok gibi görünüyor. 😟 Lütfen Yöneticinize başvurun.",
  "bot.message.slashCommand.title": "Merhaba <@{userId}>! Biraz izin mi almak istiyorsun? Sana yardımcı olayım!",
  "bot.message.sorryLetsSpokePriveate": "Hmm, özel olarak konuşalım. 🤫",
  "bot.message.sorryYouAreNotActive": "Üzgünüm, hesabınız aktif değil",
  "bot.message.sorryYouAreNotExist": "Üzgünüm, hesabınız bulunamadı",
  "bot.message.submittedLeaveRequest": "Merhaba, <@{userSlackId}> aşağıdaki izin talebini gönderdi:",
  "bot.message.submittedLeaveRequestReminder": "Merhaba, sadece bir hatırlatma, <@{name}> dikkatinizi gerektiren bir talep gönderdi.",
  "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Lütfen dikkat, bu talep {days} gün içinde sona erecek. Bu, 3 hatırlatmadan {reminderNo}. hatırlatmadır.",
  "bot.message.substituteApproverPlaceholder": "🔍 Yok",
  "bot.message.unableToRequestLeave": "İzin talebi yapılamıyor.",
  "bot.message.unknownCommand": "Üzgünüm, bu komutu anlamadım.\nMevcut komutların listesini görmek için **help** yazın.",
  "bot.microsoft.whatsNewInfoMessages": "Bu mesaj **Vacation Tracker**'dan bir duyurudur. Yönetici veya onaylayıcı olduğunuz için bu mesajı alıyorsunuz.",
  "bot.notification.accruedDaysInfoForApprover": "ℹ️ Bu izin talebi, mevcut miktara değil, gelecekteki tahakkuk edecek izin günlerine dayanmaktadır. @{name}'in bugün {amount} gün(leri) kalmıştır. {earningDate} tarihinde {accrued} tahakkuk edecektir.",
  "bot.notification.accruedDaysInfoForUser": "ℹ️ İzin talebiniz, mevcut miktara değil, gelecekteki tahakkuk edecek izin günlerine dayanmaktadır. Bugün {amount} gün(leriniz) kalmıştır ve {earningDate} tarihinde {accrued} tahakkuk edecektir.",
  "bot.notification.adminToApprovers": "Önceki Onaylayıcı'nın hesabı devre dışı bırakıldığı için {teamName} için yeni Onaylayıcı olarak atandınız.",
  "bot.notification.approverCard.approved": "✅ @{approverName} talebi onayladı, başka bir işlem gerekmemektedir.",
  "bot.notification.approverCard.autoApproved": "Talep otomatik olarak onaylanmıştır, başka bir işlem gerekmemektedir.",
  "bot.notification.approverCard.cancelled": "⚠️ Görünüşe göre {requesterName} fikrini değiştirmiş. Talep iptal edildi.",
  "bot.notification.approverCard.cancelledLeave": "Merhaba, {requesterName} iznini iptal etti.",
  "bot.notification.approverCard.cancelledTitle": "⚠️ Bu izin bir onaylayıcı tarafından iptal edildi.",
  "bot.notification.approverCard.denied": "❌ @{approverName} talebi reddetti, başka bir işlem gerekmemektedir.",
  "bot.notification.approverCard.deniedEdited": "❌ @{approverName} düzenlenen talebi reddetti, başka bir işlem gerekmemektedir.",
  "bot.notification.approverCard.editedByApprover": "@{approverName}, @{requesterName} tarafından gönderilen bir izin talebini düzenledi",
  "bot.notification.approverCard.editedByApproverForUser": "@{approverName}, @{requesterName} için bir izin talebini düzenledi.",
  "bot.notification.approverCard.info": "Bu onay veya red için bir sebep ekleyin",
  "bot.notification.approverCard.notActive": "⚠️ Bu izin talebi artık aktif değil.",
  "bot.notification.changeOutOfOfficeMessage": "Şablonu düzenle",
  "bot.notification.daily.info": "Günlük güncellemenizin zamanı geldi! 🐨 İşte yaklaşan izinler:",
  "bot.notification.daily.noLeaves.isNotForToday": "{dayName} günü için planlanmış izin yok",
  "bot.notification.daily.noLeaves.isToday": "Bugün için planlanmış izin yok",
  "bot.notification.holiday.plural": "- **{locationNames}** lokasyonları **{holidayName}** için {holidayPeriodText} izinli olacak",
  "bot.notification.holiday.singular": "- **{locationNames}** lokasyonu **{holidayName}** için {holidayPeriodText} izinli olacak",
  "bot.notification.leave.info.isInTheFuture": "{leaveTypeText} izinde olacak {leavePeriodText}",
  "bot.notification.leave.info.isInThePast": "{leaveTypeText} izinde olacak {leavePeriodText}",
  "bot.notification.leave.info.isToday": "{leaveTypeText} izinde {leavePeriodText}",
  "bot.notification.leaveDates.partDay": "{start} tarihinde {partDayStartHour} - {partDayEndHour} saatleri arasında",
  "bot.notification.leavePeriod.fullDay": "{day} tarihinde",
  "bot.notification.leavePeriod.halfDay": "{day} tarihinde (Yarım Gün)",
  "bot.notification.leavePeriod.moreDays": "{start} tarihinden {end} tarihine kadar",
  "bot.notification.leavePeriod.partDay": "{start} tarihinde {partDayStartHour} - {partDayEndHour} saatleri arasında",
  "bot.notification.leavePolicyInfo": "*Lütfen dikkat: bu kullanıcı veya bu izin türü için talep edilen izin için 0 kota ayarlanmış.*",
  "bot.notification.leaveRequestError.general": "İzin talebiyle ilgili bir hata",
  "bot.notification.leaveRequestError.notApprover": "Bu işlem yalnızca ekip onaylayıcıları/yöneticileri için izin verilen bir işlemdir",
  "bot.notification.leaveRequestError.notOpen": "İzin talebi {leaveRequestStatus} olmuştur",
  "bot.notification.leaveRequestError.notOpenIsSameAppover": "İzin talebi {leaveRequestStatus} olmuştur",
  "bot.notification.leaveRequestExpired": "Talebinizin süresi doldu",
  "bot.notification.leaveRequestExpiredGeneral": "Talebin süresi doldu",
  "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} - Yarım Gün)",
  "bot.notification.oooDefaultMessage": "Merhaba,\n\nMesajınız için teşekkür ederim. Şu anda ofis dışındayım ve {firstWorkingDay} tarihine kadar e-postalara yanıt veremeyeceğim.\n\nDöndüğümde e-postanıza en kısa sürede yanıt vermeye çalışacağım.\n\nAnlayışınız için teşekkür ederim.\n\nSaygılarımla",
  "bot.notification.processingApproval": "Onay işleniyor...",
  "bot.notification.processingDenial": "Red işleniyor...",
  "bot.notification.processingResend": "İzin talebi yeniden gönderiliyor...",
  "bot.notification.processingSnooze": "Hatırlatıcılar erteleniyor...",
  "bot.notification.reconnectCalendar": "Yeniden bağlan",
  "bot.notification.reminderSent": "İzin talebi hatırlatıcısı gönderildi",
  "bot.notification.reminderSentToil": "TOIL talebi hatırlatıcısı gönderildi",
  "bot.notification.requesterCard.addedLeave": "Sizin için izin oluşturuldu",
  "bot.notification.requesterCard.addedLeaveBy": "{approverName} tarafından eklendi",
  "bot.notification.requesterCard.addedLeaveToil": "Sizin için TOIL oluşturuldu",
  "bot.notification.requesterCard.approvedTitle": "İzin Talebiniz Onaylandı",
  "bot.notification.requesterCard.approvedTitleToil": "TOIL Talebiniz Onaylandı",
  "bot.notification.requesterCard.approverInfo": "{approverName} tarafından onaylandı",
  "bot.notification.requesterCard.autoApprovedInfo": "Talep otomatik olarak onaylandı.",
  "bot.notification.requesterCard.blackoutPeriod": "Dönem: {startDate} - {endDate}",
  "bot.notification.requesterCard.blackoutPeriodReason": "Sebep: {message}",
  "bot.notification.requesterCard.blackoutPeriodTitle": "Kapalı Dönem",
  "bot.notification.requesterCard.cancelled": "⚠️ İptal edildi",
  "bot.notification.requesterCard.cancelledEdited": "⚠️ Orijinal talep üzerinde değişiklikler yapıldı.",
  "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} bu izin talebini düzenledi.",
  "bot.notification.requesterCard.cancelledTitle": "⚠️ İzniniz bir onaylayıcı tarafından iptal edildi.",
  "bot.notification.requesterCard.deniedBy": "İzin Talebiniz Onaylandı",
  "bot.notification.requesterCard.deniedEditedDescription": "Düzenlenen izin talebiniz reddedildi, ancak orijinal talebiniz hala aktif durumda.",
  "bot.notification.requesterCard.deniedEditedTitle": "İzin talebi düzenleme reddedildi.",
  "bot.notification.requesterCard.deniedOpenDescription": "Hem orijinal izin talebiniz hem de düzenlenen izin talebiniz reddedildi.",
  "bot.notification.requesterCard.deniedOpenTitle": "İzin talebi reddedildi",
  "bot.notification.requesterCard.deniedTitle": "İzin Talebiniz Reddedildi",
  "bot.notification.requesterCard.edited": "@{requesterName} daha önce onaylanmış talebini düzenledi ve yeniden onay için gönderiyor:",
  "bot.notification.requesterCard.editedOpen": "@{requesterName} izin talebini düzenledi ve yeniden gönderdi.",
  "bot.notification.requesterCard.moreDays": "❌ Mevcut olandan daha fazla gün talep ettiniz.",
  "bot.notification.requesterCard.moreThanFullDay": "❌ İzin talebi gönderilemedi çünkü bir iş gününde birden fazla tam gün izin talep ettiniz.",
  "bot.notification.requesterCard.noWorkingDays": "❌ İzin talebi gönderilemedi çünkü herhangi bir çalışma günü dahil etmediniz. Talebi göndermeden önce ekibinizin çalışma günlerini gözden geçirdiğinizden emin olun.",
  "bot.notification.requesterCard.originalLeaveRequest": "Orijinal izin talebi:",
  "bot.notification.requesterCard.overlap": "❌ İzin talebi gönderilemiyor çünkü talep ettiğiniz tarihler mevcut bir izin talebinin tarihleriyle çakışıyor.",
  "bot.notification.requesterCard.probationPeriod": "Deneme süreniz {date} tarihine kadar devam ediyor",
  "bot.notification.requesterCard.probationPeriodReason": "Sebep: {message}",
  "bot.notification.requesterCard.probationPeriodTitle": "Deneme Süresi",
  "bot.notification.requesterCard.requestInfo": "Talebiniz onaylayıcıya gönderildi. Bir dahaki sefere kadar! 🐨",
  "bot.notification.requesterCard.requestPossibleActions": "Talebi düzenlemek veya iptal etmek için Kontrol Panelini ziyaret edin",
  "bot.notification.requesterCardapp.tryAnotherDates": "Başka tarihler deneyin",
  "bot.notification.requesterInfo": "Merhaba, @{userName} incelemeniz için bir talep gönderdi.",
  "bot.notification.requesterInfoAutoApproved": "Merhaba, @{userName} bir talep gönderdi.",
  "bot.notification.requesterInfoEdited": "@{userName} talebini düzenledi ve yeniden gönderdi.",
  "bot.notification.requesterInfoEditedByApprover": "@{approverName}, @{userName} tarafından gönderilen bir talebi düzenledi",
  "bot.notification.requesterInfoEditedForUser": "Talebiniz düzenlendi.",
  "bot.notification.requesterInfoEditedForUserByApprover": "Onaylayıcı @{approverName} talebinizde değişiklikler yaptı.",
  "bot.notification.requesterInfoEditedOpen": "@{userName} talebini düzenledi ve yeniden gönderdi.",
  "bot.notification.setOutOfOffice": "{leaveTypeName} izniniz {leavePeriodStart} tarihinde, {days} {days, plural, =1 {günden} other {günden}} daha az bir süre içinde başlayacak. İzinde olduğunuz süre boyunca otomatik bir ofis dışı mesajı ayarlamak ister misiniz?",
  "bot.notification.setOutOfOfficeButton": "OOO mesajı ayarla",
  "bot.notification.setOutOfOfficeFallbackText": "{leaveTypeName} izniniz için ofis dışı mesajı ayarlayın",
  "bot.notification.slack.additional": "... ve daha fazlası.\nBoyut sınırlaması nedeniyle ek izinler görüntülenemedi. Lütfen tam bir genel bakış için Kontrol Panelini açın.",
  "bot.notification.slack.approved": "✅ <@{approverName}> talebi onayladı, başka bir işlem gerekmiyor.",
  "bot.notification.slack.approverChanged": "Merhaba, onaylayıcı <@{approverSlackId}> bir izin talebini değiştirdi.",
  "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "Onaylayıcı <@{approverSlackId}> talebin tarihlerini değiştirdi",
  "bot.notification.slack.approverChangedLeaveReqest": "Merhaba, onaylayıcı <@{approverSlackId}> <@{userSlackId}> için aşağıdaki izin talebini değiştirdi:",
  "bot.notification.slack.approverChangedYour": "Onaylayıcı <@{approverSlackId}> izin talebinizi değiştirdi.",
  "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> bu talebi reddetti, başka bir işlem gerekmiyor",
  "bot.notification.slack.calendar": ":calendar: |  *{dayName}*  | :calendar: ",
  "bot.notification.slack.cancelled": "Merhaba, <@{userName}> izinlerini iptal etti.",
  "bot.notification.slack.cancelledByApprover": "İzniniz bir onaylayıcı tarafından iptal edildi",
  "bot.notification.slack.cancelledInfo": "Bu izin bir onaylayıcı tarafından iptal edildi",
  "bot.notification.slack.created": "Sizin için bir onaylayıcı tarafından izin oluşturuldu",
  "bot.notification.slack.holiday.plural": "- *{locationNames}* konumları *{holidayName}* için {holidayPeriodText} süresince dışarıda olacak",
  "bot.notification.slack.holiday.singular": "- *{locationNames}* konumu *{holidayName}* için {holidayPeriodText} süresince dışarıda olacak",
  "bot.notification.slack.holidays": ":airplane: *Tatiller: *",
  "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* {leavePeriodText} süresince {leaveTypeText} izninde olacak",
  "bot.notification.slack.leave.info.isInThePast": "- *{userName}* {leavePeriodText} süresince {leaveTypeText} iznindeydi",
  "bot.notification.slack.leave.info.isToday": "- *{userName}* {leavePeriodText} süresince {leaveTypeText} izninde",
  "bot.notification.slack.leaveRequestCreated": "Merhaba, <@{userName}> bir izin talebi gönderdi.",
  "bot.notification.slack.leaveRequestInfo": "Merhaba, <@{userName}> lütfen izin talebi bilgilerini onaylayın.",
  "bot.notification.slack.leaveRequestInfoDateFrom": "Başlangıç tarihi: {date}",
  "bot.notification.slack.leaveRequestInfoDateTo": "Bitiş tarihi: {date}",
  "bot.notification.slack.leaveRequestInfoError": "Sağlanan verilerden izin öneremiyoruz",
  "bot.notification.slack.leaveRequestInfoLeaveType": "İzin türü: {leaveType}",
  "bot.notification.slack.leaveRequestInfoReason": "Sebep: {reason}",
  "bot.notification.slack.leaveRequestSuggestionCancelled": "İzin talep formu iptal edildi ❌",
  "bot.notification.slack.leaves": ":loud_sound: *İzinler: *",
  "bot.notification.slack.reason": "*Sebep*\n{reason}",
  "bot.notification.slack.reasonEdited": "*Sebep [Düzenlendi] *\n{reason}",
  "bot.notification.slack.requestedBy": "*Talep eden:*\n<@{userSlackId}>",
  "bot.notification.slack.statusApproved": "*Durum:*\n✅ Onaylandı",
  "bot.notification.slack.statusAutoApproved": "*Durum*\nOtomatik onaylandı",
  "bot.notification.slack.statusDenied": "*Durum*\n❌ Reddedildi",
  "bot.notification.slack.statusInfo": "👋 Artık bu izin türü için Slack durumunuzu otomatik olarak ayarlayabiliriz! İzniniz başladığında durumunuzu güncellememize izin vermek için Etkinleştir düğmesine tıklayın. İzin bittiğinde durumu temizleyeceğiz.",
  "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "Bu izin bir onaylayıcı tarafından iptal edildi. Daha fazla bilgi için aşağıya bakın.",
  "bot.notification.slack.title": "Bildirim: \"_{title}_\"",
  "bot.notification.slack.userCancelledLeaveRequest": "Görünüşe göre <@{userSlackId}> fikrini değiştirmiş. Talep iptal edildi.",
  "bot.notification.slack.warning": ":warning: *Uyarı: {infoMessage}*",
  "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "İzniniz bir onaylayıcı tarafından iptal edildi. Daha fazla bilgi için aşağıya bakın.",
  "bot.notification.slack.yourLeaveRequestApproved": "Talebiniz onaylandı",
  "bot.notification.slack.yourRequestHasBeenDenied": "Talebiniz reddedildi",
  "bot.notification.snoozeOutOfOfficeButton": "Bir daha sorma",
  "bot.notification.snoozeOutOfOfficeMessage": "{leaveTypeName} için Ofis Dışında ayarı yapmanızı artık istemeyeceğiz. Bu ayarı her zaman Vacation Tracker Web Paneli - Hesap Ayarları sayfasından değiştirebilirsiniz",
  "bot.notification.submittedLeaveRequestReminder": "Merhaba, sadece bir hatırlatma: @{name} dikkatinizi gerektiren bir talep gönderdi.",
  "bot.notification.substituteApproverDeleted": "{startDate} - {endDate} tarihleri için Yedek Onaylayıcı rolünüz kaldırıldı.",
  "bot.notification.substituteApproverSelected": "{creatorName} tarafından {approverName}'in yokluğunda {teamNames} departman(lar)ı için yedek onaylayıcı olarak seçildiniz. Yedek onaylayıcı olarak, {startDate} ile {endDate} arasında {approverName}'e gönderilen izin taleplerini incelemek ve işlem yapmakla sorumlu olacaksınız.",
  "bot.notification.substituteApproverUpdated": "Yedek Onaylayıcı olarak görev süreniz {startDate} ile {endDate} arasında güncellendi.",
  "bot.notification.title": "Bildirim: {title}",
  "bot.notification.weekly.current.info": "Merhaba!\n 🐨 Bu haftanın ({start} - {end}) planlanmış izinleri hakkında sizi bilgilendirmek için buradayım",
  "bot.notification.weekly.next.info": "Merhaba!\n 🐨 Gelecek haftanın ({start} - {end}) planlanmış izinleri hakkında sizi bilgilendirmek için buradayım",
  "bot.notification.weekly.noHolidays": "Bu hafta için tatil yok.",
  "bot.notification.weekly.noLeaves": "Bu hafta için planlanmış izin yok.",
  "bot.slack.whatsNewInfoMessages": "Bu mesaj *Vacation Tracker*'dan bir duyurudur. Yönetici veya onaylayıcı olduğunuz için bu mesajı alıyorsunuz.",
  "bot.slackFuckedUpAgain": "Slack yine bir şeyler düzeltiyor, lütfen Slack hatalarını düzeltmek için desteğimizle iletişime geçin.",
  "bot.somethingNewFromVacationTracker": "Vacation Tracker'dan yeni bir şey",
  "bot.welcomeMessage.furtherAssistance": "Tüm bu bilgiler sizi bunalttı mı? Endişelenmeyin! Hepsini ezberlemenize gerek yok. Sohbetimizde 'Yardım' yazmanız yeterli, botumuz bunu size gönderecektir. Daha fazla yardım için, web sitemiz üzerinden Müşteri Destek ekibimizle sohbet etmekten veya Yardım Masasını ziyaret etmekten çekinmeyin.",
  "bot.welcomeMessage.goToMyProfile": "Profilim sekmesine git",
  "bot.welcomeMessage.greeting": "Merhaba {name}, ben Vacation Tracker Bot'uyum! Microsoft Teams üzerinden izinleri planlamanıza yardımcı olmak ve herkesin ofiste kimin içeride kimin dışarıda olduğu konusunda güncel kalmasını sağlamak için buradayım. Daha fazla vakit kaybetmeden, haydi izinleri takip etmeye başlayalım!",
  "bot.welcomeMessage.happyVacationing": "İyi tatiller 🌴",
  "bot.welcomeMessage.howToAccessTabs": "Sekmelere nasıl erişilir",
  "bot.welcomeMessage.noCompany": "Merhaba, \n\n Vacation Tracker bot'unu ve sekmesini Microsoft Teams'e yüklediğiniz için teşekkür ederiz. Son adım olarak, lütfen web sitemize giderek kaydı tamamlayın ve organizasyonunuzun ayarlarını yapılandırın: [{dashboardLink}]({utmDashboardLink})",
  "bot.welcomeMessage.noCompanyInfo": "Herhangi bir sorunuz varsa, web sitemiz üzerinden müşteri destek ekibimizle sohbet başlatmaktan veya yardım masamızı ziyaret etmekten çekinmeyin.",
  "bot.welcomeMessage.openWebDashboardInfo": "Aynı bilgilere web panelimizdende erişilebilir. Erişmek için aşağıdaki 'Web Panelini Aç' butonuna tıklayın ve Microsoft hesabınızla giriş yapın.",
  "bot.welcomeMessage.requestLeaveInfo": "Başlamak için, bana 'İzin talep et' içerikli bir direkt mesaj gönderin veya Vacation Tracker hakkındaki bu kısa eğitim videosunu izleyin:",
  "bot.welcomeMessage.restInfo": "Bot dışında, Microsoft Teams üzerinden sekmeler kullanarak da izin talebinde bulunabilir, izin kotanızı kontrol edebilir, ekip takvimlerini görüntüleyebilir ve daha fazlasını yapabilirsiniz:",
  "bot.welcomeMessage.title": "Vacation Tracker'dan Merhaba",
  "botUpdatePermissions.thanks": "Teşekkürler! Her şey ayarlandı. Paneli kapatabilirsiniz.",
  "button.no": "Hayır",
  "button.yes": "Evet",
  "calendar.all": "Tümü",
  "calendar.calendar": "Takvim",
  "calendar.filterByLocation": "Konuma Göre Filtrele:",
  "calendar.filterByTeam": "Departmana Göre Filtrele:",
  "calendar.forAllUsers": "Tüm kullanıcılar için",
  "calendar.getCalendarLink": "Takvim bağlantısını al",
  "calendar.halfdayTags": "Tam günden kısa izinler ve tatiller çizgili bloklarla gösterilir.",
  "calendar.halfdayTagsShort": "Bir günden kısa izinler",
  "calendar.labels": "Etiket(ler)",
  "calendar.listView": "Liste görünümü",
  "calendar.location": "Konum(lar)",
  "calendar.monthView": "Ay görünümü",
  "calendar.sync.calendarAlreadyConnected": "Bu takvim zaten bağlı",
  "calendar.sync.calendarNotFound": "Takvim bulunamıyor. Silinmiş veya artık mevcut olmayabilir. Lütfen takvim ayarlarınızı gözden geçirin.",
  "calendar.sync.canNotUpdateSharedCalendar": "{calendarName} {provider} takvimini bağladınız. Takvimi değiştirmek mümkün değil. Bağlı takvimi değiştirmek için lütfen bu entegrasyonu silin ve yeni bir tane oluşturun.",
  "calendar.sync.confirmDelete": "Lütfen <strong>{name}</strong> takvimini silmek istediğinizi onaylayın.",
  "calendar.sync.connect": "{provider} Takviminizi Bağlayın",
  "calendar.sync.connectCalendar": "Takvimi Bağla",
  "calendar.sync.connectCalendarCompletePlanyOnly": "SADECE TAM PLAN",
  "calendar.sync.connectCalendarIcalOptionLabel": "Herhangi bir takvim (iCal bağlantısı ile)",
  "calendar.sync.connectCalendarOptionLabel": "{platform} takvimi",
  "calendar.sync.connectInfo": "{provider} hesabınızı bağlayın ve onaylanan tüm izinlerin otomatik olarak gönderileceği ve senkronize edileceği paylaşılan bir takvim seçin.",
  "calendar.sync.createICalButton": "iCal bağlantısı oluştur",
  "calendar.sync.createICalFiltersInfo": "Bu iCal takvim bağlantısına aşağıdaki filtreler uygulanacaktır:",
  "calendar.sync.createICalFiltersWarning": "Takviminiz iCal verilerini gerçek zamanlı olarak yenilemez. Gecikme 24 saate kadar olabilir (takvim sağlayıcınıza bağlı olarak). Gerçek zamanlı güncellemeler için Google Takvim veya Microsoft Outlook Takvim'inizi doğrudan bağlayın.",
  "calendar.sync.createICalInfo": "iCal takvim bağlantısı, ekibinizin Vacation Tracker'dan planlanan izinlerini herhangi bir takvimde (Google Takvim, Microsoft Outlook Takvim, Apple Takvim ve daha fazlası dahil) abone olmanıza ve görüntülemenize olanak tanır.",
  "calendar.sync.createPushToSharedInfo": "Ve filtrelenmiş onaylanan izinleri aşağıdaki paylaşılan {provider} takvimine gönder ve senkronize et:",
  "calendar.sync.createSyncCalendarButton": "Takvim senkronizasyonu oluştur",
  "calendar.sync.createSyncCalendarFiltersInfo": "Vacation Tracker, tüm onaylanan izinlere aşağıdaki filtreleri uygulayacaktır:",
  "calendar.sync.creating": "Takvim senkronizasyonu oluşturuluyor",
  "calendar.sync.creatingError": "Takvim senkronizasyonu oluşturulurken hata",
  "calendar.sync.creatingSuccess": "Takvim senkronizasyonu başarıyla oluşturuldu",
  "calendar.sync.deletedCalendar": "Bu takvim senkronizasyonu silindi",
  "calendar.sync.deleteTitle": "Takvimi sil",
  "calendar.sync.deleting": "Takvim senkronizasyonu siliniyor",
  "calendar.sync.deletingError": "Takvim senkronizasyonu silinirken hata oluştu",
  "calendar.sync.deletingSuccess": "Takvim senkronizasyonu başarıyla silindi",
  "calendar.sync.emptyDataMessage": "Tüm ekip izinlerini paylaşılan takviminizde senkronize edin ve görüntüleyin. Outlook takviminizi veya Google Takvim'inizi bağlayabilir veya Vacation Tracker'ı başka bir takvim türüyle senkronize etmek için iCal'ı kullanabilirsiniz.",
  "calendar.sync.emptyDataTitle": "İzinleri ekip takviminizle senkronize edin",
  "calendar.sync.loginWithDifferentAccountInfo": "Takvimi göremiyor musunuz?",
  "calendar.sync.loginWithDifferentAccountLink": "Başka bir {provider} hesabıyla giriş yapın",
  "calendar.sync.reconnect": "{provider} Takviminizi yeniden bağlayın",
  "calendar.sync.reconnectInfo": "Paylaşılan takviminizle otomatik izin senkronizasyonuna devam etmek için {provider} hesabınızı yeniden bağlayın.",
  "calendar.sync.reconnecting": "Takvim senkronizasyonu yeniden bağlanıyor",
  "calendar.sync.reconnectingError": "Takvim senkronizasyonu yeniden bağlanırken hata oluştu",
  "calendar.sync.reconnectingSuccess": "Takvim senkronizasyonu yeniden bağlanma başarılı oldu",
  "calendar.sync.selectSharedCalendarPlaceholder": "Paylaşılan {provider} takvimini seçin",
  "calendar.sync.stepByStep": "Adım adım talimatlar",
  "calendar.sync.title": "Ekip takviminizi senkronize edin",
  "calendar.sync.tooltipInfo": "Tüm Senkronize Takvimleriniz daha kolay yönetim için bu sayfada bir araya geliyor. Daha fazla bilgi için lütfen <helpDesk>Yardım Masası makalemize</helpDesk> bakın.",
  "calendar.sync.update": "{provider} Takviminizi güncelleyin",
  "calendar.sync.updateSyncCalendarButton": "Takvim senkronizasyonunu güncelle",
  "calendar.sync.updating": "Takvim senkronizasyonu güncelleniyor",
  "calendar.sync.updatingError": "Takvim senkronizasyonu güncellenirken hata oluştu",
  "calendar.sync.updatingSuccess": "Takvim senkronizasyonu güncelleme başarılı oldu",
  "calendar.syncButton": "Takviminizle senkronize edin (Google, Outlook, vb.)",
  "calendar.syncModal.description": "Aşağıdaki bağlantıyı kopyalayın ve Google Takvim, Outlook Takvim veya iCal takvim beslemelerini destekleyen diğer herhangi bir takvime entegre edin.",
  "calendar.syncModal.filters": "Bu takvim bağlantısına aşağıdaki filtreler uygulanır:",
  "calendar.syncModal.helpdesk": "Yardım ve adım adım talimatlar için <helpDesk>Yardım Masamızı</helpDesk> ziyaret edin.",
  "calendar.team": "Departman(lar)",
  "calendar.timezone.info": "Zaman Diliminize göre dönüştürülmüş saat",
  "calendar.week.monFri": "Pzt-Cum",
  "calendar.week.sunThu": "Paz-Per",
  "calendarListModal.description": "Lütfen mevcut takvimler listesinden bir takvim seçin. Bir takvimi bağladıktan sonra, Vacation Tracker izinlerinizi seçilen takvime ekleyecek, düzenleyecek veya iptal edecektir.",
  "calendarListModal.descriptionGoogleFirstLine": "Lütfen mevcut takvimler listesinden bir takvim seçin. ",
  "calendarListModal.descriptionOutlookFirstLine": "Yalnızca varsayılan takviminizi bağlayabilirsiniz. ",
  "calendarListModal.descriptionSecondLine": "Bir takvimi bağladıktan sonra, Vacation Tracker izinlerinizi seçilen takvime ekleyecek, düzenleyecek veya iptal edecektir.",
  "calendarListModal.hiddenLeaveTypesDescription": "<helpdesk>Gizli izin türlerini görüntüleme</helpdesk> hakkında daha fazla bilgi edinin.",
  "calendarListModal.reconnectDescription": "Bağlı takviminizle otomatik izin senkronizasyonuna devam etmek için takviminizi yeniden bağlayın.",
  "calendarListModal.reconnectTitle": "Takvimi Yeniden Bağla",
  "calendarListModal.selectPlaceHolder": "Lütfen bir takvim seçin...",
  "calendarListModal.title": "Takvim Seç",
  "channel_not_found": "Seçilen kanal silinmiş veya artık mevcut değil. Lütfen Bildirimleri almak için başka bir kanal seçin.",
  "choiceAutomations.accessibleTo": "Erişilebilir: <approver>Onaylayıcı </approver><and>ve </and><admin>Yönetici </admin><only>sadece</only>",
  "choiceAutomations.activeSubscribedAddons": "Aktif abonelikli eklentiler",
  "choiceAutomations.activeSubscribedAutomations": "Aktif abonelikli Otomasyonlar",
  "choiceAutomations.adpIntegration": "Bordro aracınızla doğrudan bağlantı sağlayarak, tüm platformlarda bordro verilerinin doğru ve güncel olmasını sağlar.",
  "choiceAutomations.automationPrice": "Fiyat: ${price}/kullanıcı/ay",
  "choiceAutomations.automationPriceInfo": "Aşağıdaki otomasyonlardan herhangi birini $0.50/kullanıcı/ay fiyatına satın alabilir ve etkinleştirebilirsiniz",
  "choiceAutomations.bambooHrIntegration": "İK aracınızla doğrudan bağlantı sağlayarak, sistemler arasında verilerin doğru ve tutarlı kalmasını sağlar.",
  "choiceAutomations.blackoutPeriodInfo": "Karartma dönemi, çalışanların işten izin alamayacakları belirli bir zaman dilimidir.",
  "choiceAutomations.cancelAtPeriodEnd": "Fatura dönemi sonuna kadar aktif",
  "choiceAutomations.cancelAtPeriodEndTooltip": "Bu eklentinin aboneliğini iptal ettiniz. Mevcut fatura döneminin sonuna kadar aktif kalacak ve ardından devre dışı bırakılacaktır.",
  "choiceAutomations.comingSoonScheduleCall": "Üzerinde çalıştığımız otomasyon ile ilgili özel ihtiyaçlarınız hakkında detayları paylaşmaktan çekinmeyin.",
  "choiceAutomations.comingSoonScheduleCallTitle": "Yakında geliyor",
  "choiceAutomations.deacticate": "Aboneliği iptal et",
  "choiceAutomations.employmentLeaveRestrictionInfo": "Erken istihdam izin politikası, yeni çalışan izin talebi kısıtlamasıyla istihdamın ilk günlerinde özel bir yerleşme süresi sağlanmasına olanak tanır.",
  "choiceAutomations.enitlementByRoleShortInfo": "Çalışanlara, etiket(ler) ile tanımlanan rollerine göre ek izin günleri verilir.",
  "choiceAutomations.entitlementByRoleInfo": "Çalışanlara, etiket(ler) ile tanımlanan rollerine göre ek izin günleri verilir. Örneğin, 'Yönetici' adında bir etiket oluşturabilir ve organizasyonunuzdaki tüm yöneticilere atayabilirsiniz. Ardından, 'Yönetici' etiketine sahip tüm çalışanlara 5 ek izin günü veren bir otomasyon oluşturabilirsiniz. Farklı roller için birden fazla otomasyon oluşturabilirsiniz. Bu otomasyon, mevcut mali yılınızdan itibaren geçerlidir. Bu otomasyonu geçmiş yıllara uygulamanız gerekiyorsa <link>bize ulaşın</link>.",
  "choiceAutomations.includedInYourPlan": "Planınıza dahil",
  "choiceAutomations.leaveDurationLimitInfo": "İzin Süresini Özelleştirin: İstenen dönem için minimum ve maksimum gün sayısını belirleyin.",
  "choiceAutomations.mySbscribedAddons": "Abone olduğum eklentiler",
  "choiceAutomations.notActivatedAddons": "Abonelik için uygun",
  "choiceAutomations.openApiInfo": "Dış sistemlerle sorunsuz entegrasyon sağlar, otomatik veri senkronizasyonu ve gelişmiş işlevsellik sunar",
  "choiceAutomations.reviewAndBuy": "İncele ve satın al",
  "choiceAutomations.selectAutomations": "Tercih ettiğiniz Otomasyonu seçin",
  "choiceAutomations.seniorityEntitlementInfo": "Kıdem hakkı, çalışanların şirketteki hizmet sürelerine bağlı olarak daha fazla ücretli izin (PTO) kazandıkları bir sistemdir. Şu anda, konum başına yalnızca bir Kıdem hakkı oluşturabilirsiniz. Bu otomasyon, mevcut mali yılınızdan itibaren geçerlidir. Bir konumda birden fazla Kıdem hakkına ihtiyacınız varsa veya bu otomasyonu geçmiş yıllara uygulamanız gerekiyorsa <link>bize ulaşın</link>.",
  "choiceAutomations.seniorityEntitlementShortInfo": "Kıdem hakkı, çalışanların şirketteki hizmet sürelerine bağlı olarak daha fazla ücretli izin (PTO) kazandıkları bir sistemdir.",
  "choiceAutomations.teamLeaveBalancingInfo": "Eşzamanlı ekip izin yüzdesini yönetin.",
  "choiceAutomations.visibilityInfo": "Yöneticilerin normal kullanıcılar için izin bilgisi görünürlüğünü özelleştirmesine olanak tanır, gizliliği ve veri uygunluğunu artırır.",
  "company.platformChanged": "Yeni bir platforma geçiş yapılıyor",
  "company.platformChanged.confirmDescription": "Yeni bir platforma geçmek üzeresiniz. Bu işlem geri alınamaz. Lütfen devam etmeden önce aşağıdaki bilgileri gözden geçirin.",
  "company.platformChanged.confirmDescription.unmappedUsers.deleted": "{newPlatform} hesabınızda bulunmayan {unmappedUsers} kullanıcı bulduk. Bu kullanıcılar, geçiş tamamlandığında SİLİNDİ olarak işaretlenecektir.",
  "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "{newPlatform} hesabınızda bulunmayan {unmappedUsers} kullanıcı bulduk. Bu kullanıcılar Vacation Tracker'a giriş yapamayacaklar.",
  "company.platformChanged.confirmQuestion": "Devam etmek istediğinizden emin misiniz?",
  "company.platformChanged.confirmTitle": "Geçişi onayla",
  "company.platformChanged.description": "Lütfen Vacation Tracker kullanıcılarınızı {newPlatform} hesaplarıyla eşleştirin. Aynı e-postalara sahip kullanıcıları otomatik olarak eşleştireceğiz.",
  "company.platformChanged.googleAccount": "Google Hesabı",
  "company.platformChanged.hideInactive": "Aktif olmayanları gizle:",
  "company.platformChanged.markUnmappedAsDeleted": "Eşleşmeyen kullanıcıları silinmiş olarak işaretle?",
  "company.platformChanged.microsoftAccount": "Microsoft Hesabı",
  "company.platformChanged.slackAccount": "Slack Hesabı",
  "company.platformChanged.title": "{oldPlatform}'dan {newPlatform}'a geçiş yapılıyor",
  "company.platformChanged.unmatched": "{platform}'da bulunamadı",
  "company.platformChanged.vacationTrackerUser": "Vacation Tracker Kullanıcısı",
  "component.filterSimple.filterBy": "Filtrele",
  "component.filterSimple.selectType": "Tür seçin",
  "component.filterSimple.tooltipInfo": "Herhangi bir filtre seçmezseniz, dışa aktarma tüm kullanıcıları içerecektir.",
  "component.notificationDestination.label": "Gönderilecek yer",
  "component.notificationDestination.tooltip": "Bildirimi birden fazla e-postaya gönderebileceğinizi, ancak aynı anda yalnızca bir kanala gönderebileceğinizi unutmayın.",
  "component.password.timeToHack.days": "{duration} gün",
  "component.password.timeToHack.hours": "{duration} saat",
  "component.password.timeToHack.instantly": "Anında",
  "component.password.timeToHack.label": "Hackerlerin bu şifreyi kırması için gereken süre:",
  "component.password.timeToHack.minutes": "{duration} dakika",
  "component.password.timeToHack.perfect": "Bir ömürden daha uzun",
  "component.password.timeToHack.seconds": "{duration} saniye",
  "component.password.timeToHack.years": "{duration} yıl",
  "component.setPassword.enterOldPassword": "Eski şifreyi girin",
  "component.setPassword.enterPassword": "Şifre girin",
  "component.setPassword.tooltip.description.line1": "Yukarıdaki bilgiler, seçtiğiniz şifreyi kaba kuvvet saldırısıyla kırmak için hackerlerin ihtiyaç duyduğu tahmini maksimum süreyi gösterir. Tahmin, <link>bu makalenin</link> <italic>\"RTX 3090 GPU kullanarak PBKDF2 hash fonksiyonunu kırma şifre tablosu\"</italic> tablosuna dayanmaktadır.",
  "component.setPassword.tooltip.description.line2": "İyi bir şifre nasıl seçilir? İyi bir şifre seçmenin birçok yolu vardır. Biz şifre yöneticileri kullanıyoruz ve şifreleri tekrar kullanmıyoruz. Daha fazla ilham için bu <link>komik ama doğru kılavuza</link> göz atın.",
  "component.setPassword.tooltip.title": "Hackerlerin şifrenizi kaba kuvvetle kırması için gereken süre",
  "component.setPassword.validation": "Şifre en az şunları içermelidir: 8 karakter, bir küçük harf, bir rakam ve bir sembol.",
  "components.accrualsCalculator.calculate": "Hesapla",
  "components.accrualsCalculator.calculateAccrualsDays": "Tahakkuk Günlerini Hesapla",
  "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "O tarihte mevcut dönemde <strong>{days}</strong> gün kazanacaksınız",
  "components.accrualsCalculator.youWillEarnedInNextPeriod": "O tarihte bir sonraki dönemde <strong>{days}</strong> gün kazanacaksınız",
  "components.addHolidayForm.addHolidays": "Tatil Ekle",
  "components.addHolidayForm.addHolidays.cancel": "İptal",
  "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Yarım Gün Tatil",
  "components.addHolidayForm.addHolidays.isHolidayMultiday": "Birden Fazla Gün mü?",
  "components.addHolidayForm.addHolidays.saveHoliday": "Kaydet",
  "components.addHolidayForm.date.error": "Lütfen bir tarih seçin",
  "components.addHolidayForm.editHoliday": "Tatili düzenle",
  "components.addHolidayForm.editHolidays": "Tatilleri düzenle",
  "components.addHolidayForm.endDate.error": "Lütfen bitiş tarihini seçin",
  "components.addHolidayForm.holidaysName": "Tatil adı",
  "components.addHolidayForm.holidaysName.error": "Lütfen bir tatil adı girin",
  "components.addHolidayForm.startDate.error": "Lütfen başlangıç tarihini seçin",
  "components.announcementModal.announceToCompany": "Ekibinize Vacation Tracker'ı kullandığınızı bildirmek istiyor musunuz?",
  "components.announcementModal.send": "Duyuru Gönder",
  "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} kullanıcı<multiUsers></multiUsers> <link>(Kullanıcı<multiUsers>ları</multiUsers> Görüntüle)</link> {leaveTypes} izinlerini alamayacak,<endDate> </endDate> {fromDate} <endDate>ile {toDate} arasında</endDate><recurring> her yıl.</recurring>",
  "components.billing.email": "Fatura E-postası",
  "components.billing.emailPlaceholder": "E-posta adresini girin.",
  "components.billing.emailsValidationRequired": "Faturalama amaçları için bir e-posta gereklidir.",
  "components.billing.resubscribe": "Yeniden abone ol",
  "components.billingCouponCode.activeCouponInfo": "<percentOffCover>%{off}</percentOffCover><amountOffCover>{off} ABD$</amountOffCover> indirim <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} ay</durationInMonthsCover>, {start} tarihinden itibaren",
  "components.billingCouponCode.activeCouponInfoTitle": "Aktif Kupon Kodu",
  "components.billingCouponCode.applyCode": "Kodu uygula",
  "components.billingCouponCode.applyCodeAndResubscribe": "Kodu uygula ve Yeniden abone ol",
  "components.billingCouponCode.couponCode": "Kupon Kodu",
  "components.billingCouponCode.text": "Kupon kodunuz var mı? Aboneliğinize uygulamak için buraya girin.",
  "components.billingCouponCode.title": "Kuponlar",
  "components.billingEmails.emailPlaceholder": "E-posta adresini girin.",
  "components.billingEmails.emails": "E-postalar",
  "components.billingEmails.emailsValidationRequired": "Fatura amaçları için bir e-posta gereklidir.",
  "components.billingEmails.title": "Fatura E-postaları",
  "components.billingForm.address1": "Adres satırı 1",
  "components.billingForm.address1ValidationRequired": "Adres gereklidir.",
  "components.billingForm.address2": "Adres satırı 2",
  "components.billingForm.billingEmails": "Fatura E-postası",
  "components.billingForm.billingEmailsPlaceholder": "E-posta adresini girin.",
  "components.billingForm.billingEmailsValidationRequired": "Fatura amaçları için bir e-posta gereklidir.",
  "components.billingForm.ccRequired": "Kart numarası gereklidir.",
  "components.billingForm.city": "Şehir",
  "components.billingForm.cityValidationRequired": "Şehir gereklidir.",
  "components.billingForm.companyName": "Şirket Adı",
  "components.billingForm.companyNameValidationRequired": "Şirket Adı gereklidir.",
  "components.billingForm.country": "Ülke",
  "components.billingForm.countryValidationRequired": "Ülke gereklidir.",
  "components.billingForm.couponCode": "Kupon Kodu",
  "components.billingForm.coupons": "Kuponlar",
  "components.billingForm.description": "Aboneliğinizi kredi kartı ile ödemek için lütfen yukarıdaki forma ödeme bilgilerinizi girin.",
  "components.billingForm.invoiceDescription": "Aboneliği ödemekle görevli kişi bir Slack veya Microsoft Teams kullanıcısı değilse, kredi kartı ile ödenebilecek bir faturayı e-posta ile gönderebiliriz.",
  "components.billingForm.invoiceLabel": "E-posta faturaları gönder",
  "components.billingForm.invoiceTitle": "E-posta ile fatura ödemesi",
  "components.billingForm.paymentCC": "Kredi Kartı",
  "components.billingForm.paymentTitle": "Ödeme bilgileri",
  "components.billingForm.planTitle": "Fatura planı ",
  "components.billingForm.state": "Eyalet",
  "components.billingForm.stateValidationRequired": "Eyalet gereklidir.",
  "components.billingForm.title": "Fatura bilgileri ",
  "components.billingInfo.address": "Adres",
  "components.billingInfo.canceledDescription": "Vacation Tracker aboneliğinizi iptal ettiniz. {periodEnd} tarihine kadar aktif kalacak ",
  "components.billingInfo.canceledTrialDescription": "Deneme süreniz {periodEnd} tarihine kadar aktif kalacak, bundan sonra Vacation Tracker hesabınız iptal edilecek. ",
  "components.billingInfo.companyName": "Şirket Adı",
  "components.billingInfo.email": "Fatura E-postası",
  "components.billingInfo.resubscribe": "Yeniden Abone Ol",
  "components.billingInfo.text": "Fatura bilgileriniz aboneliğinizin ödemelerini işlemek için kullanılır. Fatura bilgilerinizi güncelleyebilir ve kredi kartı bilgilerinizi buradan ekleyebilirsiniz.",
  "components.billingInfo.title": "Fatura bilgileri ",
  "components.billingInvoice.description": "Ödeme yöntemlerini ve faturaları müşteri portalımızda yönetebilirsiniz. <link>Müşteri portalını ziyaret etmek için buraya tıklayın</link><creatingLink>Müşteri portalı bağlantısı oluşturuluyor</creatingLink>",
  "components.billingInvoice.title": "Faturalar",
  "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "Başka bir izin takip aracı bulduk",
  "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "Lütfen hangi araca geçiş yaptığınızı bize söyleyebilir misiniz?",
  "components.cancelSubscriptionModal.cancelText": "Aboneliği iptal etme",
  "components.cancelSubscriptionModal.contactInfo": "Diğer her şey için lütfen {email} adresinden desteğimizle iletişime geçin",
  "components.cancelSubscriptionModal.info": "Vacation Tracker aboneliğinizi iptal etme nedeninizi bize söyleyebilir misiniz?",
  "components.cancelSubscriptionModal.min20characters": "Minimum 20 karakter",
  "components.cancelSubscriptionModal.missingFeatures": "Araçta ekibimin ihtiyaç duyduğu özellikler yok",
  "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "Hangi özelliğin eksik olduğunu bize birkaç kelimeyle söyleyebilir misiniz?",
  "components.cancelSubscriptionModal.moreInfo": "Sizi müşteri olarak tutmak için ne yapabilirdik?",
  "components.cancelSubscriptionModal.okText": "Şimdi İptal Et",
  "components.cancelSubscriptionModal.option1": "Bir İK yazılımına geçtik",
  "components.cancelSubscriptionModal.option2": "Başka bir izin takip aracı bulduk",
  "components.cancelSubscriptionModal.option3": "Araçta ekibimin ihtiyaç duyduğu özellikler yok",
  "components.cancelSubscriptionModal.option4": "Fiyat bütçemizin üzerinde",
  "components.cancelSubscriptionModal.option5": "Diğer",
  "components.cancelSubscriptionModal.other": "Diğer",
  "components.cancelSubscriptionModal.otherAdditionalInfo": "Diğer",
  "components.cancelSubscriptionModal.priceOverBudget": "Fiyat bütçemizin üzerinde",
  "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "Bu tür bir araç için bütçeniz nedir?",
  "components.cancelSubscriptionModal.title": "Gittiğinizi görmek bizi üzüyor...",
  "components.cancelSubscriptionModal.weAreMovingToPayroll": "Bir Bordro sistemine geçiyoruz",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "Lütfen hangi Bordro sistemine geçiş yaptığınızı bize söyleyebilir misiniz?",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "Bu Bordro sistemiyle bir entegrasyonumuz olsaydı, kalmayı düşünür müydünüz?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "Bir İK yazılımına geçtik",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "Lütfen hangi İK sistemine geçiş yaptığınızı bize söyleyebilir misiniz?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "Bu İK sistemiyle bir entegrasyonumuz olsaydı, kalmayı düşünür müydünüz?",
  "components.cancelTrialSubscriptionModal.cancelText": "Denemeye devam et",
  "components.cancelTrialSubscriptionModal.info": "Lütfen denemenizi neden iptal etmek istediğinizi bir cümleyle açıklayın?",
  "components.cancelTrialSubscriptionModal.okText": "Denemeyi iptal et",
  "components.cancelTrialSubscriptionModal.title": "Gittiğinizi görmek bizi üzüyor...",
  "components.changeTeamForm.changeTeamWarning": "Kullanıcıyı aktarmak istediğiniz Departmanı seçin.",
  "components.changeTeamForm.changeUserTeam": "Kullanıcının Departmanını Değiştir",
  "components.changeTeamForm.selectTeam": "Departman Seç",
  "components.changeTeamForm.selectTeamError": "Lütfen bir departman seçin.",
  "components.companyAlreadySignup.connectWith": " {existsOnAnotherPlatform} ile bağlan",
  "components.companyAlreadySignup.info": "Şirketinizin mevcut bir Vacation Tracker hesabı olduğunu fark ettik. Giriş yapmak istiyorsanız, buraya tıklayın:",
  "components.companyAlreadySignup.signupWithMail": "İş e-postanızla bağlanın",
  "components.companyAlreadySignup.title": "Şirketiniz zaten Vacation Tracker kullanıyor",
  "components.companyLogs.dateRangeLimit": "3 aydan eski kayıtlar yalnızca tam planda mevcuttur.",
  "components.companyLogs.rangeLimit": "Tarih aralığı en fazla bir yıl olabilir",
  "components.companySettings.contactEmailInfo": "Yeni şirket iletişim e-posta adresiniz: {email}.",
  "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "Daha fazla bilgi için <helpDesk>Yardım Merkezimizi</helpDesk> ziyaret edin.",
  "components.comparePlatformsModal.info": "Hangi platformu seçerseniz seçin, Vacation Tracker'ın tüm ana özelliklerine erişiminiz olacak. Ancak bazılarının diğerlerinde bulunmayan ek avantajları vardır. Aşağıdaki karşılaştırma tablosuna bakın.",
  "components.comparePlatformsModal.table.comingSoon": "Yakında",
  "components.comparePlatformsModal.table.email": "E-posta",
  "components.comparePlatformsModal.table.feature": "Özellik",
  "components.comparePlatformsModal.table.google": "Google",
  "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Google Workspace'inizden Kullanıcıları İçe Aktar",
  "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Microsoft Active Directory'nizden Kullanıcıları İçe Aktar",
  "components.comparePlatformsModal.table.importUsersFromSlack": "Slack çalışma alanınızdan Kullanıcıları İçe Aktar",
  "components.comparePlatformsModal.table.inviteUsersViaEmail": "Kullanıcıları e-posta ile davet et",
  "components.comparePlatformsModal.table.manageLeaveTypes": "İzin Türlerini, Tatilleri ve İzin Taleplerini Yönet",
  "components.comparePlatformsModal.table.microsoft": "Microsoft",
  "components.comparePlatformsModal.table.multiTeamLocation": "Farklı İzin Politikalarına sahip çoklu Departman ve Lokasyonları yapılandır",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Microsoft Teams'den doğrudan İzin Talepleri gönder ve yönet",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Slack'ten doğrudan İzin Talepleri gönder ve yönet",
  "components.comparePlatformsModal.table.slack": "Slack",
  "components.comparePlatformsModal.table.syncToGoogleCalendar": "Vacation Tracker'ı Google Takvim ile senkronize et",
  "components.comparePlatformsModal.table.syncToOutlookCalendar": "Vacation Tracker'ı Outlook 365 Takvimi ile senkronize et",
  "components.comparePlatformsModal.title": "Benim için hangi Vacation Tracker entegrasyonu doğru?",
  "components.configureImport.configureProratedLeaveTypesErrorMessage": "En az bir izin türü seçmelisiniz",
  "components.configureImport.defaultDepartmentTooltip": "Tüm içe aktarılan kullanıcılar bu departmana eklenecek. İçe aktarıldıktan sonra, onları farklı bir departmana yerleştirebilirsiniz.",
  "components.configureImport.defaultLocationTooltip": "Tüm içe aktarılan kullanıcılar bu lokasyona eklenecek. İçe aktarıldıktan sonra, onları farklı bir lokasyona yerleştirebilirsiniz.",
  "components.configureImport.guestUsers": "Çok kanallı misafir kullanıcıları içe aktarmak istiyor musunuz?",
  "components.configureImport.guestUsersTooltip": "Bu seçenek açıksa, çalışma alanınızdaki çok kanallı misafir kullanıcılar Vacation Tracker'a içe aktarılacaktır.",
  "components.configureImport.proRateLabel": "Yeni kullanıcıların bu yıl için kotasını orantılı olarak hesapla?",
  "components.configureImport.proRateLeaveTypes": "Aşağıdaki izin türlerine orantılı hesaplama uygula",
  "components.configureImport.proRateLeaveTypesTooltip": "Lütfen kullanılabilir kullanıcı izin kotalarını orantılı olarak hesaplamak istediğiniz izin türlerini listeleyin.",
  "components.configureImport.proRateTooltip": "Lütfen sistemimizin, içe aktarılan kullanıcıların ilk yıl için kullanılabilir izin kotasını şirket ayarlarınıza ve kullanıcının başlangıç tarihine göre hesaplamasını isteyip istemediğinizi seçin.",
  "components.createLeaveType.assignToLocationManyInProgress": "İzin politikası lokasyonlara atanıyor",
  "components.createLeaveType.assignToLocationManyInProgressCompleted": "İzin politikası lokasyonlara atama tamamlandı",
  "components.createLeaveType.assignToLocationOneInProgress": "İzin politikası lokasyona atanıyor",
  "components.createLeaveType.assignToLocationOneInProgressCompleted": "İzin politikası lokasyona atama tamamlandı",
  "components.createLeaveType.createInProgress": "{leaveTypeName} izin türü oluşturuluyor",
  "components.createLeaveTypeForm.assignAndConfigure": "Ata ve Yapılandır",
  "components.createLeaveTypeForm.assignToLocations": "Lokasyonlara Ata",
  "components.createLeaveTypeForm.calendarStatusBusy": "Meşgul",
  "components.createLeaveTypeForm.calendarStatusFree": "Müsait",
  "components.createLeaveTypeForm.calendarStatusLabel": "Bu izin türünü takviminizde şu şekilde göster",
  "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Tatil gibi tam yokluk için 'Meşgul'ü, uzaktan çalışma günleri için 'Müsait'i seçin. 'Meşgul' müsait olmadığınızı, 'Müsait' ofiste olmadığınızı ama ulaşılabilir olduğunuzu belirtir.",
  "components.createLeaveTypeForm.continueCreatingLeaveType": "Evet, izin türü oluşturmak istiyorum",
  "components.createLeaveTypeForm.createAndAssign": "Oluştur ve Ata",
  "components.createLeaveTypeForm.createLeaveType": "İzin Türü Oluştur",
  "components.createLeaveTypeForm.justLastStep.assign": "Ata",
  "components.createLeaveTypeForm.justLastStep.title": "İzin Politikası Ata",
  "components.createLeaveTypeForm.Locations": "Lokasyonlar",
  "components.createLeaveTypeForm.locationsInfo": "<strong>{leaveTypeName}</strong> türünü tüm lokasyonlara veya belirli bir lokasyona atayın.",
  "components.createLeaveTypeForm.locationsPolicyHint": "Bu izin türü için politikayı (kuralları) düzenlemek için, lütfen ilgili {locations, plural, =1 {Lokasyona} other {Lokasyonlara}} gidin:",
  "components.createLeaveTypeForm.selectLocation": "Lokasyon seç",
  "components.createLeaveTypeForm.similarLeaveTypeChecking": "Mevcut izin türleri kontrol ediliyor...",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "Yine de yeni bir tür oluşturmak istiyor musunuz (<strong>{newLeaveTypeName}</strong>)?",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "Bir izin türünü birden fazla lokasyona atayabilir ve lokasyon başına kuralları özelleştirebilirsiniz (kotalar, devredilen, tahakkuklar vb.). Her yer için ayrı türler oluşturmanıza gerek yok!",
  "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "Kullanıcı izin verilerini ve tutarlılığı korumak için birden çok lokasyonda tek bir izin türü kullanmanızı öneririz.",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Lokasyona özgü kuralları şunun için ayarlayın:",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "Benzer bir izin türü zaten mevcut (<strong>{leaveTypeName}</strong>).",
  "components.createLeaveTypeForm.similarLeaveTypeTitle": "Benzer İzin Türü",
  "components.createLeaveTypeForm.title": "İzin Türü Oluştur ve İzin Politikası Ata",
  "components.denyWithReasonForm.deny": "Reddet",
  "components.denyWithReasonForm.denyLeaveRequest": "İzin Talebini Reddet",
  "components.denyWithReasonForm.reason": "Bir Neden Ekle",
  "components.denyWithReasonForm.reasonIsRequired": "Bu alan zorunludur.",
  "components.downgradeAlert.cancelChangeToPlan": "<strong>{nextPlan}</strong> planına değişikliği iptal et",
  "components.downgradeAlert.cancelSwitchPlan": "<strong>{nextPlan}</strong> {nextPeriod} planına planlanmış değişikliği iptal etmek istiyor musunuz?",
  "components.downgradeAlert.noIWantToSwitch": "Hayır, <strong>{nextPlan}</strong> {nextPeriod} planına geçmek istiyorum",
  "components.downgradeAlert.yesIWantToStay": "Evet, <strong>{currentPlan}</strong> {currentPeriod} planında kalmak istiyorum",
  "components.entitlementByRole.additionalDays": "ek gün(ler)",
  "components.entitlementByRole.additionalHours": "ek saat(ler)",
  "components.entitlementByRole.applayToUsersWithLabel": "Etiketi olan kullanıcılara uygula",
  "components.entitlementByRole.applyToUsersWithLabel": "Etiketi olan kullanıcılara uygula",
  "components.entitlementByRole.completePlanOnly": "Bu özellik yalnızca Complete planımıza abone olan kullanıcılar tarafından erişilebilir.",
  "components.entitlementByRole.noLabels": "Oluşturulmuş etiket yok veya tümü diğer Role Göre Hak otomasyonları için kullanılıyor.",
  "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Role göre hak uygulamaya başlama tarihi",
  "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Role göre hak uygulamaya başlama tarihi",
  "components.fakeDoorMailLoginModal.discount": "Erken erişim grubunun bir parçası olarak, ilk 3 ay için Vacation Tracker'da %50 indirim de alacaksınız.",
  "components.fakeDoorMailLoginModal.info": "Şu anda e-posta ile kayıt olma özelliğini etkinleştirmek üzerinde çalışıyoruz. Erken erişim almak için lütfen aşağıdan kaydolun, yakında sizinle iletişime geçeceğiz.",
  "components.fakeDoorMailLoginModal.no": "Hayır, bekleyeceğim.",
  "components.fakeDoorMailLoginModal.title": "Erken erişimle ilgileniyor musunuz? 🚀 ",
  "components.fakeDoorMailLoginModal.yes": "Evet, erken erişim almak istiyorum.",
  "components.feedbackModal.button": "Bize geri bildirim verin",
  "components.feedbackModal.emailPlaceholder": "E-posta adresiniz nedir?",
  "components.feedbackModal.feedbackForm": "Geri bildirim formu",
  "components.feedbackModal.feedbackMessage": "Geri bildirim mesajı",
  "components.feedbackModal.howDoYouLikeApp": "Uygulamamızı nasıl buldunuz?",
  "components.feedbackModal.howDoYouLikeAppPlaceholder": "Bir seçenek seçin...",
  "components.feedbackModal.iLikeIt": "Beğendim",
  "components.feedbackModal.iLoveIt": "Çok sevdim",
  "components.feedbackModal.ItMakesMeCry": "Beni ağlatıyor",
  "components.feedbackModal.itsOk": "Fena değil",
  "components.feedbackModal.messagePlaceholder": "Geri bildirim mesajınız",
  "components.feedbackModal.Nah": "Eh işte",
  "components.feedbackModal.namePlaceholder": "Adınızı girin",
  "components.feedbackModal.ratingRequired": "Lütfen verilen derecelendirmelerden birini seçin",
  "components.feedbackModal.sendFeedback": "Geri bildirim gönder",
  "components.feedbackModal.yourEmail": "E-posta adresiniz",
  "components.feedbackModal.yourEmailInfo": "Geri bildiriminiz bir yanıt gerektiriyorsa, sizinle iletişime geçebilmemiz için lütfen e-posta adresinizi girin.",
  "components.feedbackModal.yourName": "Adınız",
  "components.filterSimple.filterBy": "Filtrele",
  "components.filterSimple.selectType": "Tür seçin",
  "components.filterSimple.tooltipInfo": "Herhangi bir filtre seçmezseniz, dışa aktarma tüm kullanıcıları içerecektir.",
  "components.generalSettings.aiBotHelperLabel": "Vacation Tracker Bot ile iletişime izin ver",
  "components.generalSettings.aiBotHelperLabelDisable": "Vacation Tracker Bot ile iletişimi devre dışı bırak",
  "components.generalSettings.aiBotHelperTooltip": "Bot ile mesajlaşma, çalışanların işten izin istemesi için uygun ve verimli bir yoldur. Çalışanlar, başlangıç ve bitiş tarihleri, istenen gün sayısı ve izin türü gibi ayrıntıları belirterek bota basitçe bir izin talebi mesajı gönderebilirler.",
  "components.heatmap.clickForMore": "Daha fazla bilgi için tıklayın",
  "components.heatmap.holiday": "Tatil",
  "components.heatmap.holidayDescription": "{location} konumu {holiday} için dışarıda",
  "components.heatmap.loading": "{day} yükleniyor",
  "components.heatmap.nextMonth": "Gelecek Ay",
  "components.heatmap.nonWorkingDay": "çalışma dışı gün",
  "components.heatmap.previousMonth": "Önceki Ay",
  "components.heatmap.showLess": "Daha az göster",
  "components.heatmap.showMore": "{amount} daha göster",
  "components.heatmap.today": "bugün",
  "components.helpDrawer.adminProductTourInfo": "Yönetici olarak, Vacation Tracker'da en yüksek izin seviyesine sahipsiniz ve Yönetici Paneli aracılığıyla organizasyonun tüm ayarlarını özelleştirebilirsiniz.\n\nPanel size şunları yapma imkanı sunar: \n- İzin Talep Etme veya diğer departman üyeleri için İzin Ekleme. \n- Pano Ana Sayfası ve Takvim aracılığıyla genel bir bakış elde etme. \n- Birden çok departman oluşturma ve yapılandırma (Onaylayanları Atama) \n- Bildirimleri Ayarlama  \n- Kullanıcıları Yönetme (Aktif, Pasif, Silinmiş).\n- Lokasyonları Oluşturma ve Yönetme (İzin Türleri, Tatiller, Bildirimler)\n\nBot size şunları yapma imkanı sunar: \n- İzin Talep Etme \n- İzin Taleplerini Onaylama/Reddetme\n- Günlük/Haftalık Bildirimler Alma\n\nSorularınız veya endişeleriniz yanıtsız kalırsa, istediğiniz zaman bize ulaşın. Müşterilerimizle konuşmak günümüzün en güzel parçasıdır: ",
  "components.helpDrawer.adminProductTourTitle": "Rolünüz: Yönetici",
  "components.helpDrawer.approverProductTourInfo": "Onaylayıcı olarak, Vacation Tracker'da en yüksek ikinci izin seviyesine sahipsiniz ve Onaylayıcı olarak atandığınız departmandan Kullanıcılar tarafından gönderilen izin taleplerini onaylayabilir veya reddedebilirsiniz.\n\nGösterge Paneli size şunları yapma imkanı sunar:\n- İzin Talep etme veya diğer departman üyeleri için İzin ekleme.\n- Gösterge Paneli Ana Sayfası ve Takvim üzerinden tam bir genel bakış elde etme. \n- Bildirimleri Ayarlama \n- Kullanıcı Profilinizi Görüntüleme.\n- Talepleri Yönetme (Talepleri Onaylama/Reddetme, Geçmişi Görüntüleme).\n\nBot size şunları yapma imkanı sunar:\n- İzin Talep Etme\n- İzin Taleplerini Onaylama/Reddetme\n- Günlük/Haftalık Bildirimler Alma\n\nSorularınız veya endişeleriniz hala yanıtlanmadıysa, bize istediğiniz zaman ulaşın. Müşterilerimizle konuşmak günümüzün en güzel parçasıdır: ",
  "components.helpDrawer.approverProductTourTitle": "Rolünüz: Onaylayıcı",
  "components.helpDrawer.google.adminProductTourInfo": "Yönetici olarak Vacation Tracker'da en yüksek izin seviyesine sahipsiniz ve Yönetici Gösterge Paneli aracılığıyla organizasyonun tüm ayarlarını özelleştirebilirsiniz.\n\nGösterge Paneli size şunları yapma imkanı sunar: \n- İzin Talep etme veya diğer departman üyeleri için İzin ekleme. \n- Gösterge Paneli Ana Sayfası ve Takvim üzerinden tam bir genel bakış elde etme. \n- Birden fazla departman oluşturma ve yapılandırma (Onaylayıcılar Atama)  \n- Bildirimleri Ayarlama \n- Kullanıcıları Yönetme (Aktif, Pasif, Silinmiş).\n- Konumlar Oluşturma ve Yönetme (İzin Türleri, Tatiller, Bildirimler)\n\nSorularınız veya endişeleriniz hala yanıtlanmadıysa, bize istediğiniz zaman ulaşın. Müşterilerimizle konuşmak günümüzün en güzel parçasıdır: ",
  "components.helpDrawer.google.approverProductTourInfo": "Onaylayıcı olarak, Vacation Tracker'da en yüksek ikinci izin seviyesine sahipsiniz ve Onaylayıcı olarak atandığınız departmandan Kullanıcılar tarafından gönderilen izin taleplerini onaylayabilir veya reddedebilirsiniz.\n\nGösterge Paneli size şunları yapma imkanı sunar:\n- İzin Talep etme veya diğer departman üyeleri için İzin ekleme.\n- Gösterge Paneli Ana Sayfası ve Takvim üzerinden tam bir genel bakış elde etme. \n- Bildirimleri Ayarlama \n- Kullanıcı Profilinizi Görüntüleme.\n- Talepleri Yönetme (Talepleri Onaylama/Reddetme, Geçmişi Görüntüleme).\n\nSorularınız veya endişeleriniz hala yanıtlanmadıysa, bize istediğiniz zaman ulaşın. Müşterilerimizle konuşmak günümüzün en güzel parçasıdır: ​​​​​​​​",
  "components.helpDrawer.google.userProductTourInfo": "Vacation Tracker Kullanıcısı olarak, İzin Taleplerinizi Gösterge Paneli üzerinden gönderebilir ve yönetebilir, aynı zamanda organizasyonunuz içindeki planlanan izinlerin tam bir genel görünümüne sahip olabilirsiniz.\n\nGösterge Paneli size şunları yapma imkanı sunar:\n- İzin Talep Etme\n- Gösterge Paneli Ana Sayfası ve Takvim üzerinden tam bir genel bakış elde etme.\n- Kullanıcı Profilinizi Görüntüleme.\n\nSorularınız veya endişeleriniz hala yanıtlanmadıysa, bize istediğiniz zaman ulaşın. Müşterilerimizle konuşmak günümüzün en güzel parçasıdır: ​​​​​​​​",
  "components.helpDrawer.helpDeskInfo": "Ürünümüz hakkında daha fazla bilgi edinmek ve bazı yaygın soruların cevabını almak için Yardım Masasını ziyaret edin.",
  "components.helpDrawer.learnMore": "Daha fazla bilgi edin",
  "components.helpDrawer.needhelp": "Yardıma mı ihtiyacınız var?",
  "components.helpDrawer.quickTourInfo": "Organizasyonunuzu hızlıca kurmanız için izlemenizi önerdiğimiz birkaç kısa adım hazırladık.",
  "components.helpDrawer.quickTourTitle": "Hızlı tur",
  "components.helpDrawer.supportInfo": "Herhangi bir sorunuz varsa veya yardıma ihtiyacınız olursa, müşteri destek ekibimizle sohbet başlatın veya bize <link>hello@vacationtracker.io</link> adresinden e-posta gönderin",
  "components.helpDrawer.supportTitle": "Destek",
  "components.helpDrawer.userProductTourInfo": "Vacation Tracker Kullanıcısı olarak, İzin Taleplerinizi Gösterge Paneli veya Bot üzerinden gönderebilir ve yönetebilir, aynı zamanda organizasyonunuz içindeki planlanan izinlerin tam bir genel görünümüne sahip olabilirsiniz.\n\nGösterge Paneli size şunları yapma imkanı sunar:\n- İzin Talep Etme\n- Gösterge Paneli Ana Sayfası ve Takvim üzerinden tam bir genel bakış elde etme.\n- Kullanıcı Profilinizi Görüntüleme.\n\nBot size şunları yapma imkanı sunar:\n- İzin Talep Etme \n- Günlük/Haftalık Bildirimler Alma\n\nSorularınız veya endişeleriniz hala yanıtlanmadıysa, bize istediğiniz zaman ulaşın. Müşterilerimizle konuşmak günümüzün en güzel parçasıdır: ",
  "components.helpDrawer.userProductTourTitle": "Rolünüz: Kullanıcı",
  "components.importHolidaysForm.backButton": "Geri",
  "components.importHolidaysForm.selectCountry": "Ülke seçin",
  "components.importHolidaysForm.selectHolidays": "Tatilleri seçin",
  "components.importHolidaysForm.selectYear": "Yıl seçin",
  "components.importUsersForm.button.importAllUsers": "Tüm {users} Kullanıcıyı İçe Aktar ve Etkinleştir",
  "components.importUsersForm.button.importSelectedUsers": "Seçilen {noOfUsers} {noOfUsers, plural, =1 {Kullanıcıyı} other {Kullanıcıyı}} İçe Aktar",
  "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "{platform}'dan tüm kullanıcıları otomatik olarak içe aktarmak istiyor musunuz?",
  "components.importUsersForm.calculationStartYearInfo": "Vacation Tracker izinleri {year} yılından başlayarak hesaplayacak",
  "components.importUsersForm.cancel": "İptal",
  "components.importUsersForm.chooseUsersPlaceholder": "Ekip Üyesi Adı",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "Vacation Tracker, çalışma alanınızdaki çok kanallı misafir kullanıcıları otomatik olarak içe aktarmayacak. İhtiyaç duyarsanız onları manuel olarak içe aktarabilirsiniz.",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "Vacation Tracker, çalışma alanınızdaki çok kanallı misafir kullanıcıları otomatik olarak içe aktaracak. ",
  "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "Bu ayarları daha sonra Vacation Tracker gösterge panelinde değiştirebilirsiniz",
  "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "Yeni {noOfUsers, plural, =1 {kullanıcı} other {kullanıcılar}} {chosenDepartment} departmanına ve {chosenLocation} konumuna eklenecek",
  "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "{noOfUsers} {noOfUsers, plural, =1 {kullanıcıyı} other {kullanıcıyı}} içe aktar",
  "components.importUsersForm.confirmImportUsersModalContentOpener": "Şunları seçtiniz:",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Kullanıcıların kotasını şu izin türleri için orantılı olarak hesapla: {leaveTypes}",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "Kullanıcıların kotaları artık orantılı olarak hesaplanmayacak",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Yeni kullanıcı(lar)a bir hoş geldiniz {channel, select, Email {e-postası} other {mesajı}} gönder {channel}",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "Artık yeni kullanıcılara hoş geldiniz mesajı göndermeyeceğiz",
  "components.importUsersForm.confirmImportUsersModalTitle": "Seçilen {noOfUsers} kullanıcıyı içe aktarmayı onaylayın",
  "components.importUsersForm.consent.line1": "Kullanıcıları içe aktarmak için Microsoft Teams yöneticinizden yönetici onayı almanız gerekecek. Bu tek seferlik onayı almak için lütfen aşağıdaki bağlantıyı Microsoft Teams yöneticinize gönderin:",
  "components.importUsersForm.consent.line2": "Onayı aldıktan sonra, bu modalı kapatıp yeniden açın ve bu mesaj kaybolacaktır.",
  "components.importUsersForm.consent.warning": "Tüm kullanıcıları içe aktarabilmek için lütfen onayı aldıktan sonra bu modalı kapatıp yeniden açın.",
  "components.importUsersForm.googleAPIErrorMessage": "Google dizin ayarlarınızda bir sorun var gibi görünüyor. Lütfen yöneticinizle iletişime geçin ve onlara aşağıdaki hatayı gönderin:",
  "components.importUsersForm.importAllUsers": "Tüm kullanıcıları içe aktarmak istiyor musunuz?",
  "components.importUsersForm.importAllUsersExceedingLimt": "Dizininizde çok sayıda kullanıcı var. Tüm kullanıcıları içe aktarmak istiyorsanız, lütfen Destek ekibimizle iletişime geçin ve içe aktarmayı etkinleştirebiliriz. Alternatif olarak, belirli kullanıcıları seçerek içe aktarabilirsiniz.",
  "components.importUsersForm.importAllUsersTooltip": "{platform}'dan {noOfUsersToImport} {noOfUsersToImport, plural, =1 {kullanıcı} other {kullanıcı}} Vacation Tracker'a aktarılacak. Bu, faturanızı etkileyebilir.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "Dizininizde çok sayıda kullanıcı var. Kullanıcıları otomatik olarak içe aktarmak istiyorsanız, lütfen Destek ekibimizle iletişime geçin ve içe aktarmayı etkinleştirebiliriz.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "Dizininizde çok sayıda kullanıcı var. Kullanıcıları otomatik olarak içe aktarmak istiyorsanız, lütfen Destek ekibimizle iletişime geçin ve içe aktarmayı etkinleştirebiliriz.",
  "components.importUsersForm.importUsersAutomaticallyTooltip": "{platform}'ınıza yeni eklenen bir kullanıcı Vacation Tracker'a aktarıldığında, Vacation Tracker'ın ne olduğunu ve nasıl kullanılacağını açıklayan kısa bir mesaj alacaklar.",
  "components.importUsersForm.importUsersTitle": "Kullanıcıları İçe Aktar",
  "components.importUsersForm.newCompanyNameInfo": "Yeni şirket adı şu olacak: {newName}",
  "components.importUsersForm.selectUsers": "İçe aktarılacak kullanıcıları seçin:",
  "components.importUsersForm.selectUsersNext": "Seçilen kullanıcılar için ayarları yapılandırın",
  "components.importUsersForm.selectUsersStepName": "Kullanıcıları Seç",
  "components.importUsersForm.sendWelcomeMessageLabel": "Yeni kullanıcılara hoş geldiniz mesajı gönderilsin mi?",
  "components.importUsersForm.trackingQuotaInfoDays": "Vacation Tracker, tüm izinlerin ve kotaların süresini gün cinsinden takip edecek.",
  "components.importUsersForm.trackingQuotaInfoHours": "Vacation Tracker, tüm izinlerin ve kotaların süresini saat cinsinden takip edecek.",
  "components.importUsersForm.userEndDateInfo": "Bitiş tarihi olan kullanıcılar, o tarihten sonra izin talebinde bulunamayacak ve otomatik olarak devre dışı bırakılacaklar.",
  "components.importUsersForm.userEndDateTurnOffInfo": "Bitiş tarihi olan kullanıcılar, o tarihten sonra izin talebinde bulunabilecek ve otomatik olarak devre dışı bırakılmayacaklar.",
  "components.importUsersForm.usersIsRequired": "Bu alan zorunludur.",
  "components.importUsersForm.warningAllUsersImport": "Vacation Tracker, {platform}'ınızdaki kullanıcıları otomatik olarak senkronize edebilir, içe aktarabilir ve silebilir.",
  "components.importUsersForm.warningNumberOfUsersToImport": "\"Tüm Kullanıcıları İçe Aktar ve Etkinleştir\" düğmesine tıkladığınızda, dizininizdeki tüm {users} kullanıcıyı etkinleştireceksiniz. Bu işlem faturalandırmanızı etkileyebilir.",
  "components.importUsersForm.warningOfUsersImport": "Seçilen {noOfUsers} {noOfUsers, plural, =1 {kullanıcının} other {kullanıcının}} içe aktarılması faturalandırmanızı etkileyebilir.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "{noOfUsers} {noOfUsers, plural, =1 {kullanıcı} other {kullanıcı}} eklenmesi, {startDate} tarihinden itibaren bir sonraki faturanızı {amount} (KDV hariç) artıracak.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "Bugün tahmini orantılı {proratedAmount} (KDV hariç) ücret uygulanacak.",
  "components.importUsersForm.warningOfUsersImportNoChange": "{noOfUsers} {noOfUsers, plural, =1 {kullanıcıyı} other {kullanıcıyı}} içe aktarmak üzeresiniz. Faturalandırmanız etkilenmeyecek.",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "Mevcut koltuk limitinize ({seats}) ulaştınız. Daha fazla satın almak ister misiniz?",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "Çalışma alanınızdan/dizininizden tüm kullanıcıları içe aktaramazsınız çünkü mevcut koltuk limitinizi ({seats}) aşacaksınız. Daha fazla satın almak ister misiniz?",
  "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "{noOfUsers} {noOfUsers, plural, =1 {kullanıcının} other {kullanıcının}} içe aktarılması faturalandırmanızı etkileyecek. Bir sonraki faturanız {startDate} tarihinde {amount} (KDV hariç) olacak",
  "components.importUsersForm.warningOfUsersImportTrial": "Şu anda deneme modundasınız. {startDate} tarihinde {amount} (KDV hariç) ücretlendirileceksiniz",
  "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "Deneme süresindesiniz ve {stripeCurrentPeriodEnd} tarihinden önce ek kullanıcıların içe aktarılması için ücretlendirilmeyeceksiniz. Ücret tahminini görmek için fatura bilgilerinizi ekleyin.",
  "components.insertHolidaysForm.back": "Geri",
  "components.insertHolidaysForm.deselectAll": "Tümünü Seçimi Kaldır",
  "components.insertHolidaysForm.findHolidays": "Tatilleri Bul",
  "components.insertHolidaysForm.insertHolidays": "Tatilleri İçe Aktar",
  "components.insertHolidaysForm.pleaseChooseACountry": "Bir Ülke Seçin",
  "components.insertHolidaysForm.saveHolidays": "Tatilleri Kaydet",
  "components.insertHolidaysForm.selectAll": "Tümünü Seç",
  "components.insertHolidaysForm.selectHolidays": "Tatilleri seç",
  "components.insertHolidaysForm.subtitle": "Bu değişiklik bazı kullanıcılar için izin günü sayısını etkileyebilir. Bu kullanıcıların talep ettikleri izin için mevcut kotalarına gün(ler) eklenecek. Devam etmek istediğinizden emin misiniz?",
  "components.insertHolidaysForm.title": "Tatillerin İçe Aktarılması, daha önce eklenmiş olan Tatillerin üzerine yazacaktır.",
  "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "Kendinizi davet edemezsiniz",
  "components.inviteAndManageUsersWithEmail.header": "E-posta davetleri",
  "components.inviteAndManageUsersWithEmail.userAlreadyExists": "Belirtilen e-posta adresine sahip kullanıcı zaten mevcut",
  "components.inviteBotToPrivateChannel.descriptionOne": "Vacation Tracker'ı özel bir kanala eklemek için lütfen şunu yazın",
  "components.inviteBotToPrivateChannel.descriptionTwo": "kanalda ve listeyi yenileyin",
  "components.inviteBotToPrivateChannel.text": "Özel bir kanal seçmek istiyor musunuz?",
  "components.inviteBotToPrivateChannel.title": "Özel kanal ekle",
  "components.leaveBox.approve": "Onayla",
  "components.leaveBox.approvedBy": "Onaylayan",
  "components.leaveBox.approveLeaveText": "Lütfen izin talebini onaylamak istediğinizi doğrulayın.",
  "components.leaveBox.approveLeaveTitle": "İzin Talebini Onayla",
  "components.leaveBox.autoApproved": "Otomatik onaylandı",
  "components.leaveBox.cancelLeave": "İzni İptal Et",
  "components.leaveBox.cancelLeaveConfirm": "İzni iptal etmek istediğinizden emin misiniz?",
  "components.leaveBox.cancelLeaveTitle": "İzni İptal Et",
  "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {gün} other {gün}}",
  "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {saat} other {saat}}",
  "components.leaveBox.daysLeft": "Kalan İzin:",
  "components.leaveBox.daysOut": "Bu, ofis dışında {value} {value, plural, =1 {gün} other {gün}} demek.",
  "components.leaveBox.deny": "Reddet",
  "components.leaveBox.denyLeaveConfirm": "Lütfen izin talebini reddetmek istediğinizi doğrulayın.",
  "components.leaveBox.denyLeaveTitle": "İzin Talebini Reddet",
  "components.leaveBox.denyWithReason": "Gerekçe ile Reddet",
  "components.leaveBox.edit": "Düzenle",
  "components.leaveBox.formattedLeaveInfo": "Bu, ofis dışında {leave} demek.",
  "components.leaveBox.hoursOut": "Bu, ofis dışında {value} {value, plural, =1 {saat} other {saat}} demek.",
  "components.leaveBox.leavesBoxHalfDay": "{leaveStartDate} tarihinde {startHour}{hourFormat24, select, true {:00} other {}} - {endHour}{hourFormat24, select, true {:00} other {}} saatleri arasında {leaveTypeName} için dışarıda olacak.",
  "components.leaveBox.leavesBoxHalfDayPending": "{leaveStartDate} tarihinde {startHour}{hourFormat24, select, true {:00} other {}} - {endHour}{hourFormat24, select, true {:00} other {}} saatleri arasında {leaveTypeName} için talep.",
  "components.leaveBox.leavesBoxHalfDayToday": "Bugün {startHour}{hourFormat24, select, true {:00} other {}} - {endHour}{hourFormat24, select, true {:00} other {}} saatleri arasında izinli.",
  "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "Bugün {startHour}{hourFormat24, select, true {:00} other {}} - {endHour}{hourFormat24, select, true {:00} other {}} saatleri arasında izinli.",
  "components.leaveBox.leavesBoxHalfDayWithoutReason": "{leaveStartDate} tarihinde {startHour}{hourFormat24, select, true {:00} other {}} - {endHour}{hourFormat24, select, true {:00} other {}} saatleri arasında izin kullanıyor.",
  "components.leaveBox.leavesOffTodayInfo": "{leaveStartDate} ile {leaveEndDate} tarihleri arasında {leaveTypeName} için dışarıda. Her iki tarih de dahil.",
  "components.leaveBox.leavesOffTodayWithoutReasonInfo": "{leaveStartDate} ile {leaveEndDate} tarihleri arasında dışarıda. Her iki tarih de dahil.",
  "components.leaveBox.leavesPendingInfo": "{leaveStartDate} ile {leaveEndDate} tarihleri arasında {leaveTypeName} için talep. Her iki tarih de dahil.",
  "components.leaveBox.leavesPendingOneDay": "{leaveStartDate} tarihinde {leaveTypeName} için talep.",
  "components.leaveBox.leavesUpcomingOneDayInfo": "{leaveStartDate} tarihinde {leaveTypeName} için dışarıda olacak.",
  "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "{leaveStartDate} tarihinde dışarıda olacak.",
  "components.leaveBox.reason": "Sebep",
  "components.leaveBox.toilDuration": "Süre: {leave}",
  "components.leaveForm.addLeave": "İzin Ekle",
  "components.leaveForm.addLeaveDescription": "Başka bir kullanıcı için izin ekliyorsanız veya kendiniz için onay gerektirmeyen bir izin ekliyorsanız bu formu kullanın.",
  "components.leaveForm.connectedCalendarTimezone": "Bağlı {calendarType} takvim saat dilimi {timezone}",
  "components.leaveForm.date": "Tarih",
  "components.leaveForm.editLeave": "İzni Düzenle",
  "components.leaveForm.editLeaveDescription.adminAndApprover": "Bu iznin tarihlerini düzenleyebilirsiniz. İzin türünü düzenlemek istiyorsanız, lütfen izni iptal edin ve yeni bir tane ekleyin.",
  "components.leaveForm.editLeaveDescription.user": "Bu iznin tarihlerini ve nedenini düzenleyebilirsiniz. İzin türünü düzenlemek istiyorsanız, lütfen izni iptal edin ve yeni bir talep oluşturun.",
  "components.leaveForm.endTime": "Bitiş Zamanı",
  "components.leaveForm.halfDay": "{leaveTypeName} (Yarım Gün)",
  "components.leaveForm.hourly": "{leaveTypeName} (Saatlik)",
  "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (Saatlik)",
  "components.leaveForm.pleaseInputReason": "Neden gereklidir",
  "components.leaveForm.pleaseSelectEndDate": "Bir Bitiş Tarihi Seçin",
  "components.leaveForm.pleaseSelectLeaveType": "Bir İzin Türü Seçin",
  "components.leaveForm.pleaseSelectRangeTime": "Bir Zaman Aralığı Seçin",
  "components.leaveForm.pleaseSelectstartDate": "Bir Başlangıç Tarihi Seçin",
  "components.leaveForm.pleaseSelectUser": "Bir Kullanıcı Seçin",
  "components.leaveForm.pleaseSelectValidRangeTime": "Geçerli Bir Zaman Aralığı Seçin",
  "components.leaveForm.rangeDate": "Başlangıç ve Bitiş Tarihi",
  "components.leaveForm.reason": "Neden",
  "components.leaveForm.request": "Talep Et",
  "components.leaveForm.requestLeave": "İzin Talebi",
  "components.leaveForm.requestLeaveDescription": "Talebinizin kendiniz dışında biri tarafından onaylanması gerekiyorsa bu formu kullanın.",
  "components.leaveForm.selectSubstituteApprover": "Vekil Onaylayıcı Seçin",
  "components.leaveForm.selectSubstituteApproverInfoMessage": "Siz yokken gelen izin taleplerinizi yönetmek için bir kişi seçin. Vekil Onaylayıcının sınırlı hakları olacaktır, sadece izin taleplerini onaylayabilir veya reddedebilir. Bu adım isteğe bağlıdır.",
  "components.leaveForm.selectUser": "Bir Kullanıcı Seçin",
  "components.leaveForm.startTime": "Başlangıç Zamanı",
  "components.leaveForm.time": "Zaman",
  "components.leaveForm.user": "Bir Kullanıcı Seçin",
  "components.leavePolicyForm.accrualBiWeeklySummary": "Kullanıcılar her ikinci {day} günde {amount} ({quota}/26) gün biriktirecek",
  "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Kullanıcılar her ikinci {day} günde {amount} ({quota}/26) saat biriktirecek",
  "components.leavePolicyForm.accrualCap": "Birikim Üst Sınırını Etkinleştir",
  "components.leavePolicyForm.accrualCapRate": "Kazanılan günleri şu kadarla sınırla:",
  "components.leavePolicyForm.accrualCapRateTimes": "yıllık birikim oranının katı",
  "components.leavePolicyForm.accrualCapTooltip": "Birikim Üst Sınırı, bir çalışanın belirli bir süre içinde biriktirebileceği ücretli izin miktarını sınırlar. Yıllık birikim oranının bir katı olarak tanımlanır, kesin bir sayı değildir.",
  "components.leavePolicyForm.accrualCapWarning": "{leaveTypeName} için Birikim Üst Sınırına ulaşıldı. Mevcut günlerin bir kısmı kullanıldıktan sonra yeni günlerin birikimi devam edecek.",
  "components.leavePolicyForm.accrualCapWarning2": "Bu kullanıcı {leaveTypeName} birikim üst sınırına ulaştı ve mevcut bakiyenin bir kısmını kullanana kadar daha fazla gün biriktirmeyecek.",
  "components.leavePolicyForm.accrualDay": "Birikim Günü",
  "components.leavePolicyForm.accrualDayBiWeekly": "Birikim Verilen Gün",
  "components.leavePolicyForm.accrualDisabledInfo": "Birikimleri etkinleştirmek için sınırsız kotayı kapatın ve kotayı 0'dan büyük herhangi bir sayıya ayarlayın.",
  "components.leavePolicyForm.accrualMonthlySummary": "Kullanıcılar ayın {date}. gününde {amount} ({quota}/12) gün biriktirecek",
  "components.leavePolicyForm.accrualMonthlySummaryHours": "Kullanıcılar ayın {date}. gününde {amount} ({quota}/12) saat biriktirecek",
  "components.leavePolicyForm.accruals": "Birikimler",
  "components.leavePolicyForm.accrualSemiMonthlySummary": "Kullanıcılar ayın {firstDate} ve {secondDate} tarihlerinde {amount} ({quota}/24) gün biriktirecek",
  "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Kullanıcılar ayın {firstDate} ve {secondDate} tarihlerinde {amount} ({quota}/24) saat biriktirecek",
  "components.leavePolicyForm.accrualType": "Birikim Türü",
  "components.leavePolicyForm.accrualTypeError": "Gerekli",
  "components.leavePolicyForm.accrualTypeInfo": "Biriken İzin, şirketin politikasına göre yıl boyunca kademeli olarak birikir. Şirketinizde Birikimler varsa, yönetiminiz tarafından belirlenen Birikim Türünü seçin.",
  "components.leavePolicyForm.accrualWeeklySummary": "Kullanıcılar her {day} günü {amount} ({quota}/52) gün biriktirecek",
  "components.leavePolicyForm.accrualWeeklySummaryHours": "Kullanıcılar her {day} günü {amount} ({quota}/52) saat biriktirecek",
  "components.leavePolicyForm.addTitle": "İzin Politikası Ata",
  "components.leavePolicyForm.allDays": "Tümü",
  "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Geçmişteki İzinleri Düzenle ve Talep Et",
  "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Normal kullanıcıların geçmişteki izinleri düzenlemesine veya talep etmesine izin vermek isteyip istemediğinizi seçin.",
  "components.leavePolicyForm.allowAdvanceAccrualUsage": "Önceden Birikime Dayalı İzin Talebine İzin Ver",
  "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Çalışanların, talep anında biriktirdikleri günler yerine, gelecekte birikecek izin günlerine dayanarak izin talep etmelerine izin verin. Bu, bir çalışanın talep edilen izin tarihine kadar yeterli birikmiş güne sahip olacağını biliyorsa, talep anında yeterli günü olmasa bile önceden talepte bulunabileceği anlamına gelir.",
  "components.leavePolicyForm.approvalRequired": "Onay Gerekli",
  "components.leavePolicyForm.assignLeavePolicy": "İzin Politikası Ata",
  "components.leavePolicyForm.back": "Geri",
  "components.leavePolicyForm.BI-WEEKLY": "İki Haftada Bir",
  "components.leavePolicyForm.cancel": "İptal",
  "components.leavePolicyForm.copyLeavePolicy": "Politikayı Şuradan Kopyala",
  "components.leavePolicyForm.copyLeavePolicyDivider": "Başka bir izin veya lokasyondan şablon kullanabilirsiniz.",
  "components.leavePolicyForm.copyLeavePolicyInfo": "Daha önce ayarlanmış bir İzin Türünden İzin Politikası ayarlarını kopyalayın. Açılır menüden, İzin Politikasını kopyalamak istediğiniz İzin Türünü seçin ve ayarlar otomatik olarak yapılandırılacaktır. Bunu manuel olarak yapmak isterseniz, aşağıdaki ayarları yapılandırarak yapabilirsiniz.",
  "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": "Bir izin türü oluştururken birikimler etkin değildir. Etkinleştirmek için, belirli bir lokasyon için izin politikasını özelleştirin.",
  "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "TOIL (Fazla Mesai Karşılığı İzin) bir izin türü oluştururken etkin değildir. Etkinleştirmek için, belirli bir lokasyon için izin politikasını özelleştirin.",
  "components.leavePolicyForm.currentAccrualPeriod": "Mevcut Birikim Dönemi",
  "components.leavePolicyForm.daysPerYear": "Yıllık İzin Kotası",
  "components.leavePolicyForm.defaultLeavePolicy": "Kopyalama, yeni bir politika başlatacağım",
  "components.leavePolicyForm.editTitle": "{locationName} için {leavePolicyName} İzin Politikasını Düzenle",
  "components.leavePolicyForm.futureBroughtForward": "Gelecek devredilen",
  "components.leavePolicyForm.halfDays": "Yarım Günlere İzin Ver",
  "components.leavePolicyForm.hasUnlimitedDays": "Sınırsız Kota",
  "components.leavePolicyForm.hideLeaveType": "İzin Türünü Gizle",
  "components.leavePolicyForm.hideLeaveTypeInfo": "Bu İzin Türünü Takvim ve Bildirimlerden gizlemek isteyip istemediğinizi seçin. Yalnızca Yöneticiler, Onaylayanlar ve İzin Türünü talep eden Kullanıcı, bunu Takvimde tanımlayabilecektir.",
  "components.leavePolicyForm.hourlyIncrementsTooltip": "Ekip üyelerinizin saat bazlı izin talep etmesine izin verin.",
  "components.leavePolicyForm.isActive": "Aktif",
  "components.leavePolicyForm.leavePolicy": "İzin Politikası",
  "components.leavePolicyForm.limitedNumberOfDays": "Sınırlı",
  "components.leavePolicyForm.maxRolloverDays": "Maksimum Devredilen Günler",
  "components.leavePolicyForm.maxRolloverDaysError": "Gerekli",
  "components.leavePolicyForm.maxRolloverDaysInfo": "Bu İzin Türü için bir sonraki takvim yılına devredilebilecek gün sayısını seçin.",
  "components.leavePolicyForm.maxRolloverDaysInfoHours": "Bu İzin Türü için bir sonraki takvim yılına devredilebilecek saat sayısını seçin.",
  "components.leavePolicyForm.MONTHLY": "Aylık",
  "components.leavePolicyForm.negativeBalance": "Negatif Bakiyeye İzin Ver",
  "components.leavePolicyForm.negativeBalanceInfo": "Kullanıcıların bu İzin Türü için belirlediğiniz İzin Kotasını aşmasına izin vermek isteyip istemediğinizi seçin. Bu seçeneğe izin vermek istemiyorsanız, lütfen düğmeyi işaretlemeden bırakın.",
  "components.leavePolicyForm.nextAccrualDay": "Bir Sonraki Tahakkuk Tarihi",
  "components.leavePolicyForm.noLeaveTypes": "Mevcut İzin Türü bulunmamaktadır. Özel bir İzin Politikası ile yeni bir İzin Türü eklemek için lütfen İzin Türleri sekmesine gidin, yeni bir İzin Türü oluşturun ve ardından Konuma ekleyin.",
  "components.leavePolicyForm.NONE": "Hiçbiri",
  "components.leavePolicyForm.noRollover": "Devre Dışı",
  "components.leavePolicyForm.override.affectedUsers": "Etkilenen kullanıcılar: <strong>{totalUsers}</strong>",
  "components.leavePolicyForm.override.change": "Değişiklik: <unlimited>Sınırsız izin politikası değişikliği</unlimited><customDays>İzin kotası güncellemesi</customDays>",
  "components.leavePolicyForm.override.customLeaveQuotaInfo": "Etkilenen Kullanıcıların bazıları (<strong>{totalUsers}</strong>) özel izin kotasına sahip. Bu ayarı geçersiz kılmak istiyor musunuz?",
  "components.leavePolicyForm.override.leaveType": "İzin türü: <strong>{name}</strong>",
  "components.leavePolicyForm.override.location": "Konum: <strong>{name}</strong>",
  "components.leavePolicyForm.override.note": "Not: Bu değişiklik, hangi seçeneği seçerseniz seçin, özel izin kotası olmayan tüm kullanıcıları etkileyecektir.",
  "components.leavePolicyForm.override.overrideDisable": "Özel izin kotalarını geçersiz kıl",
  "components.leavePolicyForm.override.overrideEnable": "Mevcut izin kotasını koru",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Kullanıcı Devre Dışı Bırakıldığında Tahakkukları Duraklat",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Devre dışı bırakılan herhangi bir kullanıcı için izin günü tahakkuklarını geçici olarak askıya almak için bu özelliği etkinleştirin. Kullanıcı yeniden etkinleştirildiğinde, izin günlerinin tahakkuku otomatik olarak devam edecek, böylece izin haklarının doğru bir şekilde takibi sağlanacaktır",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "Kuruluşunuzun politikalarına göre, bu kullanıcının hesabı etkin değilken izin tahakkuku duraklatılır. Daha fazla bilgi için lütfen yöneticinize başvurun.",
  "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Önceki devredilenler ve gelecektekiler",
  "components.leavePolicyForm.reasonRequired": "Sebep Gerekli",
  "components.leavePolicyForm.required": "Gerekli",
  "components.leavePolicyForm.rolloverDaysPerYear": "Maksimum gün sayısı",
  "components.leavePolicyForm.rolloverPerYearDays": "Yıl Başına Maksimum Gün Sayısı",
  "components.leavePolicyForm.rolloverPerYearHours": "Yıl Başına Maksimum Saat Sayısı",
  "components.leavePolicyForm.rolloverSettings": "Devredilen Ayarları",
  "components.leavePolicyForm.rolloverType": "Devredilen",
  "components.leavePolicyForm.save": "Kaydet",
  "components.leavePolicyForm.saveLeavePolicy": "Kaydet",
  "components.leavePolicyForm.selectLeaveType": "İzin Türü Seç",
  "components.leavePolicyForm.SEMI-MONTHLY": "Yarı Aylık",
  "components.leavePolicyForm.updateLeavePolicy": "Güncelle",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "Bu Değişiklikleri Ne Zaman Uygulamak İstersiniz?",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "İzin değişikliklerinizi uygulamak için zaman dilimini seçin. Yalnızca gelecekteki izin tarihlerini ayarlamak için 'Gelecekte devredilen' seçeneğini, hem geçmiş hem de gelecekteki izin tarihlerini değiştirmek için 'Önceki devredilen ve gelecektekiler' seçeneğini seçin.",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "Bu Değişiklikleri Ne Zaman Uygulamak İstersiniz?",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "İzin değişikliklerinizi uygulamak için zaman dilimini seçin. Yalnızca gelecekteki izin tarihlerini ayarlamak için 'Gelecekte devredilen' seçeneğini, hem geçmiş hem de gelecekteki izin tarihlerini değiştirmek için 'Önceki devredilen ve gelecektekiler' seçeneğini seçin.",
  "components.leavePolicyForm.yearlyQuota": "Yıllık İzin Kotası",
  "components.leavePolicyForm.yearlyQuotaHours": "Yıllık İzin Kotası (Saat)",
  "components.leavePolicyForm.yearlyQuotaInfo": "Bu alan, temel yıllık izin kotasını temsil eder. Kullanıcı profilleri aracılığıyla bireysel kotaları manuel olarak ayarlayabilir veya otomasyon özelliğimizle farklı kullanıcılar arasında otomatik ayarlamalar yapabilirsiniz.",
  "components.leaveRequestAction.added": "İzin <forUser>için </forUser>{requestor} eklendi <byActionCreator>{actionCreator} tarafından</byActionCreator>",
  "components.leaveRequestAction.addToCalendar": "Takvime Ekle",
  "components.leaveRequestAction.addToCalendarMoreInfo": "\"Takvime Ekle\" özelliği hakkında daha fazla bilgi",
  "components.leaveRequestAction.approved": "{requestor} İzni <byActionCreator>{actionCreator} tarafından</byActionCreator> onaylandı ✅",
  "components.leaveRequestAction.approversLabel": "Onaylayanlar: ",
  "components.leaveRequestAction.autoApproved": "{requestor} İzni otomatik olarak onaylandı",
  "components.leaveRequestAction.cancelled": "{requestor} İzni <byActionCreator>{actionCreator} tarafından</byActionCreator> iptal edildi",
  "components.leaveRequestAction.created": "İzin <byActionCreator>{actionCreator} tarafından</byActionCreator> talep edildi",
  "components.leaveRequestAction.deleted": "İzin <byActionCreator>{actionCreator} tarafından</byActionCreator> silindi",
  "components.leaveRequestAction.denied": "{requestor} İzni <byActionCreator>{actionCreator} tarafından</byActionCreator> reddedildi ❌",
  "components.leaveRequestAction.edited": "{requestor} İzni <byActionCreator>{actionCreator} tarafından</byActionCreator> düzenlendi",
  "components.leaveRequestAction.editedApproved": "Planlanmış bir izin <byActionCreator>{actionCreator} tarafından</byActionCreator> düzenlendi ✅",
  "components.leaveRequestAction.editedAutoApproved": "Düzenlenen izin <byActionCreator>{actionCreator} tarafından</byActionCreator> otomatik onaylandı",
  "components.leaveRequestAction.editedDenied": "Düzenlenen İzin <byActionCreator>{actionCreator} tarafından</byActionCreator> reddedildi",
  "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate} ",
  "components.leaveRequestAction.leaveRemaining": "Kalan İzin: ",
  "components.leaveRequestAction.leaveRequested": "Talep Edilen İzin: ",
  "components.leaveRequestAction.leaveTypeLabel": "İzin Türü: ",
  "components.leaveRequestAction.partDayLeavePeriod": "{date} - {partDayStartHour}{hourFormat24, select, true {:00} other {}}'dan {partDayEndHour}{hourFormat24, select, true {:00} other {}}'a kadar ",
  "components.leaveRequestAction.periodLabel": "Tarih: ",
  "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "Talebi onayladınız. {name} kişisine bilgi vermek için bir bildirim gönderilecektir.",
  "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "İzin talebi {approverName} tarafından onaylandı.",
  "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "İzin talebi iptal edildi.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "Talebi reddettiniz. {name} kişisine bilgi vermek için bir bildirim gönderilecektir.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "İzin talebi {approverName} tarafından reddedildi.",
  "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "Talep süresi doldu.",
  "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "İzin talebi onaylandı.",
  "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "İzin talebi reddedildi.",
  "components.leavesColumns.approver": "Onaylayan",
  "components.leavesColumns.autoApproved": "Otomatik onaylandı",
  "components.leavesColumns.cancelledBeforeReview": "İnceleme öncesi iptal edildi",
  "components.leavesColumns.dates": "Tarih(ler)",
  "components.leavesColumns.days": "{value} gün",
  "components.leavesColumns.denyReason": "Onaylayanın Nedeni",
  "components.leavesColumns.duration": "Süre",
  "components.leavesColumns.halfDayHoliday": "(Yarım Gün Tatil)",
  "components.leavesColumns.hours": "{value} saat",
  "components.leavesColumns.leaveType": "İzin Türü",
  "components.leavesColumns.reason": "Talep Edenin Nedeni",
  "components.leavesColumns.reviewedby": "İnceleyen",
  "components.leavesColumns.status": "Durum",
  "components.leaveTypeForm.cancel": "İptal",
  "components.leaveTypeForm.color": "Renk",
  "components.leaveTypeForm.editLeaveTypeTitle": "{leaveTypeName} İzin Türünü Düzenle",
  "components.leaveTypeForm.forbiddenCharacters": "İzin Türü adı noktalama işareti veya özel karakter içeremez.",
  "components.leaveTypeForm.isActive": "Aktif",
  "components.leaveTypeForm.maxLength": "İzin Türü adı çok uzun.",
  "components.leaveTypeForm.name": "Ad",
  "components.leaveTypeForm.nameIsRequired": "Bu alan zorunludur.",
  "components.leaveTypeForm.save": "Kaydet",
  "components.leaveTypeForm.setSlackStatus": "Slack Durumunu Ayarla",
  "components.leaveTypeForm.setSlackStatusPlaceholder": "Lütfen Slack durumunu ayarlayın",
  "components.leaveTypeForm.slackStatus": "Slack Durumu",
  "components.leaveTypeForm.title": "İzin Türü",
  "components.leaveTypeForm.tooltipElement1": "<strong>Onay Gerekli</strong>: Bu seçenek, bir iznin yönetici onayı gerektirmesi durumunda açılabilir.",
  "components.leaveTypeForm.tooltipElement2": "<strong>Negatif Bakiyeye İzin Ver</strong>: Bu seçenek, kullanıcıların kotalarını kullandıktan sonra bile izin talep etmelerine olanak tanır.",
  "components.leaveTypeForm.tooltipElement3": "<strong>Yarım Günlere İzin Ver</strong>: Bu seçenek, kuruluşunuzun ihtiyaçlarına göre etkinleştirilebilir.",
  "components.leaveTypeForm.tooltipFooter": "Unutmayın, İzin Politikalarının her konum için ayrı ayrı tanımlandığını belirtmek önemlidir. Bu politikaları kuruluşunuzun ihtiyaçlarına göre özelleştirebilir ve uygun izin türlerine atayabilirsiniz.",
  "components.leaveTypeForm.tooltipHeader": "Vacation Tracker'da, 'İzin Türleri' Ücretli İzin, Hastalık İzni, Evden Çalışma gibi global izin kategorilerine atıfta bulunur. Öte yandan, 'İzin Politikaları' her İzin Türüne her konum için atanan özel kural ve ayarlardır. Bu politikalar şu gibi çeşitli seçenekleri içerebilir:",
  "components.legacyPlanAlert.warning": "Şu anda eski planı kullanıyorsunuz. Planları değiştirirseniz, tekrar etkinleştiremezsiniz. Daha fazla bilgi için lütfen <link>destek</link> ekibimizle iletişime geçin.",
  "components.locationForm.changeLocationWarning": "Kullanıcıyı aktarmak istediğiniz Konumu seçin.",
  "components.locationForm.changeUserLocation": "Kullanıcının Konumunu Değiştir",
  "components.locationForm.days": "Gün",
  "components.locationForm.daysAfterEmploymentDate": "işe başlama tarihinden sonraki gün.",
  "components.locationForm.daysInfo": "Kullanılmayan iznin bir sonraki takvim yılına aktarılması, Kullanıcının İşe Başlama Tarihini takip eden belirlediğiniz gün sayısından sonra sona erecektir. Her İzin Türü için İzin Kotası, Konum Ayarlarındaki İzin Türleri sekmesi aracılığıyla yapılandırılır ve aktarmanın etkinleştirilmesi veya devre dışı bırakılması seçeneğini içerir.",
  "components.locationForm.defaultLocation": "Bu Konumu Varsayılan Yap",
  "components.locationForm.firstDayOfWeek": "Hafta Başlangıcı",
  "components.locationForm.fiscalYear": "Muhasebe Yılı",
  "components.locationForm.fiscalYearStart": "Mali Yıl Başlangıcı",
  "components.locationForm.name": "Ad",
  "components.locationForm.noRolloverExpiryDate": "Aktarılan İzin Sona Erme Tarihi Yok",
  "components.locationForm.overrideUserSettings": "Kullanıcı Ayarlarını Geçersiz Kıl",
  "components.locationForm.overrideUserSettingsInfo": "Çalışma haftası yapılandırması yalnızca Kullanıcı Profillerinde özel Ayarları olmayan Kullanıcılara uygulanacaktır. Yeni Çalışma Haftası ayarlarını özel Çalışma Haftası ayarlanmış olanlar da dahil olmak üzere tüm Kullanıcılara uygulamak istiyorsanız, \"Kullanıcı Ayarlarını Geçersiz Kıl\" seçeneğini seçin.",
  "components.locationForm.resetQuotas": "İzin Kotası Sıfırlama Esası",
  "components.locationForm.resetQuotasInfo": "Bu ayar, izin bakiyenizin muhasebe yılına (şirketin mali yılı) göre mi yoksa çalışanın işe başlama tarihine göre mi sıfırlanacağını belirleyecektir. Kotaların yanı sıra, aktarma politikanız da bu ayara göre etkilenecektir.",
  "components.locationForm.rolloverExpiryAfterDays": "Aktarılan İzin Sona Erme Tarihi Yok",
  "components.locationForm.rolloverExpiryAfterDaysInfo": "Her yıl, kullanıcıların aktarılan izinleri işe başlama tarihlerinden bu kadar gün sonra sona erecektir. Her izin türü için kotalar, bu konum için İzin Türleri bölümü aracılığıyla yapılandırılır ve her biri aktarmalara izin verip vermeyeceği şekilde ayrı ayrı ayarlanabilir.",
  "components.locationForm.rolloverExpiryDay": "Aktarılan İzin Sona Erme Tarihi Yok",
  "components.locationForm.rolloverExpiryDayInfo": "Her yıl, kullanıcının aktarılan izinleri belirlediğiniz tarihte sona erecektir. Her izin türü için kotalar, bu konum için İzin Türleri bölümü aracılığıyla yapılandırılır ve her biri aktarmalara izin verip vermeyeceği şekilde ayrı ayrı ayarlanabilir.",
  "components.locationForm.selectLocation": "Konum Seç",
  "components.locationForm.selectLocationError": "Lütfen bir konum seçin.",
  "components.locationForm.settings.makeDefaultDescription": "Bu Konumu varsayılan yaparak, tüm yeni ekip üyeleri otomatik olarak bu Konuma eklenecektir.",
  "components.locationForm.settings.makeDefaultTitle": "Varsayılan Konum",
  "components.locationForm.settings.nameDescription": "Konumunuzun adını belirleyin ve kullanıcılarını seçin. Kullanıcıları eklemeden önce konumu yapılandırmak istiyorsanız, önce konumu oluşturabilir ve daha sonra kullanıcıları ekleyebilirsiniz.",
  "components.locationForm.settings.nameDescription2": "Bu varsayılan saat dilimi sistem genelinde kullanılır. Örneğin, takvimde izin bilgilerini doğru bir şekilde göstermek ve Günlükler'de listelenen sistem olayları için kullanılır.",
  "components.locationForm.settings.namePlaceholder": "Konum adını girin",
  "components.locationForm.settings.nameTitle": "Konum Ayarları",
  "components.locationForm.settings.quotasDescription": "Bu ayar, izin bakiyenizin muhasebe yılına (şirketin mali yılı) veya çalışanın başlangıç tarihine göre sıfırlanıp sıfırlanmayacağını belirler. Kotaların yanı sıra, devir politikanız da bu ayara göre etkilenecektir.",
  "components.locationForm.settings.quotasTitle": "İzin Kotası ve Mali Yıl",
  "components.locationForm.settings.workWeekDescription": "İzin verilerinin doğru bir şekilde takip edilmesini sağlamak için lütfen haftanın çalışma günlerini seçin.",
  "components.locationForm.settings.workWeekTitle": "Çalışma Haftası ve İş Günü",
  "components.locationForm.users": "Kullanıcılar",
  "components.locationForm.userYear": "Kullanıcı İşe Başlama Tarihi",
  "components.locationForm.warningInitialRollover": "Konum ayarlarındaki herhangi bir değişiklik, kullanıcıların yapılandırmasını ve izin kotası ve hesaplamaları gibi izin bilgilerini etkileyebilir. \nLütfen bu konumda herhangi bir izin politikasında ileriye taşıma son kullanma tarihi ayarladıysanız, bunun devre dışı bırakılacağını unutmayın.",
  "components.locationForm.warningInitialRolloverFiscalYear": "Konum ayarlarındaki herhangi bir değişiklik, kullanıcıların yapılandırmasını ve izin kotası ve hesaplamaları gibi izin bilgilerini etkileyebilir.",
  "components.locationForm.warningInitialRolloverResetQuotas": "Konum ayarlarındaki herhangi bir değişiklik, kullanıcıların yapılandırmasını ve izin kotası ve hesaplamaları gibi izin bilgilerini etkileyebilir.",
  "components.locationForm.warningInitialRolloverUsers": "Kullanıcı ekleme veya çıkarma, kullanıcının yapılandırmasını ve izin bilgilerini etkileyebilir (örneğin, ilk ileriye taşınan günler).",
  "components.locationForm.workWeek": "Çalışma Haftası",
  "components.locationHolidays.addHolidays": "Tatil Ekle",
  "components.locationHolidays.deleteHolidaysConfirm": "{name} tatilini silmek istediğinizden emin misiniz?",
  "components.locationHolidays.deleteHolidaysTitle": "Tatili Sil",
  "components.locationHolidays.holidaysForLocation": "{locationName} konumu için tatiller",
  "components.locationHolidays.insertHolidays": "Tatilleri İçe Aktar",
  "components.locationHolidays.overlap": "Bu tatil mevcut bir tatille çakışıyor",
  "components.locationLeavePolicies.activatingLeaveTypeInfo": "Bu İzin Türünü etkinleştirerek, Kullanıcıların belirli İzin Türünü talep etmesine izin veriyorsunuz. \n\nÖnceki ayarlar ve veriler geri yüklenecektir.",
  "components.locationLeavePolicies.activatingLeaveTypeTitle": "İzin Türünü Etkinleştir",
  "components.locationLeavePolicies.allowHalfDays": "Yarım Günlere İzin Ver",
  "components.locationLeavePolicies.createNewLeavePolicy": "İzin Politikası Ata",
  "components.locationLeavePolicies.createNewLeavePolicyInfo": "Bir Konuma özel İzin Türü eklemek için İzin Politikası Ata'ya gidin. İzin Türleri sekmesi aracılığıyla zaten oluşturduğunuz İzin Türünü seçin ve İzin Politikası ayarlarını yapılandırın.",
  "components.locationLeavePolicies.daysPerYear": "Yıllık İzin Kotası",
  "components.locationLeavePolicies.deactivateLeaveTypeInfo": "Bu İzin Türünü devre dışı bıraktığınızda, bu Konumdaki Kullanıcılar artık belirli İzin Türünü talep edemeyecekler. Tekrar etkinleştirdiğinizde, İzin Türü önceki ayarları ve verileriyle birlikte geri yüklenecektir. \n\nBu İzin Türünü tüm Konumlar için devre dışı bırakmak istiyorsanız, bunu İzin Türleri sekmesinden yapabilirsiniz.",
  "components.locationLeavePolicies.deactivateLeaveTypeTitle": "İzin Türünü Devre Dışı Bırak",
  "components.locationLeavePolicies.hasUnlimitedDays": "Sınırsız",
  "components.locationLeavePolicies.isApprovalRequired": "Onay Gerekli",
  "components.locationLeavePolicies.leaveTypeName": "İzin Türü Adı",
  "components.locationLeavePolicies.status": "Durum",
  "components.locationLeavePolicy.createInProgress": "{name} izin politikası oluşturuluyor",
  "components.locationLeavePolicy.successTitle": "İzin politikası ayarlandı",
  "components.locationLeavePolicy.updateInProgress": "İzin politikası güncelleniyor",
  "components.locationLeaveTypesTag.hiddenLeaveType": "Gizli İzin Türü",
  "components.locationLeaveTypesTag.holidays": "Tatiller",
  "components.logs.accrual": "Kullanıcı {earned} {leaveTypeName} {earnedDayInGrammaticalNumber} biriktirdi ({defaultDaysPerYear} {defaultDayInGrammaticalNumber} kotadan)",
  "components.logs.accrualDays": "Kullanıcı {earned} {leaveTypeName} {earned, plural, =1 {gün} other {gün}} tahakkuk etti (toplam {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {gün} other {gün}} kotadan)",
  "components.logs.accrualDisplay": "Kullanıcı {earned} {leaveTypeName} tahakkuk etti (toplam {defaultDaysPerYear} kotadan)",
  "components.logs.accrualHours": "Kullanıcı {earned} {leaveTypeName} {earned, plural, =1 {saat} other {saat}} tahakkuk etti (toplam {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {saat} other {saat}} kotadan)",
  "components.logs.automationAddonsUpdatedPurchased": "{user} Eklenti satın aldı: {automationType}",
  "components.logs.automationAddonsUpdatedUnsubscribed": "{user} Eklenti aboneliğini iptal etti: {automationType}",
  "components.logs.billingCancelled": "Faturalama {paymentProcessor} üzerinde iptal edildi",
  "components.logs.billingUpdated": "Faturalama {paymentProcessor} üzerinde güncellendi",
  "components.logs.blackoutPeriodCreated": "{user} bir Kapalı Dönem oluşturdu <b>{name}</b>.<br></br>Etkin: {enabled}.<br></br>Tekrarlanan: {recurring}.<br></br>Başlangıç Tarihi: {startDate}.<br></br>Bitiş Tarihi: {endDate}.<br></br>İzin Türleri: {leaveTypes}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>",
  "components.logs.blackoutPeriodDeleted": "{user} bir Kapalı Dönemi sildi.",
  "components.logs.blackoutPeriodUpdated": "{user} bir Kapalı Dönemi güncelledi <b>{name}</b>.<br></br>Etkin: {enabled}.<br></br>Tekrarlanan: {recurring}.<br></br>Başlangıç Tarihi: {startDate}.<br></br>Bitiş Tarihi: {endDate}.<br></br>İzin Türleri: {leaveTypes}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>",
  "components.logs.companyCreated": "Şirket <b>{name}</b> oluşturuldu",
  "components.logs.companySettingsUpdated": "<b>{user}</b> şirket ayarlarını güncelledi.<br></br>Yeni kullanıcıları duyur: {announceNewUsers}.<br></br>Saatlik izin muhasebesi: {hourlyLeaveAccounting}.<br></br>Kullanıcı bitiş tarihi: {userEndDate}.<br></br>Hesaplama Başlangıç Yılı: {calculationStartYear}<br></br>Kullanıcıları otomatik içe aktar: {importUsersAutomatically}",
  "components.logs.deleted": "<Silindi>",
  "components.logs.earned": "Kullanıcı fazla mesai çalışmasından (TOIL) {total} gün {leaveTypeName} kazandı {period}. {showExpiration, plural, =1 {Günler şu tarihte sona erecek: } other {}}{expiration}",
  "components.logs.entitlementByRoleCreated": "{user} bir Role Göre Hak Ediş oluşturdu <b>{name}</b>.<br></br>Etkin: {enabled}.<br></br>Günler: {days}.<br></br>Etiket: {label}.<br></br>İzin Türü: {leaveType}.<br></br>",
  "components.logs.entitlementByRoleDeleted": "{user} bir Role Göre Hak Edişi sildi.",
  "components.logs.entitlementByRoleUpdated": "{user} bir Role Göre Hak Edişi güncelledi <b>{name}</b>.<br></br>Etkin: {enabled}.<br></br>Günler: {days}.<br></br>Etiket: {label}.<br></br>İzin Türü: {leaveType}.<br></br>",
  "components.logs.failedToLoadLog": "Günlük yüklenemedi",
  "components.logs.importLeaves": "<b>{user}</b> {totalLeaves} {totalLeaves, plural, =1 {izin} other {izin}} içe aktardı.",
  "components.logs.importUsers": "<b>{user}</b> {totalUsers} {totalUsers, plural, =1 {kullanıcı} other {kullanıcı}} içe aktardı.",
  "components.logs.initialRolloverDeleted": "İzin Türü için Başlangıç Devri: {leaveTypeName} <link>{creatorName}</link> tarafından 0'a sıfırlandı",
  "components.logs.initialRolloverUpdated": "İzin Türü için Başlangıç Devri: {leaveTypeName} <link>{creatorName}</link> tarafından {customRolloverDays} olarak değiştirildi",
  "components.logs.leavePolicyDisabled": "{user} <b>{locationName}</b> konumunda <b>{leaveTypeName}</b> izin politikasını devre dışı bıraktı",
  "components.logs.leavePolicyEnabled": "{user} <b>{locationName}</b> konumunda <b>{leaveTypeName}</b> izin politikasını etkinleştirdi.<br></br>Yıllık gün sayısı: {daysPerYear}.<br></br>Maksimum devir günü: {maxRolloverDays}.<br></br>Devir günleri sona ermeli mi: {enableRolloverExpiry}.<br></br>Devir günleri şu tarihte sona erer: {rolloverExpiryDate}.<br></br>Devir günleri şu kadar gün sonra sona erer: {rolloverExpiryAfterDays}.<br></br>Onay gerekli: {isApprovalRequired}.<br></br>Sınırsız günler: {hasUnlimitedDays}.<br></br>En kısa izin aralığı: {shortestLeaveInterval}.<br></br>Sebep gerekli: {isReasonRequired}.<br></br>Tahakkuk türü: {accrualType}.<br></br>Negatif bakiyeye izin verilir: {negativeBallanceAllowed}.<br></br>Geçmişte izin vermeye izin ver: {allowLeaveInPast}.<br></br>Tahakkuk üst sınırı: {accrualCapRate, plural, =0 {Devre dışı} other {Etkin, oran: {accrualCapRate}}}.<br></br>İzin türünü gizle: {hideLeaveType}.<br></br>Toil etkin: {toil}.<br></br>Toil en kısa aralık: {toilShortestInterval}.<br></br>Toil tüm kullanıcılar: {toilRequestsAllowedForUsers}.<br></br>Toil son kullanma tarihi: {toilExpiration}.<br></br>Toil son kullanma tarihi: {toilExpirationDate}.<br></br>Toil son kullanma ayları: {toilExpirationMonths}.",
  "components.logs.leaveTypeCreated": "{user} <b>{name}</b> izin türünü oluşturdu ve şu konum(lar)a atadı: <b>{locations}</b>.<br></br>Renk: {color}.<br></br>Yıllık gün sayısı: {daysPerYear}.<br></br>Maksimum devir günü sayısı: {maxRolloverDays}.<br></br>Onay gerekli: {isApprovalRequired}.<br></br>Sınırsız günler: {hasUnlimitedDays}.<br></br>En kısa izin aralığı: {shortestLeaveInterval}.<br></br>Sebep gerekli: {isReasonRequired}.<br></br>Tahakkuk türü: {accrualType}.<br></br>Negatif bakiyeye izin verilir: {negativeBallanceAllowed}.<br></br>Geçmişte izin eklemeye izin ver: {allowLeaveInPast}.<br></br>İzin türünü gizle: {hideLeaveType}",
  "components.logs.leaveTypeDeleted": "{user} <b>{name}</b> izin türünü sildi",
  "components.logs.leaveTypeUpdated": "{user} <b>{name}</b> izin türünü güncelledi.<br></br>Aktif: {isActive}.<br></br>Renk: {color}",
  "components.logs.locationCreated": "{user} <b>{name}</b> konumu oluşturdu.<br></br>Yıl başlangıç ayı: {yearStartMonth}.<br></br>Yıl başlangıç günü: {yearStartDay}.<br></br>Varsayılan konum: {isDefault}.<br></br>Haftanın ilk günü: {firstDayOfWeek}.<br></br>Kota Sıfırlama Esası: {resetQuotas}.<br></br>Devir sona erme ayı: {rolloverExpiryMonth}.<br></br>Devir sona erme günü: {rolloverExpiryDay}.<br></br>Devir sona erme gün sayısı: {rolloverExpiryAfterDays}.<br></br>Saat dilimi: {timezone}.<br></br>Kullanıcılar: {users}.<br></br>Günler: {days}",
  "components.logs.locationDeleted": "{user} konumu sildi",
  "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> <b>{location}</b> konumu için otomatik tatil içe aktarmayı kapattı",
  "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> <b>{location}</b> konumu için otomatik tatil içe aktarmayı açtı<br></br>Tatiller: {holidays}",
  "components.logs.locationUpdated": "{user} <b>{name}</b> konumunu güncelledi.<br></br>Yıl başlangıç ayı: {yearStartMonth}.<br></br>Yıl başlangıç günü: {yearStartDay}.<br></br>Varsayılan konum: {isDefault}.<br></br>Haftanın ilk günü: {firstDayOfWeek}.<br></br>Kotaları sıfırla: {resetQuotas}.<br></br>Devir sona erme ayı: {rolloverExpiryMonth}.<br></br>Devir sona erme günü: {rolloverExpiryDay}.<br></br>Devir sona erme gün sayısı: {rolloverExpiryAfterDays}.<br></br>Saat dilimi: {timezone}.<br></br>Kullanıcılar: {users}",
  "components.logs.locationWorkWeekUpdated": "{user} <b>{name}</b> konumunda çalışma haftasını güncelledi.<br></br>Günler: {days}.<br></br>Saatler: {hours}",
  "components.logs.locationYearHolidaysChanged": "<b>{user}</b> <b>{name}</b> konumunda {year} yılı için tatil(ler)i değiştirdi.<br></br>Tatiller: {holidays}",
  "components.logs.locationYearRollover": "<b>{name}</b> konumunda konum yılı devri gerçekleşti",
  "components.logs.nonAccrualLeaveType": "Kullanıcı {period} için {earned} <b>{leaveTypeName}</b> aldı",
  "components.logs.nonAccrualLeaveTypeDays": "Kullanıcı {period} için {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {gün} other {gün}} aldı",
  "components.logs.nonAccrualLeaveTypeHours": "Kullanıcı {period} için {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {saat} other {saat}} aldı",
  "components.logs.notificationCreated": "Kullanıcı {user} <b>{name}</b> bildirimini oluşturdu.<br></br>Sıklık: {frequency}.<br></br>Etkin: {enabled}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>Haftanın ilk günü: {firstDayOfWeek}.<br></br>Saat dilimi: {timezone}.<br></br>Grupla {groupBy}.<br></br>Boş gönder: {sendEmpty}.<br></br>Hedefler: {destinations}.<br></br>Dakika: {minute}.<br></br>Saat: {hour}.<br></br>Gün: {day}.<br></br>Dil: {locale}.<br></br>Gönderim günleri: {sendOnDays}.<br></br>Mevcut hafta: {currentWeek}",
  "components.logs.notificationDeleted": "Kullanıcı {user} bildirimi sildi",
  "components.logs.notificationDetailsDaily": "Kullanıcı {user} bildirimi <b>{name}</b> {action}.<br></br>Sıklık: {frequency}.<br></br>Etkin: {enabled}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>Saat dilimi: {timezone}.<br></br>Gruplandırma: {groupBy}.<br></br>Boş gönder: {sendEmpty}.<br></br>Hedefler: {destinations}.<br></br>Dakika: {minute}.<br></br>Saat: {hour}.<br></br>Dil: {locale}.<br></br>Gönderilecek günler: {sendOnDays}.",
  "components.logs.notificationDetailsMonthly": "Kullanıcı {user} bildirimi <b>{name}</b> {action}.<br></br>Sıklık: {frequency}.<br></br>Etkin: {enabled}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>Haftanın ilk günü: {firstDayOfWeek}.<br></br>Saat dilimi: {timezone}.<br></br>Gruplandırma: {groupBy}.<br></br>Boş gönder: {sendEmpty}.<br></br>Hedefler: {destinations}.<br></br>Dakika: {minute}.<br></br>Saat: {hour}.<br></br>Gün: {day}.<br></br>Dil: {locale}.<br></br>Mevcut hafta: {currentWeek}",
  "components.logs.notificationUpdated": "Kullanıcı {user} bildirimi <b>{name}</b> güncelledi.<br></br>Sıklık: {frequency}.<br></br>Etkin: {enabled}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>Haftanın ilk günü: {firstDayOfWeek}.<br></br>Saat dilimi: {timezone}.<br></br>Gruplandırma: {groupBy}.<br></br>Boş gönder: {sendEmpty}.<br></br>Hedefler: {destinations}.<br></br>Dakika: {minute}.<br></br>Saat: {hour}.<br></br>Gün: {day}.<br></br>Dil: {locale}.<br></br>Gönderilecek günler: {sendOnDays}.<br></br>Mevcut hafta: {currentWeek}",
  "components.logs.probationPeriodCreated": "{user} bir Deneme Süresi <b>{name}</b> oluşturdu.<br></br>Etkin: {enabled}.<br></br>Süre (gün): {lengthDays}.<br></br>İzin Türleri: {leaveTypes}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>",
  "components.logs.probationPeriodDeleted": "{user} bir Deneme Süresini sildi.",
  "components.logs.probationPeriodUpdated": "{user} bir Deneme Süresini <b>{name}</b> güncelledi.<br></br>Etkin: {enabled}.<br></br>Süre (gün): {lengthDays}.<br></br>İzin Türleri: {leaveTypes}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>",
  "components.logs.seniorityEntitlementCreated": "{user} bir Kıdem Hakkı <b>{name}</b> oluşturdu.<br></br>Etkin: {enabled}.<br></br>Orantılı: {prorated}.<br></br>Başlangıç Tarihi: {startDate}.<br></br> İzin Türü: {leaveType}.<br></br>Verilen: {entitlementPeriods}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>",
  "components.logs.seniorityEntitlementDeleted": "{user} bir Kıdem Hakkını sildi.",
  "components.logs.seniorityEntitlementUpdated": "{user} Kıdem Hakkını <b>{name}</b> güncelledi.<br></br>Etkin: {enabled}.<br></br>Orantılı: {prorated}.<br></br>Başlangıç Tarihi: {startDate}.<br></br> İzin Türü: {leaveType}.<br></br>Verilen: {entitlementPeriods}.<br></br>Konumlar: {locations}.<br></br>Departmanlar: {teams}.<br></br>Etiketler: {labels}.<br></br>",
  "components.logs.subscriptionCancelled": "<b>{user}</b> aboneliği iptal etti.<br></br>Neden: {reason}.<br></br>Yorum: {comment}",
  "components.logs.subscriptionChanged": "Abonelik değiştirildi.<br></br>Yeni plan: {plan}.<br></br>Dönem: {period}.<br></br>Durum: {status}.<br></br>Ödeme işlemcisi: {paymentProcessor}",
  "components.logs.substituteApproverCreated": "{substituteApproverName}, {creator} tarafından {startDate} tarihinden {endDate} tarihine kadar {approverFor} için Yedek Onaylayıcı olarak atandı",
  "components.logs.substituteApproverDeleted": "{creator}, {substituteApproverName}'i Yedek Onaylayıcı olarak kaldırdı.",
  "components.logs.substituteApproverUpdated": "{creator} yedek onaylayıcıyı güncelledi.<br></br>Yedek Onaylayıcı adı: {substituteApproverName}<br></br>Başlangıç tarihi: {startDate}<br></br>Bitiş tarihi: {endDate}<br></br>",
  "components.logs.teamApproverStatusChanged": "{user}, {team} ekibi için onaylayıcıları {approvers} olarak ayarladı",
  "components.logs.teamCreated": "{user} bir departman <b>{team}</b> oluşturdu.<br></br>Varsayılan departman: {isDefault}.<br></br>Kullanıcılar: {users}.<br></br>Onaylayıcı(lar): {approvers} <br></br>Üst departman: {parentDepartment}",
  "components.logs.teamDeleted": "{user} ekibi sildi",
  "components.logs.teamUpdated": "{user} bir departmanı <b>{team}</b> güncelledi.<br></br>Varsayılan departman: {isDefault}.<br></br>Kullanıcılar: {users}.<br></br>Onaylayıcı(lar): {approvers} <br></br>Üst departman: {parentDepartment}",
  "components.logs.userActivatedBy": "Kullanıcı <link>{creatorName}</link> tarafından etkinleştirildi",
  "components.logs.userApproverStatus": "Kullanıcı, <link>{creatorName}</link> tarafından <team>{teamName}</team> departmanı için onaylayıcı olarak <status></status>",
  "components.logs.userChangedAvatar": "Kullanıcı profil resmini güncelledi",
  "components.logs.userDeactivatedBy": "Kullanıcı <link>{creatorName}</link> tarafından devre dışı bırakıldı",
  "components.logs.userDeletedBy": "Kullanıcı <link>{creatorName}</link> tarafından silindi",
  "components.logs.userEntitlementByRole": "Kullanıcıya Rol Bazlı Hak Ediş yoluyla {leaveTypeName} için {totalDays} gün verildi",
  "components.logs.userEntitlementByRoleDeleted": "Kullanıcının {leaveTypeName} için Rol Bazlı Hak Ediş günleri, <b>{labelName}</b> etiketinin kaldırılması veya silinmesi nedeniyle kaldırıldı.",
  "components.logs.userEntitlementByRoleOverride": "Kullanıcının {leaveTypeName} için hak ediş günleri, Rol Bazlı Hak Ediş ayarlamaları nedeniyle {totalDays} güne geçersiz kılındı",
  "components.logs.userInvitationAccepted": "Kullanıcı, <link>{creatorName}</link> tarafından gönderilen daveti kabul etti",
  "components.logs.userLeaveTypeDeleteQuota": "<b>{leaveTypeName}</b> izin türü için kota, <link>{creatorName}</link> tarafından varsayılan değere sıfırlandı",
  "components.logs.userLeaveTypeUnlimitedDays": "sınırsız",
  "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> bu kullanıcı için <b>{leaveTypeName}</b> kotasını <quota></quota> güne değiştirdi",
  "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> bu kullanıcı için <b>{leaveTypeName}</b> kotasını <quota></quota> güne değiştirdi",
  "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> bu kullanıcı için <b>{leaveTypeName}</b> kotasını <quota></quota> saate değiştirdi",
  "components.logs.userMovedToLocation": "Kullanıcı, <link>{creatorName}</link> tarafından <locationLink>{location}</locationLink> konumuna taşındı",
  "components.logs.userMovedToTeam": "Kullanıcı, <link>{creatorName}</link> tarafından <teamLink>{team}</teamLink> departmanına taşındı",
  "components.logs.userRolloverDays": "Önceki yıldan Devredilen: {leaveTypeName} - {value} gün",
  "components.logs.userRolloverDisplay": "Önceki yıldan Devredilen: {leaveTypeName} - {value}",
  "components.logs.userRolloverExpired": "Önceki yıldan <b>{total}</b> <b>{leaveTypeName}</b> gün süresi doldu",
  "components.logs.userRolloverExpiredDays": "Önceki yıldan günlerin süresi doldu: <b>{leaveTypeName}</b> - {value} gün",
  "components.logs.userRolloverExpiredHours": "Önceki yıldan günlerin süresi doldu: <b>{leaveTypeName}</b> - {value} saat",
  "components.logs.userRolloverHours": "Önceki yıldan Devredilen: <b>{leaveTypeName}</b> - {value} saat",
  "components.logs.userSeniorityEntitlement": "Kullanıcıya Kıdem Hakkı yoluyla {totalDays} gün {leaveTypeName} verildi",
  "components.logs.userSeniorityEntitlementOverride": "Kullanıcının {leaveTypeName} için hak günleri, Kıdem Hakkı düzenlemeleri nedeniyle {totalDays} güne geçersiz kılındı",
  "components.logs.userUpdated": "Kullanıcı <name></name> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> <link>{creatorName}</link> tarafından",
  "components.logs.userUpdatedChangeStatusToAdmin": "rol Yönetici olarak değiştirildi",
  "components.logs.userUpdatedChangeStatusToUser": "rol Kullanıcı olarak değiştirildi",
  "components.logs.userUpdatedEndDate": "bitiş tarihi {endDate} olarak değiştirildi",
  "components.logs.userUpdatedName": "İsim <wrappedName>{name}</wrappedName> olarak değiştirildi",
  "components.logs.userUpdatedStartDate": "Başlangıç tarihi {startDate} olarak değiştirildi",
  "components.logs.userWorkWeek": "Kullanıcının çalışma haftası {workDays} olarak <link>{creatorName}</link> tarafından değiştirildi",
  "components.logs.userWorkWeekDefaultLocation": "Kullanıcının çalışma haftası <link>{creatorName}</link> tarafından konum varsayılanına sıfırlandı",
  "components.logs.visibilityAddonCreated": "{user} şirket için görünürlüğü sınırladı.<br></br>Etkin: {enabled}.<br></br> Görünürlük seviyesi: {visibilityLevel}.<br></br>",
  "components.logs.visibilityAddonUpdated": "{user} şirket için sınırlı görünürlüğü güncelledi.<br></br>Etkin: {enabled}.<br></br> Görünürlük seviyesi: {visibilityLevel}.<br></br>",
  "components.manageAssignLicenses.assignLicenses": "{numberOfToAssignLicenses} lisans ata",
  "components.manageAssignLicenses.avaliableToAssign": "Atanabilir: {avaliableLicenses}",
  "components.manageAssignLicenses.microsoftTeamsMessage": "Microsoft Teams Mesajı",
  "components.manageAssignLicenses.moreUserThenLicenses": "Mevcut olandan {users} lisans daha fazla atadınız",
  "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Toplu işlem seç",
  "components.manageAssignLicenses.placeholderSelectRole": "Rol seç",
  "components.manageAssignLicenses.roleTooltipInfo": "Vacation Tracker'da kullanıcı rolleri ata. Daha fazla bilgi için <helpDesk>Yardım Masası</helpDesk>'mızı ziyaret edin.",
  "components.manageAssignLicenses.sendWelcomeMessage": "Hoş geldiniz mesajı gönder",
  "components.manageAssignLicenses.totalLicenses": "Toplam lisans: {numberOflicenses}",
  "components.modal.confirm": "Onayla",
  "components.modal.verify": "Doğrula",
  "components.notificationDestination.label": "Gönder",
  "components.notificationDestination.tooltip": "Bildirimi birden fazla e-postaya gönderebileceğinizi, ancak aynı anda yalnızca bir kanala gönderebileceğinizi unutmayın.",
  "components.notificationForm.channel": "Kanal",
  "components.notificationForm.channelSlackInfo": "Bildirimler yalnızca parçası olduğunuz kanallara gönderilebilir.",
  "components.notificationForm.daily": "Günlük",
  "components.notificationForm.day": "Gün",
  "components.notificationForm.firstDayOfWeek": "Hafta Başlangıcı",
  "components.notificationForm.refreshTheList": "Listeyi yenile",
  "components.notificationForm.team": "Microsoft Teams ekibi",
  "components.notificationForm.time": "Zaman",
  "components.notificationForm.timezone": "Saat dilimi",
  "components.notificationForm.weekly": "Haftalık",
  "components.notificationSummaryInfo.notificationSummary": "Bildirim <to></to> <channelWrapper>{channelName} </channelWrapper> <and>ve</and> <addressesWrapper>{numberOfEmailAddress} e-posta adres<multiEmailAddress>i</multiEmailAddress></addressesWrapper>'ne {sendingDaysInfo} günü saat {time}'de gönderilecek. <danger>{noOfUsers} Kullanıcı<multiUsers></multiUsers></danger>'nın izin bilgilerini içerecek <link>(Kullanıcı<multiUsers>ları</multiUsers> Görüntüle)</link>",
  "components.onboarding.assignLicensesTitle": "<icon></icon> Lisansları Ata",
  "components.onboarding.installMSBotButton": "Botu yükle",
  "components.onboarding.installMSBotDescription": "Ekibinizin izinlerini Microsoft Teams'de kolayca yönetmek için Vacation Tracker Bot ve Sekmelerini yükleyin.",
  "components.onboarding.installMSBotTitle": "<icon></icon> Bot Kurulumunu Tamamla",
  "components.onboarding.inviteFriendsDescription": "Doğru ve zamanında izin yönetimi için ekibinizi dahil edin. (Bu adımı tamamlamak için en az 3 ekip üyesi ekleyin.)",
  "components.onboarding.inviteFriendsTitle": "<icon></icon> Ekip Üyelerinizi Davet Edin",
  "components.onboarding.joyride.admin.assignLeavePolicy.info1": "Daha önce belirttiğimiz gibi, İzin Türleri tüm Organizasyon için oluşturulur. İzin Politikaları Lokasyonlara bağlıdır. Her İzin Türü, her Lokasyon için özel bir İzin Politikası atanarak ayarlanabilir.",
  "components.onboarding.joyride.admin.assignLeavePolicy.info2": "Lokasyona eklemek istediğiniz İzin Türünü seçtikten sonra, İzin Politikasını atayabilirsiniz. Mevcut bir İzin Politikasından Ayarları kopyalama veya tamamen yeni değerlerle yeni bir İzin Politikası oluşturma seçeneğiniz var.",
  "components.onboarding.joyride.admin.assignLeavePolicy.title": "İzin Politikalarını Atayın",
  "components.onboarding.joyride.admin.createLocation.info1": "Farklı lokasyonlarda ekip üyeleriniz varsa, Vacation Tracker'da birden fazla Lokasyon oluşturma ve Kullanıcıları ilgili Lokasyona ekleme seçeneğiniz vardır.",
  "components.onboarding.joyride.admin.createLocation.info2": "Her Lokasyonun, belirli bir İzin Türüne atamanız gereken kendi İzin Politikaları vardır. Tatiller de Lokasyonlara bağlıdır.",
  "components.onboarding.joyride.admin.createLocation.title": "Bir Lokasyon Oluşturun",
  "components.onboarding.joyride.admin.createNotifications.info1": "Yukarıda belirtilen adımları tamamladığınıza göre, şimdi Bildirimleri oluşturma zamanı.",
  "components.onboarding.joyride.admin.createNotifications.title": "Bildirimler Oluşturun ve Düzenleyin",
  "components.onboarding.joyride.admin.createTeams.info1": "Gösterge Paneli, organizasyonunuz içindeki bugünkü ve planlanmış izinlerin genel bir görünümünü sunar. Yukarıda belirtilenleri tamamladıktan sonra, Departmanlar oluşturma zamanı.",
  "components.onboarding.joyride.admin.createTeams.info2": "Bir Departman oluştururken, Onaylayanları eklemeniz gerekecek. Onaylayanlar, Vacation Tracker'da ikinci en yüksek izin seviyesine sahiptir ve Onaylayıcı oldukları Departmandan Kullanıcılar tarafından gönderilen izin taleplerini onaylayabilir/reddedebilir.",
  "components.onboarding.joyride.admin.createTeams.title": "Departmanlar Oluşturun ve Onaylayanlar Atayın",
  "components.onboarding.joyride.admin.helpDesk.info": "Herhangi bir sorunuz varsa veya daha fazla bilgiye ihtiyacınız olursa, Yardım Masasımıza göz atın veya müşteri destek ekibimizle bir sohbet başlatın.",
  "components.onboarding.joyride.admin.helpDesk.title": "Yardım Masası",
  "components.onboarding.joyride.admin.importHolidays.info1": "Vacation Tracker'daki Tatiller, tüm Organizasyon için değil, her Lokasyon için ayrı ayrı ayarlanır.",
  "components.onboarding.joyride.admin.importHolidays.info2": "Her ülke için önceden ayarlanmış Tatiller listemizden Tatilleri İçe Aktarma ve bir Tatili manuel olarak içe aktarma seçeneğiniz vardır.",
  "components.onboarding.joyride.admin.importHolidays.title": "Tatilleri İçe Aktarın",
  "components.onboarding.joyride.admin.leaveTypesMenu.info1": "Vacation Tracker yolculuğunuza her zaman İzin Türleri oluşturarak başlamanızı öneriyoruz. Bu İzin Türlerini daha sonra şirketinizin çalışanlarının bulunduğu tüm lokasyonları oluştururken atayacaksınız.",
  "components.onboarding.joyride.admin.leaveTypesMenu.info2": "İzin Türlerini nasıl oluşturacağınız ve düzenleyeceğiniz hakkında adım adım rehber için <link>Yardım Masası</link>'nı ziyaret edin.",
  "components.onboarding.joyride.admin.leaveTypesMenu.title": "İzin Türleri Oluşturun",
  "components.onboarding.joyride.admin.newLeaveType.info1": "Varsayılan İzin Türüne (PTO) ek olarak, 24 tane daha İzin Türü oluşturabilirsiniz.",
  "components.onboarding.joyride.admin.start.title": "Vacation Tracker hakkında bilgi edinin! 🏖️",
  "components.onboarding.joyride.admin.start1": "Başlamanıza yardımcı olmak için, organizasyonunuzu hızlıca kurmanız için takip etmenizi önerdiğimiz birkaç kısa adım hazırladık.",
  "components.onboarding.joyride.admin.start2": "Yolda yardımımıza ihtiyacınız olursa, <link>hello@vacationtracker.io</link> adresine sadece bir e-posta uzaktayız",
  "components.onboarding.joyride.admin.teams.cta1": "Microsoft Teams botunu yükleyin",
  "components.onboarding.joyride.admin.teams.cta2": "Yardım Masamızda daha fazla bilgi edinin",
  "components.onboarding.joyride.admin.teams.info1": "Gösterge panelinde her şey hazır. Son adım, ekibinizin doğrudan Microsoft Teams'de istek yapabilmesi için Microsoft Teams botunu yüklemektir.",
  "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
  "components.onboarding.joyride.approver.dashboard.info1": "Bu sayfa sizin kontrol merkezinizdir. Size bekleyen izinlerin, bugün kimin izinde olduğunun ve organizasyonunuz için yaklaşan izinlerin genel bir görünümünü sunar.",
  "components.onboarding.joyride.approver.leaveRequests.info1": "Onaylayıcı olarak, Onaylayıcısı olduğunuz ekibin herhangi bir üyesi için \"İzin Ekle\" yapabilirsiniz. Ayrıca kendiniz için \"İzin Talep Et\" yapabilirsiniz, bu talep onaylanmak üzere Onaylayıcınıza gönderilecektir.",
  "components.onboarding.joyride.approver.notifications.info1": "Bu sayfa, organizasyonunuz genelinde gönderilen tüm Bildirimlerin genel bir görünümünü sunar.",
  "components.onboarding.joyride.approver.notifications.info2": "Onaylayıcısı olduğunuz Departmanların Kullanıcıları için Bildirimler oluşturabilir veya düzenleyebilirsiniz.",
  "components.onboarding.joyride.approver.notifications.info3": "Yalnızca sizin oluşturduğunuz Bildirimleri yönetebileceğinizi unutmayın.",
  "components.onboarding.joyride.approver.users.info1": "'Kullanıcılar' sayfası, Departmanınızdaki tüm aktif kullanıcıların tam bir genel görünümünü ve Kullanıcı Profillerine salt okunur erişim sağlar. Bekleyen talepleri 'Gösterge Paneli' sayfasında mevcuttur.",
  "components.onboarding.joyride.user.calendar.info1": "Bu sayfada, organizasyonunuzun geçmiş ve gelecekteki izinlerini takvim formatında görebilirsiniz.",
  "components.onboarding.joyride.user.dashboard.info1": "Gösterge Paneli, organizasyonunuz içindeki bugünkü ve planlanmış izinlerin genel bir görünümünü sunar.",
  "components.onboarding.joyride.user.holidays.info1": "Burada Lokasyonunuzdaki Tatilleri inceleyebilir, böylece hangi günlerin zaten izinli olduğunuzu bilirsiniz.",
  "components.onboarding.joyride.user.myProfile.info1": "Burada mevcut izin türlerinizi, kotalarınızı, çalışma günlerinizi ve izin geçmişinizi görebilirsiniz.",
  "components.onboarding.joyride.user.requestLeave.info1": "Vacation Tracker Gösterge Paneli üzerinden İzin talep etmek için, mevcut izin türlerinden birini seçin, zaman aralığını ve gerekirse nedenini ekleyin ve talebi gönderin. Gönderildikten sonra, onay için Onaylayıcıya gönderilecektir.",
  "components.onboarding.joyride.user.start1": "Başlamanıza yardımcı olmak için, organizasyonunuzu hızlı bir şekilde kurmak için izlemenizi önerdiğimiz beş adımlık bir liste hazırladık.",
  "components.onboarding.openNotificationsButton": "Bildirimleri aç",
  "components.onboarding.requestALeaveDescription": "İlk izin talebinizi 3 basit adımda gönderin.",
  "components.onboarding.requestALeaveTitle": "<icon></icon> İzin Talebi",
  "components.onboarding.setNotificationsDescription": "Ekibinizin izinleri hakkında bilgilendirilmek için ne sıklıkta bildirim almak istediğinizi seçin.",
  "components.onboarding.setNotificationsTitle": "<icon></icon> Bildirimleri Ayarla",
  "components.onboarding.skipOnboarding": "Başlangıcı atla",
  "components.onboarding.syncTheCalendarDescription": "Ekibinizin izinlerinin güncel olması için Vacation Tracker takvimini kendi takviminizle entegre edin.",
  "components.onboarding.syncTheCalendarTitle": "<icon></icon> Takvimleri Senkronize Et",
  "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Google Takvim ile senkronizasyon videosu",
  "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "iCal ile senkronizasyon videosu",
  "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "MS Windows ile senkronizasyon videosu",
  "components.onboarding.title": "Başlangıç kontrol listesi <progressBar>{progressStatus}</progressBar>",
  "components.paymentInformation.expiryDate": "Son Kullanma Tarihi",
  "components.paymentInformation.labelCreditCard": "Kredi kartı",
  "components.paymentInformation.title": "Ödeme bilgileri ",
  "components.platformSwitchModal.content": "{platform} platformuna geçmek için önce {platform} hesabınızla giriş yapmalısınız. Lütfen devam etmek için aşağıdaki düğmeye tıklayın. Giriş yaptıktan ve gerekli izinleri verdikten sonra, sizi kullanıcı eşleştirme sürecine yönlendireceğiz.",
  "components.platformSwitchModal.title": "{platform} platformuna geçiş",
  "components.pricePlanCard.accruals": "İzin Biriktirme",
  "components.pricePlanCard.APISupport": "API desteği",
  "components.pricePlanCard.calendarIntegrations": "Takvim Entegrasyonu",
  "components.pricePlanCard.contactUs": "Bize ulaşın",
  "components.pricePlanCard.coreDepartmentsAndLocations": "3 Lokasyon ve 10 Departman oluşturma",
  "components.pricePlanCard.currentPlan": "Mevcut Plan",
  "components.pricePlanCard.customContract": "Özel sözleşme",
  "components.pricePlanCard.customizeLeaveTypes": "İzin Türlerini Özelleştirme",
  "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Sınırsız Lokasyon, Departman ve Özel İzin Türleri",
  "components.pricePlanCard.customOnboardingAndSetup": "Özel başlangıç ve kurulum",
  "components.pricePlanCard.dedicatedSupport": "Özel Destek",
  "components.pricePlanCard.disableDowngradeToCore": "Core plana geçiş için {noOfActions} {noOfActions, plural, =1 {işlem} other {işlem}} gerekiyor",
  "components.pricePlanCard.disableDowngradeToCoreDescription": "Core plana geçmek için lütfen aşağıdaki düzenlemeleri yapın:",
  "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "Core plan en fazla 10 departmana izin verir. Lütfen ek departmanları kaldırın.",
  "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "Core plan en fazla 3 lokasyona izin verir. Lütfen ek lokasyonları kaldırın.",
  "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "Core planda, departmanlar yalnızca bir seviye derinlikte yuvalanabilir. Şu anda {total} alt departmanınız var. Core plana geçmek için lütfen bazı alt departmanları silin.",
  "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "Özel çalışma saatleri kullanılırken Core plana geçiş mümkün değildir. Lütfen özel çalışma saatlerini lokasyon ayarlarında varsayılan değere (8) sıfırlayın",
  "components.pricePlanCard.disableDowngradeToCoreTitle": "Plan düşürme. Düzenlemeler gerekli",
  "components.pricePlanCard.displayLeaveInformationInDaysHours": "İzin Bilgilerini Gün/Saat Olarak Göster",
  "components.pricePlanCard.downgradePlan": "Plan bir sonraki fatura döngüsünde düşürülecek",
  "components.pricePlanCard.duringTheTrial": "Deneme süresi boyunca herhangi bir plan değişikliği hemen uygulanacaktır",
  "components.pricePlanCard.everythingInTheCompletePlan": "Complete plandaki her şey",
  "components.pricePlanCard.EverythingInTheCorePlan": "Temel plandaki her şey",
  "components.pricePlanCard.exportsAndReports": "Dışa Aktarmalar ve Raporlar",
  "components.pricePlanCard.forSeats": "{users} koltuktan daha az için",
  "components.pricePlanCard.forUsers": "{users} kullanıcıdan daha az için",
  "components.pricePlanCard.hourlyLeaves": "İzinleri Saatlik Olarak Takip Et",
  "components.pricePlanCard.HRFields": "İK Alanları (yakında)",
  "components.pricePlanCard.labels": "Kullanıcıları Etiketlerle Düzenle",
  "components.pricePlanCard.managePTO": "PTO taleplerini yönet",
  "components.pricePlanCard.minimumPlanPrice": "minimum <price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>",
  "components.pricePlanCard.notifications": "PTO Bildirimleri Gönder",
  "components.pricePlanCard.pricePerSeat": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>koltuk başına/ay",
  "components.pricePlanCard.pricePerUser": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>kullanıcı başına/ay",
  "components.pricePlanCard.reports": "Raporlar Oluştur",
  "components.pricePlanCard.reportsSchedule": "Raporları Zamanla",
  "components.pricePlanCard.requestHourlyLeaveIncrements": "Saatlik İzin Artışları Talep Et",
  "components.pricePlanCard.scheduleAConsultation": "Bir Danışma Planla",
  "components.pricePlanCard.scheduledReport": "Zamanlanmış Raporlar",
  "components.pricePlanCard.selectPlan": "Plan Seç",
  "components.pricePlanCard.sharedCalendars": "Paylaşılan Takvimler",
  "components.pricePlanCard.SSOIntegration": "Kurumsal SSO Entegrasyonu (Okta, vb.)",
  "components.pricePlanCard.startFreeTrial": "14 Günlük Ücretsiz Denemenizi Başlatın",
  "components.pricePlanCard.substituteApprovers": "Yedek Onaylayanlar",
  "components.pricePlanCard.support": "Öncelikli Destek",
  "components.pricePlanCard.switchPlan": "<period> {newPeriod} Planına Geç</period>",
  "components.pricePlanCard.totalPrice": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/ay {totalUsers} aktif kullanıcı için",
  "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/ay 25'ten az kullanıcı için",
  "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/yıl",
  "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/yıl",
  "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/ay {totalUsers} koltuk için",
  "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Sınırsız Departman ve Lokasyon Oluştur",
  "components.pricePlanCard.upgradePlan": "Plan hemen etkinleştirilecek ve fiyat orantılı olarak hesaplanacak",
  "components.requestAutomation.anythingElse": "Bilmemiz gereken başka bir şey var mı?",
  "components.requestAutomation.automationExtraCharge": "Bu Eklenti ek bir ücretle gelse kullanır mıydınız?",
  "components.requestAutomation.automationFunctionalities": "Eklenti hangi işlevleri veya görevleri gerçekleştirmenizi sağlayacak?",
  "components.requestAutomation.automationShouldWeAdd": "Hangi Eklentiyi eklemeliyiz?",
  "components.requestAutomation.submiteRequest": "Talebi Gönder",
  "components.requestAutomation.suggestAnAutomation": "Bir Eklenti Öner",
  "components.resetQuotas.fiscalYear": "Muhasebe Yılı ({date})",
  "components.resetQuotas.userYear": "Kullanıcı İşe Başlama Tarihi",
  "components.selectChannelForm.addNewEmail": "Alıcı Ekle",
  "components.selectChannelForm.channel": "Kanal",
  "components.selectChannelForm.channelSlackInfo": "Bildirimler yalnızca üyesi olduğunuz kanallara gönderilebilir.",
  "components.selectChannelForm.email": "Alıcılar",
  "components.selectChannelForm.errorNotification": "Bildirim Hatası",
  "components.selectChannelForm.errorNotInTeams": "Erişim reddedildi. Bu kanalı kullanmak için bu departmanın üyesi olmanız gerekir.",
  "components.selectChannelForm.notFound": "Kuruluşunuz tek bir Microsoft Teams ekibiyle kaydoldu ve siz şu kimliğe sahip ekibin üyesi değilsiniz: {msTeamId}.\n\nMicrosoft Teams uygulamanızdan bu ekibe katılabilir ve tekrar deneyebilir veya kurulum türünüzü değiştirmek için desteğimizle iletişime geçebilirsiniz.",
  "components.selectChannelForm.notFoundTitle": "Seçilen Microsoft Teams ekibinin üyesi değilsiniz",
  "components.selectChannelForm.team": "Ekip",
  "components.selectChannelFormMicrosoft.channelPlaceholder": "Lütfen bir Kanal seçin",
  "components.selectChannelFormMicrosoft.errorNotInTeams": "Erişim reddedildi. Bu kanalı kullanmak için bu departmanın üyesi olmanız gerekir.",
  "components.selectChannelFormMicrosoft.notFound": "Kuruluşunuz tek bir Microsoft Teams ekibiyle kaydoldu ve siz şu kimliğe sahip ekibin üyesi değilsiniz: {msTeamId}.\n\nMicrosoft Teams uygulamanızdan bu ekibe katılabilir ve tekrar deneyebilir veya kurulum türünüzü değiştirmek için desteğimizle iletişime geçebilirsiniz.",
  "components.selectChannelFormMicrosoft.notFoundTitle": "Seçilen Microsoft Teams ekibinin üyesi değilsiniz",
  "components.selectChannelFormMicrosoft.teamPlaceholder": "Lütfen bir Ekip seçin",
  "components.selectChannelFormSlack.errorNotification": "Bildirim Hatası",
  "components.selectChannelFormSlack.missingSlackScopes": "Eksik Slack İzinleri",
  "components.selectChannelFormSlack.privateChannel": "(özel kanal)",
  "components.selectChannelFormSlack.slackBotIdIsRequred": "Slack hatası: Slack Bot Kimliği gerekli! Sayfayı yenilemeyi deneyin!",
  "components.selectChannelFormSlack.slackError": "Slack hatası",
  "components.selectChannelFormSlack.toProccedPleaseLogin": "Devam etmek için lütfen Slack hesabınıza giriş yapın",
  "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "{channelName} kanalının üyesi değilsiniz. Bu kanala bildirim göndermek için kanalın üyesi olmanız gerekir.",
  "components.seniorityEntitlement.addDaysFrom": "Gün ekle",
  "components.seniorityEntitlement.allFiledsAreRequired": "Tüm alanlar zorunludur",
  "components.seniorityEntitlement.confirmDescription": "Kıdem hakkını çalışanın başlangıç tarihinden itibaren hesaplamayı seçmek, hesaplama başlangıç yılından itibaren geriye dönük olarak kullanıcı kotalarını ayarlayacaktır.",
  "components.seniorityEntitlement.confirmTitle": "Otomasyon oluşturmayı onaylayın",
  "components.seniorityEntitlement.daysAfter": "gün sonra",
  "components.seniorityEntitlement.disableEditInfo": "Kıdem hakkı otomasyonunu düzenleyemezsiniz. Değişiklik yapmanız gerekiyorsa, bu otomasyonu silin ve yeni bir tane oluşturun. Sorularınız varsa veya yardıma ihtiyacınız olursa <link>bizimle iletişime geçin</link>.",
  "components.seniorityEntitlement.hoursAfter": "saat sonra",
  "components.seniorityEntitlement.noLocations": "Tüm kullanıcılara uygulanmış bir otomasyonunuz var, tüm kullanıcılar için birden fazla otomasyon oluşturamazsınız.",
  "components.seniorityEntitlement.notSortedYearInPeriodError": "Yıllar kronolojik olarak sıralanmalıdır.",
  "components.seniorityEntitlement.prorateForTheFirstYear": "Orantılı hesaplamayı etkinleştirmek istiyor musunuz?",
  "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "Kullanıcının işe başlama tarihi mali yılın başlangıç tarihiyle eşleşmiyorsa, kıdeme dayalı günleri verildiği yılda orantılı olarak hesaplayacağız. Örneğin, mali yılınız 1 Ocak'ta başlıyorsa, kullanıcı 5 yıl önce 1 Temmuz'da çalışmaya başladıysa ve şirketiniz 5 yıllık hizmetten sonra 5 Temmuz'da 5 ek PTO günü veriyorsa, kullanıcı orantılı hesaplama nedeniyle 1 Temmuz'da 2.5 gün alacaktır. Bu kullanıcı, bir sonraki mali yılda tüm 5 kıdeme dayalı günü alacaktır.",
  "components.seniorityEntitlement.sameYearError": "Aynı yıl sayısına birden fazla kural atayamazsınız.",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Kıdem hakkını uygulamaya başla",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "Kıdem hakkını çalışanın başlangıç tarihinden itibaren uygulamayı seçerseniz, bu değişiklik kullanıcının geçmişteki kotalarını etkileyecektir.",
  "components.seniorityEntitlement.summaryInfoPrefix": "Kıdem Otomasyonu, dönüm noktası bazlı bir yaklaşımla ek izin verir. Örneğin: ",
  "components.seniorityEntitlement.summaryInfoSuffix": ", toplamda {amount} <showDays>gün</showDays><showHour>saat</showHour> kıdem izni.",
  "components.seniorityEntitlement.summeryInfoFirstRow": "{year}. yıl dönümünde {amount} <showDays>gün</showDays><showHour>saat</showHour> eklenir",
  "components.seniorityEntitlement.summeryInfoMoreDays": ", {year}. yıl dönümünde {amount} <showDays>gün</showDays><showHour>saat</showHour> daha",
  "components.seniorityEntitlement.yearsInTheCOmpany": "şirketteki yıllar",
  "components.setupMsBot.followDirectLink": "1) Vacation Tracker uygulamasını Microsoft Teams mağazasında bulmak için <directLink>bu bağlantıya</directLink> tıklayın.",
  "components.setupMsBot.inMSAppPreviewWindows": "2) Uygulama önizleme penceresinde, \"Aç\" düğmesinin yanındaki oka tıklayın ve \"Bir ekibe ekle\" seçeneğini tıklayın",
  "components.setupMsBot.setUpInMS": "3) Vacation Tracker'ı kurmak istediğiniz ekibi ve kanalı seçmeniz istenecektir. Ayrıca, daha kolay ve hızlı erişim için Vacation Tracker'ı bir sekme olarak sabitleme seçeneği de sunulacaktır!",
  "components.setupMsBot.stepByStep": "Ayrıntılı adım adım talimatlar için bu videoyu izleyin veya <helpDesk>Yardım Masası</helpDesk>'mızı ziyaret edin.",
  "components.setupMsBot.title": "Microsoft Teams botunu ve sekmelerini kurun",
  "components.signupWithAnotherPlatform.connectWith": " {platform} ile kaydolun",
  "components.signupWithAnotherPlatform.info": "{platform} hesabınızla bağlantı kurmak ister misiniz? Bunu yaparak, {importUsersPlatform}'dan kullanıcıları içe aktarabilir ve senkronize edebilir, ayrıca izinleri doğrudan {platform} uygulamanızdan yönetebilirsiniz. Daha fazla bilgi için <helpDesk>Yardım Masası</helpDesk>'mızı ziyaret edin.",
  "components.signupWithAnotherPlatform.or": "Veya",
  "components.signupWithAnotherPlatform.title": "{platform} kullanıyor gibi görünüyorsunuz",
  "components.switchPlanModal.downgradeProbationPeriodWarning": "Deneme Süresi Eklentisi, Core plan için ücretli bir özelliktir. Eğer planı düşürür ve bu Eklentiden aboneliğinizi iptal etmezseniz, kullanıcı başına ayda 0,50$ ücretlendirilecektir.",
  "components.switchPlanModal.switch": "Geçiş yap",
  "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "<strong>Complete</strong> aylık plan hemen etkinleştirilecek ve fiyat mevcut fatura dönemi için orantılı olarak hesaplanacaktır.<legacyPlan> Lütfen eski plana geri dönemeyeceğinizi unutmayın.</legacyPlan>",
  "components.switchPlanModal.switchFromAnnuallyToMonthly": "<strong>{newPlan}</strong> aylık plan, mevcut yıllık fatura döneminin sonunda ({endDatePeriod}) etkinleştirilecek ve faturalandırılacaktır.<legacyPlan> Lütfen eski plana geri dönemeyeceğinizi unutmayın.</legacyPlan>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "<strong>Core</strong> yıllık plan, mevcut yıllık fatura döneminin sonunda ({endDatePeriod}) etkinleştirilecektir, o zamana kadar <strong>Complete</strong> planın özelliklerini kullanmaya devam edebilirsiniz",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "<strong>Core</strong> plan, mevcut yıllık fatura döneminin sonunda ({endDatePeriod}) etkinleştirilecektir, o zamana kadar <strong>Complete</strong> planın özelliklerini kullanmaya devam edebilirsiniz.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "<strong>Core</strong> yıllık plan, mevcut aylık fatura döneminin sonunda ({endDatePeriod}) etkinleştirilecektir, o zamana kadar <strong>Complete</strong> planın özelliklerini kullanmaya devam edebilirsiniz.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "<strong>Core</strong> aylık plan, mevcut fatura döneminin sonunda ({endDatePeriod}) etkinleştirilecektir, o zamana kadar <strong>Complete</strong> planın özelliklerini kullanmaya devam edebilirsiniz.",
  "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "<strong>Complete</strong> yıllık plan hemen etkinleştirilecek ve mevcut yıllık fatura döneminin sonuna kadar orantılı fiyat farkı için faturalandırılacaksınız.<legacyPlan> Lütfen eski plana geri dönemeyeceğinizi unutmayın.</legacyPlan>",
  "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "<strong>Complete</strong> yıllık plan hemen etkinleştirilecektir. Yıllık fiyatınız, mevcut aylık faturanızda kalan miktar kadar düşürülecektir.<legacyPlan> Lütfen eski plana geri dönemeyeceğinizi unutmayın.</legacyPlan>",
  "components.switchPlanModal.switchFromMonthlyToAnnually": "<strong>{newPlan}</strong> yıllık plan hemen etkinleştirilecektir. Yıllık fiyatınız, mevcut aylık faturanızda kalan miktar kadar düşürülecektir.<legacyPlan> Lütfen eski plana geri dönemeyeceğinizi unutmayın.</legacyPlan>",
  "components.switchPlanModal.title": "<strong>{plan}</strong> planına geçiş yap",
  "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Deneme süresinden aboneliği iptal et",
  "components.teamForm.approvers": "Onaylayıcı(lar)",
  "components.teamForm.generalSettings": "Genel Ayarlar",
  "components.teamForm.isDefault": "Bu Departmanı Varsayılan Yap",
  "components.teamForm.isDefaultExtra": "Bu Departmanı varsayılan yaparak, tüm yeni ekip üyeleri otomatik olarak bu Departmana eklenecektir",
  "components.teamForm.name": "İsim",
  "components.teamForm.pleaseEnterTeamName": "Departman adını girin",
  "components.teamForm.users": "Kullanıcılar",
  "components.toil.accrualsToilsTotal": "Toplam kazanılan {hours, plural, =1 {saat} other {gün}} (TOIL + tahakkuk): {total}",
  "components.toil.added": "TOIL Eklendi",
  "components.toil.addToil": "TOIL Ekle",
  "components.toil.addToilProgress": "TOIL Ekleniyor",
  "components.toil.anotherAdd": "Başka bir TOIL ekle",
  "components.toil.anotherRequest": "Başka bir TOIL talep et",
  "components.toil.approveInProgress": "TOIL talebi onaylanıyor",
  "components.toil.bot.message.editedToilRequest": "Merhaba, <@{userSlackId}> aşağıdaki TOIL talebini düzenledi:",
  "components.toil.bot.message.leaveTypeName": "*İzin türü:*\nTOIL - {leaveTypeName}",
  "components.toil.bot.message.requestToil": "TOIL Talep Et",
  "components.toil.bot.message.requestToilSummary": "Lütfen fazla mesai yaptığınız tarihleri belirterek ve ilgili açıklamaları ekleyerek Telafi İzni (TOIL) talebinizi başlatın. Telafi İzni talepleri yalnızca geçmiş tarihler için (bugün dahil) yapılabilir.",
  "components.toil.bot.message.submittedToilRequest": "Merhaba, <@{userSlackId}> aşağıdaki TOIL talebini gönderdi:",
  "components.toil.bot.notification.slack.toilRequestAdded": "Bir onaylayıcı tarafından sizin için bir TOIL izni oluşturuldu",
  "components.toil.bot.notification.slack.toilRequestAddedSelf": "Bir TOIL izni eklediniz",
  "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> talebi onayladı, başka bir işlem gerekmez.",
  "components.toil.bot.notification.slack.toilRequestApprovedTitle": "İzin Talebiniz Onaylandı",
  "components.toil.bot.notification.slack.toilRequestCreated": "Merhaba, <@{userSlackId}> bir TOIL talebi gönderdi.",
  "components.toil.bot.notification.slack.toilRequestEdited": "Merhaba, <@{userSlackId}> bir TOIL talebini düzenledi.",
  "components.toil.cancelInProgress": "TOIL talebi iptal ediliyor",
  "components.toil.deleteInProgress": "TOIL siliniyor",
  "components.toil.denied": "TOIL Talebiniz Reddedildi",
  "components.toil.deniedBy": "TOIL talebi {approverName} tarafından reddedildi.",
  "components.toil.denyInProgress": "TOIL talebi reddediliyor",
  "components.toil.durationLabel": "En Kısa Mesai Dışı İzin Süresi",
  "components.toil.durationLabelTooltip": "Lütfen TOIL talebine uygulanacak en kısa süreyi seçin. Bu seçim, bir kullanıcının kaydedebileceği minimum fazla mesai miktarını yansıtmalıdır.",
  "components.toil.earned": "TOIL kazanıldı",
  "components.toil.edit": "TOIL'i Güncelle",
  "components.toil.edited": "TOIL güncellendi",
  "components.toil.editRequestToil": "Mesai Dışı İzin Talebini Düzenle",
  "components.toil.editToilProgress": "Mesai Dışı İzin Düzenleniyor",
  "components.toil.enableLabel": "Mesai Dışı İzni Etkinleştir:",
  "components.toil.enableLabelTooltip": "TOIL, çalışanların fazla mesai yaparak ek ücret almak yerine eşdeğer sürede izin alarak telafi edildiği bir uygulamadır.",
  "components.toil.expiration": "Son Kullanma Tarihi",
  "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {ay} other {ay}}",
  "components.toil.expirationDurationCalendar": "Takvim yılının sonu",
  "components.toil.expirationLabel": "Mesai Dışı İzin günleri sona ermeli mi?",
  "components.toil.expirationLabelTooltip": "TOIL günlerinin, kullanılamayacakları bir son kullanma tarihi olmasını istiyorsanız bu ayarı etkinleştirin. 'Evet' seçeneği, sona erme kurallarını seçmenizi isteyecektir. 'Hayır' seçilirse, TOIL günleri sona ermeyecek ve kullanılana kadar mevcut olacaktır.",
  "components.toil.featurePlan": "TOIL özelliği yalnızca Complete planında mevcuttur.",
  "components.toil.fullDayLeavePeriod": "{date}{endDate} ",
  "components.toil.halfDay": "Yarım gün TOIL",
  "components.toil.hourly": "Saatlik TOIL",
  "components.toil.lapsed": "süresi dolmuş",
  "components.toil.locationNotHaveToil": "{me, plural, =1 {Sizin} other {Kullanıcının}} konumunda TOIL içeren bir izin politikası ayarlanmamış",
  "components.toil.notification.added": "TOIL <forUser>için </forUser>{requestor} <byActionCreator>{actionCreator} tarafından</byActionCreator> eklendi",
  "components.toil.notification.approved": "{requestor} TOIL <byActionCreator>{actionCreator} tarafından</byActionCreator> onaylandı ✅",
  "components.toil.notification.cancelled": "{requestor} TOIL <byActionCreator>{actionCreator} tarafından</byActionCreator> iptal edildi",
  "components.toil.notification.created": "TOIL <byActionCreator>{actionCreator} tarafından</byActionCreator> talep edildi",
  "components.toil.notification.deleted": "TOIL <byActionCreator>{actionCreator} tarafından</byActionCreator> silindi",
  "components.toil.notification.denied": "{requestor} TOIL <byActionCreator>{actionCreator} tarafından</byActionCreator> reddedildi ❌",
  "components.toil.notification.edited": "{requestor} TOIL <byActionCreator>{actionCreator} tarafından</byActionCreator> düzenlendi",
  "components.toil.notification.editedApproved": "Planlanmış bir TOIL <byActionCreator>{actionCreator} tarafından</byActionCreator> düzenlendi ✅",
  "components.toil.notification.editedDenied": "Düzenlenen TOIL <byActionCreator>{actionCreator} tarafından</byActionCreator> reddedildi",
  "components.toil.partDayPeriod": "{date} - {partDayStartHour}{hourFormat24, select, true {:00} other {}} ile {partDayEndHour}{hourFormat24, select, true {:00} other {}} arası",
  "components.toil.reasonDefault": "Yukarıda belirtilen zaman diliminde çalışılan ek saatler.",
  "components.toil.request": "TOIL talebi",
  "components.toil.requested": "TOIL talep edildi",
  "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Talep edilen} other {Eklenen}} {period}",
  "components.toil.requester": "Kim Mesai Karşılığı İzin Talep Edebilir?",
  "components.toil.requesterAdmin": "Sadece Yöneticiler ve Onaylayanlar",
  "components.toil.requesterAll": "Tüm Kullanıcılar",
  "components.toil.requesterTooltip": "Fazla mesai yaptıktan sonra her çalışanın kendi TOIL taleplerini göndermesine izin vermek için 'Tüm Kullanıcılar'ı seçin. TOIL yönetimini merkezileştirmek ve onay ve kayıt üzerinde daha sıkı kontrol sağlamak istiyorsanız 'Sadece Yöneticiler ve Onaylayanlar'ı seçin.",
  "components.toil.requestNotFound": "TOIL talebi bulunamadı",
  "components.toil.requestToil": "Mesai Karşılığı İzin Talep Et",
  "components.toil.requestToilProgress": "Mesai Karşılığı İzin Talep Ediliyor",
  "components.toil.submitError": "TOIL Gönderme Hatası",
  "components.toil.submitErrorDate": "Seçtiğiniz başlangıç tarihi bitiş tarihinden sonra olduğu için TOIL talebi gönderilemedi. Başlangıç tarihinin bitiş tarihinden önce olduğundan emin olun ve talebi tekrar gönderin",
  "components.toil.submitErrorDateFuture": "Talep edilen TOIL tarihi geçmişte olmalıdır",
  "components.toil.submitErrorLongerThanWorkday": "Talep edilen TOIL saatleri çalışma saatlerinden uzun",
  "components.toil.submitErrorNotOpen": "TOIL açık değil",
  "components.toil.submitErrorOverlap": "Talep edilen TOIL günleri mevcut TOIL ile çakışıyor",
  "components.toil.submitErrorPlan": "Bu özellik yalnızca Complete planımıza abone olan kullanıcılar tarafından erişilebilir.",
  "components.toil.submitErrorPolicy": "Bu izin türü için izin politikası TOIL'e izin vermiyor",
  "components.toil.submitErrorPolicyDuration": "Özel çalışma saatleri olan kullanıcıların çok günlü TOIL talep etmesine izin verilmez",
  "components.toil.submitErrorPolicyLocation": "TOIL seçeneği konumunuz için etkinleştirilmemiş. İzin politikaları sayfasından etkinleştirebilir veya destek için yöneticinize başvurabilirsiniz.",
  "components.toil.submitErrorReasonNotSet": "TOIL nedeni gereklidir",
  "components.toil.submitErrorRole": "TOIL talebine izin verilmiyor",
  "components.updateWorkweekForm.cancel": "İptal",
  "components.updateWorkweekForm.form.error": "Lütfen en az bir çalışma günü seçin",
  "components.updateWorkweekForm.title": "Çalışma Haftasını Güncelle",
  "components.updateWorkweekForm.update": "Güncelle",
  "components.updateWorkweekForm.workWeek": "Çalışma Haftası",
  "components.userBroughtForwardForm.customRolloverDays": "Günler",
  "components.userBroughtForwardForm.leaveTypeEditInfo": "{dateRangeName} dönemi için \"{leaveTypeName}\" izin türünü düzenliyorsunuz.",
  "components.userBroughtForwardForm.update": "Güncelle",
  "components.userBroughtForwardForm.updateUserLeaveType": "{name} izin türü için devredilen günleri güncelle",
  "components.userLabes.addLabel": "Etiket Ekle",
  "components.userLabes.manageLabels": "Etiketleri Yönet",
  "components.userLabes.newLabel": "(yeni etiket)",
  "components.userLabes.noLabelsFound": "Etiket Bulunamadı",
  "components.userLeaveQuotas.accrualsTooltipTitle": "Tahakkuk, yıl boyunca ücretli izin saatlerinizin birikimi veya kademeli artışıdır.",
  "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "İki haftada bir {leaveType} tahakkuku her iki haftada bir gerçekleşir",
  "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "Aylık {leaveType} tahakkuku ayda bir kez gerçekleşir.",
  "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "Yarı aylık {leaveType} tahakkuku ayda iki kez gerçekleşir.",
  "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "Haftalık {leaveType} tahakkuku her hafta gerçekleşir",
  "components.userLeaveQuotas.accruedDays": "Tahakkuk Eden Günler",
  "components.userLeaveQuotas.accruedHours": "Tahakkuk Eden Saatler",
  "components.userLeaveQuotas.accruedInfo1": "{userName}, {defaultDays} gün ({defaultDaysInHours} saat) üzerinden {earnedDays} gün ({earnedDaysInHours} saat) tahakkuk etti",
  "components.userLeaveQuotas.accruedInfo1Days": "{userName}, {defaultDays} günden {earnedDays} gün tahakkuk etti",
  "components.userLeaveQuotas.accruedInfo1Hours": "{userName}, {defaultDaysInHours} saatten {earnedDaysInHours} saat tahakkuk etti",
  "components.userLeaveQuotas.accruedInfo2": "{userName}, {earnDate} tarihinde {toAccrueDays} gün ({toAccrueHours} saat) tahakkuk edecek",
  "components.userLeaveQuotas.accruedInfo2Days": "{userName}, {earnDate} tarihinde {toAccrueDays} gün tahakkuk edecek",
  "components.userLeaveQuotas.accruedInfo2Hours": "{userName}, {earnDate} tarihinde {toAccrueHours} saat tahakkuk edecek",
  "components.userLeaveQuotas.baseQuota": "Temel kota",
  "components.userLeaveQuotas.broughtForward": "Devreden",
  "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>Maksimum {maxRolloverDays} gün<br></br></rolloverDays><expiresDate>{date} tarihinde sona erer</expiresDate>",
  "components.userLeaveQuotas.broughtForwardInfo": "Devreden, önceki dönemden aktarılan kullanılmamış gün sayısını temsil eder.",
  "components.userLeaveQuotas.broughtForwardInfoDetails": "{locationName} konumunda {leaveTypeName} için çalışanlar en fazla {broughtForwardLimit} kullanılmamış {broughtForwardLimit, plural, =1 {gün} other {gün}} devredebilir.",
  "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "{locationName} konumunda {leaveTypeName} için tüm kullanılmamış günler bir sonraki döneme devredilebilir.",
  "components.userLeaveQuotas.broughtForwardValue": "Devreden",
  "components.userLeaveQuotas.editInitialQuotaTitle": "{leaveTypeName} için Başlangıç Devreden Günleri Düzenle",
  "components.userLeaveQuotas.editInitialQuotaTooltip": "Başlangıç Devreden, çalışanların önceki takip veya İK sisteminden başlangıç bakiyelerini manuel olarak girmenizi sağlar",
  "components.userLeaveQuotas.editQuotaButton": "Değiştir",
  "components.userLeaveQuotas.expirationTooltip": "Çalışanın önceki dönemden devreden ancak son kullanma tarihinden önce kullanılmayan günler",
  "components.userLeaveQuotas.expiredDateInfo": "{date} tarihinde",
  "components.userLeaveQuotas.forMoreDetails": "Daha fazla detay için bkz.",
  "components.userLeaveQuotas.initialBroughtForward": "Başlangıç Devreden",
  "components.userLeaveQuotas.initialBroughtForwardInfo": "Başlangıç Devreden, Vacation Tracker'ın organizasyonunuzun bir parçası olmadığı geçen yıldan bir Kullanıcının aktardığı (devreden) izin bakiyesidir. Yalnızca Başlangıç Devreden izin bakiyesini düzenleyebileceğinizi, sonraki yıllarda devredebileceğiniz mevcut izni ise Vacation Tracker'ın hesaplayacağını unutmayın.",
  "components.userLeaveQuotas.leavePoliciesNotExistInfo": "Şu anda mevcut İzin Türü bulunmamaktadır. Lütfen bu kullanıcının ait olduğu Konuma İzin Politikaları atayın.",
  "components.userLeaveQuotas.leaveTypes": "İzin Türleri",
  "components.userLeaveQuotas.logsTab": "loglar sekmesi.",
  "components.userLeaveQuotas.noLimit": "Sınırsız kotanız var.",
  "components.userLeaveQuotas.ofDefaultDays": "{value} günden",
  "components.userLeaveQuotas.ofDefaultHours": "{value} saatten",
  "components.userLeaveQuotas.overlapDaysContactSupport": "Tarih çakışması, daha fazla bilgi için lütfen Destek ile iletişime geçin.",
  "components.userLeaveQuotas.paidDays": "Ödenen günler",
  "components.userLeaveQuotas.quota": "Kota",
  "components.userLeaveQuotas.quotaInfo": "Kota, mevcut dönem için kullanılabilir Ücretli İzin günlerinin sayısını temsil eder. Bu sayı, temel kotayı, mesai karşılığı izni ve çalışanın rolüne veya kıdemine göre verilen günleri içerir.",
  "components.userLeaveQuotas.quotaInfoSummary": "Mevcut dönem için kota:",
  "components.userLeaveQuotas.remaining": "Kalan",
  "components.userLeaveQuotas.remainingAfterRequest": "Talepten sonra kalan",
  "components.userLeaveQuotas.remainingTooltip": "Mevcut dönem için kalan {leaveTypeName} günleri. Toplam:",
  "components.userLeaveQuotas.remainingTooltipFooter": "En fazla {broughtForwardLimit} kullanılmayan {period} bir sonraki döneme aktarılacak",
  "components.userLeaveQuotas.remainingTooltipFooterAll": "Tüm kullanılmayan günler bir sonraki döneme aktarılabilir.",
  "components.userLeaveQuotas.remainingTooltipFooterZero": "Kullanılmayan günler bir sonraki döneme aktarılamaz.",
  "components.userLeaveQuotas.requested": "Talep Edilen",
  "components.userLeaveQuotas.saveQuotaButton": "Kaydet",
  "components.userLeaveQuotas.scheduled": "Planlanmış",
  "components.userLeaveQuotas.scheduledDays": "Planlanmış günler",
  "components.userLeaveQuotas.sectionTitle": "İzin kotaları",
  "components.userLeaveQuotas.selectPeriod": "Dönem Seç:",
  "components.userLeaveQuotas.unlock": "Kilidi Aç",
  "components.userLeaveQuotas.unlockBroughtForwardInfo": "Kilidi açmak için, Ayarlar → Konumlar → {locationName} → İzin Politikalarını Görüntüle'ye gidin ve {leaveTypeName} politikasını düzenleyerek Aktarılan İzinlere izin verin.",
  "components.userLeaveQuotas.used": "Kullanılan",
  "components.userLeaveQuotas.usedAlready": "Zaten kullanılan",
  "components.userLeaveQuotas.usedDays": "Kullanılan günler",
  "components.userLeaveQuotas.usedTooltip": "Mevcut dönem için kullanılan, planlanmış ve ödenmiş {leaveTypeName} günleri. Toplam:",
  "components.userLeaveQuotas.userInactiveInfo": "Hesap şu anda pasif olduğundan, yeniden aktifleştirilene kadar başka gün eklenmeyecektir.",
  "components.userLeaveQuotasCompact.title": "İzin bakiyeniz",
  "components.userLeaveQuotasCompact.titleForApprover": "İzin bakiyesi",
  "components.userLeaveTypeForm.applyForFutureYears": "Gelecek yıllar için temel izin kotasını uygulamak istiyor musunuz?",
  "components.userLeaveTypeForm.cancel": "İptal",
  "components.userLeaveTypeForm.daysPerYear": "Yeni izin kotanız nedir?",
  "components.userLeaveTypeForm.hasUnlimitedDays": "Sınırsız İzin vermek istiyor musunuz?",
  "components.userLeaveTypeForm.leaveTypeEditInfo": "{dateRangeName} dönemi için \"{leaveTypeName}\" izin türünü düzenliyorsunuz.",
  "components.userLeaveTypeForm.prorateInfo": "{user}, ilk yıl için ({yearPeriod}) {proratedQuota} ({preciseQuota} gün, tam olarak) ve {startOfNextYear} tarihinden itibaren tüm yıllar için {fullQuota} izin hakkına sahip olacak.",
  "components.userLeaveTypeForm.prorateInfoDays": "{user}, ilk yıl için ({yearPeriod}) {proratedQuota} ({preciseQuota} gün, tam olarak) ve {startOfNextYear} tarihinden itibaren tüm yıllar için {fullQuota} gün izin hakkına sahip olacak.",
  "components.userLeaveTypeForm.prorateInfoHours": "{user}, ilk yıl için ({yearPeriod}) {proratedQuota} ({preciseQuota} saat, tam olarak) ve {startOfNextYear} tarihinden itibaren tüm yıllar için {fullQuota} saat izin hakkına sahip olacak.",
  "components.userLeaveTypeForm.prorateLabel": "Kullanıcının başlangıç tarihine ({startDate}) göre ilk yıl için orantılı hesapla",
  "components.userLeaveTypeForm.quotaAdditionalRole": "Role dayalı ek günleri ayarla",
  "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Çalışanlara iş rollerine bağlı olarak ek izin günleri tahsis edin. Bu alan, kuruluş içindeki farklı pozisyonların sorumlulukları veya özel gereksinimlerini yansıtacak şekilde izin haklarını özelleştirmenizi sağlar.",
  "components.userLeaveTypeForm.quotaAdditionalSeniority": "Kıdeme dayalı ek günleri ayarla",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "Kıdeme dayalı günler, Kıdem Hak Edişi otomasyonu tarafından eklenir. Bu alanda değeri ayarlayarak kıdeme dayalı {leaveTypeName} günlerini geçersiz kılabilirsiniz.",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "Kıdeme dayalı günler, Kıdem Hak Edişi otomasyonu tarafından eklenir. Bu alanda değeri ayarlayarak kıdeme dayalı {leaveTypeName} günlerini geçersiz kılabilirsiniz. Ancak, günler verilmeden önce gelecek yıllar için bu değeri düzenleyemeyeceğiniz için bu alan kilitlidir.",
  "components.userLeaveTypeForm.quotaAdditionalSummary": "{user} için seçilen dönemde {leaveTypeName} izin kotasını {total} {hourlyLeaveAccounting, plural, =1 {saat} other {gün}} olarak ayarlayacaksınız. Temel kota ve kıdem hak edişi ile role dayalı ek günler (toplam {total} {hourlyLeaveAccounting, plural, =1 {saat} other {gün}}) {future, plural, =1 {seçilen dönem ve gelecek dönemler} other {seçilen dönem}} için uygulanacaktır. {prorateText}",
  "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "{user} için seçilen dönemde {leaveTypeName} izin kotasını Sınırsız olarak ayarlayacaksınız.",
  "components.userLeaveTypeForm.quotaBase": "Temel izin kotasını ayarla",
  "components.userLeaveTypeForm.quotaBaseTooltip": "Temel kota, kullanıcının kıdem, rol, TOIL veya diğer ekstra günlere dayalı olarak aldığı ek günleri hariç tutan varsayılan kotasıdır. İzin politikası sayfasında bir konumdaki herkes için temel kotayı değiştirebilir veya seçili bir kullanıcı için özel bir kota ayarlamak üzere bu alanı kullanabilirsiniz.",
  "components.userLeaveTypeForm.required": "Bu alan zorunludur.",
  "components.userLeaveTypeForm.update": "Güncelle",
  "components.userLeaveTypeForm.updateUserLeaveType": "{name} izin türü için kullanıcının kotalarını güncelle",
  "components.userLogsTab.ACCRUED_DAYS": "Tahakkuk eden günler",
  "components.userLogsTab.ALL": "Tümü",
  "components.userLogsTab.APPROVER_STATUS": "Rol değişikliği",
  "components.userLogsTab.filter": "Filtre:",
  "components.userLogsTab.LOCATION_USER_MOVED": "Konum değişikliği",
  "components.userLogsTab.logsNotFound": "Seçilen tarih aralığı için kayıt bulunamadı.",
  "components.userLogsTab.ROLLOVER_EXPIRED": "Devredilen günler sona erdi",
  "components.userLogsTab.selectFromTo": "Tarih aralığı seçin:",
  "components.userLogsTab.TEAM_USER_MOVED": "Departman değişikliği",
  "components.userLogsTab.TOIL_REQUEST_APPROVED": "TOIL",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Role göre hak",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Role göre hak silindi",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Role göre hak geçersiz kılındı",
  "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "İzin kotası varsayılana sıfırlandı",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Başlangıç devredilen silindi",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Başlangıç devredilen güncellendi",
  "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "İzin kotası güncellendi",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Kıdem hakkı",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Kıdem hakkı geçersiz kılındı",
  "components.userLogsTab.USER_STATUS_CHANGED": "Durum değişikliği",
  "components.userLogsTab.USER_UPDATED": "Kullanıcı bilgileri güncellendi",
  "components.userLogsTab.USER_WORK_WEEK_DELETED": "Özel çalışma haftası silindi",
  "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Çalışma haftası güncellendi",
  "components.userLogsTab.YEAR_START": "Yılın başlangıcı",
  "components.userManageLabels.actions": "İşlemler",
  "components.userManageLabels.color": "Renk",
  "components.userManageLabels.deleteLabel": "Bu etiketi silmek istediğinizden emin misiniz?\n\nBu işlem, etiketi atanmış tüm kullanıcılar için silecektir.",
  "components.userManageLabels.findLabel": "Etiket Bul",
  "components.userManageLabels.isRequired": "{title} gereklidir",
  "components.userManageLabels.manageLabels": "Etiketleri Yönet",
  "components.userManageLabels.name": "İsim",
  "components.userManageLabels.userCount": "Kullanıcı Sayısı",
  "components.userProfileTab.admin": "Yönetici",
  "components.userProfileTab.approver": "Onaylayıcı",
  "components.userProfileTab.approvers": "Onaylayıcılar:",
  "components.userProfileTab.approverSuffix": " (Onaylayıcı)",
  "components.userProfileTab.customWorkWeekEditedBefore": "{userName}'in çalışma haftası Vacation Tracker'ın önceki sürümünde değiştirildi (1 Ocak 2021'den önce)",
  "components.userProfileTab.customWorkWeekLabel": "özel çalışma haftası",
  "components.userProfileTab.customWorkWeekTooltip": "{userName}'in çalışma haftası {adminName} tarafından {timestamp} tarihinde düzenlendi",
  "components.userProfileTab.employeeIdNotSet": "Ayarlanmadı",
  "components.userProfileTab.employeeIdTooltip": "Harici bir sistemdeki çalışan kimliği",
  "components.userProfileTab.employeeSince": "Çalışma başlangıcı:",
  "components.userProfileTab.endDate": "Bitiş Tarihi:",
  "components.userProfileTab.location": "Konum:",
  "components.userProfileTab.name": "Ad:",
  "components.userProfileTab.nameEdited": "İsim manuel olarak değiştirildi ve artık otomatik olarak senkronize edilmeyecek. Bu isim sadece Vacation Tracker içinde kullanılır.",
  "components.userProfileTab.role": "Rol:",
  "components.userProfileTab.status": "Durum:",
  "components.userProfileTab.team": "Departman:",
  "components.userProfileTab.user": "Kullanıcı",
  "components.userProfileTab.userProfile": "Kullanıcı Profili",
  "components.userProfileTab.workingDays": "Çalışma Günleri",
  "components.userTodayOff.cancelLeave": "İzni iptal et",
  "components.userTodayOff.editLeave": "İzni düzenle",
  "components.userTodayOff.leaveInfo": "İzin bilgisi",
  "components.userTodayOff.offFromTo": "Kullanıcı {startDate} tarihinden {endDate} tarihine kadar {leaveTypeName} izni almıştır",
  "components.userTodayOff.offTodayFullDay": "Kullanıcı bugün, {today} tarihinde {leaveTypeName} izni almıştır",
  "components.userTodayOff.offTodayPartDay": "Kullanıcı bugün {from}:00 ile {to}:00 saatleri arasında {leaveTypeName} izni almıştır.",
  "components.userTodayOff.offTodayReason": "Sebep: {reason}",
  "components.userUpdateForm.active": "Aktif",
  "components.userUpdateForm.admin": "Yönetici",
  "components.userUpdateForm.approver": "Onaylayıcı",
  "components.userUpdateForm.approverSuffix": " (Onaylayıcı)",
  "components.userUpdateForm.cancel": "İptal",
  "components.userUpdateForm.employeeSince": "Çalışma Başlangıcı",
  "components.userUpdateForm.location": "Konum",
  "components.userUpdateForm.name": "Ad",
  "components.userUpdateForm.pleaseSelectALocation": "Bir konum seçin",
  "components.userUpdateForm.pleaseSelectATeam": "Bir departman seçin",
  "components.userUpdateForm.pleaseSelectAUserRole": "Bir Kullanıcı Rolü seçin",
  "components.userUpdateForm.pleaseSelectEmployeeSince": "Çalışma Başlangıç Tarihi Ekleyin",
  "components.userUpdateForm.pleaseSelectUserEndDate": "Kullanıcı Bitiş Tarihi Ekleyin",
  "components.userUpdateForm.role": "Rol",
  "components.userUpdateForm.team": "Departman",
  "components.userUpdateForm.update": "Güncelle",
  "components.userUpdateForm.updateUser": "Kullanıcıyı Güncelle",
  "components.userUpdateForm.user": "Kullanıcı",
  "components.userUpdateForm.userEndDate": "Çalışan Bitiş Tarihi",
  "components.userUpdateForm.warningInitialRolloverForChangeLocation": "Kullanıcının konumunu değiştirmek, kullanıcının yapılandırmasını ve izin bilgilerini etkileyebilir (örn. başlangıçtaki devredilen günler).",
  "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "Kullanıcının başlangıç tarihini değiştirmek, kullanıcının izin bilgilerini etkileyebilir (örn. Başlangıçta Devredilen Günler).",
  "components.whatsNew.newMessage": "Yeni mesaj",
  "components.whatsNew.whatsNew": "Yenilikler",
  "connect.alreadyHaveAnAccount": "Zaten bir hesabınız var mı?",
  "connect.alreadyHaveAnAccountWithLink": "Zaten bir hesabınız var mı? <b><link>Giriş yap</link></b>",
  "connect.backToLoginWithOtherPlatofrm": "Diğer platformla giriş yapmaya geri dön",
  "connect.companyExists.line1": "{companyName} zaten Vacation Tracker'a kayıtlı",
  "connect.companyExists.line2Other": "Doğru e-postayı mı kullanıyorsunuz?",
  "connect.companyExists.line2Slack": "Doğru kimlik bilgilerini mi kullanıyorsunuz?",
  "connect.companyExists.line3aSlack": "Slack Çalışma Alanınız: {workspaceName}",
  "connect.companyExists.line3Other": "E-posta adresiniz: {email}",
  "connect.companyExists.line3Slack": "E-posta adresiniz: {email}",
  "connect.companyExists.line4Other": "Bu e-posta doğruysa hesabınız aktif değil. Lütfen Vacation Tracker organizasyonunuzun yöneticisiyle iletişime geçin:",
  "connect.companyExists.line4Slack": "Bu kimlik bilgileri doğruysa hesabınız aktif değil. Lütfen Vacation Tracker organizasyonunuzun yöneticisiyle iletişime geçin:",
  "connect.companyExists.line5": "Aksi takdirde, lütfen farklı bir <capitalize>{platform}</capitalize> hesabıyla giriş yapın veya tarayıcınızın gizli penceresinden giriş yapın.",
  "connect.companyExists.line6Microsoft": "{companyName} içinde yeni bir departman oluşturmak için Destek ile iletişime geçin",
  "connect.companyExists.lineEmailAddress": "E-posta adresiniz",
  "connect.companyExists.title": "Şirket zaten mevcut",
  "connect.connect": "Bağlan",
  "connect.connectWithWorkEmail": "İş e-postası ile giriş yap",
  "connect.connectWithYourWorkEmail": "İş e-postanızı kullanarak giriş yapın",
  "connect.copyEmail": "E-postayı kopyalamak için tıklayın",
  "connect.copyTextEnd": "İyi tatiller! 🌴",
  "connect.createAnOrganization": "Bir organizasyon oluştur",
  "connect.description": "Vacation Tracker Paneline erişmek için, ekibinizin kullandığı platforma karşılık gelen \"Bağlan\" düğmesini seçin.",
  "connect.dontHaveAnAccount": "Hesabınız yok mu?",
  "connect.doYouWantToLoginInToOrganization": "{existingOrganizationName} organizasyonuna giriş yapmak istiyor musunuz?",
  "connect.forgotYourPassword": "Şifrenizi mi unuttunuz?",
  "connect.forgotYourPasswordWhatsYourEmail": "E-posta adresiniz nedir?",
  "connect.google.notWorkspaceUserDescription": "Kişisel bir hesapla kaydolmaya çalışıyorsunuz gibi görünüyor, Google Workspace hesabı değil. Şu anda, Vacation Tracker yalnızca Google Workspace hesaplarını destekliyor.",
  "connect.google.notWorkspaceUserTitle": "Google Workspace hesabı gerekli",
  "connect.google.sharedPermissionsConsent": "Aşağıdaki düğmeye tıklayarak, Vacation Tracker'a Google yönetici kullanıcılar dizininize erişim izni veriyorsunuz.",
  "connect.google.sharedPermissionsError": "Google izinlerini paylaşma başarısız oldu, bağlantınızın süresi doldu.",
  "connect.google.sharedPermissionsRequired": "Yetersiz izinler",
  "connect.google.sharedPermissionsRequiredDescription": "Kullanıcıları içe aktarmaya devam etmek için Google yöneticinizden izin almanız gerekecek. Lütfen gerekli izni vermek için bu bağlantıyı yöneticinizle paylaşın: ",
  "connect.google.sharedPermissionsSuccess": "Google izinleri başarıyla paylaşıldı!",
  "connect.google.sharedPermissionsSuccessRedirect": "Birkaç saniye içinde Vacation Tracker ana sayfasına yönlendirileceksiniz.",
  "connect.howShouldIConnect": "Nasıl bağlanmalıyım?",
  "connect.inputEmailPlaceholder": "İş e-postanızı girin",
  "connect.inputPasswordPlaceholder": "Şifrenizi girin",
  "connect.interactionRequiredMSErrorDescription1": "Microsoft hesabınızın ek izinler gerektirdiği görünüyor. Ek izinler vermek için lütfen aşağıdaki Giriş yap düğmesine tıklayın.",
  "connect.interactionRequiredMSErrorDescription2": "Bu sorun devam ederse, lütfen destek ekibimizle iletişime geçin ve onlara aşağıdaki kodu gönderin:",
  "connect.interactionRequiredMSErrorTitle": "Microsoft ek izinler gerektiriyor",
  "connect.login": "Giriş yap",
  "connect.meta.description": "Vacation Tracker Paneline erişin ve zahmetsiz izin yönetiminin tüm gücünü ortaya çıkarın.",
  "connect.meta.title": "Kayıt Ol/Giriş Yap - Vacation Tracker",
  "connect.meta.title.signin": "Giriş Yap - Vacation Tracker",
  "connect.meta.title.signup": "Kayıt Ol - Vacation Tracker",
  "connect.or": "Veya",
  "connect.platformErrorDescription1": "Aşağıdaki hata oluştu:",
  "connect.platformErrorDescription2": "Bu bir {platform} hatası olabilir. Ancak, daha fazla yardım için destek ekibimizle iletişime geçmekten çekinmeyin.",
  "connect.platformErrorTitle": "Bir şeyler yanlış gitti",
  "connect.pleaseInputValidEmail": "Lütfen geçerli bir e-posta adresi girin",
  "connect.pleaseInputValidPassword": "Lütfen geçerli bir şifre girin",
  "connect.pleaseLogIn": "Lütfen giriş yapın",
  "connect.readMore": "Daha fazla bilgi için <helpdesk>Yardım Merkezimizi</helpdesk> ziyaret edin veya <website>web sitemize</website> gidin.",
  "connect.signInWithEmail": "E-posta ile giriş yap",
  "connect.signInWithGoogle": "Google ile giriş yap",
  "connect.signInWithGoogleToOrganization": "{existingOrganizationName} için Google Workspace'e giriş yap",
  "connect.signInWithGoogleVerification": "Google ile giriş yap",
  "connect.signInWithMS": "Microsoft Teams ile giriş yap",
  "connect.signInWithMSToOrganization": "{existingOrganizationName} için Microsoft Teams ile giriş yap",
  "connect.signInWithSlack": "Slack ile giriş yap",
  "connect.signInWithSlackInstallBot": "Vacation Tracker'ı Slack'e ekle",
  "connect.signInWithSlackToOrganization": "{existingOrganizationName} için Slack ile giriş yap",
  "connect.signUp": "İş e-postanızı kullanarak kayıt olun",
  "connect.signUpInfo": "Ücretsiz deneme için kayıt olun",
  "connect.signupNewOrganizationUsingNewPlatform": "{selectedPlatform} hesabınızı kullanarak yeni bir organizasyon olarak kayıt olun",
  "connect.signUpWithEmail": "E-posta ile kayıt ol",
  "connect.signUpWithGoogle": "Google ile kayıt ol",
  "connect.signUpWithMicrosoft": "Microsoft ile kayıt ol",
  "connect.signUpWithSlack": "Slack ile kayıt ol",
  "connect.slack.usersLoginRequiredButton": "Slack izinlerini ver",
  "connect.slack.usersLoginRequiredDescription1": "Slack bot tokeniniz süresi dolmuş veya artık geçerli değil. Yeni bir token etkinleştirene kadar Vacation Tracker botuna erişemeyecek veya Bildirimler alamayacaksınız.",
  "connect.slack.usersLoginRequiredDescription2": "Yeni bir Slack bot tokeni etkinleştirmek için lütfen Tamam'a tıklayın.",
  "connect.slack.usersLoginRequiredDescription3": "Bu adımı atlamak ve çevrimiçi Panele geçmek için Atla'ya tıklayın.",
  "connect.slack.usersLoginRequiredTitle": "Slack hesabınıza giriş yapmanız gerekiyor",
  "connect.slackPostInstallation1": "Vacation Tracker Slack uygulamasını başarıyla kurdunuz 🎉",
  "connect.slackPostInstallation2": "Uygulamayı kullanmaya başlamak için lütfen Vacation Tracker Paneline giriş yapın ve kurulumu tamamlayın.",
  "connect.slackPostInstallation3": "Size birkaç basit soru soracağız. Sadece birkaç dakikanızı alacak. Göz açıp kapayıncaya kadar biter (son tatiliniz gibi).",
  "connect.slackPostInstallationError.generic": "Hey, önce Vacation Tracker botunu kurmamız gerekiyor. Bir sonraki adımda Vacation Tracker Slack uygulaması izinlerini inceleyebilecek ve onlara izin verebileceksiniz.",
  "connect.slackPostInstallationError.invalidCode": "Yönlendirme kodu artık geçerli değil. Bu, kodun süresi dolduğunda, zaten kullanıldığında veya giriş işlemini tamamlamadan önce sayfayı yenilediğinizde olabilir. Ama endişelenmeyin, yeni bir tane alalım!",
  "connect.subscriptionExpired": "Oops, şirketinizin Vacation Tracker aboneliğinin süresi dolmuş gibi görünüyor! Lütfen şirketinizin aboneliğini yeniden etkinleştirmek için bir yöneticiyle iletişime geçin.",
  "connect.subscriptionExpiredTitle": "Abonelik Süresi Doldu",
  "connect.thisOrganizationUsePlatform": "Ancak, {existingOrganizationName} {existsOnAnotherPlatform} kullanıyor, bu nedenle hesabınıza erişmek için {existsOnAnotherPlatform} ile giriş yapmanız gerekecek",
  "connect.welcome": "Hoş geldiniz, {name}!",
  "connect.youAreAlreadyMember": "Zaten {existingOrganizationName} organizasyonunun bir üyesisiniz",
  "connectCalendar.connect": "Bağlan",
  "connectCalendar.disconnect": "Bağlantıyı kes",
  "connectCalendar.disconnectDescription": "Takvim bağlantısını kesmek istediğinizden emin misiniz? Vacation Tracker artık gelecekteki izinlerinizle güncellenmeyecek",
  "connectCalendar.disconnectError": "Takvim bağlantısını kesme hatası",
  "connectCalendar.disconnectErrorDescription": "Takviminizin Vacation Tracker ile bağlantısını kesmeye çalışırken bir hata oluştu. Lütfen tekrar denemek için Tamam'a tıklayın.",
  "connectCalendar.disconnectSuccess": "{calendar} takviminin bağlantısını başarıyla kestiniz.",
  "connectCalendar.disconnectTitle": "{calendarPlatform} Takvim Bağlantısını Kes",
  "connectCalendar.error": "Takvim bağlantı hatası",
  "connectCalendar.errorDescription": "Takviminizi Vacation Tracker ile bağlamaya çalışırken bir hata oluştu. Lütfen tekrar denemek için Tamam'a tıklayın.",
  "connectCalendar.errorReconnectDescription": "Takviminizi Vacation Tracker ile yeniden bağlamaya çalışırken bir hata oluştu. Lütfen tekrar denemek için Tamam'a tıklayın.",
  "connectCalendar.reconnect": "Yeniden bağlan",
  "connectCalendar.reconnectError": "Takvimi yeniden bağlama hatası",
  "connectCalendar.reconnectInfo": "İzninizin görünür olduğundan emin olmak için lütfen takviminize yeniden bağlanın.",
  "connectCalendar.reconnectNotification": "Takviminizle olan bağlantının süresi doldu. İzninizin takviminizde görünür olduğundan emin olmak için lütfen buraya tıklayarak yeniden bağlanın.",
  "connectCalendar.reconnectNotificationSharedCalendar": "Paylaşılan takvime olan bağlantınızın süresi doldu: {calendarName}. İzinlerin takviminizde görünür olduğundan emin olmak için lütfen buraya tıklayarak yeniden bağlanın.",
  "connectCalendar.reconnectSuccess": "{calendar} takvimini başarıyla yeniden bağladınız.",
  "connectCalendar.success": "{calendar} takvimini başarıyla bağladınız.",
  "connectedCalendar.eventDescription": "<link>Vacation Tracker</link> tarafından eklendi",
  "connectedCalendar.eventDescriptionShared": "Paylaşılan takvim etkinliği <link>Vacation Tracker</link> tarafından eklendi",
  "containers.google.topbar.adminProductTourInfo": "Yönetici olarak Vacation Tracker'da en yüksek izin seviyesine sahipsiniz ve Yönetici Kontrol Paneli aracılığıyla organizasyonun tüm ayarlarını özelleştirebilirsiniz.\n\nKontrol Paneli şunları yapmanıza olanak tanır: \n- İzin talep etme veya diğer departman üyeleri için İzin ekleme. \n- Kontrol Paneli Ana Sayfası ve Takvim aracılığıyla tam bir genel bakış elde etme. \n- Birden fazla departman oluşturma ve yapılandırma (Onaylayanlar Atama)  \n- Bildirimleri Ayarlama \n- Kullanıcıları Yönetme (Aktif, Pasif, Silinmiş).\n- Konumları Oluşturma ve Yönetme (İzin Türleri, Tatiller, Bildirimler)\n\nEğer sorularınız veya endişeleriniz cevaplanmamış kalırsa, istediğiniz zaman bize ulaşın. Müşterilerimizle konuşmak günümüzün en iyi parçasıdır: ",
  "containers.google.topbar.approverProductTourInfo": "Onaylayıcı olarak, Vacation Tracker'da ikinci en yüksek izin seviyesine sahipsiniz ve Onaylayıcı olarak atandığınız departmandan Kullanıcılar tarafından gönderilen izin taleplerini onaylayabilir veya reddedebilirsiniz.\n\nKontrol Paneli şunları yapmanıza olanak tanır:\n- İzin talep etme veya diğer departman üyeleri için İzin ekleme.\n- Kontrol Paneli Ana Sayfası ve Takvim aracılığıyla tam bir genel bakış elde etme. \n- Bildirimleri Ayarlama \n- Kullanıcı Profilinizi Görüntüleme.\n- Talepleri Yönetme (Talepleri Onaylama/Reddetme, Geçmişi Görüntüleme).\n\nEğer sorularınız veya endişeleriniz cevaplanmamış kalırsa, istediğiniz zaman bize ulaşın. Müşterilerimizle konuşmak günümüzün en iyi parçasıdır: ​​​​​​​​",
  "containers.google.topbar.userProductTourInfo": "Bir Vacation Tracker Kullanıcısı olarak, İzin Taleplerinizi Kontrol Paneli aracılığıyla gönderebilir ve yönetebilir, ayrıca organizasyonunuz içindeki planlanan izinlerin tam bir genel bakışına sahip olabilirsiniz.\n\nKontrol Paneli şunları yapmanıza olanak tanır:\n- İzin Talep Etme\n- Kontrol Paneli Ana Sayfası ve Takvim aracılığıyla tam bir genel bakış elde etme.\n- Kullanıcı Profilinizi Görüntüleme.\n\nEğer sorularınız veya endişeleriniz cevaplanmamış kalırsa, istediğiniz zaman bize ulaşın. Müşterilerimizle konuşmak günümüzün en iyi parçasıdır: ​​​​​​​​",
  "containers.topbar.adminProductTourInfo": "Yönetici olarak Vacation Tracker'da en yüksek izin seviyesine sahipsiniz ve Yönetici Kontrol Paneli aracılığıyla organizasyonun tüm ayarlarını özelleştirebilirsiniz.\n\nKontrol Paneli şunları yapmanıza olanak tanır: \n- İzin talep etme veya diğer departman üyeleri için İzin ekleme. \n- Kontrol Paneli Ana Sayfası ve Takvim aracılığıyla tam bir genel bakış elde etme. \n- Birden fazla departman oluşturma ve yapılandırma (Onaylayanlar Atama) \n- Bildirimleri Ayarlama  \n- Kullanıcıları Yönetme (Aktif, Pasif, Silinmiş).\n- Konumları Oluşturma ve Yönetme (İzin Türleri, Tatiller, Bildirimler)\n\nBot şunları yapmanıza olanak tanır: \n- İzin Talep Etme \n- İzin Taleplerini Onaylama/Reddetme\n- Günlük/Haftalık Bildirimler Alma\n\nEğer sorularınız veya endişeleriniz cevaplanmamış kalırsa, istediğiniz zaman bize ulaşın. Müşterilerimizle konuşmak günümüzün en iyi parçasıdır: ",
  "containers.topbar.adminProductTourTitle": "Yönetici",
  "containers.topbar.announcement": "Duyuru",
  "containers.topbar.approverProductTourInfo": "Onaylayıcı olarak, Vacation Tracker'da ikinci en yüksek izin seviyesine sahipsiniz ve Onaylayıcı olarak atandığınız departmandan Kullanıcılar tarafından gönderilen izin taleplerini onaylayabilir veya reddedebilirsiniz.\n\nKontrol Paneli şunları yapmanıza olanak tanır:\n- İzin talep etme veya diğer departman üyeleri için İzin ekleme.\n- Kontrol Paneli Ana Sayfası ve Takvim aracılığıyla tam bir genel bakış elde etme. \n- Bildirimleri Ayarlama \n- Kullanıcı Profilinizi Görüntüleme.\n- Talepleri Yönetme (Talepleri Onaylama/Reddetme, Geçmişi Görüntüleme).\n\nBot şunları yapmanıza olanak tanır:\n- İzin Talep Etme\n- İzin Taleplerini Onaylama/Reddetme\n- Günlük/Haftalık Bildirimler Alma\n\nEğer sorularınız veya endişeleriniz cevaplanmamış kalırsa, istediğiniz zaman bize ulaşın. Müşterilerimizle konuşmak günümüzün en iyi parçasıdır: ",
  "containers.topbar.approverProductTourTitle": "Onaylayıcı",
  "containers.topbar.giveUsFeedback": "Bize geri bildirim verin",
  "containers.topbar.help": "Yardım",
  "containers.topbar.logout": "Çıkış yap",
  "containers.topbar.profile.info": "Bu yeni menüden profilinize erişebilir, temayı değiştirebilir ve hızlı turu başlatabilirsiniz.",
  "containers.topbar.setUpTheBot": "Botu ayarla",
  "containers.topbar.userProductTourInfo": "Bir Vacation Tracker Kullanıcısı olarak, İzin Taleplerinizi Kontrol Paneli veya Bot aracılığıyla gönderebilir ve yönetebilir, ayrıca organizasyonunuz içindeki planlanan izinlerin tam bir genel bakışına sahip olabilirsiniz.\n\nKontrol Paneli şunları yapmanıza olanak tanır:\n- İzin Talep Etme\n- Kontrol Paneli Ana Sayfası ve Takvim aracılığıyla tam bir genel bakış elde etme.\n- Kullanıcı Profilinizi Görüntüleme.\n\nBot şunları yapmanıza olanak tanır:\n- İzin Talep Etme \n- Günlük/Haftalık Bildirimler Alma\n\nEğer sorularınız veya endişeleriniz cevaplanmamış kalırsa, istediğiniz zaman bize ulaşın. Müşterilerimizle konuşmak günümüzün en iyi parçasıdır: ",
  "containers.topbar.userProductTourTitle": "Kullanıcı",
  "containers.topbar.whatsNewUnread": "Yenilikler - {unread} okunmamış",
  "Conversation not found.": "Seçilen kanal silinmiş veya artık mevcut değil. Lütfen Bildirimleri almak için başka bir kanal seçin.",
  "createCompany.signupError": "Bir şeyler yanlış gitti",
  "createCompany.steps.back": "Geri",
  "createCompany.steps.cancel": "İptal",
  "createCompany.steps.companyForm": "Şirket Detayları",
  "createCompany.steps.companyForm.agreeTerms": "<aPP>Gizlilik Politikası</aPP>, <aTOS>Hizmet Şartları</aTOS> ve <aDPA>VKS</aDPA>'nı kabul ediyorum. Ayrıca kişisel verilerimin Gizlilik Politikası'nda belirtilen şartlara uygun olarak işlenmesini kabul ediyorum",
  "createCompany.steps.companyForm.agreeToTerms.error": "Devam etmek için Hizmet Şartlarımızı ve Gizlilik Politikamızı kabul etmelisiniz.",
  "createCompany.steps.companyForm.companyInfoSectionDescription": "Şirketiniz ve konumu hakkında bilgi verin. Bu bilgileri şirket hesabınızı ve ilk konumunuzu oluşturmak için kullanacağız. Daha sonra her zaman daha fazla konum ekleyebilirsiniz.",
  "createCompany.steps.companyForm.companyLeaveSection": "Varsayılan İzin Türü",
  "createCompany.steps.companyForm.companyName": "Şirket Adı",
  "createCompany.steps.companyForm.companyNameInput.validation.required": "Bu alan zorunludur.",
  "createCompany.steps.companyForm.contactEmail.validation.country": "Bu alan zorunludur.",
  "createCompany.steps.companyForm.contactEmail.validation.required": "Önemli ürün iletişimi için gereklidir.",
  "createCompany.steps.companyForm.contactEmail.validation.state": "Bu alan zorunludur.",
  "createCompany.steps.companyForm.contactEmail.validation.type": "Lütfen geçerli bir e-posta adresi girin.",
  "createCompany.steps.companyForm.contactEmailExtra": "Sağladığınız e-posta adresi yalnızca önemli ürün iletişimi için kullanılacaktır. Spam yok, söz veriyoruz.",
  "createCompany.steps.companyForm.country": "Ülke",
  "createCompany.steps.companyForm.defaultPtoQuota": "Ücretli İzin (PTO) Kotası",
  "createCompany.steps.companyForm.defaultPtoQuota.validation": "Lütfen varsayılan PTO kotanızı girin",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "Varsayılan PTO kotanız 366 günden fazla olamaz",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "Varsayılan PTO kotanız 0 günden az olamaz",
  "createCompany.steps.companyForm.defaultPtoQuotaExtra": "Bunu daha sonra Ayarlarınızda değiştirebilirsiniz.",
  "createCompany.steps.companyForm.email": "İletişim E-postası",
  "createCompany.steps.companyForm.location": "Konum bilgisi: ",
  "createCompany.steps.companyForm.state": "Eyalet",
  "createCompany.steps.companyForm.step1Title": "Şirket Detayları",
  "createCompany.steps.companyForm.step2Title": "Kullanıcıları Seç",
  "createCompany.steps.companyForm.step3Title": "Plan Seç",
  "createCompany.steps.companyForm.unlimitedLeavePolicy": "Sınırsız PTO",
  "createCompany.steps.companyForm.UserInfoSectionDescription": "Bize kim olduğunuzu söyleyin. Bu bilgileri kullanıcı profilinizi oluşturmak için kullanacağız. Varsayılan olarak, size yönetici ve onaylayıcı rolü atanacaktır. Daha sonra daha fazla kullanıcı ekleyebilir ve rollerini değiştirebilirsiniz.",
  "createCompany.steps.inviteUsers.next": "{noOfUsers} Kullanıcı{postfix} Davet Et",
  "createCompany.steps.inviteUsers.title": "Ekip üyelerinizi davet edin",
  "createCompany.steps.inviteUsers.title.info": "Organizasyonunuzun Vacation Tracker hesabına eklemek istediğiniz kullanıcıları davet edin. Daha sonra her zaman kontrol paneli üzerinden değişiklik yapabilirsiniz.",
  "createCompany.steps.next": "Devam",
  "createCompany.steps.selectPlan.noCC": "7 günlük deneme süresi için kredi kartı gerekmez",
  "createCompany.steps.selectPlan.title": "Hadi başlayalım!",
  "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "Deneme süresi boyunca istediğiniz zaman planları değiştirebilirsiniz",
  "createCompany.steps.selectUsers": "Kullanıcıları İçe Aktar",
  "createCompany.steps.selectUsers.deselectAll": "Tümünün Seçimini Kaldır",
  "createCompany.steps.selectUsers.doneButton": "{noOfUsers} Kullanıcı{postfix} İçe Aktar",
  "createCompany.steps.selectUsers.doneButtonTryItOut": "14 Günlük Ücretsiz Denemeyi Başlat",
  "createCompany.steps.selectUsers.loadingAll": "Tüm Kullanıcılar Yükleniyor",
  "createCompany.steps.selectUsers.searchUsers": "Nemo'yu bul...",
  "createCompany.steps.selectUsers.selectAll": "Tümünü Seç",
  "createCompany.steps.selectUsers.title": "Ekip üyelerinizi seçin",
  "createCompany.steps.selectUsers.title.info": "Organizasyonunuzun Vacation Tracker hesabına eklemek istediğiniz kullanıcıları seçin. Daha sonra kontrol paneli üzerinden her zaman değişiklik yapabilirsiniz. Seçtiğiniz kullanıcılar, siz duyuru yapana kadar bilgilendirilmeyecektir.",
  "dashboard.accountSettingsLanguageLabel": "Tercih ettiğiniz dili seçin",
  "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "Vacation Tracker, {email} e-postası için gelecekteki tüm ofis dışı mesajlarınızı ayarlayacaktır.\n Ancak, bu değişiklikten önce bir ofis dışı mesaj planladıysanız, hala eski e-posta için ayarlanacaktır.",
  "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "Gelecekteki ofis dışı mesajları için e-posta adresini değiştir",
  "dashboard.accountSettingsOutOfOfficeConnectedWith": "Bağlantılı",
  "dashboard.accountSettingsOutOfOfficeTitle": "Otomatik Ofis Dışı (OOO) Yanıtını Yapılandır",
  "dashboard.accountSettingsPageOOODescription": "Etkinleştirildiğinde, Vacation Tracker sizi planlanan izninizden iki gün önce bilgilendirecek ve otomatik bir Ofis Dışı (OOO) yanıt mesajı ayarlama fırsatı sunacaktır.",
  "dashboard.accountSettingsTimeDisplayLabel": "Tercih ettiğiniz zaman gösterim formatını seçin",
  "dashboard.accountSettingsUIModeLabel": "Karanlık Mod ve Aydınlık Mod",
  "dashboard.b2bSoftware": "Lütfen bunun bir B2B yazılımı olduğunu ve bu nedenle talep üzerine verilerinizi değiştiremeyeceğimizi veya silemeyeceğimizi unutmayın. Daha fazla bilgi için lütfen ilgili yöneticinizle iletişime geçin. Herhangi bir yardıma ihtiyacınız olursa, destek ekibimiz <mail>hello@vacationtracker.io</mail> adresinden ulaşılabilir durumdadır",
  "dashboard.days": "Gün(ler)",
  "dashboard.daysSmallCase": "gün(ler)",
  "dashboard.daysSmallCaseSingle": "g",
  "dashboard.googlePermissionInfo2": "- Google saat dilimi ayarlarınızı almak için Google Takvim Ayarlarınıza erişim gereklidir.",
  "dashboard.googlePermissionInfo3": "- OOO yanıtınızı ayarlamak ve ilgili tarih aralığını tanımlamak için Gmail Ayarlarınıza erişim gereklidir.",
  "dashboard.hour": "saat(ler)",
  "dashboard.hourSingle": "s",
  "dashboard.minuteSingle": "d",
  "dashboard.noLeaveRequest": "Bu izin talebi aktif değil.",
  "dashboard.noOneIsOffToday": "Bugün için planlanmış izin yok.",
  "dashboard.noOneIsTackingLeaves": "Şu anda planlanmış izin yok.",
  "dashboard.noOpenLeavesRequests": "Bekleyen talep yok.",
  "dashboard.noScheduledLeavesHolidays": "Planlanmış izin veya tatil yok",
  "dashboard.openLeavesRequests": "Bekleyen Talepler",
  "dashboard.outlookPermissionInfo2": "- Çevrimdışı erişim: Bu, Vacation Tracker'ın OOO yanıtınızı ayarlaması için gereklidir.",
  "dashboard.outlookPermissionInfo3": "- Outlook Posta Kutusu ayarları: Doğru zamanlamayı sağlamak için Outlook saat dilimi ayarlarınıza erişmemiz gerekiyor. Ayrıca, OOO mesajınızı ayarlamak ve istediğiniz tarih aralığını tanımlamak için izin gerekiyor.",
  "dashboard.outOfOffice": "OOO Mesajı Ayarla",
  "dashboard.outOfOffice.requestIntegrationButton": "Entegrasyon talep et",
  "dashboard.outOfOfficeActiveLabel": "Ofis Dışında",
  "dashboard.outOfOfficeGoogleMailButton": "Google e-postası",
  "dashboard.outOfOfficeGoogleSigninModalTitle": "Google Hesabı Girişi Gerekli",
  "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Microsoft Hesabı Girişi Gerekli",
  "dashboard.outOfOfficeNotActiveInfo": "Görünüşe göre hesabınızda henüz Otomatik Ofis Dışı E-posta Yanıtı özelliğini etkinleştirmemişsiniz. Bu araç, belirli izin türleri için otomatik e-postalar ayarlamanıza olanak tanır ve kişilerinizin ne zaman müsait olmadığınızı bilmelerini sağlar. Bu özelliği açmak ister misiniz?",
  "dashboard.outOfOfficeOtherEmail": "Farklı bir e-posta sağlayıcınız mı var?",
  "dashboard.outOfOfficeOtherEmailInfo": "Şu anda Gmail ve Outlook'u destekliyoruz. Ancak, yazılımımızı sürekli olarak geliştirmeye çalışıyoruz. Kullandığınız e-posta sağlayıcısı hakkındaki geri bildiriminiz, geliştirme önceliklerimizi belirlememize büyük ölçüde yardımcı olacaktır. Diğer sağlayıcılara desteğimizi genişletene kadar, e-posta hizmetiniz içinde Ofis Dışında e-posta yanıtınızı manuel olarak ayarlayabilirsiniz.",
  "dashboard.outOfOfficeOtherEmailPlaceholder": "Sağlayıcınızı girin, örn. ProtonMail, Yahoo! Mail...",
  "dashboard.outOfOfficeOutlookButton": "Outlook",
  "dashboard.outOfOfficePageSettingsTitle": "E-POSTA SAĞLAYICINIZI SEÇİN",
  "dashboard.outOfOfficePageTitle": "Ofis Dışında",
  "dashboard.outOfOfficeSet": "OOO Mesajını Ayarla",
  "dashboard.outOfOfficeSetError": "Ofis Dışında otomatik yanıtınızı ayarlarken bir hata oluştu. Lütfen tekrar deneyin.",
  "dashboard.outOfOfficeSetInfo": "Burası 'Ofis Dışında' e-posta yanıtınızı ayarlayabileceğiniz yerdir. Siz yokken, bu mesaj size e-posta yoluyla ulaşmaya çalışan kişilere otomatik olarak gönderilecektir. İleride bu mesajı değiştirmeye karar verirseniz, e-posta ayarlarınız üzerinden kolayca yapılabilir.",
  "dashboard.outOfOfficeSetInfoBot": "'Ofis Dışında' e-posta yanıtınız ayarlandı. Siz yokken, bu mesaj size e-posta yoluyla ulaşmaya çalışan kişilere otomatik olarak gönderilecektir. İleride bu mesajı değiştirmeye karar verirseniz, e-posta ayarlarınız üzerinden kolayca yapılabilir.",
  "dashboard.outOfOfficeSetSuccess": "Tebrikler — otomatik ofis dışında mesajınızı başarıyla ayarladınız. İzniniz başladığında aktif olacaktır.",
  "dashboard.peopleOffToday": "Bugünkü İzinler",
  "dashboard.permissionModalInfo": "Vacation Tracker'ın Ofis Dışında (OOO) yanıtınızı otomatik olarak ayarlaması için daha fazla izne ihtiyacımız var:",
  "dashboard.selectSnoozeLeaveTypesPlaceholder": "İzin türlerini seçin...",
  "dashboard.snoozedLeaveTypesLabel": "Şunun için devre dışı bırak: ",
  "dashboard.upcomingDaysOff": "Planlanmış İzinler ({total})",
  "dashboard.upcomingHolidays": "Yaklaşan tatiller",
  "departments.viewDepartment": "Departmanı Görüntüle",
  "disconnectCalendar.success": "Takvim bağlantısını başarıyla kestiniz.",
  "editLeave.editLeave": "İzni Düzenle",
  "editLeave.handleSubmitErrorTitle": "İzin Düzenleme Hatası",
  "editLeave.inProgress": "İzin düzenleniyor",
  "editLeave.requestLeave": "İzni Düzenle",
  "editLeave.title": "İzin düzenlendi",
  "email.notification.accruedDaysInfoForApprover": "ℹ️ Bu izin talebi, mevcut miktara değil, gelecekteki tahakkuk edecek izin günlerine dayanmaktadır. {name} bugün itibariyle {amount} gün(leri) kalmıştır. {earningDate} tarihinde {accrued} tahakkuk edecektir.",
  "email.notification.accruedDaysInfoForUser": "ℹ️ İzin talebiniz, mevcut miktara değil, gelecekteki tahakkuk edecek izin günlerine dayanmaktadır. Bugün itibariyle {amount} gün(leriniz) kalmıştır ve {earningDate} tarihinde {accrued} tahakkuk edecektir.",
  "email.notification.holiday.plural": "<strong>{locationNames}</strong> lokasyonları <strong>{holidayName}</strong> için {holidayPeriodText} süresince izinli olacak",
  "email.notification.holiday.singular": "<strong>{locationNames}</strong> lokasyonu <strong>{holidayName}</strong> için {holidayPeriodText} süresince izinli olacak",
  "email.notification.title": "İzin Bildirimi",
  "error.auth.companyExists.line1": "Hesabınız aktif değil. Vacation Tracker'ı kullanmak için lütfen yöneticiyle iletişime geçin ve hesabınızı aktifleştirmelerini isteyin.",
  "error.auth.companyExists.line2": "Yöneticiler:",
  "error.auth.companyExists.line3": "Veya, aynı şirkette bağımsız bir departman olarak Vacation Tracker'ı kullanmak istiyorsanız desteğimizle ({support}) iletişime geçin.",
  "error.auth.companyExists.title": "Organizasyonunuz zaten kayıtlı",
  "error.automations.automationSubmitError": "Otomasyon Gönderme Hatası",
  "error.automations.insufficientPrivileges.description": "Bu işlemi gerçekleştirmek için izniniz yok. Lütfen yöneticinizle iletişime geçin.",
  "error.automations.insufficientPrivileges.title": "İzin hatası",
  "error.blackOutPeriod.isntCreated": "Karartma dönemi oluşturulamadı",
  "error.blackOutPeriod.isntDeleted": "Karartma dönemi silinemedi",
  "error.blackOutPeriod.isntUpdated": "Karartma dönemi güncellenemedi",
  "error.blackOutPeriod.UserNotAdminOrApprover": "Kullanıcı yönetici veya onaylayıcı değil",
  "error.blackOutPeriod.UserNotFound": "Kullanıcı bulunamadı",
  "error.botTokenNotFound": "Bot token bulunamadı, lütfen kontrol panelinize giriş yapın ve tekrar deneyin.",
  "error.CannotDuplicateName": "Girdiğiniz isim zaten kullanılıyor. Lütfen farklı bir isim girin.",
  "error.channelNotFound": "Seçilen kanal silinmiş veya artık mevcut değil. Lütfen Bildirimler almak için başka bir kanal seçin.",
  "error.codeDeliveryFailureException": "Kod gönderimi başarısız",
  "error.codeDeliveryFailureExceptionMessage": "Doğrulama kodu gönderilemiyor. Lütfen geçerli bir e-posta adresi verdiğinizi doğrulayın. E-posta adresiniz geçerliyse, lütfen daha sonra tekrar deneyin.",
  "error.codeMismatchException": "Geçersiz doğrulama kodu",
  "error.codeMismatchExceptionMessage": "Geçersiz doğrulama kodu sağlandı. Lütfen tekrar deneyin veya e-postayı yeniden gönderin.",
  "error.companyExists": "Şirket zaten mevcut",
  "error.CustomWorkday": "Özel çalışma saatleri olmayan bir plana geçiş mümkün değil. Lütfen ayarlardan özel çalışma saatlerini kaldırın veya destek ile iletişime geçin.",
  "error.deletedLocation": "Seçilen Konum silinmiş veya artık mevcut değil. Lütfen Bildirimler almak için başka bir Konum(lar) seçin.",
  "error.deletedTeam": "Seçilen Departman silinmiş veya artık mevcut değil. Lütfen Bildirimler almak için başka bir Departman(lar) seçin.",
  "error.DepartmentWithChildrenDeleteForbidden": "Alt departmanları olan bir departmanı silemezsiniz. Lütfen önce alt departmanları silin.",
  "error.emailError": "Geçersiz e-posta",
  "error.emailError.description": "Lütfen geçerli bir e-posta adresi girin",
  "error.endTimeCantBeBeforeStartTime": "Seçtiğiniz başlangıç saati bitiş saatinden sonra görünüyor. Lütfen bitiş saatinden önce bir başlangıç saati seçin.",
  "error.entitlementByRole.isntCreated": "Role göre hak oluşturulamadı",
  "error.entitlementByRole.isntDeleted": "Role göre hak silinemedi",
  "error.entitlementByRole.isntUpdated": "Role göre hak güncellenemedi",
  "error.expiredCodeException": "Doğrulama kodu süresi doldu",
  "error.expiredCodeExceptionMessage": "Sağlanan doğrulama kodunun süresi doldu.",
  "error.fetchingConnectedCalendarInfo": "Bağlı takviminizi getirirken bir sorun oluştu. Lütfen tekrar deneyin. Bu sorun devam ederse, lütfen müşteri desteğimizle iletişime geçin",
  "error.fetchingUserOutOfOfficeSettings": "Ofis dışı ayarlarınızı getirirken bir sorun oluştu. Lütfen tekrar deneyin. Bu sorun devam ederse, lütfen müşteri desteğimizle iletişime geçin",
  "error.fieldIsRequired": "Bu alan gereklidir.",
  "error.firstEarningDateMissing": "İlk kazanç tarihi eksik",
  "error.generic": "Bir hata oluştu",
  "error.generic.description": "Lütfen aşağıdaki mesajla birlikte destek ekibiyle iletişime geçin:",
  "error.google.directoryApiDisabledP1": "Vacation Tracker'ın yeni Kullanıcıları içe aktarmak için Dizin verilerine erişmesi gerekiyor.",
  "error.google.directoryApiDisabledP2": "Google Workspace Yöneticiniz, destek makalesindeki adımları izleyerek dizin erişimine izin verebilir: ",
  "error.google.directoryApiDisabledP3": "Bu işlevi etkinleştirmeden de kaydolabilirsiniz. Ancak, Google Workspace'inizden kullanıcıları içe aktaramayacaksınız.",
  "error.google.directoryApiDisabledP4": "Ek yardıma ihtiyacınız varsa, hello@vacationtracker.io adresinden bize ulaşın",
  "error.google.directoryApiDisabledTitle": "Kişi Paylaşımı Kapalı",
  "error.google.directoryApiPermissionDeniedP1": "Vacation Tracker'ın Google Workspace dizininizdeki kullanıcıları listeleyebilmesi gerekiyor. Lütfen \"Google ile Oturum Aç\" düğmesine tıklayın ve kaydolabilmek için aşağıdaki resimde gösterildiği gibi onay kutusunu işaretleyin.",
  "error.google.directoryApiPermissionDeniedP2": "Yalnızca açıkça içe aktarmak için seçtiğiniz kullanıcılar için şu bilgileri saklayacağız: ad, profil resmi ve e-posta.",
  "error.google.directoryApiPermissionDeniedP3": "\"Google ile Oturum Aç\" düğmesine tıkladığınızda onay kutusunu görmüyorsanız, lütfen kayıt işlemine devam etmek için Google izinleri modalındaki \"Devam Et\" düğmesine tıklayın.",
  "error.google.directoryApiPermissionDeniedTitle": "Devam etmek için eksik izinleri verin",
  "error.google.loginRequiredTitle": "Google hesabınıza giriş yapmanız gerekiyor",
  "error.google.usersLoginRequiredDescription1": "Kullanıcıları yönetmek Google kapsamları gerektirir. Tamam düğmesine tıklarsanız, sizi Google giriş sayfasına yönlendireceğiz. Tarayıcı ayarlarınıza bağlı olarak, zaten giriş yapmış olabilir ve otomatik olarak geri yönlendirilmiş olabilirsiniz.",
  "error.google.usersLoginRequiredDescription2": "Google hesabınıza giriş yaptıktan sonra, kullanıcıları yönetebilmeniz için sizi bu sayfaya geri getireceğiz.",
  "error.goToRequestLeaveTab": "İzin İste Sekmesi geçiş hatası",
  "error.holidayDateInvalid": "{year} için geçersiz tatil tarihi {date}",
  "error.incorrectPassword": "Yanlış şifre",
  "error.incorrectPasswordMessage": "Girdiğiniz şifre yanlış veya süresi dolmuş. Lütfen tekrar deneyin (büyük harf kilidinin kapalı olduğundan emin olun). Şifrenizi mi unuttunuz?",
  "error.invalidFileType": "Geçersiz dosya türü",
  "error.invalidFileType.description": "Geçersiz bir dosya türü yüklediniz. Lütfen JPEG, PNG veya GIF resmi yükleyin.",
  "error.invalidPasswordException": "Geçersiz şifre",
  "error.invalidPasswordExceptionMessage": "Lütfen 8 karakter veya daha uzun geçerli bir şifre girin.",
  "error.leavePolicyMissing": "İzin Politikası Eksik",
  "error.leaveRequestNotEditable": "Bu izin talebini düzenleme izniniz yok.",
  "error.leaveRequestOverlap": "Seçtiğiniz tarihler mevcut bir izinle çakışıyor. Lütfen farklı bir zaman aralığı seçin.",
  "error.leaveRequestsLoadingError.description": "İzinleriniz mevcut, ancak bizim tarafımızdan yaşanan teknik bir sorun nedeniyle onları yükleyemedik. Lütfen sayfayı yenilemeyi deneyin. Sorun devam ederse, <link>Müşteri Desteğimiz</link> ile iletişime geçin.",
  "error.leaveRequestsLoadingError.title": "İzinler yüklenemiyor",
  "error.leaveRequestStatusNotApproved": "İzin talebi durumu: onaylanmadı.",
  "error.leaveRequestStatusNotOpen": "İzin talebi durumu: açık değil",
  "error.leaveSubmitError": "İzin Gönderme Hatası",
  "error.leaveTypeMissing": "İzin Türü Eksik",
  "error.LocationLimitReached": "Üzgünüz, şu anda {limitNumber} konum sınırını aştığınız için TEMEL plana geçiş yapamazsınız. Lütfen konumlarınızı TEMEL plan kriterlerine uyacak şekilde ayarlayın veya daha fazla rehberlik için destek ekibiyle iletişime geçin.",
  "error.microsoft.channelNotFound": "Seçili Microsoft Teams ekibinin üyesi değilsiniz",
  "error.microsoft.logInWithDifferentAccount": "Farklı bir hesapla giriş yapın",
  "error.microsoft.microsoftPermissionsNotGranted": "Microsoft izinleri verilmedi",
  "error.microsoft.notificationsLoginRequiredTitle": "Microsoft hesabınıza giriş yapmanız gerekiyor",
  "error.microsoft.notMemberOfTeam": "Organizasyonunuz tek bir Microsoft Teams ekibiyle kaydoldu ve siz şu ID'ye sahip ekibin üyesi değilsiniz: {msTeamId}.\n\nBu ekibe Microsoft Teams uygulamanızdan katılabilir ve tekrar deneyebilir veya kurulum türünüzü değiştirmek için destek ekibimizle iletişime geçebilirsiniz.",
  "error.microsoft.wrongAccount": "Yanlış Microsoft hesabı",
  "error.missingSlackBotToken": "İşlem gerçekleştirildi, ancak kullanıcıya bildirim göndermek için bot tokeni eksik. Lütfen çıkış yapın ve bot tokenini almak için tekrar giriş yapın.",
  "error.missingSlackBotTokenError": "Bot tokeni eksik olduğu için isteği işleyemiyoruz. Lütfen çıkış yapın ve bot tokenini almak için tekrar giriş yapın, ardından tekrar deneyin.",
  "error.mustBeApproverForTeam": "Bu işlemi gerçekleştirme izniniz yok, çünkü bu departman için Onaylayıcı olmanız gerekiyor.",
  "error.mustBeCreatorOrApprover": "İzin talebinin durumunu değiştirmek için oluşturucu veya onaylayıcı olmalısınız.",
  "error.negativeBallanceNotAllowed": "Bu izin türü için negatif bakiye izin verilmiyor.",
  "error.notificationGeneral": "Bildirim Hatası",
  "error.oneHoureIntervalNotAllowed": "Bu izin türü için isteyebileceğiniz en kısa zaman aralığı 4 saattir (yarım gün).",
  "error.openApi.isntCreated": "Open API Anahtarı oluşturulamadı",
  "error.openApi.isntDeleted": "Open API Anahtarı silinemedi",
  "error.partTimeIsntAllowed": "Yarı zamanlı izin verilmiyor",
  "error.passwordResetLimitExceededException": "Limit aşıldı",
  "error.passwordResetLimitExceededExceptionMessage": "Şifre değiştirme deneme limiti aşıldı. Lütfen 15 dakika sonra tekrar deneyin.",
  "error.paymentFailed": "Ödeme başarısız oldu",
  "error.paymentNotProcessed": "Ödeme başarısız oldu, lütfen farklı bir kart ile deneyin.",
  "error.probationPeriod.isntCreated": "Deneme süresi oluşturulamadı",
  "error.probationPeriod.isntDeleted": "Deneme süresi silinemedi",
  "error.probationPeriod.isntUpdated": "Deneme süresi güncellenemedi",
  "error.PromoCodeDoesntExist": "Promosyon kodu {promoCode} mevcut değil",
  "error.PromoCodeIsntValid": "Promosyon kodu {promoCode} geçerli değil",
  "error.reasonRequired": "Neden alanı zorunludur.",
  "error.requestedMoreThanFullDay": "Bir iş gününde bir tam günden fazla izin talep ettiniz. Lütfen farklı bir gün seçin.",
  "error.samePasswordException": "Şifre değiştirilmedi",
  "error.samePasswordExceptionMessage": "Yeni şifre eskisiyle aynı. Değiştirmek istiyorsanız lütfen farklı bir şifre girin.",
  "error.saveFailed": "Kaydetme başarısız oldu",
  "error.seniorityEntitlement.isntCreated": "Kıdem hakkı oluşturulmadı",
  "error.seniorityEntitlement.isntDeleted": "Kıdem hakkı silinmedi",
  "error.seniorityEntitlement.isntUpdated": "Kıdem hakkı güncellenmedi",
  "error.signin.inactiveUser": "Kullanıcı Aktif Değil",
  "error.signup.alreadySignedUp.descriptionLine1": "Zaten şu e-posta adresiyle kayıtlısınız: <text>{ email }</text>. Lütfen giriş yapın.",
  "error.signup.alreadySignedUp.descriptionLine2": "Şifrenizi unuttuysanız, sıfırlamak için aşağıdaki düğmeye tıklayın.",
  "error.signup.alreadySignedUp.title": "Zaten kayıtlısınız",
  "error.signup.alreadySignedUpInactive.descriptionLine1": "Zaten şu e-posta adresiyle kayıtlısınız: <text>{ email }</text>.",
  "error.signup.alreadySignedUpInactive.descriptionLine2": "Ancak, hesabınız aktif değil. Daha fazla bilgi için lütfen destek ekibimizle iletişime geçin ve onlara aşağıdaki bilgileri gönderin:",
  "error.slack.allowConversationWithSlackBotDescription": "Bu özellik, Vacation Tracker botunun Slack'te doğrudan mesaj almasına izin veren ek bir Slack izni gerektirir. Botumuz, sizin ve Slack Çalışma Alanınızdaki diğer kullanıcıların ona gönderdiği mesajları okuyabilecek, ancak kanallarınızı, gruplarınızı veya doğrudan mesajlarınızı okuma iznine sahip olmayacaktır.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine1": "Vacation Tracker'ın botu, izin talepleri için eğik çizgi komutu ve kısayol desteği sunar. Buna ek olarak, Web Panelinde insan dili konuşmasını etkinleştirebilirsiniz (ve LLM kullanımını seçebilirsiniz).",
  "error.slack.allowConversationWithSlackBotLlmWarningLine2": "Bunu açmak, botun zaman zaman yanlış tarihler veya izin türleri önermesine neden olabilir. İzin talebi yapmadan önce önerilerini kontrol etmek önemlidir.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Ne Vacation Tracker ne de üçüncü tarafları, bilgilerinizi Büyük Dil Modellerini eğitmek için kullanmaz.",
  "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Büyük Dil Modeli (LLM) Sorumluluk Reddi",
  "error.slack.allowConversationWithSlackBotTitle": "Vacation Tracker botu ile konuşmaya izin ver",
  "error.slack.logInWithDifferentAccount": "Farklı hesapla giriş yap",
  "error.slack.usersLoginRequiredTitle": "Slack hesabınıza giriş yapmanız gerekiyor",
  "error.slack.wrongAccount": "Yanlış Slack hesabı",
  "error.somethingWentWrong": "Ah hayır! 😕 Bir şeyler yanlış gitti. ",
  "error.startDateCantBeInPast": "Başlangıç tarihi geçmişte olamaz, lütfen gelecekte bir tarih seçin",
  "error.subscriptionExpired": "Hata! Görünüşe göre şirketinizin Vacation Tracker aboneliği sona ermiş! Şirketinizin aboneliğini yeniden etkinleştirmek için lütfen bir yöneticiyle iletişime geçin.",
  "error.subscriptionExpiredTitle": "Abonelik süresi doldu",
  "error.team.delete": "Departman silinemez",
  "error.TeamLimitReached": "Mevcut departman sayınız CORE planda izin verilen sınırı aşıyor. Plan düşürmek için, lütfen departmanlarınızı {limitNumber} veya daha azına indirin ya da yardım için destek ekibimizle iletişime geçin.",
  "error.token.button": "{platform} ile giriş yap",
  "error.token.description": "{platform} jetonunuzun süresi doldu. Lütfen yeni bir jeton almak için aşağıdaki düğmeye tıklayın",
  "error.token.permissionDenied": "Google Giriş isteminde gerekli kimlik doğrulama kapsamlarına izin vermeden Devam'a tıkladınız. Lütfen Tümünü seç'e veya uygun izinlere tıklayın (Google Takvim kullanarak erişebildiğiniz tüm takvimleri görüntüle, düzenle, paylaş ve kalıcı olarak sil ve Gmail'deki e-posta ayarlarınızı ve filtrelerinizi görüntüle, düzenle, oluştur veya değiştir) ve ardından Devam'a tıklayın.",
  "error.userEndDateInFuture": "Talep edilen tarihler kullanıcının istihdam bitiş tarihinden sonradır. Daha fazla bilgi için lütfen organizasyonunuzun yöneticisi veya Vacation Tracker desteği ile iletişime geçin.",
  "error.UserNotAdminOrApprover": "Kullanıcı yönetici veya onaylayıcı değil",
  "error.userNotFound": "Kullanıcı bulunamadı",
  "error.UserNotFound": "Kullanıcı bulunamadı",
  "error.UserNotFoundApprover": "Onaylayıcı bulunamadı",
  "error.userSignup.invitationAccepted.description": "Davet kodu zaten kabul edildi, lütfen giriş yapın",
  "error.userSignup.invitationAccepted.title": "Davet kodu zaten kabul edildi",
  "error.userSignup.invitationExpired.description": "Davet kodu süresi doldu, lütfen yöneticinizden daveti yeniden göndermesini isteyin",
  "error.userSignup.invitationExpired.title": "Davet kodu süresi doldu",
  "error.userSignup.invitationNotFound.description": "Lütfen davet bağlantınızın doğru olduğunu kontrol edin veya yöneticinizle iletişime geçerek daveti yeniden göndermesini isteyin.",
  "error.userSignup.invitationNotFound.title": "Davet bulunamadı",
  "error.userStartDateInFuture": "İstenen tarihler kullanıcının işe başlama tarihinden öncedir. Daha fazla bilgi için lütfen organizasyonunuzun yöneticisi veya Vacation Tracker desteği ile iletişime geçin.",
  "error.zeroDaysLeaveRequest": "Bir tatil günü veya çalışılmayan bir gün seçtiniz. Lütfen geçerli bir tarih aralığı seçin.",
  "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "Görünüşe göre gerekli izinleri vermeden önce \"Microsoft ile Giriş Yap\" sayfasını iptal ettiniz veya kapattınız. Lütfen Microsoft ile giriş yapmak ve Vacation Tracker kurulumunu tamamlamak için aşağıdaki düğmeye tıklayın.\n\nMicrosoft ile giriş yapma izniniz yoksa, lütfen organizasyonunuzun Microsoft 365 yöneticisiyle iletişime geçin.",
  "errors.microsoft.subscriptionExpiredDescription": "Görünüşe göre aboneliğinizin süresi dolmuş. Daha fazla bilgi için lütfen Vacation Tracker organizasyonunuzun yöneticisiyle iletişime geçin.\n\nYöneticiyi değiştirmeniz gerekiyorsa veya başka sorularınız varsa desteğimizle iletişime geçin.",
  "errors.microsoft.wrongMicrosoftAccountDescription": "Görünüşe göre farklı bir Microsoft hesabıyla giriş yaptınız. Lisansları aşağıdaki e-posta adresini kullanarak satın aldınız:\n\n{email}.\n\nLütfen kurulumu tamamlamak için lisansları satın almak için kullandığınız hesapla giriş yapın.",
  "errors.slack.wrongSlackAccountDescription": "Görünüşe göre farklı bir Slack hesabıyla giriş yaptınız.",
  "events.logs.AUTOMATION": "Otomasyonlar",
  "events.logs.BILLING": "Faturalandırma",
  "events.logs.BULK_ACTIONS": "Kullanıcıları içe aktar",
  "events.logs.COMPANY": "Şirket",
  "events.logs.LABEL": "Etiket",
  "events.logs.LEAVE_POLICY": "İzin politikası",
  "events.logs.LEAVE_TYPE": "İzin türü",
  "events.logs.LOCATION": "Konum",
  "events.logs.LOCATION_YEAR_ROLLOVER": "Konum yılı devrimi",
  "events.logs.NOTIFICATION": "Bildirimler",
  "events.logs.REPORT": "Rapor",
  "events.logs.TEAM": "Departman",
  "events.logs.TEAM_POLICY": "Takım politikası",
  "export.approved": "Onaylandı",
  "export.currentYear": "Mevcut yıl",
  "export.denied": "Reddedildi",
  "export.export": "Dışa aktar",
  "export.exportPeriod": "Dışa aktarma dönemi",
  "export.fileType": "Dışa aktarma formatı",
  "export.fileTypeCsv": "CSV",
  "export.fileTypeExcelXlsx": "Excel",
  "export.labels": "Etiketler",
  "export.lastMonth": "Geçen ay",
  "export.leaveBalanceReport": "İzin Bakiyesi Raporu",
  "export.leaveBalanceReportCustomDaysInfo": "Lütfen özel bir dönem seçerseniz, raporun yalnızca kullanılan günleri veya saatleri göstereceğini unutmayın. Önceden tanımlanmış seçeneklerden birini seçmediğiniz sürece toplam veya mevcut kotayı dışa aktaramayacaksınız.",
  "export.leaveBalanceReportDescription": "Belirli bir zaman dilimi için her çalışan için İzin Türüne göre hak edilen ve alınan izinlerin miktarı.",
  "export.leaveHistoryReport": "İzin Talebi Raporu",
  "export.leaveHistoryReportDescription": "Belirli bir zaman dilimi için her çalışan için İzin Türüne göre tüm izin taleplerinin (onaylanan, reddedilen, süresi dolan) geçmişi.",
  "export.locations": "Konumlar",
  "export.monthlyLeaveBalanceReport": "Aylık İzin Bakiyesi Raporu",
  "export.monthlyLeaveBalanceReportDescription": "Her çalışan için mevcut ve takip eden 11 ay boyunca İzin Türüne göre alınan (veya planlanan) izinlerin miktarı.",
  "export.nextYear": "Gelecek yıl",
  "export.previousYear": "Önceki yıl",
  "export.rangeDate": "Başlangıç ve Bitiş Tarihi",
  "export.reportType": "Rapor türü",
  "export.required": "Bu alan zorunludur.",
  "export.status": "Durum",
  "export.teams": "Departmanlar",
  "externalConnect.faildLogin": "Tek kullanımlık jetonunuzun süresi dolmuş veya zaten kullanılmış. Sizi birkaç saniye içinde hedef sayfaya yönlendireceğiz, ancak tekrar giriş yapmanız gerekebilir.\n\nAcele ediyorsanız, aşağıdaki düğmeye tıklayın.",
  "externalConnect.parseError": "Hay aksi! 😕 Bir şeyler yanlış gitti.\n\n10 saniye içinde giriş sayfasına yönlendirileceksiniz.",
  "externalConnect.pleaseWait": "Lütfen bekleyin, bilgisayarınız bir süper kahraman değil!",
  "form.confirmPasswordInfo": "Lütfen şifrenizi onaylayın!",
  "form.department.generalDescriptionCreate": "Departmanınızın adını belirleyin ve kullanıcılarını ve onaylayıcılarını seçin. Kullanıcı eklemeden önce departmanı yapılandırmak istiyorsanız, önce departmanı oluşturabilir ve daha sonra kullanıcıları ekleyebilirsiniz, ancak en az bir onaylayıcı eklemek zorunludur.",
  "form.department.generalDescriptionEdit": "Departmanınızın adını veya kullanıcılarını ve onaylayıcılarını değiştirin.",
  "form.department.generalTitle": "Departman Ayarları",
  "form.department.setDefaultDescription": "Varsayılan departman",
  "form.department.setDefaultTitle": "Varsayılan departman",
  "form.department.subdepartmentDescription": "Organizasyon ağacı benzeri bir yapı oluşturmak için departmanları iç içe yerleştirebilirsiniz. Bu bir alt departmansa, bu bölümde üst departmanını ayarlayabilirsiniz.",
  "form.department.subdepartmentTitle": "Alt departman ayarları",
  "form.general.companySettingsDescription": "Şirketinizin adını ve birincil iletişim e-postasını belirleyin. Sağladığınız e-posta adresi yalnızca önemli ürün iletişimi için kullanılacaktır. Spam yok, söz veriyoruz.",
  "form.general.companySettingsTitle": "Şirket ayarları",
  "form.general.configurationDescription": "Bu bölüm, şirketiniz için izin takibinin belirli yönlerini ince ayar yapmanıza olanak tanır. Kullanıcı bitiş tarihlerini etkinleştirerek, bitiş tarihi olan çalışanları otomatik olarak devre dışı bırakabilir ve ayrılışlarından sonra izin talepleri göndermelerini engelleyebilirsiniz. Şirketinizin politikalarına göre izin kotalarını gün veya saat olarak takip etmeyi seçebilirsiniz. Ayrıca, izin takibi için başlangıç yılını tanımlayabilirsiniz. Varsayılan olarak, izin hesaplamaları kaydolduğunuz yıldan başlar, ancak diğer sistemlerden veya tablolardan geçmiş verileri dahil etmek için bunu ayarlayabilirsiniz.",
  "form.general.configurationTitle": "Yapılandırma",
  "form.general.migrationDescription": "Slack, Microsoft Teams veya Google Workspace kullanıyor musunuz? E-posta ve şifre tabanlı kimlik doğrulamadan platform özel entegrasyona geçebilirsiniz. Entegrasyonlarımızdan birine geçmek, giriş ve kullanıcı davet sürecini basitleştirecek (kullanıcı dizininizden kullanıcıları içe aktarma) ve Microsoft Teams sekmeleri ve Microsoft Teams veya Slack botları gibi ek entegrasyonlar sağlayacaktır.",
  "form.general.migrationFeatureDescription": "Bu, taşıma işlemini başlatacaktır. Hesabınızı bağlayabileceğiniz bir platforma yönlendirileceksiniz.",
  "form.general.migrationFeatureTitle": "Başka bir platforma taşımayı başlat",
  "form.general.migrationTitle": "Başka bir platforma geç",
  "form.inputRequired": "Bu alan zorunludur",
  "form.inputSize": "Alan {len} hane olmalıdır",
  "form.passwordDoNotMatch": "Girdiğiniz iki şifre eşleşmiyor!",
  "form.userNameRequired": "Lütfen adınızı girin",
  "form.validEmailRequired": "Lütfen geçerli bir e-posta adresi girin.",
  "general.announceNewUsers": "Yeni kullanıcılara duyur",
  "general.announceNewUsersInfo": "Yeni Slack/Teams/Google Workspaces kullanıcılarına Vacation Tracker hakkında duyuru mesajı gönder",
  "general.announceNewUsersInfoTooltip": "Bu kutu işaretlendiğinde, Slack/Teams/GW organizasyonunuza yeni bir kullanıcı eklediğinizde, Vacation Tracker'ın nasıl çalıştığını açıklayan kısa bir mesaj alacaklar. Bu kutu işaretlenmezse, yeni kullanıcılar Vacation Tracker'dan herhangi bir duyuru mesajı almayacaktır.",
  "general.announceNewUsersTooltip": "{platform}'a yeni bir kullanıcı eklediğinizde, Vacation Tracker'ın nasıl çalıştığını açıklayan kısa bir mesaj alacaklar. Gönderme seçeneğini seçerseniz, yeni kullanıcılar Vacation Tracker'dan herhangi bir duyuru mesajı almayacaktır.",
  "general.calculationStartYear": "Vacation Tracker izinlerinizi şu tarihten itibaren takip eder",
  "general.calculationStartYearTooltipLine1": "Bu ayarı yalnızca başka bir sistemden veya tablolardan geçmiş izinleri içe aktarmak ve bu izinleri Vacation Tracker'ın hesaplamalarına dahil etmek istiyorsanız değiştirmeniz gerekir.",
  "general.calculationStartYearTooltipLine2": "Vacation Tracker, izinleri kaydolduğunuz yıldan itibaren hesaplar. Bu, ayarlarınıza göre kalan ve devredilen günleri otomatik olarak hesaplayacağımız ve önceki sisteminizden devredilenleri her kullanıcı için profil sayfalarında ayarlayabileceğiniz anlamına gelir. Ancak, Vacation Tracker'ın geçmiş yıllar için izinleri hesaplamasını istiyorsanız, bu tarihi değiştirebilirsiniz. Daha fazla bilgi için <support>desteğimizle iletişime geçmekten</support> çekinmeyin.",
  "general.clickToSelectPlatform": "Platform seçmek için tıklayın",
  "general.companyId": "Şirket Kimliği:",
  "general.companyName": "Şirket Adı",
  "general.confirmModalTitle": "Şirket Ayarları Güncellemelerini Onaylayın",
  "general.connectSync": "Senkronizasyonu bağla",
  "general.connectToAnotherPlatform": "Başka bir platforma geç",
  "general.connectWith": "{platform}'a geç",
  "general.connectWithHelpdeskInfo": "Hangi platformu seçeceğinizden emin değil misiniz? Bu <link>makale</link> yardımcı olabilir.",
  "general.days": "Günler",
  "general.deleteAccount": "Verilerinizi değiştirmek veya silmek istiyorsanız, lütfen <mail>hello@vacationtracker.io</mail> adresinden destek ekibimizle iletişime geçin",
  "general.enableUserEndDate": "Kullanıcı bitiş tarihini etkinleştir",
  "general.enableUserEndDateInfo": "Etkinleştirildiğinde, bitiş tarihi olan kullanıcılar o tarihten sonra izin talebi gönderemeyecek ve otomatik olarak devre dışı bırakılacaklar.",
  "general.enableUserEndDateTooltip": "Kullanıcı Bitiş Tarihi etkinleştirildiğinde, Kullanıcılar bitiş tarihlerinden sonraki zaman dilimi için izin talebi gönderemeyecekler. Otomatik olarak devre dışı bırakılacak ve Vacation Tracker'a giriş yapamayacaklar.",
  "general.googleConsent": "Google girişi",
  "general.googleConsentInfo": "Google onayı",
  "general.googleMorePermission": "Google zeza sa imenima ako hocete da budete sigurni da imate puno ime i prezime treba nam jos permission-a, kliknite ovde da nam date jos permission-a",
  "general.hourlyLeaveAccounting": "İzin Kotalarını Şu Birimde Ayarla:",
  "general.hourlyLeaveAccountingTooltip": "Etkinleştirildiğinde, bu seçenek tüm izinlerin ve kotaların süresini saat cinsinden takip eder. Yalnızca Tam plan için geçerlidir.",
  "general.hours": "Saatler",
  "general.importUsersAutomatically": "Kullanıcıları otomatik olarak içe aktar",
  "general.importUsersAutomaticallyInfo": "Kullanıcıları otomatik olarak içe aktarma özelliği açıkken, {platform}'dan tüm yeni normal kullanıcıları otomatik olarak içe aktaracağız ve etkinleştireceğiz.",
  "general.importUsersAutomaticallyInfoTooltip": "Örneğin, çalışma alanınıza yeni bir kullanıcı davet ettiğinizde, o kullanıcı davetten birkaç dakika sonra Vacation Tracker'da mevcut olacaktır. Çok kanallı bir konuk kullanıcıyı etkinleştirmek istiyorsanız, onları manuel olarak davet etmeniz gerekecektir.",
  "general.importUsersAutomaticallyTurnOffInfo": "Artık {platform}'dan tüm yeni normal kullanıcıları otomatik olarak içe aktarmayacağız ve etkinleştirmeyeceğiz.",
  "general.importUsersConfigSection": "Kullanıcı İçe Aktarma Yapılandırması",
  "general.importUsersConfigSectionDescription": "Bu bölüm, kullanıcı dizininizden otomatik kullanıcı içe aktarmayı etkinleştirmenize ve duyuru mesajı, varsayılan departman ve yeni kullanıcılar için varsayılan konum gibi kullanıcı içe aktarma ayarlarını yapılandırmanıza olanak tanır.",
  "general.pleaseEnterCompanyName": "Lütfen şirket adını girin",
  "general.pleaseEnterContactEmail": "Lütfen şirket e-postasını girin",
  "general.syncGoogleUsers": "Google kullanıcılarını senkronize et",
  "general.syncGoogleUsersInfo": "Google kullanıcılarını otomatik silme, güncelleme ve ekleme senkronizasyonu",
  "general.updateInProgress": "Şirket ayarları güncelleniyor",
  "general.updateSuccessTitle": "Şirket ayarları güncellendi",
  "general.usefulLinks": "<healthcheck>Sağlık Kontrolü</healthcheck>, <helpdesk>Yardım Masası</helpdesk>",
  "general.usefulLinksTitle": "Faydalı bağlantılar: ",
  "holidays.filterByType": "Tatil türüne göre filtrele",
  "holidays.holidaysName": "Tatil adı",
  "holidays.holidaysTab": "{year} için tatiller",
  "holidays.holidaysType": "Tatil Türü",
  "holidays.importHolidays": "Tatilleri İçe Aktar",
  "holidays.importHolidaysAutomaticallyQuestion": "Gelecek yıllar için seçilen tatilleri otomatik olarak içe aktarmak istiyor musunuz?",
  "holidays.importHolidaysAutomaticallyTooltip": "Vacation Tracker, Şükran Günü, Paskalya vb. gibi her yıl aynı tarihte olmayan resmi tatiller için günleri otomatik olarak ayarlayacaktır.",
  "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Maalesef, bu özellik şu anda bu ülke için desteklenmiyor. Hizmetlerimizi genişletmek için çalışıyoruz, bu yüzden lütfen güncellemeler için takipte kalın.",
  "holidays.importHolidaysAutomaticallyTurnedOff": "Tatilleri otomatik içe aktarma kapatıldı",
  "holidays.importHolidaysAutomaticallyTurnedOn": "Tatilleri otomatik içe aktarma açıldı",
  "holidays.importHolidaysCount": "{count} seçili tatili içe aktar",
  "holidays.importHolidaysFor": "<b>{countryState}</b> için <b>{year}</b> yılının tatillerini içe aktar",
  "holidays.importHolidaysSummary": "<b>{countryState}</b> için <b>{year}</b> yılı için aşağıdaki tatilleri seçtiniz",
  "holidays.pastHolidays": "Geçmiş tatiller",
  "holidays.totalSelectedHolidays": "{total} tatil seçtiniz",
  "holidays.totalSelectedHolidaysTip": "({total} uygulanan filtre nedeniyle görünmüyor)",
  "holidays.typeBank": "Banka",
  "holidays.typeObservance": "Anma",
  "holidays.typeOptional": "İsteğe bağlı",
  "holidays.typePublic": "Resmi",
  "holidays.typeSchool": "Okul",
  "importLeaves.importFail": "İçe aktarma şu hata mesajıyla başarısız oldu:",
  "importLeaves.importSuccess": "İzinleri içe aktarmak için gönderdiniz. Başarıyla içe aktarılıp aktarılmadıklarını görmek için birkaç dakika sonra kontrol edin.",
  "importUsers.allUsersImported.description": "Görünüşe göre dizininizdeki tüm kullanıcıları zaten içe aktarmışsınız! Tüm kullanıcıları Kullanıcılar sayfasında görmelisiniz. Birini göremiyorsanız, Etkin Olmayan sekmesine bakın. Herhangi bir sorunuz varsa veya yardıma ihtiyacınız olursa destek ekibimizle iletişime geçmekten çekinmeyin.",
  "importUsers.allUsersImported.goToUsersPageButton": "Kullanıcılar sayfasına git",
  "importUsers.allUsersImported.title": "Tüm kullanıcılar zaten içe aktarıldı!",
  "importUsers.form.backButton": "Kullanıcılar sayfasına geri dön",
  "importUsers.form.searchGoogleUsersPlaceholder": "Google Workspace'inizde kullanıcı ara",
  "importUsers.form.searchMicrosoftUsersPlaceholder": "Microsoft 365 Active Directory'nizde kullanıcı aramaya başlamak için en az bir karakter yazın",
  "importUsers.form.searchSlackUsersPlaceholder": "Slack Çalışma Alanınızda kullanıcı ara",
  "importUsers.form.searchUsersNotFound": "Kullanıcı bulunamadı veya zaten içe aktarıldı",
  "importUsers.form.searchUsersPlaceholder": "Microsoft 365 Active Directory'nizde kullanıcı ara",
  "importUsers.form.selectedUsersLabel": "Seçilen Kullanıcılar ({selectedUsers})",
  "importUsers.form.showSelectedUsersAsNumber": "Seçilen Kullanıcılar",
  "importUsers.statistics.imported": "Vacation Tracker'a içe aktarılan kullanıcılar",
  "importUsers.statistics.total": "{directoryName} dizininizdeki toplam kullanıcı sayısı",
  "leaveRequest.halfDay": "Yarım Gün",
  "leaveRequest.hourly": "Saatlik",
  "leaveRequest.sent": "İzin talebi gönderildi",
  "leaveRequest.simpleQuota.addAnotherLeave": "Başka bir izin ekle",
  "leaveRequest.simpleQuota.info": "{start} - {end} tarihleri için {leaveTypeName} izin bakiyeniz burada.",
  "leaveRequest.simpleQuota.requestAnotherLeave": "Başka bir izin talep et",
  "leaveRequest.simpleQuota.summaryInfo": "Aşağıdaki talebi onay için yöneticinize gönderdik",
  "leaveRequests.addedCurrentYear": "Mevcut yıl için eklendi",
  "leaveRequests.addeddNextYear": "Gelecek yıl için eklendi",
  "leaveRequests.cancelThisAction": "Bu işlemi iptal et",
  "leaveRequests.dates": "Tarih{plural}",
  "leaveRequests.daysRemainingForCurrentYear": "Bu yıl için kalan günler",
  "leaveRequests.daysRemainingForNextYear": "Gelecek yıl için kalan günler",
  "leaveRequests.daysRequestedForCurrentYear": "Bu yıl için talep edilen günler",
  "leaveRequests.daysRequestedForNextYear": "Gelecek yıl için talep edilen günler",
  "leaveRequests.denyWithReason": "Gerekçe ile reddet",
  "leaveRequests.hoursRemainingForCurrentYear": "Bu yıl için kalan saatler",
  "leaveRequests.hoursRemainingForNextYear": "Gelecek yıl için kalan saatler",
  "leaveRequests.hoursRequestedForCurrentYear": "Bu yıl için talep edilen saatler",
  "leaveRequests.hoursRequestedForNextYear": "Gelecek yıl için talep edilen saatler",
  "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> aşağıdaki izin talebini gönderdi:",
  "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> aşağıdaki TOIL talebini gönderdi:",
  "leaveRequests.leaveTypeName": "İzin Türü",
  "leaveRequests.pending": "Bekleyen talep",
  "leaveRequests.reason": "Sebep",
  "leaveRequests.reasonForDenying": "Reddetme sebebi",
  "leaveRequests.remainingCurrentYear": "Bu yıl için kalan",
  "leaveRequests.remainingNextYear": "Gelecek yıl için kalan",
  "leaveRequests.requestedCurrentYear": "Bu yıl için talep edilen",
  "leaveRequests.requestedNextYear": "Gelecek yıl için talep edilen",
  "leaveRequests.requestSummary": "Talep özeti",
  "leaveRequests.wrongAction": "Bu eylem bizim için bilinmiyor...🤔 Lütfen farklı bir tane deneyin! 7 saniye içinde Gösterge Paneline yönlendirileceksiniz.",
  "leaveRequests.wrongLeaveRequestId": "Hata! Bu izin talebi hiçbir yerde bulunamadı! 🔎 7 saniye içinde Gösterge Paneline yönlendirileceksiniz.",
  "leaveRequestStatus.approved": "onaylandı",
  "leaveRequestStatus.APPROVED": "onaylandı",
  "leaveRequestStatus.cancelled": "iptal edildi",
  "leaveRequestStatus.CANCELLED": "iptal edildi",
  "leaveRequestStatus.deleted": "silindi",
  "leaveRequestStatus.DELETED": "silindi",
  "leaveRequestStatus.denied": "reddedildi",
  "leaveRequestStatus.DENIED": "reddedildi",
  "leaveRequestStatus.open": "açık",
  "leaveRequestStatus.OPEN": "açık",
  "leaveTypes.activatingLeaveType": "İzin Türünü Etkinleştir",
  "leaveTypes.activatingLeaveTypeInfo": "Bu İzin Türünü etkinleştirerek, önceki Lokasyonlardaki yerleşimini ve geçmiş ayarlarını ve verilerini geri yüklüyorsunuz.",
  "leaveTypes.activatingLeaveTypeTitle": "İzin Türünü Etkinleştir",
  "leaveTypes.addLeaveTypeInfo": "Bir isim, kolay gezinme için renk ve durum (Aktif/Pasif) ekleyerek özel bir İzin Türü oluşturun. Endişelenmeyin, tüm İzin Türü ayarlarını daha sonra düzenleyebilirsiniz. \n\nBir İzin Türüne özel bir İzin Politikası atamak için, Lokasyonlar -> İzin Politikası Ata'yı ziyaret edin.",
  "leaveTypes.addTypeToLocationButton": "Ekle",
  "leaveTypes.assignedToLocationsColumn": "Lokasyonlara Atandı",
  "leaveTypes.assignedToLocationsColumnWarning": "Bu izin türü herhangi bir konuma atanmamış.",
  "leaveTypes.color": "Renk",
  "leaveTypes.createInProgress": "{leaveTypeName} izin türü oluşturuluyor",
  "leaveTypes.createNewLeaveType": "Yeni İzin Türü Oluştur",
  "leaveTypes.deactivateLeaveType": "İzin Türünü Devre Dışı Bırak",
  "leaveTypes.deactivateLeaveTypeInfo": "Bu İzin Türünü devre dışı bırakarak, şu anda kullanmakta olan tüm Konumlardan kaldırıyorsunuz. İzin Türünü tekrar etkinleştirdiğinizde, mevcut ayarlar ve verilerle aynı Konumlarda görünecektir.",
  "leaveTypes.deactivateLeaveTypeTitle": "İzin Türünü Devre Dışı Bırak",
  "leaveTypes.deleteInProgress": "{leaveTypeName} izin türü siliniyor",
  "leaveTypes.deleteLeaveType": "İzin Türünü Sil",
  "leaveTypes.deleteLeaveTypeConfirm": "{name} izin türünü silmek istediğinizden emin misiniz?",
  "leaveTypes.deleteLeaveTypeTitle": "İzin Türünü Sil",
  "leaveTypes.editLeaveType": "İzin Türünü Düzenle",
  "leaveTypes.isActive": "Aktif",
  "leaveTypes.sort": "Sırala",
  "leaveTypes.typeName": "İsim",
  "leaveTypes.updateInProgress": "{leaveTypeName} izin türü güncelleniyor",
  "location.createInProgress": "{locationName} konumu oluşturuluyor",
  "location.deleteInProgress": "{locationName} konumu siliniyor",
  "location.editLocation": "Konumu düzenle",
  "location.fiscalYearStart": "Mali Yıl Başlangıcı",
  "location.general": "Genel",
  "location.hasNoUsers": "Konumda kullanıcı yok.",
  "location.holidays": "Tatiller",
  "location.holidays.autoimportTitle": "Tatil otomatik içe aktarma ayarları değiştirildi",
  "location.holidays.brokenHolidays": "Bu tatil eklenemiyor, lütfen sorunu çözmek için destek ile iletişime geçin",
  "location.holidays.successTitle": "Tatiller başarıyla güncellendi",
  "location.holidays.updateInProgress": "Tatiller güncelleniyor",
  "location.holidaysUpdated": "Tatiller güncellendi",
  "location.leavePolicies": "İzin Politikaları",
  "location.leavePoliciesNotExistInfo": "Şu anda mevcut İzin Politikası bulunmamaktadır. Lütfen Konuma bir İzin Politikası atayın.",
  "location.name": "İsim",
  "location.never": "Asla",
  "location.resetQuotas": "İzin Kotası Sıfırlama Esası",
  "location.resetQuotasInfo": "Bu ayar, yıllık izin bakiyenizin muhasebe yılına (şirketin mali yılı) göre mi yoksa çalışanın başlangıç tarihine göre mi sıfırlanacağını belirleyecektir. Kotaların yanı sıra, devretme politikanız da bu ayara göre etkilenecektir.",
  "location.rolloverExpiryAfterDays": "Devredilen Günlerin Son Kullanma Tarihi",
  "location.rolloverExpiryAfterHours": "Devredilen Saatlerin Son Kullanma Tarihi",
  "location.rolloverExpiryDay": "Devredilen Günler Sona Ermeli mi?",
  "location.rolloverExpiryHour": "Devredilen Saatler Sona Ermeli mi?",
  "location.rolloverExpiryOnDate": "Devredilen Günlerin Sona Erme Tarihi",
  "location.rolloverExpiryOnDate.extra": "Günler günün sonunda sona erecektir.",
  "location.rolloverExpiryOnDateHours": "İleri Taşınan Saatler Şu Tarihte Sona Erer",
  "location.rolloverExpiryOnDateHours.extra": "Saatler günün sonunda sona erecektir.",
  "location.startYear": "Mali Yıl Başlangıcı",
  "location.startYearInfo": "Bir kuruluşun Mali Yılı, vergilendirme veya muhasebe amaçları için hesaplanan bir yıldır. İzin dönemi sıfırlama tarihleri, kuruluşun mali yılının başlangıcına ve sonuna bağlıdır.",
  "location.timezone": "Saat dilimi",
  "location.timezoneDefault": "(GMT+00:00) GMT (yaz saati uygulaması yok)",
  "location.updateInProgress": "{locationName} konumu güncelleniyor",
  "location.users": "Kullanıcı(lar)",
  "location.workWeek": "Çalışma haftası",
  "locationForm.assignLeaveTypesInfo": "Bir Konum oluşturduktan sonra, Kullanıcıların İzin talep edebilmesi için Konuma bir İzin Politikası atayın. \nBir İzin Politikası atamak için, Konum -> İzin Politikaları -> İzin Politikası Ata'ya gidin.",
  "locationForm.createdSuccessfully": "Lütfen bize bir dakika verin, Konumunuzu oluşturuyoruz.",
  "locationForm.createLocation": "Konum Oluştur",
  "locationForm.editLocation": "Konumu Düzenle",
  "locationForm.locationLimitReached": "Hay aksi! Görünüşe göre mevcut planınızda {limitNumber} konum sınırına ulaştınız. Planınızı yönetmek veya yükseltmek için lütfen <billingPage>Fatura sayfasını</billingPage> ziyaret edin.",
  "locationForm.updateLocation": "Konumu Güncelle",
  "locations.automaticallyGeneratedName": "Bu konumu sizin için aşağıdaki ekipleri gruplandırarak otomatik olarak oluşturduk: <t>{team}</t>.",
  "locations.automaticallyTransferredLeavePoliciesAndHolidays": "İzin politikaları ve tatiller sizin için otomatik olarak aktarıldı, bu yüzden onları yeniden oluşturmanız <b>{not}</b> gerekmez.",
  "locations.created": "Oluşturuldu",
  "locations.createNewLocation": "Yeni Konum Oluştur",
  "locations.default": "Varsayılan",
  "locations.defaultLocation": "Varsayılan Konum",
  "locations.defaultLocationDescription": "Tüm yeni kullanıcılar otomatik olarak bu konuma eklenecektir.",
  "locations.deleteLocation": "Konumu Sil",
  "locations.deleteLocation.disabled": "Varsayılan konumu silemezsiniz",
  "locations.deleteLocationConfirm": "Lütfen {name} konumunu silmek istediğinizi onaylayın.",
  "locations.deleteLocationConfirmInitialRollover": "Bir konumu silmek, kullanıcıların yapılandırmasını ve izin bilgilerini etkileyebilir (örn. başlangıçta ileri taşınan günler).",
  "locations.deleteLocationTitle": "{name} Konumunu Sil",
  "locations.editLocation": "Düzenle",
  "locations.hasNoUsers": "Hiçbiri",
  "locations.holidays": "Tatiller",
  "locations.leavePolicies": "İzin Politikaları",
  "locations.leavePolicies.quotaDays": "{value} gün",
  "locations.leavePolicies.quotaHours": "{value} saat",
  "locations.leavePolicies.yearlyQuota": "Yıllık İzin Kotası",
  "locations.locationLimitReached": "Şu anda CORE planındaki {limitNumber} konum slotunuzun tümünü kullanıyorsunuz. Planınızı yönetmek veya yükseltmek için lütfen <billingPage>Fatura sayfasını</billingPage> ziyaret edin.",
  "locations.name": "İsim",
  "locations.pleaseRenameLocation": "Lütfen bu konumu yeniden adlandırın!",
  "locations.pleaseRenameLocationName": "Lütfen otomatik olarak oluşturulan konum isimlerini yeniden adlandırın *",
  "locations.resetQuotas": "İzin Kotası Sıfırlama Esası",
  "locations.selectLocation": "Konum seç",
  "locations.unableToNameThisLocation": "Ancak, sistemimiz bu konumu düzgün bir şekilde adlandıramadı.",
  "locations.users": "Kullanıcı(lar)",
  "locations.viewHolidays": "Tatilleri Görüntüle",
  "locations.viewLeavePolicies": "İzin Politikalarını Görüntüle",
  "locations.viewLocation": "Görüntüle",
  "locations.weAutomaticallyCreatedLocations": "Ekiplerinizi tatillere göre gruplandırarak organizasyonunuz için otomatik olarak konumlar oluşturduk. Tüm izin politikaları aktarıldı, sadece konumlarınızı yeniden adlandırmanız gerekiyor.",
  "manageMicrosoftLicenses.assignLicencesSuccessTitle": "Lisanslar başarıyla atandı.",
  "manageMicrosoftLicenses.weAreAssignLicenses": "Lisansları atıyoruz.",
  "microsoft.adminConsentErrorButton": "Erişim al",
  "microsoft.adminConsentErrorDescription1": "Microsoft Teams organizasyonunuzun yöneticisi olmadığınız görünüyor. Bildirimleri ayarlamak için yönetici izni gerekiyor. Lütfen yönetici izni almak için aşağıdaki bağlantıyı organizasyonunuzun yöneticisine gönderin: ",
  "microsoft.adminConsentErrorDescription2": "Yönetici izni aldığınızda, bildirimlere erişim almak için aşağıdaki düğmeye tıklayın. Eğer bir yöneticiyseniz, aynı bağlantıyı ziyaret edin, yönetici iznini verin ve bildirimleri ayarlayabileceksiniz.",
  "microsoft.adminConsentErrorTitle": "Microsoft Teams Yönetici İzni Gerekli.",
  "microsoft.createCompanyTeamLoginAdminConsentError1": "Microsoft Teams organizasyonunuzun yöneticisi olmadığınız görünüyor. Tek bir Microsoft Teams ekibinden takım üyelerini içe aktarmak için yönetici izni gerekiyor. Lütfen yönetici izni almak için aşağıdaki bağlantıyı organizasyonunuzun yöneticisine gönderin: ",
  "microsoft.createCompanyTeamLoginAdminConsentError2": "Yönetici izni aldığınızda, kayıt işlemine devam edebilirsiniz. Eğer bir yöneticiyseniz, lütfen aynı bağlantıyı ziyaret edin, yönetici iznini verin ve kayıt işlemine devam edebileceksiniz.",
  "microsoft.createCompanyTeamLoginRequiredDescription1": "Seçtiğiniz ekipten kullanıcıları listelemek için ek Microsoft Teams izni gerekiyor. Tamam düğmesine tıklarsanız, sizi Microsoft giriş sayfasına yönlendireceğiz. Tarayıcı ayarlarınıza bağlı olarak, zaten giriş yapmış olabilir ve otomatik olarak geri yönlendirilmiş olabilirsiniz.",
  "microsoft.createCompanyTeamLoginRequiredDescription2": "Microsoft hesabınıza giriş yaptıktan sonra, içe aktarılacak kullanıcıları seçebilmeniz için sizi bu sayfaya geri getireceğiz. İptal düğmesine tıklamak, yine de tüm organizasyonunuzla kaydolmanıza izin verecektir.",
  "microsoft.createCompanyTeamLoginRequiredTitle": "Microsoft Teams Ek İzni Gerekli.",
  "microsoft.manageLicenses.loginMicrosoft": "Lisans atamak için Microsoft 365 hesabınızla giriş yapın.",
  "microsoft.manageLicenses.loginMicrosoftInfo": "Hesabınızla giriş yapmak, Microsoft Active Directory'nizdeki kullanıcıları listeleyecektir. Satın alınan lisansları atayabilecek ve isteğe bağlı olarak içe aktarılan kullanıcılara bildirim gönderebileceksiniz.",
  "microsoft.notificationsLoginRequiredDescription1": "Bildirimleri yönetmek için bir Microsoft Teams jetonu gerekiyor. Tamam düğmesine tıklarsanız, sizi Microsoft giriş sayfasına yönlendireceğiz. Tarayıcı ayarlarınıza bağlı olarak, zaten giriş yapmış olabilir ve otomatik olarak geri yönlendirilmiş olabilirsiniz.",
  "microsoft.notificationsLoginRequiredDescription2": "Microsoft hesabınıza giriş yaptıktan sonra, bildirimleri yönetebilmeniz için sizi bu sayfaya geri getireceğiz. İptal düğmesine tıklamak sizi kontrol panelimizde en son ziyaret ettiğiniz sayfaya geri götürecektir.",
  "microsoft.notificationsLoginRequiredTitle": "Microsoft hesabınıza giriş yapmanız gerekiyor",
  "microsoft.universalLoginRequiredDescription": "Jetonunuzun süresi dolmuş görünüyor. Lütfen devam etmek için Microsoft Teams hesabınıza giriş yapın.",
  "microsoft.universalLoginRequiredTitle": "Jeton süresi doldu",
  "microsoft.usersLoginRequiredDescription1": "Kullanıcıları yönetmek için bir Microsoft Teams jetonu gerekiyor. Tamam düğmesine tıklarsanız, sizi Microsoft giriş sayfasına yönlendireceğiz. Tarayıcı ayarlarınıza bağlı olarak, zaten giriş yapmış olabilir ve otomatik olarak geri yönlendirilmiş olabilirsiniz.",
  "microsoft.usersLoginRequiredDescription2": "Microsoft hesabınıza giriş yaptıktan sonra, kullanıcıları yönetebilmeniz için sizi bu sayfaya geri getireceğiz.",
  "microsoft.usersLoginRequiredTitle": "Microsoft hesabınıza giriş yapmanız gerekiyor",
  "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "Şirketiniz zaten kayıtlı, ancak siz aktif değilsiniz. Doğru e-posta adresini mi kullanıyorsunuz?\nE-posta adresiniz: {email}\nEğer bu e-posta doğruysa, hesabınız aktif değil. Lütfen Vacation Tracker organizasyonunuzun yöneticisiyle iletişime geçin:\n{admins}\nVeya aynı kiracıda ayrı bir bağımsız ekip olarak kaydolmak istiyorsanız desteğimizle iletişime geçin.\nEğer e-postanız doğru değilse, lütfen farklı bir Microsoft hesabıyla giriş yapın veya tarayıcınızın gizli modunda giriş yapın.",
  "microsoftSass.createCompany.alertError.errorTitle": "Hata",
  "microsoftSass.createCompany.alertError.goToAdminCenter": "Yönetim Merkezine Git",
  "microsoftSass.createCompany.alertError.goToConnectPage": "Bağlantı Sayfasına Git",
  "microsoftSass.createCompany.alertError.logInWithPlatform": "{platform} ile giriş yap",
  "microsoftSass.createCompany.alertError.resubscribedDescription": "{companyName} şirketi olarak başarıyla yeniden abone oldunuz.\n\nLütfen giriş sayfasına yönlendirilmek için düğmeye tıklayın.",
  "microsoftSass.createCompany.alertError.resubscribedTitle": "Başarıyla yeniden abone oldunuz",
  "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Satın alma jetonunuzun süresi doldu. Lütfen Microsoft Teams Yönetim Merkezine gidin ve devam etmek için \"Hesabı kur\" düğmesine tıklayın.",
  "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Satın alma jetonunun süresi doldu",
  "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Microsoft satın alma jetonunuz geçersiz. Eğer Vacation Tracker planını Microsoft Teams Uygulama Mağazası üzerinden satın aldıysanız, lütfen Microsoft Teams Yönetim Merkezine gidin ve devam etmek için \"Hesabı kur\" düğmesine tıklayın. Aksi takdirde, giriş yapmak veya hesap oluşturmak için bağlantı sayfamıza gidin.",
  "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Satın alma jetonu geçersiz",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "Zaten bir hesabınız var. Şirketiniz ({companyName}) {platform} kullanarak kaydoldu. {platform} hesabınızla giriş yapmak için aşağıdaki düğmeye tıklayın.\n\nEndişelenmeyin. Satın almanızı iptal ettik ve az önce satın aldığınız lisanslar için ücretlendirilmeyeceksiniz. Şirketinizin eski aboneliği hala aktif. Yeni aboneliğinizin iptal edildiğini doğrulamak için Microsoft Yönetim Merkezini ziyaret edebilirsiniz.\n\nEğer bilerek başka bir abonelik satın almak istiyorsanız, lütfen desteğimizle iletişime geçin.",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "Zaten bir hesabınız var",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "SATIN ALINAN LİSANSLARI ATAYIN",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "Satın aldığınız lisansları atayalım! {totalLicenses} lisansınız var. Bunları Vacation Tracker'ı kullanabilecek kullanıcılara atayın.",
  "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "Daha sonra Vacation Tracker kontrol panelindeki Kullanıcılar sayfasından daha fazla lisans satın alabilirsiniz.",
  "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "Endişelenmeyin! Onayınız olmadan kimseyi içe aktarmayacak veya bilgilendirmeyeceğiz.",
  "microsoftSass.createCompany.completed": "Tamamlandı",
  "microsoftSass.createCompany.createCompanySetupInfo": "Vacation Tracker organizasyonunuz için iletişim e-postasını ve varsayılan ayarları belirleyelim.",
  "microsoftSass.createCompany.createCompanyTitle": "ŞİRKET KURULUMUNU TAMAMLA",
  "microsoftSass.createCompany.finishFristStep": "Devam etmek için lütfen şirket kurulumunu tamamlayın.",
  "microsoftSass.createCompany.finishSecondStep": "Devam etmek için lütfen lisansları atayın.",
  "microsoftSass.createCompany.finishSetup": "Kurulumu Bitir",
  "microsoftSass.createCompany.help": "Yardıma ihtiyacınız varsa, <helpdesk>Yardım Merkezimizi</helpdesk> ziyaret edin veya <support>desteğimizle iletişime geçin</support>.",
  "microsoftSass.createCompany.importAllUsers": "Tüm kullanıcılara atandı",
  "microsoftSass.createCompany.letsCompleteSetup": "Kurulumu tamamlayalım, kullanıcılarınıza lisans atayalım ve Microsoft Teams için Vacation Tracker entegrasyonunu kuralım.",
  "microsoftSass.createCompany.numberOfLicensesAssigned": "{assignedLicenses} lisans atandı",
  "microsoftSass.createCompany.setupMsBot.areYouAdmin": "Microsoft Teams entegrasyonunuzun yöneticisi misiniz",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "Bot ve kanal sekmelerini kurmak için gerekli olan iki ek Microsoft Teams iznini vermediğiniz görünüyor. Bu adım olmadan, seçtiğiniz takım üyelerine hoş geldiniz mesajı gönderemeyecek ve Microsoft Teams uygulamanızdan Vacation Tracker uygulamasını kullanamayacaksınız.",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "Bu adımı atlayabilir ve bot ile sekmeleri manuel olarak kurabilirsiniz. Adım adım kılavuz için Yardım Merkezimize bakın.",
  "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Bot ve sekmeleri kur",
  "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "Bot ve sekmeleri manuel olarak kuracağım",
  "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "Microsoft Teams'inize Vacation Tracker sohbet botu ve sekmelerini kurmak için ek izne ihtiyacımız var. Aşağıdaki Devam Et düğmesine tıkladığınızda sizi Microsoft izinler ekranına yönlendireceğiz.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Lütfen Vacation Tracker Microsoft Teams botunu ve sekmelerini <helpdesk>bu Yardım Merkezi makalesinde</helpdesk> açıklanan adımları izleyerek manuel olarak kurun. Manuel kurulum için herhangi bir yönetici ayrıcalığına ihtiyacınız olmamalı. Manuel kurulum için herhangi bir yönetici yardımına ihtiyacınız olmamalı. Yardıma ihtiyacınız olursa, <support>desteğimizle iletişime geçin</support>.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "Microsoft entegrasyonunu manuel olarak kurmanız gerekiyor",
  "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "Bot ve sekmeleri kurmak için yönetici onayına ihtiyacımız var. Botu kurana kadar hoş geldiniz mesajları gönderemeyiz.",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Sekmeleri kurmak istediğiniz Microsoft Teams kanalını seçin",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Microsoft Teams kanalı seçin",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Botu kurmak istediğiniz Microsoft Teams ekibini seçin",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Microsoft Teams ekibi seçin",
  "microsoftSass.createCompany.setupMsBotAndTabs": "VACATION TRACKER BOT VE SEKMELERİNİ KUR",
  "microsoftSass.createCompany.setupMsBotAndTabsInfo": "Microsoft Teams için Vacation Tracker botunu ve sekmelerini kuralım!",
  "microsoftSass.createCompany.stepAssignLicenses": "2. Lisansları ata",
  "microsoftSass.createCompany.stepCreateComapny": "1. Şirket kurulumunu tamamla",
  "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Microsoft Teams botunu ve sekmelerini kur",
  "microsoftSass.createCompany.welcomeMessage": "Vacation Tracker'a hoş geldiniz! <bold>{totalLicenses} lisans</bold> satın aldığınız için teşekkür ederiz.",
  "microsoftSass.createCompany.welcomeTitle": "VACATION TRACKER'I KURALIM",
  "microsoftSubscription.adminInitialContent": "Aboneliğiniz sona erdi. Aboneliği yeniden etkinleştirmek için lütfen Microsoft AppSource mağazasına gidin. Herhangi bir sorunuz varsa veya yardıma ihtiyacınız olursa, desteğimizle iletişime geçin.",
  "microsoftSubscription.goToAppSource": "Yeni bir abonelik satın al",
  "modal.dontHaveAnAccount.accountNotFound": "Hesap bulunamadı",
  "modal.dontHaveAnAccount.asCompany": "Bir şirket olarak",
  "modal.dontHaveAnAccount.asCompanyDescription": "Vacation Tracker'ı denemek isteyen bir yönetici veya işletme sahibisiniz.",
  "modal.dontHaveAnAccount.asEmployee": "Bir çalışan olarak",
  "modal.dontHaveAnAccount.asEmployeeDescription": "Şirketiniz Vacation Tracker'ı kullanıyor, ancak sizin erişiminiz yok.",
  "modal.dontHaveAnAccount.employeeSignUp": "Çalışan kaydı",
  "modal.dontHaveAnAccount.employeeSignupDescription": "Eğer şirketiniz zaten Vacation Tracker kullanıyorsa, kayıt olmanıza gerek yok! Hesabınızı aktifleştirmesi için yöneticinizle iletişime geçin ve Vacation Tracker paneline ve entegrasyonlarına tam erişim elde edin.",
  "modal.dontHaveAnAccount.employeeSignupSupport": "Yardıma ihtiyacınız varsa veya yöneticinizin kim olduğundan emin değilseniz, destek ekibimizle iletişime geçmekten çekinmeyin.",
  "modal.dontHaveAnAccount.introParagraph": "Görünüşe göre bir hesabınız yok. Vacation Tracker'ı nasıl kullanmak istersiniz?",
  "modal.forgotPassword.step2Intro": "E-postanıza bir kod gönderdik. Lütfen aşağıya girin ve yeni bir şifre belirleyin. 5 dakika içinde bir e-posta almadıysanız, spam klasörünüzü kontrol edin ve doğru e-posta adresini girdiğinizden emin olun.",
  "myProfile.cancel": "İptal",
  "myProfile.cancelLeave": "İptal",
  "myProfile.cancelLeaveConfirmTitle": "İzni İptal Et",
  "myProfile.dontHaveAnyHistory": "{name} henüz hiç izin kullanmamış.",
  "myProfile.edit.title": "Profilimi Düzenle",
  "myProfile.editLeave": "İzni Düzenle",
  "myProfile.editLeaveWord": "Düzenle",
  "myProfile.general": "Genel",
  "myProfile.history": "Geçmiş",
  "myProfile.historyLeaves": "Geçmiş",
  "myProfile.leaveHistory": "İzin Geçmişi",
  "myProfile.leaveTypes": "İzin Türleri",
  "myProfile.noUpcomingLeaves": "Planlanmış İzin Yok",
  "myProfile.openMyProfile": "Profilimi Görüntüle",
  "myProfile.upcomingLeaves": "Planlanmış İzinler",
  "myProfile.userWorkWeek": "Çalışma Haftası",
  "not_in_channel": "Seçilen kanal silinmiş veya artık mevcut değil. Lütfen Bildirimleri almak için başka bir kanal seçin.",
  "notification.codeResent": "Doğrulama kodu yeniden gönderildi",
  "notification.codeResentMessage": "Lütfen e-postanızı kontrol edin",
  "notification.passwordReset": "Şifre başarıyla sıfırlandı",
  "notification.passwordResetMessage": "Artık yeni şifrenizi kullanarak giriş yapabilirsiniz.",
  "notifications.add": "Yeni Bildirim",
  "notifications.cantOpenNoptification": "Bu Bildirim başka bir kullanıcı tarafından oluşturuldu ve siz düzenleyemezsiniz.",
  "notifications.createDailyRequestSubmitted": "Günlük bildirimleri ayarlama",
  "notifications.createWeeklyRequestSubmitted": "Haftalık bildirimleri ayarlama",
  "notifications.deleteAutomationConfirm": "Lütfen <strong>{name}</strong> otomasyonunu silmek istediğinizi onaylayın.",
  "notifications.deleteAutomationTitle": "Otomasyonu sil",
  "notifications.deleteDailyRequestSubmitted": "Günlük bildirimleri silme",
  "notifications.deleteEntitlementByRoleAutomationConfirm": "Bu Role Göre Hak Edişler otomasyonunu silmek istediğinizden emin misiniz? Bu işlemi geri alamazsınız. Ancak, otomasyonu silmek zaten verilmiş olan {leaveTypeName} günlerini etkilemeyecektir.",
  "notifications.deleteNotificationConfirm": "Lütfen <strong>{name}</strong> bildirimini silmek istediğinizi onaylayın.",
  "notifications.deleteNotificationOk": "Sil",
  "notifications.deleteNotificationTitle": "Bildirimi sil",
  "notifications.deleteSeniorityEntitlementAutomationConfirm": "Bu Kıdem Hak Edişleri otomasyonunu silmek istediğinizden emin misiniz? Bu işlemi geri alamazsınız. Ancak, otomasyonu silmek zaten verilmiş olan {leaveTypeName} günlerini etkilemeyecektir.",
  "notifications.deleteWeeklyRequestSubmitted": "Haftalık bildirimleri silme",
  "notifications.emptyViewButton": "Günlük veya haftalık bir bildirim oluştur",
  "notifications.emptyViewMessage": "yaklaşan izinler hakkında otomatik bildirimler göndererek",
  "notifications.emptyViewTitle": "Görünürlüğü artırın ve ekibinizi bilgilendirin",
  "notifications.emptyViewVideoLink": "Daha fazla bilgi için <link>Yardım Masası</link>'mızı ziyaret edin veya aşağıdaki videoyu izleyin:",
  "notifications.error": "Bu sorun devam ederse, lütfen destek ekibiyle iletişime geçin ve onlara şu hata kodunu verin: {correlationId}.",
  "notifications.frequency": "Sıklık",
  "notifications.handleSubmitErrorTitle": "Bildirim Hatası",
  "notifications.microsoftTokenMissing": "Microsoft belirteci eksik olduğu için bildirimleri ayarlayamıyoruz. Lütfen oturumu kapatıp tekrar giriş yaparak Microsoft belirtecini alın ve tekrar deneyin.",
  "notifications.notificationInfo": "Belirli bir departman için Bildirimler ayarlamak isterseniz, lütfen Departmanlar sayfasına gidin ve Bildirimleri ayarlamak istediğiniz departmanı düzenleyin.",
  "notifications.notificationSendNow": "Bildiriminiz yolda! 🚀",
  "notifications.notificationSendNowFailed": "Gönderim Başarısız",
  "notifications.notificationSendNowFailedInfo": "Ah hayır! 😕 Bir şeyler yanlış gitti. ",
  "notifications.notificationTitle": "Bildirimler",
  "notifications.passwordChangedDescription": "Şifrenizi başarıyla değiştirdiniz, lütfen giriş yapın",
  "notifications.passwordChangedTitle": "Şifre değiştirildi",
  "notifications.sendNotificationNowCofirmModalDesc": "<strong>{name}</strong> bildirimi aktif değil. Yine de şimdi göndermek istediğinizi onaylayın.",
  "notifications.sendNotificationNowCofirmModalTitle": "Bildirim gönder",
  "notifications.sendNotificationNowOk": "Gönder",
  "notifications.table.actions": "İşlemler",
  "notifications.table.active": "Aktif",
  "notifications.table.DAILY": "Günlük",
  "notifications.table.frequency": "Sıklık",
  "notifications.table.name": "İsim",
  "notifications.table.owner": "Sahip",
  "notifications.table.scheduledTime": "Planlanmış Zaman",
  "notifications.table.sendNotificationNow": "Şimdi Gönder",
  "notifications.table.WEEKLY": "Haftalık",
  "notifications.title": "Bildirimler",
  "notifications.tooltipInfo": "Tüm Bildirimleriniz daha kolay yönetim için bu sayfada bir araya geliyor. Daha fazla bilgi için lütfen <helpDesk>Yardım Masası makalemize</helpDesk> bakın.",
  "notifications.update": "Bildirimi Güncelle",
  "notifications.updateAutomationConfirm": "Bir otomasyonu devre dışı bırakmak aboneliği iptal etmeyecektir. Aboneliği kapatmak istiyorsanız, \"Otomasyon Oluştur\" düğmesine tıklayarak otomasyonlar listesine erişmeniz ve ardından \"Aboneliği İptal Et\" düğmesine tıklamanız gerekir",
  "notifications.updateAutomationTitle": "Otomasyonu güncelle",
  "notifications.updateNotification": "Güncelle",
  "notifications.weekStartsOn": "Hafta Başlangıcı",
  "notificationsForm.advancedFilter.tooltipInfo": "Hiçbir Filtre seçilmezse, Bildirim tüm Kullanıcıları içerecektir.",
  "notificationsForm.createInProgress": "{notificationName} bildirimi oluşturuluyor",
  "notificationsForm.currentWeek": "Mevcut hafta",
  "notificationsForm.DAILY": "Günlük",
  "notificationsForm.deleteFailed": "Silme başarısız oldu",
  "notificationsForm.deleteInProgress": "{notificationName} bildirimi siliniyor",
  "notificationsForm.frequency": "Sıklık",
  "notificationsForm.groupBy": "İzinleri grupla",
  "notificationsForm.groupBy.tooltip": "İzinler tercihlerinize göre görüntülenebilir. Kullanıcıların Konumu, Departmanı veya Alfabetik olarak (Hiçbiri) sıralanabilir.",
  "notificationsForm.locale": "Dil",
  "notificationsForm.LOCATION": "Konum",
  "notificationsForm.name": "Bildirim Adı",
  "notificationsForm.newNotificationTitle": "Yeni Bildirim",
  "notificationsForm.nextWeek": "Gelecek hafta",
  "notificationsForm.NONE": "Hiçbiri",
  "notificationsForm.periodCovered": "Kapsanan Zaman Aralığı",
  "notificationsForm.saveFailed": "Kaydetme başarısız",
  "notificationsForm.selectChannel": "Kanal Seç",
  "notificationsForm.sendEmpty": "Yaklaşan İzin Yoksa",
  "notificationsForm.sendEmptyNo": "Hayır, bildirimi gönderme",
  "notificationsForm.sendEmptyYes": "Evet, bildirimi gönder",
  "notificationsForm.sendOnDays": "Gönderilecek günler",
  "notificationsForm.sendOnDaysInfo": "Bildirim seçilen gün(ler)de gönderilecektir.",
  "notificationsForm.submit": "Bildirimi Kaydet",
  "notificationsForm.TEAM": "Departman",
  "notificationsForm.time": "Zaman",
  "notificationsForm.timezone": "Zaman dilimi",
  "notificationsForm.update": "Bildirimi Güncelle",
  "notificationsForm.updateInProgress": "{notificationName} bildirimi güncelleniyor",
  "notificationsForm.updateNotificationTitle": "Bildirimi Güncelle",
  "notificationsForm.WEEKLY": "Haftalık",
  "notificationsForm.weekStartsOn": "Hafta Başlangıcı",
  "overlapInfo.moreUsersAreOnLeaves": "ℹ️ Görünüşe göre {overlapperNames} aynı zaman diliminde izinde olacak.",
  "overlapInfo.youCanSafelyAcceptTheRequest": "İsteği güvenle kabul edebilirsiniz, {teamName} ekibinden başka kimse bu zaman diliminde izinde değil.",
  "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "{teamName} ekibinden başka kimse bu zaman diliminde izinde değil.",
  "page404.message": "Aradığınız sayfa hak edilmiş bir tatil yapıyor.",
  "page404.title": "Sayfa tatilde.",
  "quickTour.admin.buttonTitle": "Hızlı tur",
  "quickTour.buttons.back": "Geri",
  "quickTour.buttons.close": "Kapat",
  "quickTour.buttons.last": "Kapat",
  "quickTour.buttons.next": "İleri",
  "quickTour.buttons.skip": "Atla",
  "registration.flowB.progress": "Adım {current} / {total}",
  "registration.flowB.step1.list1": "<b>7 günlük ücretsiz deneme</b>, tüm özellikler dahil",
  "registration.flowB.step1.list2": "Deneme süresi boyunca kredi kartı gerekmez",
  "registration.flowB.step1.list3": "İş birliği aracınızla entegre edin",
  "registration.flowB.step1.submitButton": "Başlayın",
  "registration.flowB.step1.subtitle": "Şirket e-posta adresiniz nedir?",
  "registration.flowB.step1.title": "Vacation Tracker ile PTO takibini kolaylaştıran dünya çapındaki binlerce ekibe katılın.",
  "registration.flowB.step2.submitButton": "Hesap Oluştur",
  "registration.flowB.step2.subtitle": "Vacation Tracker'a hoş geldiniz",
  "registration.flowB.step3.continueWithEmailPlatform": "İş e-postası ile devam et",
  "registration.flowB.step3.continueWithPlatform": "{platform} ile devam et",
  "registration.flowB.step3.list1": "İzin Taleplerini Yönetin",
  "registration.flowB.step3.list10": "ve daha fazlası!",
  "registration.flowB.step3.list2": "İzin Bildirimleri Gönderin",
  "registration.flowB.step3.list3": "İzin Türlerini Özelleştirin",
  "registration.flowB.step3.list4": "Lokasyonlar ve Departmanlar Oluşturun",
  "registration.flowB.step3.list5": "Raporlar Oluşturun",
  "registration.flowB.step3.list6": "İzin Tahakkukları Ayarlayın",
  "registration.flowB.step3.list7": "Takım Üyelerini Etiketlere Göre Gruplandırın",
  "registration.flowB.step3.list8": "Raporları Planlayın",
  "registration.flowB.step3.list9": "Saatlik İzin Talep Edin",
  "registration.flowB.step3.notice": "Siz hazır olana kadar ekibinizle hiçbir şekilde iletişim kurmayacağız.",
  "registration.flowB.step3.submitButton": "SON BİR ADIM",
  "registration.flowB.step3.subtitle": "Hangi araçla entegre olmak istersiniz, {name}?",
  "registration.flowB.step3.title": "7 günlük tam plan denemesi",
  "registration.flowB.step3.title2": "<b>Tam planımızın</b> <b>ücretsiz denemesini</b> alacaksınız, bu plan şunları içerir:",
  "registration.flowB.testimonial1.profession": "Yapımcı",
  "registration.flowB.testimonial1.title": "\"Herkesin bir milyon soru sormadan Vacation Tracker'ı kullanmaya geçiş yapma kolaylığı etkileyiciydi!\"",
  "registration.flowB.testimonial2.profession": "CEO",
  "registration.flowB.testimonial2.title": "\"Kurulumu çok basit, izin talep etmeyi ve onaylamayı son derece kolaylaştırıyor ve ekip çok duyarlı ve geri bildirimleri ciddiye alıyor.\"",
  "registration.flowB.testimonial3.profession": "İdari Hizmetler Direktörü",
  "registration.flowB.testimonial3.title": "\"Artık personelime ve diğer birçok şeye odaklanabiliyorum - Vacation Tracker bana haftada en az 10 saat ve yöneticilerimize ve çalışanlarımıza sayısız saat kazandırdı çünkü her şey elektronik.\"",
  "requestLeave.handleSubmitErrorTitle": "İzin Gönderme Hatası",
  "requestLeave.inProgress": "İzin talep ediliyor",
  "requestLeave.requestLeave": "İzin Talebi",
  "requestLeave.title": "İzin talep edildi",
  "scheduledReportForm.addNewEmail": "Alıcı Ekle",
  "scheduledReportForm.approved": "Onaylandı",
  "scheduledReportForm.createAScheduledReport": "Yeni Rapor",
  "scheduledReportForm.createInProgress": "{scheduledReportName} planlanmış raporu oluşturuluyor",
  "scheduledReportForm.deleteFailed": "Silme başarısız",
  "scheduledReportForm.deleteInProgress": "{scheduledReportName} planlanmış raporu siliniyor",
  "scheduledReportForm.deleteReport": "Raporu Sil",
  "scheduledReportForm.deleteScheduledReport": "Planlanmış raporu sil",
  "scheduledReportForm.deleteScheduledReportConfirm": "Lütfen {name} planlanmış raporunu silmek istediğinizi onaylayın.",
  "scheduledReportForm.deleteScheduledReportTitle": "{name} planlanmış raporunu sil",
  "scheduledReportForm.denied": "Reddedildi",
  "scheduledReportForm.emails": "Alıcılar",
  "scheduledReportForm.lastMonth": "Geçen ay",
  "scheduledReportForm.leaveBalanceReport": "İzin Bakiyesi Raporu",
  "scheduledReportForm.leaveHistoryReport": "İzin Talebi Raporu",
  "scheduledReportForm.leaveStatus": "İzin Durumu",
  "scheduledReportForm.locations": "Konum(lar)",
  "scheduledReportForm.monthlyLeaveBalanceReport": "Aylık İzin Bakiyesi Raporu",
  "scheduledReportForm.period": "Raporlama Dönemi",
  "scheduledReportForm.periodInfo": "Geçen Ay",
  "scheduledReportForm.periodInfoTootltip": "Rapor şu anda önceki ayla sınırlıdır. Daha fazla seçenek için lütfen hello@vacationtracker.io adresine e-posta gönderin ve gelecekteki olası iyileştirmeler için isteğinizi not alacağımızdan emin olun.",
  "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Lütfen geçerli bir e-posta girin veya bu alanı silin.",
  "scheduledReportForm.pleaseInputValidEmail": "Lütfen geçerli bir e-posta girin.",
  "scheduledReportForm.reportName": "İsim",
  "scheduledReportForm.reportSummary": "Rapor Özeti",
  "scheduledReportForm.reportSummaryInfo": "Raporlama Dönemi: {from} - {to}\nRapor Türü: {reportType}\nBir sonraki rapor {dateReport} tarihinde saat {timeReport}'de gönderilecek",
  "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Raporlama Dönemi: Gelecek 12 ay. \nRapor Türü: {reportType}\nBir sonraki rapor {dateReport} tarihinde saat {timeReport}'de gönderilecek",
  "scheduledReportForm.reportType": "Rapor Türü",
  "scheduledReportForm.required": "Bu alan zorunludur.",
  "scheduledReportForm.saveFailed": "Kaydetme başarısız oldu",
  "scheduledReportForm.saveReport": "Raporu Planla",
  "scheduledReportForm.sendDayOfMonth": "Teslim Tarihi/Günü",
  "scheduledReportForm.sendDayOfMonthInfo": "Raporun atanan alıcılara gönderileceği tam günü belirterek rapor teslim tercihlerinizi yapılandırın.",
  "scheduledReportForm.sendHourAndMinute": "Teslim Saati",
  "scheduledReportForm.sendHourAndMinuteInfo": "Raporun atanan alıcılara gönderileceği günün tam saatini belirterek rapor teslim tercihlerinizi yapılandırın.",
  "scheduledReportForm.sendReportFailed": "Teslim Başarısız",
  "scheduledReportForm.sendReportFailedInfo": "Ah hayır! 😕 Bir şeyler yanlış gitti.",
  "scheduledReportForm.sendReportNow": "Şimdi Gönder",
  "scheduledReportForm.sendReportSuccessfully": "Raporunuz yolda! 🚀",
  "scheduledReportForm.teams": "Departman(lar)",
  "scheduledReportForm.timezone": "Saat Dilimi",
  "scheduledReportForm.updateAScheduledReport": "Raporu Güncelle",
  "scheduledReportForm.updateInProgress": "{scheduledReportName} planlanmış raporu güncelleniyor",
  "scheduledReportForm.updateReport": "Raporu Güncelle",
  "scheduledReports.add": "Yeni Rapor",
  "scheduledReports.allLocations": "Tüm Konumlar",
  "scheduledReports.allTeams": "Tüm Departmanlar",
  "scheduledReports.createScheduledReports": "Yeni Rapor",
  "scheduledReports.deleteFailed": "Silme başarısız",
  "scheduledReports.deleteInProgress": "{scheduledReportName} planlanmış raporu siliniyor",
  "scheduledReports.deleteReportConfirm": "{name} raporunu silmek istiyor musunuz?",
  "scheduledReports.deleteReportOkText": "Sil",
  "scheduledReports.deleteReportTitle": "Silmeyi Onayla",
  "scheduledReports.deliveryDate": "Teslim Tarihi",
  "scheduledReports.edit": "Düzenle",
  "scheduledReports.lastMonth": "Geçen ay",
  "scheduledReports.leaveBalanceReport": "İzin Bakiye Raporu",
  "scheduledReports.leaveHistoryReport": "İzin Talep Raporu",
  "scheduledReports.locations": "Konum(lar)",
  "scheduledReports.monthlyLeaveBalanceReport": "Aylık İzin Bakiye Raporu",
  "scheduledReports.name": "İsim",
  "scheduledReports.period": "Raporlama Dönemi",
  "scheduledReports.receivers": "Alıcılar",
  "scheduledReports.reportSentSuccessfully": "Raporunuz yolda! 🚀",
  "scheduledReports.reportType": "Rapor Türü",
  "scheduledReports.sendReportFailed": "Gönderim Başarısız",
  "scheduledReports.sendReportFailedInfo": "Eyvah! 😕 Bir şeyler ters gitti. ",
  "scheduledReports.sendReportNow": "Şimdi Gönder",
  "scheduledReports.sendReportSuccessfully": "Raporunuz yolda! 🚀",
  "scheduledReports.teams": "Departman(lar)",
  "sidebar.addLeave": "İzin Ekle",
  "sidebar.addons": "Eklentiler",
  "sidebar.billing": "Faturalandırma",
  "sidebar.calendar": "Takvim",
  "sidebar.changelog": "Değişiklik Günlüğü",
  "sidebar.dashboard": "Gösterge Paneli",
  "sidebar.export": "Dışa Aktar",
  "sidebar.faq": "Yardım Masası",
  "sidebar.general": "Genel",
  "sidebar.holidays": "Tatiller",
  "sidebar.leaves": "İzin Talepleri",
  "sidebar.leaveTypes": "İzin Türleri",
  "sidebar.location": "Konum",
  "sidebar.locations": "Konumlar",
  "sidebar.main": "Ana",
  "sidebar.myProfile": "Profilim",
  "sidebar.notifications": "Bildirimler",
  "sidebar.onboarding": "Oryantasyon",
  "sidebar.reports": "Raporlar",
  "sidebar.requestLeave": "İzin Talebi",
  "sidebar.scheduledReports": "Planlanmış Raporlar",
  "sidebar.settings": "Ayarlar",
  "sidebar.teams": "Departmanlar",
  "sidebar.user": "Kullanıcı",
  "sidebar.users": "Kullanıcılar",
  "signInAsUser.codeIsRequired": "Bir kod gereklidir",
  "signInAsUser.email": "E-posta adresinizi girin",
  "signInAsUser.emailIsRequired": "Geçerli bir e-posta adresi gereklidir",
  "signInAsUser.enterTheCode": "Kodu girin",
  "signInAsUser.userId": "Kullanıcı kimliğini girin",
  "signInAsUser.userIdIsRequired": "Bir kullanıcı kimliği gereklidir",
  "slack.notificationsLoginRequiredDescription1": "Bildirimleri yönetmek Slack izinleri gerektirir. Tamam düğmesine tıklarsanız, sizi Slack giriş sayfasına yönlendireceğiz. Tarayıcı ayarlarınıza bağlı olarak, zaten giriş yapmış olabilir ve otomatik olarak geri yönlendirilmiş olabilirsiniz.",
  "slack.notificationsLoginRequiredDescription2": "Slack hesabınıza giriş yaptıktan sonra, bildirimleri yönetebilmeniz için sizi bu sayfaya geri getireceğiz. İptal düğmesine tıklamak sizi gösterge panelimizde en son ziyaret ettiğiniz sayfaya geri götürecektir.",
  "slack.notificationsLoginRequiredTitle": "Slack hesabınıza giriş yapmanız gerekiyor",
  "slack.usersLoginRequiredDescription1": "Kullanıcıları yönetmek Slack izinleri gerektirir. Tamam düğmesine tıklarsanız, sizi Slack giriş sayfasına yönlendireceğiz. Tarayıcı ayarlarınıza bağlı olarak, zaten giriş yapmış olabilir ve otomatik olarak geri yönlendirilmiş olabilirsiniz.",
  "slack.usersLoginRequiredDescription2": "Slack hesabınıza giriş yaptıktan sonra, kullanıcıları yönetebilmeniz için sizi bu sayfaya geri getireceğiz.",
  "slack.usersLoginRequiredTitle": "Slack hesabınıza giriş yapmanız gerekiyor",
  "stripe.dynamicMessage": "{message}",
  "subscription.annual.info": "{plan} {period} Planını etkinleştireceksiniz - ${price}",
  "subscription.hidePlansButton": "Planları gizle",
  "subscription.logout": "Çıkış yap",
  "subscription.monthly.info": "{plan} {period} Planını etkinleştireceksiniz - ${usersPrice} ({noOfActiveUsers} kullanıcı x {dollarValue})",
  "subscription.monthly.infoUnderUserPLanLimit": "{plan} {period} Planını etkinleştireceksiniz - ${usersPrice}",
  "subscription.showPlansButton": "Planları göster",
  "subscription.stripeCustomerPortalLink": "Müşteri portalını ziyaret etmek için buraya tıklayın",
  "subscription.subscribe": "Abone ol",
  "subscription.title": "Abonelik",
  "subscription.updateInProgress": "Ödeme deneniyor",
  "subscriptions.switchPlanTitle": "<strong>{plan}</strong> planına geç",
  "surveyAboutUs.description": "Bizi nasıl duydunuzu söyleyebilir misiniz? Vacation Tracker'a olan yolculuğunuz hakkında daha fazla bilgi edinmek isteriz. Bu isteğe bağlıdır, ancak bize çok yardımcı olacaktır. Teşekkürler!",
  "surveyAboutUs.OptionQuestion1-1": "Google Araması",
  "surveyAboutUs.OptionQuestion1-1-1": "Google'da ne aradınız (örneğin \"tatil takibi\")?",
  "surveyAboutUs.OptionQuestion1-2": "Uygulama Mağazası (Slack - Uygulama Dizini, MS Teams - Uygulama Kaynağı, vb...)",
  "surveyAboutUs.OptionQuestion1-3": "Sosyal Medya, İnceleme Platformu veya Başka Bir Web Sitesi",
  "surveyAboutUs.OptionQuestion1-3-1": "Hangi platform veya site?",
  "surveyAboutUs.OptionQuestion1-4": "Tavsiye",
  "surveyAboutUs.OptionQuestion1-4-1": "Harika! Bizi kim tavsiye etti?",
  "surveyAboutUs.OptionQuestion1-4-1-1": "Bir meslektaş veya arkadaş",
  "surveyAboutUs.OptionQuestion1-4-1-2": "Başka bir şirket önerdi",
  "surveyAboutUs.OptionQuestion1-4-1-3": "Geçmişte kullanmıştım",
  "surveyAboutUs.OptionQuestion1-5": "Diğer",
  "surveyAboutUs.Question1": "Vacation Tracker hakkında nasıl haberdar oldunuz?",
  "surveyAboutUs.textarea": "Lütfen belirtin",
  "surveyAboutUs.title": "Vacation Tracker hakkında nasıl haberdar oldunuz?",
  "team.approvers": "Onaylayıcı(lar)",
  "team.createInProgress": "{teamName} departmanı oluşturuluyor",
  "team.editTeam": "Departmanı Düzenle",
  "team.hasNoUsers": "Departmanda kullanıcı yok.",
  "team.name": "İsim",
  "team.team": "Departman",
  "team.updateInProgress": "{teamName} departmanı güncelleniyor",
  "team.users": "Kullanıcı(lar)",
  "teamForm.createTeam": "Departman Oluştur",
  "teamForm.createTeamError": "Hay aksi! 😕 Bir şeyler yanlış gitti.",
  "teamForm.editTeam": "Departmanı Düzenle",
  "teamForm.name": "İsim",
  "teamForm.pleaseEnterTeamName": "Bir departman adı girin",
  "teamForm.setAsSubdepartment": "Üst departmanı seçin",
  "teamForm.teamLimitReached": "Ups! Mevcut planınızın {limitNumber} departman sınırına ulaştınız. Planınızı yönetmek veya yükseltmek için lütfen <billingPage>Fatura sayfasını</billingPage> ziyaret edin.",
  "teamForm.updateTeam": "Departmanı Güncelle",
  "teams.approvers": "Onaylayıcı(lar)",
  "teams.created": "Oluşturuldu",
  "teams.createNewTeam": "Yeni Departman Oluştur",
  "teams.defaultTeam": "Tüm yeni kullanıcılar otomatik olarak bu departmana eklenecektir.",
  "teams.deleteInProgress": "{teamName} departmanı siliniyor",
  "teams.deleteTeam": "Sil",
  "teams.deleteTeamConfirm": "{name} departmanını silmek istediğinizden emin misiniz?",
  "teams.deleteTeamTitle": "Departmanı Sil",
  "teams.editTeam": "Departmanı Düzenle",
  "teams.handleDeleteError": "Silme hatası",
  "teams.handleDeleteErrorTitle": "Departman silme hatası",
  "teams.name": "İsim",
  "teams.removeUserInfo": "Kullanıcıyı varsayılan departmandan çıkaramazsınız, kullanıcıyı başka bir departmana taşımak istiyorsanız, diğer departmanı düzenleyebilir ve kullanıcıyı oraya ekleyebilirsiniz.",
  "teams.teamsLimitReached": "Şu anda CORE plandaki tüm {limitNumber} departman slotunuzu kullanıyorsunuz. Planınızı yönetmek veya yükseltmek için lütfen <billingPage>Fatura sayfasını</billingPage> ziyaret edin.",
  "teams.users": "Kullanıcı(lar)",
  "teams.viewTeam": "Departmanı Görüntüle",
  "user.accrualsCalculator": "Hesapla",
  "user.accrualsCalculatorInfo": "Belirli bir tarihe kadar kaç gün biriktirmiş olacağım?",
  "user.addLeave": "İzin Ekle",
  "user.approve": "Onayla",
  "user.approveInProgress": "İzin talebi onaylanıyor",
  "user.approveLeaveText": "İzin talebini onaylamak istediğinizi lütfen onaylayın.",
  "user.approveLeaveTitle": "İzin Talebini Onayla",
  "user.approveSuccessTitle": "İzin talebi onaylandı",
  "user.bulkChangeStatusInProgress": "Kullanıcıların durumları güncelleniyor.",
  "user.bulkChangeStatusSuccessTitle": "Kullanıcıların durumları güncellendi.",
  "user.cancelLeave": "İzni İptal Et",
  "user.cancelLeave.buttonLink": "İptal",
  "user.cancelLeaveConfirmTitle": "İzni İptal Et",
  "user.cancelLeaveRequestInProgress": "İzin talebi iptal ediliyor",
  "user.cancelLeaveRequestTitle": "İzin talebi iptal edildi",
  "user.currentLeaves": "Mevcut İzinler",
  "user.deleteLeave": "İzni Sil",
  "user.deleteLeave.buttonLabel": "Sil",
  "user.deleteLeaveConfirmText": "İzni silmek istediğinizi lütfen onaylayın.",
  "user.deleteLeaveConfirmTitle": "İzni Sil",
  "user.deleteLeaveInProgress": "İzin siliniyor",
  "user.deleteLeaveSuccessTitle": "İzin silindi",
  "user.deleteToil": "TOIL'i Sil",
  "user.deleteToilConfirmText": "TOIL'i silmek istediğinizi lütfen onaylayın.",
  "user.deny": "Reddet",
  "user.denyInProgress": "İzin talebi reddediliyor",
  "user.denyLeaveConfirm": "İzin talebini reddetmek istediğinizi lütfen onaylayın.",
  "user.denyLeaveTitle": "İzin Talebini Reddet",
  "user.denySuccessTitle": "İzin talebi reddedildi",
  "user.denyWithReason": "Gerekçeyle Reddet",
  "user.dontHaveAnyHistory": "{name} henüz hiç izin kullanmamış.",
  "user.editLeave": "İzni Düzenle",
  "user.editLeave.buttonLabel": "Düzenle",
  "user.editLeaveWord": "Düzenle",
  "user.editUser": "Düzenle",
  "user.editWorkWeek": "Düzenle",
  "user.employeeEndDate": "Çalışanın İşten Ayrılma Tarihi",
  "user.employeeId": "Çalışan Kimliği",
  "user.employeeSince": "İşe Başlama Tarihi",
  "user.general": "Genel",
  "user.history": "Geçmiş",
  "user.historyLeaves": "Geçmiş",
  "user.invitationDeletedTitle": "Davet silindi",
  "user.invitationSuccessTitle": "Davetler gönderildi",
  "user.leaves": "İzinler",
  "user.leaveTypes": "İzin Türleri",
  "user.location": "Konum",
  "user.location.moveInProgress": "Kullanıcı {name} konumuna taşınıyor",
  "user.location.moveSuccessTitle": "Kullanıcı {name} konumuna taşındı",
  "user.logs": "Kayıtlar",
  "user.noUpcomingLeaves": "Planlanmış İzin Yok",
  "user.pendingLeaves": "Bekleyen İzin Talepleri",
  "user.probationPeriodDescription": "Kullanıcı, <probationPeriodName></probationPeriodName> politikası altında <leaveTypeNames></leaveTypeNames> için deneme sürecinde olup, bu süreç <lengthDays></lengthDays> gün sürecek ve <date></date> tarihinde sona erecektir.",
  "user.profile": "Profil",
  "user.resend": "Talebi yeniden gönder",
  "user.resending": "İzin Talebi Yeniden Gönderiliyor...",
  "user.resent": "Talep yeniden gönderildi",
  "user.role": "Rol",
  "user.signUp": "Kaydol",
  "user.signup.intro": "{invitedByName} sizi Vacation Tracker organizasyonuna katılmaya davet etti. Daveti kabul etmek ve Vacation Tracker paneline erişmek için lütfen aşağıdan kaydolun.",
  "user.signup.introHelpdesk": "Herhangi bir sorunuz varsa veya yardıma ihtiyacınız olursa, desteğimizle iletişime geçin veya <helpdesk>Yardım Masası</helpdesk>'nı ziyaret edin.",
  "user.status": "Durum",
  "user.status.updateInProgress": "Kullanıcı durumu güncelleniyor.",
  "user.status.updateSuccessTitle": "Kullanıcı durumu güncellendi.",
  "user.statusReason": "Neden Ekle",
  "user.team.moveInProgress": "Kullanıcı {name} departmanına taşınıyor",
  "user.team.moveSuccessTitle": "Kullanıcı {name} departmanına taşındı",
  "user.upcomingLeaves": "Planlanmış İzinler",
  "user.updateInProgress": "Kullanıcı güncelleniyor",
  "user.updateSuccessTitle": "Kullanıcı güncellendi",
  "user.userWorkWeek": "Kullanıcı Çalışma Haftası",
  "user.workWeek.updateInProgress": "Çalışma haftası güncelleniyor",
  "user.workWeek.updateSuccessTitle": "Çalışma haftası güncellendi",
  "users.aboutToSync": "Mevcut tüm kullanıcıları senkronize ederek, ekibinizin kullandığı iş birliği platformundan kullanıcı verilerini Vacation Tracker profillerine (örn. Ad, Profil Resmi vb.) senkronize ediyorsunuz",
  "users.activateUsers": "Etkinleştir",
  "users.activeUser": "Etkinleştir",
  "users.activeUsers": "Aktif",
  "users.assignLicenses": "Lisans Ata",
  "users.cancel": "İptal",
  "users.changeUserLocation": "Konumu Değiştir",
  "users.changeUserTeam": "Departmanı Değiştir",
  "users.deactivate": "Devre Dışı Bırak",
  "users.deactivateUser": "Devre Dışı Bırak",
  "users.deactivateUserConfirm": "{name} kullanıcısını devre dışı bırakmak istediğinizden emin misiniz?",
  "users.deactivateUsers": "Devre Dışı Bırak",
  "users.deactivateUserTitle": "Devre Dışı Bırak",
  "users.deletedUsers": "Silinmiş",
  "users.deleteInvite": "Daveti sil",
  "users.deleteInviteConfirm": "{email} adresine gönderilen daveti silmek istediğinizden emin misiniz?",
  "users.deleteUser": "Kullanıcıyı Sil",
  "users.deleteUserUserConfirm": "{name} kullanıcısını silmek istediğinizden emin misiniz?",
  "users.employmentDate": "Kullanıcı İşe Giriş Tarihi",
  "users.endDate": "Bitiş Tarihi",
  "users.import.description": "İçe aktarma biraz zaman alabilir, listede yeni kullanıcıları görmüyorsanız lütfen 10-15 saniye sonra bu sayfayı yenileyin.",
  "users.importedSuccessfully": "Kullanıcılar başarıyla içe aktarıldı",
  "users.importMany.InProgress": "Kullanıcılar içe aktarılıyor",
  "users.importMany.successTitle": "Kullanıcılar başarıyla içe aktarıldı",
  "users.importNewUsers": "Kullanıcı Ekle",
  "users.importOne.InProgress": "Yeni kullanıcı içe aktarılıyor",
  "users.importOne.successTitle": "Kullanıcı başarıyla içe aktarıldı",
  "users.importUsers": "Kullanıcıları İçe Aktar",
  "users.inactiveUsers": "Pasif",
  "users.invitations": "Davetiyeler",
  "users.inviteUsers": "Kullanıcıları Davet Et",
  "users.location": "Konum",
  "users.name": "İsim",
  "users.next": "İleri",
  "users.prev": "Geri",
  "users.reinvite": "Yeniden Davet Et",
  "users.reInviteConfirm": "{email} adresini yeniden davet etmek istediğinizden emin misiniz?",
  "users.role": "Rol",
  "users.search": "Kullanıcı ara",
  "users.sendInvitationsInLanguage": "Davetiyeleri şu dilde gönder",
  "users.startDate": "Başlangıç Tarihi",
  "users.syncExistingUsers": "Mevcut Kullanıcıları Senkronize Et",
  "users.syncExistingUsers.InProgress": "Mevcut kullanıcılar senkronize ediliyor",
  "users.syncExistingUsers.successTitle": "Mevcut Kullanıcılar başarıyla senkronize edildi.",
  "users.syncWithGoogleConsent": "Google izniyle senkronize et",
  "users.syncWithoutGoogleConsent": "Google izni olmadan senkronize et",
  "users.team": "Departman",
  "users.updateQuota.InProgress": "Kullanıcı kotası güncelleniyor",
  "users.updateQuota.successTitle": "Kullanıcı kotası başarıyla güncellendi",
  "users.updateRolloverQuota.successTitle": "Başlangıç Devredilen kotası başarıyla güncellendi.",
  "users.viewUser": "Kullanıcıyı Görüntüle",
  "wallchart.range.next4Weeks": "Gelecek 4 hafta",
  "wallchart.range.next5Weeks": "Gelecek 5 hafta",
  "wallchart.range.nextMonth": "Gelecek ay",
  "wallchart.range.previousMonth": "Geçen ay",
  "wallchart.range.thisMonth": "Bu ay"
}

import React from 'react'
import { useIntl } from 'react-intl'
import { App, Form, FormInstance, Input, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import SetPassword from '../set-password'

import IntlMessages from '../../util/IntlMessages'
import { RcFile } from 'antd/lib/upload'

export const UserSignUpForm = ({
  addAvatar = false,
  form,
  isNameInputDisabled = false,
  name = '',
}: {
  addAvatar: boolean
  form: FormInstance<any>
  isNameInputDisabled?: boolean
  name?: string
}) => {
  const { formatMessage } = useIntl()
  const { notification } = App.useApp()

  const beforePictureUpload = (file: RcFile) => {
    notification.destroy('INVALID_FILE_TYPE')
    const allowedFileFormats = ['image/png', 'image/jpeg', 'image/gif']
    if (!allowedFileFormats.includes(file.type)) {
      notification.error({
        message: formatMessage({ id: 'error.invalidFileType' }),
        key: 'INVALID_FILE_TYPE',
        description: formatMessage({ id: 'error.invalidFileType.description' }),
        duration: 0,
      })
      return
    }
    form.setFieldsValue({ profilePicture: file})

    return false
  }

  const handlePreview = (file) => {
    const newTab = window.open()
    newTab?.document.write(`<img src="${file.thumbUrl}" alt="preview" />`)
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  return <>
    <Form.Item
      label={<IntlMessages id="app.firstAndLastName" />}
      name="userName"
      initialValue={name}
      rules={[{
        required: true,
        message: <IntlMessages id="error.fieldIsRequired" />,
      }]}
    >
      <Input type="text" data-qa='first-and-last-name-input' disabled={isNameInputDisabled} />
    </Form.Item>
    <SetPassword />
    {addAvatar && 
      <Form.Item
        label={<IntlMessages id="app.profilePicture" />}
        name="profilePicture"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <ImgCrop rotationSlider>
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={beforePictureUpload}
            onPreview={handlePreview}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}><IntlMessages id="app.upload" /></div>
            </div>
          </Upload>
        </ImgCrop>
      </Form.Item>
    }
  </>
}
import { IPartDay } from './leave-request'

export interface IAnalyticsEventAuth {
  eventType: AnalyticsEventType
  companyId: string
  userId: string
  ignore?: AnalyticsPlatformEnum[]
  [key: string]: string | number | boolean | undefined | null | string[] | IPartDay
}

export interface IAnalyticsEventUnAuth {
  eventType: AnalyticsEventType
  email?: string
  anonymousId?: string
  ignore?: AnalyticsPlatformEnum[]
  platform?: string
  [key: string]: string | number | boolean | undefined | null | string[]
}

export type IAnalyticsEvent = IAnalyticsEventAuth | IAnalyticsEventUnAuth

export type AnalyticsEventType =
  | 'DASHBOARD_OPENED'
  | 'SUBSCRIPTION_STARTED'
  | 'SUBSCRIPTION_CANCELED'
  | 'SUBSCRIPTION_REACTIVATED'
  | 'SUBSCRIPTION_ENDED'
  | 'ONBOARDING_CHECKLIST_STARTED_EVENT'
  | 'ONBOARDING_CHECKLIST_BOT_VIDEO_WATCHED'
  | 'ONBOARDING_CHECKLIST_BOT_BUTTON_CLICKED'
  | 'ONBOARDING_CHECKLIST_BOT_COMPLETED'
  | 'ONBOARDING_CHECKLIST_LEAVE_REQUEST_VIDEO_WATCHED'
  | 'ONBOARDING_CHECKLIST_LEAVE_REQUEST_BUTTON_CLICKED'
  | 'ONBOARDING_CHECKLIST_LEAVE_REQUEST_COMPLETED'
  | 'ONBOARDING_CHECKLIST_ICAL_VIDEO_WATCHED'
  | 'ONBOARDING_CHECKLIST_ICAL_BUTTON_CLICKED'
  | 'ONBOARDING_CHECKLIST_ICAL_COMPLETED'
  | 'ONBOARDING_CHECKLIST_NOTIFICATIONS_VIDEO_WATCHED'
  | 'ONBOARDING_CHECKLIST_NOTIFICATIONS_BUTTON_CLICKED'
  | 'ONBOARDING_CHECKLIST_NOTIFICATIONS_COMPLETED'
  | 'ONBOARDING_CHECKLIST_IMPORT_USERS_VIDEO_WATCHED'
  | 'ONBOARDING_CHECKLIST_IMPORT_USERS_BUTTON_CLICKED'
  | 'ONBOARDING_CHECKLIST_IMPORT_USERS_COMPLETED'
  | 'ONBOARDING_CHECKLIST_COMPLETED_EVENT'
  | 'ADD_TO_CALENDAR_LINK_CLICKED'
  | 'EXPORT_CREATED'
  | 'NUMBER_OF_LOCATIONS_PER_COMPANY'
  | 'NUMBER_OF_TEAMS_PER_COMPANY'
  | 'NUMBER_OF_ADMINS_PER_COMPANY'
  | 'NUMBER_OF_LEAVE_TYPE_PER_COMPANY'
  | 'ANNUAL_SUBSCRIPTION_INVOICE_PAYMENT_FAILED'
  | 'DELETED_USER_WAS_ADMIN'
  | 'SUBSCRIPTION_INVOICE_PAYMENT_FAILED'
  | 'LEAVE_REQUEST_REMINDER_SENT'
  | 'LEAVE_REQUEST_REMINDER_SNOOZED'
  | 'LEAVE_REQUEST_EXPIRED'
  | 'LEAVE_REQUEST_RESENT'
  | 'TOIL_REQUEST_REMINDER_SENT'
  | 'TOIL_REQUEST_REMINDER_SNOOZED'
  | 'TOIL_REQUEST_EXPIRED'
  | 'TOIL_REQUEST_RESENT'
  | 'TOIL_FEATURE_ENABLED'
  | 'TOIL_ADDED_TO_LEAVE_TYPE'
  | 'TOIL_REMOVED_FROM_LEAVE_TYPE'
  | 'TOIL_EXPIRATION_ENABLED'
  | 'TOIL_EXPIRATION_DISABLED'
  | 'TOIL_REQUEST_PERMISSION_SET_TO_ALL_USERS'
  | 'TOIL_REQUEST_PERMISSION_SET_TO_APPROVERS_ONLY'
  | 'TOIL_REQUEST_SUBMITTED'
  | 'TOIL_REQUEST_APPROVED'
  | 'TOIL_REQUEST_DENIED'
  | 'TOIL_REQUEST_CANCELLED'
  | 'SLACK_BOT_MESSAGE'
  | 'AUTOMATION_CREATED'
  | 'AUTOMATION_UPDATED'
  | 'AUTOMATION_DELETED'
  | 'USER_CHANGED_LANGUAGE'
  | 'USER_CHANGED_HOUR_FORMAT'
  | 'USER_CHANGED_EMAIL'
  | 'UPDATE_SUBSCRIPTION_STATUS'
  | 'SUBSTITUTE_APPROVER_SELECTED'
  | 'SUBSTITUTE_APPROVER_CHANGED'
  | 'SUBSTITUTE_APPROVER_LEAVE_REQUEST_APPROVED'
  | 'SUBSTITUTE_APPROVER_LEAVE_REQUEST_DENIED'
  | 'ADDONS_PER_COMPANY'
  | 'MS_BOT_STARTED'
  | 'FEATURE_FLAGS_PER_COMPANY'
  | 'LOCATION_WORK_HOURS_CHANGED'
  // AI Assistant events
  | 'AI_ASSISTED_ONBOARDING_ERROR_TRIAL_ENDED'
  | 'AI_ASSISTED_ONBOARDING_INITIAL_CONVERSATION_CREATED'
  | 'AI_ASSISTED_ONBOARDING_USER_MESSAGE_SAVED'
  | 'AI_ASSISTED_ONBOARDING_IS_ONBOARDING_COMPLETED'
  | 'AI_ASSISTED_ONBOARDING_COMPLETED'
  | 'AI_ASSISTED_ONBOARDING_ASSISTANT_REPLY_SAVED'
  | 'AI_ASSISTED_ONBOARDING_ERROR'

export enum AnalyticsPlatformEnum {
  CUSTOMERIO = 'customerIo',
  POSTHOG = 'ph',
  MIXPANEL = 'mx',
}

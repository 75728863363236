import React, { useContext, useEffect } from 'react'
import { Drawer, Layout } from 'antd'

import { ThemeContext } from '../../context/themeContext'
import { MenuContext } from '../../context/menuContext'
import { DARK_THEME, LIGHT_THEME } from '@vacationtracker/shared/constants/ThemeSetting'
import { TAB_SIZE } from '@vacationtracker/shared/constants/sizes'

import SidebarContent from './SidebarContent'

const { Sider } = Layout

const Sidebar = () => {

  const themeContext = useContext(ThemeContext)
  const menuContext = useContext(MenuContext)
  const onToggleCollapsedNav = () => {
    menuContext.setNavCollapsed(!menuContext.navCollapsed)
  }

  const updateWindowWidth = () => {
    menuContext.setCollapseNavbar(window.innerWidth < TAB_SIZE)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth)
    return () => window.removeEventListener('resize', updateWindowWidth)
  }, [])


  return (
    <Sider
      className={menuContext.collapseNavbar ? 'collapsed-sidebar' : ''}
      width="280"
      trigger={null}
      collapsed={(menuContext.collapseNavbar ? false : menuContext.miniMenu)}
      theme={themeContext.theme === DARK_THEME ? DARK_THEME : LIGHT_THEME}
      collapsible>
      {menuContext.collapseNavbar ?
        <Drawer
          className={'drawer-sidebar vt-header-wrapper'}
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          open={menuContext.navCollapsed}>
          <SidebarContent/>
        </Drawer> :
        <SidebarContent/>
      }
    </Sider>
  )
}
export default Sidebar

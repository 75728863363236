import React from 'react'
import { Alert, Button } from 'antd'
import { useHistory } from 'react-router'

import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { logout } from '../../services/auth/logout-handler'
import { track } from '../../services/analytics/analytics'

import IntlMessages from '../../util/IntlMessages'
import { openChat } from '../../util/set-crisp-session-info'

const MicrosoftSubscriptionPage = () => {
  const { authUser: { role, initialUser, id }, isLoadingAuthUser } = useAppSelector(selectAuthUserSlice)
  const history = useHistory()
  const dispatch = useAppDispatch()

  const onLogout = () => {
    logout({
      history,
      reduxDispatch: dispatch,
      userId: id,
    })
  }

  const openCrisp = (e) => {
    e.preventDefault()
    openChat()
  }

  const goToAdminCenter = () => {
    track('SIGNUP_SETUP_PURCHASE_TOKEN_EXPIRED_CLICK', {
      platform: 'microsoft',
      paymentProcessor: 'microsoft-billing',
    })
    // eslint-disable-next-line max-len
    window.open('https://appsource.microsoft.com/en-us/product/web-apps/vacationtrackertechnologies1601577357036.vacation_tracker-preview?flightCodes=f3ec2894-ca05-4c0f-984c-dc684bf2a8b2','_self')
  }

  return (
    <section className="ant-layout ant-layout-has-sider app-layout">
      <section className="ant-layout content-layout">
        <main className="ant-layout-content">
          <div className="main-content-wrapper">
            <div className="main-content">
              <div className="main-content-header">
                <div className="main-content-header-title">
                  <span><IntlMessages id="subscription.title" /></span>
                </div>
                <div className="ant-card-extra">
                  <Button type="text" onClick={() => onLogout()}><IntlMessages id="app.logout" /></Button>
                </div>
              </div>
              <div className="main-content-body">
                <div className="billing-body">
                  {!isLoadingAuthUser &&
                    <Alert
                      showIcon
                      type='error'
                      message={<IntlMessages id='error.subscriptionExpiredTitle' />}
                      description={
                        <>
                          {role === 'Admin' && <IntlMessages id="microsoftSubscription.adminInitialContent"/>}
                          {role !== 'Admin' && <IntlMessages id="error.subscriptionExpired"/>}

                          {role === 'Admin' &&
                            <div style={{ marginTop: 10 }}>
                              {initialUser && <Button onClick={goToAdminCenter} style={{ marginRight: 15 }}><IntlMessages id='microsoftSubscription.goToAppSource' /></Button>}
                              <Button onClick={openCrisp}>
                                <IntlMessages id='app.contactSupport' />
                              </Button>
                            </div>
                          }
                        </>
                      }
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </section>
  )
}

export default MicrosoftSubscriptionPage
import { WorkingHours } from '@vacationtracker/shared/types/work-week'
import { IGetUsersOffToday, IHeatmapLeaveRequests, IUsersOffToday } from './custom-queries'
import { ILeaveDayDetails, IPartDay } from '@vacationtracker/shared/types/leave-request'

export interface ITodayLeaves extends IUsersOffToday {
  user: IGetUsersOffToday
}

export interface IHeatMapLeaves {
  formatDate: string
  date: number
  value: number
  leaves: IHeatmapLeaveRequests[]
}

export interface IDashboardLeaves {
  id: string
  user: {
    id: string
    name: string
    imageUrl: string
    team?: {
      id: string
      approvers: {
        id: string
      }[]
    }
    labels?: {
      id: string
      name: string
      color: string
    }[]
    location?: {
      id: string
      name: string
    }
    workHours: WorkingHours
  }
  status: string
  approver?: IApprover | IApprover[]
  leavePolicy: {
    id: string
    hideLeaveType: boolean
    leaveType: {
      id?: string
      name: string
      color: string
      deleted?: boolean
    }
  }
  autoApproved?: boolean
  startDate: string
  endDate: string
  isPartDay: boolean
  partDayStartHour?: number
  partDayEndHour?: number
  partDay?: IPartDay
  reason?: string
  workingDays: number
  daysList?: ILeaveDayDetails[]
  calendarDays?: number
  totalThisYear?: number
  totalNextYear?: number
  statusReason?: string
  isEdited?: boolean
  isToil?: boolean
  approvedBySubstituteApprover?: boolean
}

export interface IApprover {
  id: string
  name: string
}

export const DASHBOARD_SURVEY_STORAGE_KEY = 'dashboardSurvey'

openapi: 3.0.3
info:
  title: VacationTracker - OpenAPI 1.0
  description: |-
    Vacation Tracker Open API
  termsOfService: https://vacationtracker.io/terms-of-service
  contact:
    email: dev@vacationtracker.io
  version: 1.0.0
servers:
  - url: https://api.vacationtracker.io
tags:
  - name: departments
    description: Get list of departments
  - name: labels
    description: Get list of labels
  - name: leavetypes
    description: Get list of leave types
  - name: leaves
    description: Get list of leaves
  - name: users
    description: Get list of users

paths:
  /v1/departments:
    options:
      summary: CORS support
      description: |
        Enable CORS by returning correct headers
      tags:
      - CORS
      responses:
        200:
          description: Default response for CORS method
          headers:
            Access-Control-Allow-Origin:
              schema:
                type: "string"
            Access-Control-Allow-Methods:
              schema:
                type: "string"
            Access-Control-Allow-Headers:
              schema:
                type: "string"
          content: {}
      x-amazon-apigateway-integration:
        type: mock
        requestTemplates:
          application/json: "{\"statusCode\": 200}"
        passthroughBehavior: "never"
        responses:
          default:
            statusCode: "200"
            responseParameters:
              method.response.header.Access-Control-Allow-Headers: "'Content-Type,X-Amz-Date,Authorization,X-Api-Key'"
              method.response.header.Access-Control-Allow-Methods: "'*'"
              method.response.header.Access-Control-Allow-Origin: "'*'"
    get:
      tags:
        - departments
      summary: Get departments
      description: This API method returns a list of all active departments with their IDs and names.
      operationId: listDepartments
      security:
        - apiKeyAuth: []
      responses:
        200:
          description: Successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/DepartmentsResponse'
        '400':
          description: Request parameters are not valid
        '404':
          description: The API Gateway cannot find
      x-amazon-apigateway-integration:
        uri: 
          Fn::Sub: 'arn:aws:apigateway:${AWS::Region}:lambda:path/2015-03-31/functions/${ListDepartments.Arn}/invocations'
        httpMethod: POST
        contentHandling: "CONVERT_TO_TEXT"
        type: "aws_proxy"

  /v1/locations:
    options:
      summary: CORS support
      description: |
        Enable CORS by returning correct headers
      tags:
      - CORS
      responses:
        200:
          description: Default response for CORS method
          headers:
            Access-Control-Allow-Origin:
              schema:
                type: "string"
            Access-Control-Allow-Methods:
              schema:
                type: "string"
            Access-Control-Allow-Headers:
              schema:
                type: "string"
          content: {}
      x-amazon-apigateway-integration:
        type: mock
        requestTemplates:
          application/json: "{\"statusCode\": 200}"
        passthroughBehavior: "never"
        responses:
          default:
            statusCode: "200"
            responseParameters:
              method.response.header.Access-Control-Allow-Headers: "'Content-Type,X-Amz-Date,Authorization,X-Api-Key'"
              method.response.header.Access-Control-Allow-Methods: "'*'"
              method.response.header.Access-Control-Allow-Origin: "'*'"
    get:
      tags:
        - locations
      summary: Get locations
      description: This API method returns a list of all active locations with their IDs, names, and basic info.
      operationId: listLocations
      security:
        - apiKeyAuth: []
      responses:
        '200':
          description: Successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/LocationsResponse'
        '400':
          description: Request parameters are not valid
        '404':
          description: The API Gateway cannot find
      x-amazon-apigateway-integration:
        uri: 
          Fn::Sub: 'arn:aws:apigateway:${AWS::Region}:lambda:path/2015-03-31/functions/${ListLocations.Arn}/invocations'
        httpMethod: POST
        contentHandling: "CONVERT_TO_TEXT"
        type: "aws_proxy"

  /v1/labels:
    options:
      summary: CORS support
      description: |
        Enable CORS by returning correct headers
      tags:
      - CORS
      responses:
        200:
          description: Default response for CORS method
          headers:
            Access-Control-Allow-Origin:
              schema:
                type: "string"
            Access-Control-Allow-Methods:
              schema:
                type: "string"
            Access-Control-Allow-Headers:
              schema:
                type: "string"
          content: {}
      x-amazon-apigateway-integration:
        type: mock
        requestTemplates:
          application/json: "{\"statusCode\": 200}"
        passthroughBehavior: "never"
        responses:
          default:
            statusCode: "200"
            responseParameters:
              method.response.header.Access-Control-Allow-Headers: "'Content-Type,X-Amz-Date,Authorization,X-Api-Key'"
              method.response.header.Access-Control-Allow-Methods: "'*'"
              method.response.header.Access-Control-Allow-Origin: "'*'"
    get:
      tags:
        - labels
      summary: Get labels
      description: This API method returns a list of all active labels with their IDs and names.
      operationId: listLabels
      security:
        - apiKeyAuth: []
      responses:
        '200':
          description: Successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/LabelsResponse'
        '400':
          description: Request parameters are not valid
        '404':
          description: The API Gateway cannot find
      x-amazon-apigateway-integration:
        uri: 
          Fn::Sub: 'arn:aws:apigateway:${AWS::Region}:lambda:path/2015-03-31/functions/${ListLabels.Arn}/invocations'
        httpMethod: POST
        contentHandling: "CONVERT_TO_TEXT"
        type: "aws_proxy"

  /v1/leave-types:
    options:
      summary: CORS support
      description: |
        Enable CORS by returning correct headers
      tags:
      - CORS
      responses:
        200:
          description: Default response for CORS method
          headers:
            Access-Control-Allow-Origin:
              schema:
                type: "string"
            Access-Control-Allow-Methods:
              schema:
                type: "string"
            Access-Control-Allow-Headers:
              schema:
                type: "string"
          content: {}
      x-amazon-apigateway-integration:
        type: mock
        requestTemplates:
          application/json: "{\"statusCode\": 200}"
        passthroughBehavior: "never"
        responses:
          default:
            statusCode: "200"
            responseParameters:
              method.response.header.Access-Control-Allow-Headers: "'Content-Type,X-Amz-Date,Authorization,X-Api-Key'"
              method.response.header.Access-Control-Allow-Methods: "'*'"
              method.response.header.Access-Control-Allow-Origin: "'*'"
    get:
      tags:
        - leavetypes
      summary: Get leave types
      description: This API method returns a list of all leave types with their IDs, names, and basic info.
      operationId: listLeaveTypes
      security:
        - apiKeyAuth: []
      responses:
        '200':
          description: Successful operation
          content:
            application/json:
              schema:
                items:
                  $ref: '#/components/schemas/LeaveTypesResponse'
        '400':
          description: Request parameters are not valid
        '404':
          description: The API Gateway cannot find
      x-amazon-apigateway-integration:
        uri: 
          Fn::Sub: 'arn:aws:apigateway:${AWS::Region}:lambda:path/2015-03-31/functions/${ListLeaveTypes.Arn}/invocations'
        httpMethod: POST
        contentHandling: "CONVERT_TO_TEXT"
        type: "aws_proxy"

  /v1/leaves:
    options:
      summary: CORS support
      description: |
        Enable CORS by returning correct headers
      tags:
      - CORS
      responses:
        200:
          description: Default response for CORS method
          headers:
            Access-Control-Allow-Origin:
              schema:
                type: "string"
            Access-Control-Allow-Methods:
              schema:
                type: "string"
            Access-Control-Allow-Headers:
              schema:
                type: "string"
          content: {}
      x-amazon-apigateway-integration:
        type: mock
        requestTemplates:
          application/json: "{\"statusCode\": 200}"
        passthroughBehavior: "never"
        responses:
          default:
            statusCode: "200"
            responseParameters:
              method.response.header.Access-Control-Allow-Headers: "'Content-Type,X-Amz-Date,Authorization,X-Api-Key'"
              method.response.header.Access-Control-Allow-Methods: "'*'"
              method.response.header.Access-Control-Allow-Origin: "'*'"
    get:
      tags:
        - leaves
      summary: Get list of leaves between two dates
      description: This API method returns a list of leaves between two dates.
      operationId: listLeaves
      security:
        - apiKeyAuth: []
      parameters:
        - name: startDate
          in: query
          description: date in YYYY-MM-DD format.
          required: true
          explode: true
          schema:
            type: string
            format: '2023-02-03'
        - name: endDate
          in: query
          description: date in YYYY-MM-DD format, cant be before the startDate.
          required: true
          explode: true
          schema:
            type: string
            format: '2023-03-03'
        - name: nextToken
          in: query
          description: The nextToken returned by the previous API call.
          required: false
          explode: false
          schema:
            type: string
        - name: limit
          in: query
          description: a number of users to return, between 25 and 500, default is 300. If the filters are provided, they'll be applied after the limit. The number of returned users might be less than the provided limit.
          required: false
          explode: false
          schema:
            type: string
        - name: status
          in: query
          description: leave request status, by default 'APPROVED'
          required: false
          explode: false
          schema:
            type: string
            enum:
              - APPROVED
              - OPEN
              - DENIED
              - CANCELLED
              - EXPIRED
              - DELETED
        - name: expand
          in: query
          description: expand leave request
          required: false
          explode: false
          schema:
            type: string
            enum:
              - user
              - approver
              - leaveType
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/LeavesResponse'
        '400':
          description: Request parameters are not valid
      x-amazon-apigateway-integration:
        uri: 
          Fn::Sub: 'arn:aws:apigateway:${AWS::Region}:lambda:path/2015-03-31/functions/${ListLeaves.Arn}/invocations'
        httpMethod: POST
        contentHandling: "CONVERT_TO_TEXT"
        type: "aws_proxy"

  /v1/users:
    options:
      summary: CORS support
      description: |
        Enable CORS by returning correct headers
      tags:
      - CORS
      responses:
        200:
          description: Default response for CORS method
          headers:
            Access-Control-Allow-Origin:
              schema:
                type: "string"
            Access-Control-Allow-Methods:
              schema:
                type: "string"
            Access-Control-Allow-Headers:
              schema:
                type: "string"
          content: {}
      x-amazon-apigateway-integration:
        type: mock
        requestTemplates:
          application/json: "{\"statusCode\": 200}"
        passthroughBehavior: "never"
        responses:
          default:
            statusCode: "200"
            responseParameters:
              method.response.header.Access-Control-Allow-Headers: "'Content-Type,X-Amz-Date,Authorization,X-Api-Key'"
              method.response.header.Access-Control-Allow-Methods: "'*'"
              method.response.header.Access-Control-Allow-Origin: "'*'"
    get:
      tags:
        - users
      summary: Get list of users
      description: This API method returns a list of all users with their IDs, names, and basic info.
      operationId: listUsers
      security:
        - apiKeyAuth: []
      parameters:
        - name: nextToken
          in: query
          description: The nextToken returned by the previous API call.
          required: false
          explode: false
          schema:
            type: string
        - name: limit
          in: query
          description: a number of users to return, between 25 and 500, default is 300. If the filters are provided, they'll be applied after the limit. The number of returned users might be less than the provided limit.
          required: false
          explode: false
          schema:
            type: string
        - name: status
          in: query
          description: filter users by status, default is 'ACTIVE'
          required: false
          explode: false
          schema:
            type: string
            enum:
              - ACTIVE
              - INACTIVE
              - DELETED
        - name: locations
          in: query
          description: a comma-sparated list of locations. Used for filtering, if provided, the API method will return leaves by users in one of the provided locations. By default, the API method returns leaves by users in any location.
          required: false
          explode: false
          schema:
            type: string
        - name: departments
          in: query
          description: a comma-sparated list of departments. Used for filtering, if provided, the API method will return leaves by users in one of the provided departments. By default, the API method returns leaves by users in any department.
          required: false
          explode: false
          schema:
            type: string
        - name: labels
          in: query
          description: a comma-sparated list of labels. Used for filtering, if provided, the API method will return leaves by users with one of the provided labels. By default, the API method returns leaves by users with or without labels.
          required: false
          explode: false
          schema:
            type: string
        - name: expand
          in: query
          description: expand leave request
          required: false
          explode: false
          schema:
            type: string
            enum:
              - location
              - department
      responses:
        '200':
          description: Successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/UsersResponse'
        '400':
          description: Request parameters are not valid
        '404':
          description: The API Gateway cannot find
      x-amazon-apigateway-integration:
        uri: 
          Fn::Sub: 'arn:aws:apigateway:${AWS::Region}:lambda:path/2015-03-31/functions/${ListUsers.Arn}/invocations'
        httpMethod: POST
        contentHandling: "CONVERT_TO_TEXT"
        type: "aws_proxy"

components:
  securitySchemes:
    apiKeyAuth:
      type: apiKey
      name: x-api-key
      in: header
  responses:
    UnauthorizedError:
      description: API key is missing or invalid
      headers:
        WWW_Authenticate:
          schema:
            type: string
  schemas:
    DepartmentsResponse:
      type: object
      properties:
        status:
          type: string
          example: 'ok'
          enum:
            - ok
            - error
        data:
          type: array
          items:
            $ref: '#/components/schemas/Departments'
    Departments:
      type: object
      properties:
        id:
          type: string
          format: UUID
          example: 'teams-969f8429-7dc3-48a1-96b1-6049a99dda06'
          description: department ID
        name:
          type: string
          example: 'Dev Team'
          description: department name
        isDefault:
          type: boolean
          example: false
          description: a boolean that tells you if the department is default or not
        createdAt:
          type: string
          format: Date ISO 8601 format
          example: '2022-08-22T15:00:00.000Z'
          description: a timestamp in the ISO 8601 format

    LocationsResponse:
      type: object
      properties:
        status:
          type: string
          example: 'ok'
          enum:
            - ok
            - error
        data:
          type: array
          items:
            $ref: '#/components/schemas/Locations'
    Locations:
      type: object
      properties:
        id:
          type: string
          format: UUID
          example: 'locations-6ca89536-be55-42c5-8e52-c5c4215afb9f'
          description: location ID
        name:
          type: string
          example: 'Los Angeles'
          description: location name
        timezone:
          type: string
          example: 'America/Los_Angeles'
          description: location time zone
        isDefault:
          type: boolean
          example: false
          description: a boolean that tells you if the location is default or not
        createdAt:
          type: string
          format: Date ISO 8601 format
          example: '2022-08-22T15:00:00.000Z'
          description: a timestamp in the ISO 8601 format

    LabelsResponse:
      type: object
      properties:
        status:
          type: string
          example: 'ok'
          enum:
            - ok
            - error
        data:
          type: array
          items:
            $ref: '#/components/schemas/Labels'
    Labels:
      type: object
      properties:
        id:
          type: string
          format: UUID
          example: '969f8429-7dc3-48a1-96b1-6049a99dda06'
          description: label ID
        name:
          type: string
          example: 'Team B'
          description: lable name
        color:
          type: string
          example: 'FF0000'
          description: label color (hex code)
        userCount:
          type: number
          example: 42
          description: a number of users with this label

    LeaveTypesResponse:
      type: object
      properties:
        status:
          type: string
          example: 'ok'
          enum:
            - ok
            - error
        data:
          type: array
          items:
            $ref: '#/components/schemas/LeaveTypes'
    LeaveTypes:
      type: object
      properties:
        id:
          type: string
          format: UUID
          example: '88681ca1-4a47-4e4d-bc35-ffff86c0f54e'
        name:
          type: string
          example: 'Team B'
        color:
          type: string
          example: 'FF0000'
        isActive:
          type: boolean
          example: true

    LeavesResponse:
      type: object
      properties:
        status:
          type: string
          example: 'ok'
          enum:
            - ok
            - error
        nextToken:
          type: string
          example: 'next-token'
        data:
          type: array
          items:
            $ref: '#/components/schemas/Leaves'
    Leaves:
      type: object
      properties:
        id:
          type: string
          format: UUID
          example: 'leaveRequest-cfe5d39b-f1f0-45aa-85cd-ce93e637a262'
          description: 'leave ID'
        userId:
          type: string
          example: 'user-63b17e97-d38b-4586-8498-bb4f5d2e2c14'
          description: an ID of the user on leave
        approverId: 
          type: string
          example: "user-63b17e97-d38b-4586-8498-bb4f5d2e2c14"
          description: an ID of the approver (only if the leave request is approved)
        autoApproved: 
          type: boolean
          example: false
          description: is the leave request automatically approved or not
        durationCalendarDays:
          type: number
          example: 1
          description: the duration of a leave in calendar days
        durationWorkingDays:
          type: number
          example: 1
          description: the duration of a leave in working/business days
        startDate: 
          type: string
          example: "2022-11-08"
          description: date in YYYY-MM-DD format
        endDate: 
          type: string
          example: "2022-11-08"
          description: date in YYYY-MM-DD format
        isFullDayLeave:
          type: boolean
          example: true
          description: if the selected leave a full day leave (1 or multiple days), or false if it is a part day leave (half day or hourly)
        startHour:
          type: number
          description: start hour in users time zone (in 24h format) for the part day leave, returned only when the isFullDayLeave is equal to false
        endHour:
          type: number
          description: end hour in users time zone (in 24h format) for the part day leave, returned only when the isFullDayLeave is equal to false
        startMinute:
          type: number
          description: start minutes in the users time zone for the part-day leave, returned only when isFullDayLeave is equal to false.
        endMinute:
          type: number
          description: end minutes in the users time zone for the part-day leave, returned only when isFullDayLeave is equal to false.
        leaveTypeId: 
          type: string
          example: "leaveType-fcc1e5c5-ba93-479d-bd58-8f860ba10615"
          description: the ID of a leave type of the selected leave
        locationId: 
          type: string
          example: "location-79c77ac8-e5bf-479f-9488-648a58379ff1"
          description: the ID of the users location when the leave request was sent
        departmentId: 
          type: string
          example: "team-0a21c25e-6925-4a5b-90ee-ecf82096c4f6"
          description: the ID of the users department when the leave request was sent
        status: 
          type: string
          example: "APPROVED"
          description: leave request status
          enum: 
            - APPROVED
            - OPEN
            - DENIED
            - CANCELLED
            - EXPIRED
            - DELETED
        createdAt: 
          type: string
          example: "2022-11-07T16:29:40.062Z"
          description: a timestamp in the ISO 8601 format
        leaveType:
          $ref: '#/components/schemas/LeaveTypes'
        user:
          $ref: '#/components/schemas/Users'
        approver:
          $ref: '#/components/schemas/Users'

    UsersResponse:
      type: object
      properties:
        status:
          type: string
          example: 'ok'
          enum:
            - ok
            - error
        nextToken:
          type: string
          example: 'next-token'
        data:
          type: array
          items:
            $ref: '#/components/schemas/Users'
    Users:
      type: object
      properties:
        id:
          type: string
          format: UUID
          example: 'user-cfe5d39b-f1f0-45aa-85cd-ce93e637a262'
          description: 'user ID'
        name: 
          type: string
          example: "Jomh Chao"
          description: user name
        email:
          type: string
          example: johnchao@chaochao.com
          description: user email
        isAdmin:
          type: boolean
          example: false
          description: a boolean that tells you if the selected user is an admin
        locationId: 
          type: string
          example: "location-79c77ac8-e5bf-479f-9488-648a58379ff1"
          description: the ID of the user's location
        departmentId: 
          type: string
          example: "team-0a21c25e-6925-4a5b-90ee-ecf82096c4f6"
          description: the ID of the user's department
        labels: 
          type: array
          items: 
            type: string
          example: ['contractor']
          description: labels
        status: 
          type: string
          example: "ACTIVE"
          description: users status, one of the following
          enum: 
            - ACTIVE
            - INACTIVE
            - DELETED
        employeeId: 
          type: string
          example: "uuid-id"
          description: an employee ID if it is set on the user profile page
        startDate: 
          type: string
          example: "2022-11-08"
          description: a timestamp in the ISO 8601 format
        endDate: 
          type: string
          example: "2022-11-08"
          description: a timestamp in the ISO 8601 format, if the end date is set on the user profile page
        location:
          $ref: '#/components/schemas/Locations'
        department:
          $ref: '#/components/schemas/Departments'


{
  "accountSettings.connectYourPersonalCalendar": "Conecta tu calendario personal",
  "accountSettings.connectYourPersonalCalendar.description": "Conecta tu calendario personal a las ausencias automáticamente. Cuando solicites una ausencia, puedes establecer si quieres que la ausencia solicitada aparezca como disponible u ocupado en tu calendario. Visita nuestro <helpdesk>Centro de ayuda</helpdesk> para más información.",
  "actionNotifications.companyAnnouncement.success": "Anuncio enviado",
  "actionNotifications.leave.editedRequestDenied": "Edición de solicitud de ausencia denegada.",
  "actionNotifications.leave.editedRequestDeniedDescription": "Tu solicitud de ausencia editada ha sido denegada, sin embargo, tu solicitud original sigue activa.",
  "actionNotifications.leave.requestApproved": "La solicitud de ausencia ha sido aprobada",
  "actionNotifications.leave.requestDenied": "La solicitud de ausencia ha sido denegada.",
  "actionNotifications.leaveType.created": "El tipo de ausencia {leaveTypeName} ha sido creado",
  "actionNotifications.leaveType.deleted": "El tipo de ausencia ha sido eliminado",
  "actionNotifications.leaveType.updated": "El tipo de ausencia {leaveTypeName} ha sido actualizado",
  "actionNotifications.location.createdTitle": "La ubicación {locationName} ha sido creada",
  "actionNotifications.location.deletedTitle": "La ubicación ha sido eliminada",
  "actionNotifications.location.updatedTitle": "La ubicación {locationName} ha sido actualizada",
  "actionNotifications.location.view": "Ver ubicación",
  "actionNotifications.notification.created": "La notificación ha sido creada",
  "actionNotifications.notification.deleted": "La notificación ha sido eliminada",
  "actionNotifications.notification.updated": "La notificación ha sido actualizada",
  "actionNotifications.outOfOfficeSetting.success": "Tu configuración de fuera de la oficina ha sido actualizada",
  "actionNotifications.scheduledReport.createdTitle": "El informe programado {scheduledReportName} ha sido creado",
  "actionNotifications.scheduledReport.deletedTitle": "El informe programado ha sido eliminado",
  "actionNotifications.scheduledReport.updatedTitle": "El informe programado {scheduledReportName} ha sido actualizado",
  "actionNotifications.scheduledReport.viewScheduledReport": "Ver informe programado",
  "actionNotifications.team.createdTitle": "El departamento {teamName} ha sido creado",
  "actionNotifications.team.deletedTitle": "El departamento ha sido eliminado",
  "actionNotifications.team.updatedTitle": "El departamento {teamName} ha sido actualizado",
  "actionNotifications.team.view": "Ver departamento",
  "addLeave.addLeave": "Añadir ausencia",
  "addLeave.handleSubmitErrorTitle": "Error al enviar ausencia",
  "addLeave.inProgress": "Añadiendo ausencia",
  "addLeave.noApproverUsers": "Si no formas parte de un departamento, no podrás añadir ausencias para ti mismo. Para solicitar una ausencia, incluso si no formas parte de un departamento para el que tienes derechos de aprobación, abre el formulario \"Solicitar ausencia\" y envía una solicitud de ausencia.",
  "addLeave.title": "Ausencia añadida",
  "addons.openApi.apiKeyName": "Nombre de la Clave API",
  "addons.openApi.apiKeyNamePlaceholder": "Nombre de la clave API",
  "addons.openApi.apiKeySecret": "Secreto de la Clave API",
  "addons.openApi.apiKeysInfo": "Genera y gestiona tus claves API.",
  "addons.openApi.apiKeysTitle": "Claves API",
  "addons.openApi.buyAppiAddon": "Comprar complemento API",
  "addons.openApi.buyOpenApiInfoPrice": "El complemento API es de pago y cuesta ${price}/mes/usuario activo. Comprarlo aumentará tu factura mensual en ${totalPaid} por tus {userCount} {userCount, plural, =1 {usuario activo} other {usuarios activos}} (te daremos una estimación precisa cuando hagas clic en el botón \"Comprar\").",
  "addons.openApi.buyOpenApiInfoPrice2line": "Después de comprar el complemento, tú o cualquier otro administrador de tu organización podrá generar y gestionar tu clave API.",
  "addons.openApi.buyOpenApiTitle": "Compra el complemento API para generar claves API",
  "addons.openApi.createInProgress": "Creación de Clave API en progreso",
  "addons.openApi.createInProgressCompleted": "Creación de Clave API completada",
  "addons.openApi.createNewApiKey": "Crear Nueva Clave API",
  "addons.openApi.currentMonthUsage": "Uso del Mes Actual",
  "addons.openApi.documentation": "Documentación API",
  "addons.openApi.documentationInfo": "La documentación API describe cómo funciona nuestra API y qué métodos están disponibles actualmente.",
  "addons.openApi.dontHaveApiKeys": "No tienes claves API, haz clic en el botón de abajo para crear una",
  "addons.openApi.dpenDocumentation": "Abrir Documentación",
  "addons.openApi.feedback": "Si tienes comentarios o necesitas datos que nuestra API no expone, <link>haz clic aquí para informarnos</link>.",
  "addons.openApi.limitRequestPerMonth": "Puedes enviar hasta 30000 solicitudes por mes",
  "addons.openApi.limitRequestPerSecound": "Las solicitudes están limitadas a 10 por segundo con un modo de ráfaga de 50 solicitudes/segundo",
  "addons.openApi.notificationDeleteConfirm": "Por favor, confirma que deseas eliminar la Clave API <strong>{name}</strong>.",
  "addons.openApi.notificationDeleteTitle": "Eliminar Clave API",
  "addons.openApi.openApiDeleted": "Clave API eliminada",
  "addons.openApi.quotasAndRateLimitsInfo": "Aquí están nuestras cuotas y límites de tasa de API por clave API:",
  "addons.openApi.quotasAndRateLimitsTitle": "Cuotas y límites de tasa",
  "addons.openApiInfo": "Nuestro complemento API te permite conectar Vacation Tracker a otras aplicaciones programáticamente. Actualmente, nuestra API permite acceso de solo lectura a tus usuarios, sus permisos, ubicaciones, departamentos, tipos de permiso y etiquetas.",
  "addonsPaymentStatus.activateForFree": "Activar gratis",
  "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Activo hasta que termine el período de facturación",
  "addonsPaymentStatus.canceled": "Cancelado",
  "addonsPaymentStatus.grantPeriod": "Período de concesión",
  "addonsPaymentStatus.paymentActive": "Pago activo",
  "ai.assistant.completedConversationTooltip": "Esta conversación ha terminado. No puedes enviar nuevos mensajes, pero puedes ver la conversación existente.",
  "ai.assistant.defaultMessage": "¿En qué puedo ayudarte?",
  "ai.assistant.disclaimer": "El Asistente de IA está en versión Beta. Puede cometer errores. En caso de errores o respuestas confusas, por favor <support>contacta con nuestro soporte</support>.",
  "ai.assistant.inputLockedTooltip": "Esta es una versión temprana de nuestro Asistente de IA. Actualmente, solo te ayuda con la incorporación. Sin embargo, pronto podrás iniciar nuevas conversaciones y hablar con el Asistente de IA de Vacation Tracker. ¡Gracias por tu paciencia!",
  "ai.assistant.lastMessageTimestampTooltip": "El último mensaje fue enviado el: {date}",
  "ai.assisted.onboarding.companyLogUserNameDecorator": "(a través del Asistente de IA)",
  "ai.assisted.onboarding.finalAssistantMessage": "Tu proceso de incorporación está completo. Vacation Tracker ahora configurará tu cuenta de empresa con esta configuración. Recuerda que siempre puedes hacer cambios en tu configuración más adelante si es necesario.",
  "ai.assisted.onboarding.initialAssistantMessage": "Mientras te registrabas, ya hemos configurado algunas cosas para ti:\n\nUbicaciones ({locations}), \nDepartamentos ({departments}), y \nTipos de ausencia ({leaveTypes}).\n\n¿Todos tus empleados trabajan en esta ubicación?",
  "ai.assisted.onboarding.initialUserMessage": "¡Hola! Soy tu Asistente de IA. Estoy aquí para ayudarte a comenzar con tu nueva cuenta de empresa.\n\nEsta configuración de IA es opcional, por supuesto. Puedes omitirla y configurar el sistema tú mismo. (Probablemente quieras comenzar en la sección \"Configuración\" de nuestro menú). Sin embargo, te guiaré a través de algunas partes esenciales de Vacation Tracker y lo configuraré todo por ti en unos minutos. No almacenamos ninguno de tus datos privados (pero por favor no compartas información sensible conmigo).",
  "ai.assisted.onboarding.noTopics": "¡Hey, parece que acabas de encontrar un huevo de Pascua! 🐣 Estamos trabajando en algo nuevo que debería ahorrarte mucho tiempo, pero aún no está listo. Así que, por favor, mantengamos esto como nuestro pequeño secreto. ¡Gracias!",
  "ai.assisted.onboarding.openSetupNotification": "Ver lo que se ha hecho hasta ahora",
  "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Gracias por proporcionar toda la información necesaria. Ahora estoy comenzando a crear tu configuración de Vacation Tracker. Comenzarás a ver actualizaciones en vivo en cualquier momento...",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "Tipo de ausencia {leaveTypeName} asignado a {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "Asignando tipo de ausencia {leaveTypeName} a {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} ha sido exitosamente {action}",
  "ai.assisted.onboarding.openSetupNotificationNoData": "No se pueden mostrar los datos de configuración",
  "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "No puedo mostrar los datos de configuración en este momento. Esto puede suceder debido a una breve interrupción de la red o porque el sistema aún no ha terminado de procesar los datos. Por favor, recarga esta página o verifica la configuración en la sección Ajustes del menú.",
  "ai.assisted.onboarding.openSetupNotificationTitle": "Estado de Configuración de Incorporación",
  "ai.assisted.onboarding.sendMessageError": "Parece que la respuesta del Asistente de IA está tardando demasiado. Por favor, recarga.",
  "ai.assisted.onboarding.summary.intro": "Ya está todo configurado. Ahora puedes abrir el panel de control y comenzar a usar la aplicación.",
  "ai.assisted.onboarding.summary.introSummary": "Aquí tienes el resumen de tu configuración:",
  "ai.assisted.onboarding.summary.leavePoliciesInfo": "Las políticas de ausencia son configuraciones de tipos de ausencia específicas para una ubicación. Para ver las políticas de ausencia creadas, ve a la página de ubicaciones, selecciona una ubicación y luego abre la pestaña de políticas de ausencia.",
  "ai.assisted.onboarding.summary.view.departments": "Ver Departamentos",
  "ai.assisted.onboarding.summary.view.leaveTypes": "Ver Tipos de Ausencia",
  "ai.assisted.onboarding.summary.view.locations": "Ver Ubicaciones",
  "app.12h": "12h",
  "app.24h": "24h",
  "app.aboutMe": "Sobre mí:",
  "app.accountPreferences": "Ver Preferencias",
  "app.accountSettings": "Configuración de la Cuenta",
  "app.accruals": "Acumulaciones",
  "app.actions": "Acciones",
  "app.activate": "Activar",
  "app.active": "Activos",
  "app.add": "Añadir",
  "app.addAvatar": "Añadir Avatar",
  "app.addLeave": "Añadir Ausencia",
  "app.addNewEmail": "Añadir Destinatario",
  "app.addons": "Complementos",
  "app.addToGoogleCalendar": "Calendario de Google",
  "app.addToMicrosoft365Calendar": "Calendario de Microsoft 365",
  "app.AIAssistant": "Asistente de IA",
  "app.all": "Todos",
  "app.allDay": "Todo el Día",
  "app.allowHalfDays": "Permitir Medios Días",
  "app.allowHourlyIncrements": "Permitir Incrementos por Hora",
  "app.and": "y",
  "app.announcementsHaveBeenPaused": "Tus anuncios han sido pausados durante 2 meses.",
  "app.applyTo": "Aplicar a",
  "app.approvalReason": "Motivo de aprobación",
  "app.approvalRequired": "Aprobación Requerida",
  "app.approve": "Aprobar",
  "app.approved": "Aprobado",
  "app.approveLeaveText": "Por favor, confirma que quieres aprobar la solicitud de ausencia.",
  "app.approveLeaveTitle": "Aprobar Solicitud de Ausencia",
  "app.april": "Abril",
  "app.areYouSure": "¿Estás seguro?",
  "app.askMeLater": "Pregúntame más tarde",
  "app.assignLeavePolicy": "Asignar Política de Ausencia",
  "app.august": "Agosto",
  "app.autoApproved": "Aprobado automáticamente",
  "app.autoDeleted": "<Eliminado Automáticamente>",
  "app.autoImport": "<Importación Automática>",
  "app.automations": "Automatizaciones",
  "app.avatar": "Avatar",
  "app.back": "Atrás",
  "app.backToMenu": "Volver al menú",
  "app.bi-weekly": "Quincenal",
  "app.billing.periodAnnual": "Anual",
  "app.billingStatus": "Estado de facturación",
  "app.billingStatus.ACTIVE": "Pago activo",
  "app.billingStatus.CANCEL_AT_PERIOD_END": "Activo hasta que finalice el período de facturación",
  "app.billingStatus.CANCELED": "Pago cancelado",
  "app.billingStatus.GRANT_PERIOD": "Período de concesión",
  "app.broughtForward": "Transferencia de Días No Utilizados",
  "app.by": "por",
  "app.calendar": "Calendario",
  "app.cancel": "Cancelar",
  "app.cancelLeave": "Cancelar permiso",
  "app.cancelLeaveConfirmText": "¿Estás seguro de que quieres cancelar este permiso programado?",
  "app.cancelled": "Cancelado",
  "app.change": "Cambiar",
  "app.changePassword": "Cambiar contraseña",
  "app.close": "Cerrar",
  "app.code": "Código",
  "app.color": "Color",
  "app.comingSoon": "Próximamente",
  "app.company": "Empresa",
  "app.companyLogs": "Registros de la empresa",
  "app.companyNotExist": "¡Ups! Parece que no completaste el registro con Vacation Tracker. Por favor, visita: app.vacationtracker.io para completar el registro.",
  "app.companyNotExist.description1": "Ahora has añadido el bot y la pestaña de Vacation Tracker a tu espacio de trabajo de Microsoft Teams.",
  "app.companyNotExist.description2": "Por favor, ve a nuestro sitio web para completar tu registro y configurar los ajustes de tu organización.",
  "app.companyNotExist.getStarted": "Completar registro",
  "app.completed": "Completado",
  "app.completeRegistration": "Completar registro",
  "app.configuration.moreInfo": "Para más información, visita nuestro servicio de ayuda: <link>https://vacationtracker.crisp.help/en/</link>",
  "app.configuration.option.overview": "Visión general",
  "app.configuration.options.info": "Selecciona el tipo de pestaña: ",
  "app.configuration.welcome": "¡Bienvenido a Vacation Tracker! Esta pestaña te permite solicitar permisos fácilmente sin salir de Microsoft Teams, gestionar tus permisos, comprobar el estado de tus solicitudes, ver quién está fuera de la oficina y ver los próximos días festivos para tu equipo.",
  "app.configure": "Configurar",
  "app.confirm": "Confirmar",
  "app.confirmNewPassword": "Confirmar nueva contraseña",
  "app.confirmPassword": "Confirmar contraseña",
  "app.consentRequired": "Se requiere el consentimiento del administrador de Microsoft Teams. Parece que no eres administrador de tu organización de Microsoft Teams. Para acceder a la pestaña de Vacation Tracker, necesitarás el consentimiento del administrador. Por favor, envía el siguiente enlace al administrador de tu organización para obtener el consentimiento del administrador: {link}",
  "app.consentRequired.title": "Se requiere el consentimiento del administrador de Microsoft Teams",
  "app.contactSupport": "Contactar con soporte",
  "app.continue": "Continuar",
  "app.copyright": "Copyright VacationTracker",
  "app.correlationIdError": "Se ha producido un error. Por favor, contacta con nuestro servicio de atención al cliente en hello@vacationtracker.io con el siguiente ID: {correlationId}",
  "app.country": "País",
  "app.create": "Crear",
  "app.created": "Creado",
  "app.createdAt": "Creado el",
  "app.createdBy": "Creado por",
  "app.createdSuccessfully": "Solicitud enviada",
  "app.creating": "Creando",
  "app.creditCard": "Tarjeta de crédito",
  "app.currentYear": "Año actual",
  "app.customDays": "Días personalizados",
  "app.customWorkHours": "Horas de trabajo personalizadas",
  "app.daily": "Diario",
  "app.dashboard": "Panel de control",
  "app.dateRange": "Fecha de inicio y fin",
  "app.day": "día",
  "app.days": "días",
  "app.december": "Diciembre",
  "app.delete": "Eliminar",
  "app.deleted": "Eliminado",
  "app.deleteFailed": "Error al eliminar",
  "app.deleteUser": "Eliminar usuario",
  "app.deleting": "Eliminando",
  "app.deliveryFailed": "Entrega fallida",
  "app.denialReason": "Motivo de denegación",
  "app.denied": "Denegado",
  "app.deny": "Denegar",
  "app.denyLeaveRequest": "Denegar solicitud de ausencia",
  "app.denyLeaveRequestText": "Por favor, confirme que desea denegar la solicitud de ausencia.",
  "app.denyWithReason": "Denegar con motivo",
  "app.department": "Departamento",
  "app.departments": "Departamentos",
  "app.departmentsSub": "Subdepartamentos",
  "app.deselectAll": "Deseleccionar todo",
  "app.displayDaysHoursTooltip.days": "Eso son {value} días",
  "app.displayDaysHoursTooltip.hours": "Eso son {value} horas",
  "app.documentation": "Documentación",
  "app.done": "Hecho",
  "app.dontSend": "No enviar",
  "app.downgradeWithoutUnsubscribe": "Bajar de categoría sin cancelar la suscripción",
  "app.downgradeWithSubscribe": "Bajar de categoría y cancelar la suscripción",
  "app.download": "Descargar",
  "app.downloadProgressReport": "Creando su archivo, esto puede tardar un momento...",
  "app.downloadReady": "Su archivo está listo. Haga clic aquí para descargarlo.",
  "app.downloadTemplate": "Descargar plantilla",
  "app.edit": "Editar",
  "app.edited": "Editado",
  "app.editLeave": "Editar ausencia",
  "app.email": "Correo electrónico",
  "app.emailDeleted": "Correo electrónico eliminado",
  "app.employeeEndDate": "Fecha de finalización del empleado",
  "app.enable": "Habilitar",
  "app.enabled": "Habilitado",
  "app.enterWorkEmail": "Introduzca su correo electrónico de trabajo",
  "app.error": "Error",
  "app.every": "cada",
  "app.expired": "Expirado",
  "app.expires": "Expira",
  "app.export": "Exportar",
  "app.export.comingSoon": "Nuestro equipo está puliendo esta función. Deberías esperarla pronto.",
  "app.feature.access": "Disponible exclusivamente en el plan Completo.",
  "app.february": "Febrero",
  "app.firstAndLastName": "Nombre y Apellido",
  "app.friday": "Viernes",
  "app.friday.short": "Vie",
  "app.full-day": "Día Completo",
  "app.full-name": "Nombre Completo",
  "app.general": "General",
  "app.goodAfternoon": "Buenas tardes",
  "app.goodEvening": "Buenas noches",
  "app.goodMorning": "Buenos días",
  "app.gotIt": "Entendido",
  "app.half-day": "Medio día",
  "app.halfDay": "Medio Día",
  "app.halfDayInParenthesis": " (Medio Día)",
  "app.happyVacationing": "¡Felices vacaciones! 🌴",
  "app.helpdesk": "Mesa de ayuda",
  "app.holidays": "Días festivos",
  "app.home": "Inicio",
  "app.hour": "hora",
  "app.hours": "horas",
  "app.import": "Importar",
  "app.importFile": "Importar Archivo",
  "app.importFormat": "Formato de Importación",
  "app.importLeaves": "Importar Ausencias",
  "app.importLeaves.alerts.confirmClose.bodyText": "¿Estás seguro? Tu información actual no se guardará.",
  "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "Cancelar",
  "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Salir",
  "app.importLeaves.alerts.confirmClose.headerTitle": "Salir del flujo de importación",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "Aún hay algunas filas que contienen errores. Las filas con errores se ignorarán al enviar.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "Aún hay algunas filas que contienen errores. Por favor, elimina las filas inválidas o corrige los errores e intenta de nuevo.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "Cancelar",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Enviar",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Errores Detectados",
  "app.importLeaves.alerts.toast": "Error",
  "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "Hay columnas requeridas que no están emparejadas o se han ignorado. Debes emparejar todas las columnas requeridas para continuar.",
  "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "Cancelar",
  "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Continuar",
  "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "No todas las columnas están emparejadas",
  "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Columnas requeridas no emparejadas:",
  "app.importLeaves.downloadTemplateFileTooltip": "Aquí puedes descargar nuestro archivo de plantilla de importación, que sirve como ejemplo para llenar los datos que deseas importar. Al crear tu archivo de importación, asegúrate de que contenga una columna de encabezado para que el sistema reconozca tus datos correctamente.<br></br><br></br>Verifica que tus datos coincidan con la plantilla para asegurar una importación exitosa. Para instrucciones adicionales, no dudes en contactar a nuestro equipo de soporte.",
  "app.importLeaves.dropzone.activeDropzoneTitle": "Suelta el archivo aquí",
  "app.importLeaves.dropzone.buttonTitle": "Seleccionar archivo",
  "app.importLeaves.dropzone.errorToastDescription": "Sube un archivo .xlsx, .xls o .csv",
  "app.importLeaves.dropzone.loadingTitle": "Procesando...",
  "app.importLeaves.dropzone.title": "Sube un archivo .xlsx, .xls o .csv",
  "app.importLeaves.errors.dateFromAfterDateTo": "La Fecha Desde es posterior a la Fecha Hasta",
  "app.importLeaves.errors.dateFromNotValid": "La fecha de inicio no es válida",
  "app.importLeaves.errors.dateFromRequired": "La fecha de inicio es obligatoria",
  "app.importLeaves.errors.dateToNotValid": "La fecha de finalización no es válida",
  "app.importLeaves.errors.dateToRequired": "La fecha de finalización es obligatoria",
  "app.importLeaves.errors.halfDay": "El permiso es mayor a un día",
  "app.importLeaves.errors.hourFromAfterHourTo": "La hora de inicio es posterior a la hora de finalización",
  "app.importLeaves.errors.hourFromNotValid": "La hora de inicio no es válida, ingrese un valor entre 0 y 24",
  "app.importLeaves.errors.hourFromRequired": "La hora de inicio es obligatoria",
  "app.importLeaves.errors.hourRange": "Las horas seleccionadas exceden la duración de la jornada laboral de este usuario ({hours} horas). Por favor, ajuste su selección.",
  "app.importLeaves.errors.hourToNotValid": "La hora de finalización no es válida, ingrese un valor entre 0 y 24",
  "app.importLeaves.errors.hourToRequired": "La hora de finalización es obligatoria",
  "app.importLeaves.errors.leavePolicyError": "La política de permisos solo permite días completos",
  "app.importLeaves.errors.leaveTypeNotFound": "Tipo de permiso no encontrado",
  "app.importLeaves.errors.leaveTypeNotInUserLocation": "El tipo de permiso no está en la ubicación del usuario",
  "app.importLeaves.helpdesk": "Para obtener orientación detallada sobre cómo importar sus archivos, consulte las instrucciones paso a paso disponibles en <helpdesk>la página de ayuda</helpdesk>.",
  "app.importLeaves.importFormatTooltip": "Seleccione el formato de importación que coincida con su tipo de datos y preferencias.",
  "app.importLeaves.leaveStatusWarning": "El estado del permiso se establecerá como \"Aprobado\" para todos los permisos que importe.",
  "app.importLeaves.manifestDescription": "(Tendrá la oportunidad de renombrar o eliminar columnas en los siguientes pasos)",
  "app.importLeaves.manifestTitle": "Datos que esperamos:",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "Las columnas no pueden duplicarse",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "Otra columna deseleccionada",
  "app.importLeaves.matchColumnsStep.ignoredColumnText": "Columna ignorada",
  "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Emparejar",
  "app.importLeaves.matchColumnsStep.nextButtonTitle": "Siguiente",
  "app.importLeaves.matchColumnsStep.selectPlaceholder": "Seleccionar columna...",
  "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Seleccionar...",
  "app.importLeaves.matchColumnsStep.templateTitle": "Se convertirá en",
  "app.importLeaves.matchColumnsStep.title": "Emparejar Columnas",
  "app.importLeaves.matchColumnsStep.unmatched": "Sin emparejar",
  "app.importLeaves.matchColumnsStep.userTableTitle": "Su tabla",
  "app.importLeaves.maxRecordsExceeded": "Demasiados registros. Se permiten hasta {maxRecords}",
  "app.importLeaves.nextButtonTitle": "Siguiente",
  "app.importLeaves.selectHeaderStep.title": "Seleccionar fila de encabezado",
  "app.importLeaves.selectSheet.title": "Seleccionar la hoja a utilizar",
  "app.importLeaves.validationStep.discardButtonTitle": "Descartar filas seleccionadas",
  "app.importLeaves.validationStep.filterSwitchTitle": "Mostrar solo filas con errores",
  "app.importLeaves.validationStep.nextButtonTitle": "Confirmar",
  "app.importLeaves.validationStep.noRowsMessage": "No se encontraron datos",
  "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "No hay datos que contengan errores",
  "app.importLeaves.validationStep.title": "Validar datos",
  "app.importType": "Tipo de importación",
  "app.integrations": "Integraciones",
  "app.invite": "Invitar",
  "app.january": "Enero",
  "app.july": "Julio",
  "app.june": "Junio",
  "app.label": "Etiqueta",
  "app.labels": "Etiquetas",
  "app.lastMonth": "Mes pasado",
  "app.learnMore": "Aprender más",
  "app.leave": "Ausencia",
  "app.leaveBalanceReport": "Informe de Saldo de Ausencias",
  "app.leaveCanceled": "Ausencia Cancelada",
  "app.leaveHistory": "Historial de Ausencias",
  "app.leaveHistoryReport": "Informe de Solicitudes de Ausencias",
  "app.leavePolicies": "Políticas de Ausencias",
  "app.leaveRequestCalendarStatusLabel": "Mostrar en tu calendario como ",
  "app.leaveRequestCreated": "Solicitud de Ausencia Creada",
  "app.leaveRequests": "Solicitudes de Ausencias",
  "app.leaveType": "Tipo de Ausencia",
  "app.leaveTypes": "Tipos de Ausencias",
  "app.link": "Enlace",
  "app.load": "Cargar",
  "app.loading": "Cargando",
  "app.loadMore": "Cargar más",
  "app.location": "Ubicación",
  "app.locations": "Ubicaciones",
  "app.login": "Iniciar sesión",
  "app.logInWithMicrosoft": "Iniciar sesión con Microsoft",
  "app.logout": "Cerrar sesión",
  "app.logoutNow": "Cerrar sesión ahora",
  "app.logs": "Registros",
  "app.manageLabels": "Gestionar Etiquetas",
  "app.march": "Marzo",
  "app.may": "Mayo",
  "app.maybe": "Quizás",
  "app.message": "mensaje",
  "app.meta.description": "Amado por más de 1,000 empresas en todo el mundo, Vacation Tracker hace que gestionar tu PTO sea notoriamente fácil. Disponible para Slack, Microsoft Teams y Google Workspace.",
  "app.meta.title": "Vacation Tracker - Solución de seguimiento de ausencias apta para trabajo remoto",
  "app.mfa": "Autenticación de múltiples factores",
  "app.mfaCode": "Código",
  "app.mfaCodeErrorGeneric": "Error al validar el código",
  "app.mfaCodeErrorMissmatch": "Código no coincide",
  "app.mfaCodeErrorNetwork": "Error de red",
  "app.mfaCodePlaceholder": "Por favor, ingresa el código mostrado en tu aplicación de autenticación",
  "app.mfaDisableAnswerNo": "No, mantener MFA",
  "app.mfaDisableAnswerYes": "Sí, desactivar MFA",
  "app.mfaDisableError": "Error al desactivar MFA. Por favor, inténtalo de nuevo más tarde.",
  "app.mfaDisableSuccess": "Has desactivado MFA con éxito.",
  "app.mfaDisableWarning": "¿Estás seguro de que quieres desactivar MFA?",
  "app.mfaDisableWarningTitle": "Desactivar la autenticación de múltiples factores",
  "app.mfaEnableError": "Error al activar MFA. Por favor, inténtalo de nuevo más tarde.",
  "app.mfaEnableSuccess": "Has configurado MFA con éxito.",
  "app.mfaEnterCode": "Ingresa el código",
  "app.mfaLabel": "Activar MFA",
  "app.mfaLoginFailed": "Inicio de sesión fallido",
  "app.mfaSecretKey": "O ingresa la clave secreta manualmente:",
  "app.mfaSubTitle": "Escanea el siguiente código QR en tu aplicación MFA (por ejemplo, Authy o Google Authenticator):",
  "app.mfaTitle": "Activar la autenticación de múltiples factores",
  "app.mfaTooltip": "Una vez que hayas añadido Vacation Tracker a tu aplicación MFA, espera a que genere el código e ingrésalo a continuación:",
  "app.microsoft.manageLicenses": "Gestionar licencias",
  "app.microsoftTeams": "Microsoft Teams",
  "app.monday": "Lunes",
  "app.monday.short": "Lun",
  "app.monthly": "Mensual",
  "app.monthlyLeaveBalanceReport": "Informe Mensual de Saldo de Ausencias",
  "app.moreDetails": "Más Detalles",
  "app.myProfile": "Mi Perfil",
  "app.myStatus": "Mi estado",
  "app.name": "Nombre",
  "app.newPassword": "Nueva Contraseña",
  "app.next": "Siguiente",
  "app.nextInvoice": " Su próxima factura será de {amount} (sin IVA) el {startDate}",
  "app.nextMonth": "Próximo mes",
  "app.nextYear": "Próximo Año",
  "app.no": "No",
  "app.noLeaves": "Sin ausencias",
  "app.none": "Ninguno",
  "app.nonWorkingDay": "Día no laborable",
  "app.notDone": "No realizado",
  "app.note": "Nota",
  "app.notificationError": "¡Oh no! 😕 Algo salió mal. ",
  "app.notifications": "Notificaciones",
  "app.notSet": "No establecido",
  "app.notValidEmail": "Por favor, introduzca una dirección de correo electrónico válida.",
  "app.november": "Noviembre",
  "app.october": "Octubre",
  "app.of": "de",
  "app.ofThat": "de eso",
  "app.ok": "Ok",
  "app.oldPassword": "Contraseña Antigua",
  "app.one-hour": "1 hora",
  "app.openCalendar": "Abrir Calendario",
  "app.openDashboard": "Abrir panel",
  "app.openWebDashboard": "Abrir Panel Web",
  "app.or": "O",
  "app.password": "Contraseña",
  "app.passwordAndSecurity": "Contraseña y Seguridad",
  "app.pastDue": "Debido a un pago fallido, su acceso a Vacation Tracker se cerrará pronto. Si desea continuar usando Vacation Tracker, por favor <a>introduzca una tarjeta válida</a>.",
  "app.pending": "Pendiente",
  "app.pendingLeaveRequests": "Solicitudes de Ausencia Pendientes",
  "app.pleaseSelect": "Por favor seleccione",
  "app.prev": "Anterior",
  "app.previous": "Anterior",
  "app.proceed": "Proceder",
  "app.processingRequest": "Procesando solicitud...",
  "app.profilePicture": "Foto de Perfil",
  "app.reason": "Motivo",
  "app.recipients": "Destinatarios",
  "app.recommended": "recomendado",
  "app.reconnect": "Reconectar",
  "app.reload": "Recargar",
  "app.removed": "eliminado",
  "app.rename": "Renombrar",
  "app.reportType": "Tipo de informe",
  "app.requestLeave": "Solicitar permiso",
  "app.requestToil": "Solicitar tiempo libre compensatorio",
  "app.required": "Obligatorio",
  "app.resendCode": "Reenviar código",
  "app.resent": "Reenviado",
  "app.reset": "Restablecer",
  "app.resetPassword": "Restablecer contraseña",
  "app.reviewAndConfirm": "Revisar y confirmar",
  "app.role.administrator": "Administrador",
  "app.role.approver": "Aprobador",
  "app.role.approvers": "Aprobadores",
  "app.saturday": "Sábado",
  "app.saturday.short": "Sáb",
  "app.save": "Guardar",
  "app.scheduledLeaves": "Permisos programados",
  "app.scheduledReports": "Informes programados",
  "app.seeInCalendar": "🗓️ Ver en el calendario",
  "app.seeMoreOptions": "Ver más opciones",
  "app.select": "Seleccionar",
  "app.selectAll": "Seleccionar todo",
  "app.semi-monthly": "Bimensual",
  "app.send": "Enviar",
  "app.sendNow": "Enviar ahora",
  "app.september": "Septiembre",
  "app.set": "establecer",
  "app.setOn": "establecido en",
  "app.shortestLeaveDuration": "Duración mínima del permiso",
  "app.showLess": "Mostrar menos",
  "app.showMore": "Mostrar más",
  "app.skip": "Omitir",
  "app.slack": "Slack",
  "app.snooze": "Posponer",
  "app.snoozeForMonths": "Posponer por 2 meses",
  "app.startFreeTrial": "Comienza tu prueba gratuita de 7 días",
  "app.state": "Estado",
  "app.status": "Estado",
  "app.submit": "Enviar",
  "app.substituteApprover": "Aprobador sustituto",
  "app.substituteApproverSet": "Has sido establecido como Aprobador sustituto.",
  "app.sunday": "Domingo",
  "app.sunday.short": "Dom",
  "app.switch": "Cambiar",
  "app.sync": "Sincronizar",
  "app.takeQuickTour": "Hacer un recorrido rápido",
  "app.text": "Texto",
  "app.thisMonth": "Este mes",
  "app.thisWeek": "Esta semana",
  "app.thursday": "Jueves",
  "app.thursday.short": "Jue",
  "app.time": "Hora",
  "app.timeOffInLieu": "Tiempo libre compensatorio",
  "app.timeOffInLieuTooltip": "¿Quieres editar el tiempo libre compensatorio? <helpDesk>Aprende cómo hacerlo</helpDesk>",
  "app.timezone": "Zona horaria",
  "app.today": "Hoy",
  "app.toil": "TOIL",
  "app.trialExpired": "La versión de prueba expira en {days} {daysInfo}. Si te gusta, por favor <a>suscríbete</a> para continuar usando Vacation Tracker después de que termine el período de prueba.",
  "app.trialExtend": "¿Necesitas una extensión del período de prueba?",
  "app.trialPeriod": "Período de prueba",
  "app.tryAgain": "Intentar de nuevo",
  "app.tryLateOrContactSupport": "Algo salió mal. Por favor, intenta más tarde o contacta a nuestro servicio de atención al cliente en hello@vacationtracker.io con el siguiente ID: {code}",
  "app.tuesday": "Martes",
  "app.tuesday.short": "Mar",
  "app.type": "Tipo",
  "app.unknown": "<Desconocido>",
  "app.unlimited": "Ilimitado",
  "app.update": "Actualizar",
  "app.updated": "actualizado",
  "app.updatedInProgress": "Procesando respuesta",
  "app.updatedSuccessfully": "Solicitud enviada.",
  "app.updating": "Actualizando",
  "app.upload": "Subir",
  "app.user": "Usuario",
  "app.users": "Usuarios",
  "app.users.role": "Rol",
  "app.via": "vía",
  "app.view": "Ver",
  "app.viewAll": "Ver todo",
  "app.visitHelpdesk": "Visitar el Centro de ayuda",
  "app.wallchart": "Gráfico mural",
  "app.was": "era",
  "app.watchVideo": "Ver video",
  "app.wednesday": "Miércoles",
  "app.wednesday.short": "Mié",
  "app.weekly": "Semanal",
  "app.willBe": "será",
  "app.workday": "Duración de la jornada laboral",
  "app.workdayWarning": "La opción de medio día de permiso solo está disponible para una jornada laboral de 8 horas. Cambiar la jornada laboral estándar de 8 horas deshabilitará esta opción.",
  "app.workweek": "Semana laboral",
  "app.year": "Año",
  "app.years": "Años",
  "app.yes": "Sí",
  "automation.feedback.thankYou": "Gracias por compartir tus pensamientos con nosotros. Tu aporte impulsa nuestro crecimiento constante.",
  "automations.activateInProgress": "En progreso - Activación",
  "automations.activateInProgressDescription": "Tu solicitud para activar la automatización está actualmente en progreso. Estamos trabajando en habilitar esta función para ti.",
  "automations.add": "Crear automatización",
  "automations.addAddonsDescirptionForAdmin": "¿Proceder con la compra del complemento seleccionado? El monto será prorrateado a ${prorateAmount}, con su próxima factura totalizando ${nextInvoiceAmount}. Haga clic en 'Confirmar' para finalizar.",
  "automations.addAddonsDescirptionForAdminInTrial": "Este complemento se facturará en su próxima factura: ${total} basado en {userCount} usuarios a ${addonsPrice} por complemento. Durante el período de prueba, puede activarlo y desactivarlo libremente sin cargos si no hay complementos activos al final.",
  "automations.addAddonsDescirptionForApprover": "Solo los usuarios de nivel Administrador tienen la capacidad de comprar o darse de baja de los complementos. Si se necesitan ajustes en sus suscripciones de complementos, comuníquese con su administrador.",
  "automations.addFreeAddonsDescirptionForAdmin": "Este complemento está incluido sin costo adicional en su plan actual. Puede activarlo y desactivarlo libremente durante la suscripción de su plan actual sin cargos adicionales.",
  "automations.addpnSuccessfullyAdded": "Completado - Activación",
  "automations.addpnSuccessfullyAddedDescription": "La automatización se ha activado con éxito. Ahora puede usar todas sus características y funcionalidades.",
  "automations.addpnSuccessfullyDeactivated": "Completado - Desactivación",
  "automations.addpnSuccessfullyDeactivatedDescription": "La automatización se ha desactivado con éxito. Ya no estará activa en su sistema.",
  "automations.ADP_INTEGRATION": "Integración con ADP",
  "automations.anythingMissing": "¿Falta algo?",
  "automations.automationBlackoutPeriodDeleted": "Período de bloqueo eliminado",
  "automations.automationEntitlementByRoleDeleted": "Derecho por rol eliminado",
  "automations.automationProbationPeriodDeleted": "Período de prueba eliminado",
  "automations.automationSeniorityEntitlementDeleted": "Derecho por antigüedad eliminado",
  "automations.automationType": "Tipo de automatización",
  "automations.BAMBOOHR_INTEGRATION": "Integración con BambooHR",
  "automations.BLACKOUT_PERIOD": "Período de Bloqueo",
  "automations.blackoutPeriod.autoRejectReasonDefault": "Durante nuestro período actual de alto volumen, todo nuestro equipo es esencial para mantener las operaciones. Desafortunadamente, no podemos aprobar tiempo libre ya que afectaría nuestros objetivos comerciales y compromisos con los clientes.",
  "automations.blackoutPeriod.createInProgress": "Creación de período de bloqueo en progreso",
  "automations.blackoutPeriod.createInProgressCompleted": "Creación de período de bloqueo completada",
  "automations.blackoutPeriod.updateInProgress": "Actualización de período de bloqueo en progreso",
  "automations.blackoutPeriod.updateInProgressCompleted": "Actualización de período de bloqueo completada",
  "automations.blackoutPeriodFromTo": "Período de bloqueo del {fromDate} <endDate>al {toDate}</endDate>",
  "automations.cantOpenAutomations": "Esta automatización fue creada por otro usuario y no puede editarla.",
  "automations.createAutomation": "Crear automatización",
  "automations.datePeriod": "{fromDate} <endDate>y {toDate}</endDate><recurring> cada año</recurring>",
  "automations.deactivateInPgoress": "En Progreso - Desactivación",
  "automations.deactivateInPgoressDescription": "Su solicitud para desactivar la automatización está siendo procesada. Por favor, espere mientras actualizamos su configuración",
  "automations.deactivateVisibilityWarn": "Deshabilitar una automatización no cancelará la suscripción. Si desea desactivar la suscripción, debe hacer clic en el botón \"Cancelar suscripción\".",
  "automations.deleteInProgress": "Eliminando la automatización {automationName}",
  "automations.departmentVisibilityLevelInfo": "Los usuarios verán información de ausencias solo dentro de su propio departamento. Los aprobadores verán información de ausencias dentro de su propio departamento y cualquier departamento del que sean aprobadores, incluso si no son miembros. Los administradores siempre verán todo.",
  "automations.discoveryMoreAddons": "Descubrir Más Complementos",
  "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Restricción de Ausencias en Empleo Temprano",
  "automations.emptyViewButton": "Ver complementos disponibles",
  "automations.emptyViewMessage": "Agregue días de PTO basados en antigüedad, cree períodos de bloqueo, automatice el proceso de aprobación o rechazo, y mucho más con nuestras automatizaciones, complementos e integraciones.",
  "automations.emptyViewTitle": "Automatice sus tareas que consumen tiempo e intégrese con sistemas externos",
  "automations.ENTITLEMENT_BY_ROLE": "Derecho por Rol",
  "automations.entitlementByRole.createInProgress": "Creación de derecho por rol en progreso",
  "automations.entitlementByRole.createInProgressCompleted": "Creación de derecho por rol completada",
  "automations.entitlementByRole.days": "Días",
  "automations.entitlementByRole.updateInProgress": "Actualización de derecho por rol en progreso",
  "automations.entitlementByRole.updateInProgressCompleted": "Actualización de derecho por rol completada",
  "automations.LEAVE_DURATION_LIMIT": "Límite de Duración de Ausencia",
  "automations.message": "Mensaje",
  "automations.messagePlaceholder": "Su mensaje",
  "automations.myAddons": "Mis Complementos",
  "automations.OPEN_API": "API Abierta",
  "automations.OPEN_API_DOCUMENTATION": "Documentación de API Abierta",
  "automations.period": "Período",
  "automations.PROBATION_PERIOD": "Período de Prueba",
  "automations.probationPeriod.autoRejectReasonDefault": "Su solicitud de ausencia ha sido rechazada automáticamente porque se encuentra en su fase de empleo temprano, un período en el que no se permite tomar ausencias. Esta política ayuda a los nuevos empleados a sumergirse completamente y adaptarse a sus nuevos roles y dinámicas de equipo.",
  "automations.probationPeriod.createInProgress": "Creación de período de prueba en progreso",
  "automations.probationPeriod.createInProgressCompleted": "Creación de período de prueba completada",
  "automations.probationPeriod.lengthDays": "Duración (días)",
  "automations.probationPeriod.name": "Nombre de la Política de Prueba",
  "automations.probationPeriod.namePlaceholder": "Ingrese el nombre de la política de prueba",
  "automations.probationPeriod.settings.applyToMDescription": "Puede elegir si desea aplicar esta automatización universalmente a todos los usuarios o personalizarla según departamentos, ubicaciones o etiquetas específicas.",
  "automations.probationPeriod.settings.applyToMDescriptionTwo": "También puede seleccionar uno o más tipos de permiso a los que le gustaría aplicar esta automatización.",
  "automations.probationPeriod.settings.lengthDaysDescription": "Ingrese el número de días durante los cuales los nuevos empleados tendrán restricciones para tomar tipos de permisos específicos, contando desde su fecha de inicio de empleo.",
  "automations.probationPeriod.settings.nameDescription": "Esta sección le permite establecer una política que restringe a los nuevos empleados de tomar permisos durante su período inicial de empleo.",
  "automations.probationPeriod.settings.nameDescriptionThree": "Asegúrese de que los usuarios reciban un mensaje automático explicando por qué su solicitud de permiso fue denegada.",
  "automations.probationPeriod.settings.nameDescriptionTwo": "Ingrese el número de días y calcularemos el período de prueba basado en la fecha de empleo de cada usuario.",
  "automations.probationPeriod.settings.nameTitle": "Definir Política",
  "automations.probationPeriod.updateInProgress": "Actualización del período de prueba en progreso",
  "automations.probationPeriod.updateInProgressCompleted": "Actualización del período de prueba completada",
  "automations.probationPeriodMessages": "Su período de prueba dura hasta {date}",
  "automations.removeAddons": "Eliminar complementos",
  "automations.removeAddonsDescirptionForAdmin": "¿Está seguro de que desea cancelar la suscripción a este complemento? Mantendrá acceso a todas sus funciones hasta el final de su período de facturación actual. Después de eso, se desactivará, afectando su configuración de gestión de permisos en consecuencia.",
  "automations.removeAddonsDescirptionForApprover": "Solo los usuarios de nivel Administrador tienen la capacidad de comprar o cancelar suscripciones de complementos. Si se necesitan ajustes en sus suscripciones de complementos, por favor contacte a su administrador.",
  "automations.requestAnAutomation": "Solicitar un Complemento",
  "automations.requestAutomationTitle": "¿No encuentra el Complemento que necesita?",
  "automations.reSubscribeAddOns": "Reactivar suscripción al Complemento",
  "automations.reSubscribeAddons": "Volver a suscribirse al Complemento",
  "automations.reSubscribeAddonsDescirptionForAdmin": "¿Está seguro de que desea reactivar la suscripción a este Complemento? Se le facturará ${addonsPrice} por usuario por mes a partir de su próxima factura. El cargo del primer mes se prorrateará según el número de días que se use el complemento.",
  "automations.reSubscribeAddonsDescirptionForAdminInTrial": "¿Está seguro de que desea reactivar la suscripción a este Complemento? Permanecerá gratuito hasta el final del período de prueba. Después de eso, se aplicará un cargo de ${addonsPrice} por usuario por mes.",
  "automations.reSubscribeInfo": "Renueve su suscripción al complemento y continúe usando nuestras funciones avanzadas. Haga clic en Reactivar suscripción para proceder.",
  "automations.SENIORITY_ENTITLEMENT": "Derecho por Antigüedad",
  "automations.seniorityEntitlement.createInProgress": "Creación del derecho por antigüedad en progreso",
  "automations.seniorityEntitlement.createInProgressCompleted": "Creación del derecho por antigüedad completada",
  "automations.seniorityEntitlement.periodsViewTable": "{days} días después de {years} años",
  "automations.seniorityEntitlement.updateInProgress": "Actualización del derecho por antigüedad en progreso",
  "automations.seniorityEntitlement.updateInProgressCompleted": "Actualización del derecho por antigüedad completada",
  "automations.showCaneledAddons": "Mostrar complementos cancelados",
  "automations.stepByStepHelpDesk": "Para obtener una guía detallada sobre cómo configurar su Automatización <automationName></automationName>, consulte las instrucciones paso a paso disponibles en <link>la página de ayuda</link>.",
  "automations.subscripteToAddOns": "Suscribirse a complementos",
  "automations.subscripteToAddons": "Suscribirse a complementos",
  "automations.TEAM_LEAVE_BALANCING": "Equilibrio de Permisos del Equipo",
  "automations.unsubscribeForFreeAddonsInfo": "Actualmente está suscrito a este complemento sin costo adicional ya que está incluido en su plan. Si desea cancelar la suscripción, haga clic en el botón de cancelar suscripción a la derecha.",
  "automations.unsubscribeInfo": "Actualmente está suscrito a este complemento por ${addonsPrice} por usuario. Si desea cancelar la suscripción, haga clic en el botón de cancelar suscripción a la derecha.",
  "automations.unsubscripteAddOns": "¿Cancelar suscripción al Complemento?",
  "automations.unsubscripteAddons": "¿Cancelar suscripción al Complemento?",
  "automations.update": "Actualizar automatizaciones",
  "automations.VISIBILITY": "Administrador de Visibilidad",
  "automations.visibility.createInProgress": "Limitación de visibilidad en progreso",
  "automations.visibility.createInProgressCompleted": "Limitación de visibilidad completada",
  "automations.visibility.updateInProgress": "Actualizando complemento de visibilidad",
  "automations.visibility.updateInProgressCompleted": "Actualización del complemento de visibilidad completada",
  "automations.visibilityForm.DEPARTMENT": "Su departamento",
  "automations.visibilityForm.limitVisibility": "Limitar visibilidad para usuarios",
  "automations.visibilityForm.visibilityLevel": "Los usuarios pueden ver",
  "automations.visibilityForm.visibilityTypeValidationRequired": "Debe seleccionar al menos un tipo de visibilidad",
  "automations.visibilityInfo.settings.nameTitle": "Configuración de Visibilidad",
  "automations.visibilityManagerNotificationsInfo": "Los Calendarios Compartidos y Notificaciones creados antes del cambio en la configuración de visibilidad deben ser eliminados manualmente si no cumplen con las nuevas reglas.",
  "automationsForm.advancedFilter.tooltipInfo": "Puede filtrar los usuarios a los que se aplicará esta automatización según departamentos, ubicaciones o etiquetas.",
  "automationsForm.applyToAllUsers": "¿Aplicar a todos los usuarios?",
  "automationsForm.applyToAllUsersTooltip": "Por favor, elija si desea aplicar esta Automatización universalmente a todos los usuarios o personalizarla según departamentos, ubicaciones o etiquetas específicas.",
  "automationsForm.autoRejectReason": "Respuesta de rechazo automático",
  "automationsForm.autoRejectReasonTooltip": "Proporciona al usuario una respuesta automática sobre por qué su solicitud de permiso ha sido rechazada durante este período.",
  "automationsForm.blackoutPeriodShortText": "Un período de tiempo en el que se prohíbe a los empleados tomar vacaciones",
  "automationsForm.period": "Período",
  "automationsForm.recurring": "Repetir anualmente",
  "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "¿Quieres aplicar este derecho por antigüedad a todos los usuarios de tu empresa o a los usuarios en una ubicación específica? Si tienes diferentes reglas de derecho por antigüedad en diferentes ubicaciones, debes crear múltiples reglas de automatización de derecho por antigüedad, una para cada ubicación.",
  "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Inicio del año fiscal",
  "automationsForm.seniorityEntitlement.employeeStartDate": "Fecha de inicio del empleado",
  "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "Fin del año fiscal",
  "billing.applyingCoupon": "Aplicando cupón",
  "billing.applyingCouponAndResubscribing": "Aplicando cupón y reactivando suscripción",
  "billing.cancelSubscription": "Cancelar suscripción",
  "billing.cancelTrial": "Tu período de prueba permanecerá activo hasta el {stripeCurrentPeriodEnd}. Para continuar usando Vacation Tracker, por favor ingresa tu información de facturación.",
  "billing.cancelTrialButton": "Cancelar prueba",
  "billing.cancelUpdateInProgress": "Cancelando...",
  "billing.linkPaymentMethod": "Enlace de Stripe",
  "billing.microsoft.billedLabel": "Facturado",
  "billing.microsoft.billingCycle": "El ciclo de facturación actual termina el {date}",
  "billing.microsoft.billingCycleEnds": "Tu suscripción se cancelará después de que termine el ciclo de facturación y ya no podrás usar Vacation Tracker.",
  "billing.microsoft.billingCycleLabel": "Ciclo de facturación",
  "billing.microsoft.buyLessLicensesConfirmModalContent": "Tienes {currentNoOfLicenses} licencias. Estás a punto de eliminar {additionalLicences}. ¿Estás seguro?",
  "billing.microsoft.buyMoreLicensesConfirmModalContent": "Tienes {currentNoOfLicenses} licencias. Estás a punto de comprar {additionalLicences} más. ¿Estás seguro?",
  "billing.microsoft.canceledSubscriptionMessage": "Tu suscripción se cancelará en ~15 minutos",
  "billing.microsoft.changePlanModalTitle": "Cambiar el plan actual <strong>{plan}</strong>",
  "billing.microsoft.info": "Tu información de pago es gestionada por Microsoft. Visita el <adminCenterLink>Centro de administración</adminCenterLink> para actualizar tu {labelInfo}",
  "billing.microsoft.licensesSideActionLabel": "Ver historial",
  "billing.microsoft.licensesTitle": "Licencias",
  "billing.microsoft.pricingPlanLabel": "Plan de precios",
  "billing.microsoft.switchPlanModal.confirm": "Por favor, confirma que quieres cambiar a este plan.",
  "billing.paymentInformation": "Información de pago",
  "billing.paymentMethod": "Método de pago",
  "billing.pricingPlanPeriodAnnual": "Anual",
  "billing.pricingPlanPeriodMonthly": "Mensual",
  "billing.pricingPlansText": "Esta sección muestra tu plan de suscripción. Puedes volver a suscribirte al plan que tenías anteriormente o cambiar el plan antes de volver a suscribirte.",
  "billing.pricingPlansTitle": "Planes de precios",
  "billing.promoCodeErrorDescription": "El código promocional {code} no es válido.",
  "billing.resubscribeCTA": "Volver a suscribirse",
  "billing.resubscribingUpdateInProgress": "Reactivando suscripción...",
  "billing.seats": "Asientos",
  "billing.seats.buyLessSeatsConfirmModalContent": "Tienes {currentNoOfSeats} asientos. Estás a punto de eliminar {additionalSeats}.",
  "billing.seats.buyMoreSeatsConfirmModalContent": "Tienes {currentNoOfSeats} asientos. Estás a punto de comprar {additionalSeats} más.",
  "billing.seats.canNotDowngrade": "No puedes reducir tu número de asientos ({currentNumberOfSeats}) sin desactivar uno o más usuarios actualmente activos.",
  "billing.seats.info": "El número de asientos que has comprado.",
  "billing.seats.loadingUpcomingPaymentInformation": "Cargando información del próximo pago...",
  "billing.seats.manage": "Gestionar asientos",
  "billing.seats.noBillingChange": "Estás a punto de comprar {noOfSeats} {noOfSeats, plural, =1 {asiento} other {asientos}}. Tu facturación no se verá afectada.",
  "billing.seats.warningOfProrateAdditional": "Se aplicará hoy un cargo prorrateado estimado de {proratedAmount} (sin IVA).",
  "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "Agregar {noOfSeats} {noOfSeats, plural, =1 {asiento} other {asientos}} aumentará tu próxima factura a {amount} (sin IVA) a partir de {startDate}.",
  "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "Comprar {noOfSeats} {noOfSeats, plural, =1 {asiento} other {asientos}} afectará tu facturación. Tu próxima factura será de {amount} (sin IVA) el {startDate}",
  "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "Eliminar {noOfSeats} {noOfSeats, plural, =1 {asiento} other {asientos}} disminuirá tu próxima factura a {amount} (sin IVA) a partir de {startDate}.",
  "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "Eliminar {noOfSeats} {noOfSeats, plural, =1 {asiento} other {asientos}} afectará su facturación. Su próxima factura será de {amount} (sin incluir IVA) el {startDate}",
  "billing.subscriptionCanceled": "Suscripción cancelada",
  "billing.subscriptionExpired": "Suscripción no activa",
  "billing.subscriptionExpiredDescription": "Su suscripción está cancelada o ha expirado. No es posible realizar actualizaciones en este momento.",
  "billing.subscriptionExpiredText": "Su suscripción ha expirado. Vuelva a suscribirse para continuar usando Vacation Tracker. Si tiene algún problema o pregunta, contacte a nuestro {supportLink}.",
  "billing.SubscriptionPlan": "Plan de suscripción",
  "billing.subscriptionUpdateInProgress": "Actualización de suscripción en progreso",
  "billing.subscriptionUpdateInProgressDescription": "La operación anterior aún está en progreso. Por favor, inténtelo de nuevo en un minuto.",
  "billing.updateBillingInfo": "Actualizar información de facturación",
  "billing.updatedFaild": "Falló la actualización de la información de facturación",
  "billing.updatedSuccessfully": "La información de facturación se ha actualizado",
  "billing.updateInProgress": "Actualizando información de facturación",
  "bot.announce.slack": "Hola <@{slackUserId}>\n\nMi nombre es Vacation Tracker. Te ayudaré a reservar permisos a través de Slack y también mantendré a todos informados sobre los próximos permisos en tu organización.\n\nEscribe `/vacation` en cualquier lugar de Slack para comenzar o consulta mi tutorial de YouTube para obtener más información:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.announce.slack.info": "Hola <@{slackUserId}>, mi nombre es Vacation Tracker.",
  "bot.announce.slackCompany": "Hola Equipo,\n\n¡Mi nombre es Vacation Tracker Bot!\n\nTe permito reservar tus permisos a través de Slack y también mantengo a todos informados sobre los próximos permisos de los miembros del equipo. ¡Empecemos a rastrear algunos permisos!\n\nEscribe `/vacation` en cualquier lugar de Slack o consulta el tutorial de YouTube para obtener más información:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.message.about.commandDescription": "Vacation Tracker simplifica el proceso de solicitud y reserva de permisos de tu organización. Los miembros del equipo pueden reservar cualquier tipo de permiso, o cualquier tipo de día libre pagado/no pagado, sin salir de Slack y sin tener que recordar otro inicio de sesión o contraseña.\n\nConfigura tipos de permisos personalizados y permite que cada individuo en tu organización tenga el control de su tiempo libre. Vacation Tracker proporciona a cada miembro del equipo acceso al Panel de control (http://app.vacationtracker.io) donde pueden solicitar permisos, verificar su estado de permiso actual o historial, y ver los permisos programados para todo su equipo.\n\nDe acuerdo con la configuración del Administrador o Aprobador, los miembros del equipo recibirán notificaciones diarias/semanales sobre las próximas ausencias. Sin complicaciones y sin sorpresas desagradables.\n\n¿Quieres saber más sobre Vacation Tracker? Nuestro <https://vacationtracker.crisp.help/en/|Helpdesk> es tu segunda mejor opción después de la <https://vacationtracker.io/request-a-demo/|demo>.\n\nSi tus preguntas o inquietudes permanecen sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día: hello@vacationtracker.io",
  "bot.message.accessExpired": "¡Oh no! Tu acceso a Vacation Tracker ha expirado. Por favor, contacta a tu administrador para obtener más información.",
  "bot.message.accruedDaysInfoForApprover": "ℹ️ Esta solicitud de permiso se basa en futuros días libres acumulados, no en la cantidad actual. <@{name}> tiene {amount} día(s) restante(s) hoy. Acumularán {accrued} el {earningDate}.",
  "bot.message.accruedDaysInfoForUser": "ℹ️ Tu solicitud de permiso se basa en futuros días libres acumulados, no en la cantidad actual. Tienes {amount} día(s) restante(s) hoy, y acumularás {accrued} el {earningDate}.",
  "bot.message.approveLeave": "Aprobar permiso",
  "bot.message.approveLeaves.approvedRequest": "✅ Has aprobado la solicitud. Se enviará una notificación a <@{userSlackId}> para informarle.",
  "bot.message.approveLeaves.deniedRequest": "❌ Has denegado la solicitud. Se enviará una notificación a <@{userSlackId}> para informarle.",
  "bot.message.approver": "*Aprobador:*\n<@{approverSlackId}>",
  "bot.message.approver.autoApproved": "*Aprobador:*\nAprobación automática",
  "bot.message.approveToil": "Aprobar TOIL",
  "bot.message.backToOptions": "Volver a las opciones",
  "bot.message.blackoutPeriod": "*Período de bloqueo*: {startDate} - {endDate} \n \n_{message}_",
  "bot.message.couldNotProcessRequest": "¡Uy! No pudimos procesar tu solicitud.",
  "bot.message.date": "*Fecha*",
  "bot.message.dateEdited": "*Fecha [Editada]*",
  "bot.message.dates": "*Fechas*",
  "bot.message.datesEdited": "*Fechas [Editadas]*",
  "bot.message.deniedLeave": "Permiso denegado",
  "bot.message.deniedLeaveDate": "*Fecha*\n{leaveDate}",
  "bot.message.deniedLeaveDates": "*Fechas*\n{leaveDate}",
  "bot.message.deniedLeaveLeaveType": "*Tipo de permiso:*\n{leaveTypeName}",
  "bot.message.deniedLeaveWithReason": "Hola, <@{userSlackId}> ha enviado la siguiente solicitud de permiso:",
  "bot.message.deniedToil": "TOIL denegado",
  "bot.message.error.endDateCantBeInPast": "La fecha de finalización no puede ser anterior a la de inicio, por favor selecciona una fecha en el futuro",
  "bot.message.error.endTimeCantBeBeforeStartTime": "Parece que la hora de inicio que has seleccionado es posterior a la hora de finalización.*\n *Por favor, selecciona una hora de inicio que sea anterior a la hora de finalización.",
  "bot.message.error.leaveRequestOverlap": "Las fechas que has elegido se superponen con un permiso existente.*\n *Por favor, selecciona un período de tiempo diferente.",
  "bot.message.error.negativeBallanceNotAllowed": "No se permite un saldo negativo para este tipo de permiso.",
  "bot.message.error.oneHoureIntervalNotAllowed": "El intervalo de tiempo más corto que puedes solicitar para este tipo de permiso es de 4 horas (medio día).",
  "bot.message.error.partTimeIsntAllowed": "No se permite tiempo parcial",
  "bot.message.error.reasonRequired": "El campo de razón es obligatorio.",
  "bot.message.error.requestedMoreThanFullDay": "Has solicitado más de un día completo de permiso en un día laboral.*\n *Por favor, selecciona un día diferente.",
  "bot.message.error.startDateCantBeInPast": "La fecha de inicio no puede estar en el pasado, por favor selecciona una fecha en el futuro",
  "bot.message.error.userCannotBeFound": "El usuario seleccionado no se puede encontrar en tu organización.*\n *Por favor, contacta al administrador de tu organización o al soporte de Vacation Tracker para obtener más información.",
  "bot.message.error.userEndDateInFuture": "Las fechas solicitadas son posteriores a la fecha de finalización de empleo del usuario.*\n *Por favor, contacta al administrador de tu organización o al soporte de Vacation Tracker para obtener más información.",
  "bot.message.error.userStartDateInFuture": "Las fechas solicitadas son anteriores a la fecha de inicio de empleo del usuario. Por favor, contacta al administrador de tu organización o al soporte de Vacation Tracker para obtener más información.",
  "bot.message.error.zeroDaysLeaveRequest": "Has seleccionado un día festivo o no laborable.*\n *Por favor, selecciona un rango de fechas válido.",
  "bot.message.feedback.chooseAnOption": "Elige una opción...",
  "bot.message.feedback.form": "Formulario de comentarios",
  "bot.message.feedback.haveBeenSend": "Los comentarios han sido enviados",
  "bot.message.feedback.howDoYouLikeOurApp": "¿Qué te parece nuestra aplicación?",
  "bot.message.feedback.info": "Danos tu opinión :heart:",
  "bot.message.feedback.itMakesMeCry": "Me hace llorar 😢",
  "bot.message.feedback.itsOk": "Está bien 😐",
  "bot.message.feedback.likeIt": "Me gusta 😀",
  "bot.message.feedback.loveIt": "Me encanta 😍",
  "bot.message.feedback.message": "Mensaje de comentarios",
  "bot.message.feedback.nah": "Nah 🙁",
  "bot.message.feedback.response": "Si tu comentario requiere una respuesta, por favor ingresa tu correo electrónico para que podamos contactarte.",
  "bot.message.feedback.thanks": "¡Gracias por tus comentarios! Siempre puedes ponerte en contacto con nosotros usando support@vacationtracker.io.",
  "bot.message.feedback.youEmailAddress": "Tu dirección de correo electrónico",
  "bot.message.genericError": "¡Ups! Ha ocurrido un error inesperado. 😱 Si este problema persiste, por favor contacta a soporte y proporciónales el siguiente código de error: {requestId}.",
  "bot.message.goToTheMyProfileTab": "Ir a la pestaña Mi Perfil",
  "bot.message.helpMessage": "Hola, \nCon Vacation Tracker, puedes realizar una de las siguientes acciones:\n- Enviar **Solicitar permiso** en un mensaje directo para solicitar un nuevo permiso usando el bot de Microsoft Teams.\n- Enviar **Solicitar TOIL** en un mensaje directo para solicitar un nuevo TOIL usando el bot de Microsoft Teams.\n- Visitar la pestaña **Mi Perfil** para ver tu información personal, como tus próximos permisos y días festivos, y tu historial de permisos.\n- Anclar la pestaña **Resumen** a uno de tus canales para ver el resumen del equipo, incluyendo próximos permisos y días festivos para tu equipo.\n- Visitar el [**Panel Web de Vacation Tracker ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard).\nPara más ayuda y tutoriales, visita nuestro [**Centro de Ayuda ⧉**](https://vacationtracker.crisp.help/en/). También puedes programar una demostración personalizada de Vacation Tracker en este enlace: [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
  "bot.message.holidays": "*Días festivos:*",
  "bot.message.leaveDate": "*Fecha:*\n{leaveDate}",
  "bot.message.leaveDateEdited": "*Fecha [Editada]:*\n{leaveDate}",
  "bot.message.leaveDates": "*Fechas:*\n{leaveDate}",
  "bot.message.leaveDatesEdited": "*Fechas [Editadas]:*\n{leaveDate}",
  "bot.message.leaveDuration1": "*{word} solicitados para el año actual:*\n{requestedThisYearNumber}",
  "bot.message.leaveDuration2": "*{word} restantes para el año actual:*\n{currentYearNumber}",
  "bot.message.leaveDuration2Unlimited": "*{word} restantes para el año actual:*\nIlimitado",
  "bot.message.leaveDuration3": "*{word} solicitados para el próximo año:*\n{requestedNextYearNumber}",
  "bot.message.leaveDuration4": "*{word} restantes para el próximo año:*\n{nextYearNumber}",
  "bot.message.leaveDuration4Unlimited": "*{word} restantes para el próximo año:*\nIlimitado",
  "bot.message.leaveRemaining": "Permisos restantes",
  "bot.message.leaveRemainingCurrent": "Permisos restantes (Año actual)",
  "bot.message.leaveRemainingDays": "Días de permiso restantes",
  "bot.message.leaveRemainingDaysCurrent": "Días de permiso restantes (Año actual)",
  "bot.message.leaveRemainingDaysNext": "Días de permiso restantes (Año siguiente)",
  "bot.message.leaveRemainingHours": "Horas de permiso restantes",
  "bot.message.leaveRemainingHoursNext": "Horas de permiso restantes (Año siguiente)",
  "bot.message.leaveRemainingNext": "Permisos restantes (Año siguiente)",
  "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
  "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Medio día)",
  "bot.message.leaveRequest.halfDay": "(Medio día)",
  "bot.message.leaveRequest.hourly": "(Por horas)",
  "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
  "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (Por horas)",
  "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Por horas)",
  "bot.message.leaveRequestCreated.error": "La solicitud de permiso no puede ser enviada",
  "bot.message.leaveRequestCreated.errorBadTime": "❌ La solicitud de permiso no puede ser enviada porque es más larga que las horas de trabajo, o el intervalo de tiempo no es válido.",
  "bot.message.leaveRequestCreated.errorDateAfter": "❌ La solicitud de permiso no puede ser enviada porque la fecha de inicio que has seleccionado es posterior a la fecha de finalización. Asegúrate de que la fecha de inicio sea anterior a la fecha de finalización, y envía la solicitud de nuevo.",
  "bot.message.leaveRequestCreated.errorDateInThePast": "❌ La solicitud de permiso no puede ser enviada porque la fecha de inicio que has seleccionado está en el pasado. Asegúrate de que las fechas que selecciones estén en el futuro, y envía la solicitud de nuevo.",
  "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ La solicitud de permiso no puede ser enviada porque no has seleccionado la fecha de finalización, o la fecha no es válida.",
  "bot.message.leaveRequestCreated.errorNoStartDate": "❌ La solicitud de permiso no puede ser enviada porque no has seleccionado la fecha de inicio, o la fecha no es válida.",
  "bot.message.leaveRequestCreated.errorNoStartTime": "❌ La solicitud de permiso no se puede enviar porque no has seleccionado la hora de inicio o la hora no es válida.",
  "bot.message.leaveRequestCreated.errorReasonRequired": "❌ La solicitud de permiso no se puede enviar, se requiere una razón.",
  "bot.message.leaveRequested": "Permiso Solicitado",
  "bot.message.leaveRequestedCurrent": "Permiso Solicitado (Año Actual)",
  "bot.message.leaveRequestedDays": "Días de Permiso Solicitados",
  "bot.message.leaveRequestedDaysCurrent": "Días de Permiso Solicitados (Año Actual)",
  "bot.message.leaveRequestedDaysNext": "Días de Permiso Solicitados (Año Siguiente)",
  "bot.message.leaveRequestedHours": "Horas de Permiso Solicitadas",
  "bot.message.leaveRequestedHoursCurrent": "Horas de Permiso Solicitadas (Año Actual)",
  "bot.message.leaveRequestedHoursNext": "Horas de Permiso Solicitadas (Año Siguiente)",
  "bot.message.leaveRequestedNext": "Permiso Solicitado (Año Siguiente)",
  "bot.message.leaveRequestNext.endDate": "Fecha de Finalización:",
  "bot.message.leaveRequestNext.info": "¡Genial! 🐨 Selecciona el período de tiempo y envía la solicitud.",
  "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (Medio Día)",
  "bot.message.leaveRequestNext.leaveTime": "Hora del Permiso:",
  "bot.message.leaveRequestNext.reasonOptional": "Razón (Opcional)",
  "bot.message.leaveRequestNext.reasonRequired": "Razón (Requerida)",
  "bot.message.leaveRequestNext.startDate": "Fecha de Inicio:",
  "bot.message.leaveRequestNext.title": "Solicitar permiso",
  "bot.message.leaves": "*Permisos:*",
  "bot.message.leavesHistory": "Tu historial de permisos:",
  "bot.message.leavesHistory.leaveMessageFullDay": "- Permiso (*{leaveTypeName}*) del {start} al {end} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOn": "- Permiso (*{leaveTypeName}*) el {start} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOnPartDay": "- Permiso (*{leaveTypeName}*) el {start} de {partDayStartHour} a {partDayEndHour} *[{leaveStatus}]*\n",
  "bot.message.leavesHistoryNot": "Hm, parece que aún no has tenido ningún permiso.",
  "bot.message.leaveTypeName": "*Tipo de permiso:*\n{leaveTypeName}",
  "bot.message.leaveTypeWithHalfDay": "*Tipo de permiso:*\n{leaveTypeName} (medio día)",
  "bot.message.manageLeaves.cancelRequest": "Cancelar Solicitud",
  "bot.message.manageLeaves.confirmCancellation": "Por favor, confirma que deseas cancelar tu solicitud de permiso",
  "bot.message.manageLeaves.summary": "Aquí tienes un resumen de tus próximos permisos y solicitudes pendientes.",
  "bot.message.manageLeaves.summaryNoLeaves": "No tienes próximos permisos programados",
  "bot.message.manageLeaves.upcomingLeaves": "*Tienes {upcomingLeaves} solicitud confirmada:*",
  "bot.message.manageLeaves.upcomingLeavesPlural": "*Tienes {upcomingLeaves} solicitudes confirmadas:*",
  "bot.message.manageLeaves.waitingLeaves": "*Tienes {waitingLeaves} solicitud pendiente:*",
  "bot.message.manageLeaves.waitingLeavesPlural": "*Tienes {waitingLeaves} solicitudes pendientes:*",
  "bot.message.membersAway": "Miembros ausentes",
  "bot.message.membersAway.holiday": "- *{holidayLocationName}* estará de descanso por *{holidayName}* el {date}.\n",
  "bot.message.membersAway.leaveMessage": "- *{user}* está de permiso{leaveTypeName} hoy {partDay}\n",
  "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* está de permiso{leaveTypeName} del {startDate} al {endDate}\n",
  "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {verb} de permiso{leaveTypeName} el {startDate}{partDay}\n",
  "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {verb} de permiso{leaveTypeName} del {startDate} al {endDate}\n",
  "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Miembros del equipo que están ausentes este mes.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Miembros del equipo que estarán ausentes el próximo mes.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Miembros del equipo que estarán ausentes la próxima semana.",
  "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Miembros del equipo que están ausentes hoy.",
  "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Miembros del equipo que están ausentes esta semana.",
  "bot.message.membersAway.multiHoliday": "- *{holidayLocationName}* estará de descanso por *{holidayName}* del {start} al {end}.\n",
  "bot.message.membersAway.noLeaves": "Actualmente no hay permisos programados para este período en tu equipo 😎.",
  "bot.message.memersAway.noLeaves": "Actualmente no hay permisos programados para este período en tu equipo 😎.",
  "bot.message.menageLeaves": "Gestionar Permisos",
  "bot.message.moreOptions": "Hola <@{username}>!\n¿En qué puedo ayudarte?",
  "bot.message.moreOptions.about": "Más sobre mí :koala:",
  "bot.message.moreOptions.leaveHistoryTitle": "Historial de ausencias",
  "bot.message.moreOptions.leavesAndHolidaysTitle": "Tus ausencias y días festivos:",
  "bot.message.moreOptions.membersAway": "Ver qué miembros del equipo están ausentes:",
  "bot.message.moreOptions.menageLeavesTitle": "Gestionar ausencias",
  "bot.message.moreOptions.showHolidaysTitle": "Mostrar días festivos",
  "bot.message.myStatus.currentYear": "_Año actual, hasta {yearEnd}_",
  "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nRestante: {current}\nUsado: {used}\nTotal: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nDías restantes: {current}\nDías usados: {used}\nDías totales: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nHoras restantes: {current}\nHoras usadas: {used}\nHoras totales: {earned}",
  "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nDías restantes: Ilimitados\nDías usados: {usedDays}\nDías totales: Ilimitados",
  "bot.message.myStatus.nextYear": "_Próximo año, desde {startYear} hasta {endYear}_",
  "bot.message.myStatus.summary": "Aquí tienes el resumen de tus ausencias para el año actual y el próximo.",
  "bot.message.noLeavePolicies": "Parece que tu ubicación no tiene políticas de ausencia asignadas. 🤔 Contacta a tu Administrador para más información.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "El usuario está solicitando {leaveDurationTotal} días de ausencia, lo cual es {overLimitCurrentYear} día(s) por encima del límite que tiene para el año actual, y {overLimitNextYear} día(s) por encima del límite para el año siguiente.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "El usuario está solicitando {leaveDurationTotal} {leaveDurationTotal, plural, =1 {día} other {días}}, lo cual es {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {día} other {días}} por encima del límite que tiene para el año actual, y {overLimitNextYear} {overLimitNextYear, plural, =1 {día} other {días}} por encima del límite para el año siguiente.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "El usuario está solicitando {leaveDurationTotal} {leaveDurationTotal, plural, =1 {hora} other {horas}}, lo cual es {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {hora} other {horas}} por encima del límite que tiene para el año actual, y {overLimitNextYear} {overLimitNextYear, plural, =1 {hora} other {horas}} por encima del límite para el año siguiente.",
  "bot.message.notification.overAllowedLimitForCurrentYear": "El usuario está solicitando {leaveDurationCurrentYear} día de ausencia, lo cual es {overLimit} día(s) por encima del límite permitido que tiene este usuario para el año actual.",
  "bot.message.notification.overAllowedLimitForCurrentYearDays": "El usuario está solicitando {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {día} other {días}}, lo cual es {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {día} other {días}} por encima del límite permitido que tiene este usuario para el año actual.",
  "bot.message.notification.overAllowedLimitForCurrentYearHours": "El usuario está solicitando {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {hora} other {horas}}, lo cual es {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {hora} other {horas}} por encima del límite permitido que tiene este usuario para el año actual.",
  "bot.message.notification.overAllowedLimitForCurrentYearPlural": "El usuario está solicitando {leaveDurationCurrentYear} días de ausencia, lo cual es {overLimit} día(s) por encima del límite permitido que tiene este usuario para el año actual.",
  "bot.message.notification.overAllowedLimitForFollowingYear": "El usuario está solicitando {leaveDurationNextYear} día de ausencia, lo cual es {overLimit} día(s) por encima del límite permitido que tiene este usuario para el próximo año.",
  "bot.message.notification.overAllowedLimitForFollowingYearDays": "El usuario está solicitando {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {día} other {días}}, lo cual es {overLimitNextYear} {overLimitNextYear, plural, =1 {día} other {días}} por encima del límite permitido que tiene este usuario para el próximo año.",
  "bot.message.notification.overAllowedLimitForFollowingYearHours": "El usuario está solicitando {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {hora} other {horas}}, lo cual es {overLimitNextYear} {overLimitNextYear, plural, =1 {hora} other {horas}} por encima del límite permitido que tiene este usuario para el próximo año.",
  "bot.message.notification.overAllowedLimitForFollowingYearPlural": "El usuario está solicitando {leaveDurationNextYear} días de ausencia, lo cual es {overLimit} día(s) por encima del límite permitido que tiene este usuario para el próximo año.",
  "bot.message.notSignedUpError": "Parece que no estás registrado en Vacation Tracker. Por favor, navega a [{link}]({utmLink}) y crea una cuenta primero. ¡Tomará menos de un minuto!",
  "bot.message.notSignedUpError.slack": "Parece que no estás registrado en Vacation Tracker. Por favor, haz clic en el botón de abajo para crear una cuenta. ¡Tomará menos de un minuto!",
  "bot.message.on": "El",
  "bot.message.openTheWebDashboard": "Abrir el Panel Web",
  "bot.message.partDay": "de {partDayStartHour} a {partDayEndHour}",
  "bot.message.probationPeriod": "*Período de prueba*: \nTu período de prueba dura hasta el {date} \n \nMotivo: _{message}_",
  "bot.message.reminder": "¡Hola equipo! Un recordatorio amistoso del bot de Vacation Tracker",
  "bot.message.reminderSnoozed": "Recordatorios de solicitud pospuestos",
  "bot.message.requestLeave": "Por favor, selecciona uno de los tipos de ausencia disponibles de la lista de abajo, y selecciona las fechas de inicio y fin de tu ausencia. Cuando hagas clic en el botón Enviar solicitud, tu solicitud será enviada para aprobación.",
  "bot.message.requestLeave.error": "*Error: {errorMessage}*",
  "bot.message.requestLeave.errorAutomation": "{errorMessage}",
  "bot.message.requestLeave.initialHelp": "Estoy aquí para ayudarte a enviar una solicitud de ausencia. 🐨 Por favor, selecciona un Tipo de Ausencia, luego haz clic en Siguiente.",
  "bot.message.requestLeave.leaveDuration1": "Eso es 1 día de ausencia.",
  "bot.message.requestLeave.leaveDurationHalfDay": "Eso es medio día de ausencia",
  "bot.message.requestLeave.leaveDurationYear1": "Eso es {year1Value} de ausencia.",
  "bot.message.requestLeave.leaveDurationYear1And2": "Eso es {year1Value} de ausencia para el año actual y {year2Value} de ausencia para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear1And2Days": "Eso es {year1Value} {year1Value, plural, =1 {día} other {días}} de ausencia para el año actual y {year2Value} {year2Value, plural, =1 {día} other {días}} de ausencia para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear1And2Hours": "Eso es {year1Value} {year1Value, plural, =1 {hora} other {horas}} de ausencia para el año actual y {year2Value} {year2Value, plural, =1 {hora} other {horas}} de ausencia para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear1Days": "Eso es {year1Value} {year1Value, plural, =1 {día} other {días}} de ausencia.",
  "bot.message.requestLeave.leaveDurationYear1Hours": "Eso es {year1Value} {year1Value, plural, =1 {hora} other {horas}} de ausencia.",
  "bot.message.requestLeave.leaveDurationYear1PluralYear2": "Eso es {year1Days} días de ausencia para el año actual y {year2Days} día de ausencia para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "Eso es {year1Days} días de ausencia para el año actual y {year2Days} días de ausencia para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear2": "Eso es {year2Value} de ausencia para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear2Days": "Eso es {year2Value} {year2Value, plural, =1 {día} other {días}} de ausencia para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear2Hours": "Eso es {year2Value} {year2Value, plural, =1 {hora} other {horas}} de permiso para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear2Plural": "Eso son {year2Days} días de permiso para el próximo año.",
  "bot.message.requestLeave.leaveDurationYear2PluralYear1": "Eso es {year1Days} día de permiso para el año actual y {year2Days} días de permiso para el próximo año",
  "bot.message.requestLeave.selectCalendarStatus": "Seleccionar estado del calendario",
  "bot.message.requestLeave.selectCalendarStatus.info": "*Estado del calendario*\nMostrar en tu calendario como",
  "bot.message.requestLeave.selectDate.from": "*Fecha de inicio*\nPor favor, selecciona una fecha de inicio",
  "bot.message.requestLeave.selectDate.info": "*Fecha de inicio*\nPor favor, selecciona una fecha de inicio",
  "bot.message.requestLeave.selectDate.oneDay": "*Fecha*\nPor favor, selecciona una fecha",
  "bot.message.requestLeave.selectDate.to": "*Fecha de finalización*\nPor favor, selecciona una fecha de finalización",
  "bot.message.requestLeave.selectLeaveType": "Seleccionar tipo de permiso",
  "bot.message.requestLeave.selectLeaveType.info": "*Tipo de permiso*\nPor favor, selecciona el tipo de permiso",
  "bot.message.requestLeave.selectTimeFormat": "*AM/PM*",
  "bot.message.requestLeave.selectTimeRange": "Seleccionar rango de tiempo",
  "bot.message.requestLeave.selectTimeRange.end": "*Fecha de finalización*\nPor favor, selecciona una fecha de finalización",
  "bot.message.requestLeave.selectTimeRange.from": "*Hora de inicio*",
  "bot.message.requestLeave.selectTimeRange.info": "*Rango de tiempo*\n Por favor, selecciona el rango de tiempo",
  "bot.message.requestLeave.selectTimeRange.range": "*Rango de tiempo*",
  "bot.message.requestLeave.selectTimeRange.to": "*Hora de finalización*",
  "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\n ¡Hasta la próxima!",
  "bot.message.requestLeave.submitted.letTeamKnow": "P.D. ¡Hazle saber a mi equipo si fui de ayuda!",
  "bot.message.requestLeave.submitted.withApproval": "Las fechas que solicitaste han sido enviadas a tu gerente para su aprobación",
  "bot.message.requestLeave.submitted.withoutApproval": "Las fechas que has solicitado han sido aprobadas automáticamente",
  "bot.message.requestLeaveModal.requestALeave": "Solicitar un permiso",
  "bot.message.requestLeaveModal.requestingLeave": "Solicitando permiso",
  "bot.message.requestLeaveModal.sendFeedback": "Enviar comentarios",
  "bot.message.requestLeaveModal.sendRequest": "Enviar solicitud",
  "bot.message.requestToil.initialHelp": "Estoy aquí para ayudarte a enviar una solicitud de TOIL. 🐨 Por favor, selecciona un tipo de permiso y luego haz clic en Siguiente.",
  "bot.message.selectADate": "Selecciona una fecha",
  "bot.message.selectSubstituteApprover": "*Seleccionar Aprobador Sustituto*\nSelecciona una persona para gestionar tus solicitudes de permiso entrantes mientras estás ausente.",
  "bot.message.showHolidays.holidaysHalfDay": "- *{holidayLocationName}* estará cerrado por *{holidayName}* el {date} (Medio día).\n",
  "bot.message.showHolidays.noUpcomingHolidays": "No hay próximos días festivos en tu organización o tu administrador aún no ha establecido ningún día festivo.",
  "bot.message.showHolidays.one": "- *{holidayLocationName}* estará cerrado por *{holidayName}* desde el {startDate} hasta el {endDate}.\n",
  "bot.message.showHolidays.two": "- *{holidayLocationName}* estará cerrado por *{holidayName}* el {date}.\n",
  "bot.message.showHolidays.upcomingHolidays": "Próximos días festivos",
  "bot.message.slack.userChanged": "El usuario ha cambiado esta solicitud",
  "bot.message.slashCommand.helpCommand1": "*Aquí tienes un poco más de información sobre mí:*",
  "bot.message.slashCommand.helpCommand2": "Con la confianza de más de 1000 organizaciones en todo el mundo, *Vacation Tracker permite a tu equipo realizar un seguimiento sin esfuerzo de quién está dentro y fuera de la oficina en todo momento.*",
  "bot.message.slashCommand.helpCommand3": "Aprende más sobre nuestra historia en nuestra <https://vacationtracker.io/about|página Acerca de>.",
  "bot.message.slashCommand.helpCommand4": "Para guías paso a paso, visita nuestro <https://vacationtracker.crisp.help/en/|Centro de ayuda>.",
  "bot.message.slashCommand.helpCommand5": "Para iniciar sesión en el Panel en línea, visita <{dashboardUrl}/connect?platform=slack>.",
  "bot.message.slashCommand.helpCommand6": "Y si alguna vez nos necesitas en el camino, estamos a solo un correo electrónico de distancia en hello@vacationtracker.io.",
  "bot.message.slashCommand.noPermissions": "Lamento ser portador de malas noticias, pero parece que no tienes permiso para acceder a Vacation Tracker. 😟 Por favor, contacta a tu Administrador.",
  "bot.message.slashCommand.title": "¡Hola <@{userId}>! ¿Buscas tomar un tiempo libre? ¡Déjame ayudarte!",
  "bot.message.sorryLetsSpokePriveate": "Hmm, hablemos en privado. 🤫",
  "bot.message.sorryYouAreNotActive": "Lo siento, tu cuenta está inactiva",
  "bot.message.sorryYouAreNotExist": "Lo siento, tu cuenta no fue encontrada",
  "bot.message.submittedLeaveRequest": "Hola, <@{userSlackId}> ha enviado la siguiente solicitud de permiso:",
  "bot.message.submittedLeaveRequestReminder": "Hola, solo un recordatorio de que <@{name}> ha enviado una solicitud que requiere tu atención.",
  "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Ten en cuenta que esta solicitud expirará en {days} día(s). Este es el recordatorio {reminderNo} de 3.",
  "bot.message.substituteApproverPlaceholder": "🔍 Ninguno",
  "bot.message.unableToRequestLeave": "No se puede solicitar permiso.",
  "bot.message.unknownCommand": "Lo siento, no entiendo este comando.\nEscribe **ayuda** para ver la lista de comandos disponibles.",
  "bot.microsoft.whatsNewInfoMessages": "Este es un mensaje de anuncio de **Vacation Tracker**. Lo estás recibiendo porque eres un administrador o un aprobador.",
  "bot.notification.accruedDaysInfoForApprover": "ℹ️ Esta solicitud de permiso se basa en días libres acumulados futuros, no en la cantidad actual. @{name} tiene {amount} día(s) restante(s) hoy. Acumularán {accrued} el {earningDate}.",
  "bot.notification.accruedDaysInfoForUser": "ℹ️ Tu solicitud de permiso se basa en días libres acumulados futuros, no en la cantidad actual. Tienes {amount} día(s) restante(s) hoy, y acumularás {accrued} el {earningDate}.",
  "bot.notification.adminToApprovers": "Has sido nombrado como el nuevo Aprobador para {teamName}, ya que la cuenta del Aprobador anterior ha sido desactivada.",
  "bot.notification.approverCard.approved": "✅ @{approverName} ha aprobado la solicitud, no se requiere ninguna acción adicional.",
  "bot.notification.approverCard.autoApproved": "La solicitud ha sido aprobada automáticamente, no se requiere ninguna acción adicional.",
  "bot.notification.approverCard.cancelled": "⚠️ Parece que {requesterName} ha cambiado de opinión. La solicitud ha sido cancelada.",
  "bot.notification.approverCard.cancelledLeave": "Hola, {requesterName} ha cancelado su permiso.",
  "bot.notification.approverCard.cancelledTitle": "⚠️ Este permiso ha sido cancelado por un aprobador.",
  "bot.notification.approverCard.denied": "❌ @{approverName} ha denegado la solicitud, no se requiere ninguna acción adicional.",
  "bot.notification.approverCard.deniedEdited": "❌ @{approverName} ha denegado la solicitud editada, no se requiere ninguna acción adicional.",
  "bot.notification.approverCard.editedByApprover": "@{approverName} ha editado una solicitud de permiso enviada por @{requesterName}",
  "bot.notification.approverCard.editedByApproverForUser": "@{approverName} ha editado una solicitud de permiso para @{requesterName}.",
  "bot.notification.approverCard.info": "Añade un motivo para esta aprobación o denegación",
  "bot.notification.approverCard.notActive": "⚠️ Esta solicitud de permiso ya no está activa.",
  "bot.notification.changeOutOfOfficeMessage": "Editar plantilla",
  "bot.notification.daily.info": "¡Es hora de tu actualización diaria! 🐨 Aquí están los próximos permisos:",
  "bot.notification.daily.noLeaves.isNotForToday": "No hay permisos programados para el {dayName}",
  "bot.notification.daily.noLeaves.isToday": "No hay permisos programados para hoy",
  "bot.notification.holiday.plural": "- Las ubicaciones **{locationNames}** estarán ausentes por **{holidayName}** {holidayPeriodText}",
  "bot.notification.holiday.singular": "- La ubicación **{locationNames}** estará ausente por **{holidayName}** {holidayPeriodText}",
  "bot.notification.leave.info.isInTheFuture": "estará de permiso{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isInThePast": "estuvo de permiso{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isToday": "está de permiso{leaveTypeText} {leavePeriodText}",
  "bot.notification.leaveDates.partDay": "{start} de {partDayStartHour} a {partDayEndHour}",
  "bot.notification.leavePeriod.fullDay": "el {day}",
  "bot.notification.leavePeriod.halfDay": "el {day} (Medio día)",
  "bot.notification.leavePeriod.moreDays": "del {start} al {end}",
  "bot.notification.leavePeriod.partDay": "el {start} de {partDayStartHour} a {partDayEndHour}",
  "bot.notification.leavePolicyInfo": "*Nota: este usuario o este tipo de permiso tiene establecida una cuota de 0 para el permiso solicitado.*",
  "bot.notification.leaveRequestError.general": "Error con una solicitud de permiso",
  "bot.notification.leaveRequestError.notApprover": "Esta acción solo está permitida para aprobadores/administradores del equipo",
  "bot.notification.leaveRequestError.notOpen": "La solicitud de permiso ha sido {leaveRequestStatus}",
  "bot.notification.leaveRequestError.notOpenIsSameAppover": "La solicitud de permiso ha sido {leaveRequestStatus}",
  "bot.notification.leaveRequestExpired": "Tu solicitud ha expirado",
  "bot.notification.leaveRequestExpiredGeneral": "La solicitud ha expirado",
  "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} - Medio día)",
  "bot.notification.oooDefaultMessage": "Hola,\n\nGracias por tu mensaje. Actualmente estoy fuera de la oficina y no podré responder a los correos electrónicos hasta el {firstWorkingDay}.\n\nHaré todo lo posible por responder a tu correo electrónico lo antes posible a mi regreso.\n\nGracias por tu comprensión.\n\nSaludos cordiales",
  "bot.notification.processingApproval": "Procesando aprobación...",
  "bot.notification.processingDenial": "Procesando denegación...",
  "bot.notification.processingResend": "Reenviando solicitud de permiso...",
  "bot.notification.processingSnooze": "Posponiendo recordatorios...",
  "bot.notification.reconnectCalendar": "Reconectar",
  "bot.notification.reminderSent": "Recordatorio de solicitud de permiso enviado",
  "bot.notification.reminderSentToil": "Recordatorio de solicitud de TOIL enviado",
  "bot.notification.requesterCard.addedLeave": "Se ha creado un permiso para ti",
  "bot.notification.requesterCard.addedLeaveBy": "Añadido por {approverName}",
  "bot.notification.requesterCard.addedLeaveToil": "Se ha creado un TOIL para ti",
  "bot.notification.requesterCard.approvedTitle": "Tu Solicitud de Permiso fue Aprobada",
  "bot.notification.requesterCard.approvedTitleToil": "Tu Solicitud de TOIL fue Aprobada",
  "bot.notification.requesterCard.approverInfo": "Aprobado por {approverName}",
  "bot.notification.requesterCard.autoApprovedInfo": "La solicitud ha sido aprobada automáticamente.",
  "bot.notification.requesterCard.blackoutPeriod": "Período: {startDate} - {endDate}",
  "bot.notification.requesterCard.blackoutPeriodReason": "Motivo: {message}",
  "bot.notification.requesterCard.blackoutPeriodTitle": "Período de Bloqueo",
  "bot.notification.requesterCard.cancelled": "⚠️ Cancelado",
  "bot.notification.requesterCard.cancelledEdited": "⚠️ Se han realizado cambios en la solicitud original.",
  "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} ha editado esta solicitud de permiso.",
  "bot.notification.requesterCard.cancelledTitle": "⚠️ Tu permiso ha sido cancelado por un aprobador.",
  "bot.notification.requesterCard.deniedBy": "Tu Solicitud de Permiso fue Aprobada",
  "bot.notification.requesterCard.deniedEditedDescription": "Tu solicitud de permiso editada ha sido denegada, sin embargo, tu solicitud original sigue activa.",
  "bot.notification.requesterCard.deniedEditedTitle": "Edición de solicitud de permiso denegada.",
  "bot.notification.requesterCard.deniedOpenDescription": "Tanto tu solicitud de permiso original como la solicitud editada han sido denegadas.",
  "bot.notification.requesterCard.deniedOpenTitle": "Solicitud de permiso denegada",
  "bot.notification.requesterCard.deniedTitle": "Tu Solicitud de Permiso fue Denegada",
  "bot.notification.requesterCard.edited": "@{requesterName} ha editado su solicitud previamente aprobada y la está volviendo a enviar para su aprobación:",
  "bot.notification.requesterCard.editedOpen": "@{requesterName} ha editado y vuelto a enviar su solicitud de permiso.",
  "bot.notification.requesterCard.moreDays": "❌ Has solicitado más días de los disponibles.",
  "bot.notification.requesterCard.moreThanFullDay": "❌ La solicitud de permiso no puede ser enviada porque has solicitado más de un día completo de permiso en un día laborable.",
  "bot.notification.requesterCard.noWorkingDays": "❌ La solicitud de permiso no puede ser enviada porque no has incluido ningún día laborable. Asegúrate de revisar los días laborables de tu equipo antes de enviar la solicitud.",
  "bot.notification.requesterCard.originalLeaveRequest": "Solicitud de permiso original:",
  "bot.notification.requesterCard.overlap": "❌ La solicitud de permiso no puede ser enviada porque las fechas que has solicitado se superponen con las fechas de una solicitud de permiso existente.",
  "bot.notification.requesterCard.probationPeriod": "Tu período de prueba dura hasta {date}",
  "bot.notification.requesterCard.probationPeriodReason": "Motivo: {message}",
  "bot.notification.requesterCard.probationPeriodTitle": "Período de Prueba",
  "bot.notification.requesterCard.requestInfo": "Tu solicitud está en camino al aprobador. ¡Hasta la próxima! 🐨",
  "bot.notification.requesterCard.requestPossibleActions": "Para editar o cancelar la solicitud, visita el Panel de Control",
  "bot.notification.requesterCardapp.tryAnotherDates": "Intenta con otras fechas",
  "bot.notification.requesterInfo": "Hola, @{userName} ha enviado una solicitud para que la revises.",
  "bot.notification.requesterInfoAutoApproved": "Hola, @{userName} ha enviado una solicitud.",
  "bot.notification.requesterInfoEdited": "@{userName} ha editado y vuelto a enviar su solicitud.",
  "bot.notification.requesterInfoEditedByApprover": "@{approverName} ha editado una solicitud enviada por @{userName}",
  "bot.notification.requesterInfoEditedForUser": "Tu solicitud ha sido editada.",
  "bot.notification.requesterInfoEditedForUserByApprover": "El aprobador @{approverName} ha realizado cambios en tu solicitud.",
  "bot.notification.requesterInfoEditedOpen": "@{userName} ha editado y vuelto a enviar su solicitud.",
  "bot.notification.setOutOfOffice": "Tu permiso de {leaveTypeName} está programado para comenzar en menos de {days} {days, plural, =1 {día} other {días}} el {leavePeriodStart}. ¿Te gustaría configurar un mensaje automático de ausencia para que se envíe durante tu tiempo fuera?",
  "bot.notification.setOutOfOfficeButton": "Configurar mensaje de ausencia",
  "bot.notification.setOutOfOfficeFallbackText": "Configurar ausencia para tu {leaveTypeName}",
  "bot.notification.slack.additional": "... y más.\nNo se pudieron mostrar permisos adicionales debido al límite de tamaño. Por favor, abre el Panel de Control para una vista completa.",
  "bot.notification.slack.approved": "✅ <@{approverName}> ha aprobado la solicitud, no se requiere ninguna acción adicional.",
  "bot.notification.slack.approverChanged": "Hola, el aprobador <@{approverSlackId}> cambió una solicitud de permiso.",
  "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "El aprobador <@{approverSlackId}> ha cambiado las fechas de la solicitud",
  "bot.notification.slack.approverChangedLeaveReqest": "Hola, el aprobador <@{approverSlackId}> ha cambiado la siguiente solicitud de permiso para <@{userSlackId}>:",
  "bot.notification.slack.approverChangedYour": "El aprobador <@{approverSlackId}> cambió tu solicitud de permiso.",
  "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> ha denegado esta solicitud, no se requiere ninguna acción adicional",
  "bot.notification.slack.calendar": ":calendar: |  *{dayName}*  | :calendar: ",
  "bot.notification.slack.cancelled": "Hola, <@{userName}> ha cancelado su permiso.",
  "bot.notification.slack.cancelledByApprover": "Tu permiso ha sido cancelado por un aprobador",
  "bot.notification.slack.cancelledInfo": "Esta ausencia ha sido cancelada por un aprobador",
  "bot.notification.slack.created": "Un aprobador ha creado una ausencia para ti",
  "bot.notification.slack.holiday.plural": "- Las ubicaciones de *{locationNames}* estarán ausentes por *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holiday.singular": "- La ubicación de *{locationNames}* estará ausente por *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holidays": ":airplane: *Días festivos: *",
  "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* estará de ausencia{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isInThePast": "- *{userName}* estuvo de ausencia{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isToday": "- *{userName}* está de ausencia{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leaveRequestCreated": "Hola, <@{userName}> ha enviado una solicitud de ausencia.",
  "bot.notification.slack.leaveRequestInfo": "Hola, <@{userName}> por favor confirma la información de la solicitud de ausencia.",
  "bot.notification.slack.leaveRequestInfoDateFrom": "Fecha de inicio: {date}",
  "bot.notification.slack.leaveRequestInfoDateTo": "Fecha de finalización: {date}",
  "bot.notification.slack.leaveRequestInfoError": "No pudimos sugerir una ausencia con los datos proporcionados",
  "bot.notification.slack.leaveRequestInfoLeaveType": "Tipo de ausencia: {leaveType}",
  "bot.notification.slack.leaveRequestInfoReason": "Motivo: {reason}",
  "bot.notification.slack.leaveRequestSuggestionCancelled": "Formulario de solicitud de ausencia cancelado ❌",
  "bot.notification.slack.leaves": ":loud_sound: *Ausencias: *",
  "bot.notification.slack.reason": "*Motivo*\n{reason}",
  "bot.notification.slack.reasonEdited": "*Motivo [Editado] *\n{reason}",
  "bot.notification.slack.requestedBy": "*Solicitado por:*\n<@{userSlackId}>",
  "bot.notification.slack.statusApproved": "*Estado:*\n✅ Aprobado",
  "bot.notification.slack.statusAutoApproved": "*Estado*\nAprobación automática",
  "bot.notification.slack.statusDenied": "*Estado*\n❌ Denegado",
  "bot.notification.slack.statusInfo": "👋 ¡Ahora podemos configurar automáticamente tu estado de Slack para este tipo de ausencia! Haz clic en el botón Habilitar para permitirnos actualizar tu estado cuando comience tu ausencia. Lo borraremos cuando termine.",
  "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "Esta ausencia ha sido cancelada por un aprobador. Consulta la información adicional a continuación.",
  "bot.notification.slack.title": "Notificación: \"_{title}_\"",
  "bot.notification.slack.userCancelledLeaveRequest": "Parece que <@{userSlackId}> ha cambiado de opinión. La solicitud ha sido cancelada.",
  "bot.notification.slack.warning": ":warning: *Advertencia: {infoMessage}*",
  "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "Tu ausencia ha sido cancelada por un aprobador. Consulta la información adicional a continuación.",
  "bot.notification.slack.yourLeaveRequestApproved": "Tu solicitud ha sido aprobada",
  "bot.notification.slack.yourRequestHasBeenDenied": "Tu solicitud ha sido denegada",
  "bot.notification.snoozeOutOfOfficeButton": "No volver a preguntarme",
  "bot.notification.snoozeOutOfOfficeMessage": "Ya no te preguntaremos más sobre configurar el estado de Fuera de la oficina para {leaveTypeName}. Siempre puedes cambiar esta configuración en la página de Configuración de cuenta del Panel web de Vacation Tracker",
  "bot.notification.submittedLeaveRequestReminder": "Hola, solo un recordatorio de que @{name} ha enviado una solicitud que requiere tu atención.",
  "bot.notification.substituteApproverDeleted": "Tu rol como Aprobador Sustituto para las fechas {startDate} - {endDate} ha sido eliminado.",
  "bot.notification.substituteApproverSelected": "{creatorName} te ha seleccionado como aprobador sustituto para el/los departamento(s) {teamNames} en nombre de {approverName} durante su ausencia. Como aprobador sustituto, serás responsable de revisar y actuar sobre las solicitudes de ausencia enviadas a {approverName} desde {startDate} hasta {endDate}.",
  "bot.notification.substituteApproverUpdated": "Tu período como Aprobador Sustituto se ha actualizado del {startDate} al {endDate}.",
  "bot.notification.title": "Notificación: {title}",
  "bot.notification.weekly.current.info": "¡Hola!\n 🐨 Estoy aquí para informarte sobre las ausencias programadas para la semana actual ({start} - {end})",
  "bot.notification.weekly.next.info": "¡Hola!\n 🐨 Estoy aquí para informarte sobre las ausencias programadas para la próxima semana ({start} - {end})",
  "bot.notification.weekly.noHolidays": "No hay días festivos para esta semana.",
  "bot.notification.weekly.noLeaves": "No hay ausencias programadas para esta semana.",
  "bot.slack.whatsNewInfoMessages": "Este mensaje es un anuncio de *Vacation Tracker*. Lo estás recibiendo porque eres un administrador o un aprobador.",
  "bot.slackFuckedUpAgain": "Slack ha estado arreglando algo de nuevo, por favor contacta a nuestro soporte para solucionar los errores de Slack.",
  "bot.somethingNewFromVacationTracker": "Algo nuevo de Vacation Tracker",
  "bot.welcomeMessage.furtherAssistance": "¿Te sientes abrumado con toda esta información? ¡No te preocupes! No necesitas memorizar todo. Simplemente escribe 'Ayuda' en nuestro chat y nuestro bot te enviará esta información. Para obtener más ayuda, no dudes en chatear con nuestro equipo de Soporte al Cliente a través de nuestro sitio web o visitar nuestra Mesa de Ayuda.",
  "bot.welcomeMessage.goToMyProfile": "Ir a la pestaña Mi Perfil",
  "bot.welcomeMessage.greeting": "¡Saludos {name}, soy el Bot de Vacation Tracker! Estoy aquí para ayudarte a programar ausencias a través de Microsoft Teams, así como mantener a todos informados sobre quién está dentro y fuera de la oficina. Sin más preámbulos, ¡comencemos a rastrear esas ausencias!",
  "bot.welcomeMessage.happyVacationing": "¡Felices vacaciones! 🌴",
  "bot.welcomeMessage.howToAccessTabs": "Cómo acceder a las pestañas",
  "bot.welcomeMessage.noCompany": "Hola, \n\n Gracias por instalar el bot y la pestaña de Vacation Tracker en Microsoft Teams. Como último paso, por favor ve a nuestro sitio web para completar el registro y configurar los ajustes de tu organización: [{dashboardLink}]({utmDashboardLink})",
  "bot.welcomeMessage.noCompanyInfo": "Si tienes alguna pregunta, no dudes en iniciar un chat con nuestro equipo de soporte al cliente en nuestro sitio web o visita nuestro centro de ayuda.",
  "bot.welcomeMessage.openWebDashboardInfo": "La misma información también es accesible a través de nuestro panel web. Simplemente haz clic en el botón 'Abrir Panel Web' a continuación e inicia sesión con tu cuenta de Microsoft para acceder.",
  "bot.welcomeMessage.requestLeaveInfo": "Para comenzar, simplemente envíame un mensaje directo con 'Solicitar permiso' o mira este breve tutorial sobre Vacation Tracker:",
  "bot.welcomeMessage.restInfo": "Además del bot, también puedes enviar solicitudes de permiso, verificar tus cuotas de permiso, ver calendarios de equipo y más desde Microsoft Teams usando pestañas:",
  "bot.welcomeMessage.title": "Hola desde Vacation Tracker",
  "botUpdatePermissions.thanks": "¡Gracias! Todo está configurado. Puedes cerrar el panel.",
  "button.no": "No",
  "button.yes": "Sí",
  "calendar.all": "Todos",
  "calendar.calendar": "Calendario",
  "calendar.filterByLocation": "Filtrar por Ubicación:",
  "calendar.filterByTeam": "Filtrar por Departamento:",
  "calendar.forAllUsers": "Para todos los usuarios",
  "calendar.getCalendarLink": "Obtener enlace del calendario",
  "calendar.halfdayTags": "Los permisos y vacaciones de menos de un día completo se representan con bloques rayados.",
  "calendar.halfdayTagsShort": "Permisos de menos de un día",
  "calendar.labels": "Etiqueta(s)",
  "calendar.listView": "Vista de lista",
  "calendar.location": "Ubicación(es)",
  "calendar.monthView": "Vista mensual",
  "calendar.sync.calendarAlreadyConnected": "Este calendario ya está conectado",
  "calendar.sync.calendarNotFound": "No se puede encontrar el calendario. Es posible que haya sido eliminado o ya no esté disponible. Por favor, revisa tu configuración de calendario.",
  "calendar.sync.canNotUpdateSharedCalendar": "Has conectado el calendario {calendarName} de {provider}. No se permite cambiar el calendario. Para cambiar el calendario conectado, por favor elimina esta integración y crea una nueva.",
  "calendar.sync.confirmDelete": "Por favor, confirma que deseas eliminar el calendario <strong>{name}</strong>.",
  "calendar.sync.connect": "Conecta tu Calendario de {provider}",
  "calendar.sync.connectCalendar": "Conectar Calendario",
  "calendar.sync.connectCalendarCompletePlanyOnly": "SOLO PLAN COMPLETO",
  "calendar.sync.connectCalendarIcalOptionLabel": "Cualquier calendario (a través de enlace iCal)",
  "calendar.sync.connectCalendarOptionLabel": "calendario de {platform}",
  "calendar.sync.connectInfo": "Conecta tu cuenta de {provider} y selecciona un calendario compartido donde todos los permisos aprobados serán añadidos y sincronizados automáticamente.",
  "calendar.sync.createICalButton": "Crear enlace iCal",
  "calendar.sync.createICalFiltersInfo": "Los siguientes filtros se aplicarán a este enlace de calendario iCal:",
  "calendar.sync.createICalFiltersWarning": "Tu calendario no actualiza los datos iCal en tiempo real. El retraso puede ser de hasta 24 horas (dependiendo de tu proveedor de calendario). Para actualizaciones en tiempo real, conecta tu Google Calendar o Microsoft Outlook Calendar directamente.",
  "calendar.sync.createICalInfo": "El enlace de calendario iCal te permite suscribirte y ver los permisos programados de tu equipo desde Vacation Tracker en cualquier calendario (incluyendo Google Calendar, Microsoft Outlook Calendar, Apple Calendar y muchos más).",
  "calendar.sync.createPushToSharedInfo": "Y envía y sincroniza los permisos aprobados filtrados al siguiente calendario compartido de {provider}:",
  "calendar.sync.createSyncCalendarButton": "Crear sincronización de calendario",
  "calendar.sync.createSyncCalendarFiltersInfo": "Vacation Tracker aplicará los siguientes filtros a todos los permisos aprobados:",
  "calendar.sync.creating": "Creando sincronización de calendario",
  "calendar.sync.creatingError": "Error al crear la sincronización de calendario",
  "calendar.sync.creatingSuccess": "La creación de la sincronización de calendario fue exitosa",
  "calendar.sync.deletedCalendar": "Esta sincronización de calendario ha sido eliminada",
  "calendar.sync.deleteTitle": "Eliminar calendario",
  "calendar.sync.deleting": "Eliminando sincronización de calendario",
  "calendar.sync.deletingError": "Error al eliminar la sincronización de calendario",
  "calendar.sync.deletingSuccess": "La eliminación de la sincronización de calendario fue exitosa",
  "calendar.sync.emptyDataMessage": "Sincroniza y visualiza todos los permisos del equipo en tu calendario compartido. Puedes conectar tu calendario de Outlook o Google Calendar, o usar iCal para sincronizar Vacation Tracker con cualquier otro tipo de calendario.",
  "calendar.sync.emptyDataTitle": "Sincroniza permisos con el calendario de tu equipo",
  "calendar.sync.loginWithDifferentAccountInfo": "¿No ves el calendario?",
  "calendar.sync.loginWithDifferentAccountLink": "Inicia sesión con otra cuenta de {provider}",
  "calendar.sync.reconnect": "Reconecta tu Calendario de {provider}",
  "calendar.sync.reconnectInfo": "Vuelva a conectar su cuenta de {provider} para continuar la sincronización automática de ausencias con su calendario compartido.",
  "calendar.sync.reconnecting": "Reconectando sincronización del calendario",
  "calendar.sync.reconnectingError": "Error al reconectar la sincronización del calendario",
  "calendar.sync.reconnectingSuccess": "Reconexión de la sincronización del calendario exitosa",
  "calendar.sync.selectSharedCalendarPlaceholder": "Seleccione el calendario compartido de {provider}",
  "calendar.sync.stepByStep": "Instrucciones paso a paso",
  "calendar.sync.title": "Sincronizar su calendario de equipo",
  "calendar.sync.tooltipInfo": "Todos sus Calendarios Sincronizados se reúnen en esta página para una gestión más fácil. Para obtener más información, consulte nuestro <helpDesk>artículo de Ayuda.</helpDesk>",
  "calendar.sync.update": "Actualizar su Calendario de {provider}",
  "calendar.sync.updateSyncCalendarButton": "Actualizar sincronización del calendario",
  "calendar.sync.updating": "Actualizando sincronización del calendario",
  "calendar.sync.updatingError": "Error al actualizar la sincronización del calendario",
  "calendar.sync.updatingSuccess": "Actualización de la sincronización del calendario exitosa",
  "calendar.syncButton": "Sincronizar con su calendario (Google, Outlook, etc.)",
  "calendar.syncModal.description": "Copie el enlace de abajo e intégrelo en su Calendario de Google, Calendario de Outlook o cualquier otro calendario que admita fuentes de calendario iCal.",
  "calendar.syncModal.filters": "Los siguientes filtros se aplican a este enlace de calendario:",
  "calendar.syncModal.helpdesk": "Visite nuestro <helpDesk>Servicio de Ayuda</helpDesk> para obtener ayuda e instrucciones paso a paso.",
  "calendar.team": "Departamento(s)",
  "calendar.timezone.info": "La hora se convierte a su Zona Horaria",
  "calendar.week.monFri": "Lun-Vie",
  "calendar.week.sunThu": "Dom-Jue",
  "calendarListModal.description": "Por favor, seleccione el calendario de la siguiente lista de calendarios disponibles. Después de conectar un calendario, Vacation Tracker agregará, editará o cancelará sus ausencias en el calendario elegido.",
  "calendarListModal.descriptionGoogleFirstLine": "Por favor, seleccione el calendario de la siguiente lista de calendarios disponibles. ",
  "calendarListModal.descriptionOutlookFirstLine": "Solo puede conectar su calendario predeterminado. ",
  "calendarListModal.descriptionSecondLine": "Después de conectar un calendario, Vacation Tracker agregará, editará o cancelará sus ausencias en el calendario elegido.",
  "calendarListModal.hiddenLeaveTypesDescription": "Aprenda más sobre <helpdesk>cómo mostrar tipos de ausencia ocultos</helpdesk>.",
  "calendarListModal.reconnectDescription": "Vuelva a conectar su calendario para continuar la sincronización automática de ausencias con su calendario conectado.",
  "calendarListModal.reconnectTitle": "Reconectar Calendario",
  "calendarListModal.selectPlaceHolder": "Por favor, seleccione un calendario...",
  "calendarListModal.title": "Seleccionar Calendario",
  "channel_not_found": "El canal seleccionado ha sido eliminado o ya no está disponible. Por favor, seleccione otro canal para recibir Notificaciones.",
  "choiceAutomations.accessibleTo": "Accesible para <approver>Aprobador </approver><and>y </and><admin>Administrador </admin><only>solamente</only>",
  "choiceAutomations.activeSubscribedAddons": "Complementos suscritos activos",
  "choiceAutomations.activeSubscribedAutomations": "Automatizaciones suscritas activas",
  "choiceAutomations.adpIntegration": "Proporciona una conexión directa con tu herramienta de nómina, asegurando que los datos de nómina sean precisos y estén actualizados en todas las plataformas.",
  "choiceAutomations.automationPrice": "Precio: ${price}/usuario/mes",
  "choiceAutomations.automationPriceInfo": "Puede comprar y activar cualquiera de las automatizaciones a continuación por $0.50/usuario/mes",
  "choiceAutomations.bambooHrIntegration": "Proporciona una conexión directa con tu herramienta de RRHH, manteniendo los datos precisos y consistentes en todos los sistemas.",
  "choiceAutomations.blackoutPeriodInfo": "Un período de bloqueo es un marco de tiempo específico durante el cual los empleados no pueden tomar ausencias del trabajo.",
  "choiceAutomations.cancelAtPeriodEnd": "Activo hasta que finalice el período de facturación",
  "choiceAutomations.cancelAtPeriodEndTooltip": "Se ha dado de baja de este complemento. Permanecerá activo hasta el final del período de facturación actual y se desactivará después.",
  "choiceAutomations.comingSoonScheduleCall": "Siéntase libre de compartir detalles sobre sus necesidades específicas con respecto a la automatización en la que estamos trabajando.",
  "choiceAutomations.comingSoonScheduleCallTitle": "Próximamente",
  "choiceAutomations.deacticate": "Cancelar suscripción",
  "choiceAutomations.employmentLeaveRestrictionInfo": "La política de ausencias de empleo temprano permite garantizar un tiempo dedicado de adaptación durante los primeros días de empleo mediante la restricción de solicitudes de ausencia de nuevos empleados.",
  "choiceAutomations.enitlementByRoleShortInfo": "Los empleados reciben días de ausencia adicionales basados en su rol, según lo definido por etiqueta(s).",
  "choiceAutomations.entitlementByRoleInfo": "Los empleados reciben días de ausencia adicionales basados en su rol, según lo definido por etiqueta(s). Por ejemplo, puede crear una etiqueta llamada 'Gerente' y asignarla a todos los gerentes en su organización. Luego, puede crear una automatización que otorgue 5 días de ausencia adicionales a todos los empleados con la etiqueta 'Gerente'. Puede crear múltiples automatizaciones para diferentes roles. Esta automatización es válida desde su año fiscal actual. <link>Contáctenos</link> si necesita aplicar esta automatización a años anteriores.",
  "choiceAutomations.includedInYourPlan": "Incluido en su plan",
  "choiceAutomations.leaveDurationLimitInfo": "Personalizar Duración de Ausencia: Establezca el número mínimo y máximo de días para el período deseado.",
  "choiceAutomations.mySbscribedAddons": "Mis complementos suscritos",
  "choiceAutomations.notActivatedAddons": "Disponible para suscripción",
  "choiceAutomations.openApiInfo": "Proporciona una integración perfecta con sistemas externos, permitiendo la sincronización automatizada de datos y funcionalidad mejorada",
  "choiceAutomations.reviewAndBuy": "Revisar y comprar",
  "choiceAutomations.selectAutomations": "Selecciona tu automatización preferida",
  "choiceAutomations.seniorityEntitlementInfo": "El derecho por antigüedad es un sistema donde los empleados ganan más tiempo libre pagado (PTO) basado en su tiempo de servicio en la empresa. Actualmente, puedes crear solo un derecho por antigüedad por ubicación. Esta automatización es válida desde tu año fiscal actual. <link>Contáctanos</link> si necesitas más de un derecho por antigüedad en una ubicación o necesitas aplicar esta automatización a años anteriores.",
  "choiceAutomations.seniorityEntitlementShortInfo": "El derecho por antigüedad es un sistema donde los empleados ganan más tiempo libre pagado (PTO) basado en su tiempo de servicio en la empresa.",
  "choiceAutomations.teamLeaveBalancingInfo": "Gestiona el porcentaje de ausencias simultáneas del equipo.",
  "choiceAutomations.visibilityInfo": "Permite a los administradores personalizar la visibilidad de la información de ausencias para usuarios regulares, mejorando la privacidad y la relevancia de los datos.",
  "company.platformChanged": "Migrando a una nueva plataforma",
  "company.platformChanged.confirmDescription": "Estás a punto de migrar a una nueva plataforma. Esta acción es irreversible. Por favor, revisa la siguiente información antes de proceder.",
  "company.platformChanged.confirmDescription.unmappedUsers.deleted": "Hemos encontrado {unmappedUsers} usuarios que no se encuentran en tu cuenta de {newPlatform}. Estos usuarios serán marcados como ELIMINADOS cuando se complete la migración.",
  "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "Hemos encontrado {unmappedUsers} usuarios que no se encuentran en tu cuenta de {newPlatform}. Estos usuarios no podrán iniciar sesión en Vacation Tracker.",
  "company.platformChanged.confirmQuestion": "¿Estás seguro de que quieres proceder?",
  "company.platformChanged.confirmTitle": "Confirmar migración",
  "company.platformChanged.description": "Por favor, relaciona tus usuarios de Vacation Tracker con sus cuentas de {newPlatform}. Relacionaremos automáticamente los usuarios con los mismos correos electrónicos.",
  "company.platformChanged.googleAccount": "Cuenta de Google",
  "company.platformChanged.hideInactive": "Ocultar inactivos:",
  "company.platformChanged.markUnmappedAsDeleted": "¿Marcar usuarios no relacionados como eliminados?",
  "company.platformChanged.microsoftAccount": "Cuenta de Microsoft",
  "company.platformChanged.slackAccount": "Cuenta de Slack",
  "company.platformChanged.title": "Migrando de {oldPlatform} a {newPlatform}",
  "company.platformChanged.unmatched": "No encontrado en {platform}",
  "company.platformChanged.vacationTrackerUser": "Usuario de Vacation Tracker",
  "component.filterSimple.filterBy": "Filtrar por",
  "component.filterSimple.selectType": "Seleccionar tipo",
  "component.filterSimple.tooltipInfo": "Si no seleccionas ningún filtro, la exportación incluirá a todos los usuarios.",
  "component.notificationDestination.label": "Enviar a",
  "component.notificationDestination.tooltip": "Ten en cuenta que puedes enviar la notificación a múltiples correos electrónicos, pero solo a un canal a la vez.",
  "component.password.timeToHack.days": "{duration} días",
  "component.password.timeToHack.hours": "{duration} horas",
  "component.password.timeToHack.instantly": "Instantáneamente",
  "component.password.timeToHack.label": "Tiempo que los hackers necesitan para descifrar esta contraseña:",
  "component.password.timeToHack.minutes": "{duration} minutos",
  "component.password.timeToHack.perfect": "Más de una vida",
  "component.password.timeToHack.seconds": "{duration} segundos",
  "component.password.timeToHack.years": "{duration} años",
  "component.setPassword.enterOldPassword": "Ingresa la contraseña antigua",
  "component.setPassword.enterPassword": "Ingresa la contraseña",
  "component.setPassword.tooltip.description.line1": "La información anterior muestra el tiempo máximo estimado que los hackers necesitarían para descifrar por fuerza bruta tu contraseña seleccionada. La estimación se basa en la tabla <italic>\"Tabla de contraseñas para descifrar una función hash PBKDF2 usando una GPU RTX 3090\"</italic> de <link>este artículo</link>.",
  "component.setPassword.tooltip.description.line2": "¿Cómo elegir una buena contraseña? Hay muchas formas de seleccionar una buena contraseña. Nosotros usamos gestores de contraseñas y no reutilizamos contraseñas. Echa un vistazo a esta <link>guía divertida pero correcta</link> para más inspiración.",
  "component.setPassword.tooltip.title": "Tiempo que los hackers necesitan para descifrar por fuerza bruta tu contraseña",
  "component.setPassword.validation": "La contraseña debe contener al menos: 8 caracteres, una letra minúscula, un número y un símbolo.",
  "components.accrualsCalculator.calculate": "Calcular",
  "components.accrualsCalculator.calculateAccrualsDays": "Calcular días de acumulación",
  "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "Ganarás <strong>{days}</strong> días en el período actual en esa fecha",
  "components.accrualsCalculator.youWillEarnedInNextPeriod": "Ganarás <strong>{days}</strong> días en el próximo período en esa fecha",
  "components.addHolidayForm.addHolidays": "Agregar festivo",
  "components.addHolidayForm.addHolidays.cancel": "Cancelar",
  "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Festivo de medio día",
  "components.addHolidayForm.addHolidays.isHolidayMultiday": "¿Más de un día?",
  "components.addHolidayForm.addHolidays.saveHoliday": "Guardar",
  "components.addHolidayForm.date.error": "Por favor, selecciona una fecha",
  "components.addHolidayForm.editHoliday": "Editar día festivo",
  "components.addHolidayForm.editHolidays": "Editar días festivos",
  "components.addHolidayForm.endDate.error": "Por favor, seleccione la fecha de finalización",
  "components.addHolidayForm.holidaysName": "Nombre del día festivo",
  "components.addHolidayForm.holidaysName.error": "Por favor, ingrese un nombre para el día festivo",
  "components.addHolidayForm.startDate.error": "Por favor, seleccione la fecha de inicio",
  "components.announcementModal.announceToCompany": "¿Quieres informar a tu equipo que estás utilizando Vacation Tracker?",
  "components.announcementModal.send": "Enviar anuncio",
  "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} usuario<multiUsers>s</multiUsers> <link>(Ver Usuario<multiUsers>s</multiUsers>)</link> no podrán tomar {leaveTypes} ausencias,<endDate> entre</endDate> {fromDate} <endDate>y {toDate}</endDate><recurring> cada año.</recurring>",
  "components.billing.email": "Correo electrónico de facturación",
  "components.billing.emailPlaceholder": "Ingrese dirección de correo electrónico.",
  "components.billing.emailsValidationRequired": "Se requiere un correo electrónico para fines de facturación.",
  "components.billing.resubscribe": "Volver a suscribirse",
  "components.billingCouponCode.activeCouponInfo": "<percentOffCover>{off}%</percentOffCover><amountOffCover>US${off}</amountOffCover> de descuento <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} mes</durationInMonthsCover>, desde {start}",
  "components.billingCouponCode.activeCouponInfoTitle": "Código de cupón activo",
  "components.billingCouponCode.applyCode": "Aplicar código",
  "components.billingCouponCode.applyCodeAndResubscribe": "Aplicar código y volver a suscribirse",
  "components.billingCouponCode.couponCode": "Código de cupón",
  "components.billingCouponCode.text": "¿Tienes un código de cupón? Ingrésalo aquí para aplicarlo a tu suscripción.",
  "components.billingCouponCode.title": "Cupones",
  "components.billingEmails.emailPlaceholder": "Ingrese dirección de correo electrónico.",
  "components.billingEmails.emails": "Correos electrónicos",
  "components.billingEmails.emailsValidationRequired": "Se requiere un correo electrónico para fines de facturación.",
  "components.billingEmails.title": "Correos electrónicos de facturación",
  "components.billingForm.address1": "Línea de dirección 1",
  "components.billingForm.address1ValidationRequired": "La dirección es obligatoria.",
  "components.billingForm.address2": "Línea de dirección 2",
  "components.billingForm.billingEmails": "Correo electrónico de facturación",
  "components.billingForm.billingEmailsPlaceholder": "Ingrese dirección de correo electrónico.",
  "components.billingForm.billingEmailsValidationRequired": "Se requiere un correo electrónico para fines de facturación.",
  "components.billingForm.ccRequired": "El número de tarjeta es obligatorio.",
  "components.billingForm.city": "Ciudad",
  "components.billingForm.cityValidationRequired": "La ciudad es obligatoria.",
  "components.billingForm.companyName": "Nombre de la empresa",
  "components.billingForm.companyNameValidationRequired": "El nombre de la empresa es obligatorio.",
  "components.billingForm.country": "País",
  "components.billingForm.countryValidationRequired": "El país es obligatorio.",
  "components.billingForm.couponCode": "Código de cupón",
  "components.billingForm.coupons": "Cupones",
  "components.billingForm.description": "Por favor, ingrese su información de pago en el formulario de arriba para pagar su suscripción con tarjeta de crédito.",
  "components.billingForm.invoiceDescription": "Si la persona encargada de pagar la suscripción no es un usuario de Slack o Microsoft Teams, podemos enviarles una factura por correo electrónico que se puede pagar con tarjeta de crédito.",
  "components.billingForm.invoiceLabel": "Enviar facturas por correo electrónico",
  "components.billingForm.invoiceTitle": "Pago con factura enviada por correo electrónico",
  "components.billingForm.paymentCC": "Tarjeta de crédito",
  "components.billingForm.paymentTitle": "Información de pago",
  "components.billingForm.planTitle": "Plan de facturación ",
  "components.billingForm.state": "Estado",
  "components.billingForm.stateValidationRequired": "El estado es obligatorio.",
  "components.billingForm.title": "Información de facturación ",
  "components.billingInfo.address": "Dirección",
  "components.billingInfo.canceledDescription": "Has cancelado tu suscripción a Vacation Tracker. Permanecerá activa hasta {periodEnd}",
  "components.billingInfo.canceledTrialDescription": "Tu período de prueba permanecerá activo hasta {periodEnd}, después de eso tu cuenta de Vacation Tracker será cancelada.",
  "components.billingInfo.companyName": "Nombre de la empresa",
  "components.billingInfo.email": "Correo electrónico de facturación",
  "components.billingInfo.resubscribe": "Volver a suscribirse",
  "components.billingInfo.text": "Tu información de facturación se utiliza para procesar los pagos de tu suscripción. Puedes actualizar tu información de facturación y añadir la información de tu tarjeta de crédito aquí.",
  "components.billingInfo.title": "Información de facturación",
  "components.billingInvoice.description": "Puedes gestionar los métodos de pago y las facturas en nuestro portal de clientes. <link>Haz clic aquí para visitar el portal de clientes</link><creatingLink>Creando el enlace del portal de clientes</creatingLink>",
  "components.billingInvoice.title": "Facturas",
  "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "Hemos encontrado otra herramienta de seguimiento de ausencias",
  "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "¿Puedes decirnos a qué herramienta te estás cambiando?",
  "components.cancelSubscriptionModal.cancelText": "No cancelar la suscripción",
  "components.cancelSubscriptionModal.contactInfo": "Para todo lo demás, por favor contacta con nuestro soporte en {email}",
  "components.cancelSubscriptionModal.info": "¿Puedes decirnos cuál es la razón por la que estás cancelando tu suscripción a Vacation Tracker?",
  "components.cancelSubscriptionModal.min20characters": "Mínimo 20 caracteres",
  "components.cancelSubscriptionModal.missingFeatures": "La herramienta no tiene las características que mi equipo necesita",
  "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "¿Puedes decirnos en pocas palabras qué característica falta?",
  "components.cancelSubscriptionModal.moreInfo": "¿Qué podríamos haber hecho para mantenerte como cliente?",
  "components.cancelSubscriptionModal.okText": "Cancelar ahora",
  "components.cancelSubscriptionModal.option1": "Nos hemos cambiado a un software de RRHH",
  "components.cancelSubscriptionModal.option2": "Hemos encontrado otra herramienta de seguimiento de ausencias",
  "components.cancelSubscriptionModal.option3": "La herramienta no tiene las características que mi equipo necesita",
  "components.cancelSubscriptionModal.option4": "El precio está por encima de nuestro presupuesto",
  "components.cancelSubscriptionModal.option5": "Otro",
  "components.cancelSubscriptionModal.other": "Otro",
  "components.cancelSubscriptionModal.otherAdditionalInfo": "Otro",
  "components.cancelSubscriptionModal.priceOverBudget": "El precio está por encima de nuestro presupuesto",
  "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "¿Cuál es tu presupuesto para una herramienta como esta?",
  "components.cancelSubscriptionModal.title": "Lamentamos verte partir...",
  "components.cancelSubscriptionModal.weAreMovingToPayroll": "Nos estamos cambiando a un sistema de nóminas",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "¿Puedes decirnos a qué sistema de nóminas te estás cambiando?",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "Si tuviéramos una integración con este sistema de nóminas, ¿considerarías quedarte?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "Nos hemos cambiado a un software de RRHH",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "¿Puedes decirnos a qué sistema de RRHH te estás cambiando?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "Si tuviéramos una integración con este sistema de RRHH, ¿considerarías quedarte?",
  "components.cancelTrialSubscriptionModal.cancelText": "Continuar prueba",
  "components.cancelTrialSubscriptionModal.info": "Por favor, explica en una frase por qué quieres cancelar tu prueba",
  "components.cancelTrialSubscriptionModal.okText": "Cancelar prueba",
  "components.cancelTrialSubscriptionModal.title": "Lamentamos verte partir...",
  "components.changeTeamForm.changeTeamWarning": "Selecciona el Departamento al que quieres transferir al Usuario.",
  "components.changeTeamForm.changeUserTeam": "Cambiar el Departamento del Usuario",
  "components.changeTeamForm.selectTeam": "Seleccionar Departamento",
  "components.changeTeamForm.selectTeamError": "Por favor, selecciona un departamento.",
  "components.companyAlreadySignup.connectWith": "Conectar con {existsOnAnotherPlatform}",
  "components.companyAlreadySignup.info": "Hemos notado que tu empresa ya tiene una cuenta existente en Vacation Tracker. Si querías iniciar sesión, simplemente haz clic aquí:",
  "components.companyAlreadySignup.signupWithMail": "Conectar con tu correo electrónico de trabajo",
  "components.companyAlreadySignup.title": "Tu empresa ya está utilizando Vacation Tracker",
  "components.companyLogs.dateRangeLimit": "Los registros de más de 3 meses solo están disponibles en el plan completo.",
  "components.companyLogs.rangeLimit": "El rango de fechas puede ser de un año como máximo",
  "components.companySettings.contactEmailInfo": "La nueva dirección de correo electrónico de contacto de tu empresa es: {email}.",
  "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "Para obtener más información, visite nuestro <helpDesk>Centro de Ayuda</helpDesk>.",
  "components.comparePlatformsModal.info": "Independientemente de la plataforma que elija, tendrá acceso a todas las funciones principales de Vacation Tracker. Sin embargo, algunas tienen beneficios adicionales que no están disponibles en otras. Consulte la tabla comparativa a continuación.",
  "components.comparePlatformsModal.table.comingSoon": "Próximamente",
  "components.comparePlatformsModal.table.email": "Correo electrónico",
  "components.comparePlatformsModal.table.feature": "Característica",
  "components.comparePlatformsModal.table.google": "Google",
  "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Importar usuarios desde su Google Workspace",
  "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Importar usuarios desde su Directorio Activo de Microsoft",
  "components.comparePlatformsModal.table.importUsersFromSlack": "Importar usuarios desde su espacio de trabajo de Slack",
  "components.comparePlatformsModal.table.inviteUsersViaEmail": "Invitar usuarios por correo electrónico",
  "components.comparePlatformsModal.table.manageLeaveTypes": "Gestionar tipos de ausencias, festivos y solicitudes de ausencia",
  "components.comparePlatformsModal.table.microsoft": "Microsoft",
  "components.comparePlatformsModal.table.multiTeamLocation": "Configurar múltiples departamentos y ubicaciones con diferentes políticas de ausencias",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Enviar y gestionar solicitudes de ausencia directamente desde Microsoft Teams",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Enviar y gestionar solicitudes de ausencia directamente desde Slack",
  "components.comparePlatformsModal.table.slack": "Slack",
  "components.comparePlatformsModal.table.syncToGoogleCalendar": "Sincronizar Vacation Tracker con Google Calendar",
  "components.comparePlatformsModal.table.syncToOutlookCalendar": "Sincronizar Vacation Tracker con Outlook 365 Calendar",
  "components.comparePlatformsModal.title": "¿Qué integración de Vacation Tracker es adecuada para mí?",
  "components.configureImport.configureProratedLeaveTypesErrorMessage": "Necesita seleccionar al menos un tipo de ausencia",
  "components.configureImport.defaultDepartmentTooltip": "Todos los usuarios importados se añadirán a este departamento. Una vez importados, puede ubicarlos en un departamento diferente.",
  "components.configureImport.defaultLocationTooltip": "Todos los usuarios importados se añadirán a esta ubicación. Una vez importados, puede ubicarlos en una ubicación diferente.",
  "components.configureImport.guestUsers": "¿Desea importar usuarios invitados multicanal?",
  "components.configureImport.guestUsersTooltip": "Si esta opción está activada, los usuarios invitados multicanal de su espacio de trabajo se importarán a Vacation Tracker.",
  "components.configureImport.proRateLabel": "¿Prorratear la cuota de los nuevos usuarios para este año?",
  "components.configureImport.proRateLeaveTypes": "Aplicar prorrateo a los siguientes tipos de ausencia",
  "components.configureImport.proRateLeaveTypesTooltip": "Por favor, enumere los tipos de ausencia para los que desea prorratear las cuotas de ausencia disponibles de los usuarios.",
  "components.configureImport.proRateTooltip": "Por favor, seleccione si desea que nuestro sistema calcule la cuota de ausencia disponible de los usuarios importados para el año inicial basándose en la configuración de su empresa y la fecha de inicio del usuario.",
  "components.createLeaveType.assignToLocationManyInProgress": "Asignando política de ausencias a ubicaciones",
  "components.createLeaveType.assignToLocationManyInProgressCompleted": "Asignación de política de ausencias a ubicaciones completada",
  "components.createLeaveType.assignToLocationOneInProgress": "Asignando política de ausencias a ubicación",
  "components.createLeaveType.assignToLocationOneInProgressCompleted": "Asignación de política de ausencias a ubicación completada",
  "components.createLeaveType.createInProgress": "Creando el tipo de ausencia {leaveTypeName}",
  "components.createLeaveTypeForm.assignAndConfigure": "Asignar y Configurar",
  "components.createLeaveTypeForm.assignToLocations": "Asignar a Ubicaciones",
  "components.createLeaveTypeForm.calendarStatusBusy": "Ocupado",
  "components.createLeaveTypeForm.calendarStatusFree": "Libre",
  "components.createLeaveTypeForm.calendarStatusLabel": "Mostrar este tipo de ausencia en su calendario como",
  "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Elija 'Ocupado' para ausencias completas como vacaciones, indicando no disponibilidad, o 'Libre' para días de trabajo remoto, indicando que no está en la oficina pero está disponible.",
  "components.createLeaveTypeForm.continueCreatingLeaveType": "Sí, quiero crear el tipo de ausencia",
  "components.createLeaveTypeForm.createAndAssign": "Crear y Asignar",
  "components.createLeaveTypeForm.createLeaveType": "Crear Tipo de Ausencia",
  "components.createLeaveTypeForm.justLastStep.assign": "Asignar",
  "components.createLeaveTypeForm.justLastStep.title": "Asignar Política de Ausencias",
  "components.createLeaveTypeForm.Locations": "Ubicaciones",
  "components.createLeaveTypeForm.locationsInfo": "Asignar <strong>{leaveTypeName}</strong> a todas las ubicaciones o a una ubicación específica.",
  "components.createLeaveTypeForm.locationsPolicyHint": "Para editar la política (reglas) para este tipo de ausencia, por favor vaya a {locations, plural, =1 {la Ubicación} other {las Ubicaciones}} específica(s):",
  "components.createLeaveTypeForm.selectLocation": "Seleccionar ubicación",
  "components.createLeaveTypeForm.similarLeaveTypeChecking": "Comprobando tipos de ausencia existentes...",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "¿Aún desea crear un nuevo tipo (<strong>{newLeaveTypeName}</strong>)?",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "Puede asignar un tipo de permiso a múltiples ubicaciones y personalizar las reglas por ubicación (como cuotas, días acumulados, devengos, etc.). ¡No es necesario crear tipos separados para cada lugar!",
  "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "Recomendamos usar un solo tipo de permiso en múltiples ubicaciones para mantener la consistencia y los datos de permisos de los usuarios.",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Establecer las reglas específicas de ubicación para:",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "Ya existe un tipo de permiso similar (<strong>{leaveTypeName}</strong>).",
  "components.createLeaveTypeForm.similarLeaveTypeTitle": "Tipo de Permiso Similar",
  "components.createLeaveTypeForm.title": "Crear Tipo de Permiso y Asignar Política de Permiso",
  "components.denyWithReasonForm.deny": "Denegar",
  "components.denyWithReasonForm.denyLeaveRequest": "Denegar Solicitud de Permiso",
  "components.denyWithReasonForm.reason": "Agregar un Motivo",
  "components.denyWithReasonForm.reasonIsRequired": "Este campo es obligatorio.",
  "components.downgradeAlert.cancelChangeToPlan": "Cancelar cambio al plan <strong>{nextPlan}</strong>",
  "components.downgradeAlert.cancelSwitchPlan": "¿Desea cancelar el cambio programado al plan <strong>{nextPlan}</strong> {nextPeriod}?",
  "components.downgradeAlert.noIWantToSwitch": "No, quiero cambiar al plan <strong>{nextPlan}</strong> {nextPeriod}",
  "components.downgradeAlert.yesIWantToStay": "Sí, quiero permanecer en el plan <strong>{currentPlan}</strong> {currentPeriod}",
  "components.entitlementByRole.additionalDays": "día(s) adicional(es)",
  "components.entitlementByRole.additionalHours": "hora(s) adicional(es)",
  "components.entitlementByRole.applayToUsersWithLabel": "Aplicar a usuarios con etiqueta",
  "components.entitlementByRole.applyToUsersWithLabel": "Aplicar a usuarios con etiqueta",
  "components.entitlementByRole.completePlanOnly": "Esta función es exclusivamente accesible para usuarios suscritos a nuestro plan Completo.",
  "components.entitlementByRole.noLabels": "No hay etiquetas creadas o todas están utilizadas para otras automatizaciones de Derecho por Rol.",
  "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Comenzar a aplicar derecho por rol desde",
  "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Comenzar a aplicar derecho por rol desde",
  "components.fakeDoorMailLoginModal.discount": "Como parte del grupo de acceso anticipado, también obtendrás un 50% de descuento en Vacation Tracker durante los primeros 3 meses.",
  "components.fakeDoorMailLoginModal.info": "Actualmente estamos trabajando en habilitar el registro por correo electrónico. Para obtener acceso anticipado, por favor regístrate a continuación y nos pondremos en contacto contigo pronto.",
  "components.fakeDoorMailLoginModal.no": "No, esperaré.",
  "components.fakeDoorMailLoginModal.title": "¿Interesado en acceso anticipado? 🚀 ",
  "components.fakeDoorMailLoginModal.yes": "Sí, quiero obtener acceso anticipado.",
  "components.feedbackModal.button": "Danos tu opinión",
  "components.feedbackModal.emailPlaceholder": "¿Cuál es tu dirección de correo electrónico?",
  "components.feedbackModal.feedbackForm": "Formulario de opinión",
  "components.feedbackModal.feedbackMessage": "Mensaje de opinión",
  "components.feedbackModal.howDoYouLikeApp": "¿Qué te parece nuestra aplicación?",
  "components.feedbackModal.howDoYouLikeAppPlaceholder": "Elige una opción...",
  "components.feedbackModal.iLikeIt": "Me gusta",
  "components.feedbackModal.iLoveIt": "Me encanta",
  "components.feedbackModal.ItMakesMeCry": "Me hace llorar",
  "components.feedbackModal.itsOk": "Está bien",
  "components.feedbackModal.messagePlaceholder": "Tu mensaje de opinión",
  "components.feedbackModal.Nah": "Nah",
  "components.feedbackModal.namePlaceholder": "Ingresa tu nombre",
  "components.feedbackModal.ratingRequired": "Por favor, selecciona una de las calificaciones dadas",
  "components.feedbackModal.sendFeedback": "Enviar opinión",
  "components.feedbackModal.yourEmail": "Tu dirección de correo electrónico",
  "components.feedbackModal.yourEmailInfo": "Si tu opinión requiere una respuesta, por favor ingresa tu correo electrónico para que podamos contactarte.",
  "components.feedbackModal.yourName": "Tu nombre",
  "components.filterSimple.filterBy": "Filtrar por",
  "components.filterSimple.selectType": "Seleccionar tipo",
  "components.filterSimple.tooltipInfo": "Si no seleccionas ningún filtro, la exportación incluirá a todos los usuarios.",
  "components.generalSettings.aiBotHelperLabel": "Permitir comunicación con el Bot de Vacation Tracker",
  "components.generalSettings.aiBotHelperLabelDisable": "Deshabilitar comunicación con el Bot de Vacation Tracker",
  "components.generalSettings.aiBotHelperTooltip": "Enviar mensajes al bot es una forma conveniente y eficiente para que los empleados soliciten tiempo libre del trabajo. Los empleados pueden simplemente enviar un mensaje de solicitud de permiso al bot, especificando detalles como las fechas de inicio y fin, el número de días solicitados y el tipo de permiso.",
  "components.heatmap.clickForMore": "Haz clic para más información",
  "components.heatmap.holiday": "Festivo",
  "components.heatmap.holidayDescription": "La ubicación {location} está ausente por {holiday}",
  "components.heatmap.loading": "cargando {day}",
  "components.heatmap.nextMonth": "Mes siguiente",
  "components.heatmap.nonWorkingDay": "día no laborable",
  "components.heatmap.previousMonth": "Mes anterior",
  "components.heatmap.showLess": "Mostrar menos",
  "components.heatmap.showMore": "Mostrar {amount} más",
  "components.heatmap.today": "hoy",
  "components.helpDrawer.adminProductTourInfo": "Como Administrador, tienes el nivel más alto de permisos en Vacation Tracker, y puedes personalizar todas las configuraciones de la organización a través del Panel de Administrador.\n\nEl Panel te permite:\n- Solicitar permiso o añadir permiso para otros miembros del departamento.\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Crear y configurar múltiples departamentos (Asignar Aprobadores)\n- Establecer Notificaciones\n- Gestionar Usuarios (Activos, Inactivos, Eliminados).\n- Crear y gestionar Ubicaciones (Tipos de Permiso, Festivos, Notificaciones)\n\nEl Bot te permite:\n- Solicitar Permiso\n- Aprobar/Denegar Solicitudes de Permiso\n- Recibir Notificaciones Diarias/Semanales\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "components.helpDrawer.adminProductTourTitle": "Tu rol: Administrador",
  "components.helpDrawer.approverProductTourInfo": "Como Aprobador, tienes el segundo nivel más alto de permisos en Vacation Tracker, y puedes aprobar o denegar solicitudes de permiso enviadas por usuarios del departamento del que has sido asignado como Aprobador.\n\nEl Panel te permite:\n- Solicitar Permiso o añadir Permiso para otros miembros del departamento.\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Establecer Notificaciones\n- Ver tu Perfil de Usuario.\n- Gestionar Solicitudes (Aprobar/Denegar Solicitudes, Ver Historial).\n\nEl Bot te permite:\n- Solicitar Permiso\n- Aprobar/Denegar Solicitudes de Permiso\n- Recibir Notificaciones Diarias/Semanales\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "components.helpDrawer.approverProductTourTitle": "Tu rol: Aprobador",
  "components.helpDrawer.google.adminProductTourInfo": "Como Administrador, tienes el nivel más alto de permisos en Vacation Tracker, y puedes personalizar todas las configuraciones de la organización a través del Panel de Administrador.\n\nEl Panel te permite:\n- Solicitar Permiso o añadir Permiso para otros miembros del departamento.\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Crear y configurar múltiples departamentos (Asignar Aprobadores)\n- Establecer Notificaciones\n- Gestionar Usuarios (Activos, Inactivos, Eliminados).\n- Crear y gestionar Ubicaciones (Tipos de Permiso, Festivos, Notificaciones)\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "components.helpDrawer.google.approverProductTourInfo": "Como Aprobador, tienes el segundo nivel más alto de permisos en Vacation Tracker, y puedes aprobar o denegar solicitudes de permiso enviadas por usuarios del departamento del que has sido asignado como Aprobador.\n\nEl Panel te permite:\n- Solicitar Permiso o añadir Permiso para otros miembros del departamento.\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Establecer Notificaciones\n- Ver tu Perfil de Usuario.\n- Gestionar Solicitudes (Aprobar/Denegar Solicitudes, Ver Historial).\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "components.helpDrawer.google.userProductTourInfo": "Como Usuario de Vacation Tracker, puedes enviar y gestionar tus Solicitudes de Permiso a través del Panel, así como tener una visión general completa de las ausencias planificadas dentro de tu organización.\n\nEl Panel te permite:\n- Solicitar Permiso\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Ver tu Perfil de Usuario.\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "components.helpDrawer.helpDeskInfo": "Visita nuestro Servicio de Ayuda para aprender más sobre nuestro producto y obtener respuestas a algunas de las preguntas comunes.",
  "components.helpDrawer.learnMore": "Aprender más",
  "components.helpDrawer.needhelp": "¿Necesitas ayuda?",
  "components.helpDrawer.quickTourInfo": "Hemos preparado algunos pasos cortos que te recomendamos seguir para configurar tu organización rápidamente.",
  "components.helpDrawer.quickTourTitle": "Tour rápido",
  "components.helpDrawer.supportInfo": "Si tienes alguna pregunta o necesitas asistencia, inicia un chat con nuestro equipo de soporte al cliente o envíanos un correo electrónico a <link>hello@vacationtracker.io</link>",
  "components.helpDrawer.supportTitle": "Soporte",
  "components.helpDrawer.userProductTourInfo": "Como Usuario de Vacation Tracker, puedes enviar y gestionar tus Solicitudes de Permiso a través del Panel o el Bot, así como tener una visión general completa de las ausencias planificadas dentro de tu organización.\n\nEl Panel te permite:\n- Solicitar Permiso\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Ver tu Perfil de Usuario.\n\nEl Bot te permite:\n- Solicitar Permiso\n- Recibir Notificaciones Diarias/Semanales\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "components.helpDrawer.userProductTourTitle": "Tu rol: Usuario",
  "components.importHolidaysForm.backButton": "Atrás",
  "components.importHolidaysForm.selectCountry": "Seleccionar país",
  "components.importHolidaysForm.selectHolidays": "Seleccionar festivos",
  "components.importHolidaysForm.selectYear": "Seleccionar año",
  "components.importUsersForm.button.importAllUsers": "Importar y Activar Todos los {users} Usuarios",
  "components.importUsersForm.button.importSelectedUsers": "Importar {noOfUsers} {noOfUsers, plural, =1 {Usuario Seleccionado} other {Usuarios Seleccionados}}",
  "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "¿Quieres importar automáticamente todos los usuarios de {platform}?",
  "components.importUsersForm.calculationStartYearInfo": "Vacation Tracker calculará los permisos a partir de {year}",
  "components.importUsersForm.cancel": "Cancelar",
  "components.importUsersForm.chooseUsersPlaceholder": "Nombre del Miembro del Equipo",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "Vacation Tracker no importará automáticamente usuarios invitados multicanal de tu espacio de trabajo. Aún puedes importarlos manualmente si es necesario.",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "Vacation Tracker importará automáticamente usuarios invitados multicanal de tu espacio de trabajo.",
  "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "Puedes cambiar estas configuraciones más tarde en el panel de Vacation Tracker",
  "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "{noOfUsers, plural, =1 {El nuevo usuario será añadido} other {Los nuevos usuarios serán añadidos}} al departamento {chosenDepartment} y en la ubicación {chosenLocation}",
  "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "Importar {noOfUsers} {noOfUsers, plural, =1 {usuario} other {usuarios}}",
  "components.importUsersForm.confirmImportUsersModalContentOpener": "Has seleccionado:",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Prorratear la cuota de los usuarios para los siguientes tipos de permiso: {leaveTypes}",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "Las cuotas de los usuarios ya no serán prorrateadas",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Enviar un {channel, select, Email {correo} other {mensaje}} de bienvenida {channel} a {noOfUsers, plural, =1 {el nuevo usuario} other {los nuevos usuarios}}",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "Ya no enviaremos un mensaje de bienvenida a los nuevos usuarios",
  "components.importUsersForm.confirmImportUsersModalTitle": "Confirmar la importación de {noOfUsers} usuarios seleccionados",
  "components.importUsersForm.consent.line1": "Para importar usuarios, necesitarás obtener el consentimiento del administrador de tu Microsoft Teams. Para obtener este consentimiento por única vez, por favor envía el siguiente enlace al administrador de tu Microsoft Teams:",
  "components.importUsersForm.consent.line2": "Una vez que obtengas el consentimiento, cierra y vuelve a abrir este modal, y este mensaje desaparecerá.",
  "components.importUsersForm.consent.warning": "Por favor, cierre y vuelva a abrir este modal una vez que obtenga el consentimiento para poder importar todos los usuarios.",
  "components.importUsersForm.googleAPIErrorMessage": "Parece que hay un problema con la configuración de tu directorio de Google. Por favor, contacta a tu administrador y envíales el siguiente error:",
  "components.importUsersForm.importAllUsers": "¿Quieres importar todos los usuarios?",
  "components.importUsersForm.importAllUsersExceedingLimt": "Tienes un gran número de usuarios en tu directorio. Si quieres importar todos los usuarios, por favor contacta a nuestro equipo de Soporte y podemos habilitar la importación. Alternativamente, puedes seleccionar usuarios específicos para importar.",
  "components.importUsersForm.importAllUsersTooltip": "{noOfUsersToImport} {noOfUsersToImport, plural, =1 {usuario} other {usuarios}} de {platform} serán importados a Vacation Tracker. Esto podría afectar tu facturación.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "Tienes un gran número de usuarios en tu directorio. Si quieres importar usuarios automáticamente, por favor contacta a nuestro equipo de Soporte y podemos habilitar la importación.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "Tienes un gran número de usuarios en tu directorio. Si quieres importar usuarios automáticamente, por favor contacta a nuestro equipo de Soporte y podemos habilitar la importación.",
  "components.importUsersForm.importUsersAutomaticallyTooltip": "Una vez que un usuario recién añadido a tu {platform} sea importado a Vacation Tracker, recibirá un breve mensaje explicando qué es Vacation Tracker y cómo navegarlo.",
  "components.importUsersForm.importUsersTitle": "Importar Usuarios",
  "components.importUsersForm.newCompanyNameInfo": "El nuevo nombre de la empresa será: {newName}",
  "components.importUsersForm.selectUsers": "Selecciona usuarios para importar:",
  "components.importUsersForm.selectUsersNext": "Configurar ajustes para los usuarios seleccionados",
  "components.importUsersForm.selectUsersStepName": "Seleccionar Usuarios",
  "components.importUsersForm.sendWelcomeMessageLabel": "¿Enviar un mensaje de bienvenida a los nuevos usuarios?",
  "components.importUsersForm.trackingQuotaInfoDays": "Vacation Tracker registrará la duración de todas las ausencias y cuotas en días.",
  "components.importUsersForm.trackingQuotaInfoHours": "Vacation Tracker registrará la duración de todas las ausencias y cuotas en horas.",
  "components.importUsersForm.userEndDateInfo": "Los usuarios con una fecha de finalización no podrán enviar solicitudes de ausencia después de esa fecha, y los desactivaremos automáticamente.",
  "components.importUsersForm.userEndDateTurnOffInfo": "Los usuarios con una fecha de finalización podrán enviar solicitudes de ausencia después de esa fecha, y no los desactivaremos automáticamente.",
  "components.importUsersForm.usersIsRequired": "Este campo es obligatorio.",
  "components.importUsersForm.warningAllUsersImport": "Vacation Tracker puede sincronizar, importar y eliminar usuarios automáticamente de tu {platform}.",
  "components.importUsersForm.warningNumberOfUsersToImport": "Cuando hagas clic en el botón \"Importar y Activar Todos los Usuarios\", activarás todos los {users} usuarios de tu directorio. Esta acción puede afectar tu facturación.",
  "components.importUsersForm.warningOfUsersImport": "Importar {noOfUsers} {noOfUsers, plural, =1 {usuario seleccionado} other {usuarios seleccionados}} podría afectar tu facturación.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "Agregar {noOfUsers} {noOfUsers, plural, =1 {usuario} other {usuarios}} aumentará tu próxima factura a {amount} (excluyendo IVA) a partir de {startDate}.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "Se aplicará hoy un cargo prorrateado estimado de {proratedAmount} (excluyendo IVA).",
  "components.importUsersForm.warningOfUsersImportNoChange": "Estás a punto de importar {noOfUsers} {noOfUsers, plural, =1 {usuario} other {usuarios}}. Tu facturación no se verá afectada.",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "Has alcanzado tu límite actual de asientos ({seats}). ¿Quieres comprar más?",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "No puedes importar todos los usuarios de tu espacio de trabajo/directorio porque superarías tu límite actual de asientos ({seats}). ¿Quieres comprar más?",
  "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "Importar {noOfUsers} {noOfUsers, plural, =1 {usuario} other {usuarios}} afectará tu facturación. Tu próxima factura será de {amount} (excluyendo IVA) el {startDate}",
  "components.importUsersForm.warningOfUsersImportTrial": "Actualmente estás en modo de prueba. Se te cobrará {amount} (excluyendo IVA) el {startDate}",
  "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "Estás en un período de prueba y no se te cobrará por ninguna importación adicional de usuarios antes del {stripeCurrentPeriodEnd}. Agrega tu información de facturación para ver el estimado de cargos.",
  "components.insertHolidaysForm.back": "Atrás",
  "components.insertHolidaysForm.deselectAll": "Deseleccionar Todo",
  "components.insertHolidaysForm.findHolidays": "Buscar Festivos",
  "components.insertHolidaysForm.insertHolidays": "Importar Festivos",
  "components.insertHolidaysForm.pleaseChooseACountry": "Elige un País",
  "components.insertHolidaysForm.saveHolidays": "Guardar Festivos",
  "components.insertHolidaysForm.selectAll": "Seleccionar Todo",
  "components.insertHolidaysForm.selectHolidays": "Seleccionar festivos",
  "components.insertHolidaysForm.subtitle": "El cambio puede afectar el número de días de ausencia para algunos usuarios. A estos usuarios se les añadirán día(s) a su cuota disponible para la ausencia solicitada. ¿Estás seguro de que quieres proceder?",
  "components.insertHolidaysForm.title": "Importar Festivos sobrescribirá cualquier Festivo añadido previamente.",
  "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "No puedes invitarte a ti mismo",
  "components.inviteAndManageUsersWithEmail.header": "Invitaciones por correo electrónico",
  "components.inviteAndManageUsersWithEmail.userAlreadyExists": "El usuario con el correo electrónico proporcionado ya existe",
  "components.inviteBotToPrivateChannel.descriptionOne": "Para agregar Vacation Tracker a un canal privado, por favor escribe",
  "components.inviteBotToPrivateChannel.descriptionTwo": "en el canal y actualiza la lista",
  "components.inviteBotToPrivateChannel.text": "¿Quieres seleccionar un canal privado?",
  "components.inviteBotToPrivateChannel.title": "Añadir canal privado",
  "components.leaveBox.approve": "Aprobar",
  "components.leaveBox.approvedBy": "Aprobado Por",
  "components.leaveBox.approveLeaveText": "Por favor, confirma que quieres aprobar la solicitud de ausencia.",
  "components.leaveBox.approveLeaveTitle": "Aprobar Solicitud de Permiso",
  "components.leaveBox.autoApproved": "Aprobación automática",
  "components.leaveBox.cancelLeave": "Cancelar Permiso",
  "components.leaveBox.cancelLeaveConfirm": "¿Está seguro de que desea cancelar el permiso?",
  "components.leaveBox.cancelLeaveTitle": "Cancelar Permiso",
  "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {día} other {días}}",
  "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {hora} other {horas}}",
  "components.leaveBox.daysLeft": "Días restantes:",
  "components.leaveBox.daysOut": "Eso es {value} {value, plural, =1 {día} other {días}} fuera de la oficina.",
  "components.leaveBox.deny": "Denegar",
  "components.leaveBox.denyLeaveConfirm": "Por favor, confirme que desea denegar la solicitud de permiso.",
  "components.leaveBox.denyLeaveTitle": "Denegar Solicitud de Permiso",
  "components.leaveBox.denyWithReason": "Denegar con Motivo",
  "components.leaveBox.edit": "Editar",
  "components.leaveBox.formattedLeaveInfo": "Eso es {leave} fuera de la oficina.",
  "components.leaveBox.hoursOut": "Eso es {value} {value, plural, =1 {hora} other {horas}} fuera de la oficina.",
  "components.leaveBox.leavesBoxHalfDay": "Estará ausente por {leaveTypeName} el {leaveStartDate} desde las {startHour}{hourFormat24, select, true {:00} other {}} hasta las {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayPending": "Solicitud de {leaveTypeName} el {leaveStartDate} desde las {startHour}{hourFormat24, select, true {:00} other {}} hasta las {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayToday": "Está ausente hoy desde las {startHour}{hourFormat24, select, true {:00} other {}} hasta las {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "Está ausente hoy desde las {startHour}{hourFormat24, select, true {:00h} other {}} hasta las {endHour}{hourFormat24, select, true {:00h} other {}}.",
  "components.leaveBox.leavesBoxHalfDayWithoutReason": "Tomará un permiso el {leaveStartDate} desde las {startHour}{hourFormat24, select, true {:00} other {}} hasta las {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesOffTodayInfo": "Está ausente por {leaveTypeName} desde el {leaveStartDate} hasta el {leaveEndDate}. Ambas fechas incluidas.",
  "components.leaveBox.leavesOffTodayWithoutReasonInfo": "Está ausente desde el {leaveStartDate} hasta el {leaveEndDate}. Incluyendo ambas fechas.",
  "components.leaveBox.leavesPendingInfo": "Solicitud de {leaveTypeName} desde el {leaveStartDate} hasta el {leaveEndDate}. Ambas fechas incluidas.",
  "components.leaveBox.leavesPendingOneDay": "Solicitud de {leaveTypeName} para el {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayInfo": "Estará ausente por {leaveTypeName} el {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "Estará ausente el {leaveStartDate}.",
  "components.leaveBox.reason": "Motivo",
  "components.leaveBox.toilDuration": "Duración: {leave}",
  "components.leaveForm.addLeave": "Añadir Permiso",
  "components.leaveForm.addLeaveDescription": "Use este formulario si está añadiendo un permiso para otro usuario, o si está añadiendo un permiso para usted mismo que no requiere aprobación.",
  "components.leaveForm.connectedCalendarTimezone": "La zona horaria del calendario {calendarType} conectado es {timezone}",
  "components.leaveForm.date": "Fecha",
  "components.leaveForm.editLeave": "Editar Permiso",
  "components.leaveForm.editLeaveDescription.adminAndApprover": "Puede editar las fechas de este permiso. Si desea editar el tipo de permiso, por favor cancele el permiso y añada uno nuevo.",
  "components.leaveForm.editLeaveDescription.user": "Puede editar las fechas y el motivo de este permiso. Si desea editar el tipo de permiso, por favor cancele el permiso y cree una nueva solicitud.",
  "components.leaveForm.endTime": "Hora de Finalización",
  "components.leaveForm.halfDay": "{leaveTypeName} (Medio Día)",
  "components.leaveForm.hourly": "{leaveTypeName} (Por Horas)",
  "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (Por Horas)",
  "components.leaveForm.pleaseInputReason": "Se requiere un motivo",
  "components.leaveForm.pleaseSelectEndDate": "Seleccione una Fecha de Finalización",
  "components.leaveForm.pleaseSelectLeaveType": "Seleccione un Tipo de Permiso",
  "components.leaveForm.pleaseSelectRangeTime": "Seleccione un Rango de Tiempo",
  "components.leaveForm.pleaseSelectstartDate": "Seleccione una Fecha de Inicio",
  "components.leaveForm.pleaseSelectUser": "Seleccione un Usuario",
  "components.leaveForm.pleaseSelectValidRangeTime": "Seleccione un Rango de Tiempo Válido",
  "components.leaveForm.rangeDate": "Fecha de Inicio y Finalización",
  "components.leaveForm.reason": "Motivo",
  "components.leaveForm.request": "Solicitar",
  "components.leaveForm.requestLeave": "Solicitar Permiso",
  "components.leaveForm.requestLeaveDescription": "Utilice este formulario si su solicitud necesita ser aprobada por alguien que no sea usted mismo.",
  "components.leaveForm.selectSubstituteApprover": "Seleccionar Aprobador Sustituto",
  "components.leaveForm.selectSubstituteApproverInfoMessage": "Seleccione a una persona para gestionar sus solicitudes de permiso entrantes mientras está ausente. El Aprobador Sustituto tendrá derechos limitados, ya que solo podrá aprobar o denegar solicitudes de permiso. Este paso es opcional.",
  "components.leaveForm.selectUser": "Seleccionar un Usuario",
  "components.leaveForm.startTime": "Hora de Inicio",
  "components.leaveForm.time": "Hora",
  "components.leaveForm.user": "Seleccionar un Usuario",
  "components.leavePolicyForm.accrualBiWeeklySummary": "Los usuarios acumularán {amount} ({quota}/26) días cada segundo {day}",
  "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Los usuarios acumularán {amount} ({quota}/26) horas cada segundo {day}",
  "components.leavePolicyForm.accrualCap": "Habilitar Límite de Acumulación",
  "components.leavePolicyForm.accrualCapRate": "Limitar días acumulados a",
  "components.leavePolicyForm.accrualCapRateTimes": "veces la tasa de acumulación anual",
  "components.leavePolicyForm.accrualCapTooltip": "El Límite de Acumulación restringe cuánto PTO puede acumular un empleado durante un período específico. Se define como un múltiplo de la tasa de acumulación anual, no como un número preciso.",
  "components.leavePolicyForm.accrualCapWarning": "Se ha alcanzado el Límite de Acumulación para {leaveTypeName}. La acumulación de nuevos días se reanudará una vez que se utilicen algunos días existentes.",
  "components.leavePolicyForm.accrualCapWarning2": "Este usuario ha alcanzado el límite de acumulación de {leaveTypeName} y no acumulará más días hasta que use parte del saldo actual.",
  "components.leavePolicyForm.accrualDay": "Día de Acumulación",
  "components.leavePolicyForm.accrualDayBiWeekly": "Acumulación Otorgada en",
  "components.leavePolicyForm.accrualDisabledInfo": "Desactive la cuota ilimitada y establezca la cuota en cualquier número mayor que 0 para habilitar las acumulaciones.",
  "components.leavePolicyForm.accrualMonthlySummary": "Los usuarios acumularán {amount} ({quota}/12) días el {date} de cada mes",
  "components.leavePolicyForm.accrualMonthlySummaryHours": "Los usuarios acumularán {amount} ({quota}/12) horas el {date} de cada mes",
  "components.leavePolicyForm.accruals": "Acumulaciones",
  "components.leavePolicyForm.accrualSemiMonthlySummary": "Los usuarios acumularán {amount} ({quota}/24) días el {firstDate} y el {secondDate} de cada mes",
  "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Los usuarios acumularán {amount} ({quota}/24) horas el {firstDate} y el {secondDate} de cada mes",
  "components.leavePolicyForm.accrualType": "Tipo de Acumulación",
  "components.leavePolicyForm.accrualTypeError": "Requerido",
  "components.leavePolicyForm.accrualTypeInfo": "El Permiso Acumulado se acumula gradualmente durante el año según la política de la empresa. Si su empresa tiene Acumulaciones, seleccione el Tipo de Acumulación determinado por su gestión.",
  "components.leavePolicyForm.accrualWeeklySummary": "Los usuarios acumularán {amount} ({quota}/52) días cada {day}",
  "components.leavePolicyForm.accrualWeeklySummaryHours": "Los usuarios acumularán {amount} ({quota}/52) horas cada {day}",
  "components.leavePolicyForm.addTitle": "Asignar Política de Permisos",
  "components.leavePolicyForm.allDays": "Activado",
  "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Editar y Solicitar Permisos en el Pasado",
  "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Elija si desea permitir que los usuarios regulares editen o soliciten permisos en el pasado.",
  "components.leavePolicyForm.allowAdvanceAccrualUsage": "Permitir Solicitar Permiso Basado en Acumulación por Adelantado",
  "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Permitir a los empleados solicitar permiso basado en su acumulación proyectada de días libres, en lugar de solo lo que han acumulado en el momento de la solicitud. Esto significa que si un empleado sabe que tendrá suficientes días acumulados para cuando ocurra el permiso solicitado, puede enviar la solicitud por adelantado, incluso si no tiene suficientes días en el momento de hacer la solicitud.",
  "components.leavePolicyForm.approvalRequired": "Aprobación Requerida",
  "components.leavePolicyForm.assignLeavePolicy": "Asignar Política de Permisos",
  "components.leavePolicyForm.back": "Atrás",
  "components.leavePolicyForm.BI-WEEKLY": "Quincenal",
  "components.leavePolicyForm.cancel": "Cancelar",
  "components.leavePolicyForm.copyLeavePolicy": "Copiar Política De",
  "components.leavePolicyForm.copyLeavePolicyDivider": "Puede usar una plantilla de otro permiso o ubicación.",
  "components.leavePolicyForm.copyLeavePolicyInfo": "Copie la configuración de la Política de Permisos de un Tipo de Permiso previamente establecido. En el menú desplegable, elija el Tipo de Permiso del que desea copiar la Política de Permisos, y la configuración se configurará automáticamente. Si desea hacer esto manualmente, puede hacerlo configurando los ajustes a continuación.",
  "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": "Las acumulaciones están inactivas al crear un tipo de permiso. Para activarlas, personalice la política de permisos para una ubicación específica.",
  "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "TOIL (Tiempo Libre Compensatorio) está inactivo al crear un tipo de permiso. Para activarlo, personalice la política de permisos para una ubicación específica.",
  "components.leavePolicyForm.currentAccrualPeriod": "Período de Acumulación Actual",
  "components.leavePolicyForm.daysPerYear": "Cuota Anual de Permisos",
  "components.leavePolicyForm.defaultLeavePolicy": "No copiar, comenzaré una nueva política",
  "components.leavePolicyForm.editTitle": "Editar Política de Permisos {leavePolicyName} para {locationName}",
  "components.leavePolicyForm.futureBroughtForward": "Días transferidos en el futuro",
  "components.leavePolicyForm.halfDays": "Permitir medios días",
  "components.leavePolicyForm.hasUnlimitedDays": "Cuota ilimitada",
  "components.leavePolicyForm.hideLeaveType": "Ocultar tipo de permiso",
  "components.leavePolicyForm.hideLeaveTypeInfo": "Elija si desea ocultar este tipo de permiso del calendario y las notificaciones. Solo los administradores, aprobadores y el usuario que ha solicitado el tipo de permiso podrán identificarlo en el calendario.",
  "components.leavePolicyForm.hourlyIncrementsTooltip": "Permita que los miembros de su equipo soliciten permisos por horas.",
  "components.leavePolicyForm.isActive": "Activo",
  "components.leavePolicyForm.leavePolicy": "Política de permisos",
  "components.leavePolicyForm.limitedNumberOfDays": "Limitado",
  "components.leavePolicyForm.maxRolloverDays": "Máximo de días transferidos",
  "components.leavePolicyForm.maxRolloverDaysError": "Obligatorio",
  "components.leavePolicyForm.maxRolloverDaysInfo": "Seleccione el número de días que se pueden transferir al siguiente año calendario para este tipo de permiso.",
  "components.leavePolicyForm.maxRolloverDaysInfoHours": "Seleccione el número de horas que se pueden transferir al siguiente año calendario para este tipo de permiso.",
  "components.leavePolicyForm.MONTHLY": "Mensual",
  "components.leavePolicyForm.negativeBalance": "Permitir saldo negativo",
  "components.leavePolicyForm.negativeBalanceInfo": "Elija si desea autorizar a los usuarios a exceder la cuota de permiso permitida que ha establecido para este tipo de permiso. Si no desea permitir esta opción, deje el botón desmarcado.",
  "components.leavePolicyForm.nextAccrualDay": "Próxima acumulación otorgada el",
  "components.leavePolicyForm.noLeaveTypes": "No hay tipos de permiso disponibles. Para agregar un nuevo tipo de permiso con una política de permiso personalizada, vaya a la pestaña Tipos de permiso para crear un nuevo tipo de permiso y luego agréguelo a la ubicación.",
  "components.leavePolicyForm.NONE": "Ninguno",
  "components.leavePolicyForm.noRollover": "Desactivado",
  "components.leavePolicyForm.override.affectedUsers": "Usuarios afectados: <strong>{totalUsers}</strong>",
  "components.leavePolicyForm.override.change": "Cambio: <unlimited>Cambio de política de permiso ilimitado</unlimited><customDays>Actualización de cuota de permiso</customDays>",
  "components.leavePolicyForm.override.customLeaveQuotaInfo": "Algunos de los usuarios afectados (<strong>{totalUsers}</strong>) tienen cuotas de permiso personalizadas. ¿Desea anular esta configuración?",
  "components.leavePolicyForm.override.leaveType": "Tipo de permiso: <strong>{name}</strong>",
  "components.leavePolicyForm.override.location": "Ubicación: <strong>{name}</strong>",
  "components.leavePolicyForm.override.note": "Nota: Este cambio afectará a todos los usuarios sin una cuota de permiso personalizada, sin importar la opción que elija.",
  "components.leavePolicyForm.override.overrideDisable": "Anular su cuota de permiso personalizada",
  "components.leavePolicyForm.override.overrideEnable": "Mantener la cuota de permiso existente",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Pausar acumulaciones cuando el usuario está desactivado",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Active esta función para suspender temporalmente las acumulaciones de días de permiso para cualquier usuario que esté desactivado. Una vez que el usuario sea reactivado, su acumulación de días de permiso continuará automáticamente, asegurando un seguimiento preciso de sus derechos de permiso",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "De acuerdo con las políticas de su organización, la acumulación de permisos se pausa mientras la cuenta de este usuario está inactiva. Por favor, contacte a su administrador para más detalles.",
  "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Días transferidos en el pasado y en el futuro",
  "components.leavePolicyForm.reasonRequired": "Razón requerida",
  "components.leavePolicyForm.required": "Obligatorio",
  "components.leavePolicyForm.rolloverDaysPerYear": "Número máximo de días",
  "components.leavePolicyForm.rolloverPerYearDays": "Número máximo de días",
  "components.leavePolicyForm.rolloverPerYearHours": "Número máximo de horas",
  "components.leavePolicyForm.rolloverSettings": "Configuración de transferencia",
  "components.leavePolicyForm.rolloverType": "Transferido",
  "components.leavePolicyForm.save": "Guardar",
  "components.leavePolicyForm.saveLeavePolicy": "Guardar",
  "components.leavePolicyForm.selectLeaveType": "Seleccionar tipo de permiso",
  "components.leavePolicyForm.SEMI-MONTHLY": "Bimensual",
  "components.leavePolicyForm.updateLeavePolicy": "Actualizar",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "¿Cuándo le gustaría aplicar estos cambios?",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "Seleccione el plazo para aplicar sus cambios de permiso. Elija 'Futuros transferidos' para ajustar solo las fechas de permiso próximas, o 'Anteriores transferidos y futuros' para modificar tanto las fechas pasadas como las futuras.",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "¿Cuándo le gustaría aplicar estos cambios?",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "Seleccione el plazo para aplicar sus cambios de permiso. Elija 'Futuros transferidos' para ajustar solo las fechas de permiso próximas, o 'Anteriores transferidos y futuros' para modificar tanto las fechas pasadas como las futuras.",
  "components.leavePolicyForm.yearlyQuota": "Cuota anual de permisos",
  "components.leavePolicyForm.yearlyQuotaHours": "Cuota anual de permisos (Horas)",
  "components.leavePolicyForm.yearlyQuotaInfo": "Este campo representa la cuota base anual de permisos. Podrá ajustar las cuotas individuales manualmente a través de los perfiles de usuario o automatizar los ajustes en diferentes usuarios con nuestra función de automatizaciones.",
  "components.leaveRequestAction.added": "Permiso <forUser>para </forUser>{requestor} añadido <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.addToCalendar": "Añadir al Calendario",
  "components.leaveRequestAction.addToCalendarMoreInfo": "Más información sobre la función \"Añadir al Calendario\"",
  "components.leaveRequestAction.approved": "Permiso de {requestor} aprobado <byActionCreator>por {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.approversLabel": "Aprobadores: ",
  "components.leaveRequestAction.autoApproved": "El permiso de {requestor} ha sido aprobado automáticamente",
  "components.leaveRequestAction.cancelled": "Permiso de {requestor} cancelado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.created": "Permiso solicitado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.deleted": "Permiso eliminado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.denied": "Permiso de {requestor} denegado <byActionCreator>por {actionCreator}</byActionCreator> ❌",
  "components.leaveRequestAction.edited": "Permiso de {requestor} editado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedApproved": "Un permiso programado ha sido editado <byActionCreator>por {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.editedAutoApproved": "Permiso editado aprobado automáticamente <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedDenied": "Permiso editado denegado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate} ",
  "components.leaveRequestAction.leaveRemaining": "Días restantes: ",
  "components.leaveRequestAction.leaveRequested": "Días solicitados: ",
  "components.leaveRequestAction.leaveTypeLabel": "Tipo de permiso: ",
  "components.leaveRequestAction.partDayLeavePeriod": "{date} - de {partDayStartHour}{hourFormat24, select, true {:00} other {}}  a {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.leaveRequestAction.periodLabel": "Fecha: ",
  "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "Has aprobado la solicitud. Se enviará una notificación a {name} para informarle.",
  "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "La solicitud de permiso ha sido aprobada por {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "La solicitud de permiso ha sido cancelada.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "Has denegado la solicitud. Se enviará una notificación a {name} para informarle.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "La solicitud de permiso ha sido denegada por {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "La solicitud ha expirado.",
  "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "La solicitud de permiso ha sido aprobada.",
  "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "La solicitud de permiso ha sido denegada.",
  "components.leavesColumns.approver": "Aprobador",
  "components.leavesColumns.autoApproved": "Aprobado automáticamente",
  "components.leavesColumns.cancelledBeforeReview": "Cancelado antes de la revisión",
  "components.leavesColumns.dates": "Fecha(s)",
  "components.leavesColumns.days": "{value} días",
  "components.leavesColumns.denyReason": "Motivo del aprobador",
  "components.leavesColumns.duration": "Duración",
  "components.leavesColumns.halfDayHoliday": "(Medio día festivo)",
  "components.leavesColumns.hours": "{value} horas",
  "components.leavesColumns.leaveType": "Tipo de permiso",
  "components.leavesColumns.reason": "Motivo del solicitante",
  "components.leavesColumns.reviewedby": "Revisado por",
  "components.leavesColumns.status": "Estado",
  "components.leaveTypeForm.cancel": "Cancelar",
  "components.leaveTypeForm.color": "Color",
  "components.leaveTypeForm.editLeaveTypeTitle": "Editar tipo de permiso {leaveTypeName}",
  "components.leaveTypeForm.forbiddenCharacters": "El nombre del tipo de permiso no puede contener signos de puntuación ni caracteres especiales.",
  "components.leaveTypeForm.isActive": "Activo",
  "components.leaveTypeForm.maxLength": "El nombre del tipo de permiso es demasiado largo.",
  "components.leaveTypeForm.name": "Nombre",
  "components.leaveTypeForm.nameIsRequired": "Este campo es obligatorio.",
  "components.leaveTypeForm.save": "Guardar",
  "components.leaveTypeForm.setSlackStatus": "Establecer Estado de Slack",
  "components.leaveTypeForm.setSlackStatusPlaceholder": "Por favor, establezca el estado de Slack",
  "components.leaveTypeForm.slackStatus": "Estado de Slack",
  "components.leaveTypeForm.title": "Tipo de Permiso",
  "components.leaveTypeForm.tooltipElement1": "<strong>Aprobación Requerida</strong>: Esta opción se puede activar si un permiso requiere la aprobación de un gerente.",
  "components.leaveTypeForm.tooltipElement2": "<strong>Permitir Saldo Negativo</strong>: Esta opción permite a los usuarios solicitar permisos incluso después de haber agotado su cuota.",
  "components.leaveTypeForm.tooltipElement3": "<strong>Permitir Medios Días</strong>: Esta opción se puede activar según las necesidades de su organización.",
  "components.leaveTypeForm.tooltipFooter": "Recuerde, es importante tener en cuenta que las Políticas de Permisos se definen por ubicación. Puede personalizar estas políticas de acuerdo con las necesidades de su organización y asignarlas a los tipos de permiso apropiados.",
  "components.leaveTypeForm.tooltipHeader": "En Vacation Tracker, los 'Tipos de Permiso' se refieren a categorías globales de permisos como Tiempo Libre Pagado, Día de Enfermedad, Trabajo desde Casa, etc. Por otro lado, las 'Políticas de Permiso' son reglas y configuraciones específicas que se asignan a cada Tipo de Permiso por ubicación. Estas políticas pueden incluir varias opciones como:",
  "components.legacyPlanAlert.warning": "Actualmente está utilizando el plan heredado. Si cambia de plan, no podrá activarlo nuevamente. Para más información, por favor contacte a nuestro <link>soporte</link>.",
  "components.locationForm.changeLocationWarning": "Seleccione la Ubicación a la que desea transferir al Usuario.",
  "components.locationForm.changeUserLocation": "Cambiar Ubicación del Usuario",
  "components.locationForm.days": "Días",
  "components.locationForm.daysAfterEmploymentDate": "días después de la fecha de empleo.",
  "components.locationForm.daysInfo": "La transferencia de permisos no utilizados al siguiente año calendario caducará después del número de días que haya establecido después de la Fecha de Empleo del Usuario. La Cuota de Permisos para cada Tipo de Permiso se configura a través de la pestaña Tipos de Permiso en la Configuración de Ubicación, incluyendo la opción de habilitar o deshabilitar la transferencia.",
  "components.locationForm.defaultLocation": "Hacer Esta Ubicación Predeterminada",
  "components.locationForm.firstDayOfWeek": "La Semana Comienza En",
  "components.locationForm.fiscalYear": "Año Contable",
  "components.locationForm.fiscalYearStart": "Inicio del Año Fiscal",
  "components.locationForm.name": "Nombre",
  "components.locationForm.noRolloverExpiryDate": "Sin Fecha de Caducidad para Días Transferidos",
  "components.locationForm.overrideUserSettings": "Anular Configuraciones de Usuario",
  "components.locationForm.overrideUserSettingsInfo": "La configuración de la semana laboral se aplicará solo a los Usuarios que no tengan Configuraciones personalizadas en su Perfil de Usuario. Si desea aplicar la nueva configuración de Semana Laboral a todos los Usuarios, incluidos aquellos que tienen una Semana Laboral personalizada, seleccione la opción \"Anular Configuraciones de Usuario\".",
  "components.locationForm.resetQuotas": "Reinicio de Cuota de Permisos Basado En",
  "components.locationForm.resetQuotasInfo": "Esta configuración determinará si su saldo de permisos se restablecerá según el año contable (año fiscal de la empresa) o según la fecha de inicio del empleado. Además de las cuotas, su política de transferencia también se verá afectada según esta configuración.",
  "components.locationForm.rolloverExpiryAfterDays": "Sin Fecha de Caducidad para Días Transferidos",
  "components.locationForm.rolloverExpiryAfterDaysInfo": "Cada año, los permisos transferidos de los usuarios caducarán después de estos días a partir de su fecha de empleo. Las cuotas para cada tipo de permiso se configuran a través de la sección Tipos de Permiso para esta ubicación y cada una se puede configurar individualmente para permitir o no permitir transferencias.",
  "components.locationForm.rolloverExpiryDay": "Sin Fecha de Caducidad para Días Transferidos",
  "components.locationForm.rolloverExpiryDayInfo": "Cada año, los permisos transferidos del usuario caducarán en la fecha que usted establezca. Las cuotas para cada tipo de permiso se configuran a través de la sección Tipos de Permiso para esta ubicación y cada una se puede configurar individualmente para permitir o no permitir transferencias.",
  "components.locationForm.selectLocation": "Seleccionar Ubicación",
  "components.locationForm.selectLocationError": "Por favor, seleccione una ubicación.",
  "components.locationForm.settings.makeDefaultDescription": "Al hacer esta Ubicación la predeterminada, todos los nuevos miembros del equipo se agregarán automáticamente a esta Ubicación.",
  "components.locationForm.settings.makeDefaultTitle": "Ubicación Predeterminada",
  "components.locationForm.settings.nameDescription": "Establezca el nombre de su ubicación y seleccione sus usuarios. Si desea configurar la ubicación antes de agregar usuarios, puede crear la ubicación primero y agregar usuarios más tarde.",
  "components.locationForm.settings.nameDescription2": "Esta zona horaria predeterminada se utiliza en todo el sistema. Por ejemplo, para mostrar con precisión la información de permisos en el calendario y para los eventos del sistema listados en los Registros.",
  "components.locationForm.settings.namePlaceholder": "Ingrese el nombre de la ubicación",
  "components.locationForm.settings.nameTitle": "Configuración de Ubicación",
  "components.locationForm.settings.quotasDescription": "Esta configuración determinará si su saldo de permisos se restablecerá según el año contable (año fiscal de la empresa) o según la fecha de inicio del empleado. Además de las cuotas, su política de transferencia también se verá afectada según esta configuración.",
  "components.locationForm.settings.quotasTitle": "Cuota de Permisos y Año Fiscal",
  "components.locationForm.settings.workWeekDescription": "Por favor, seleccione los días laborables de la semana para asegurar un seguimiento preciso de los datos de permisos.",
  "components.locationForm.settings.workWeekTitle": "Semana Laboral y Día Laboral",
  "components.locationForm.users": "Usuarios",
  "components.locationForm.userYear": "Fecha de Empleo del Usuario",
  "components.locationForm.warningInitialRollover": "Cualquier cambio en la configuración de la ubicación podría afectar la configuración de los usuarios y la información de permisos, como la cuota de permisos y los cálculos. \nTenga en cuenta que si tiene una fecha de caducidad de transferencia establecida en cualquier política de permisos en esta ubicación, será desactivada.",
  "components.locationForm.warningInitialRolloverFiscalYear": "Cualquier cambio en la configuración de la ubicación podría afectar la configuración de los usuarios y la información de permisos, como la cuota de permisos y los cálculos.",
  "components.locationForm.warningInitialRolloverResetQuotas": "Cualquier cambio en la configuración de la ubicación podría afectar la configuración de los usuarios y la información de permisos, como la cuota de permisos y los cálculos.",
  "components.locationForm.warningInitialRolloverUsers": "Agregar o eliminar un usuario podría afectar la configuración del usuario y la información de permisos (por ejemplo, los días iniciales transferidos).",
  "components.locationForm.workWeek": "Semana Laboral",
  "components.locationHolidays.addHolidays": "Agregar un Día Festivo",
  "components.locationHolidays.deleteHolidaysConfirm": "¿Está seguro de que desea eliminar el día festivo {name}?",
  "components.locationHolidays.deleteHolidaysTitle": "Eliminar Festivo",
  "components.locationHolidays.holidaysForLocation": "Festivos para la ubicación {locationName}",
  "components.locationHolidays.insertHolidays": "Importar Festivos",
  "components.locationHolidays.overlap": "Este festivo se superpone con uno existente",
  "components.locationLeavePolicies.activatingLeaveTypeInfo": "Al activar este Tipo de Permiso, estás permitiendo a los Usuarios solicitar el Tipo de Permiso específico. \n\nSe restaurarán las configuraciones y datos anteriores.",
  "components.locationLeavePolicies.activatingLeaveTypeTitle": "Activar Tipo de Permiso",
  "components.locationLeavePolicies.allowHalfDays": "Permitir Medios Días",
  "components.locationLeavePolicies.createNewLeavePolicy": "Asignar Política de Permisos",
  "components.locationLeavePolicies.createNewLeavePolicyInfo": "Para añadir un Tipo de Permiso personalizado a una Ubicación, ve a Asignar Política de Permisos. Selecciona el Tipo de Permiso que ya has creado a través de la pestaña Tipos de Permiso y configura los ajustes de la Política de Permisos.",
  "components.locationLeavePolicies.daysPerYear": "Cuota Anual de Permisos",
  "components.locationLeavePolicies.deactivateLeaveTypeInfo": "Una vez que desactives este Tipo de Permiso, los Usuarios en esta Ubicación ya no podrán solicitar este Tipo de Permiso específico. Cuando lo actives de nuevo, el Tipo de Permiso se restaurará con sus configuraciones y datos anteriores. \n\nSi quieres desactivar este Tipo de Permiso para todas las Ubicaciones, puedes hacerlo desde la pestaña Tipos de Permiso.",
  "components.locationLeavePolicies.deactivateLeaveTypeTitle": "Desactivar Tipo de Permiso",
  "components.locationLeavePolicies.hasUnlimitedDays": "Ilimitado",
  "components.locationLeavePolicies.isApprovalRequired": "Aprobación Requerida",
  "components.locationLeavePolicies.leaveTypeName": "Nombre del Tipo de Permiso",
  "components.locationLeavePolicies.status": "Estado",
  "components.locationLeavePolicy.createInProgress": "Creando la política de permisos {name}",
  "components.locationLeavePolicy.successTitle": "La política de permisos está establecida",
  "components.locationLeavePolicy.updateInProgress": "Actualizando la política de permisos",
  "components.locationLeaveTypesTag.hiddenLeaveType": "Tipo de Permiso Oculto",
  "components.locationLeaveTypesTag.holidays": "Festivos",
  "components.logs.accrual": "El usuario acumuló {earned} {leaveTypeName} {earnedDayInGrammaticalNumber} (de una cuota de {defaultDaysPerYear} {defaultDayInGrammaticalNumber})",
  "components.logs.accrualDays": "El usuario acumuló {earned} {leaveTypeName} {earned, plural, =1 {día} other {días}} (de una cuota de {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {día} other {días}})",
  "components.logs.accrualDisplay": "El usuario acumuló {earned} {leaveTypeName} (de una cuota de {defaultDaysPerYear})",
  "components.logs.accrualHours": "El usuario acumuló {earned} {leaveTypeName} {earned, plural, =1 {hora} other {horas}} (de una cuota de {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {hora} other {horas}})",
  "components.logs.automationAddonsUpdatedPurchased": "{user} compró el Complemento: {automationType}",
  "components.logs.automationAddonsUpdatedUnsubscribed": "{user} canceló la suscripción al Complemento: {automationType}",
  "components.logs.billingCancelled": "Facturación cancelada en {paymentProcessor}",
  "components.logs.billingUpdated": "Facturación actualizada en {paymentProcessor}",
  "components.logs.blackoutPeriodCreated": "{user} creó un Período de Bloqueo <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Recurrente: {recurring}.<br></br>Fecha de inicio: {startDate}.<br></br>Fecha de finalización: {endDate}.<br></br>Tipos de Permiso: {leaveTypes}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.blackoutPeriodDeleted": "{user} eliminó un Período de Bloqueo.",
  "components.logs.blackoutPeriodUpdated": "{user} actualizó un Período de Bloqueo <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Recurrente: {recurring}.<br></br>Fecha de inicio: {startDate}.<br></br>Fecha de finalización: {endDate}.<br></br>Tipos de Permiso: {leaveTypes}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.companyCreated": "La empresa <b>{name}</b> ha sido creada",
  "components.logs.companySettingsUpdated": "<b>{user}</b> actualizó la configuración de la empresa.<br></br>Anunciar nuevos usuarios: {announceNewUsers}.<br></br>Contabilidad de permisos por horas: {hourlyLeaveAccounting}.<br></br>Fecha de finalización del usuario: {userEndDate}.<br></br>Año de inicio de cálculo: {calculationStartYear}<br></br>Importar usuarios automáticamente: {importUsersAutomatically}",
  "components.logs.deleted": "<Eliminado>",
  "components.logs.earned": "El usuario ganó {total} días de {leaveTypeName} por trabajo extra (TOIL) {period}. {showExpiration, plural, =1 {Los días expirarán el } other {}}{expiration}",
  "components.logs.entitlementByRoleCreated": "{user} creó un Derecho por Rol <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Días: {days}.<br></br>Etiqueta: {label}.<br></br>Tipo de Permiso: {leaveType}.<br></br>",
  "components.logs.entitlementByRoleDeleted": "{user} eliminó un Derecho por Rol.",
  "components.logs.entitlementByRoleUpdated": "{user} actualizó un Derecho por Rol <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Días: {days}.<br></br>Etiqueta: {label}.<br></br>Tipo de Permiso: {leaveType}.<br></br>",
  "components.logs.failedToLoadLog": "No se pudo cargar el registro",
  "components.logs.importLeaves": "<b>{user}</b> importó {totalLeaves} {totalLeaves, plural, =1 {permiso} other {permisos}}.",
  "components.logs.importUsers": "<b>{user}</b> importó {totalUsers} {totalUsers, plural, =1 {usuario} other {usuarios}}.",
  "components.logs.initialRolloverDeleted": "Traspaso Inicial para el Tipo de Permiso: {leaveTypeName} restablecido a 0 por <link>{creatorName}</link>",
  "components.logs.initialRolloverUpdated": "Traspaso Inicial para el Tipo de Permiso: {leaveTypeName} cambiado a {customRolloverDays} por <link>{creatorName}</link>",
  "components.logs.leavePolicyDisabled": "{user} deshabilitó la política de permisos <b>{leaveTypeName}</b> en la ubicación <b>{locationName}</b>",
  "components.logs.leavePolicyEnabled": "{user} habilitó la política de permisos <b>{leaveTypeName}</b> en la ubicación <b>{locationName}</b>.<br></br>Días por año: {daysPerYear}.<br></br>Máximo de días de traspaso: {maxRolloverDays}.<br></br>¿Deben expirar los días de traspaso?: {enableRolloverExpiry}.<br></br>Los días de traspaso expiran el: {rolloverExpiryDate}.<br></br>Los días de traspaso expiran después de días: {rolloverExpiryAfterDays}.<br></br>Aprobación requerida: {isApprovalRequired}.<br></br>Días ilimitados: {hasUnlimitedDays}.<br></br>Intervalo mínimo de permiso: {shortestLeaveInterval}.<br></br>Razón requerida: {isReasonRequired}.<br></br>Tipo de acumulación: {accrualType}.<br></br>Saldo negativo permitido: {negativeBallanceAllowed}.<br></br>Permitir permisos en el pasado: {allowLeaveInPast}.<br></br>Límite de acumulación: {accrualCapRate, plural, =0 {Deshabilitado} other {Habilitado con tasa {accrualCapRate}}}.<br></br>Ocultar tipo de permiso: {hideLeaveType}.<br></br>TOIL habilitado: {toil}.<br></br>Intervalo mínimo de TOIL: {toilShortestInterval}.<br></br>TOIL para todos los usuarios: {toilRequestsAllowedForUsers}.<br></br>Expiración de TOIL: {toilExpiration}.<br></br>Fecha de expiración de TOIL: {toilExpirationDate}.<br></br>Meses de expiración de TOIL: {toilExpirationMonths}.",
  "components.logs.leaveTypeCreated": "{user} creó el tipo de permiso <b>{name}</b> y lo asignó a la(s) ubicación(es): <b>{locations}</b>.<br></br>Color: {color}.<br></br>Días por año: {daysPerYear}.<br></br>Número máximo de días de traspaso: {maxRolloverDays}.<br></br>Aprobación requerida: {isApprovalRequired}.<br></br>Días ilimitados: {hasUnlimitedDays}.<br></br>Intervalo mínimo de permiso: {shortestLeaveInterval}.<br></br>Razón requerida: {isReasonRequired}.<br></br>Tipo de acumulación: {accrualType}.<br></br>Saldo negativo permitido: {negativeBallanceAllowed}.<br></br>Permitir añadir permisos en el pasado: {allowLeaveInPast}.<br></br>Ocultar tipo de permiso: {hideLeaveType}",
  "components.logs.leaveTypeDeleted": "{user} eliminó el tipo de permiso <b>{name}</b>",
  "components.logs.leaveTypeUpdated": "{user} actualizó el tipo de permiso <b>{name}</b>.<br></br>Activo: {isActive}.<br></br>Color: {color}",
  "components.logs.locationCreated": "{user} creó una ubicación <b>{name}</b>.<br></br>Mes de inicio del año: {yearStartMonth}.<br></br>Día de inicio del año: {yearStartDay}.<br></br>Ubicación predeterminada: {isDefault}.<br></br>Primer día de la semana: {firstDayOfWeek}.<br></br>Reinicio de cuota basado en: {resetQuotas}.<br></br>Mes de expiración del traspaso: {rolloverExpiryMonth}.<br></br>Día de expiración del traspaso: {rolloverExpiryDay}.<br></br>Días de expiración del traspaso: {rolloverExpiryAfterDays}.<br></br>Zona horaria: {timezone}.<br></br>Usuarios: {users}.<br></br>Días: {days}",
  "components.logs.locationDeleted": "{user} eliminó la ubicación",
  "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> desactivó la importación automática de días festivos para la ubicación <b>{location}</b>",
  "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> activó la importación automática de días festivos para la ubicación <b>{location}</b><br></br>Días festivos: {holidays}",
  "components.logs.locationUpdated": "{user} actualizó la ubicación <b>{name}</b>.<br></br>Mes de inicio del año: {yearStartMonth}.<br></br>Día de inicio del año: {yearStartDay}.<br></br>Ubicación predeterminada: {isDefault}.<br></br>Primer día de la semana: {firstDayOfWeek}.<br></br>Reiniciar cuotas: {resetQuotas}.<br></br>Mes de vencimiento de traslado: {rolloverExpiryMonth}.<br></br>Día de vencimiento de traslado: {rolloverExpiryDay}.<br></br>Vencimiento de traslado después de días: {rolloverExpiryAfterDays}.<br></br>Zona horaria: {timezone}.<br></br>Usuarios: {users}",
  "components.logs.locationWorkWeekUpdated": "{user} actualizó la semana laboral en la ubicación <b>{name}</b>.<br></br>Días: {days}.<br></br>Horas: {hours}",
  "components.logs.locationYearHolidaysChanged": "<b>{user}</b> cambió los día(s) festivo(s) para el año {year} en la ubicación <b>{name}</b>.<br></br>Días festivos: {holidays}",
  "components.logs.locationYearRollover": "El traslado de año de ubicación ocurrió en la ubicación <b>{name}</b>",
  "components.logs.nonAccrualLeaveType": "El usuario recibió {earned} <b>{leaveTypeName}</b> para {period}",
  "components.logs.nonAccrualLeaveTypeDays": "El usuario recibió {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {día} other {días}} para {period}",
  "components.logs.nonAccrualLeaveTypeHours": "El usuario recibió {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {hora} other {horas}} para {period}",
  "components.logs.notificationCreated": "El usuario {user} creó una notificación <b>{name}</b>.<br></br>Frecuencia: {frequency}.<br></br>Habilitado: {enabled}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>Primer día de la semana: {firstDayOfWeek}.<br></br>Zona horaria: {timezone}.<br></br>Agrupar por {groupBy}.<br></br>Enviar vacío: {sendEmpty}.<br></br>Destinos: {destinations}.<br></br>Minuto: {minute}.<br></br>Hora: {hour}.<br></br>Día: {day}.<br></br>Idioma: {locale}.<br></br>Enviar en días: {sendOnDays}.<br></br>Semana actual: {currentWeek}",
  "components.logs.notificationDeleted": "El usuario {user} eliminó la notificación",
  "components.logs.notificationDetailsDaily": "El usuario {user} {action} notificación <b>{name}</b>.<br></br>Frecuencia: {frequency}.<br></br>Habilitado: {enabled}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>Zona horaria: {timezone}.<br></br>Agrupar por {groupBy}.<br></br>Enviar vacío: {sendEmpty}.<br></br>Destinos: {destinations}.<br></br>Minuto: {minute}.<br></br>Hora: {hour}.<br></br>Idioma: {locale}.<br></br>Enviar en días: {sendOnDays}.",
  "components.logs.notificationDetailsMonthly": "El usuario {user} {action} notificación <b>{name}</b>.<br></br>Frecuencia: {frequency}.<br></br>Habilitado: {enabled}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>Primer día de la semana: {firstDayOfWeek}.<br></br>Zona horaria: {timezone}.<br></br>Agrupar por {groupBy}.<br></br>Enviar vacío: {sendEmpty}.<br></br>Destinos: {destinations}.<br></br>Minuto: {minute}.<br></br>Hora: {hour}.<br></br>Día: {day}.<br></br>Idioma: {locale}.<br></br>Semana actual: {currentWeek}",
  "components.logs.notificationUpdated": "El usuario {user} actualizó una notificación <b>{name}</b>.<br></br>Frecuencia: {frequency}.<br></br>Habilitado: {enabled}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>Primer día de la semana: {firstDayOfWeek}.<br></br>Zona horaria: {timezone}.<br></br>Agrupar por {groupBy}.<br></br>Enviar vacío: {sendEmpty}.<br></br>Destinos: {destinations}.<br></br>Minuto: {minute}.<br></br>Hora: {hour}.<br></br>Día: {day}.<br></br>Idioma: {locale}.<br></br>Enviar en días: {sendOnDays}.<br></br>Semana actual: {currentWeek}",
  "components.logs.probationPeriodCreated": "{user} creó un Período de Prueba <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Duración (días): {lengthDays}.<br></br>Tipos de Ausencia: {leaveTypes}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.probationPeriodDeleted": "{user} eliminó un Período de Prueba.",
  "components.logs.probationPeriodUpdated": "{user} actualizó un Período de Prueba <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Duración (días): {lengthDays}.<br></br>Tipos de Ausencia: {leaveTypes}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.seniorityEntitlementCreated": "{user} creó un Derecho por Antigüedad <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Prorrateado: {prorated}.<br></br>Fecha de inicio: {startDate}.<br></br> Tipo de Ausencia: {leaveType}.<br></br>Otorgado: {entitlementPeriods}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.seniorityEntitlementDeleted": "{user} eliminó un Derecho por Antigüedad.",
  "components.logs.seniorityEntitlementUpdated": "{user} actualizó el Derecho por Antigüedad <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Prorrateado: {prorated}.<br></br>Fecha de inicio: {startDate}.<br></br> Tipo de Ausencia: {leaveType}.<br></br>Otorgado: {entitlementPeriods}.<br></br>Ubicaciones: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.subscriptionCancelled": "<b>{user}</b> canceló la suscripción.<br></br>Razón: {reason}.<br></br>Comentario: {comment}",
  "components.logs.subscriptionChanged": "Suscripción cambiada.<br></br>Nuevo plan: {plan}.<br></br>Período: {period}.<br></br>Estado: {status}.<br></br>Procesador de pago: {paymentProcessor}",
  "components.logs.substituteApproverCreated": "{substituteApproverName} fue establecido como Aprobador Sustituto para {approverFor} por {creator} desde {startDate} hasta {endDate}",
  "components.logs.substituteApproverDeleted": "{creator} eliminó a {substituteApproverName} como Aprobador Sustituto.",
  "components.logs.substituteApproverUpdated": "{creator} actualizó el aprobador sustituto.<br></br>Nombre del Aprobador Sustituto: {substituteApproverName}<br></br>Fecha de inicio: {startDate}<br></br>Fecha de finalización: {endDate}<br></br>",
  "components.logs.teamApproverStatusChanged": "{user} estableció aprobadores {approvers} para el equipo {team}",
  "components.logs.teamCreated": "{user} creó un departamento <b>{team}</b>.<br></br>Departamento predeterminado: {isDefault}.<br></br>Usuarios: {users}.<br></br>Aprobador(es): {approvers} <br></br>Departamento padre: {parentDepartment}",
  "components.logs.teamDeleted": "{user} eliminó el equipo",
  "components.logs.teamUpdated": "{user} actualizó un departamento <b>{team}</b>.<br></br>Departamento predeterminado: {isDefault}.<br></br>Usuarios: {users}.<br></br>Aprobador(es): {approvers} <br></br>Departamento padre: {parentDepartment}",
  "components.logs.userActivatedBy": "El usuario fue activado por <link>{creatorName}</link>",
  "components.logs.userApproverStatus": "El usuario es <status></status> como aprobador para el departamento <team>{teamName}</team> por <link>{creatorName}</link>",
  "components.logs.userChangedAvatar": "El usuario actualizó su foto de perfil",
  "components.logs.userDeactivatedBy": "El usuario fue desactivado por <link>{creatorName}</link>",
  "components.logs.userDeletedBy": "El usuario fue eliminado por <link>{creatorName}</link>",
  "components.logs.userEntitlementByRole": "Al usuario se le otorgaron {totalDays} día(s) de {leaveTypeName} a través del Derecho por Rol",
  "components.logs.userEntitlementByRoleDeleted": "Los días de Derecho por Rol del usuario para {leaveTypeName} fueron eliminados debido a la eliminación de la etiqueta <b>{labelName}</b>.",
  "components.logs.userEntitlementByRoleOverride": "Los días de derecho del usuario para {leaveTypeName} han sido anulados a {totalDays} día(s) debido a ajustes en el Derecho por Rol",
  "components.logs.userInvitationAccepted": "El usuario aceptó la invitación de <link>{creatorName}</link>",
  "components.logs.userLeaveTypeDeleteQuota": "La cuota para el tipo de ausencia <b>{leaveTypeName}</b> fue restablecida al valor predeterminado por <link>{creatorName}</link>",
  "components.logs.userLeaveTypeUnlimitedDays": "ilimitado",
  "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> ha cambiado la cuota de este usuario para <b>{leaveTypeName}</b> a <quota></quota> días",
  "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> ha cambiado la cuota de este usuario para <b>{leaveTypeName}</b> a <quota></quota> días",
  "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> ha cambiado la cuota de este usuario para <b>{leaveTypeName}</b> a <quota></quota> horas",
  "components.logs.userMovedToLocation": "El usuario fue movido a la ubicación <locationLink>{location}</locationLink> por <link>{creatorName}</link>",
  "components.logs.userMovedToTeam": "El usuario fue movido al departamento <teamLink>{team}</teamLink> por <link>{creatorName}</link>",
  "components.logs.userRolloverDays": "Trasladado del año anterior: {leaveTypeName} - {value} día{s}",
  "components.logs.userRolloverDisplay": "Trasladado del año anterior: {leaveTypeName} - {value}",
  "components.logs.userRolloverExpired": "<b>{total}</b> días de <b>{leaveTypeName}</b> del año anterior han expirado",
  "components.logs.userRolloverExpiredDays": "Días del año anterior que han expirado: <b>{leaveTypeName}</b> - {value} día{s}",
  "components.logs.userRolloverExpiredHours": "Los días del año anterior han caducado: <b>{leaveTypeName}</b> - {value} hora{s}",
  "components.logs.userRolloverHours": "Traspasado del año anterior: <b>{leaveTypeName}</b> - {value} hora{s}",
  "components.logs.userSeniorityEntitlement": "Al usuario se le otorgaron {totalDays} día(s) de {leaveTypeName} a través del Derecho por Antigüedad",
  "components.logs.userSeniorityEntitlementOverride": "Los días de derecho del usuario para {leaveTypeName} se han anulado a {totalDays} día(s) debido a ajustes del Derecho por Antigüedad",
  "components.logs.userUpdated": "Usuario <name></name> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> por <link>{creatorName}</link>",
  "components.logs.userUpdatedChangeStatusToAdmin": "rol cambiado a Administrador",
  "components.logs.userUpdatedChangeStatusToUser": "rol cambiado a Usuario",
  "components.logs.userUpdatedEndDate": "fecha de finalización cambiada a {endDate}",
  "components.logs.userUpdatedName": "Nombre cambiado a <wrappedName>{name}</wrappedName>",
  "components.logs.userUpdatedStartDate": "Fecha de inicio cambiada a {startDate}",
  "components.logs.userWorkWeek": "La semana laboral del usuario se cambió a {workDays} por <link>{creatorName}</link>",
  "components.logs.userWorkWeekDefaultLocation": "La semana laboral del usuario se restableció al valor predeterminado de la ubicación por <link>{creatorName}</link>",
  "components.logs.visibilityAddonCreated": "{user} limitó la visibilidad para la empresa.<br></br>Habilitado: {enabled}.<br></br> Nivel de visibilidad: {visibilityLevel}.<br></br>",
  "components.logs.visibilityAddonUpdated": "{user} actualizó la visibilidad limitada para la empresa.<br></br>Habilitado: {enabled}.<br></br> Nivel de visibilidad: {visibilityLevel}.<br></br>",
  "components.manageAssignLicenses.assignLicenses": "Asignar {numberOfToAssignLicenses} licencias",
  "components.manageAssignLicenses.avaliableToAssign": "Disponible para asignar: {avaliableLicenses}",
  "components.manageAssignLicenses.microsoftTeamsMessage": "Mensaje de Microsoft Teams",
  "components.manageAssignLicenses.moreUserThenLicenses": "Ha asignado {users} licencias más de las disponibles",
  "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Seleccionar acción masiva",
  "components.manageAssignLicenses.placeholderSelectRole": "Seleccionar rol",
  "components.manageAssignLicenses.roleTooltipInfo": "Asignar roles de usuario en Vacation Tracker. Para más información, visite nuestro <helpDesk>Centro de Ayuda</helpDesk>.",
  "components.manageAssignLicenses.sendWelcomeMessage": "Enviar mensaje de bienvenida",
  "components.manageAssignLicenses.totalLicenses": "Total de licencias: {numberOflicenses}",
  "components.modal.confirm": "Confirmar",
  "components.modal.verify": "Verificar",
  "components.notificationDestination.label": "Enviar a",
  "components.notificationDestination.tooltip": "Ten en cuenta que puedes enviar la Notificación a múltiples correos electrónicos, pero solo a un canal a la vez.",
  "components.notificationForm.channel": "Canal",
  "components.notificationForm.channelSlackInfo": "Las notificaciones solo pueden enviarse a los canales de los que eres parte.",
  "components.notificationForm.daily": "Diario",
  "components.notificationForm.day": "Día",
  "components.notificationForm.firstDayOfWeek": "La Semana Comienza El",
  "components.notificationForm.refreshTheList": "Actualizar la lista",
  "components.notificationForm.team": "Equipo de Microsoft Teams",
  "components.notificationForm.time": "Hora",
  "components.notificationForm.timezone": "Zona horaria",
  "components.notificationForm.weekly": "Semanal",
  "components.notificationSummaryInfo.notificationSummary": "La Notificación se enviará <to>a</to> <channelWrapper>{channelName} </channelWrapper> <and>y</and> <addressesWrapper>{numberOfEmailAddress} dirección<multiEmailAddress>es</multiEmailAddress> de correo electrónico</addressesWrapper> el {sendingDaysInfo} a las {time} horas. Incluirá información de ausencias de <danger>{noOfUsers} Usuario<multiUsers>s</multiUsers></danger> <link>(Ver Usuario<multiUsers>s</multiUsers>)</link>",
  "components.onboarding.assignLicensesTitle": "<icon></icon> Asignar Licencias",
  "components.onboarding.installMSBotButton": "Instalar el bot",
  "components.onboarding.installMSBotDescription": "Instala el Bot y las Pestañas de Vacation Tracker para gestionar fácilmente las ausencias de tu equipo en Microsoft Teams.",
  "components.onboarding.installMSBotTitle": "<icon></icon> Completar la Configuración del Bot",
  "components.onboarding.inviteFriendsDescription": "Incorpora a tu equipo para una gestión precisa y oportuna de las ausencias. (Añade al menos 3 miembros del equipo para completar este paso.)",
  "components.onboarding.inviteFriendsTitle": "<icon></icon> Invita a los Miembros de tu Equipo",
  "components.onboarding.joyride.admin.assignLeavePolicy.info1": "Como hemos mencionado antes, los Tipos de Ausencia se crean para toda la Organización. Las Políticas de Ausencia están vinculadas a las Ubicaciones. Cada Tipo de Ausencia puede configurarse para cada Ubicación asignándole una Política de Ausencia personalizada.",
  "components.onboarding.joyride.admin.assignLeavePolicy.info2": "Una vez que selecciones el Tipo de Ausencia que deseas añadir a la Ubicación, puedes asignar la Política de Ausencia. Tienes la opción de copiar la Configuración de una Política de Ausencia existente o crear una Política de Ausencia completamente nueva con nuevos valores.",
  "components.onboarding.joyride.admin.assignLeavePolicy.title": "Asignar Políticas de Ausencia",
  "components.onboarding.joyride.admin.createLocation.info1": "Si tienes miembros del equipo en diferentes ubicaciones, tienes la opción de crear múltiples Ubicaciones en Vacation Tracker y añadir Usuarios a la Ubicación correspondiente.",
  "components.onboarding.joyride.admin.createLocation.info2": "Cada Ubicación tiene sus propias Políticas de Ausencia que necesitas asignar a un determinado Tipo de Ausencia. Los días festivos también están vinculados a las Ubicaciones.",
  "components.onboarding.joyride.admin.createLocation.title": "Crear una Ubicación",
  "components.onboarding.joyride.admin.createNotifications.info1": "Ahora que has completado los pasos mencionados anteriormente, es hora de crear Notificaciones.",
  "components.onboarding.joyride.admin.createNotifications.title": "Crear y Editar Notificaciones",
  "components.onboarding.joyride.admin.createTeams.info1": "Una vez que hayas completado el Panel de control que te da una visión general de las ausencias de hoy y programadas dentro de tu organización mencionado anteriormente, es hora de crear Departamentos.",
  "components.onboarding.joyride.admin.createTeams.info2": "Al crear un Departamento, tendrás que añadir los Aprobadores. Los Aprobadores tienen el segundo nivel más alto de permisos en Vacation Tracker, y pueden aprobar/denegar solicitudes de ausencia enviadas por los Usuarios del Departamento en el que son Aprobadores.",
  "components.onboarding.joyride.admin.createTeams.title": "Crear Departamentos y Asignar Aprobadores",
  "components.onboarding.joyride.admin.helpDesk.info": "Si tienes alguna pregunta o necesitas más información, consulta nuestro Centro de Ayuda o inicia un chat con nuestro equipo de atención al cliente.",
  "components.onboarding.joyride.admin.helpDesk.title": "Centro de Ayuda",
  "components.onboarding.joyride.admin.importHolidays.info1": "Los días festivos en Vacation Tracker se establecen para cada Ubicación, y no para toda la Organización.",
  "components.onboarding.joyride.admin.importHolidays.info2": "Tienes la opción de Importar Festivos de nuestra lista de Festivos preestablecidos para cada país, y la opción de importar un Festivo manualmente.",
  "components.onboarding.joyride.admin.importHolidays.title": "Importar Festivos",
  "components.onboarding.joyride.admin.leaveTypesMenu.info1": "Siempre recomendamos que comiences tu viaje en Vacation Tracker creando Tipos de Ausencia. Asignarás estos Tipos de Ausencia más tarde cuando crees todas las ubicaciones donde tu empresa tiene empleados.",
  "components.onboarding.joyride.admin.leaveTypesMenu.info2": "Visita <link>Centro de Ayuda</link> para obtener una guía paso a paso sobre cómo crear y editar Tipos de Ausencia.",
  "components.onboarding.joyride.admin.leaveTypesMenu.title": "Crear Tipos de Ausencia",
  "components.onboarding.joyride.admin.newLeaveType.info1": "Además del Tipo de Ausencia Predeterminado (PTO), puedes crear hasta 24 Tipos de Ausencia más.",
  "components.onboarding.joyride.admin.start.title": "¡Aprende sobre Vacation Tracker! 🏖️",
  "components.onboarding.joyride.admin.start1": "Para ayudarte a comenzar, hemos reunido algunos pasos breves que te recomendamos seguir para configurar rápidamente tu organización.",
  "components.onboarding.joyride.admin.start2": "Si necesitas nuestra ayuda en el camino, estamos a solo un correo electrónico de distancia en <link>hello@vacationtracker.io</link>",
  "components.onboarding.joyride.admin.teams.cta1": "Instalar bot de Microsoft Teams",
  "components.onboarding.joyride.admin.teams.cta2": "Aprende más en nuestro Centro de Ayuda",
  "components.onboarding.joyride.admin.teams.info1": "Ya está todo listo en el panel. El último paso es instalar el bot de Microsoft Teams para que tu equipo pueda hacer solicitudes directamente en Microsoft Teams.",
  "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
  "components.onboarding.joyride.approver.dashboard.info1": "Esta página es tu centro de control. Te da una visión general de las ausencias pendientes, quién está fuera hoy y las próximas ausencias para tu organización.",
  "components.onboarding.joyride.approver.leaveRequests.info1": "Como Aprobador, puedes \"Añadir Ausencia\" para cualquier miembro del equipo del que eres Aprobador. También puedes \"Solicitar Ausencia\" para ti mismo, que se enviará para aprobación a tu Aprobador.",
  "components.onboarding.joyride.approver.notifications.info1": "Esta página ofrece una visión general de todas las Notificaciones que se envían en toda tu organización.",
  "components.onboarding.joyride.approver.notifications.info2": "Puedes crear o editar Notificaciones para Usuarios de Departamentos de los que eres Aprobador.",
  "components.onboarding.joyride.approver.notifications.info3": "Ten en cuenta que solo puedes gestionar las Notificaciones creadas por ti.",
  "components.onboarding.joyride.approver.users.info1": "La página 'Usuarios' te da una visión general completa de todos los usuarios activos dentro de tu Departamento junto con acceso de solo lectura a sus Perfiles de Usuario. Sus solicitudes pendientes están disponibles en la página 'Panel de control'.",
  "components.onboarding.joyride.user.calendar.info1": "En esta página, puedes ver el historial y las próximas ausencias de tu organización en formato de calendario.",
  "components.onboarding.joyride.user.dashboard.info1": "El Panel de control te da una visión general de las ausencias de hoy y programadas dentro de tu organización.",
  "components.onboarding.joyride.user.holidays.info1": "Aquí puedes revisar los Festivos dentro de tu Ubicación, para asegurarte de saber qué días ya tienes libres.",
  "components.onboarding.joyride.user.myProfile.info1": "Aquí puedes ver tus tipos de ausencia disponibles, cuotas, días laborables y tu historial de ausencias.",
  "components.onboarding.joyride.user.requestLeave.info1": "Para solicitar Ausencia a través del Panel de control de Vacation Tracker, selecciona uno de los tipos de ausencia disponibles, añade el período de tiempo y el motivo si es necesario, y envía la solicitud. Una vez enviada, se enviará para su aprobación al Aprobador.",
  "components.onboarding.joyride.user.start1": "Para ayudarte a comenzar, hemos reunido una lista de cinco pasos que te recomendamos seguir para configurar rápidamente tu organización.",
  "components.onboarding.openNotificationsButton": "Abrir notificaciones",
  "components.onboarding.requestALeaveDescription": "Envía tu primera solicitud de ausencia en 3 sencillos pasos.",
  "components.onboarding.requestALeaveTitle": "<icon></icon> Solicitar una Ausencia",
  "components.onboarding.setNotificationsDescription": "Elige con qué frecuencia te gustaría ser notificado sobre las ausencias de tu equipo para mantenerte informado.",
  "components.onboarding.setNotificationsTitle": "<icon></icon> Configurar Notificaciones",
  "components.onboarding.skipOnboarding": "Saltar introducción",
  "components.onboarding.syncTheCalendarDescription": "Integra el calendario de Vacation Tracker con tu calendario para que las ausencias de tu equipo estén actualizadas.",
  "components.onboarding.syncTheCalendarTitle": "<icon></icon> Sincronizar Calendarios",
  "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Video para sincronizar con Google Calendar",
  "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "Video para sincronizar con iCal",
  "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "Video para sincronizar con MS Windows",
  "components.onboarding.title": "Lista de introducción <progressBar>{progressStatus}</progressBar>",
  "components.paymentInformation.expiryDate": "Fecha de caducidad",
  "components.paymentInformation.labelCreditCard": "Tarjeta de crédito",
  "components.paymentInformation.title": "Información de pago ",
  "components.platformSwitchModal.content": "Primero debes iniciar sesión con tu cuenta de {platform} para cambiar a la plataforma {platform}. Por favor, haz clic en el botón de abajo para continuar. Después de iniciar sesión y otorgar los permisos necesarios, te redirigiremos al proceso de mapeo de usuarios.",
  "components.platformSwitchModal.title": "Migración a la plataforma {platform}",
  "components.pricePlanCard.accruals": "Acumular Ausencias",
  "components.pricePlanCard.APISupport": "Soporte de API",
  "components.pricePlanCard.calendarIntegrations": "Integración de Calendario",
  "components.pricePlanCard.contactUs": "Contáctenos",
  "components.pricePlanCard.coreDepartmentsAndLocations": "Crear hasta 3 Ubicaciones y 10 Departamentos",
  "components.pricePlanCard.currentPlan": "Plan Actual",
  "components.pricePlanCard.customContract": "Contrato personalizado",
  "components.pricePlanCard.customizeLeaveTypes": "Personalizar Tipos de Ausencia",
  "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Ubicaciones, Departamentos y Tipos de Ausencia Personalizados Ilimitados",
  "components.pricePlanCard.customOnboardingAndSetup": "Incorporación y configuración personalizadas",
  "components.pricePlanCard.dedicatedSupport": "Soporte Dedicado",
  "components.pricePlanCard.disableDowngradeToCore": "El cambio al plan Core requiere {noOfActions} {noOfActions, plural, =1 {acción} other {acciones}}",
  "components.pricePlanCard.disableDowngradeToCoreDescription": "Para cambiar al plan Core, por favor realice los siguientes ajustes:",
  "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "El plan Core permite un máximo de 10 departamentos. Por favor, elimine los departamentos adicionales.",
  "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "El plan Core permite un máximo de 3 ubicaciones. Por favor, elimine las ubicaciones adicionales.",
  "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "En el plan Core, los departamentos solo pueden anidarse un nivel de profundidad. Actualmente tiene {total} subdepartamentos. Por favor, elimine algunos subdepartamentos para cambiar al plan Core.",
  "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "No es posible cambiar al plan Core mientras se utilizan horas de trabajo personalizadas. Por favor, restablezca las horas de trabajo personalizadas al valor predeterminado (8) en la configuración de ubicaciones",
  "components.pricePlanCard.disableDowngradeToCoreTitle": "Degradación de plan. Se necesitan ajustes",
  "components.pricePlanCard.displayLeaveInformationInDaysHours": "Mostrar Información de Ausencias en Días/Horas",
  "components.pricePlanCard.downgradePlan": "El plan se degradará en el próximo ciclo de facturación",
  "components.pricePlanCard.duringTheTrial": "Durante el período de prueba, cualquier cambio de plan se implementará inmediatamente",
  "components.pricePlanCard.everythingInTheCompletePlan": "Todo lo incluido en el plan Complete",
  "components.pricePlanCard.EverythingInTheCorePlan": "Todo lo incluido en el plan Core",
  "components.pricePlanCard.exportsAndReports": "Exportaciones e Informes",
  "components.pricePlanCard.forSeats": "para menos de {users} asientos",
  "components.pricePlanCard.forUsers": "para menos de {users} usuarios",
  "components.pricePlanCard.hourlyLeaves": "Seguimiento de Ausencias por Horas",
  "components.pricePlanCard.HRFields": "Campos de RRHH (próximamente)",
  "components.pricePlanCard.labels": "Organizar Usuarios por Etiquetas",
  "components.pricePlanCard.managePTO": "Gestionar solicitudes de PTO",
  "components.pricePlanCard.minimumPlanPrice": "mínimo <price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>",
  "components.pricePlanCard.notifications": "Enviar Notificaciones de PTO",
  "components.pricePlanCard.pricePerSeat": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>por asiento/mes",
  "components.pricePlanCard.pricePerUser": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>por usuario/mes",
  "components.pricePlanCard.reports": "Generar Informes",
  "components.pricePlanCard.reportsSchedule": "Programar Informes",
  "components.pricePlanCard.requestHourlyLeaveIncrements": "Solicitar Incrementos de Ausencia por Hora",
  "components.pricePlanCard.scheduleAConsultation": "Programar una Consulta",
  "components.pricePlanCard.scheduledReport": "Informes Programados",
  "components.pricePlanCard.selectPlan": "Seleccionar Plan",
  "components.pricePlanCard.sharedCalendars": "Calendarios Compartidos",
  "components.pricePlanCard.SSOIntegration": "Integración SSO Empresarial (Okta, etc.)",
  "components.pricePlanCard.startFreeTrial": "Comience su Prueba Gratuita de 14 Días",
  "components.pricePlanCard.substituteApprovers": "Aprobadores Sustitutos",
  "components.pricePlanCard.support": "Soporte Prioritario",
  "components.pricePlanCard.switchPlan": "Cambiar <period> Al Plan {newPeriod}</period>",
  "components.pricePlanCard.totalPrice": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/mes para {totalUsers} usuarios activos",
  "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/mes para menos de 25 usuarios",
  "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/año",
  "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/año",
  "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/mes para {totalUsers} asientos",
  "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Crear Departamentos y Ubicaciones ilimitados",
  "components.pricePlanCard.upgradePlan": "El plan se activará inmediatamente y el precio será prorrateado",
  "components.requestAutomation.anythingElse": "¿Hay algo más que deberíamos saber?",
  "components.requestAutomation.automationExtraCharge": "¿Utilizarías este complemento si tuviera un cargo adicional?",
  "components.requestAutomation.automationFunctionalities": "¿Qué funcionalidades o tareas te permitirá realizar el complemento?",
  "components.requestAutomation.automationShouldWeAdd": "¿Qué complemento deberíamos agregar?",
  "components.requestAutomation.submiteRequest": "Enviar solicitud",
  "components.requestAutomation.suggestAnAutomation": "Sugerir un complemento",
  "components.resetQuotas.fiscalYear": "Año fiscal ({date})",
  "components.resetQuotas.userYear": "Fecha de contratación del usuario",
  "components.selectChannelForm.addNewEmail": "Agregar destinatario",
  "components.selectChannelForm.channel": "Canal",
  "components.selectChannelForm.channelSlackInfo": "Las notificaciones solo se pueden enviar a los canales de los que eres miembro.",
  "components.selectChannelForm.email": "Destinatarios",
  "components.selectChannelForm.errorNotification": "Error de notificación",
  "components.selectChannelForm.errorNotInTeams": "Acceso denegado. Para usar este canal, debes ser miembro de este departamento.",
  "components.selectChannelForm.notFound": "Tu organización se registró con un solo equipo de Microsoft Teams, y no eres miembro del equipo con el siguiente ID: {msTeamId}.\n\nPuedes unirte a este equipo en tu aplicación de Microsoft Teams e intentarlo de nuevo, o contactar a nuestro soporte para cambiar tu tipo de instalación.",
  "components.selectChannelForm.notFoundTitle": "No eres miembro del equipo de Microsoft Teams seleccionado",
  "components.selectChannelForm.team": "Equipo",
  "components.selectChannelFormMicrosoft.channelPlaceholder": "Por favor, selecciona un Canal",
  "components.selectChannelFormMicrosoft.errorNotInTeams": "Acceso denegado. Para usar este canal, debes ser miembro de este departamento.",
  "components.selectChannelFormMicrosoft.notFound": "Tu organización se registró con un solo equipo de Microsoft Teams, y no eres miembro del equipo con el siguiente ID: {msTeamId}.\n\nPuedes unirte a este equipo en tu aplicación de Microsoft Teams e intentarlo de nuevo, o contactar a nuestro soporte para cambiar tu tipo de instalación.",
  "components.selectChannelFormMicrosoft.notFoundTitle": "No eres miembro del equipo de Microsoft Teams seleccionado",
  "components.selectChannelFormMicrosoft.teamPlaceholder": "Por favor, selecciona un Equipo",
  "components.selectChannelFormSlack.errorNotification": "Error de notificación",
  "components.selectChannelFormSlack.missingSlackScopes": "Faltan permisos de Slack",
  "components.selectChannelFormSlack.privateChannel": "(canal privado)",
  "components.selectChannelFormSlack.slackBotIdIsRequred": "Error de Slack: ¡Se requiere el ID del Bot de Slack! ¡Intenta refrescar la página!",
  "components.selectChannelFormSlack.slackError": "Error de Slack",
  "components.selectChannelFormSlack.toProccedPleaseLogin": "Para continuar, por favor inicia sesión en tu cuenta de Slack",
  "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "No eres miembro de {channelName}. Para enviar la notificación a este canal, necesitas ser miembro de él.",
  "components.seniorityEntitlement.addDaysFrom": "Agregar días desde",
  "components.seniorityEntitlement.allFiledsAreRequired": "Todos los campos son obligatorios",
  "components.seniorityEntitlement.confirmDescription": "Optar por comenzar a calcular el derecho por antigüedad desde la fecha de inicio del empleado ajustará las cuotas de usuario retrospectivamente desde el año de inicio del cálculo en adelante.",
  "components.seniorityEntitlement.confirmTitle": "Confirmar creación de automatización",
  "components.seniorityEntitlement.daysAfter": "día(s) después de",
  "components.seniorityEntitlement.disableEditInfo": "No puedes editar la automatización de derecho por antigüedad. Si necesitas hacer cambios, elimina esta automatización y crea una nueva. <link>Contáctanos</link> si tienes alguna pregunta o necesitas ayuda.",
  "components.seniorityEntitlement.hoursAfter": "hora(s) después de",
  "components.seniorityEntitlement.noLocations": "Tienes una automatización que se ha aplicado a todos los usuarios, no puedes tener múltiples automatizaciones para todos los usuarios.",
  "components.seniorityEntitlement.notSortedYearInPeriodError": "Los años deben estar ordenados cronológicamente.",
  "components.seniorityEntitlement.prorateForTheFirstYear": "¿Quieres habilitar el prorrateo?",
  "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "Si la fecha de inicio del empleado no coincide con la fecha de inicio del año fiscal, prorratearemos los días basados en la antigüedad en el año en que se otorgan. Por ejemplo, si tu año fiscal comienza el 1 de enero, el usuario comenzó a trabajar el 1 de julio hace 5 años, y tu empresa otorga 5 días adicionales de PTO el 5 de julio después de 5 años de servicio, el usuario recibirá 2.5 días el 1 de julio debido al prorrateo. Este usuario recibirá todos los 5 días basados en la antigüedad en el próximo año fiscal.",
  "components.seniorityEntitlement.sameYearError": "No puedes asignar múltiples reglas al mismo número de años.",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Comenzar a aplicar el derecho por antigüedad desde",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "Si eliges comenzar a aplicar el derecho por antigüedad desde la fecha de inicio del empleado, este cambio afectará las cuotas del usuario en el pasado.",
  "components.seniorityEntitlement.summaryInfoPrefix": "La Automatización de Antigüedad otorga permisos adicionales en un enfoque basado en hitos. Por ejemplo: ",
  "components.seniorityEntitlement.summaryInfoSuffix": ", totalizando {amount} <showDays>días</showDays><showHour>hora(s)</showHour> de permiso por antigüedad.",
  "components.seniorityEntitlement.summeryInfoFirstRow": "Se agregan {amount} <showDays>días</showDays><showHour>hora(s)</showHour> en el hito de {year} años",
  "components.seniorityEntitlement.summeryInfoMoreDays": ", {amount} más <showDays>días</showDays><showHour>hora(s)</showHour> al alcanzar los {year} años",
  "components.seniorityEntitlement.yearsInTheCOmpany": "años en la empresa",
  "components.setupMsBot.followDirectLink": "1) Haz clic en <directLink>este enlace</directLink> para encontrar la aplicación Vacation Tracker en la tienda de Microsoft Teams.",
  "components.setupMsBot.inMSAppPreviewWindows": "2) En la ventana de vista previa de la aplicación, haz clic en la flecha junto al botón \"Abrir\" y selecciona \"Agregar a un equipo\"",
  "components.setupMsBot.setUpInMS": "3) Se te pedirá que selecciones el equipo y el canal donde deseas instalar Vacation Tracker. ¡También tendrás la opción de anclar Vacation Tracker como una pestaña para un acceso más fácil y rápido!",
  "components.setupMsBot.stepByStep": "Para obtener instrucciones detalladas paso a paso, mira este video o visita nuestro <helpDesk>Centro de Ayuda</helpDesk>.",
  "components.setupMsBot.title": "Configura el bot y las pestañas de Microsoft Teams",
  "components.signupWithAnotherPlatform.connectWith": " Registrarse con {platform}",
  "components.signupWithAnotherPlatform.info": "¿Te gustaría conectarte con tu cuenta de {platform}? Al hacerlo, podrás importar y sincronizar usuarios desde tu {importUsersPlatform} y gestionar ausencias directamente desde tu aplicación de {platform}. Para más información, consulta nuestro <helpDesk>Centro de Ayuda</helpDesk>.",
  "components.signupWithAnotherPlatform.or": "O",
  "components.signupWithAnotherPlatform.title": "Parece que estás usando {platform}",
  "components.switchPlanModal.downgradeProbationPeriodWarning": "El complemento Período de Prueba es una característica de pago para el plan Core. Si lo degradas y no cancelas la suscripción a este complemento, se cobrará a $0.50 por usuario por mes.",
  "components.switchPlanModal.switch": "Cambiar",
  "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "El plan mensual <strong>Complete</strong> se activará inmediatamente y el precio se prorrateará para el ciclo de facturación actual.<legacyPlan> Ten en cuenta que no podrás volver al plan heredado.</legacyPlan>",
  "components.switchPlanModal.switchFromAnnuallyToMonthly": "El plan mensual <strong>{newPlan}</strong> se activará y facturará después de que finalice el ciclo de facturación anual actual ({endDatePeriod}).<legacyPlan> Ten en cuenta que no podrás volver al plan heredado.</legacyPlan>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "El plan anual <strong>Core</strong> se activará después de que finalice el ciclo de facturación anual actual ({endDatePeriod}), hasta entonces puedes seguir utilizando las características del plan <strong>Complete</strong>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "El plan <strong>Core</strong> se activará después de que finalice el ciclo de facturación anual actual ({endDatePeriod}), hasta entonces puedes seguir utilizando las características del plan <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "El plan anual <strong>Core</strong> se activará después de que finalice el ciclo de facturación mensual actual ({endDatePeriod}), hasta entonces puedes seguir utilizando las características del plan <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "El plan mensual <strong>Core</strong> se activará después de que finalice el ciclo de facturación actual ({endDatePeriod}), hasta entonces puedes seguir utilizando las características del plan <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "El plan anual <strong>Complete</strong> se activará inmediatamente y se te facturará la diferencia prorrateada en el precio hasta el final del ciclo de facturación anual actual.<legacyPlan> Ten en cuenta que no podrás volver al plan heredado.</legacyPlan>",
  "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "El plan anual <strong>Complete</strong> se activará inmediatamente. Tu precio anual se reducirá por el monto restante en tu factura mensual actual.<legacyPlan> Ten en cuenta que no podrás volver al plan heredado.</legacyPlan>",
  "components.switchPlanModal.switchFromMonthlyToAnnually": "El plan anual <strong>{newPlan}</strong> se activará inmediatamente. Tu precio anual se reducirá por el monto restante en tu factura mensual actual.<legacyPlan> Ten en cuenta que no podrás volver al plan heredado.</legacyPlan>",
  "components.switchPlanModal.title": "Cambiar al plan <strong>{plan}</strong>",
  "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Cancelar suscripción del período de prueba",
  "components.teamForm.approvers": "Aprobador(es)",
  "components.teamForm.generalSettings": "Configuración General",
  "components.teamForm.isDefault": "Hacer Este Departamento Predeterminado",
  "components.teamForm.isDefaultExtra": "Al hacer este Departamento el predeterminado, todos los nuevos miembros del equipo serán automáticamente añadidos a este Departamento",
  "components.teamForm.name": "Nombre",
  "components.teamForm.pleaseEnterTeamName": "Ingrese el nombre del departamento",
  "components.teamForm.users": "Usuarios",
  "components.toil.accrualsToilsTotal": "Total ganado {hours, plural, =1 {horas} other {días}} (TOIL + acumulación): {total}",
  "components.toil.added": "TOIL Añadido",
  "components.toil.addToil": "Añadir TOIL",
  "components.toil.addToilProgress": "Añadiendo TOIL",
  "components.toil.anotherAdd": "Añadir otro TOIL",
  "components.toil.anotherRequest": "Solicitar otro TOIL",
  "components.toil.approveInProgress": "Aprobando solicitud de TOIL",
  "components.toil.bot.message.editedToilRequest": "Hola, <@{userSlackId}> ha editado la siguiente solicitud de TOIL:",
  "components.toil.bot.message.leaveTypeName": "*Tipo de ausencia:*\nTOIL - {leaveTypeName}",
  "components.toil.bot.message.requestToil": "Solicitar TOIL",
  "components.toil.bot.message.requestToilSummary": "Por favor, inicia tu solicitud de Tiempo Libre Compensatorio especificando las fechas en las que realizaste horas extras e incluye cualquier observación relevante. Ten en cuenta que las solicitudes de Tiempo Libre Compensatorio solo pueden hacerse para fechas en el pasado, incluyendo hoy.",
  "components.toil.bot.message.submittedToilRequest": "Hola, <@{userSlackId}> ha enviado la siguiente solicitud de TOIL:",
  "components.toil.bot.notification.slack.toilRequestAdded": "Un aprobador ha creado una ausencia TOIL para ti",
  "components.toil.bot.notification.slack.toilRequestAddedSelf": "Has añadido una ausencia TOIL",
  "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> ha aprobado la solicitud, no se requiere ninguna acción adicional.",
  "components.toil.bot.notification.slack.toilRequestApprovedTitle": "Tu Solicitud de Ausencia ha sido Aprobada",
  "components.toil.bot.notification.slack.toilRequestCreated": "Hola, <@{userSlackId}> ha enviado una solicitud de TOIL.",
  "components.toil.bot.notification.slack.toilRequestEdited": "Hola, <@{userSlackId}> ha editado una solicitud de TOIL.",
  "components.toil.cancelInProgress": "Cancelando la solicitud de TOIL",
  "components.toil.deleteInProgress": "Eliminando TOIL",
  "components.toil.denied": "Tu solicitud de TOIL fue denegada",
  "components.toil.deniedBy": "La solicitud de TOIL ha sido denegada por {approverName}.",
  "components.toil.denyInProgress": "Denegando solicitud de TOIL",
  "components.toil.durationLabel": "Duración mínima del Tiempo Libre Compensatorio",
  "components.toil.durationLabelTooltip": "Por favor, seleccione la duración más corta que se aplica a la solicitud de TOIL. Esta selección debe reflejar la cantidad mínima de horas extras que un usuario puede registrar.",
  "components.toil.earned": "TOIL ganado",
  "components.toil.edit": "Actualizar TOIL",
  "components.toil.edited": "TOIL actualizado",
  "components.toil.editRequestToil": "Editar solicitud de Tiempo Libre Compensatorio",
  "components.toil.editToilProgress": "Editando Tiempo Libre Compensatorio",
  "components.toil.enableLabel": "Habilitar Tiempo Libre Compensatorio:",
  "components.toil.enableLabelTooltip": "TOIL es una práctica donde los empleados son compensados por trabajar horas extras recibiendo tiempo libre equivalente, en lugar de recibir un pago extra por las horas adicionales trabajadas.",
  "components.toil.expiration": "Vencimiento",
  "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {mes} other {meses}}",
  "components.toil.expirationDurationCalendar": "Fin del año calendario",
  "components.toil.expirationLabel": "¿Deben expirar los días de Tiempo Libre Compensatorio?",
  "components.toil.expirationLabelTooltip": "Habilite esta configuración si desea que los días de TOIL tengan una fecha de vencimiento, después de la cual ya no se puedan usar. Seleccionar 'Sí' le pedirá que elija reglas de vencimiento. Si se selecciona 'No', los días de TOIL no expirarán y permanecerán disponibles hasta que se usen.",
  "components.toil.featurePlan": "La función TOIL solo está disponible para el plan Completo.",
  "components.toil.fullDayLeavePeriod": "{date}{endDate} ",
  "components.toil.halfDay": "TOIL de medio día",
  "components.toil.hourly": "TOIL por horas",
  "components.toil.lapsed": "caducado",
  "components.toil.locationNotHaveToil": "No se ha configurado una política de permisos con TOIL para {me, plural, =1 {tu} other {la}} ubicación {me, plural, =1 {} other {del usuario}}",
  "components.toil.notification.added": "TOIL <forUser>para </forUser>{requestor} agregado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.approved": "TOIL de {requestor} aprobado <byActionCreator>por {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.cancelled": "TOIL de {requestor} cancelado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.created": "TOIL solicitado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.deleted": "TOIL eliminado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.denied": "TOIL de {requestor} denegado <byActionCreator>por {actionCreator}</byActionCreator> ❌",
  "components.toil.notification.edited": "TOIL de {requestor} editado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.editedApproved": "Un TOIL programado ha sido editado <byActionCreator>por {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.editedDenied": "TOIL editado denegado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.partDayPeriod": "{date} - de {partDayStartHour}{hourFormat24, select, true {:00} other {}}  a {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.toil.reasonDefault": "Horas adicionales trabajadas durante el período de tiempo especificado anteriormente.",
  "components.toil.request": "Solicitud de TOIL",
  "components.toil.requested": "TOIL solicitado",
  "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Solicitado} other {Agregado}} {period}",
  "components.toil.requester": "¿Quién puede solicitar Tiempo Libre Compensatorio?",
  "components.toil.requesterAdmin": "Solo Administradores y Aprobadores",
  "components.toil.requesterAll": "Todos los usuarios",
  "components.toil.requesterTooltip": "Elija 'Todos los usuarios' para permitir que cada empleado envíe sus propias solicitudes de TOIL después de trabajar horas extras. Opte por 'Solo Administradores y Aprobadores' si prefiere centralizar la gestión de TOIL y mantener un control más estricto sobre su aprobación y registro.",
  "components.toil.requestNotFound": "Solicitud de TOIL no encontrada",
  "components.toil.requestToil": "Solicitar Tiempo Libre Compensatorio",
  "components.toil.requestToilProgress": "Solicitando Tiempo Libre Compensatorio",
  "components.toil.submitError": "Error al enviar TOIL",
  "components.toil.submitErrorDate": "La solicitud de TOIL no se puede enviar porque la fecha de inicio que ha seleccionado es posterior a la fecha de finalización. Asegúrese de que la fecha de inicio sea anterior a la fecha de finalización y envíe la solicitud nuevamente",
  "components.toil.submitErrorDateFuture": "La fecha de TOIL solicitada debe estar en el pasado",
  "components.toil.submitErrorLongerThanWorkday": "Las horas de TOIL solicitadas son mayores que las horas de trabajo",
  "components.toil.submitErrorNotOpen": "TOIL no está abierto",
  "components.toil.submitErrorOverlap": "Los días de TOIL solicitados se superponen con TOIL existente",
  "components.toil.submitErrorPlan": "Esta función es exclusivamente accesible para usuarios suscritos a nuestro plan Completo.",
  "components.toil.submitErrorPolicy": "La política de permisos para este tipo de permiso no permite TOIL",
  "components.toil.submitErrorPolicyDuration": "Los usuarios con horas de trabajo personalizadas no pueden solicitar TOIL de varios días",
  "components.toil.submitErrorPolicyLocation": "La opción TOIL no está habilitada para tu ubicación. Puedes habilitarla en la página de políticas de permisos o contactar a tu administrador para obtener ayuda.",
  "components.toil.submitErrorReasonNotSet": "Se requiere un motivo para TOIL",
  "components.toil.submitErrorRole": "Solicitud de TOIL no permitida",
  "components.updateWorkweekForm.cancel": "Cancelar",
  "components.updateWorkweekForm.form.error": "Por favor, selecciona al menos un día laboral",
  "components.updateWorkweekForm.title": "Actualizar Semana Laboral",
  "components.updateWorkweekForm.update": "Actualizar",
  "components.updateWorkweekForm.workWeek": "Semana Laboral",
  "components.userBroughtForwardForm.customRolloverDays": "Días",
  "components.userBroughtForwardForm.leaveTypeEditInfo": "Estás editando el tipo de permiso \"{leaveTypeName}\" para el período {dateRangeName}.",
  "components.userBroughtForwardForm.update": "Actualizar",
  "components.userBroughtForwardForm.updateUserLeaveType": "Actualizar los días transferidos para el tipo de permiso {name}",
  "components.userLabes.addLabel": "Añadir Etiqueta",
  "components.userLabes.manageLabels": "Gestionar Etiquetas",
  "components.userLabes.newLabel": "(nueva etiqueta)",
  "components.userLabes.noLabelsFound": "No se encontraron etiquetas",
  "components.userLeaveQuotas.accrualsTooltipTitle": "La acumulación es el aumento gradual de tus horas de tiempo libre remunerado durante el año.",
  "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "Una acumulación quincenal de {leaveType} ocurre cada dos semanas",
  "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "Una acumulación mensual de {leaveType} ocurre una vez al mes.",
  "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "Una acumulación quincenal de {leaveType} ocurre dos veces al mes.",
  "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "Una acumulación semanal de {leaveType} ocurre cada semana",
  "components.userLeaveQuotas.accruedDays": "Días Acumulados",
  "components.userLeaveQuotas.accruedHours": "Horas Acumuladas",
  "components.userLeaveQuotas.accruedInfo1": "{userName} acumuló {earnedDays} días ({earnedDaysInHours} horas) de {defaultDays} días ({defaultDaysInHours} horas)",
  "components.userLeaveQuotas.accruedInfo1Days": "{userName} acumuló {earnedDays} de {defaultDays} días",
  "components.userLeaveQuotas.accruedInfo1Hours": "{userName} acumuló {earnedDaysInHours} de {defaultDaysInHours} horas",
  "components.userLeaveQuotas.accruedInfo2": "{userName} acumulará {toAccrueDays} días ({toAccrueHours} horas) el {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Days": "{userName} acumulará {toAccrueDays} días el {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Hours": "{userName} acumulará {toAccrueHours} horas el {earnDate}",
  "components.userLeaveQuotas.baseQuota": "Cuota base",
  "components.userLeaveQuotas.broughtForward": "Transferido",
  "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>Máximo {maxRolloverDays} días<br></br></rolloverDays><expiresDate>Expira el {date}</expiresDate>",
  "components.userLeaveQuotas.broughtForwardInfo": "Transferido representa el número de días no utilizados que se transfieren del período anterior.",
  "components.userLeaveQuotas.broughtForwardInfoDetails": "Para {leaveTypeName} en la ubicación {locationName}, los empleados pueden transferir hasta {broughtForwardLimit} {broughtForwardLimit, plural, =1 {día no utilizado} other {días no utilizados}}.",
  "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "Para {leaveTypeName} en la ubicación {locationName}, todos los días no utilizados pueden transferirse al siguiente período.",
  "components.userLeaveQuotas.broughtForwardValue": "Transferido",
  "components.userLeaveQuotas.editInitialQuotaTitle": "Editar días Iniciales Transferidos para {leaveTypeName}",
  "components.userLeaveQuotas.editInitialQuotaTooltip": "Transferencia Inicial permite ingresar manualmente los saldos iniciales de los empleados de un sistema de seguimiento o RRHH anterior",
  "components.userLeaveQuotas.editQuotaButton": "Cambiar",
  "components.userLeaveQuotas.expirationTooltip": "Días que el empleado transfirió del período anterior pero que no se utilizaron antes de la fecha de vencimiento",
  "components.userLeaveQuotas.expiredDateInfo": "el {date}",
  "components.userLeaveQuotas.forMoreDetails": "Para más detalles, ver",
  "components.userLeaveQuotas.initialBroughtForward": "Transferencia Inicial",
  "components.userLeaveQuotas.initialBroughtForwardInfo": "Transferencia Inicial es el saldo de permisos que un Usuario ha transferido (acumulado) del año pasado, en el que Vacation Tracker no formaba parte de tu organización. Ten en cuenta que solo puedes editar el saldo de permisos de Transferencia Inicial, mientras que los permisos disponibles que puedes transferir en los años siguientes serán calculados por Vacation Tracker.",
  "components.userLeaveQuotas.leavePoliciesNotExistInfo": "Actualmente no hay Tipos de Permiso disponibles. Por favor, asigna Políticas de Permiso a la Ubicación a la que pertenece este usuario.",
  "components.userLeaveQuotas.leaveTypes": "Tipos de Permiso",
  "components.userLeaveQuotas.logsTab": "pestaña de registros.",
  "components.userLeaveQuotas.noLimit": "Tienes una cuota ilimitada.",
  "components.userLeaveQuotas.ofDefaultDays": "de {value} días",
  "components.userLeaveQuotas.ofDefaultHours": "de {value} horas",
  "components.userLeaveQuotas.overlapDaysContactSupport": "Superposición de fechas, por favor contacta a Soporte para más información.",
  "components.userLeaveQuotas.paidDays": "Días pagados",
  "components.userLeaveQuotas.quota": "Cuota",
  "components.userLeaveQuotas.quotaInfo": "La cuota representa el número de días de Tiempo Libre Pagado disponibles para el período actual. Este número incluye la cuota base, tiempo compensatorio y días otorgados según el rol o antigüedad del empleado.",
  "components.userLeaveQuotas.quotaInfoSummary": "Cuota para el período actual:",
  "components.userLeaveQuotas.remaining": "Restante",
  "components.userLeaveQuotas.remainingAfterRequest": "Restante después de la solicitud",
  "components.userLeaveQuotas.remainingTooltip": "Días restantes de {leaveTypeName} para el período actual. Total:",
  "components.userLeaveQuotas.remainingTooltipFooter": "Hasta {broughtForwardLimit} {period} no utilizados se transferirán al próximo período",
  "components.userLeaveQuotas.remainingTooltipFooterAll": "Todos los días no utilizados pueden transferirse al próximo período.",
  "components.userLeaveQuotas.remainingTooltipFooterZero": "No se pueden transferir días no utilizados al próximo período.",
  "components.userLeaveQuotas.requested": "Solicitado",
  "components.userLeaveQuotas.saveQuotaButton": "Guardar",
  "components.userLeaveQuotas.scheduled": "Programado",
  "components.userLeaveQuotas.scheduledDays": "Días programados",
  "components.userLeaveQuotas.sectionTitle": "Cuotas de permisos",
  "components.userLeaveQuotas.selectPeriod": "Seleccionar Período:",
  "components.userLeaveQuotas.unlock": "Desbloquear",
  "components.userLeaveQuotas.unlockBroughtForwardInfo": "Para desbloquear, ve a Configuración → Ubicaciones → {locationName} → Ver Políticas de Permisos, y edita la política de {leaveTypeName} para permitir Días Transferidos.",
  "components.userLeaveQuotas.used": "Utilizado",
  "components.userLeaveQuotas.usedAlready": "Ya utilizado",
  "components.userLeaveQuotas.usedDays": "Días utilizados",
  "components.userLeaveQuotas.usedTooltip": "Días de {leaveTypeName} utilizados, programados y pagados para el período actual. Total:",
  "components.userLeaveQuotas.userInactiveInfo": "Como la cuenta está inactiva, no se agregarán más días hasta la reactivación.",
  "components.userLeaveQuotasCompact.title": "Tu saldo de permisos",
  "components.userLeaveQuotasCompact.titleForApprover": "Saldo de permisos",
  "components.userLeaveTypeForm.applyForFutureYears": "¿Quieres aplicar la cuota base de permiso para los años futuros?",
  "components.userLeaveTypeForm.cancel": "Cancelar",
  "components.userLeaveTypeForm.daysPerYear": "¿Cuál es tu nueva cuota de permisos?",
  "components.userLeaveTypeForm.hasUnlimitedDays": "¿Quieres permitir Permisos Ilimitados?",
  "components.userLeaveTypeForm.leaveTypeEditInfo": "Estás editando el tipo de permiso \"{leaveTypeName}\" para el período {dateRangeName}.",
  "components.userLeaveTypeForm.prorateInfo": "{user} tendrá {proratedQuota} ({preciseQuota} días, para ser exactos) para el año inicial ({yearPeriod}) y {fullQuota} para todos los años a partir de {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoDays": "{user} tendrá {proratedQuota} ({preciseQuota} días, para ser exactos) para el año inicial ({yearPeriod}) y {fullQuota} días para todos los años a partir de {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoHours": "{user} tendrá {proratedQuota} ({preciseQuota} horas, para ser exactos) para el año inicial ({yearPeriod}) y {fullQuota} horas para todos los años a partir de {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateLabel": "Prorratear para el año inicial basado en la fecha de inicio del usuario ({startDate})",
  "components.userLeaveTypeForm.quotaAdditionalRole": "Establecer los días adicionales basados en el rol",
  "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Asigna días de permiso adicionales a los empleados según su puesto de trabajo. Este campo te permite adaptar los derechos de permiso para reflejar las responsabilidades o requisitos específicos de diferentes posiciones dentro de la organización.",
  "components.userLeaveTypeForm.quotaAdditionalSeniority": "Establecer los días adicionales basados en la antigüedad",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "Los días basados en la antigüedad se agregan mediante la automatización de derechos por antigüedad. Puedes sobrescribir los días de {leaveTypeName} basados en la antigüedad estableciendo el valor en este campo.",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "Los días basados en la antigüedad se agregan mediante la automatización de derechos por antigüedad. Puedes sobrescribir los días de {leaveTypeName} basados en la antigüedad estableciendo el valor en este campo. Sin embargo, este campo está bloqueado porque no puedes editar este valor para años futuros antes de que se otorguen los días.",
  "components.userLeaveTypeForm.quotaAdditionalSummary": "Establecerás la cuota de permiso {leaveTypeName} para {user} para el período seleccionado en {total} {hourlyLeaveAccounting, plural, =1 {horas} other {días}}. La cuota base y los días adicionales basados en la antigüedad y el derecho por rol ({total} {hourlyLeaveAccounting, plural, =1 {horas} other {días}} en total) se aplicarán para {future, plural, =1 {el período seleccionado y períodos futuros} other {el período seleccionado}}. {prorateText}",
  "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "Establecerás la cuota de permiso {leaveTypeName} para {user} para el período seleccionado como Ilimitada.",
  "components.userLeaveTypeForm.quotaBase": "Establecer la cuota base de permiso",
  "components.userLeaveTypeForm.quotaBaseTooltip": "La cuota base es la cuota predeterminada del usuario, que excluye los días adicionales recibidos por su antigüedad, rol, tiempo compensatorio u otros días extra. Puedes cambiar la cuota base para todos en una ubicación en la página de políticas de permisos o usar este campo para establecer una cuota personalizada para un usuario seleccionado.",
  "components.userLeaveTypeForm.required": "Este campo es obligatorio.",
  "components.userLeaveTypeForm.update": "Actualizar",
  "components.userLeaveTypeForm.updateUserLeaveType": "Actualizar cuotas del usuario para el tipo de permiso {name}",
  "components.userLogsTab.ACCRUED_DAYS": "Días acumulados",
  "components.userLogsTab.ALL": "Todos",
  "components.userLogsTab.APPROVER_STATUS": "Cambio de rol",
  "components.userLogsTab.filter": "Filtrar:",
  "components.userLogsTab.LOCATION_USER_MOVED": "Cambio de ubicación",
  "components.userLogsTab.logsNotFound": "No se encontraron registros para el rango de fechas seleccionado.",
  "components.userLogsTab.ROLLOVER_EXPIRED": "Días transferidos expirados",
  "components.userLogsTab.selectFromTo": "Seleccionar rango de fechas:",
  "components.userLogsTab.TEAM_USER_MOVED": "Cambio de departamento",
  "components.userLogsTab.TOIL_REQUEST_APPROVED": "TOIL",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Derecho por rol",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Derecho por rol eliminado",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Anulación de derecho por rol",
  "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "Cuota de permiso restablecida por defecto",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Transferencia inicial eliminada",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Transferencia inicial actualizada",
  "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "Cuota de permiso actualizada",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Derecho por antigüedad",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Anulación de derecho por antigüedad",
  "components.userLogsTab.USER_STATUS_CHANGED": "Cambio de estado",
  "components.userLogsTab.USER_UPDATED": "Actualización de información del usuario",
  "components.userLogsTab.USER_WORK_WEEK_DELETED": "Semana laboral personalizada eliminada",
  "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Semana laboral actualizada",
  "components.userLogsTab.YEAR_START": "Inicio de año",
  "components.userManageLabels.actions": "Acciones",
  "components.userManageLabels.color": "Color",
  "components.userManageLabels.deleteLabel": "¿Está seguro de que desea eliminar esta etiqueta?\n\nEsto eliminará la etiqueta para todos los usuarios asignados.",
  "components.userManageLabels.findLabel": "Buscar Etiqueta",
  "components.userManageLabels.isRequired": "{title} es obligatorio",
  "components.userManageLabels.manageLabels": "Gestionar Etiquetas",
  "components.userManageLabels.name": "Nombre",
  "components.userManageLabels.userCount": "Recuento de Usuarios",
  "components.userProfileTab.admin": "Administrador",
  "components.userProfileTab.approver": "Aprobador",
  "components.userProfileTab.approvers": "Aprobadores:",
  "components.userProfileTab.approverSuffix": " (Aprobador)",
  "components.userProfileTab.customWorkWeekEditedBefore": "La semana laboral de {userName} fue modificada en la versión anterior de Vacation Tracker (antes del 1 de enero de 2021)",
  "components.userProfileTab.customWorkWeekLabel": "semana laboral personalizada",
  "components.userProfileTab.customWorkWeekTooltip": "La semana laboral de {userName} fue editada por {adminName} el {timestamp}",
  "components.userProfileTab.employeeIdNotSet": "No establecido",
  "components.userProfileTab.employeeIdTooltip": "ID del empleado en un sistema externo",
  "components.userProfileTab.employeeSince": "Empleado desde:",
  "components.userProfileTab.endDate": "Fecha de finalización:",
  "components.userProfileTab.location": "Ubicación:",
  "components.userProfileTab.name": "Nombre:",
  "components.userProfileTab.nameEdited": "El nombre se cambió manualmente y ya no se sincronizará automáticamente. Este nombre se usa solo dentro de Vacation Tracker.",
  "components.userProfileTab.role": "Rol:",
  "components.userProfileTab.status": "Estado:",
  "components.userProfileTab.team": "Departamento:",
  "components.userProfileTab.user": "Usuario",
  "components.userProfileTab.userProfile": "Perfil de Usuario",
  "components.userProfileTab.workingDays": "Días Laborables",
  "components.userTodayOff.cancelLeave": "Cancelar permiso",
  "components.userTodayOff.editLeave": "Editar permiso",
  "components.userTodayOff.leaveInfo": "Información del permiso",
  "components.userTodayOff.offFromTo": "El usuario ha tomado {leaveTypeName} desde {startDate} hasta {endDate}",
  "components.userTodayOff.offTodayFullDay": "El usuario ha tomado {leaveTypeName} hoy, {today}",
  "components.userTodayOff.offTodayPartDay": "El usuario ha tomado {leaveTypeName} hoy desde las {from}:00h hasta las {to}:00h.",
  "components.userTodayOff.offTodayReason": "Motivo: {reason}",
  "components.userUpdateForm.active": "Activo",
  "components.userUpdateForm.admin": "Administrador",
  "components.userUpdateForm.approver": "Aprobador",
  "components.userUpdateForm.approverSuffix": " (Aprobador)",
  "components.userUpdateForm.cancel": "Cancelar",
  "components.userUpdateForm.employeeSince": "Empleado desde",
  "components.userUpdateForm.location": "Ubicación",
  "components.userUpdateForm.name": "Nombre",
  "components.userUpdateForm.pleaseSelectALocation": "Seleccionar una ubicación",
  "components.userUpdateForm.pleaseSelectATeam": "Seleccionar un departamento",
  "components.userUpdateForm.pleaseSelectAUserRole": "Seleccionar un rol de usuario",
  "components.userUpdateForm.pleaseSelectEmployeeSince": "Añadir fecha de inicio del empleado",
  "components.userUpdateForm.pleaseSelectUserEndDate": "Añadir fecha de finalización del usuario",
  "components.userUpdateForm.role": "Rol",
  "components.userUpdateForm.team": "Departamento",
  "components.userUpdateForm.update": "Actualizar",
  "components.userUpdateForm.updateUser": "Actualizar Usuario",
  "components.userUpdateForm.user": "Usuario",
  "components.userUpdateForm.userEndDate": "Fecha de finalización del empleado",
  "components.userUpdateForm.warningInitialRolloverForChangeLocation": "Cambiar la ubicación del usuario podría afectar la configuración y la información de permisos del usuario (por ejemplo, los días iniciales transferidos).",
  "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "Cambiar la fecha de inicio de un usuario podría afectar la información de permisos del usuario (por ejemplo, los días iniciales transferidos).",
  "components.whatsNew.newMessage": "Nuevo mensaje",
  "components.whatsNew.whatsNew": "Qué hay de nuevo",
  "connect.alreadyHaveAnAccount": "¿Ya tienes una cuenta?",
  "connect.alreadyHaveAnAccountWithLink": "¿Ya tienes una cuenta? <b><link>Iniciar sesión</link></b>",
  "connect.backToLoginWithOtherPlatofrm": "Volver a iniciar sesión con otra plataforma",
  "connect.companyExists.line1": "{companyName} ya está registrada en Vacation Tracker",
  "connect.companyExists.line2Other": "¿Estás usando el correo electrónico correcto?",
  "connect.companyExists.line2Slack": "¿Estás usando las credenciales correctas?",
  "connect.companyExists.line3aSlack": "Tu espacio de trabajo de Slack: {workspaceName}",
  "connect.companyExists.line3Other": "Tu dirección de correo electrónico: {email}",
  "connect.companyExists.line3Slack": "Tu dirección de correo electrónico: {email}",
  "connect.companyExists.line4Other": "Si este correo electrónico es correcto, tu cuenta no está activada. Por favor, contacta al administrador de tu organización en Vacation Tracker:",
  "connect.companyExists.line4Slack": "Si estas credenciales son correctas, tu cuenta no está activada. Por favor, contacta al administrador de tu organización en Vacation Tracker:",
  "connect.companyExists.line5": "De lo contrario, inicia sesión con una cuenta <capitalize>{platform}</capitalize> diferente o inicia sesión desde una ventana de incógnito en tu navegador.",
  "connect.companyExists.line6Microsoft": "Contacta con Soporte para crear un nuevo departamento en {companyName}",
  "connect.companyExists.lineEmailAddress": "Tu dirección de correo electrónico",
  "connect.companyExists.title": "La empresa ya existe",
  "connect.connect": "Conectar",
  "connect.connectWithWorkEmail": "Iniciar sesión con correo de trabajo",
  "connect.connectWithYourWorkEmail": "Inicia sesión con tu correo de trabajo",
  "connect.copyEmail": "Haz clic para copiar el correo electrónico",
  "connect.copyTextEnd": "¡Felices vacaciones! 🌴",
  "connect.createAnOrganization": "Crear una organización",
  "connect.description": "Para acceder al Panel de Control de Vacation Tracker, selecciona el botón \"Conectar con\" que corresponda a la plataforma utilizada por tu equipo.",
  "connect.dontHaveAnAccount": "¿No tienes una cuenta?",
  "connect.doYouWantToLoginInToOrganization": "¿Quieres iniciar sesión en {existingOrganizationName}?",
  "connect.forgotYourPassword": "¿Olvidaste tu contraseña?",
  "connect.forgotYourPasswordWhatsYourEmail": "¿Cuál es tu correo electrónico?",
  "connect.google.notWorkspaceUserDescription": "Parece que estás intentando registrarte con una cuenta personal y no con una cuenta de Google Workspace. Por el momento, Vacation Tracker solo admite cuentas de Google Workspace.",
  "connect.google.notWorkspaceUserTitle": "Se requiere una cuenta de Google Workspace",
  "connect.google.sharedPermissionsConsent": "Al hacer clic en el botón de abajo, estás otorgando a Vacation Tracker permiso para acceder al directorio de usuarios administradores de Google.",
  "connect.google.sharedPermissionsError": "Error al compartir los permisos de Google, tu enlace ha expirado.",
  "connect.google.sharedPermissionsRequired": "Permisos insuficientes",
  "connect.google.sharedPermissionsRequiredDescription": "Para continuar con la importación de usuarios, necesitarás obtener permisos de tu administrador de Google. Por favor, comparte este enlace con tu administrador para otorgar el consentimiento necesario: ",
  "connect.google.sharedPermissionsSuccess": "¡Permisos de Google compartidos con éxito!",
  "connect.google.sharedPermissionsSuccessRedirect": "Serás redirigido a la página de inicio de Vacation Tracker en unos segundos.",
  "connect.howShouldIConnect": "¿Cómo debo conectarme?",
  "connect.inputEmailPlaceholder": "Ingresa tu correo electrónico de trabajo",
  "connect.inputPasswordPlaceholder": "Ingresa tu contraseña",
  "connect.interactionRequiredMSErrorDescription1": "Parece que tu cuenta de Microsoft requiere permisos adicionales. Por favor, haz clic en el botón Iniciar sesión a continuación para otorgar permisos adicionales.",
  "connect.interactionRequiredMSErrorDescription2": "Si este problema persiste, por favor contacta a nuestro soporte y envíales el siguiente código:",
  "connect.interactionRequiredMSErrorTitle": "Microsoft requiere permisos adicionales",
  "connect.login": "Iniciar sesión",
  "connect.meta.description": "Accede al Panel de Control de Vacation Tracker y desbloquea todo el poder de la gestión de ausencias sin esfuerzo.",
  "connect.meta.title": "Registro/Inicio de sesión - Vacation Tracker",
  "connect.meta.title.signin": "Iniciar Sesión - Vacation Tracker",
  "connect.meta.title.signup": "Registro - Vacation Tracker",
  "connect.or": "O",
  "connect.platformErrorDescription1": "Ocurrió el siguiente error:",
  "connect.platformErrorDescription2": "Esto podría ser un error de {platform}. Sin embargo, no dudes en contactar a nuestro soporte para obtener más ayuda.",
  "connect.platformErrorTitle": "Algo salió mal",
  "connect.pleaseInputValidEmail": "Por favor, ingresa un correo electrónico válido",
  "connect.pleaseInputValidPassword": "Por favor, ingresa una contraseña válida",
  "connect.pleaseLogIn": "Por favor, inicia sesión",
  "connect.readMore": "Para más información, visita nuestro <helpdesk>Centro de Ayuda</helpdesk> o ve a nuestro <website>sitio web</website>.",
  "connect.signInWithEmail": "Iniciar sesión con correo electrónico",
  "connect.signInWithGoogle": "Iniciar sesión con Google",
  "connect.signInWithGoogleToOrganization": "Iniciar sesión en Google Workspace para {existingOrganizationName}",
  "connect.signInWithGoogleVerification": "Iniciar sesión con Google",
  "connect.signInWithMS": "Iniciar sesión con Microsoft Teams",
  "connect.signInWithMSToOrganization": "Iniciar sesión con Microsoft Teams en {existingOrganizationName}",
  "connect.signInWithSlack": "Iniciar sesión con Slack",
  "connect.signInWithSlackInstallBot": "Agregar Vacation Tracker a Slack",
  "connect.signInWithSlackToOrganization": "Iniciar sesión con Slack en {existingOrganizationName}",
  "connect.signUp": "Regístrate usando tu correo electrónico de trabajo",
  "connect.signUpInfo": "Regístrate para tu prueba gratuita",
  "connect.signupNewOrganizationUsingNewPlatform": "Regístrate como una nueva organización usando tu cuenta de {selectedPlatform}",
  "connect.signUpWithEmail": "Registrarse con correo electrónico",
  "connect.signUpWithGoogle": "Registrarse con Google",
  "connect.signUpWithMicrosoft": "Registrarse con Microsoft",
  "connect.signUpWithSlack": "Registrarse con Slack",
  "connect.slack.usersLoginRequiredButton": "Conceder permisos de Slack",
  "connect.slack.usersLoginRequiredDescription1": "Tu token de bot de Slack ha expirado o ya no es válido. No podrás acceder al bot de Vacation Tracker ni recibir notificaciones hasta que actives un nuevo token.",
  "connect.slack.usersLoginRequiredDescription2": "Para activar un nuevo token de bot de Slack, por favor haz clic en OK.",
  "connect.slack.usersLoginRequiredDescription3": "Para omitir este paso y continuar al Panel en línea, haz clic en Omitir.",
  "connect.slack.usersLoginRequiredTitle": "Se requiere iniciar sesión en tu cuenta de Slack",
  "connect.slackPostInstallation1": "Has instalado exitosamente la aplicación de Slack de Vacation Tracker 🎉",
  "connect.slackPostInstallation2": "Para comenzar a usar la aplicación, por favor inicia sesión en el Panel de Vacation Tracker y completa la configuración.",
  "connect.slackPostInstallation3": "Te haremos algunas preguntas sencillas. No debería tomar más de unos minutos. En un abrir y cerrar de ojos estará listo (como tus últimas vacaciones).",
  "connect.slackPostInstallationError.generic": "Hey, necesitamos instalar primero el bot de Vacation Tracker. Podrás revisar los permisos de la aplicación de Slack de Vacation Tracker y concederlos en el siguiente paso.",
  "connect.slackPostInstallationError.invalidCode": "El código de redirección ya no es válido. Esto puede suceder si el código expiró, ya fue usado, o si actualizaste la página antes de que termináramos el proceso de inicio de sesión. Pero no te preocupes, ¡obtengamos uno nuevo!",
  "connect.subscriptionExpired": "¡Ups, parece que la suscripción de tu empresa a Vacation Tracker ha expirado! Por favor, contacta a un administrador para reactivar la suscripción de tu empresa.",
  "connect.subscriptionExpiredTitle": "Suscripción Expirada",
  "connect.thisOrganizationUsePlatform": "Sin embargo, {existingOrganizationName} utiliza {existsOnAnotherPlatform}, así que necesitarás iniciar sesión con {existsOnAnotherPlatform} para acceder a tu cuenta",
  "connect.welcome": "¡Bienvenido, {name}!",
  "connect.youAreAlreadyMember": "Ya eres miembro de la organización {existingOrganizationName}",
  "connectCalendar.connect": "Conectar con",
  "connectCalendar.disconnect": "Desconectar",
  "connectCalendar.disconnectDescription": "¿Estás seguro de que quieres desconectar el calendario? Vacation Tracker ya no lo actualizará con tus futuras ausencias",
  "connectCalendar.disconnectError": "Error al desconectar el calendario",
  "connectCalendar.disconnectErrorDescription": "Hubo un error al intentar desconectar tu calendario de Vacation Tracker. Por favor, haz clic en Ok para intentarlo de nuevo.",
  "connectCalendar.disconnectSuccess": "Has desconectado exitosamente el calendario de {calendar}.",
  "connectCalendar.disconnectTitle": "Desconectar Calendario de {calendarPlatform}",
  "connectCalendar.error": "Error al conectar el calendario",
  "connectCalendar.errorDescription": "Hubo un error al intentar conectar tu calendario con Vacation Tracker. Por favor, haz clic en Ok para intentarlo de nuevo.",
  "connectCalendar.errorReconnectDescription": "Hubo un error al intentar reconectar tu calendario con Vacation Tracker. Por favor, haz clic en Ok para intentarlo de nuevo.",
  "connectCalendar.reconnect": "Reconectar",
  "connectCalendar.reconnectError": "Error al reconectar el calendario",
  "connectCalendar.reconnectInfo": "Para asegurar que tus ausencias sean visibles, por favor reconecta tu calendario.",
  "connectCalendar.reconnectNotification": "La conexión con tu calendario ha expirado. Por favor, haz clic aquí para reconectar y asegurar que tus ausencias sean visibles en tu calendario.",
  "connectCalendar.reconnectNotificationSharedCalendar": "Tu conexión con el calendario compartido: {calendarName} ha expirado. Por favor, haz clic aquí para reconectar y asegurar que las ausencias sean visibles en tu calendario.",
  "connectCalendar.reconnectSuccess": "Has reconectado exitosamente el calendario de {calendar}.",
  "connectCalendar.success": "Has conectado exitosamente el calendario de {calendar}.",
  "connectedCalendar.eventDescription": "Agregado por <link>Vacation Tracker</link>",
  "connectedCalendar.eventDescriptionShared": "Evento de calendario compartido agregado por <link>Vacation Tracker</link>",
  "containers.google.topbar.adminProductTourInfo": "Como Administrador, tienes el nivel más alto de permisos en Vacation Tracker y puedes personalizar todas las configuraciones de la organización a través del Panel de Administrador.\n\nEl Panel te permite:\n- Solicitar Ausencias o agregar Ausencias para otros miembros del departamento.\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Crear y configurar múltiples departamentos (Asignar Aprobadores)\n- Establecer Notificaciones\n- Gestionar Usuarios (Activos, Inactivos, Eliminados).\n- Crear y gestionar Ubicaciones (Tipos de Ausencias, Festivos, Notificaciones)\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "containers.google.topbar.approverProductTourInfo": "Como Aprobador, tienes el segundo nivel más alto de permisos en Vacation Tracker y puedes aprobar o denegar solicitudes de ausencia enviadas por Usuarios del departamento para el que has sido asignado como Aprobador.\n\nEl Panel te permite:\n- Solicitar Ausencias o agregar Ausencias para otros miembros del departamento.\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Establecer Notificaciones\n- Ver tu Perfil de Usuario.\n- Gestionar Solicitudes (Aprobar/Denegar Solicitudes, Ver Historial).\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "containers.google.topbar.userProductTourInfo": "Como Usuario de Vacation Tracker, puedes enviar y gestionar tus Solicitudes de Ausencia a través del Panel, así como tener una visión general completa de las ausencias planificadas dentro de tu organización.\n\nEl Panel te permite:\n- Solicitar Ausencias\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Ver tu Perfil de Usuario.\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "containers.topbar.adminProductTourInfo": "Como Administrador, tienes el nivel más alto de permisos en Vacation Tracker y puedes personalizar todas las configuraciones de la organización a través del Panel de Administrador.\n\nEl Panel te permite:\n- Solicitar Ausencias o agregar Ausencias para otros miembros del departamento.\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Crear y configurar múltiples departamentos (Asignar Aprobadores)\n- Establecer Notificaciones\n- Gestionar Usuarios (Activos, Inactivos, Eliminados).\n- Crear y gestionar Ubicaciones (Tipos de Ausencias, Festivos, Notificaciones)\n\nEl Bot te permite:\n- Solicitar Ausencias\n- Aprobar/Denegar Solicitudes de Ausencia\n- Recibir Notificaciones Diarias/Semanales\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "containers.topbar.adminProductTourTitle": "Administrador",
  "containers.topbar.announcement": "Anuncio",
  "containers.topbar.approverProductTourInfo": "Como Aprobador, tienes el segundo nivel más alto de permisos en Vacation Tracker y puedes aprobar o denegar solicitudes de ausencia enviadas por Usuarios del departamento para el que has sido asignado como Aprobador.\n\nEl Panel te permite:\n- Solicitar Ausencias o agregar Ausencias para otros miembros del departamento.\n- Tener una visión general completa a través de la Página de Inicio del Panel y el Calendario.\n- Establecer Notificaciones\n- Ver tu Perfil de Usuario.\n- Gestionar Solicitudes (Aprobar/Denegar Solicitudes, Ver Historial).\n\nEl Bot te permite:\n- Solicitar Ausencias\n- Aprobar/Denegar Solicitudes de Ausencia\n- Recibir Notificaciones Diarias/Semanales\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día:",
  "containers.topbar.approverProductTourTitle": "Aprobador",
  "containers.topbar.giveUsFeedback": "Danos tu opinión",
  "containers.topbar.help": "Ayuda",
  "containers.topbar.logout": "Cerrar sesión",
  "containers.topbar.profile.info": "Puedes acceder a tu perfil, cambiar el tema y comenzar el tour rápido desde este nuevo menú.",
  "containers.topbar.setUpTheBot": "Configurar el bot",
  "containers.topbar.userProductTourInfo": "Como usuario de Vacation Tracker, puedes enviar y gestionar tus solicitudes de ausencia a través del Panel de Control o el Bot, así como tener una visión completa de las ausencias planificadas dentro de tu organización.\n\nEl Panel de Control te permite:\n- Solicitar ausencias\n- Tener una visión general completa a través de la página de inicio del Panel de Control y el Calendario.\n- Ver tu perfil de usuario.\n\nEl Bot te permite:\n- Solicitar ausencias\n- Recibir notificaciones diarias/semanales\n\nSi tus preguntas o inquietudes quedan sin respuesta, contáctanos en cualquier momento. Hablar con nuestros clientes es la mejor parte de nuestro día: ",
  "containers.topbar.userProductTourTitle": "Usuario",
  "containers.topbar.whatsNewUnread": "Novedades - {unread} sin leer",
  "Conversation not found.": "El canal seleccionado ha sido eliminado o ya no está disponible. Por favor, selecciona otro canal para recibir notificaciones. ",
  "createCompany.signupError": "Algo salió mal",
  "createCompany.steps.back": "Atrás",
  "createCompany.steps.cancel": "Cancelar",
  "createCompany.steps.companyForm": "Detalles de la empresa",
  "createCompany.steps.companyForm.agreeTerms": "Acepto la <aPP>Política de Privacidad</aPP>, los <aTOS>Términos de Servicio</aTOS> y el <aDPA>DPA</aDPA>. También reconozco y acepto el procesamiento de mis datos personales de acuerdo con los términos descritos en la Política de Privacidad",
  "createCompany.steps.companyForm.agreeToTerms.error": "Debes aceptar nuestros Términos de Servicio y Política de Privacidad para continuar.",
  "createCompany.steps.companyForm.companyInfoSectionDescription": "Cuéntanos sobre tu empresa y ubicación. Usaremos esta información para crear tu cuenta de empresa y tu primera ubicación. Siempre puedes agregar más ubicaciones más tarde.",
  "createCompany.steps.companyForm.companyLeaveSection": "Tipo de ausencia predeterminado",
  "createCompany.steps.companyForm.companyName": "Nombre de la empresa",
  "createCompany.steps.companyForm.companyNameInput.validation.required": "Este campo es obligatorio.",
  "createCompany.steps.companyForm.contactEmail.validation.country": "Este campo es obligatorio.",
  "createCompany.steps.companyForm.contactEmail.validation.required": "Necesario para comunicaciones importantes sobre el producto.",
  "createCompany.steps.companyForm.contactEmail.validation.state": "Este campo es obligatorio.",
  "createCompany.steps.companyForm.contactEmail.validation.type": "Por favor, introduce una dirección de correo electrónico válida.",
  "createCompany.steps.companyForm.contactEmailExtra": "La dirección de correo electrónico que proporciones se utilizará solo para comunicaciones importantes sobre el producto. Sin spam, lo prometemos.",
  "createCompany.steps.companyForm.country": "País",
  "createCompany.steps.companyForm.defaultPtoQuota": "Cuota de tiempo libre pagado (PTO)",
  "createCompany.steps.companyForm.defaultPtoQuota.validation": "Por favor, introduce tu cuota de PTO predeterminada",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "Tu cuota de PTO predeterminada no puede ser superior a 366 días",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "Tu cuota de PTO predeterminada no puede ser inferior a 0 días",
  "createCompany.steps.companyForm.defaultPtoQuotaExtra": "Puedes cambiar esto más tarde en tu Configuración.",
  "createCompany.steps.companyForm.email": "Correo electrónico de contacto",
  "createCompany.steps.companyForm.location": "Información de ubicación: ",
  "createCompany.steps.companyForm.state": "Estado",
  "createCompany.steps.companyForm.step1Title": "Detalles de la empresa",
  "createCompany.steps.companyForm.step2Title": "Seleccionar usuarios",
  "createCompany.steps.companyForm.step3Title": "Seleccionar plan",
  "createCompany.steps.companyForm.unlimitedLeavePolicy": "PTO ilimitado",
  "createCompany.steps.companyForm.UserInfoSectionDescription": "Cuéntanos quién eres. Usaremos esta información para crear tu perfil de usuario. Por defecto, se te asignará el rol de administrador y aprobador. Puedes agregar más usuarios y cambiar sus roles más tarde.",
  "createCompany.steps.inviteUsers.next": "Invitar {noOfUsers} Usuario{postfix}",
  "createCompany.steps.inviteUsers.title": "Invita a los miembros de tu equipo",
  "createCompany.steps.inviteUsers.title.info": "Invita a los usuarios que deseas agregar a la cuenta de Vacation Tracker de tu organización. Siempre puedes hacer cambios más tarde a través del panel de control.",
  "createCompany.steps.next": "Continuar",
  "createCompany.steps.selectPlan.noCC": "No se requiere tarjeta de crédito para tu prueba de 7 días",
  "createCompany.steps.selectPlan.title": "¡Empecemos!",
  "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "Puedes cambiar de plan en cualquier momento durante la prueba",
  "createCompany.steps.selectUsers": "Importar usuarios",
  "createCompany.steps.selectUsers.deselectAll": "Deseleccionar todo",
  "createCompany.steps.selectUsers.doneButton": "Importar {noOfUsers} Usuario{postfix}",
  "createCompany.steps.selectUsers.doneButtonTryItOut": "Inicia tu prueba gratuita de 14 días",
  "createCompany.steps.selectUsers.loadingAll": "Cargando todos los usuarios",
  "createCompany.steps.selectUsers.searchUsers": "Buscar a Nemo...",
  "createCompany.steps.selectUsers.selectAll": "Seleccionar todo",
  "createCompany.steps.selectUsers.title": "Elige a los miembros de tu equipo",
  "createCompany.steps.selectUsers.title.info": "Selecciona los usuarios que deseas agregar a la cuenta de Vacation Tracker de tu organización. Siempre puedes hacer cambios más tarde a través del panel de control. Los usuarios que selecciones no serán notificados hasta que hagas el anuncio.",
  "dashboard.accountSettingsLanguageLabel": "Elige tu idioma preferido",
  "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "Vacation Tracker configurará todos tus futuros mensajes de fuera de la oficina para el correo electrónico {email}.\n Sin embargo, si programaste un mensaje de fuera de la oficina antes de este cambio, lo mantendremos para el correo electrónico anterior.",
  "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "Cambiar dirección de correo electrónico para futuros mensajes de fuera de la oficina",
  "dashboard.accountSettingsOutOfOfficeConnectedWith": "Conectado con",
  "dashboard.accountSettingsOutOfOfficeTitle": "Configurar respuesta automática de fuera de la oficina (OOO)",
  "dashboard.accountSettingsPageOOODescription": "Una vez habilitado, Vacation Tracker te notificará dos días antes de tu ausencia planificada y te dará la oportunidad de configurar un mensaje de respuesta automática de fuera de la oficina (OOO).",
  "dashboard.accountSettingsTimeDisplayLabel": "Elige tu formato preferido de visualización de hora",
  "dashboard.accountSettingsUIModeLabel": "Modo Oscuro vs. Modo Claro",
  "dashboard.b2bSoftware": "Ten en cuenta que este es un software B2B y, por lo tanto, no podemos cambiar ni eliminar tus datos a petición. Para más información, contacta a tu gerente designado. Si necesitas ayuda, nuestro equipo de soporte está disponible en <mail>hello@vacationtracker.io</mail>",
  "dashboard.days": "Día(s)",
  "dashboard.daysSmallCase": "día(s)",
  "dashboard.daysSmallCaseSingle": "d",
  "dashboard.googlePermissionInfo2": "- Se requiere acceso a tu configuración de Google Calendar para obtener tu configuración de zona horaria de Google.",
  "dashboard.googlePermissionInfo3": "- Se necesita acceso a tu configuración de Gmail para configurar tu respuesta OOO y definir el rango de fechas relevante.",
  "dashboard.hour": "hora(s)",
  "dashboard.hourSingle": "h",
  "dashboard.minuteSingle": "m",
  "dashboard.noLeaveRequest": "Esta solicitud de ausencia no está activa.",
  "dashboard.noOneIsOffToday": "No hay ausencias planificadas para hoy.",
  "dashboard.noOneIsTackingLeaves": "No hay ausencias programadas en este momento.",
  "dashboard.noOpenLeavesRequests": "No hay solicitudes pendientes.",
  "dashboard.noScheduledLeavesHolidays": "No hay ausencias o vacaciones programadas",
  "dashboard.openLeavesRequests": "Solicitudes pendientes",
  "dashboard.outlookPermissionInfo2": "- Acceso sin conexión: Esto es necesario para que Vacation Tracker configure tu respuesta OOO.",
  "dashboard.outlookPermissionInfo3": "- Configuración del buzón de Outlook: Necesitamos acceso a tu configuración de zona horaria de Outlook para garantizar una temporización precisa. Además, requerimos permiso para establecer tu mensaje OOO y definir el rango de fechas deseado.",
  "dashboard.outOfOffice": "Establecer mensaje OOO",
  "dashboard.outOfOffice.requestIntegrationButton": "Solicitar una integración",
  "dashboard.outOfOfficeActiveLabel": "Fuera de la oficina",
  "dashboard.outOfOfficeGoogleMailButton": "Correo de Google",
  "dashboard.outOfOfficeGoogleSigninModalTitle": "Se requiere iniciar sesión en la cuenta de Google",
  "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Se requiere iniciar sesión en la cuenta de Microsoft",
  "dashboard.outOfOfficeNotActiveInfo": "Parece que aún no has habilitado la función de respuesta automática de correo electrónico de fuera de la oficina en tu cuenta. Esta herramienta te permite configurar correos electrónicos automatizados para tipos específicos de ausencia, asegurando que tus contactos sepan cuándo no estás disponible. ¿Te gustaría activar esta función?",
  "dashboard.outOfOfficeOtherEmail": "¿Tienes un proveedor de correo electrónico diferente?",
  "dashboard.outOfOfficeOtherEmailInfo": "En este momento, admitimos Gmail y Outlook. Sin embargo, nos esforzamos constantemente por mejorar nuestro software. Tu opinión sobre el proveedor de correo electrónico que estás utilizando sería muy beneficiosa para ayudarnos a determinar nuestras prioridades de desarrollo. Hasta que ampliemos nuestro soporte a otros proveedores, puedes establecer manualmente tu respuesta de correo electrónico de fuera de la oficina dentro de tu servicio de correo electrónico.",
  "dashboard.outOfOfficeOtherEmailPlaceholder": "Ingresa tu proveedor, por ejemplo, ProtonMail, Yahoo! Mail...",
  "dashboard.outOfOfficeOutlookButton": "Outlook",
  "dashboard.outOfOfficePageSettingsTitle": "ELIGE TU PROVEEDOR DE CORREO ELECTRÓNICO",
  "dashboard.outOfOfficePageTitle": "Fuera de la oficina",
  "dashboard.outOfOfficeSet": "Establecer mensaje OOO",
  "dashboard.outOfOfficeSetError": "Hubo un error al configurar tu respuesta automática de fuera de la oficina. Por favor, inténtalo de nuevo.",
  "dashboard.outOfOfficeSetInfo": "Aquí es donde puedes configurar tu respuesta de correo electrónico 'Fuera de la oficina'. Mientras estés ausente, este mensaje se enviará automáticamente a las personas que intenten comunicarse contigo por correo electrónico. Si decides modificar este mensaje en el futuro, se puede hacer fácilmente a través de tu configuración de correo electrónico.",
  "dashboard.outOfOfficeSetInfoBot": "Tu respuesta de correo electrónico 'Fuera de la oficina' está configurada. Mientras estés ausente, este mensaje se enviará automáticamente a las personas que intenten comunicarse contigo por correo electrónico. Si decides modificar este mensaje en el futuro, se puede hacer fácilmente a través de tu configuración de correo electrónico.",
  "dashboard.outOfOfficeSetSuccess": "Felicitaciones — has configurado exitosamente tu mensaje automático de fuera de la oficina. Estará activo cuando comience tu ausencia.",
  "dashboard.peopleOffToday": "Ausencias de hoy",
  "dashboard.permissionModalInfo": "Para que Vacation Tracker configure automáticamente tu respuesta de fuera de la oficina (OOO), necesitamos permisos adicionales:",
  "dashboard.selectSnoozeLeaveTypesPlaceholder": "Seleccionar tipos de ausencia...",
  "dashboard.snoozedLeaveTypesLabel": "Desactivar para ",
  "dashboard.upcomingDaysOff": "Ausencias programadas ({total})",
  "dashboard.upcomingHolidays": "Próximas vacaciones",
  "departments.viewDepartment": "Ver departamento",
  "disconnectCalendar.success": "Has desconectado el calendario exitosamente.",
  "editLeave.editLeave": "Editar Permiso",
  "editLeave.handleSubmitErrorTitle": "Error al Editar Permiso",
  "editLeave.inProgress": "Editando permiso",
  "editLeave.requestLeave": "Editar Permiso",
  "editLeave.title": "Permiso editado",
  "email.notification.accruedDaysInfoForApprover": "ℹ️ Esta solicitud de permiso se basa en días futuros acumulados, no en la cantidad actual. A {name} le quedan {amount} día(s) hoy. Acumularán {accrued} el {earningDate}.",
  "email.notification.accruedDaysInfoForUser": "ℹ️ Tu solicitud de permiso se basa en días futuros acumulados, no en la cantidad actual. Te quedan {amount} día(s) hoy, y acumularás {accrued} el {earningDate}.",
  "email.notification.holiday.plural": "Las ubicaciones de <strong>{locationNames}</strong> estarán ausentes por <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.holiday.singular": "La ubicación de <strong>{locationNames}</strong> estará ausente por <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.title": "Notificación de Permiso",
  "error.auth.companyExists.line1": "Tu cuenta no está activada. Para usar Vacation Tracker, por favor contacta al administrador y pídele que active tu cuenta.",
  "error.auth.companyExists.line2": "Administradores:",
  "error.auth.companyExists.line3": "O, contacta a nuestro soporte ({support}) si quieres usar Vacation Tracker como un departamento independiente en la misma empresa.",
  "error.auth.companyExists.title": "Tu organización ya está registrada",
  "error.automations.automationSubmitError": "Error al Enviar Automatización",
  "error.automations.insufficientPrivileges.description": "No tienes permiso para realizar esta acción. Por favor, contacta a tu administrador.",
  "error.automations.insufficientPrivileges.title": "Error de permisos",
  "error.blackOutPeriod.isntCreated": "El período de bloqueo no se ha creado",
  "error.blackOutPeriod.isntDeleted": "El período de bloqueo no se ha eliminado",
  "error.blackOutPeriod.isntUpdated": "El período de bloqueo no se ha actualizado",
  "error.blackOutPeriod.UserNotAdminOrApprover": "El usuario no es administrador ni aprobador",
  "error.blackOutPeriod.UserNotFound": "Usuario no encontrado",
  "error.botTokenNotFound": "Token del bot no encontrado, por favor inicia sesión en nuestro panel y vuelve a intentarlo.",
  "error.CannotDuplicateName": "El nombre que ingresaste ya está en uso. Por favor, ingresa un nombre diferente.",
  "error.channelNotFound": "El canal seleccionado ha sido eliminado o ya no está disponible. Por favor, selecciona otro canal para recibir Notificaciones.",
  "error.codeDeliveryFailureException": "Fallo en la entrega del código",
  "error.codeDeliveryFailureExceptionMessage": "No podemos entregar un código de verificación. Por favor, verifica que proporcionaste una dirección de correo electrónico válida. Si tu dirección de correo es válida, por favor intenta de nuevo más tarde.",
  "error.codeMismatchException": "Código de verificación inválido",
  "error.codeMismatchExceptionMessage": "Se proporcionó un código de verificación inválido. Por favor, intenta de nuevo o reenvía el correo.",
  "error.companyExists": "La empresa ya existe",
  "error.CustomWorkday": "No es posible cambiar a un plan sin horas de trabajo personalizadas. Por favor, elimina las horas de trabajo personalizadas de la configuración o contacta a soporte.",
  "error.deletedLocation": "La Ubicación seleccionada ha sido eliminada o ya no está disponible. Por favor, selecciona otra(s) Ubicación(es) para recibir Notificaciones.",
  "error.deletedTeam": "El Departamento seleccionado ha sido eliminado o ya no está disponible. Por favor, selecciona otro(s) Departamento(s) para recibir Notificaciones.",
  "error.DepartmentWithChildrenDeleteForbidden": "No puedes eliminar un departamento que tiene subdepartamentos. Por favor, elimina primero los subdepartamentos.",
  "error.emailError": "Correo electrónico inválido",
  "error.emailError.description": "Por favor, proporciona una dirección de correo electrónico válida",
  "error.endTimeCantBeBeforeStartTime": "Parece que la hora de inicio que has seleccionado es posterior a la hora de finalización. Por favor, selecciona una hora de inicio que sea anterior a la hora de finalización.",
  "error.entitlementByRole.isntCreated": "El derecho por rol no se ha creado",
  "error.entitlementByRole.isntDeleted": "El derecho por rol no se ha eliminado",
  "error.entitlementByRole.isntUpdated": "El derecho por rol no se ha actualizado",
  "error.expiredCodeException": "Código de verificación expirado",
  "error.expiredCodeExceptionMessage": "El código de verificación proporcionado ha expirado.",
  "error.fetchingConnectedCalendarInfo": "Hubo un problema al obtener la información de tu calendario conectado. Por favor, inténtalo de nuevo. Si el problema persiste, contacta a nuestro servicio de atención al cliente",
  "error.fetchingUserOutOfOfficeSettings": "Hubo un problema al obtener tu configuración de fuera de la oficina. Por favor, inténtalo de nuevo. Si el problema persiste, contacta a nuestro servicio de atención al cliente",
  "error.fieldIsRequired": "Este campo es obligatorio.",
  "error.firstEarningDateMissing": "Falta la primera fecha de ganancia",
  "error.generic": "Ha ocurrido un error",
  "error.generic.description": "Por favor, contacta a soporte con el siguiente mensaje:",
  "error.google.directoryApiDisabledP1": "Vacation Tracker necesita acceso a los datos del Directorio para importar nuevos Usuarios.",
  "error.google.directoryApiDisabledP2": "El Administrador de tu Google Workspace puede permitir el acceso al directorio siguiendo los pasos del artículo de soporte: ",
  "error.google.directoryApiDisabledP3": "Puede registrarse sin habilitar esta funcionalidad. Sin embargo, no podrá importar usuarios desde su Google Workspace.",
  "error.google.directoryApiDisabledP4": "Si necesita ayuda adicional, contáctenos en hello@vacationtracker.io",
  "error.google.directoryApiDisabledTitle": "Compartir contactos desactivado",
  "error.google.directoryApiPermissionDeniedP1": "Vacation Tracker necesita poder listar usuarios de su directorio de Google Workspace. Por favor, haga clic en el botón \"Iniciar sesión con Google\" y marque la casilla como se muestra en la imagen de abajo para poder registrarse.",
  "error.google.directoryApiPermissionDeniedP2": "Solo almacenaremos la siguiente información de los usuarios que seleccione explícitamente para importar: nombre, foto de perfil y correo electrónico.",
  "error.google.directoryApiPermissionDeniedP3": "Si no ve la casilla de verificación cuando hace clic en el botón \"Iniciar sesión con Google\", por favor haga clic en el botón \"Continuar\" en el modal de permisos de Google para continuar con el proceso de registro.",
  "error.google.directoryApiPermissionDeniedTitle": "Conceda los permisos faltantes para continuar",
  "error.google.loginRequiredTitle": "Se requiere iniciar sesión en su cuenta de Google",
  "error.google.usersLoginRequiredDescription1": "La gestión de usuarios requiere permisos de Google. Si hace clic en el botón OK, le redirigiremos a la página de inicio de sesión de Google. Dependiendo de la configuración de su navegador, es posible que ya esté conectado y sea redirigido automáticamente.",
  "error.google.usersLoginRequiredDescription2": "Después de iniciar sesión en su cuenta de Google, le llevaremos de vuelta a esta página para que pueda gestionar los usuarios.",
  "error.goToRequestLeaveTab": "Error de transición en la pestaña de Solicitud de Ausencia",
  "error.holidayDateInvalid": "Fecha de festivo inválida {date} para {year}",
  "error.incorrectPassword": "Contraseña incorrecta",
  "error.incorrectPasswordMessage": "La contraseña que ingresó es incorrecta o ha expirado. Por favor, inténtelo de nuevo (asegúrese de que el bloqueo de mayúsculas esté desactivado). ¿Olvidó su contraseña?",
  "error.invalidFileType": "Tipo de archivo inválido",
  "error.invalidFileType.description": "Ha subido un tipo de archivo inválido. Por favor, suba una imagen JPEG, PNG o GIF.",
  "error.invalidPasswordException": "Contraseña inválida",
  "error.invalidPasswordExceptionMessage": "Por favor, proporcione una contraseña válida con 8 caracteres o más.",
  "error.leavePolicyMissing": "Falta la política de ausencias",
  "error.leaveRequestNotEditable": "No se le permite editar esta solicitud de ausencia.",
  "error.leaveRequestOverlap": "Las fechas que ha elegido se superponen con una ausencia existente. Por favor, seleccione un período de tiempo diferente.",
  "error.leaveRequestsLoadingError.description": "Sus ausencias existen, pero no pudimos cargarlas debido a un problema técnico de nuestra parte. Por favor, intente recargar la página. Si el problema persiste, contacte con nuestro <link>Servicio de Atención al Cliente</link>.",
  "error.leaveRequestsLoadingError.title": "No se pueden cargar las ausencias",
  "error.leaveRequestStatusNotApproved": "Estado de la solicitud de ausencia: no aprobada.",
  "error.leaveRequestStatusNotOpen": "Estado de la solicitud de ausencia: no abierta",
  "error.leaveSubmitError": "Error al enviar la ausencia",
  "error.leaveTypeMissing": "Falta el tipo de ausencia",
  "error.LocationLimitReached": "Lo sentimos, pero no puede cambiar al plan CORE ya que actualmente excede el límite de {limitNumber} ubicaciones. Por favor, ajuste sus ubicaciones para cumplir con los criterios del plan CORE o contacte con el soporte para obtener más orientación.",
  "error.microsoft.channelNotFound": "No eres miembro del equipo de Microsoft Teams seleccionado",
  "error.microsoft.logInWithDifferentAccount": "Iniciar sesión con una cuenta diferente",
  "error.microsoft.microsoftPermissionsNotGranted": "Permisos de Microsoft no concedidos",
  "error.microsoft.notificationsLoginRequiredTitle": "Se requiere iniciar sesión en su cuenta de Microsoft",
  "error.microsoft.notMemberOfTeam": "Su organización se registró con un solo equipo de Microsoft Teams, y usted no es miembro del equipo con el siguiente ID: {msTeamId}.\n\nPuede unirse a este equipo en su aplicación de Microsoft Teams e intentarlo de nuevo, o contactar con nuestro soporte para cambiar su tipo de instalación.",
  "error.microsoft.wrongAccount": "Cuenta de Microsoft incorrecta",
  "error.missingSlackBotToken": "La acción ha sido procesada, sin embargo, nos falta el token del bot para notificar al usuario. Por favor, cierre sesión y vuelva a iniciarla para obtener el token del bot.",
  "error.missingSlackBotTokenError": "No podemos procesar la solicitud porque falta el token del bot. Por favor, cierre sesión y vuelva a iniciarla para recibir el token del bot, e inténtelo de nuevo.",
  "error.mustBeApproverForTeam": "No se le permite realizar esta acción, ya que debe ser un Aprobador para este departamento para hacerlo.",
  "error.mustBeCreatorOrApprover": "Debe ser el creador o aprobador para cambiar el estado de la solicitud de ausencia.",
  "error.negativeBallanceNotAllowed": "No se permite un saldo negativo para este tipo de ausencia.",
  "error.notificationGeneral": "Error de notificación",
  "error.oneHoureIntervalNotAllowed": "El intervalo de tiempo más corto que puede solicitar para este tipo de ausencia es de 4 horas (medio día).",
  "error.openApi.isntCreated": "La Clave API no se ha creado",
  "error.openApi.isntDeleted": "La Clave API no se ha eliminado",
  "error.partTimeIsntAllowed": "No se permite el tiempo parcial",
  "error.passwordResetLimitExceededException": "Límite excedido",
  "error.passwordResetLimitExceededExceptionMessage": "Se ha excedido el límite de intentos de cambio de contraseña. Por favor, inténtelo de nuevo en 15 minutos.",
  "error.paymentFailed": "El pago falló",
  "error.paymentNotProcessed": "El pago falló, por favor intente con una tarjeta diferente.",
  "error.probationPeriod.isntCreated": "El período de prueba no ha sido creado",
  "error.probationPeriod.isntDeleted": "El período de prueba no ha sido eliminado",
  "error.probationPeriod.isntUpdated": "El período de prueba no ha sido actualizado",
  "error.PromoCodeDoesntExist": "El código promocional {promoCode} no existe",
  "error.PromoCodeIsntValid": "El código promocional {promoCode} no es válido",
  "error.reasonRequired": "El campo de razón es obligatorio.",
  "error.requestedMoreThanFullDay": "Has solicitado más de un día completo de ausencia en un día laboral. Por favor, selecciona un día diferente.",
  "error.samePasswordException": "Contraseña no cambiada",
  "error.samePasswordExceptionMessage": "La nueva contraseña es la misma que la anterior. Por favor, ingresa una contraseña diferente si deseas cambiarla.",
  "error.saveFailed": "Error al guardar",
  "error.seniorityEntitlement.isntCreated": "No se ha creado el derecho por antigüedad",
  "error.seniorityEntitlement.isntDeleted": "No se ha eliminado el derecho por antigüedad",
  "error.seniorityEntitlement.isntUpdated": "No se ha actualizado el derecho por antigüedad",
  "error.signin.inactiveUser": "Usuario inactivo",
  "error.signup.alreadySignedUp.descriptionLine1": "Ya estás registrado con la siguiente dirección de correo electrónico: <text>{ email }</text>. Por favor, inicia sesión.",
  "error.signup.alreadySignedUp.descriptionLine2": "Si olvidaste tu contraseña, haz clic en el botón de abajo para restablecerla.",
  "error.signup.alreadySignedUp.title": "Ya estás registrado",
  "error.signup.alreadySignedUpInactive.descriptionLine1": "Ya estás registrado con la siguiente dirección de correo electrónico: <text>{ email }</text>.",
  "error.signup.alreadySignedUpInactive.descriptionLine2": "Sin embargo, tu cuenta está inactiva. Por favor, contacta a nuestro soporte para más información y envíales la siguiente información:",
  "error.slack.allowConversationWithSlackBotDescription": "Esta función requiere un permiso adicional de Slack que permite al bot de Vacation Tracker para Slack recibir mensajes directos. Nuestro bot podrá leer los mensajes que tú y otros usuarios de tu espacio de trabajo de Slack le envíen, pero no tendrá permiso para leer tus canales, grupos o mensajes directos.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine1": "El bot de Vacation Tracker admite un comando de barra y un acceso directo para las solicitudes de ausencia. Además de esto, puedes habilitar la conversación en lenguaje humano en el Panel Web (y optar por el uso de LLM).",
  "error.slack.allowConversationWithSlackBotLlmWarningLine2": "Activar esto puede hacer que el bot ocasionalmente sugiera fechas o tipos de ausencia incorrectos. Es importante verificar sus recomendaciones antes de hacer una solicitud de ausencia.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Ni Vacation Tracker ni sus terceros utilizan tu información para entrenar Modelos de Lenguaje Grande.",
  "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Descargo de responsabilidad del Modelo de Lenguaje Grande (LLM)",
  "error.slack.allowConversationWithSlackBotTitle": "Permitir conversación con el bot de Vacation Tracker",
  "error.slack.logInWithDifferentAccount": "Iniciar sesión con una cuenta diferente",
  "error.slack.usersLoginRequiredTitle": "Se requiere iniciar sesión en tu cuenta de Slack",
  "error.slack.wrongAccount": "Cuenta de Slack incorrecta",
  "error.somethingWentWrong": "¡Oh no! 😕 Algo salió mal. ",
  "error.startDateCantBeInPast": "La fecha de inicio no puede estar en el pasado, por favor selecciona una fecha en el futuro",
  "error.subscriptionExpired": "¡Ups, parece que la suscripción de tu empresa a Vacation Tracker ha expirado! Por favor, contacta a un administrador para reactivar la suscripción de tu empresa.",
  "error.subscriptionExpiredTitle": "Suscripción expirada",
  "error.team.delete": "El departamento no puede ser eliminado",
  "error.TeamLimitReached": "Tu número actual de departamentos excede el límite permitido en el plan CORE. Para bajar de categoría, por favor reduce tus departamentos a {limitNumber} o menos, o contacta a nuestro soporte para obtener ayuda.",
  "error.token.button": "Iniciar sesión con {platform}",
  "error.token.description": "Tu token de {platform} ha expirado. Por favor, haz clic en el botón de abajo para obtener un nuevo token",
  "error.token.permissionDenied": "En el mensaje de inicio de sesión de Google, has hecho clic en Continuar sin permitir los ámbitos de autenticación requeridos. Por favor, haz clic en Seleccionar todo o selecciona los permisos adecuados (Ver, editar, compartir y eliminar permanentemente todos los calendarios a los que puedes acceder usando Google Calendar y Ver, editar, crear o cambiar tus configuraciones de correo electrónico y filtros en Gmail) y luego haz clic en Continuar.",
  "error.userEndDateInFuture": "Las fechas solicitadas están después de la fecha de finalización del empleo del usuario. Por favor, contacta al administrador de tu organización o al soporte de Vacation Tracker para obtener más información.",
  "error.UserNotAdminOrApprover": "El usuario no es administrador ni aprobador",
  "error.userNotFound": "Usuario no encontrado",
  "error.UserNotFound": "Usuario no encontrado",
  "error.UserNotFoundApprover": "Aprobador no encontrado",
  "error.userSignup.invitationAccepted.description": "El código de invitación ya ha sido aceptado, por favor inicia sesión",
  "error.userSignup.invitationAccepted.title": "Código de invitación ya aceptado",
  "error.userSignup.invitationExpired.description": "El código de invitación ha expirado, por favor pide a tu administrador que reenvíe la invitación",
  "error.userSignup.invitationExpired.title": "Código de invitación expirado",
  "error.userSignup.invitationNotFound.description": "Por favor, verifica que tu enlace de invitación sea correcto o contacta a tu administrador para que reenvíe la invitación.",
  "error.userSignup.invitationNotFound.title": "Invitación no encontrada",
  "error.userStartDateInFuture": "Las fechas solicitadas son anteriores a la fecha de inicio del empleo del usuario. Por favor, contacta al administrador de tu organización o al soporte de Vacation Tracker para obtener más información.",
  "error.zeroDaysLeaveRequest": "Has seleccionado un día festivo o no laborable. Por favor, selecciona un rango de fechas válido.",
  "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "Parece que has cancelado o cerrado la página \"Iniciar sesión con Microsoft\" antes de otorgar los permisos requeridos. Por favor, haz clic en el botón de abajo para iniciar sesión con Microsoft y finalizar la configuración de Vacation Tracker.\n\nSi no tienes permiso para iniciar sesión con Microsoft, por favor contacta al administrador de Microsoft 365 de tu organización.",
  "errors.microsoft.subscriptionExpiredDescription": "Parece que tu suscripción ha expirado. Por favor, ponte en contacto con el administrador de tu organización de Vacation Tracker para obtener más detalles.\n\nContacta a nuestro soporte si necesitas cambiar el administrador o tienes otras preguntas.",
  "errors.microsoft.wrongMicrosoftAccountDescription": "Parece que has iniciado sesión con una cuenta de Microsoft diferente. Compraste licencias usando la siguiente dirección de correo electrónico:\n\n{email}.\n\nPor favor, inicia sesión con la cuenta que usaste para comprar las licencias para finalizar la configuración.",
  "errors.slack.wrongSlackAccountDescription": "Parece que has iniciado sesión con una cuenta de Slack diferente.",
  "events.logs.AUTOMATION": "Automatizaciones",
  "events.logs.BILLING": "Facturación",
  "events.logs.BULK_ACTIONS": "Importar usuarios",
  "events.logs.COMPANY": "Empresa",
  "events.logs.LABEL": "Etiqueta",
  "events.logs.LEAVE_POLICY": "Política de ausencias",
  "events.logs.LEAVE_TYPE": "Tipo de ausencia",
  "events.logs.LOCATION": "Ubicación",
  "events.logs.LOCATION_YEAR_ROLLOVER": "Cambio de año en ubicación",
  "events.logs.NOTIFICATION": "Notificaciones",
  "events.logs.REPORT": "Informe",
  "events.logs.TEAM": "Departamento",
  "events.logs.TEAM_POLICY": "Política de equipo",
  "export.approved": "Aprobado",
  "export.currentYear": "Año actual",
  "export.denied": "Denegado",
  "export.export": "Exportar",
  "export.exportPeriod": "Período de exportación",
  "export.fileType": "Formato de exportación",
  "export.fileTypeCsv": "CSV",
  "export.fileTypeExcelXlsx": "Excel",
  "export.labels": "Etiquetas",
  "export.lastMonth": "Mes pasado",
  "export.leaveBalanceReport": "Informe de saldo de ausencias",
  "export.leaveBalanceReportCustomDaysInfo": "Tenga en cuenta que el informe solo mostrará los días u horas utilizados si selecciona un período personalizado. No podrá exportar la cuota total o disponible a menos que elija una de las opciones predefinidas.",
  "export.leaveBalanceReportDescription": "La cantidad de ausencias autorizadas y tomadas por Tipo de Ausencia para cada empleado durante un período de tiempo específico.",
  "export.leaveHistoryReport": "Informe de solicitudes de ausencias",
  "export.leaveHistoryReportDescription": "Historial de todas las solicitudes de ausencias (aprobadas, denegadas, expiradas) por Tipo de Ausencia para cada empleado durante un período de tiempo específico.",
  "export.locations": "Ubicaciones",
  "export.monthlyLeaveBalanceReport": "Informe mensual de saldo de ausencias",
  "export.monthlyLeaveBalanceReportDescription": "La cantidad de ausencias tomadas (o programadas) por Tipo de Ausencia para cada empleado para el mes actual y los siguientes 11 meses.",
  "export.nextYear": "Próximo año",
  "export.previousYear": "Año anterior",
  "export.rangeDate": "Fecha de inicio y fin",
  "export.reportType": "Tipo de informe",
  "export.required": "Este campo es obligatorio.",
  "export.status": "Estado",
  "export.teams": "Departamentos",
  "externalConnect.faildLogin": "Su token de un solo uso ha expirado o ya ha sido utilizado. Le redirigiremos a la página de destino en unos segundos, pero es posible que deba iniciar sesión de nuevo.\n\nSi tiene prisa, haga clic en el botón de abajo.",
  "externalConnect.parseError": "¡Oh no! 😕 Algo salió mal.\n\nSerá redirigido a la página de inicio de sesión en 10 segundos.",
  "externalConnect.pleaseWait": "Por favor espere, ¡su PC no es un superhéroe!",
  "form.confirmPasswordInfo": "¡Por favor, confirme su contraseña!",
  "form.department.generalDescriptionCreate": "Establezca el nombre de su departamento y seleccione sus usuarios y aprobadores. Si desea configurar el departamento antes de agregar usuarios, puede crear el departamento primero y agregar usuarios después, pero es necesario agregar al menos un aprobador.",
  "form.department.generalDescriptionEdit": "Cambie el nombre de su departamento o sus usuarios y aprobadores.",
  "form.department.generalTitle": "Configuración del departamento",
  "form.department.setDefaultDescription": "Departamento predeterminado",
  "form.department.setDefaultTitle": "Departamento predeterminado",
  "form.department.subdepartmentDescription": "Puede anidar departamentos para crear una estructura jerárquica de la organización. Si este es un subdepartamento, puede establecer su departamento padre en esta sección.",
  "form.department.subdepartmentTitle": "Configuración de subdepartamento",
  "form.general.companySettingsDescription": "Establezca el nombre de su empresa y el correo electrónico de contacto principal. La dirección de correo electrónico que proporcione se utilizará únicamente para comunicaciones importantes sobre el producto. Sin spam, lo prometemos.",
  "form.general.companySettingsTitle": "Configuración de la empresa",
  "form.general.configurationDescription": "Esta sección le permite ajustar aspectos específicos del seguimiento de ausencias para su empresa. Active las fechas de finalización de usuario para desactivar automáticamente a los empleados con una fecha de finalización, evitando que envíen solicitudes de ausencia más allá de su partida. Elija realizar un seguimiento de las cuotas de ausencia en días u horas, según las políticas de su empresa. Además, defina el año de inicio para el seguimiento de ausencias. Por defecto, los cálculos de ausencias comienzan desde el año en que se registró, pero puede ajustarlo para incluir datos históricos de otros sistemas u hojas de cálculo.",
  "form.general.configurationTitle": "Configuración",
  "form.general.migrationDescription": "¿Está utilizando Slack, Microsoft Teams o Google Workspace? Puede cambiar de la autenticación basada en correo electrónico y contraseña a una integración específica de plataforma. Cambiar a una de nuestras integraciones simplificaría el proceso de inicio de sesión e invitación de usuarios (importar usuarios de su directorio de usuarios) y le proporcionaría integraciones adicionales, como pestañas de Microsoft Teams y bots de Microsoft Teams o Slack.",
  "form.general.migrationFeatureDescription": "Esto iniciará el proceso de migración. Será redirigido a una plataforma donde podrá conectar su cuenta.",
  "form.general.migrationFeatureTitle": "Iniciar migración a otra plataforma",
  "form.general.migrationTitle": "Cambiar a otra plataforma",
  "form.inputRequired": "Este campo es obligatorio",
  "form.inputSize": "El campo debe tener {len} dígitos",
  "form.passwordDoNotMatch": "¡Las dos contraseñas que ha introducido no coinciden!",
  "form.userNameRequired": "Por favor, introduzca su nombre",
  "form.validEmailRequired": "Por favor, introduzca una dirección de correo electrónico válida.",
  "general.announceNewUsers": "Anunciar a nuevos usuarios",
  "general.announceNewUsersInfo": "Enviar mensaje de anuncio sobre Vacation Tracker a nuevos usuarios de Slack/Teams/Google Workspaces",
  "general.announceNewUsersInfoTooltip": "Cuando esta casilla está marcada, cada vez que añada un nuevo usuario a su organización de Slack/Teams/GW, recibirán un breve mensaje de Vacation Tracker explicando cómo funciona. Si esta casilla no está marcada, los nuevos usuarios no recibirán ningún mensaje de anuncio de Vacation Tracker.",
  "general.announceNewUsersTooltip": "Cada vez que añada un nuevo usuario a su {platform}, recibirán un breve mensaje de Vacation Tracker explicando cómo funciona. Si elige la opción No enviar, los nuevos usuarios no recibirán ningún mensaje de anuncio de Vacation Tracker.",
  "general.calculationStartYear": "Vacation Tracker registra sus ausencias desde",
  "general.calculationStartYearTooltipLine1": "Solo necesita cambiar esta configuración si desea importar ausencias históricas de otro sistema u hojas de cálculo e incluir estas ausencias en los cálculos de Vacation Tracker.",
  "general.calculationStartYearTooltipLine2": "Vacation Tracker calcula las ausencias desde el año en que se registró. Eso significa que calcularemos automáticamente los días restantes y trasladados según su configuración, y podrá establecer los días trasladados de su sistema anterior para cada usuario en sus páginas de perfil. Sin embargo, si desea que Vacation Tracker calcule las ausencias de años pasados, puede cambiar esta fecha. No dude en <support>contactar con nuestro soporte</support> para obtener más información.",
  "general.clickToSelectPlatform": "Haga clic para seleccionar la plataforma",
  "general.companyId": "ID de la empresa:",
  "general.companyName": "Nombre de la empresa",
  "general.confirmModalTitle": "Confirmar actualizaciones de la configuración de la empresa",
  "general.connectSync": "Conectar sincronización",
  "general.connectToAnotherPlatform": "Cambiar a otra plataforma",
  "general.connectWith": "Cambiar a {platform}",
  "general.connectWithHelpdeskInfo": "¿No está seguro de qué plataforma elegir? Este <link>artículo</link> podría ayudar.",
  "general.days": "Días",
  "general.deleteAccount": "Si desea modificar o eliminar sus datos, por favor contacte a nuestro equipo de soporte en <mail>hello@vacationtracker.io</mail>",
  "general.enableUserEndDate": "Habilitar fecha de finalización de usuario",
  "general.enableUserEndDateInfo": "Cuando se activa, los usuarios con una fecha de finalización no podrán enviar solicitudes de ausencia más allá de esa fecha, y los desactivaremos automáticamente.",
  "general.enableUserEndDateTooltip": "Cuando se activa la Fecha de Finalización del Usuario, los Usuarios no podrán enviar solicitudes de ausencia para el período posterior a su fecha de finalización. Serán desactivados automáticamente y no podrán iniciar sesión en Vacation Tracker.",
  "general.googleConsent": "Inicio de sesión de Google",
  "general.googleConsentInfo": "Consentimiento de Google",
  "general.googleMorePermission": "Google zeza sa imenima ako hocete da budete sigurni da imate puno ime i prezime treba nam jos permission-a, kliknite ovde da nam date jos permission-a",
  "general.hourlyLeaveAccounting": "Establecer cuotas de ausencia en",
  "general.hourlyLeaveAccountingTooltip": "Cuando se activa, esta opción rastrea la duración de todas las ausencias y cuotas en horas. Disponible solo para el plan Complete.",
  "general.hours": "Horas",
  "general.importUsersAutomatically": "Importar usuarios automáticamente",
  "general.importUsersAutomaticallyInfo": "Con la importación automática de usuarios activada, importaremos y activaremos automáticamente todos los nuevos usuarios regulares de {platform}.",
  "general.importUsersAutomaticallyInfoTooltip": "Por ejemplo, cuando invita a un nuevo usuario a su espacio de trabajo, ese usuario estará disponible en Vacation Tracker unos minutos después de la invitación. Si desea activar un usuario invitado multicanal, deberá invitarlo manualmente.",
  "general.importUsersAutomaticallyTurnOffInfo": "Ya no importaremos ni activaremos automáticamente todos los nuevos usuarios regulares de {platform}.",
  "general.importUsersConfigSection": "Configuración de importación de usuarios",
  "general.importUsersConfigSectionDescription": "Esta sección permite habilitar la importación automática de usuarios desde su directorio de usuarios y configurar los ajustes de importación de usuarios, como el mensaje de anuncio, el departamento predeterminado y la ubicación predeterminada para los nuevos usuarios.",
  "general.pleaseEnterCompanyName": "Por favor, introduzca el nombre de la empresa",
  "general.pleaseEnterContactEmail": "Por favor, introduzca el correo electrónico de la empresa",
  "general.syncGoogleUsers": "Sincronizar usuarios de Google",
  "general.syncGoogleUsersInfo": "Sincronizar usuarios de Google borrado automático, actualización y adición de usuarios",
  "general.updateInProgress": "Actualizando la configuración de la empresa",
  "general.updateSuccessTitle": "Configuración de la empresa actualizada",
  "general.usefulLinks": "<healthcheck>Chequeo de salud</healthcheck>, <helpdesk>Mesa de ayuda</helpdesk>",
  "general.usefulLinksTitle": "Enlaces útiles: ",
  "holidays.filterByType": "Filtrar por tipo de festivo",
  "holidays.holidaysName": "Nombre del festivo",
  "holidays.holidaysTab": "Festivos para {year}",
  "holidays.holidaysType": "Tipo de festivo",
  "holidays.importHolidays": "Importar festivos",
  "holidays.importHolidaysAutomaticallyQuestion": "¿Desea importar automáticamente los festivos seleccionados para los años futuros?",
  "holidays.importHolidaysAutomaticallyTooltip": "Vacation Tracker ajustará automáticamente los días para los festivos públicos que no caen en la misma fecha cada año, como el Día de Acción de Gracias, Pascua, etc.",
  "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Desafortunadamente, esta función no está actualmente disponible para este país. Estamos trabajando para expandir nuestros servicios, así que por favor manténgase atento a las actualizaciones.",
  "holidays.importHolidaysAutomaticallyTurnedOff": "Importación automática de festivos desactivada",
  "holidays.importHolidaysAutomaticallyTurnedOn": "Importación automática de festivos activada",
  "holidays.importHolidaysCount": "Importar {count} festivos seleccionados",
  "holidays.importHolidaysFor": "Importar festivos para <b>{year}</b> para <b>{countryState}</b>",
  "holidays.importHolidaysSummary": "Ha seleccionado los siguientes festivos para <b>{year}</b> para <b>{countryState}</b>",
  "holidays.pastHolidays": "Festivos pasados",
  "holidays.totalSelectedHolidays": "Ha seleccionado {total} festivo(s)",
  "holidays.totalSelectedHolidaysTip": "({total} no visible debido al filtro aplicado)",
  "holidays.typeBank": "Bancario",
  "holidays.typeObservance": "Conmemorativo",
  "holidays.typeOptional": "Opcional",
  "holidays.typePublic": "Público",
  "holidays.typeSchool": "Escolar",
  "importLeaves.importFail": "La importación falló con el siguiente mensaje de error:",
  "importLeaves.importSuccess": "Ha enviado ausencias para importar. Verifique en unos minutos si se han importado correctamente.",
  "importUsers.allUsersImported.description": "¡Parece que ya ha importado todos los usuarios de su directorio! Debería ver todos los usuarios en la página de Usuarios. Si no ve a alguien, eche un vistazo a la pestaña Inactivos. No dude en contactar con nuestro soporte si tiene alguna pregunta o necesita ayuda.",
  "importUsers.allUsersImported.goToUsersPageButton": "Ir a la página de Usuarios",
  "importUsers.allUsersImported.title": "¡Todos los usuarios ya importados!",
  "importUsers.form.backButton": "Volver a la página de Usuarios",
  "importUsers.form.searchGoogleUsersPlaceholder": "Buscar usuarios en su espacio de trabajo de Google",
  "importUsers.form.searchMicrosoftUsersPlaceholder": "Escriba al menos un carácter para comenzar a buscar usuarios en su Directorio Activo de Microsoft 365",
  "importUsers.form.searchSlackUsersPlaceholder": "Buscar usuarios en su espacio de trabajo de Slack",
  "importUsers.form.searchUsersNotFound": "Usuario no encontrado o ya importado",
  "importUsers.form.searchUsersPlaceholder": "Buscar usuarios en su Directorio Activo de Microsoft 365",
  "importUsers.form.selectedUsersLabel": "Usuarios seleccionados ({selectedUsers})",
  "importUsers.form.showSelectedUsersAsNumber": "Usuarios seleccionados",
  "importUsers.statistics.imported": "Usuarios importados a Vacation Tracker",
  "importUsers.statistics.total": "Total de usuarios en su {directoryName}",
  "leaveRequest.halfDay": "Medio día",
  "leaveRequest.hourly": "Por horas",
  "leaveRequest.sent": "Solicitud de ausencia enviada",
  "leaveRequest.simpleQuota.addAnotherLeave": "Agregar otra ausencia",
  "leaveRequest.simpleQuota.info": "Aquí está su balance de ausencias para {leaveTypeName} para {start} - {end}.",
  "leaveRequest.simpleQuota.requestAnotherLeave": "Solicitar otra ausencia",
  "leaveRequest.simpleQuota.summaryInfo": "Enviamos la siguiente solicitud para aprobación a su gerente",
  "leaveRequests.addedCurrentYear": "Agregado para el año actual",
  "leaveRequests.addeddNextYear": "Agregado para el próximo año",
  "leaveRequests.cancelThisAction": "Cancelar esta acción",
  "leaveRequests.dates": "Fecha{plural}",
  "leaveRequests.daysRemainingForCurrentYear": "Días restantes para el año actual",
  "leaveRequests.daysRemainingForNextYear": "Días restantes para el próximo año",
  "leaveRequests.daysRequestedForCurrentYear": "Días solicitados para el año actual",
  "leaveRequests.daysRequestedForNextYear": "Días solicitados para el próximo año",
  "leaveRequests.denyWithReason": "Denegar con motivo",
  "leaveRequests.hoursRemainingForCurrentYear": "Horas restantes para el año actual",
  "leaveRequests.hoursRemainingForNextYear": "Horas restantes para el próximo año",
  "leaveRequests.hoursRequestedForCurrentYear": "Horas solicitadas para el año actual",
  "leaveRequests.hoursRequestedForNextYear": "Horas solicitadas para el próximo año",
  "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> ha enviado la siguiente solicitud de ausencia:",
  "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> ha enviado la siguiente solicitud de TOIL:",
  "leaveRequests.leaveTypeName": "Tipo de ausencia",
  "leaveRequests.pending": "Solicitud pendiente",
  "leaveRequests.reason": "Motivo",
  "leaveRequests.reasonForDenying": "Motivo de denegación",
  "leaveRequests.remainingCurrentYear": "Restante para el año actual",
  "leaveRequests.remainingNextYear": "Restante para el próximo año",
  "leaveRequests.requestedCurrentYear": "Solicitado para el año actual",
  "leaveRequests.requestedNextYear": "Solicitado para el próximo año",
  "leaveRequests.requestSummary": "Resumen de la solicitud",
  "leaveRequests.wrongAction": "Esta acción es desconocida para nosotros...🤔 ¡Por favor, intenta una diferente! Serás redirigido al Panel de Control en 7 segundos.",
  "leaveRequests.wrongLeaveRequestId": "¡Ups! ¡Esta solicitud de ausencia no se encuentra por ninguna parte! 🔎 Serás redirigido al Panel de Control en 7 segundos.",
  "leaveRequestStatus.approved": "aprobado",
  "leaveRequestStatus.APPROVED": "aprobado",
  "leaveRequestStatus.cancelled": "cancelado",
  "leaveRequestStatus.CANCELLED": "cancelado",
  "leaveRequestStatus.deleted": "eliminado",
  "leaveRequestStatus.DELETED": "eliminado",
  "leaveRequestStatus.denied": "denegado",
  "leaveRequestStatus.DENIED": "denegado",
  "leaveRequestStatus.open": "abierto",
  "leaveRequestStatus.OPEN": "abierto",
  "leaveTypes.activatingLeaveType": "Activar tipo de ausencia",
  "leaveTypes.activatingLeaveTypeInfo": "Al activar este tipo de ausencia, estás restaurando su ubicación anterior en las localizaciones, así como sus configuraciones y datos pasados.",
  "leaveTypes.activatingLeaveTypeTitle": "Activar tipo de ausencia",
  "leaveTypes.addLeaveTypeInfo": "Crea un tipo de ausencia personalizado añadiendo un nombre, color para una navegación más fácil y estado (Activo/Inactivo). No te preocupes, puedes editar todas las configuraciones del tipo de ausencia más adelante. \n\nPara asignar una política de ausencia personalizada a un tipo de ausencia, visita Localizaciones -> Asignar política de ausencia.",
  "leaveTypes.addTypeToLocationButton": "Añadir",
  "leaveTypes.assignedToLocationsColumn": "Asignado a localizaciones",
  "leaveTypes.assignedToLocationsColumnWarning": "Este tipo de ausencia no está asignado a ninguna localización.",
  "leaveTypes.color": "Color",
  "leaveTypes.createInProgress": "Creando el tipo de ausencia {leaveTypeName}",
  "leaveTypes.createNewLeaveType": "Crear un tipo de ausencia",
  "leaveTypes.deactivateLeaveType": "Desactivar tipo de ausencia",
  "leaveTypes.deactivateLeaveTypeInfo": "Al desactivar este tipo de ausencia, lo estás eliminando de todas las localizaciones que lo están utilizando actualmente. Cuando actives el tipo de ausencia de nuevo, aparecerá en las mismas localizaciones con la configuración y datos existentes.",
  "leaveTypes.deactivateLeaveTypeTitle": "Desactivar tipo de ausencia",
  "leaveTypes.deleteInProgress": "Eliminando el tipo de ausencia {leaveTypeName}",
  "leaveTypes.deleteLeaveType": "Eliminar tipo de ausencia",
  "leaveTypes.deleteLeaveTypeConfirm": "¿Estás seguro de que quieres eliminar el tipo de ausencia {name}?",
  "leaveTypes.deleteLeaveTypeTitle": "Eliminar tipo de permiso",
  "leaveTypes.editLeaveType": "Editar tipo de permiso",
  "leaveTypes.isActive": "Activo",
  "leaveTypes.sort": "Ordenar",
  "leaveTypes.typeName": "Nombre",
  "leaveTypes.updateInProgress": "Actualizando el tipo de permiso {leaveTypeName}",
  "location.createInProgress": "Creando la ubicación {locationName}",
  "location.deleteInProgress": "Eliminando la ubicación {locationName}",
  "location.editLocation": "Editar ubicación",
  "location.fiscalYearStart": "Inicio del año fiscal",
  "location.general": "General",
  "location.hasNoUsers": "La ubicación no tiene usuarios.",
  "location.holidays": "Días festivos",
  "location.holidays.autoimportTitle": "Se han cambiado los ajustes de importación automática de días festivos",
  "location.holidays.brokenHolidays": "Este día festivo no se puede añadir, por favor contacte con soporte para resolver el problema",
  "location.holidays.successTitle": "Los días festivos se han actualizado correctamente",
  "location.holidays.updateInProgress": "Actualizando días festivos",
  "location.holidaysUpdated": "Días festivos actualizados",
  "location.leavePolicies": "Políticas de permisos",
  "location.leavePoliciesNotExistInfo": "Actualmente no hay políticas de permisos disponibles. Por favor, asigne una política de permisos a la ubicación.",
  "location.name": "Nombre",
  "location.never": "Nunca",
  "location.resetQuotas": "Reinicio de cuota de permisos basado en",
  "location.resetQuotasInfo": "Esta configuración determinará si su balance anual de permisos se reiniciará basándose en el año contable (año fiscal de la empresa) o en la fecha de inicio del empleado. Además de las cuotas, su política de transferencia también se verá afectada según esta configuración.",
  "location.rolloverExpiryAfterDays": "Caducidad de días transferidos",
  "location.rolloverExpiryAfterHours": "Caducidad de horas transferidas",
  "location.rolloverExpiryDay": "¿Deben caducar los días transferidos?",
  "location.rolloverExpiryHour": "¿Deben caducar las horas transferidas?",
  "location.rolloverExpiryOnDate": "Los días transferidos caducan el",
  "location.rolloverExpiryOnDate.extra": "Los días caducarán al final del día.",
  "location.rolloverExpiryOnDateHours": "Las horas transferidas caducan el",
  "location.rolloverExpiryOnDateHours.extra": "Las horas caducarán al final del día.",
  "location.startYear": "Inicio del año fiscal",
  "location.startYearInfo": "El año fiscal de una organización es un año calculado para fines fiscales o contables. Las fechas de reinicio del período de vacaciones dependen del inicio y fin del año fiscal de la organización.",
  "location.timezone": "Zona horaria",
  "location.timezoneDefault": "(GMT+00:00) GMT (sin horario de verano)",
  "location.updateInProgress": "Actualizando la ubicación {locationName}",
  "location.users": "Usuario(s)",
  "location.workWeek": "Semana laboral",
  "locationForm.assignLeaveTypesInfo": "Una vez que haya creado una ubicación, asigne una política de permisos a la ubicación para permitir que los usuarios soliciten permisos. \nPara asignar una política de permisos, vaya a Ubicación -> Políticas de permisos -> Asignar política de permisos.",
  "locationForm.createdSuccessfully": "Por favor, espere un momento, estamos creando su ubicación.",
  "locationForm.createLocation": "Crear ubicación",
  "locationForm.editLocation": "Editar ubicación",
  "locationForm.locationLimitReached": "¡Ups! Parece que has alcanzado el límite de {limitNumber} ubicaciones para tu plan actual. Para gestionar o actualizar tu plan, por favor visita la <billingPage>página de facturación</billingPage>.",
  "locationForm.updateLocation": "Actualizar ubicación",
  "locations.automaticallyGeneratedName": "Generamos automáticamente esta ubicación para ti agrupando los siguientes equipos: <t>{team}</t>.",
  "locations.automaticallyTransferredLeavePoliciesAndHolidays": "Las políticas de permisos y los días festivos se transfieren automáticamente por ti, así que <b>{not}</b> necesitas recrearlos.",
  "locations.created": "Creado",
  "locations.createNewLocation": "Crear una ubicación",
  "locations.default": "Predeterminado",
  "locations.defaultLocation": "Ubicación predeterminada",
  "locations.defaultLocationDescription": "Todos los nuevos usuarios se añadirán automáticamente a esta ubicación.",
  "locations.deleteLocation": "Eliminar ubicación",
  "locations.deleteLocation.disabled": "No puedes eliminar la ubicación predeterminada",
  "locations.deleteLocationConfirm": "Por favor, confirma que deseas eliminar la ubicación {name}.",
  "locations.deleteLocationConfirmInitialRollover": "Eliminar una ubicación podría afectar la configuración de los usuarios y la información de permisos (por ejemplo, los días iniciales traspasados).",
  "locations.deleteLocationTitle": "Eliminar ubicación {name}",
  "locations.editLocation": "Editar",
  "locations.hasNoUsers": "Ninguno",
  "locations.holidays": "Festivos",
  "locations.leavePolicies": "Políticas de permisos",
  "locations.leavePolicies.quotaDays": "{value} días",
  "locations.leavePolicies.quotaHours": "{value} horas",
  "locations.leavePolicies.yearlyQuota": "Cuota anual de permisos",
  "locations.locationLimitReached": "Actualmente estás utilizando todas las {limitNumber} ubicaciones disponibles en el plan CORE. Para gestionar o actualizar tu plan, visita la <billingPage>página de facturación</billingPage>.",
  "locations.name": "Nombre",
  "locations.pleaseRenameLocation": "¡Por favor, renombra esta ubicación!",
  "locations.pleaseRenameLocationName": "Por favor, renombra los nombres de ubicación generados automáticamente *",
  "locations.resetQuotas": "Reinicio de cuota de permisos basado en",
  "locations.selectLocation": "Seleccionar ubicación",
  "locations.unableToNameThisLocation": "Sin embargo, nuestro sistema no pudo nombrar correctamente esta ubicación.",
  "locations.users": "Usuario(s)",
  "locations.viewHolidays": "Ver festivos",
  "locations.viewLeavePolicies": "Ver políticas de permisos",
  "locations.viewLocation": "Ver",
  "locations.weAutomaticallyCreatedLocations": "Hemos creado automáticamente ubicaciones para tu organización agrupando tus equipos por festivos. Todas las políticas de permisos se han transferido, solo necesitas renombrar tus ubicaciones.",
  "manageMicrosoftLicenses.assignLicencesSuccessTitle": "Las licencias se han asignado correctamente.",
  "manageMicrosoftLicenses.weAreAssignLicenses": "Estamos asignando las licencias.",
  "microsoft.adminConsentErrorButton": "Obtener acceso",
  "microsoft.adminConsentErrorDescription1": "Parece que no eres administrador de tu organización de Microsoft Teams. La configuración de las notificaciones requiere el consentimiento del administrador. Por favor, envía el siguiente enlace al administrador de tu organización para obtener el consentimiento del administrador: ",
  "microsoft.adminConsentErrorDescription2": "Cuando obtengas el consentimiento del administrador, haz clic en el botón de abajo para obtener acceso a las notificaciones. Si eres administrador, visita el mismo enlace, otorga el consentimiento del administrador y podrás configurar las notificaciones.",
  "microsoft.adminConsentErrorTitle": "Se requiere el consentimiento del administrador de Microsoft Teams.",
  "microsoft.createCompanyTeamLoginAdminConsentError1": "Parece que no eres administrador de tu organización de Microsoft Teams. Importar miembros del equipo de un único equipo de Microsoft Teams requiere el consentimiento del administrador. Por favor, envía el siguiente enlace al administrador de tu organización para obtener el consentimiento del administrador: ",
  "microsoft.createCompanyTeamLoginAdminConsentError2": "Cuando obtengas el consentimiento del administrador, podrás continuar con el proceso de registro. Si eres administrador, visita el mismo enlace, otorga el consentimiento del administrador y podrás continuar con el registro.",
  "microsoft.createCompanyTeamLoginRequiredDescription1": "Necesitamos permisos adicionales de Microsoft Teams para listar los usuarios del equipo que has seleccionado. Si haces clic en el botón OK, te redirigiremos a la página de inicio de sesión de Microsoft. Dependiendo de la configuración de tu navegador, es posible que ya hayas iniciado sesión y seas redirigido automáticamente.",
  "microsoft.createCompanyTeamLoginRequiredDescription2": "Después de iniciar sesión en tu cuenta de Microsoft, te devolveremos a esta página para que puedas seleccionar los usuarios a importar. Hacer clic en el botón Cancelar aún te permitirá registrarte con toda tu organización.",
  "microsoft.createCompanyTeamLoginRequiredTitle": "Se requieren permisos adicionales de Microsoft Teams.",
  "microsoft.manageLicenses.loginMicrosoft": "Inicia sesión con tu cuenta de Microsoft 365 para asignar licencias.",
  "microsoft.manageLicenses.loginMicrosoftInfo": "Iniciar sesión con tu cuenta listará los usuarios en tu Directorio Activo de Microsoft. Podrás asignar licencias compradas y, opcionalmente, notificar a los usuarios importados.",
  "microsoft.notificationsLoginRequiredDescription1": "La gestión de notificaciones requiere un token de Microsoft Teams. Si haces clic en el botón OK, te redirigiremos a la página de inicio de sesión de Microsoft. Dependiendo de la configuración de tu navegador, es posible que ya hayas iniciado sesión y seas redirigido automáticamente.",
  "microsoft.notificationsLoginRequiredDescription2": "Después de iniciar sesión en tu cuenta de Microsoft, te devolveremos a esta página para que puedas gestionar las notificaciones. Hacer clic en el botón Cancelar te llevará de vuelta a la última página que visitaste en nuestro panel.",
  "microsoft.notificationsLoginRequiredTitle": "Se requiere iniciar sesión en tu cuenta de Microsoft",
  "microsoft.universalLoginRequiredDescription": "Parece que tu token ha expirado. Por favor, inicia sesión en tu cuenta de Microsoft Teams para continuar.",
  "microsoft.universalLoginRequiredTitle": "Token expirado",
  "microsoft.usersLoginRequiredDescription1": "La gestión de usuarios requiere un token de Microsoft Teams. Si haces clic en el botón OK, te redirigiremos a la página de inicio de sesión de Microsoft. Dependiendo de la configuración de tu navegador, es posible que ya hayas iniciado sesión y seas redirigido automáticamente.",
  "microsoft.usersLoginRequiredDescription2": "Después de iniciar sesión en tu cuenta de Microsoft, te devolveremos a esta página para que puedas gestionar los usuarios.",
  "microsoft.usersLoginRequiredTitle": "Se requiere iniciar sesión en tu cuenta de Microsoft",
  "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "Tu empresa ya está registrada, pero no estás activo. ¿Estás utilizando el correo electrónico correcto?\nTu dirección de correo electrónico: {email}\nSi este correo es correcto, tu cuenta no está activada. Por favor, contacta al administrador de tu organización de Vacation Tracker:\n{admins}\nO contacta a nuestro soporte si deseas registrarte como un equipo independiente separado en el mismo inquilino.\nSi tu correo electrónico no es el correcto, por favor inicia sesión con una cuenta de Microsoft diferente o inicia sesión desde una ventana de incógnito en tu navegador.",
  "microsoftSass.createCompany.alertError.errorTitle": "Error",
  "microsoftSass.createCompany.alertError.goToAdminCenter": "Ir al Centro de administración",
  "microsoftSass.createCompany.alertError.goToConnectPage": "Ir a la página de conexión",
  "microsoftSass.createCompany.alertError.logInWithPlatform": "Iniciar sesión con {platform}",
  "microsoftSass.createCompany.alertError.resubscribedDescription": "Se ha reactivado su suscripción exitosamente como empresa - {companyName}.\n\nPor favor, haga clic en el botón para ser redirigido a la página de inicio de sesión.",
  "microsoftSass.createCompany.alertError.resubscribedTitle": "Ha reactivado su suscripción exitosamente",
  "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Su token de compra ha expirado. Por favor, vaya al Centro de administración de Microsoft Teams y haga clic en el botón \"Configurar cuenta\" para continuar.",
  "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Token de compra expirado",
  "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Su token de compra de Microsoft no es válido. Si compró el plan de Vacation Tracker a través de la tienda de aplicaciones de Microsoft Teams, por favor vaya al Centro de administración de Microsoft Teams y haga clic en el botón \"Configurar cuenta\" para continuar. De lo contrario, vaya a nuestra página de conexión para iniciar sesión o crear una cuenta.",
  "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Token de compra inválido",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "Ya tiene una cuenta. Su empresa ({companyName}) se registró usando {platform}. Haga clic en el botón de abajo para iniciar sesión usando su cuenta de {platform}.\n\nNo se preocupe. Hemos cancelado su compra y no se le cobrará por las licencias que acaba de comprar. La suscripción antigua de su empresa sigue activa. Puede visitar el Centro de administración de Microsoft para verificar que su nueva suscripción ha sido cancelada.\n\nSi desea comprar otra suscripción intencionalmente, por favor contacte a nuestro soporte.",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "Ya tiene una cuenta",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "ASIGNAR LICENCIAS COMPRADAS",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "¡Asignemos las licencias que compró! Tiene {totalLicenses} licencias. Asígnelas a los usuarios que podrán usar Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "Puede comprar más licencias más tarde en la página de Usuarios del panel de Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "¡No se preocupe! No importaremos ni notificaremos a nadie sin su aprobación.",
  "microsoftSass.createCompany.completed": "Completado",
  "microsoftSass.createCompany.createCompanySetupInfo": "Configuremos el correo electrónico de contacto y los ajustes predeterminados para su organización de Vacation Tracker.",
  "microsoftSass.createCompany.createCompanyTitle": "COMPLETAR CONFIGURACIÓN DE LA EMPRESA",
  "microsoftSass.createCompany.finishFristStep": "Por favor, complete la configuración de la empresa para continuar.",
  "microsoftSass.createCompany.finishSecondStep": "Por favor, asigne licencias para continuar.",
  "microsoftSass.createCompany.finishSetup": "Finalizar configuración",
  "microsoftSass.createCompany.help": "Si necesita ayuda, visite nuestro <helpdesk>Centro de ayuda</helpdesk> o <support>contacte a nuestro soporte</support>.",
  "microsoftSass.createCompany.importAllUsers": "Asignado a todos los usuarios",
  "microsoftSass.createCompany.letsCompleteSetup": "Completemos la configuración, asignemos licencias a sus usuarios e instalemos la integración de Vacation Tracker para Microsoft Teams.",
  "microsoftSass.createCompany.numberOfLicensesAssigned": "{assignedLicenses} licencias asignadas",
  "microsoftSass.createCompany.setupMsBot.areYouAdmin": "¿Es usted el administrador de su integración de Microsoft Teams?",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "Parece que no otorgó los dos permisos adicionales de Microsoft Teams que necesitamos para configurar el bot y las pestañas del canal. Sin este paso, no podremos enviar un mensaje de bienvenida a los miembros del equipo seleccionados, y usted no podrá usar la aplicación Vacation Tracker desde su aplicación de Microsoft Teams.",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "Puede omitir este paso e instalar el bot y las pestañas manualmente. Consulte nuestro Centro de ayuda para obtener una guía paso a paso.",
  "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Instalar bot y pestañas",
  "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "Instalaré el bot y las pestañas manualmente",
  "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "Necesitamos permisos adicionales para instalar el chatbot y las pestañas de Vacation Tracker en su Microsoft Teams. Lo redirigiremos a la pantalla de permisos de Microsoft cuando haga clic en el botón Continuar a continuación.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Por favor, instale el bot y las pestañas de Microsoft Teams de Vacation Tracker manualmente siguiendo los pasos descritos en <helpdesk>este artículo del Centro de ayuda</helpdesk>. No debería necesitar privilegios de administrador para la configuración manual. No debería necesitar asistencia de administrador para la configuración manual. Si necesita ayuda, contacte a <support>nuestro soporte</support>.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "Necesita instalar la integración de Microsoft manualmente",
  "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "Necesitamos el consentimiento del administrador para configurar el bot y las pestañas. No podemos enviar mensajes de bienvenida hasta que instale el bot.",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Seleccione el canal de Microsoft Teams donde desea instalar las pestañas",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Seleccionar canal de Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Seleccione el equipo de Microsoft Teams donde desea instalar el bot",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Seleccionar equipo de Microsoft Teams",
  "microsoftSass.createCompany.setupMsBotAndTabs": "CONFIGURAR BOT Y PESTAÑAS DE VACATION TRACKER",
  "microsoftSass.createCompany.setupMsBotAndTabsInfo": "¡Instalemos el bot y las pestañas de Vacation Tracker para Microsoft Teams!",
  "microsoftSass.createCompany.stepAssignLicenses": "2. Asignar licencias",
  "microsoftSass.createCompany.stepCreateComapny": "1. Completar la configuración de la empresa",
  "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Configurar el bot y las pestañas de Microsoft Teams",
  "microsoftSass.createCompany.welcomeMessage": "¡Bienvenido a Vacation Tracker! Gracias por comprar <bold>{totalLicenses} licencias</bold>.",
  "microsoftSass.createCompany.welcomeTitle": "CONFIGUREMOS VACATION TRACKER",
  "microsoftSubscription.adminInitialContent": "Su suscripción ha expirado. Por favor, vaya a la tienda Microsoft AppSource para reactivar la suscripción. Si tiene alguna pregunta o necesita ayuda, contacte a nuestro soporte.",
  "microsoftSubscription.goToAppSource": "Comprar una nueva suscripción",
  "modal.dontHaveAnAccount.accountNotFound": "Cuenta no encontrada",
  "modal.dontHaveAnAccount.asCompany": "Como empresa",
  "modal.dontHaveAnAccount.asCompanyDescription": "Usted es un administrador o propietario de negocio que desea probar Vacation Tracker.",
  "modal.dontHaveAnAccount.asEmployee": "Como empleado",
  "modal.dontHaveAnAccount.asEmployeeDescription": "Tu empresa utiliza Vacation Tracker, pero tú no tienes acceso a él.",
  "modal.dontHaveAnAccount.employeeSignUp": "Registro de empleado",
  "modal.dontHaveAnAccount.employeeSignupDescription": "Si tu empresa ya está utilizando Vacation Tracker, ¡no necesitas registrarte! Contacta a tu administrador para que te active, y tendrás acceso completo al panel de control e integraciones de Vacation Tracker.",
  "modal.dontHaveAnAccount.employeeSignupSupport": "Si necesitas ayuda o no estás seguro de quién es tu administrador, no dudes en contactar a nuestro soporte.",
  "modal.dontHaveAnAccount.introParagraph": "Parece que no tienes una cuenta. ¿Cómo quieres usar Vacation Tracker?",
  "modal.forgotPassword.step2Intro": "Hemos enviado un código a tu correo electrónico. Por favor, ingrésalo a continuación y establece una nueva contraseña. Si no has recibido un correo electrónico en 5 minutos, revisa tu carpeta de spam y verifica si ingresaste la dirección de correo electrónico correcta.",
  "myProfile.cancel": "Cancelar",
  "myProfile.cancelLeave": "Cancelar",
  "myProfile.cancelLeaveConfirmTitle": "Cancelar Permiso",
  "myProfile.dontHaveAnyHistory": "{name} no ha tomado ningún permiso aún.",
  "myProfile.edit.title": "Editar Mi Perfil",
  "myProfile.editLeave": "Editar Permiso",
  "myProfile.editLeaveWord": "Editar",
  "myProfile.general": "General",
  "myProfile.history": "Historial",
  "myProfile.historyLeaves": "Historial",
  "myProfile.leaveHistory": "Historial de Permisos",
  "myProfile.leaveTypes": "Tipos de Permiso",
  "myProfile.noUpcomingLeaves": "No hay Permisos Programados",
  "myProfile.openMyProfile": "Ver Mi Perfil",
  "myProfile.upcomingLeaves": "Permisos Programados",
  "myProfile.userWorkWeek": "Semana Laboral",
  "not_in_channel": "El canal seleccionado ha sido eliminado o ya no está disponible. Por favor, selecciona otro canal para recibir Notificaciones.",
  "notification.codeResent": "Código de verificación reenviado",
  "notification.codeResentMessage": "Por favor, revisa tu correo electrónico",
  "notification.passwordReset": "Contraseña restablecida con éxito",
  "notification.passwordResetMessage": "Ahora puedes iniciar sesión utilizando tu nueva contraseña.",
  "notifications.add": "Nueva Notificación",
  "notifications.cantOpenNoptification": "Esta Notificación fue creada por otro usuario y no puedes editarla.",
  "notifications.createDailyRequestSubmitted": "Configurando notificaciones diarias",
  "notifications.createWeeklyRequestSubmitted": "Configurando notificaciones semanales",
  "notifications.deleteAutomationConfirm": "Por favor, confirma que deseas eliminar la automatización <strong>{name}</strong>.",
  "notifications.deleteAutomationTitle": "Eliminar automatización",
  "notifications.deleteDailyRequestSubmitted": "Eliminando notificaciones diarias",
  "notifications.deleteEntitlementByRoleAutomationConfirm": "¿Estás seguro de que quieres eliminar esta automatización de Derecho por rol? No puedes deshacer esta acción. Sin embargo, eliminar la automatización no afectará los días de {leaveTypeName} ya otorgados.",
  "notifications.deleteNotificationConfirm": "Por favor, confirma que deseas eliminar la notificación <strong>{name}</strong>.",
  "notifications.deleteNotificationOk": "Eliminar",
  "notifications.deleteNotificationTitle": "Eliminar notificación",
  "notifications.deleteSeniorityEntitlementAutomationConfirm": "¿Estás seguro de que quieres eliminar esta automatización de Derecho por antigüedad? No puedes deshacer esta acción. Sin embargo, eliminar la automatización no afectará los días de {leaveTypeName} ya otorgados.",
  "notifications.deleteWeeklyRequestSubmitted": "Eliminando notificaciones semanales",
  "notifications.emptyViewButton": "Crear una notificación diaria o semanal",
  "notifications.emptyViewMessage": "mediante el envío de notificaciones automatizadas sobre próximos permisos",
  "notifications.emptyViewTitle": "Aumenta la visibilidad y mantén a tu equipo informado",
  "notifications.emptyViewVideoLink": "Para más información, visita nuestro <link>Centro de Ayuda</link> o mira el video a continuación:",
  "notifications.error": "Si este problema persiste, por favor contacta al soporte y proporciona el siguiente código de error: {correlationId}.",
  "notifications.frequency": "Frecuencia",
  "notifications.handleSubmitErrorTitle": "Error de Notificación",
  "notifications.microsoftTokenMissing": "No podemos configurar las notificaciones porque te falta el token de Microsoft. Por favor, cierra sesión e inicia sesión nuevamente para recibir el token de Microsoft, y luego intenta de nuevo.",
  "notifications.notificationInfo": "Si deseas configurar Notificaciones para un departamento específico, por favor ve a la página de Departamentos y edita el departamento para el que quieres configurar las Notificaciones.",
  "notifications.notificationSendNow": "¡Tu notificación está en camino! 🚀",
  "notifications.notificationSendNowFailed": "Entrega fallida",
  "notifications.notificationSendNowFailedInfo": "¡Oh no! 😕 Algo salió mal. ",
  "notifications.notificationTitle": "Notificaciones",
  "notifications.passwordChangedDescription": "Has cambiado tu contraseña con éxito, por favor inicia sesión",
  "notifications.passwordChangedTitle": "Contraseña cambiada",
  "notifications.sendNotificationNowCofirmModalDesc": "La notificación <strong>{name}</strong> no está activa. Por favor, confirma si quieres enviarla ahora de todos modos.",
  "notifications.sendNotificationNowCofirmModalTitle": "Enviar notificación",
  "notifications.sendNotificationNowOk": "Enviar",
  "notifications.table.actions": "Acciones",
  "notifications.table.active": "Activo",
  "notifications.table.DAILY": "Diario",
  "notifications.table.frequency": "Frecuencia",
  "notifications.table.name": "Nombre",
  "notifications.table.owner": "Propietario",
  "notifications.table.scheduledTime": "Hora programada",
  "notifications.table.sendNotificationNow": "Enviar ahora",
  "notifications.table.WEEKLY": "Semanal",
  "notifications.title": "Notificaciones",
  "notifications.tooltipInfo": "Todas tus notificaciones se reúnen en esta página para una gestión más fácil. Para obtener más información, consulta nuestro <helpDesk>artículo de Ayuda</helpDesk>.",
  "notifications.update": "Actualizar notificación",
  "notifications.updateAutomationConfirm": "Desactivar una automatización no cancelará la suscripción. Si quieres desactivar la suscripción, necesitas acceder a la lista de automatizaciones haciendo clic en \"Crear automatización\" y luego hacer clic en el botón \"Cancelar suscripción\"",
  "notifications.updateAutomationTitle": "Actualizar automatización",
  "notifications.updateNotification": "Actualizar",
  "notifications.weekStartsOn": "La semana comienza el",
  "notificationsForm.advancedFilter.tooltipInfo": "Si no se seleccionan filtros, la notificación incluirá a todos los usuarios.",
  "notificationsForm.createInProgress": "Creando la notificación {notificationName}",
  "notificationsForm.currentWeek": "Semana actual",
  "notificationsForm.DAILY": "Diario",
  "notificationsForm.deleteFailed": "Eliminación fallida",
  "notificationsForm.deleteInProgress": "Eliminando la notificación {notificationName}",
  "notificationsForm.frequency": "Frecuencia",
  "notificationsForm.groupBy": "Agrupar ausencias por",
  "notificationsForm.groupBy.tooltip": "Las ausencias se pueden mostrar según tus preferencias. Pueden ordenarse por la ubicación de los usuarios, departamento o alfabéticamente (Ninguno).",
  "notificationsForm.locale": "Idioma",
  "notificationsForm.LOCATION": "Ubicación",
  "notificationsForm.name": "Nombre de la notificación",
  "notificationsForm.newNotificationTitle": "Nueva notificación",
  "notificationsForm.nextWeek": "Próxima semana",
  "notificationsForm.NONE": "Ninguno",
  "notificationsForm.periodCovered": "Período de tiempo cubierto",
  "notificationsForm.saveFailed": "Guardado fallido",
  "notificationsForm.selectChannel": "Seleccionar canal",
  "notificationsForm.sendEmpty": "Si no hay próximas ausencias",
  "notificationsForm.sendEmptyNo": "No, no enviar la notificación",
  "notificationsForm.sendEmptyYes": "Sí, enviar la notificación",
  "notificationsForm.sendOnDays": "Enviar en",
  "notificationsForm.sendOnDaysInfo": "La notificación se enviará en el/los día(s) seleccionado(s).",
  "notificationsForm.submit": "Guardar notificación",
  "notificationsForm.TEAM": "Departamento",
  "notificationsForm.time": "Hora",
  "notificationsForm.timezone": "Zona horaria",
  "notificationsForm.update": "Actualizar Notificación",
  "notificationsForm.updateInProgress": "Actualizando la notificación {notificationName}",
  "notificationsForm.updateNotificationTitle": "Actualizar Notificación",
  "notificationsForm.WEEKLY": "Semanal",
  "notificationsForm.weekStartsOn": "La semana comienza el",
  "overlapInfo.moreUsersAreOnLeaves": "ℹ️ Parece que {overlapperNames} estarán de permiso durante el mismo período.",
  "overlapInfo.youCanSafelyAcceptTheRequest": "Puede aceptar la solicitud con seguridad, nadie más de {teamName} está de permiso durante este período.",
  "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "Nadie más de {teamName} está de permiso durante este período.",
  "page404.message": "La página que estás buscando se está tomando un merecido descanso.",
  "page404.title": "Página de vacaciones.",
  "quickTour.admin.buttonTitle": "Tour rápido",
  "quickTour.buttons.back": "Atrás",
  "quickTour.buttons.close": "Cerrar",
  "quickTour.buttons.last": "Cerrar",
  "quickTour.buttons.next": "Siguiente",
  "quickTour.buttons.skip": "Saltar",
  "registration.flowB.progress": "Paso {current} de {total}",
  "registration.flowB.step1.list1": "<b>Prueba gratuita de 7 días</b>, todas las funciones incluidas",
  "registration.flowB.step1.list2": "No se requiere tarjeta de crédito durante la prueba",
  "registration.flowB.step1.list3": "Integración con tu herramienta de colaboración",
  "registration.flowB.step1.submitButton": "Comenzar",
  "registration.flowB.step1.subtitle": "¿Cuál es el correo electrónico de tu empresa?",
  "registration.flowB.step1.title": "Únete a los miles de equipos en todo el mundo que optimizan el seguimiento de PTO con Vacation Tracker.",
  "registration.flowB.step2.submitButton": "Crear Cuenta",
  "registration.flowB.step2.subtitle": "Bienvenido a Vacation Tracker",
  "registration.flowB.step3.continueWithEmailPlatform": "Continuar con el correo electrónico del trabajo",
  "registration.flowB.step3.continueWithPlatform": "Continuar con {platform}",
  "registration.flowB.step3.list1": "Gestionar Solicitudes de Permiso",
  "registration.flowB.step3.list10": "¡y más! ",
  "registration.flowB.step3.list2": "Enviar Notificaciones de Permiso",
  "registration.flowB.step3.list3": "Personalizar Tipos de Permiso",
  "registration.flowB.step3.list4": "Crear Ubicaciones y Departamentos",
  "registration.flowB.step3.list5": "Generar Informes",
  "registration.flowB.step3.list6": "Establecer Acumulaciones de Permiso",
  "registration.flowB.step3.list7": "Agrupar Miembros del Equipo por Etiquetas",
  "registration.flowB.step3.list8": "Programar Informes",
  "registration.flowB.step3.list9": "Solicitar Permiso por Horas",
  "registration.flowB.step3.notice": "No nos comunicaremos con tu equipo de ninguna manera hasta que estés listo.",
  "registration.flowB.step3.submitButton": "ÚLTIMO PASO",
  "registration.flowB.step3.subtitle": "¿Con qué herramienta te gustaría integrarte, {name}?",
  "registration.flowB.step3.title": "Prueba de 7 días del plan completo",
  "registration.flowB.step3.title2": "Recibirás una <b>prueba gratuita</b> de nuestro <b>Plan Completo</b>, que incluye:",
  "registration.flowB.testimonial1.profession": "Productor",
  "registration.flowB.testimonial1.title": "\"¡La facilidad con la que todos hicieron la transición al uso de Vacation Tracker sin hacer un millón de preguntas fue impresionante!\"",
  "registration.flowB.testimonial2.profession": "CEO",
  "registration.flowB.testimonial2.title": "\"Muy fácil de configurar, hace que sea super simple solicitar y aprobar días libres, y el equipo es muy receptivo y toma en serio los comentarios.\"",
  "registration.flowB.testimonial3.profession": "Director de Servicios Administrativos",
  "registration.flowB.testimonial3.title": "\"Ahora puedo concentrarme en mi personal y en muchas otras cosas - Vacation Tracker me devolvió al menos 10 horas a la semana, además de numerosas horas a nuestros gerentes y empleados porque todo es electrónico.\"",
  "requestLeave.handleSubmitErrorTitle": "Error al enviar la solicitud de permiso",
  "requestLeave.inProgress": "Solicitando permiso",
  "requestLeave.requestLeave": "Solicitar permiso",
  "requestLeave.title": "Permiso solicitado",
  "scheduledReportForm.addNewEmail": "Agregar destinatario",
  "scheduledReportForm.approved": "Aprobado",
  "scheduledReportForm.createAScheduledReport": "Nuevo informe",
  "scheduledReportForm.createInProgress": "Creando el informe programado {scheduledReportName}",
  "scheduledReportForm.deleteFailed": "Error al eliminar",
  "scheduledReportForm.deleteInProgress": "Eliminando el informe programado {scheduledReportName}",
  "scheduledReportForm.deleteReport": "Eliminar informe",
  "scheduledReportForm.deleteScheduledReport": "Eliminar informe programado",
  "scheduledReportForm.deleteScheduledReportConfirm": "Por favor, confirme que desea eliminar el informe programado {name}.",
  "scheduledReportForm.deleteScheduledReportTitle": "Eliminar informe programado {name}",
  "scheduledReportForm.denied": "Denegado",
  "scheduledReportForm.emails": "Destinatarios",
  "scheduledReportForm.lastMonth": "Mes pasado",
  "scheduledReportForm.leaveBalanceReport": "Informe de saldo de permisos",
  "scheduledReportForm.leaveHistoryReport": "Informe de solicitudes de permiso",
  "scheduledReportForm.leaveStatus": "Estado del permiso",
  "scheduledReportForm.locations": "Ubicación(es)",
  "scheduledReportForm.monthlyLeaveBalanceReport": "Informe mensual de saldo de permisos",
  "scheduledReportForm.period": "Período del informe",
  "scheduledReportForm.periodInfo": "Mes pasado",
  "scheduledReportForm.periodInfoTootltip": "Actualmente, el informe está limitado al mes anterior. Para más opciones, envíe un correo electrónico a hello@vacationtracker.io y nos aseguraremos de tomar nota de su solicitud para posibles mejoras futuras.",
  "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Por favor, ingrese un correo electrónico válido o elimine este campo.",
  "scheduledReportForm.pleaseInputValidEmail": "Por favor, ingrese un correo electrónico válido.",
  "scheduledReportForm.reportName": "Nombre",
  "scheduledReportForm.reportSummary": "Resumen del informe",
  "scheduledReportForm.reportSummaryInfo": "Período del informe: {from} - {to}\nTipo de informe: {reportType}\nEl próximo informe se enviará el {dateReport} a las {timeReport}",
  "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Período del informe: Próximos 12 meses. \nTipo de informe: {reportType}\nEl próximo informe se enviará el {dateReport} a las {timeReport}",
  "scheduledReportForm.reportType": "Tipo de informe",
  "scheduledReportForm.required": "Este campo es obligatorio.",
  "scheduledReportForm.saveFailed": "Error al guardar",
  "scheduledReportForm.saveReport": "Programar informe",
  "scheduledReportForm.sendDayOfMonth": "Fecha/Día de entrega",
  "scheduledReportForm.sendDayOfMonthInfo": "Configure sus preferencias de entrega del informe, especificando el día exacto en que se enviará el informe a los destinatarios asignados.",
  "scheduledReportForm.sendHourAndMinute": "Hora de entrega",
  "scheduledReportForm.sendHourAndMinuteInfo": "Configure sus preferencias de entrega del informe, especificando la hora exacta del día en que se enviará el informe a los destinatarios asignados.",
  "scheduledReportForm.sendReportFailed": "Error en la entrega",
  "scheduledReportForm.sendReportFailedInfo": "¡Oh no! 😕 Algo salió mal.",
  "scheduledReportForm.sendReportNow": "Enviar ahora",
  "scheduledReportForm.sendReportSuccessfully": "¡Su informe está en camino! 🚀",
  "scheduledReportForm.teams": "Departamento(s)",
  "scheduledReportForm.timezone": "Zona horaria",
  "scheduledReportForm.updateAScheduledReport": "Actualizar informe",
  "scheduledReportForm.updateInProgress": "Actualizando el informe programado {scheduledReportName}",
  "scheduledReportForm.updateReport": "Actualizar informe",
  "scheduledReports.add": "Nuevo informe",
  "scheduledReports.allLocations": "Todas las Ubicaciones",
  "scheduledReports.allTeams": "Todos los Departamentos",
  "scheduledReports.createScheduledReports": "Nuevo Informe",
  "scheduledReports.deleteFailed": "Eliminación fallida",
  "scheduledReports.deleteInProgress": "Eliminando el informe programado {scheduledReportName}",
  "scheduledReports.deleteReportConfirm": "¿Eliminar el informe {name}?",
  "scheduledReports.deleteReportOkText": "Eliminar",
  "scheduledReports.deleteReportTitle": "Confirmar Eliminación",
  "scheduledReports.deliveryDate": "Fecha de Entrega",
  "scheduledReports.edit": "Editar",
  "scheduledReports.lastMonth": "Mes pasado",
  "scheduledReports.leaveBalanceReport": "Informe de Saldo de Ausencias",
  "scheduledReports.leaveHistoryReport": "Informe de Solicitudes de Ausencia",
  "scheduledReports.locations": "Ubicación(es)",
  "scheduledReports.monthlyLeaveBalanceReport": "Informe Mensual de Saldo de Ausencias",
  "scheduledReports.name": "Nombre",
  "scheduledReports.period": "Período del Informe",
  "scheduledReports.receivers": "Destinatarios",
  "scheduledReports.reportSentSuccessfully": "¡Tu informe está en camino! 🚀",
  "scheduledReports.reportType": "Tipo de Informe",
  "scheduledReports.sendReportFailed": "Entrega Fallida",
  "scheduledReports.sendReportFailedInfo": "¡Oh no! 😕 Algo salió mal. ",
  "scheduledReports.sendReportNow": "Enviar Ahora",
  "scheduledReports.sendReportSuccessfully": "¡Tu informe está en camino! 🚀",
  "scheduledReports.teams": "Departamento(s)",
  "sidebar.addLeave": "Agregar Ausencia",
  "sidebar.addons": "Complementos",
  "sidebar.billing": "Facturación",
  "sidebar.calendar": "Calendario",
  "sidebar.changelog": "Registro de Cambios",
  "sidebar.dashboard": "Panel de Control",
  "sidebar.export": "Exportar",
  "sidebar.faq": "Mesa de Ayuda",
  "sidebar.general": "General",
  "sidebar.holidays": "Festivos",
  "sidebar.leaves": "Solicitudes de Ausencia",
  "sidebar.leaveTypes": "Tipos de Ausencia",
  "sidebar.location": "Ubicación",
  "sidebar.locations": "Ubicaciones",
  "sidebar.main": "Principal",
  "sidebar.myProfile": "Mi Perfil",
  "sidebar.notifications": "Notificaciones",
  "sidebar.onboarding": "Incorporación",
  "sidebar.reports": "Informes",
  "sidebar.requestLeave": "Solicitar Ausencia",
  "sidebar.scheduledReports": "Informes Programados",
  "sidebar.settings": "Configuración",
  "sidebar.teams": "Departamentos",
  "sidebar.user": "Usuario",
  "sidebar.users": "Usuarios",
  "signInAsUser.codeIsRequired": "Se requiere un código",
  "signInAsUser.email": "Ingrese su dirección de correo electrónico",
  "signInAsUser.emailIsRequired": "Se requiere una dirección de correo electrónico válida",
  "signInAsUser.enterTheCode": "Ingrese el código",
  "signInAsUser.userId": "Ingrese el ID de usuario",
  "signInAsUser.userIdIsRequired": "Se requiere un ID de usuario",
  "slack.notificationsLoginRequiredDescription1": "La gestión de notificaciones requiere permisos de Slack. Si hace clic en el botón OK, lo redirigiremos a la página de inicio de sesión de Slack. Dependiendo de la configuración de su navegador, es posible que ya haya iniciado sesión y sea redirigido automáticamente.",
  "slack.notificationsLoginRequiredDescription2": "Después de iniciar sesión en su cuenta de Slack, lo llevaremos de vuelta a esta página para que pueda gestionar las notificaciones. Al hacer clic en el botón Cancelar, volverá a la última página que visitó en nuestro panel de control.",
  "slack.notificationsLoginRequiredTitle": "Se requiere iniciar sesión en su cuenta de Slack",
  "slack.usersLoginRequiredDescription1": "La gestión de usuarios requiere permisos de Slack. Si hace clic en el botón OK, lo redirigiremos a la página de inicio de sesión de Slack. Dependiendo de la configuración de su navegador, es posible que ya haya iniciado sesión y sea redirigido automáticamente.",
  "slack.usersLoginRequiredDescription2": "Después de iniciar sesión en su cuenta de Slack, lo llevaremos de vuelta a esta página para que pueda gestionar los usuarios.",
  "slack.usersLoginRequiredTitle": "Se requiere iniciar sesión en su cuenta de Slack",
  "stripe.dynamicMessage": "{message}",
  "subscription.annual.info": "Activará el Plan {plan} {period} - ${price}",
  "subscription.hidePlansButton": "Ocultar planes",
  "subscription.logout": "Cerrar sesión",
  "subscription.monthly.info": "Activará el Plan {plan} {period} - ${usersPrice} ({noOfActiveUsers} usuarios x {dollarValue})",
  "subscription.monthly.infoUnderUserPLanLimit": "Activará el Plan {plan} {period} - ${usersPrice}",
  "subscription.showPlansButton": "Mostrar planes",
  "subscription.stripeCustomerPortalLink": "Haga clic aquí para visitar el portal del cliente",
  "subscription.subscribe": "Suscribirse",
  "subscription.title": "Suscripción",
  "subscription.updateInProgress": "Intentando realizar el pago",
  "subscriptions.switchPlanTitle": "Cambiar al plan <strong>{plan}</strong>",
  "surveyAboutUs.description": "¿Puede decirnos cómo se enteró de nosotros? Nos encantaría saber más sobre su camino hacia Vacation Tracker. Esto es opcional, pero nos ayudará mucho. ¡Gracias!",
  "surveyAboutUs.OptionQuestion1-1": "Búsqueda de Google",
  "surveyAboutUs.OptionQuestion1-1-1": "¿Qué buscó en Google (por ejemplo, \"seguimiento de vacaciones\")?",
  "surveyAboutUs.OptionQuestion1-2": "App Store (Slack - Directorio de Apps, MS Teams - App Source, etc...)",
  "surveyAboutUs.OptionQuestion1-3": "Redes sociales, plataforma de reseñas u otro sitio web",
  "surveyAboutUs.OptionQuestion1-3-1": "¿Qué plataforma o sitio?",
  "surveyAboutUs.OptionQuestion1-4": "Recomendación",
  "surveyAboutUs.OptionQuestion1-4-1": "¡Eso es genial! ¿Quién nos recomendó?",
  "surveyAboutUs.OptionQuestion1-4-1-1": "Un colega o amigo",
  "surveyAboutUs.OptionQuestion1-4-1-2": "Otra empresa lo sugirió",
  "surveyAboutUs.OptionQuestion1-4-1-3": "Lo he usado en el pasado",
  "surveyAboutUs.OptionQuestion1-5": "Otro",
  "surveyAboutUs.Question1": "¿Cómo se enteró de Vacation Tracker?",
  "surveyAboutUs.textarea": "Por favor, especifique",
  "surveyAboutUs.title": "¿Cómo se enteró de Vacation Tracker?",
  "team.approvers": "Aprobador(es)",
  "team.createInProgress": "Creando el departamento {teamName}",
  "team.editTeam": "Editar Departamento",
  "team.hasNoUsers": "El departamento no tiene usuarios.",
  "team.name": "Nombre",
  "team.team": "Departamento",
  "team.updateInProgress": "Actualizando el departamento {teamName}",
  "team.users": "Usuario(s)",
  "teamForm.createTeam": "Crear Departamento",
  "teamForm.createTeamError": "¡Oh no! 😕 Algo salió mal. ",
  "teamForm.editTeam": "Editar Departamento",
  "teamForm.name": "Nombre",
  "teamForm.pleaseEnterTeamName": "Ingrese un nombre de departamento",
  "teamForm.setAsSubdepartment": "Elegir departamento padre",
  "teamForm.teamLimitReached": "¡Ups! Ha alcanzado el límite de {limitNumber} departamentos de su plan actual. Para gestionar o actualizar su plan, visite la <billingPage>página de facturación</billingPage>.",
  "teamForm.updateTeam": "Actualizar Departamento",
  "teams.approvers": "Aprobador(es)",
  "teams.created": "Creado",
  "teams.createNewTeam": "Crear Nuevo Departamento",
  "teams.defaultTeam": "Todos los nuevos usuarios serán agregados automáticamente a este departamento.",
  "teams.deleteInProgress": "Eliminando el departamento {teamName}",
  "teams.deleteTeam": "Eliminar",
  "teams.deleteTeamConfirm": "¿Está seguro de que desea eliminar el departamento {name}?",
  "teams.deleteTeamTitle": "Eliminar Departamento",
  "teams.editTeam": "Editar Departamento",
  "teams.handleDeleteError": "Error al eliminar",
  "teams.handleDeleteErrorTitle": "Error al eliminar departamento",
  "teams.name": "Nombre",
  "teams.removeUserInfo": "No puede eliminar al usuario del departamento predeterminado. Si desea mover al usuario a otro departamento, puede editar otro departamento y agregar al usuario allí.",
  "teams.teamsLimitReached": "Actualmente está utilizando todas las {limitNumber} ranuras de departamento disponibles en el plan CORE. Para gestionar o actualizar su plan, visite la <billingPage>página de facturación</billingPage>.",
  "teams.users": "Usuario(s)",
  "teams.viewTeam": "Ver Departamento",
  "user.accrualsCalculator": "Calcular",
  "user.accrualsCalculatorInfo": "¿Cuántos días habré acumulado para una fecha específica?",
  "user.addLeave": "Agregar Permiso",
  "user.approve": "Aprobar",
  "user.approveInProgress": "Aprobando solicitud de permiso",
  "user.approveLeaveText": "Por favor, confirme que desea aprobar la solicitud de permiso.",
  "user.approveLeaveTitle": "Aprobar Solicitud de Permiso",
  "user.approveSuccessTitle": "Solicitud de permiso aprobada",
  "user.bulkChangeStatusInProgress": "Actualizando estados de los usuarios.",
  "user.bulkChangeStatusSuccessTitle": "Los estados de los usuarios han sido actualizados.",
  "user.cancelLeave": "Cancelar Permiso",
  "user.cancelLeave.buttonLink": "Cancelar",
  "user.cancelLeaveConfirmTitle": "Cancelar Permiso",
  "user.cancelLeaveRequestInProgress": "Cancelando la solicitud de permiso",
  "user.cancelLeaveRequestTitle": "La solicitud de permiso ha sido cancelada",
  "user.currentLeaves": "Permisos Actuales",
  "user.deleteLeave": "Eliminar Permiso",
  "user.deleteLeave.buttonLabel": "Eliminar",
  "user.deleteLeaveConfirmText": "Por favor, confirme que desea eliminar el permiso.",
  "user.deleteLeaveConfirmTitle": "Eliminar Permiso",
  "user.deleteLeaveInProgress": "Eliminando permiso",
  "user.deleteLeaveSuccessTitle": "El permiso ha sido eliminado",
  "user.deleteToil": "Eliminar TOIL",
  "user.deleteToilConfirmText": "Por favor, confirme que desea eliminar el TOIL.",
  "user.deny": "Denegar",
  "user.denyInProgress": "Denegando solicitud de permiso",
  "user.denyLeaveConfirm": "Por favor, confirme que desea denegar la solicitud de permiso.",
  "user.denyLeaveTitle": "Denegar Solicitud de Permiso",
  "user.denySuccessTitle": "Solicitud de permiso denegada",
  "user.denyWithReason": "Denegar con motivo",
  "user.dontHaveAnyHistory": "{name} aún no ha tomado ninguna licencia.",
  "user.editLeave": "Editar licencia",
  "user.editLeave.buttonLabel": "Editar",
  "user.editLeaveWord": "Editar",
  "user.editUser": "Editar",
  "user.editWorkWeek": "Editar",
  "user.employeeEndDate": "Fecha de finalización del empleado",
  "user.employeeId": "ID de empleado",
  "user.employeeSince": "Empleado desde",
  "user.general": "General",
  "user.history": "Historial",
  "user.historyLeaves": "Historial",
  "user.invitationDeletedTitle": "Invitación eliminada",
  "user.invitationSuccessTitle": "Invitaciones enviadas",
  "user.leaves": "Licencias",
  "user.leaveTypes": "Tipos de licencia",
  "user.location": "Ubicación",
  "user.location.moveInProgress": "Moviendo usuario a la ubicación {name}",
  "user.location.moveSuccessTitle": "Usuario movido a la ubicación {name}",
  "user.logs": "Registros",
  "user.noUpcomingLeaves": "No hay licencias programadas",
  "user.pendingLeaves": "Solicitudes de licencia pendientes",
  "user.probationPeriodDescription": "El usuario está en un período de prueba bajo la política <probationPeriodName></probationPeriodName> para <leaveTypeNames></leaveTypeNames>, que dura <lengthDays></lengthDays> día(s), hasta <date></date>.",
  "user.profile": "Perfil",
  "user.resend": "Reenviar solicitud",
  "user.resending": "Reenviando solicitud de licencia...",
  "user.resent": "Solicitud reenviada",
  "user.role": "Rol",
  "user.signUp": "Registrarse",
  "user.signup.intro": "{invitedByName} te ha invitado a unirte a su organización en Vacation Tracker. Por favor, regístrate a continuación para aceptar la invitación y acceder al panel de Vacation Tracker.",
  "user.signup.introHelpdesk": "Si tienes alguna pregunta o necesitas ayuda, contacta a nuestro soporte o visita <helpdesk>el Centro de ayuda</helpdesk>.",
  "user.status": "Estado",
  "user.status.updateInProgress": "Actualizando estado del usuario.",
  "user.status.updateSuccessTitle": "El estado del usuario ha sido actualizado.",
  "user.statusReason": "Agregar motivo",
  "user.team.moveInProgress": "Moviendo usuario al departamento {name}",
  "user.team.moveSuccessTitle": "Usuario movido al departamento {name}",
  "user.upcomingLeaves": "Licencias programadas",
  "user.updateInProgress": "Actualizando usuario",
  "user.updateSuccessTitle": "Usuario actualizado",
  "user.userWorkWeek": "Semana laboral del usuario",
  "user.workWeek.updateInProgress": "Actualizando semana laboral",
  "user.workWeek.updateSuccessTitle": "Semana laboral actualizada",
  "users.aboutToSync": "Al sincronizar todos los usuarios existentes, estás sincronizando los datos del usuario desde la plataforma de colaboración utilizada por tu equipo con su perfil de Vacation Tracker (ej. Nombre, Imagen de perfil, etc.)",
  "users.activateUsers": "Activar",
  "users.activeUser": "Activar",
  "users.activeUsers": "Activos",
  "users.assignLicenses": "Asignar licencias",
  "users.cancel": "Cancelar",
  "users.changeUserLocation": "Cambiar ubicación",
  "users.changeUserTeam": "Cambiar departamento",
  "users.deactivate": "Desactivar",
  "users.deactivateUser": "Desactivar",
  "users.deactivateUserConfirm": "¿Estás seguro de que quieres desactivar a {name}?",
  "users.deactivateUsers": "Desactivar",
  "users.deactivateUserTitle": "Desactivar",
  "users.deletedUsers": "Eliminados",
  "users.deleteInvite": "Eliminar invitación",
  "users.deleteInviteConfirm": "¿Estás seguro de que quieres eliminar la invitación enviada a {email}?",
  "users.deleteUser": "Eliminar usuario",
  "users.deleteUserUserConfirm": "¿Estás seguro de que quieres eliminar a {name}?",
  "users.employmentDate": "Fecha de empleo del usuario",
  "users.endDate": "Fecha de finalización",
  "users.import.description": "La importación puede llevar algún tiempo, si no ves nuevos usuarios en la lista, por favor actualiza esta página después de 10-15 segundos.",
  "users.importedSuccessfully": "Usuarios importados con éxito",
  "users.importMany.InProgress": "Importando usuarios",
  "users.importMany.successTitle": "Usuarios importados con éxito",
  "users.importNewUsers": "Agregar usuarios",
  "users.importOne.InProgress": "Importando un nuevo usuario",
  "users.importOne.successTitle": "Usuario importado con éxito",
  "users.importUsers": "Importar usuarios",
  "users.inactiveUsers": "Inactivos",
  "users.invitations": "Invitaciones",
  "users.inviteUsers": "Invitar usuarios",
  "users.location": "Ubicación",
  "users.name": "Nombre",
  "users.next": "Siguiente",
  "users.prev": "Anterior",
  "users.reinvite": "Volver a invitar",
  "users.reInviteConfirm": "¿Estás seguro de que quieres volver a invitar a {email}?",
  "users.role": "Rol",
  "users.search": "Buscar usuarios",
  "users.sendInvitationsInLanguage": "Enviar invitaciones en",
  "users.startDate": "Fecha de inicio",
  "users.syncExistingUsers": "Sincronizar usuarios existentes",
  "users.syncExistingUsers.InProgress": "Sincronizando usuarios existentes",
  "users.syncExistingUsers.successTitle": "Los usuarios existentes se han sincronizado con éxito.",
  "users.syncWithGoogleConsent": "Sincronizar con consentimiento de Google",
  "users.syncWithoutGoogleConsent": "Sincronizar sin consentimiento de Google",
  "users.team": "Departamento",
  "users.updateQuota.InProgress": "Actualizando cuota de usuario en progreso",
  "users.updateQuota.successTitle": "La cuota de usuario se ha actualizado con éxito",
  "users.updateRolloverQuota.successTitle": "La cuota inicial de traspaso se ha actualizado con éxito.",
  "users.viewUser": "Ver usuario",
  "wallchart.range.next4Weeks": "Próximas 4 semanas",
  "wallchart.range.next5Weeks": "Próximas 5 semanas",
  "wallchart.range.nextMonth": "Próximo mes",
  "wallchart.range.previousMonth": "Mes anterior",
  "wallchart.range.thisMonth": "Este mes"
}

/* eslint-disable max-len */
import React, { useState, Fragment, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { App, Modal, Form, Select, Steps, Button, Input, Progress, Switch, Tooltip, StepsProps } from 'antd'
import { InfoCircleOutlined, CloseCircleOutlined, LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import InputColor from 'react-input-color'
import { useIntl } from 'react-intl'
import 'emoji-mart/css/emoji-mart.css'
import { Picker, Emoji } from 'emoji-mart'
import * as logger from '../../services/logger'

import Api from '@vacationtracker/shared/services/api'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { notificationStore } from '../../context/notificationsContext/store'
import { getCalendarStatusSelectOptions } from '@vacationtracker/shared/functions/get-calendar-status-options'
import { convertQuota } from '@vacationtracker/shared/functions/get-days-or-hours'

import IntlMessages from '../../util/IntlMessages'
import LeavePolicyForm from '../leave-policy-form'

import { ILeavePolicyForm, ILeaveTypeCreate } from '../../types/leave-types'
import { IGetLocationsShort } from '../../types/locations'
import { AccrualType, ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import { LeaveTypeCalendarStatusesEnum } from '@vacationtracker/shared/types/calendar'
import { ISimilarLeaveType } from '@vacationtracker/shared/types/leave-type'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'

interface ILeaveTypeAndAssignPolicyModal {
  setModalVisibility: (e) => void
  locations: IGetLocationsShort[]
  visibleModal: boolean
  handleCancel: (e) => void
  setAssigninPolicyCorrelationId: (v) => void
  setShouldReloadOnActionNotificationsChange: (v) => void
  leaveType?: {
    id: string
    name: string
  }
  hourlyLeaveAccounting: boolean
  checkForLeaveTypeSimilarity?: (v: string) => void
  resetSimilarLeaveType?: () => void
  similarLeaveType?: ISimilarLeaveType | null
  isCheckForLeaveTypeSimilarityLoading?: boolean
}

interface IDefaultFormValues {
  leaveTypeId: string | null
  accrualType: AccrualType
  shortestLeaveInterval: ShortestLeaveIntervalEnum
  quota: number
  maxRollover: number
  hasUnlimitedDays: boolean
  hideLeaveType: boolean
  isApprovalRequired:boolean
  isReasonRequired: boolean
  negativeBallanceAllowed: boolean
  rolloverType: string
  color: string
  name: string
  locations: string[]
  allowLeaveInPast: boolean
  calendarStatus?: LeaveTypeCalendarStatusesEnum
}

enum StepsEnum {
  CREATE_LEAVE_TYPE = 'CREATE_LEAVE_TYPE',
  SIMILAR_LEAVE_TYPE = 'SIMILAR_LEAVE_TYPE',
  SET_LEAVE_POLICY = 'SET_LEAVE_POLICY',
}

const CreateLeaveTypeForm = ({
  setModalVisibility,
  locations,
  visibleModal,
  handleCancel,
  setAssigninPolicyCorrelationId,
  setShouldReloadOnActionNotificationsChange,
  leaveType,
  hourlyLeaveAccounting,
  checkForLeaveTypeSimilarity,
  similarLeaveType,
  isCheckForLeaveTypeSimilarityLoading,
  resetSimilarLeaveType,
}: ILeaveTypeAndAssignPolicyModal): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { authUser: { platform } } = useAppSelector(selectAuthUserSlice)
  const { actionNotifications, setActionNotifications, eventsNotifications, setEventsNotifications} = useContext(notificationStore)
  const { notification } = App.useApp()

  const leaveTypeId = leaveType?.id

  const [form] = Form.useForm()
  const [currentStep, setCurrentStep] = useState(leaveTypeId ? 1 : 0)
  const [steps, setSteps] = useState(leaveTypeId ? StepsEnum.SET_LEAVE_POLICY : StepsEnum.CREATE_LEAVE_TYPE)
  const [color, setColor] = useState<string>('#ffe58f')
  const [loading, setLoading] = useState<boolean>(false)
  const [isDirtyFieldName, setIsDirtyFieldName] = useState<boolean>(false)
  const [isDirtyFieldStatus, setIsDirtyFieldStatus] = useState<boolean>(false)
  const [selectedLocations, setSelectedLocations] = useState(leaveTypeId ? [] : locations.map(l => l.id))
  const [leaveTypeName, setLeaveTypeName] = useState(leaveType?.name || '')
  const [showSlackStatus, setShowSlackStatus] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(':palm_tree:')
  const [slackStatusMessage, setSlackStatusMessage] = useState('')
  const [showEmojicon, setShowEmojicon] = useState(false)
  const [calendarStatus, setCalendarStatus] = useState(LeaveTypeCalendarStatusesEnum.busy)
  const defaultValue: IDefaultFormValues = {
    leaveTypeId: null,
    accrualType: 'NONE',
    shortestLeaveInterval: ShortestLeaveIntervalEnum.fullDay,
    hasUnlimitedDays: false,
    hideLeaveType: false,
    isApprovalRequired: true,
    isReasonRequired: false,
    quota: 0,
    maxRollover: 0,
    negativeBallanceAllowed: false,
    rolloverType: 'no',
    color: '#ffe58f',
    name: '',
    locations: leaveTypeId ? [] : selectedLocations.map(locationId => (locations.find(l => l.id === locationId) as IGetLocationsShort).name as string),
    allowLeaveInPast: false,
    calendarStatus: LeaveTypeCalendarStatusesEnum.busy,
  }

  useEffect(() => {
    if (!isCheckForLeaveTypeSimilarityLoading) {
      if (similarLeaveType) {
        setSteps(StepsEnum.SIMILAR_LEAVE_TYPE)
        setCurrentStep(1)
      } else if (leaveTypeId || leaveTypeName) {
        setSteps(StepsEnum.SET_LEAVE_POLICY)
        setCurrentStep(1)
      }
    }
  }, [similarLeaveType, isCheckForLeaveTypeSimilarityLoading])

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const onFinish = async () => {
    try {
      const values = await form.validateFields()
      if (values.rolloverType === 'all') {
        values.maxRollover = 366
      } else if (values.rolloverType === 'no') {
        values.maxRollover = 0
      } else {
        values.maxRollover = values.maxRollover || 0
      }
      delete values.rolloverType

      const formValuesData = values as ILeavePolicyForm
      submitForm(formValuesData, leaveTypeId)
      resetSimilarLeaveType && resetSimilarLeaveType()
    } catch (error) {
      logger.error(error)
    }
  }

  const showErrorNotification = (error) => {
    const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: formatMessage({ id: 'error.generic' }),
      description: errorDescription,
      duration: 0,
    })
    setLoading(false)
  }

  const submitForm = async (data: ILeavePolicyForm, leaveTypeId: string | undefined) => {
    setLoading(true)
    if (leaveTypeId) {
      await assignLeavePolicies({
        ...data,
        leaveTypeId,
        correlationId: uuidv4(),
      })
    } else {
      await onSaveLeaveType(data)
    }

    setModalVisibility(false)
    setLoading(false)
  }

  const onSaveLeaveType = async (data) => {
    try {
      const body: ILeaveTypeCreate = {
        eventType: 'LEAVE_TYPE_CREATED',
        eventGroup: 'LEAVE_TYPE',
        name: leaveTypeName,
        color: color.trim(),
        isActive: true,
        locationIds: selectedLocations,
        calendarStatus: calendarStatus,
        leavePolicy: {
          negativeBallanceAllowed: data.negativeBallanceAllowed,
          daysPerYear: data.daysPerYear || 0,
          maxRolloverDays: data.maxRolloverDays || 0,
          hasUnlimitedDays: data.hasUnlimitedDays,
          shortestLeaveInterval: data.shortestLeaveInterval,
          hideLeaveType: data.hideLeaveType,
          isApprovalRequired: data.isApprovalRequired,
          isReasonRequired: data.isReasonRequired,
          allowLeaveInPast: data.allowLeaveInPast,
          quotaSetIn: hourlyLeaveAccounting ? 'hours' : 'days',
          quota: data.quota,
          maxRollover: data.maxRollover,
        },
        version: 2,
      }
      if (platform === 'slack') {
        body.awayStatus = showSlackStatus
        body.awayStatusIcon = chosenEmoji
        body.awayStatusMessage = slackStatusMessage
      }
      const response = await Api.post('/core/event', body)
      resetSimilarLeaveType && resetSimilarLeaveType()
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'leaveTypes.createInProgress' }, { leaveTypeName: leaveTypeName }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })

      setActionNotifications([ ...actionNotifications, response.correlationId ])

      setShouldReloadOnActionNotificationsChange(false)

      setAssigninPolicyCorrelationId(`${response.correlationId}#LEAVE_POLICY_ENABLED`)
      openNoitificationForAssignLeavePolicies(selectedLocations, `${response.correlationId}#LEAVE_POLICY_ENABLED`)
      return response
    } catch (error) {
      logger.warning('error on creating leave type: ', error)
      showErrorNotification(error)
      setLoading(false)
    }
  }

  const assignLeavePolicies = async (data) => {
    setAssigninPolicyCorrelationId(data.correlationId)
    const quotaSetIn = hourlyLeaveAccounting ? 'hours' : 'days'
    const locationIds: string[] = []
    for (const locationId of selectedLocations) {
      const locationHours = getUserWorkingHoursPerDay(locations.find(l => l.id === locationId)?.workHours)
      const {days: daysPerYear, hours: hoursPerYear} = convertQuota(data.quota as number, quotaSetIn, locationHours)
      const {days: maxRolloverDays, hours: maxRolloverHours} = convertQuota(data.maxRollover as number, quotaSetIn, locationHours)
      try {
        await Api.post('/core/event', {
          eventType: 'LEAVE_POLICY_ENABLED',
          eventGroup: 'LEAVE_POLICY',
          leaveTypeId,
          negativeBallanceAllowed: data.negativeBallanceAllowed,
          locationId,
          daysPerYear,
          hoursPerYear,
          quotaSetIn,
          maxRolloverDays,
          maxRolloverHours,
          hasUnlimitedDays: data.hasUnlimitedDays,
          shortestLeaveInterval: data.shortestLeaveInterval,
          hideLeaveType: data.hideLeaveType,
          accrualType: 'NONE',
          isApprovalRequired: data.isApprovalRequired,
          isReasonRequired: data.isReasonRequired,
          cancelUserSettings: false,
          correlationId: data.correlationId,
          allowLeaveInPast: data.allowLeaveInPast,
          broughtForwardExpirationSettings: {
            enabled: false,
          },
          allowAdvanceAccrualUsage: data.allowAdvanceAccrualUsage,
          version: 3,
          toil: data.toil,
        })
        locationIds.push(locationId)
      } catch (error) {
        logger.error('error on assign leave policy: ', error)
      }
    }

    openNoitificationForAssignLeavePolicies(locationIds, data.correlationId)
  }

  const openNoitificationForAssignLeavePolicies = (locationIds, correlationId) => {
    if (locationIds.length > 0) {
      notification.open({
        key: correlationId,
        message: locationIds.length === 1 ?
          formatMessage({ id: 'components.createLeaveType.assignToLocationOneInProgress' }) :
          formatMessage({ id: 'components.createLeaveType.assignToLocationManyInProgress' }),
        description: <Progress percent={0} size="small" />,
        duration: 0,
      })

      setEventsNotifications({
        ...eventsNotifications,
        [correlationId]: {
          eventType: 'LEAVE_POLICY_ENABLED',
          payload: {
            locationIds,
            totalLocationIds: locationIds.length,
          },
        },
      })
    }
  }

  const handleLocationsSelectChange = (_, values) => {
    // keys are location ids
    setSelectedLocations(values.map(l => l.key))
  }

  const onChangeSetSlackStatus = () => {
    setShowSlackStatus(!showSlackStatus)
  }

  const onEmojiSelect = (e) => {
    setShowEmojicon(false)
    setChosenEmoji(e.colons)
  }

  const handleSetCalendarStatus = (v) => {
    setCalendarStatus(v)
  }

  return (
    <Modal
      title={leaveTypeId ? <IntlMessages id="app.assignLeavePolicy" /> : <IntlMessages id="components.createLeaveTypeForm.title" />}
      open={visibleModal}
      style={{ top: 20 }}
      width={680}
      onCancel={handleCancel}
      closeIcon={<CloseCircleOutlined />}
      footer={[
        <Fragment key="menu">
          {steps === StepsEnum.CREATE_LEAVE_TYPE && <>
            <Button key="cancel" disabled={loading} onClick={handleCancel}><IntlMessages id="app.cancel" /></Button>
            <Button
              disabled={!isDirtyFieldName || (showSlackStatus && !isDirtyFieldStatus)}
              key="find"
              loading={loading || isCheckForLeaveTypeSimilarityLoading}
              type="primary"
              onClick={() => checkForLeaveTypeSimilarity && checkForLeaveTypeSimilarity(leaveTypeName)}
              data-qa='go-to-leave-policy-step'
            >
              <IntlMessages id="app.next" />
            </Button>
          </>}
          {steps === StepsEnum.SET_LEAVE_POLICY && <>
            {leaveTypeId ?
              <Button key="cancel" disabled={loading} onClick={handleCancel}><IntlMessages id="app.cancel" /></Button> :
              <Button key="backToSelectLeaveType" type="default" disabled={loading} onClick={() => {
                if (similarLeaveType) {
                  setSteps(StepsEnum.SIMILAR_LEAVE_TYPE)
                  setCurrentStep(1)
                } else {
                  setCurrentStep(0)
                  setSteps(StepsEnum.CREATE_LEAVE_TYPE)
                }
              }}>
                <IntlMessages id="app.back" />
              </Button>
            }
            <Button
              key="assignLeavePolicy"
              type="primary"
              onClick={onFinish}
              disabled={(Boolean(leaveTypeId) && selectedLocations.length === 0) || loading}
              loading={loading}
              data-qa='set-leave-policy'
            >
              {leaveTypeId ?
                <IntlMessages id="components.createLeaveTypeForm.justLastStep.assign" /> :
                <IntlMessages id="components.createLeaveTypeForm.createAndAssign" />
              }
            </Button>
          </>}
          {steps === StepsEnum.SIMILAR_LEAVE_TYPE && <>
            <Button key="cancel" disabled={loading} onClick={handleCancel}><IntlMessages id="app.cancel" /></Button>
            <Button key="cancel" disabled={loading} type='primary' onClick={() => {
              setSteps(StepsEnum.SET_LEAVE_POLICY)
              setCurrentStep(2)
            }}><IntlMessages id="components.createLeaveTypeForm.continueCreatingLeaveType" /></Button>
          </>}
        </Fragment>,
      ]}
    >
      <Fragment>
        {!leaveTypeId &&
          <Steps
            style={{ marginBottom: 20 }}
            size="small"
            current={currentStep}
            items={[
              {
                key: 'selectLeaveType',
                title: <IntlMessages id="components.createLeaveTypeForm.createLeaveType" />,
              },
              similarLeaveType ? {
                key: 'similarLeaveType',
                title: <IntlMessages id="components.createLeaveTypeForm.similarLeaveTypeTitle" />,
              } : null,
              {
                key: 'setLeavePolicies',
                title: <IntlMessages id="components.createLeaveTypeForm.assignToLocations" />,
              },
            ].filter(item => item !== null) as StepsProps['items']}
          />
        }

        <Form
          {...formLayout}
          form={form}
          labelWrap
          name="LeavePolicyForm"
          initialValues={defaultValue}
        >
          {steps === StepsEnum.CREATE_LEAVE_TYPE && <>
            <Form.Item label={<IntlMessages id="app.name" />} >
              <Input
                value={leaveTypeName}
                onChange={(e) => {
                  if (!e.target.value) {
                    setIsDirtyFieldName(false)
                    setLeaveTypeName('')
                  } else {
                    setIsDirtyFieldName(true)
                    setLeaveTypeName(e.target.value)
                  }
                }}
                data-qa='leave-type-name'
              />
            </Form.Item>

            <Form.Item label={<IntlMessages id="app.color" />} >
              <InputColor
                initialValue={color}
                onChange={(color) => setColor(color.hex)}
                placement="right"
                data-qa="leave-type-color-picker"
              />
            </Form.Item>

            <Form.Item
              name='calendarStatus'
              label={(<>
                <IntlMessages id="components.createLeaveTypeForm.calendarStatusLabel" />
                <Tooltip className="info-tooltip" title={<IntlMessages id="components.createLeaveTypeForm.calendarStatusLabelTooltipInfo" />} ><InfoCircleOutlined /></Tooltip>
              </>)}
            >
              <Select
                defaultValue={LeaveTypeCalendarStatusesEnum.busy}
                options={getCalendarStatusSelectOptions(formatMessage)}
                style={{ width: 100 }}
                onSelect={handleSetCalendarStatus}
              />
            </Form.Item>

            {platform === 'slack' &&
              <>
                <Form.Item
                  name="setSlackStatus"
                  label={<IntlMessages id="components.leaveTypeForm.setSlackStatus" />}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    onChange={onChangeSetSlackStatus}
                    unCheckedChildren={<CloseOutlined />}
                    checked={showSlackStatus}
                  />
                </Form.Item>

                {showSlackStatus &&
                  <>
                    <Form.Item
                      name="slackStatus"
                      label={<IntlMessages id="components.leaveTypeForm.slackStatus" />}
                      style={{ position: 'relative' }}
                    >
                      <Button onClick={() => setShowEmojicon(!showEmojicon)} className="ant-input ant-input-emoji">
                        <Emoji emoji={chosenEmoji} size={16} />
                      </Button>
                      <Input
                        style={{ display: 'inline-block', width: 'calc(100% - 45px)' }}
                        placeholder={`${formatMessage({ id: 'components.leaveTypeForm.setSlackStatusPlaceholder' })}`}
                        value={slackStatusMessage}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setSlackStatusMessage('')
                            setIsDirtyFieldStatus(false)
                          } else {
                            setIsDirtyFieldStatus(true)
                            setSlackStatusMessage(e.target.value)
                          }
                        }}
                      />
                      {showEmojicon &&
                        <span className="emoji-picker">
                          <Picker
                            onSelect={onEmojiSelect}
                            theme="auto"
                            color="#7f00ff"
                            emoji=""
                            title=""
                            sheetSize={32}
                            emojiSize={22}
                          />
                        </span>
                      }
                    </Form.Item>
                  </>
                }
              </>
            }
          </>}
          {steps === StepsEnum.SIMILAR_LEAVE_TYPE && <>
            {similarLeaveType &&
              <p>
                <IntlMessages
                  id="components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType"
                  values={{
                    leaveTypeName: similarLeaveType.name,
                    strong: (...chunks) => (<strong>{chunks}</strong>),
                  }}
                />&nbsp;
                <IntlMessages
                  id="components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip"
                />&nbsp;
                <IntlMessages
                  id="components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure"
                  values={{
                    newLeaveTypeName: leaveTypeName,
                    strong: (...chunks) => (<strong>{chunks}</strong>),
                  }}
                />
              </p>}
            {locations.length > 0 && similarLeaveType && <>
              <p>
                <IntlMessages
                  id="components.createLeaveTypeForm.similarLeaveTypeInfoSetRules"
                  values={{
                    leaveTypeName: similarLeaveType.name,
                    strong: (...chunks) => (<strong>{chunks}</strong>),
                  }}
                />
              </p>
              {locations.map((location) => <p key={location.id}>
                <ul>
                  <li key={location.id}>
                    <Link
                      to={`/app/settings/locations/${location.id}/leave-policies`}
                      target="_blank"
                    >
                      <Button>{location.name}</Button>
                    </Link>
                  </li>
                </ul>
              </p>)}
            </>}
            {similarLeaveType &&
            <p>
              <IntlMessages
                id="components.createLeaveTypeForm.similarLeaveTypeInfoRecommend"
                values={{
                  leaveTypeName: similarLeaveType.name,
                  strong: (...chunks) => (<strong>{chunks}</strong>),
                }}
              />
            </p>}
          </>}

          {steps === StepsEnum.SET_LEAVE_POLICY && <>
            <p>
              <IntlMessages
                id="components.createLeaveTypeForm.locationsInfo"
                values={{
                  leaveTypeName: leaveTypeName,
                  strong: (...chunks) => (<strong>{chunks}</strong>),
                }}
              />
            </p>

            <Form.Item
              label={<IntlMessages id="app.locations" />}
              name="locations"
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}>
              <Select
                placeholder={<IntlMessages id="locations.selectLocation" />}
                mode="multiple"
                maxTagCount="responsive"
                onChange={handleLocationsSelectChange}
              >
                {locations.map((location) => <Select.Option key={location.id} value={location.name}>{location.name}</Select.Option>)}
              </Select>
            </Form.Item>

            <LeavePolicyForm
              form={form}
              showLeavePolicyTitle={true}
              isCreateLeaveTypeForm
            />

          </>}
        </Form>
      </Fragment>
    </Modal>
  )
}
export default CreateLeaveTypeForm

import React from 'react'
import { Tooltip } from 'antd'
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../utils/IntlMessages'
import { getDaysOrHours } from '../../functions/get-days-or-hours'
import { roundTo2Decimals } from '../../functions/calculation-shared'
import dayjs from 'dayjs'
import FormattedDate from '../formatted-date'
import { getUserWorkingHoursPerDay } from '../../functions/work-week'
import { WorkingHours } from '../../types/work-week'
import capitalizeString from '../../functions/capitalize-string'

const InitialBroughtForwardTooltipContent = ({value, units}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.initialBroughtForwardInfo" /></div>
    <br />
    <div><IntlMessages id="components.userLeaveQuotas.initialBroughtForward" />
      :<strong> {value} <IntlMessages id={`app.${units}`}/></strong></div>
  </div>
)

const BroughtForwardTooltipContent = ({value, units, leaveTypeName, locationName, broughtForwardLimit}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.broughtForwardInfo" /></div>
    <br />
    <div>
      <IntlMessages
        id={
          [366, 2928].includes(broughtForwardLimit)
            ? 'components.userLeaveQuotas.broughtForwardInfoDetailsAll'
            : `components.userLeaveQuotas.broughtForwardInfoDetails${capitalizeString(units)}`
        }
        values={{
          leaveTypeName,
          locationName,
          ...(![366, 2928].includes(broughtForwardLimit) && { broughtForwardLimit: roundTo2Decimals(broughtForwardLimit) }),
        }}
      />
    </div>
    <br />
    <div>
      <IntlMessages id="components.userLeaveQuotas.broughtForwardValue" />:
      <strong>&nbsp;{roundTo2Decimals(value)}
        &nbsp;
        <IntlMessages id={`app.${units}`}/>
      </strong>
    </div>
  </div>
)

const QuotaTooltipContent = ({value, quota, hourlyLeaveAccounting, workingHours}) => (
  
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.quotaInfo" /></div>
    <br />
    <div><IntlMessages id="components.userLeaveQuotas.quotaInfoSummary" /></div>
    {quota.hasUnlimitedDays ? <div><IntlMessages id="components.userLeaveQuotas.noLimit"/></div> : <div><div>
      <strong>{value} <IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/></strong>
    </div>
    <div><IntlMessages id="app.ofThat" />:</div>
    <br />
    <ul style={{ textAlign: 'left' }}>
      <li><IntlMessages id="components.userLeaveQuotas.baseQuota"/>
      : {roundTo2Decimals(getDaysOrHours(quota.baseQuota, hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)))}</li>
      <li><IntlMessages id="app.timeOffInLieu"/>
      : {roundTo2Decimals(getDaysOrHours(quota.toilDays - quota.toilDaysLapsed, hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)))}
        {quota.toilDaysLapsed > 0 && <>&nbsp;
        ({roundTo2Decimals(getDaysOrHours(quota.toilDaysLapsed, hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)))}&nbsp;
        <IntlMessages id="components.toil.lapsed"/>)</>}
      </li>
      <li>
        <IntlMessages id="automations.SENIORITY_ENTITLEMENT"/>: {getDaysOrHours(
          roundTo2Decimals(quota.seniorityEntitlementDaysForYear), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)
        )}
      </li>
      {quota.entitlementByRoleDaysForYear > 0 &&
        <li>
          <IntlMessages id="automations.ENTITLEMENT_BY_ROLE"/>: {getDaysOrHours(
            roundTo2Decimals(quota.entitlementByRoleDaysForYear), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)
          )}
        </li>
      }
    </ul></div>}
  </div>
)

const ExpirationTooltipContent = ({expirationDate, locale}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.expirationTooltip" /></div>
    <br />
    <div>
      {dayjs(expirationDate as string).isBefore(new Date()) ? <IntlMessages id="app.expired" /> : <IntlMessages id="app.expires" />}:
      <strong> <FormattedDate value={expirationDate?.slice(0,10)} locale={locale} format="MMMM Do YYYY."/></strong>
    </div>
  </div>
)

const UsedDaysTooltipContent = ({used, scheduled, units, leaveTypeName }) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.usedTooltip" values={{ leaveTypeName }} />
      <br />
      <strong>{units === 'hours' ? roundTo2Decimals(used.raw.hours) : roundTo2Decimals(used.raw.days)} <IntlMessages id={`app.${units}`}/></strong>
    </div>
    <div><IntlMessages id="app.ofThat" />:</div>
    <br />
    <ul style={{ textAlign: 'left' }}>
      <li>
        <IntlMessages id="components.userLeaveQuotas.usedAlready"/>
        : {units === 'hours' ? (roundTo2Decimals(used.raw.hours - scheduled.raw.hours)) : (roundTo2Decimals(used.raw.days - scheduled.raw.days))}
        &nbsp;<IntlMessages id={`app.${units}`}/>
      </li>
      {scheduled.raw.days > 0 && 
        <li>
          <IntlMessages
            id="components.userLeaveQuotas.scheduled"/>: {units === 'hours' ? roundTo2Decimals(scheduled.raw.hours) : roundTo2Decimals(scheduled.raw.days)}
          &nbsp;<IntlMessages id={`app.${units}`}/>
        </li>
      }
    </ul>
  </div>
)

const RemainingDaysTooltipContent = ({value, units, leaveTypeName, broughtForwardLimit, unlimited}) => (
  <div style={{textAlign: 'center'}}>
    {unlimited ? <div><IntlMessages id="components.userLeaveQuotas.noLimit"/></div> : 
      <>
        <div><IntlMessages id="components.userLeaveQuotas.remainingTooltip" values={{ leaveTypeName }} /></div>
        <strong>{roundTo2Decimals(value)}&nbsp;<IntlMessages id={`app.${units}`}/></strong>
        <br />
        {<div>{broughtForwardLimit > 0 && ![366, 2928].includes(broughtForwardLimit) && 
          <div><IntlMessages id="components.userLeaveQuotas.remainingTooltipFooter" 
            values={{ 
              broughtForwardLimit: broughtForwardLimit,
              period: <IntlMessages id={`app.${units}`}/>}
            }/>
          </div>
        }
        {[366, 2928].includes(broughtForwardLimit) && <div><IntlMessages id="components.userLeaveQuotas.remainingTooltipFooterAll" values={{ broughtForwardLimit }} /></div>}
        {broughtForwardLimit === 0 && <div><IntlMessages id="components.userLeaveQuotas.remainingTooltipFooterZero" /></div>}</div>}
      </>}
  </div>
)

export const InitialBroughtForwardTooltip = ({value, units}) => (
  <Tooltip title={<InitialBroughtForwardTooltipContent value={value} units={units}/>}>
    <InfoCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const BroughtForwardTooltip = ({value, units, locationName, leaveTypeName, broughtForwardLimit}) => (
  <Tooltip title={
    <BroughtForwardTooltipContent 
      value={value}
      units={units}
      locationName={locationName}
      leaveTypeName={leaveTypeName}
      broughtForwardLimit={broughtForwardLimit}
    />
  }>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const QuotaTooltip = ({value, quota, hourlyLeaveAccounting, workingHours}) => (
  <Tooltip title={<QuotaTooltipContent value={value} quota={quota} hourlyLeaveAccounting={hourlyLeaveAccounting} workingHours={workingHours}/>}>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const ExpirationTooltip = ({expirationDate, locale}) => (
  <>{ expirationDate && 
    <Tooltip title={<ExpirationTooltipContent expirationDate={expirationDate} locale={locale} />}>
      <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
    </Tooltip>
  }</>
)

export const UsedDaysTooltip = ({used, units, scheduled, leaveTypeName}) => (
  <Tooltip title={<UsedDaysTooltipContent 
    scheduled={scheduled}
    used={used}
    units={units}
    leaveTypeName={leaveTypeName}/>
  }>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const RemainingDaysTooltip = ({value, units, leaveTypeName, broughtForwardLimit, unlimited}) => (
  <Tooltip title={<RemainingDaysTooltipContent 
    value={value} units={units} leaveTypeName={leaveTypeName} broughtForwardLimit={roundTo2Decimals(broughtForwardLimit)} unlimited={unlimited}/>}>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)
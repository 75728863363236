/* eslint-disable max-len */
import React from 'react'
import { Button, Col, Dropdown, MenuProps, Row, Typography } from 'antd'
import Icon from '@ant-design/icons'

import googleCalendarIconSvg from '../../assets/images/google-calendar.svg'
import office365CalendarIconSvg from '../../assets/images/microsoft-office365-outlook.svg'

import { generateAddToCalendarLink } from '@vacationtracker/shared/functions/generate-link'
import { getLeavePeriod} from '@vacationtracker/shared/functions/get-leave-period'

import { IGenerateNotificationValues, IUpdateLeaveRequestNotification } from './types'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { CalendarType } from '@vacationtracker/shared/types/features/add-to-calendar'
import { createQuotaFromDays } from '@vacationtracker/shared/functions/calculation-shared'
import { WorkingHours } from '@vacationtracker/shared/types/work-week'
import { displayQuota } from '@vacationtracker/shared/functions/get-days-or-hours'

const { Text } = Typography

const getRequestor = (
  userId,
  authUser,
  subscriptionEvent,
  userName
) => {
  if (userId === authUser?.id) {
    return ''
  }

  if (subscriptionEvent?.eventType === 'LEAVE_REQUEST_ADDED') {
    return userName
  }

  return `${userName}'s`
}

const generateNotification = (values: IGenerateNotificationValues, leaveRequest: any, autoApproved: boolean, formatMessage: any) => {
  const showRequestedRemainingInfo = values.showRequestedRemainingInfo
  const showAddToCalendarButton = values.showAddToCalendar

  const apiUrl = process.env.REACT_APP_API_URL || ''
  const requestData = { ...leaveRequest, leaveTypeName: values.leaveTypeName }
  const data = {
    companyId: requestData.user.companyId,
    userId: requestData.user.id,
    leaveTypeName: requestData.leaveTypeName,
    startDate: requestData.startDate,
    endDate: requestData.endDate,
    isPartDay: requestData.isPartDay,
    partDay: requestData.partDay,
    partDayStartHour: requestData.partDayStartHour,
    partDayEndHour: requestData.partDayEndHour,
  }
  const googleCalendarLink = generateAddToCalendarLink(CalendarType.Google, data, apiUrl, window.URL, values.hourFormat)
  const office365CalendarLink = generateAddToCalendarLink(CalendarType.Office365, data, apiUrl, window.URL, values.hourFormat)
  const helpDeskArticle = 'https://vacationtracker.crisp.help/en/'

  const addToCalendarMenu: MenuProps['items'] = [
    {
      key: 'cal-item-1',
      label: <a target="_blank" rel="noopener noreferrer" href={googleCalendarLink}>
        <Icon component={googleCalendarIconSvg} width={12} height={12} /> { formatMessage({ id: 'app.addToGoogleCalendar' }) }
      </a>,
    },
    {
      key: 'cal-item-2',
      label: <a target="_blank" rel="noopener noreferrer" href={office365CalendarLink}>
        <Icon component={office365CalendarIconSvg} width={12} height={12} /> { formatMessage({ id: 'app.addToMicrosoft365Calendar' }) }
      </a>,
    },
    {
      key: 'cal-item-3',
      label: <a target="_blank" rel="noopener noreferrer" href={helpDeskArticle}>
        { formatMessage({ id: 'components.leaveRequestAction.addToCalendarMoreInfo' }) }
      </a>,
    },
  ]

  return {
    key: values.key,
    description: (
      <div>
        <div>
          <Row>
            <Col span={12}>{formatMessage({ id: 'components.leaveRequestAction.leaveTypeLabel' })}</Col>
            <Col span={12}>{formatMessage({ id: 'components.leaveRequestAction.periodLabel' })}</Col>
          </Row>
          <Row>
            <Col span={12}><Text strong>{values.leaveTypeName}</Text></Col>
            <Col span={12}><Text strong>{values.leavePeriod}</Text></Col>
          </Row>
        </div>
        {showRequestedRemainingInfo && values.requestedAmountCurrentYear.raw.days > 0 &&
          <div>
            <Row>
              <Col span={12}>{formatMessage({ id: 'leaveRequests.requestedCurrentYear'})}</Col>
              <Col span={12}>{formatMessage({ id: 'leaveRequests.remainingCurrentYear' })}</Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text strong>{displayQuota(values.requestedAmountCurrentYear, formatMessage)}</Text>
              </Col>
              <Col span={12}>
                <Text strong>{
                  values.hasUnlimitedDays ? formatMessage({ id: 'app.unlimited' }) : displayQuota(values.remainingAmountCurrentYear, formatMessage)
                }</Text>
              </Col>
            </Row>
          </div>
        }
        {showRequestedRemainingInfo && values.requestedAmountNextYear.raw.days > 0 &&
          <div>
            <Row>
              <Col span={12}>{formatMessage({ id: 'leaveRequests.requestedNextYear'})}</Col>
              <Col span={12}>{formatMessage({ id: 'leaveRequests.remainingNextYear'})}</Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text strong>{displayQuota(values.requestedAmountNextYear, formatMessage)}</Text>
              </Col>
              <Col span={12}>
                <Text strong>{
                  values.hasUnlimitedDaysNextYear ? formatMessage({ id: 'app.unlimited' }) : displayQuota(values.remainingAmountNextYear, formatMessage)
                }</Text>
              </Col>
            </Row>
          </div>
        }
        {!autoApproved &&
          <div>
            <Row>
              <Col span={12}>{formatMessage({ id: 'app.role.approvers'})}:</Col>
            </Row>
            <Row>
              <Col span={24}><Text strong>{values.approvers.map((a) => a.name).join(', ')}</Text></Col>
            </Row>
          </div>
        }
        {showAddToCalendarButton &&
          <div style={{ marginTop: 20 }}>
            <Dropdown
              menu={{ items: addToCalendarMenu}}
              placement="bottomLeft"
            >
              <Button>{ formatMessage({ id: 'components.leaveRequestAction.addToCalendar' }) }</Button>
            </Dropdown>
          </div>
        }
      </div>
    ),
    message: values.message,
    duration: values.duration,
  }
}

export const updateLeaveRequestNotification = ({
  subscriptionEvent,
  leaveRequest,
  result,
  authUser,
  authCompany,
  formatMessage,
  locale,
  isCalendarConnected,
  notification,
}: IUpdateLeaveRequestNotification): { key: string } | void | object => {
  const {
    isPartDay,
    startDate,
    endDate,
    partDayStartHour,
    partDayEndHour,
    autoApproved,
    partDay,
    user: { team, leaveDays, name: userName, id: userId },
    leaveType,
    workingDays,
    totalThisYear,
    totalNextYear,
  } = leaveRequest.getLeaveRequest

  const { name: editorName, id: editorId } = leaveRequest.getUser
  const leaveTypeId = leaveType.id

  const currentYearIndex = leaveDays.findIndex(year => {
    if (totalThisYear > 0) {
      return new Date().toISOString() > year.yearStart && new Date().toISOString() < year.yearEnd
    }
  })
  const nextYearIndex = leaveDays.findIndex(year => {
    if (totalNextYear > 0) {
      return new Date(startDate).toISOString() < year.yearStart && new Date(endDate).toISOString() < year.yearEnd
    }
  })

  let currentYearRemainingQuota = createQuotaFromDays(0)
  let currentYearUnlimitted = false
  let currentYearHours: undefined | WorkingHours = undefined
  if (currentYearIndex !== -1) {
    const currentYearData = leaveDays[currentYearIndex].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)
    if (currentYearData?.quotas) {
      currentYearRemainingQuota = currentYearData.quotas.remaining
    }
    currentYearUnlimitted = Boolean(currentYearData?.hasUnlimitedDays)
    currentYearHours = leaveDays[currentYearIndex].workingHours
  }

  let nextYearRemainingQuota = createQuotaFromDays(0)
  let nextYearUnlimitted = false
  let nextYearHours: undefined | WorkingHours = undefined
  if (nextYearIndex !== -1) {
    const nextYearData = leaveDays[nextYearIndex].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)
    if (nextYearData?.quotas) {
      nextYearRemainingQuota = nextYearData.quotas.remaining
    }
    nextYearUnlimitted = Boolean(nextYearData?.hasUnlimitedDays)
    nextYearHours = leaveDays[nextYearIndex].workingHours
  }

  const hasUnlimitedDays = currentYearUnlimitted
  const hasUnlimitedDaysNextYear = nextYearUnlimitted
  let showRequestedRemainingInfo = true
  let showAddToCalendar = false

  let title
  const actionCreator = editorId === authUser?.id ? '' : editorName
  const requestor = getRequestor(userId, authUser, subscriptionEvent, userName)
  const titleValues = { actionCreator, requestor }
  const descriptionValues = {
    editor: editorName,
    action: '',
    requestedAmountCurrentYear: createQuotaFromDays(totalThisYear, currentYearHours),
    requestedAmountNextYear: createQuotaFromDays(totalNextYear, nextYearHours),
    remainingAmountCurrentYear: currentYearRemainingQuota,
    remainingAmountNextYear: nextYearRemainingQuota,
    leaveTypeName: leaveType.name,
    hourFormat: authUser?.hourFormat || HourFormatEnum.twentyFour,
    leavePeriod: getLeavePeriod({
      isPartDay,
      startDate,
      endDate,
      partDayStartHour,
      partDayEndHour,
      partDay,
      formatMessage,
      leaveRequestWorkingDays: workingDays,
      locale,
      hourFormat: authUser?.hourFormat || HourFormatEnum.twentyFour,
    }),
    approvers: team.approvers,
  }

  const message: string = subscriptionEvent.message
  switch (subscriptionEvent?.eventType) {
    case 'LEAVE_REQUEST_APPROVED':
      if (autoApproved) {
        if (result?.isEdited && result?.originalVersion.status === 'APPROVED') {
          title = 'components.leaveRequestAction.editedAutoApproved'
        } else {
          title = 'components.leaveRequestAction.autoApproved'
        }
      } else {
        title = 'components.leaveRequestAction.approved'
      }
      showRequestedRemainingInfo = false
      showAddToCalendar = !isCalendarConnected && authUser.id === result.userId
      break

    case 'LEAVE_REQUEST_DENIED':
      if (result && result?.isEdited && result?.originalVersion.status === 'APPROVED') {
        title = 'components.leaveRequestAction.editedDenied'
      } else {
        title = 'components.leaveRequestAction.denied'
      }
      showRequestedRemainingInfo = false
      break

    case 'LEAVE_REQUEST_CANCELLED':
      title = 'components.leaveRequestAction.cancelled'
      break

    case 'LEAVE_REQUEST_ADDED':
      title = 'components.leaveRequestAction.added'
      break
    case 'LEAVE_REQUEST_UPDATED':
      if (result?.isEdited && result?.status === 'APPROVED') {
        title = 'components.leaveRequestAction.editedApproved'
      } else {
        title = 'components.leaveRequestAction.edited'
      }
      break

    case 'LEAVE_REQUEST_CREATED':
      title = 'components.leaveRequestAction.created'
      break

    case 'LEAVE_REQUEST_DELETED':
      title = 'components.leaveRequestAction.deleted'
      break
  }

  const getMessageValue = (title, titleValues, message) => {
    const formatedMessage = formatMessage(
      { id: title },
      {
        actionCreator: titleValues.actionCreator,
        byActionCreator: (...chunks) => titleValues?.actionCreator ? chunks : '',
        requestor: titleValues.requestor,
        forUser: (...chunks) => titleValues?.requestor ? chunks : '',
      }
    )
    if (title && formatedMessage) {
      return formatedMessage
    } else if (/^error\.[a-zA-Z0-9]+$/.test(message)) {
      return formatMessage({ id: message })
    } else {
      return message
    }
  }

  const generatedNotification = generateNotification(
    {
      key: subscriptionEvent.correlationId,
      message: getMessageValue(title, titleValues, message),
      showRequestedRemainingInfo,
      showAddToCalendar,
      hasUnlimitedDays,
      hasUnlimitedDaysNextYear,
      ...descriptionValues,
      duration: 30,
    },
    leaveRequest.getLeaveRequest,
    autoApproved,
    formatMessage
  )

  // notify other user approvers
  if (authUser?.approverTo?.find(u => u.id === subscriptionEvent.userId) && authUser.id !== subscriptionEvent.creator) {
    notification.open(generatedNotification)
  }

  // notify approver of a user who changed leave request
  if (authUser?.approverTo?.find(u => u.id === subscriptionEvent.userId) && editorId === subscriptionEvent.creator) {
    notification.open(generatedNotification)
  }

  // notify user leave request belongs to
  if (authUser?.id === subscriptionEvent.userId) {
    notification.open(generatedNotification)
  }

  // notify user leave request belongs to when AUTO APPROVED
  if (authUser?.id === subscriptionEvent.userId && editorId === authUser.id && autoApproved && subscriptionEvent.eventType !== 'LEAVE_REQUEST_UPDATED') {
    notification.open(generatedNotification)
  }
}
import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { App as AntApp, ConfigProvider } from 'antd'
import {IntlProvider} from 'react-intl'
import { useSelector } from 'react-redux'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import * as Sentry from '@sentry/react'
import qs from 'qs'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { ClientContext, useManualQuery } from 'graphql-hooks'
import dayjs from 'dayjs'
import { getCompanyAndUserInfo, getUserActionFlow, getUserHelpInfo } from '../../graphql/custom-queries'
import * as logger from '../../services/logger'

import { identify, identifyCompany, identifySingleUser, page } from '../../services/analytics/analytics'
import { logout } from '../../services/auth/logout-handler'
import Api from '@vacationtracker/shared/services/api'

import { OnboardingProvider } from '../../context/onboardingContext'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setFeatureFlags } from '../../store/feature-flags-slice'
import { isMicrosoftPayment, setAuthCompany } from '../../store/auth-company-slice'
import { selectUserIdSlice } from '../../store/user-id-slice'
import { selectLocaleSlice, setLocale } from '../../store/locale-slice'
import { selectAuthUserSlice, setAuthUser } from '../../store/auth-user-slice'
import { setOnboardingActions, hideOnboardingFlow } from '../../store/onboarding-slice'
import { selectAuthStateSlice, setAuthState } from '../../store/auth-state-slice'

import MainApp from './MainApp'
import Notifications from '../Notifications'
import CompanyUpdatesNotifications from '../CompanyUpdatesNotifications'
import Signin from '../signin'
import Signup from '../signup'
import SlackPostInstallation from '../slack-post-installation'
import ExternalConnect from '../external-connect'
import CreateCompany from '../create-company'
import MicrosoftSaaSCreateCompany from '../microsoft-saas-create-company'
import SignInAsUser from '../sign-in-as-user'
import UserSignup from '../user-signup'
import AppLocale from '@vacationtracker/shared/i18n/language-provider'
import { updateGraphQlAuthHeaderAndSubscription } from '../../util/update-graphql-header-and-subscription'

import SubscriptionPage from '../../routes/SubscriptionPage'
import MicrosoftSubscriptionPage from '../../routes/MicrosoftSubscriptionPage'
import { SeoTags } from '../../components/seo-tags'

import { IGetCompanyAndUserInfo, IGetUserActionFlowData, IGetUserHelpInfoData } from '../../types/custom-queries'
import { IUserInfo } from '../../types/users'
import { wait } from '@vacationtracker/shared/functions/wait'
import { ICompanyInfo } from '@vacationtracker/shared/types/company'
import { availableLanguages, getSafeLocale } from '@vacationtracker/shared/i18n'
import { FrontendUrls } from '../../types/urls'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { setHelpInfoActions } from '../../store/help-info-slice'
import WhatsNewNotifications from '../WhatsNewNotifications'
import GooglePermissions from '../google-permissions'
import { UserRole } from '@vacationtracker/shared/types/user'
import SlackInstallBot from '../slack-install-bot'
import { ThemeContext } from '../../context/themeContext'
import { lightThemeToken, darkThemeToken, DARK_THEME } from '@vacationtracker/shared/constants/ThemeSetting'
import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'
import { getCurrentUser, IAuthUser } from '@vacationtracker/shared/services/auth'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

const UDID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i

const App = (): React.ReactElement => {
  const { locale } = useAppSelector(selectLocaleSlice)
  const { userId } = useAppSelector(selectUserIdSlice)
  const { isSigningUp } = useAppSelector(selectAuthUserSlice)
  const { authState } = useAppSelector(selectAuthStateSlice)
  const isMicrosoftBillingPayment = useSelector(isMicrosoftPayment)
  const { theme } = useContext(ThemeContext)
  const gqlClient = useContext(ClientContext)

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useAppDispatch()
  const [user, setUser] = useState<IUserInfo>()
  const [currentAppLocale, setCurrentApplocale] = useState(AppLocale[locale.locale])
  const [goToDashboardSection, setGoToDashboardSection] = useState<boolean>(false)
  const [themeToken, setThemeToken] = useState((theme && theme === DARK_THEME) ? darkThemeToken : lightThemeToken)

  const aiAssistedOnboardingEnabled = useFeatureFlagEnabled(FeatureFlagEnum.aiAssistedOnboarding)

  const abortControllerRef = useRef<AbortController>(new AbortController())

  const [getUserActionFlowQuery] = useManualQuery<IGetUserActionFlowData, { type: 'dashboard' }>(getUserActionFlow)
  const [getUserHelpInfoQuery] = useManualQuery<IGetUserHelpInfoData>(getUserHelpInfo)
  const [getCompanyAndUserInfoQuery] = useManualQuery<IGetCompanyAndUserInfo, { userId: string }>(getCompanyAndUserInfo)

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    page(location.pathname.replace(UDID_REGEX, 'ID'))
  }, [location.pathname])

  useEffect(() => {
    if (theme === DARK_THEME) {
      setThemeToken(darkThemeToken)
    } else {
      setThemeToken(lightThemeToken)
    }
  }, [theme])

  useEffect(() => {
    abortControllerRef.current = new AbortController()
    // Include the Crisp code here, without the <script></script> tags
    const script = document.createElement('script')
    if (process.env.REACT_APP_HIDE_CRISP_CHAT !== 'true') {
      window.$crisp = []
      window.$crisp.push(['safe', true])
      window.CRISP_WEBSITE_ID = 'b0a287f7-571c-45e0-92bc-3c30d8abc2ea'
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true
      document.body.appendChild(script)
    }
    return () => {
      if (process.env.REACT_APP_HIDE_CRISP_CHAT !== 'true') {
        document.body.removeChild(script)
      }
      abortControllerRef.current?.abort()
    }
  }, [])

  const updateHeadersAndGetCompanyAndUser = async () => {
    try {
      if (gqlClient !== null) {
        await updateGraphQlAuthHeaderAndSubscription({
          graphQlClient: gqlClient,
        })
        await getCompanyAndUser(userId, abortControllerRef.current)
      }
    } catch (error) {
      // Swallow the error
      logger.error('ERROR updateHeadersAndGetCompanyAndUser', error)
    }
  }

  useEffect(() => {
    if (userId && userId !== '') {
      abortControllerRef.current?.abort()
      abortControllerRef.current = new AbortController()
      updateHeadersAndGetCompanyAndUser()
    }
    return () => {
      // Cancel timeouts in the `getCompanyAndUser` function
      abortControllerRef.current?.abort()
    }
  }, [userId])

  useEffect(() => {
    setCurrentApplocale(AppLocale[locale.locale])
    dayjs.locale(getSafeLocale(locale.locale))
  }, [locale.locale])

  useEffect(() => {
    if (queryParams?.lang) {
      dispatch(setLocale(availableLanguages[queryParams.lang as LocaleEnum]))
    }
    const locationPathName = location?.pathname
    if (queryParams?.forceLogin === 'true') {
      delete queryParams.forceLogin

      logout({
        history: history,
        redirectRoute: `${FrontendUrls.connect}?${qs.stringify(queryParams)}`,
        reduxDispatch: dispatch,
        userId: userId,
      })
    }

    if (locationPathName === FrontendUrls.createCompanyStep3 && isSigningUp) {
      return
    }
    if (locationPathName === FrontendUrls.googlePermissions) {
      return
    }

    if (locationPathName.includes('/app/') && !userId && !localStorage.getItem('userId')) {
      localStorage.clear()
      sessionStorage.clear()
      dispatch(setAuthState(AuthStateEnum.signIn))
    }

    if (authState === AuthStateEnum.signUp) {
      if ([FrontendUrls.kats].includes(locationPathName as FrontendUrls)) {
        history.push(locationPathName)
        return
      }
    }

    if (authState === AuthStateEnum.signIn) {
      if (locationPathName.includes(FrontendUrls.externalConnect)) {
        return
      }
      if ([FrontendUrls.kats].includes(locationPathName as FrontendUrls)) {
        history.push(locationPathName)
        return
      }
      if ([FrontendUrls.googlePermissions].includes(locationPathName as FrontendUrls)) {
        history.push(locationPathName)
        return
      }
      if(locationPathName.includes(FrontendUrls.signin)) {
        history.push(FrontendUrls.signin + location.search)
        return
      }
      if(
        !locationPathName.includes(FrontendUrls.signup) &&
        !locationPathName.includes(FrontendUrls.slackPostInstall) &&
        !locationPathName.includes(FrontendUrls.installSlackBot)
      ) {
        history.push(FrontendUrls.signup)
        return
      }
    }

    if (authState === AuthStateEnum.signedIn) {
      logger.debug('STEP 3: aiAssistedOnboardingEnabled', aiAssistedOnboardingEnabled)
      if (locationPathName === FrontendUrls.createCompanyStep3 && typeof aiAssistedOnboardingEnabled === 'undefined') {
        // Feature flag is not loaded yet, wait for it
        return
      }
      if (locationPathName === FrontendUrls.createCompanyStep3) {
        const tourOrOnboarding = aiAssistedOnboardingEnabled ? 'onboarding' : 'tour'
        history.push(`${FrontendUrls.dashboard}?${tourOrOnboarding}=true`)
        return
      }
      if (['/', FrontendUrls.kats, FrontendUrls.googlePermissions].includes(locationPathName)) {
        history.push(FrontendUrls.dashboard)
        return
      }
    }

    if (authState === AuthStateEnum.loading && locationPathName === FrontendUrls.signup && localStorage.getItem('userId')) {
      setGoToDashboardSection(true)
      history.push(FrontendUrls.dashboard)
      return
    }

    if (authState === AuthStateEnum.signUp) {
      if (localStorage.getItem('userId') && goToDashboardSection) {
        history.push(FrontendUrls.dashboard)
        return
      }
      if (locationPathName === FrontendUrls.createCompany) {
        history.push(FrontendUrls.createCompanyStep1)
        return
      }
      if (locationPathName === FrontendUrls.microsoftSaasCreateCompany) {
        history.push(FrontendUrls.signup)
        return
      }
      if (
        !locationPathName.includes(FrontendUrls.createCompany) &&
        !locationPathName.includes(FrontendUrls.signup) &&
        !locationPathName.includes(FrontendUrls.slackPostInstall) &&
        !locationPathName.includes(FrontendUrls.installSlackBot) &&
        !locationPathName.includes(FrontendUrls.signin) // For redirects
      ) {
        history.push(FrontendUrls.signup)
        return
      }
    }

  }, [authState, location.pathname, aiAssistedOnboardingEnabled])

  const getFeatureFlags = async (role: UserRole, pathname) => {
    try {
      const response = await Api.get(`/core/status?role=${role.toLowerCase()}&url=${pathname}`, { withCredentials: true })
      const userIdInLocalStorage = localStorage.getItem('userId')
      if (userIdInLocalStorage && userIdInLocalStorage !== response.userId ) {
        logout({
          history: history,
          reduxDispatch: dispatch,
        })
      }
      dispatch(setFeatureFlags(response.enabledFeatures))
    } catch(error) {
      logger.error('ERROR', error)
    }
  }

  const getOnboardingChecklist = async () => {
    try {
      const response = await getUserActionFlowQuery({
        variables: {
          type: 'dashboard',
        },
        fetchOptionsOverrides: {
          signal: abortControllerRef.current?.signal,
        },
      })
      if (!response.data || response.error) throw response.error
      if(!response.data.getUserActionFlow) {
        dispatch(hideOnboardingFlow())
        return
      }
      const actions = pickBy(response.data.getUserActionFlow.actions || {}, identity)
      response.data.getUserActionFlow.actions = actions
      dispatch(setOnboardingActions(response.data.getUserActionFlow || {}))
    } catch(error) {
      logger.error('ERROR', error)
    }
  }

  const getHelpInfoForUser = async () => {
    try {
      const response = await getUserHelpInfoQuery({
        fetchOptionsOverrides: {
          signal: abortControllerRef.current?.signal,
        },
      })
      if (!response.data || response.error) throw response.error
      dispatch(setHelpInfoActions(response.data.getUserHelpInfo || {}))
    } catch(error) {
      logger.error('ERROR', error)
    }
  }

  let retryCrispSessionUpdate = 0
  const setCrispSessionInfoForLoggedInUsers = (companyInfo: ICompanyInfo, userInfo: IUserInfo): void => {
    try {
      if (!window.$crisp) {
        if(retryCrispSessionUpdate < 5) {
          retryCrispSessionUpdate++
          setTimeout(() => {
            setCrispSessionInfoForLoggedInUsers(companyInfo, userInfo)
          }, 20000)
        }
        return
      }

      // Set Crisp segments, i.e., "chat," "support," "dashboard," "slack," and "core-plan"
      const segments = ['chat', 'support', 'dashboard']
      segments.push(companyInfo.platform === 'microsoft' ? 'teams' : companyInfo.platform)
      if (companyInfo.plan) {
        segments.push(companyInfo.plan.toLowerCase() + '-plan')
      }
      window.$crisp.push(['set', 'session:segments', [segments, true]])

      // Set user name and company (user data)
      window.$crisp.push(['set', 'user:nickname', userInfo.name])
      window.$crisp.push(['set', 'user:company', companyInfo.name])

      // Set session data (company Id, user Id, user role and if the user is approver)
      window.$crisp.push(['set', 'session:data', [[
        ['company_id', companyInfo.id],
        ['user_id', userInfo.id],
        ['role', userInfo.role],
        ['locale', userInfo.locale],
        ['is_approver', userInfo.approverTo.length > 0],
        ['subscription_status', companyInfo.subscriptionStatus],
        ['plan', companyInfo.plan],
        ['platform', companyInfo.platform],
        ['payment_processor', companyInfo.billing?.paymentProcessor ?? ''],
      ]]])

    } catch(err) {
      // Swallow errors
      window.$crisp.push(['set', 'session:data', ['error', err.message]])
      logger.error(err)
    }
  }

  let numberOfRetry = 0
  const getCompanyAndUser = async (id: string, abortController?: AbortController) => {
    try {
      const response = await getCompanyAndUserInfoQuery({
        variables: {
          userId: id,
        },
        fetchOptionsOverrides: {
          signal: abortControllerRef.current?.signal,
        },
      })
      if (!response.data || response.error) throw response.error
      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        if (response.data.getUser.status !== 'ACTIVE') {
          logout({
            history,
            reduxDispatch: dispatch,
          })
        }

        // We do not want to wait for the logs to be initialized because, in some situations,
        // the firewall blocks the CW logs, but instead of giving an error, it keeps the connection until the timeout.
        // When this happens, the dashboard is stuck for these users.
        logger.init()

        const companyData = response.data.getCompany
        dispatch(setAuthCompany(companyData))
        const userData = response.data.getUser
        dispatch(setAuthUser(userData))
        const role: UserRole = userData.role === 'Admin' ? 'Admin' : userData.approverTo.length > 0 ? 'Approver' : 'User'

        if (queryParams?.lang && LocaleEnum[queryParams?.lang as string]) {
          dispatch(setLocale(availableLanguages[queryParams.lang as LocaleEnum]))
          try {
            let body = {}
            if (userData.role === 'Admin') {
              body = {
                eventType: 'USER_UPDATED',
                eventGroup: 'USER',
                userId: userData.id,
                name: userData.name,
                locale: queryParams?.lang,
                email: userData.email,
                platform: userData.platform,
                startDate: userData.startDate,
                isAdmin: userData.role === 'Admin',
                isNameLocked: userData?.isNameLocked,
                status: userData.status,
              }
            } else {
              body = {
                eventType: 'USER_UPDATED',
                eventGroup: 'USER',
                userId: userData.id,
                name: userData.name,
                locale: queryParams?.lang,
              }
            }
            delete queryParams.lang
            history.replace({
              search: qs.stringify(queryParams),
            })
            await Api.post('/core/event', body)
          } catch (error) {
            Sentry.captureException(error)
          }
        } else if (userData.locale) {
          dispatch(setLocale(availableLanguages[userData.locale]))
        }

        getOnboardingChecklist()
        getFeatureFlags(role, history.location.pathname)
        getHelpInfoForUser()

        setUser(userData)
        Sentry.setUser({
          id: userData.id,
          companyId: companyData.id,
        })
        if (userData?.id) {
          identify(userData.id, {
            name: userData.name,
            role: userData.role,
            subscriptionStatus: companyData?.subscriptionStatus || '',
            trialPeriod: companyData?.trialPeriod || '',
            companyId: companyData?.id,
          })
          identifySingleUser(userData.id, {
            name: userData.name,
            role: userData.role,
          }, companyData?.id)
          identifyCompany(companyData.id, {
            name: companyData.name,
            subscriptionStatus: companyData.subscriptionStatus,
            trialPeriod: companyData.trialPeriod,
            platform: companyData.platform,
            plan: companyData.plan,
            organizationId: companyData.organizationId,
          })
        }
        setCrispSessionInfoForLoggedInUsers(companyData, userData)
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      if (error.fetchError?.name === 'AbortError') {
        return
      }
      let currentUser: IAuthUser | null = null
      try {
        // This can fail, so we have try-catch inside catch
        currentUser = await getCurrentUser()
      } catch (error) {
        // Swallow the error
      }
      if (numberOfRetry >= 10) {
        // Log only before the logout
        logger.warning('ERROR GET COMPANY AND USER (app)', error, numberOfRetry)
        logout({
          history: history,
          reduxDispatch: dispatch,
        })
      } else if (currentUser
        || ![FrontendUrls.signin, FrontendUrls.signup].includes(location.pathname as FrontendUrls)
        || location.pathname.includes(FrontendUrls.externalConnect)
      ) {
        numberOfRetry++
        await wait(200 * numberOfRetry, abortController)
        return await getCompanyAndUser(id, abortController)
      }
    }
  }

  const RedirectWithParams = ({ to, from }) => {
    return (
      <Route
        path={from}
        render={() => <Redirect to={`${to}${location.search}`} />}
      />
    )
  }

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      theme={themeToken}
    >
      <AntApp>
        <IntlProvider
          key={currentAppLocale.locale}
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <SeoTags
            title='app.meta.title'
            description='app.meta.description'
          />
          <Switch>
            <RedirectWithParams from={FrontendUrls.connect} to={FrontendUrls.signup} />
            <Route exact path={FrontendUrls.signin} render={(routerProps) => <Signin {...routerProps} />} />
            <Route exact path={FrontendUrls.signup} render={(routerProps) => <Signup {...routerProps} />} />
            <Route exact path={FrontendUrls.slackPostInstall} render={() => <SlackPostInstallation />} />
            <Route exact path={`${FrontendUrls.installSlackBot}/:teamId`} render={(routerProps) => <SlackInstallBot {...routerProps} />} />
            <Route exact path={`${FrontendUrls.signup}/user/:code`} render={(routerProps) => <UserSignup {...routerProps} />} />
            <Route exact path={`${FrontendUrls.connect}/campaign`} render={(routerProps) => <Signin {...routerProps} />} />
            <Route exact path={`${FrontendUrls.externalConnect}/:code`} render={(routerProps) => <ExternalConnect {...routerProps} />} />
            <Route exact path={FrontendUrls.createCompanyStep1} render={() => <CreateCompany />} />
            <Route exact path={FrontendUrls.createCompanyStep2} render={() => <CreateCompany />} />
            <Route exact path={FrontendUrls.createCompanyStep3} render={() => <CreateCompany />} />
            <Route exact path={FrontendUrls.microsoftSaasCreateCompanyWelcome} render={() => <MicrosoftSaaSCreateCompany />} />
            <Route exact path={FrontendUrls.microsoftSaasCreateCompanyDetails} render={() => <MicrosoftSaaSCreateCompany />} />
            <Route exact path={FrontendUrls.microsoftSaasCreateCompanyAssignLicenses} render={() => <MicrosoftSaaSCreateCompany />} />
            <Route exact path={FrontendUrls.microsoftSaasCreateCompanySetupBot} render={() => <MicrosoftSaaSCreateCompany />} />
            <Route exact path={FrontendUrls.kats} render={(routerProps) => <SignInAsUser {...routerProps} />} />
            <Route exact path={FrontendUrls.googlePermissions} render={(routerProps) => <GooglePermissions {...routerProps} />} />
            {user &&
              <>
                <Notifications />
                <CompanyUpdatesNotifications />
                <WhatsNewNotifications />
                { isMicrosoftBillingPayment ?
                  <Route path={FrontendUrls.subscription} component={() => <MicrosoftSubscriptionPage />} /> :
                  <Route path={FrontendUrls.subscription} component={() => <SubscriptionPage />} />
                }
                {location.pathname !== FrontendUrls.subscription &&
                  <OnboardingProvider history={history} user={user}>
                    <Route
                      path={`${match.url}`}
                      component={() => <MainApp user={user} />}
                    />
                  </OnboardingProvider>
                }
              </>
            }
          </Switch>
        </IntlProvider>
      </AntApp>
    </ConfigProvider>
  )
}

export default memo(App)

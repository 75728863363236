import { IError } from './types'

export const RefreshTokenRenewalFailed: IError = {
  code: 'RefreshTokenRenewalFailed',
  message: 'Refresh token renew failed',
}

export const CalendarNotFound: IError = {
  code: 'ErrorCalendarNotFound',
  message: 'Unable to find the calendar. It may have been deleted or is no longer available. Please review your calendar settings.',
}

export const UpdateInsertLeaveInCalendarFailed: IError = {
  code: 'UpdateInsertLeaveInCalendarFailed',
  message: 'Insert/Update leave in calendar failed',
}

export const CalendarOrEventNotFound: IError = {
  code: 'ErrorCalendarOrEventNotFound',
  message: 'Calendar or event not found',
}

export const CalendarAlreadyConnected: IError = {
  code: 'ErrorCalendarAlreadyConnected',
  message: 'This calendar is already connected',
}

export const CalendarAdminConsentRequired: IError = {
  code: 'CalendarAdminConsentRequired',
  message: 'Admin consent is required to insert or update events in the calendar',
}

export const ForceReconnect: IError = {
  code: 'ForceReconnect',
  message: 'Reconnect the calendar', // front end will not show this message
}


import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Checkbox, Divider, Form, Input, Modal, Select, Switch, Tooltip, Tag, Button } from 'antd'
import { CloseCircleOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import InputColor from 'react-input-color'
import 'emoji-mart/css/emoji-mart.css'
import { Picker, Emoji } from 'emoji-mart'

import IntlMessages from '../../util/IntlMessages'
import { getCalendarStatusSelectOptions } from '@vacationtracker/shared/functions/get-calendar-status-options'

import { Platform } from '@vacationtracker/shared/types/core-event'
import { LeaveTypeCalendarStatusesEnum } from '@vacationtracker/shared/types/calendar'
import { ILeaveTypeUpdates, ILeaveTypeWithAssignLocations } from '../../types/leave-types'

interface IEditLeaveTypeForm {
  visibleModal: boolean
  handleCancel: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave: (e: any) => void
  leaveType?: ILeaveTypeWithAssignLocations
  loading: boolean
  platform: Platform
}

interface IDefaultFormValues {
  name: string
  isActive: boolean
  color: string
  awayStatusMessage?: string
  calendarStatus?: LeaveTypeCalendarStatusesEnum
}

const EditLeaveTypeForm = ({
  visibleModal,
  handleCancel,
  onSave,
  leaveType,
  loading,
  platform,
}: IEditLeaveTypeForm): React.ReactElement => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const [showSlackStatus, setShowSlackStatus] = useState(leaveType?.awayStatus || false)
  const [chosenEmoji, setChosenEmoji] = useState(leaveType?.awayStatusIcon || ':palm_tree:')
  const [showEmojicon, setShowEmojicon] = useState(false)

  const defaultValue: IDefaultFormValues = {
    name: leaveType?.name || '',
    isActive: Boolean(leaveType?.isActive),
    color: leaveType?.color || '#ffe58f',
    awayStatusMessage: leaveType?.awayStatusMessage,
  }

  const [color, setColor] = useState<string>('#ffe58f')

  const onFinish = async () => {
    const values = await form.validateFields()
    const updateValue: Partial<ILeaveTypeUpdates> = {
      name: values.name,
      isActive: values.isActive,
      color: color.trim(),
      leaveTypeId: leaveType?.id,
      calendarStatus: values.calendarStatus,
    }

    if (platform === 'slack') {
      updateValue.awayStatus = showSlackStatus
      if(showSlackStatus) {
        updateValue.awayStatusIcon = chosenEmoji
        updateValue.awayStatusMessage = values.awayStatusMessage
      }
    }
    onSave(updateValue)
  }


  const onChangeSetSlackStatus = () => {
    setShowSlackStatus(!showSlackStatus)
  }

  const onEmojiSelect = (e: { colons: React.SetStateAction<string> }) => {
    setShowEmojicon(false)
    setChosenEmoji(e.colons)
  }

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  }

  return (
    <Modal
      title={<IntlMessages id="components.leaveTypeForm.editLeaveTypeTitle" values={{ leaveTypeName: leaveType?.name }} />}
      open={visibleModal}
      closeIcon={<CloseCircleOutlined />}
      okText={<IntlMessages id="app.save" />}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onOk={onFinish}
      style={{ top: 20 }}
      width={580}
      cancelText={<IntlMessages id="app.cancel" />}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Form
        {...formLayout}
        form={form}
        name="LeaveTypeForm"
        labelWrap
        disabled={loading}
        initialValues={defaultValue}
      >
        <Form.Item
          name='name'
          label={<IntlMessages id="app.name" />}
          rules={[
            { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
            { max: 32, message: <IntlMessages id="components.leaveTypeForm.maxLength" /> },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="app.color" />}
          name='color'
        >
          <InputColor
            initialValue={defaultValue.color}
            onChange={(color) => setColor(color.hex)}
            placement='right'
            data-qa="leave-type-color-picker"
          />
        </Form.Item>

        <Form.Item
          label={<IntlMessages id="app.active" />}
          name='isActive'
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          name='calendarStatus'
          label={(<>
            <IntlMessages id="components.createLeaveTypeForm.calendarStatusLabel" />
            <Tooltip className="info-tooltip" title={<IntlMessages id="components.createLeaveTypeForm.calendarStatusLabelTooltipInfo" />} ><InfoCircleOutlined /></Tooltip>
          </>)}
        >
          <Select
            defaultValue={leaveType?.calendarStatus || LeaveTypeCalendarStatusesEnum.busy}
            options={getCalendarStatusSelectOptions(formatMessage)}
            style={{ width: 100 }}
          />
        </Form.Item>

        {platform === 'slack' &&
          <>
            <Form.Item
              name="setSlackStatus"
              label={<IntlMessages id="components.leaveTypeForm.setSlackStatus" />}
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                onChange={onChangeSetSlackStatus}
                unCheckedChildren={<CloseOutlined />}
                checked={showSlackStatus}
              />
            </Form.Item>

            {showSlackStatus &&
              <>
                <Form.Item
                  label={<IntlMessages id="components.leaveTypeForm.slackStatus" />}
                  style={{ position: 'relative' }}
                >
                  <Form.Item noStyle>
                    <Button onClick={() => setShowEmojicon(!showEmojicon)} className="ant-input ant-input-emoji">
                      <Emoji emoji={chosenEmoji} size={16} />
                    </Button>
                  </Form.Item>
                  <Form.Item
                    name="awayStatusMessage"
                    style={{ display: 'inline-block', width: 'calc(100% - 45px)' }}
                    rules={[
                      { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
                    ]}
                  >
                    <Input placeholder={`${formatMessage({ id: 'components.leaveTypeForm.setSlackStatusPlaceholder' })}`} />
                  </Form.Item>
                  {showEmojicon &&
                    <span className="emoji-picker">
                      <Picker
                        onSelect={onEmojiSelect}
                        theme="auto"
                        color="#7f00ff"
                        emoji=""
                        title=""
                        sheetSize={32}
                        emojiSize={22}
                      />
                    </span>
                  }
                </Form.Item>
              </>
            }
          </>
        }
        {leaveType && leaveType?.assignedToLocations?.length > 0 &&
          <div>
            <Divider />
            <p>
              <Tooltip className="info-tooltip" title={
                <div style={{textAlign: 'center'}}>
                  <div><IntlMessages id="components.leaveTypeForm.tooltipHeader" /></div>
                  <br />
                  <ul style={{ textAlign: 'left' }}>
                    {[1,2,3].map((id) => (
                      <li key={id}><IntlMessages id={`components.leaveTypeForm.tooltipElement${id}`} values={{ strong: (...chunks) => <strong>{chunks}</strong> }}/></li>
                    ))}
                  </ul>
                  <br />
                  <div><IntlMessages id="components.leaveTypeForm.tooltipFooter" /></div>
                </div>}>
                <InfoCircleOutlined />
              </Tooltip>
              &nbsp;
              <IntlMessages
                id="components.createLeaveTypeForm.locationsPolicyHint"
                values={{ locations: leaveType?.assignedToLocations?.filter(location => location !== null).length }}
              />
            </p>
            {leaveType?.assignedToLocations?.filter(location => location !== null).map((location) => (
              <Tag key={location.id}>
                <span><Link to={`/app/settings/location/${location.id}/leave-policies/${leaveType.id}`}>{location.name}</Link></span>
              </Tag>
            ))}
          </div>}
      </Form>
    </Modal>
  )
}

export default EditLeaveTypeForm

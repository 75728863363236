import React, { useState, useEffect } from 'react'
import { Select, Space } from 'antd'
import { ClockCircleOutlined, CloseCircleFilled, SwapRightOutlined } from '@ant-design/icons'
import dayjs, { Dayjs } from 'dayjs'
import range from 'lodash/range'
import { BaseInfo } from 'rc-picker/lib/interface'

import IntlMessages from '../utils/IntlMessages'

import { CustomTimeRangePickerProps, ITimeOption } from './types'
import { DEFAULT_WORKING_HOURS_IN_DAY } from '../../data/app-parameters'
import { getUserWorkingHoursPerDay } from '../../functions/work-week'
import { ShortestLeaveIntervalEnum } from '../../types/leave-policy'
import { HourFormatEnum } from '../../types/user'


const CustomTimeRangePicker: React.FC<CustomTimeRangePickerProps> = ({
  form,
  shortestLeaveInterval,
  selectedUser,
  selectedDate,
  shouldResetTime,
  hourFormat,
  minuteStep = 60,
  isHalfDayHoliday,
  onChange,
  style,
  editTimes,
  setShouldResetTime,
}) => {
  const [startTime, setStartTime] = useState<ITimeOption | null>(null)
  const [endTime, setEndTime] = useState<ITimeOption | null>(null)
  const [hovered, setHovered] = useState<boolean>(false)
  const [hourFormatString] = useState<string>(hourFormat === HourFormatEnum.twelve ? 'hh:mm A' : 'HH:mm')
  const [startTimes, setStartTimes] = useState<ITimeOption[]>([])
  const [endTimes, setEndTimes] = useState<ITimeOption[]>([])


  useEffect(() => {
    if (editTimes) {
      const {
        startHour,
        startMinute,
        endHour,
        endMinute,
      } = editTimes
      const startTime = dayjs().hour(startHour).minute(startMinute as number)
      const endTime = dayjs().hour(endHour).minute(endMinute as number)
      const startTimeObj = {
        key: startTime.toISOString(),
        label: startTime.format(hourFormatString),
        value: startTime.toISOString(),
      }

      const endTimeObj = {
        key: endTime.toISOString(),
        label: endTime.format(hourFormatString),
        value: endTime.toISOString(),
      }
      setStartTime(startTimeObj)
      const endTimeOptions = generateTimeOptions('end', shortestLeaveInterval, startTimeObj.value)
      setEndTimes(endTimeOptions)
      setEndTime(endTimeObj)

      updateForm([startTime, endTime])
    }
  }, [editTimes])

  useEffect(() => {
    if (selectedDate) {
      const startTimeOptions = generateTimeOptions('start', shortestLeaveInterval)
      setStartTimes(startTimeOptions)

      if (!editTimes) {
        const initialStartTime = {
          value: dayjs().hour(8).minute(0).toISOString(),
          label: dayjs().hour(8).minute(0).format(hourFormatString),
          key: dayjs().hour(8).minute(0).toISOString(),
        }
        setStartTime(initialStartTime)

        // Generate end times immediately after setting start time
        const endTimeOptions = generateTimeOptions('end', shortestLeaveInterval)
        setEndTimes(endTimeOptions)

        handleTimeSelect([dayjs(initialStartTime.value)], undefined, { range: 'start' })
      }
      if (shouldResetTime) {
        setShouldResetTime(false)
      }
    }
  }, [selectedDate, editTimes, shouldResetTime])

  useEffect(() => {
    if (startTime) {
      const existingEndTime = endTime
      let newEndTime
      const endTimeOptions = generateTimeOptions('end', shortestLeaveInterval, startTime.value)
      setEndTimes(endTimeOptions)
      if (shouldUpdateEndTime(startTime, existingEndTime)) {
        newEndTime = endTimeOptions.filter(option => !option.disabled)[0]
        setEndTime(newEndTime)
      }
      const startTimeValue = startTime.value
      const endTimeValue = newEndTime?.value || existingEndTime?.value

      updateParentValue(startTimeValue, endTimeValue)
      handleTimeSelect([dayjs(startTimeValue), dayjs(endTimeValue)], undefined, { range: 'end' })
    }
  }, [startTime, editTimes])


  const shouldUpdateEndTime = (
    startTime: ITimeOption,
    existingEndTime: ITimeOption | null
  ) => {
    if (!existingEndTime) return true

    const startMoment = dayjs(startTime.value).second(0).millisecond(0)
    const endMoment = dayjs(existingEndTime.value).second(0).millisecond(0)

    // Check if minutes match - THIS IS TO BE CHANGED WHEN WE ALLOW LEAVE TO BE LESS THAN 1 HOUR
    const startMinute = startMoment.minute()
    const endMinute = endMoment.minute()
    const minutesDontMatch = startMinute !== endMinute

    // Calculate if current end time is within valid range
    const hourDiff = endMoment.diff(startMoment, 'hour')
    const isWithinWorkingHours = hourDiff > 0 && hourDiff <= DEFAULT_WORKING_HOURS_IN_DAY

    return !isWithinWorkingHours ||
      startMoment.isAfter(endMoment) ||
      startMoment.isSame(endMoment) ||
      minutesDontMatch
  }

  const generateTimeOptions = (type: 'start' | 'end', shortestLeaveInterval, startTimeValue?: string): ITimeOption[] => {
    const computedStartTimeValue = startTimeValue ? dayjs(startTimeValue) : dayjs(startTime?.value)
    const options: ITimeOption[] = []
    const current = dayjs()
    const times = handleDisableTime(current, type, { from: computedStartTimeValue })

    const addOption = (hour: number, minute: number) => {
      const time = dayjs().hour(hour).minute(minute)

      if (times.disabledHours && times.disabledHours().includes(hour)) {
        options.push({
          value: time.toISOString(),
          label: time.format(hourFormatString),
          disabled: true,
          key: time.toISOString(),
        })
        return
      }

      if (type === 'end' && startTimeValue) {
        const startTime = dayjs(startTimeValue)
        const startMinute = startTime.minute()
        const startHour = startTime.hour()

        // Match minutes
        if (minute !== startMinute) {
          return
        }

        // Enforce minimum interval based on leave type
        const hourDiff = hour - startHour
        if (shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay && hourDiff < 4) {
          return // Skip if less than 4 hours for half-day
        } else if (shortestLeaveInterval === ShortestLeaveIntervalEnum.oneHour && hourDiff < 1) {
          return // Skip if less than 1 hour
        }
      }

      if (times.disabledMinutes && times.disabledMinutes(hour).includes(minute)) return

      options.push({
        value: time.toISOString(),
        label: time.format(hourFormatString),
        disabled: false,
        key: time.toISOString(),
      })
    }

    let endHour = 24
    if (type === 'start') {
      endHour = shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay ? 20 : 23
    }

    for (let hour = type === 'start' ? 0 : 1; hour < endHour; hour++) {
      for (let minute = 0; minute < 60; minute += minuteStep) {
        addOption(hour, minute)
      }
    }

    return options
  }

  const handleTimeSelect = (dates: Dayjs[] | null, dateStrings?: string[], info?: BaseInfo) => {
    if (!dates) {
      return
    }
    const start = dates[0]
    if (start && info?.range === 'start' && shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay) {
      // Get user working hours and available hours for a selected date
      const user = selectedUser
      const workingHours = getUserWorkingHoursPerDay(user.workHours)
      const endTime = start.add(Math.floor(workingHours / 2), 'hours')
      updateForm([start, endTime])
      setEndTime({
        key: endTime.toISOString(),
        label: endTime.format(hourFormat === HourFormatEnum.twelve ? 'hh:mm A' : 'HH:mm'),
        value: endTime.toISOString(),
      })
      return
    }
    updateForm(dates)
  }

  const handleDisableTime = (
    _: dayjs.Dayjs,
    pickedRange: 'start' | 'end',
    info: { from?: dayjs.Dayjs | undefined }
  ) => {
    // No need to validate only start time or if start date is not selected
    if (pickedRange === 'start' || !form.getFieldValue('startDate')) {
      return {}
    }

    // Get start hour
    const startHour = info.from?.hour() || 0
    // Disable all hours before start hour + 1 hour (we do not allow less than 1 hour leaves)
    const disabledBefore = range(0, startHour + 1)
    // Get user working hours and available hours for a selected date
    const user = selectedUser
    let workingHours = getUserWorkingHoursPerDay(user.workHours)
    if (isHalfDayHoliday()) {
      workingHours /= 2
    }

    // For half day, they can select only one value (now + half day hours)
    if (shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay) {
      const allowedValue = startHour + Math.floor(workingHours / 2)
      return {
        disabledHours: () => [
          ...range(0, allowedValue),
          ...range(allowedValue + 1, 24),
        ],
      }
    }

    // Disable all hours after start hour + working hours + 1 hour until the end of the current day
    const disabledAfter = range(startHour + Math.floor(workingHours + 1), 24, 1)

    return {
      disabledHours: () => [...disabledBefore, ...disabledAfter],
      disabledMinutes: (selectedHour: number) => {
        if (selectedHour === startHour) {
          return range(0, 60)
        }
        if (selectedHour === startHour + Math.floor(workingHours + 1)) {
          return range(0, 60)
        }
        return []
      },
    }
  }

  const handleStartTimeChange = (startTimeOption: ITimeOption) => {
    setStartTime(startTimeOption)
    if (endTime && dayjs(startTimeOption.value).isAfter(dayjs(endTime.value))) {
      setEndTime(null)
    }
    updateParentValue(startTimeOption.value, endTime?.value as string)
    handleTimeSelect([dayjs(startTimeOption.value)], undefined, { range: 'start' })
  }

  const handleEndTimeChange = (endTimeOption: ITimeOption) => {
    setEndTime(endTimeOption)
    updateParentValue(startTime?.value as string, endTimeOption.value)
    handleTimeSelect([dayjs(startTime?.value), dayjs(endTimeOption.value)], undefined, { range: 'end' })
  }

  const updateParentValue = (start: string | null, end: string | null) => {
    const formatTime = (time: string | null) => (time ? dayjs(time).get('hours').toString() : null)

    const startTime = formatTime(start)
    const endTime = formatTime(end)

    onChange?.({ startTime, endTime })
  }

  const handleClear = () => {
    updateForm([null, null])
    setStartTime(null)
    setEndTime(null)
    onChange?.({ startTime: null, endTime: null })
    handleTimeSelect(null, undefined, { range: 'start' })
  }

  const updateForm = (dates: (Dayjs | null)[]) => {
    if (!dates) {
      return
    }
    form.setFieldsValue({
      range: dates,
    })
  }

  const handleOnDropdownVisibleChange = (open: boolean, timeLabel: string | undefined) => {
    if (open) {
      setTimeout(() => {
        const container = document.querySelector('.ant-select-dropdown:not(.ant-select-dropdown-hidden)') as HTMLElement
        if (container) {
          scrollToSelectedOption(container, timeLabel || '')
        }
      }, 325)
    }
  }

  const scrollToSelectedOption = (container: HTMLElement, timeLabel: string) => {
    const selectedOption = Array.from(container.querySelectorAll('.ant-select-item-option-content')).find(
      (el) => el.textContent === timeLabel
    ) as HTMLElement | undefined

    if (selectedOption) {
      selectedOption.scrollIntoView({
        block: 'center',
      })
    }
  }

  const handleOnMouseEnter = () => {
    if (startTime && endTime) {
      setHovered(true)
    }
  }

  const handleOnMouseLeave = () => {
    if (hovered) {
      setHovered(false)
    }
  }

  return (
    <div
      className="custom-time-range-picker"
      style={style}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Space>
        <Select<string, ITimeOption>
          popupMatchSelectWidth={100}
          className="time-select"
          style={{ width: 130 }}
          placeholder={<IntlMessages id="components.leaveForm.startTime" />}
          value={startTime as unknown as string}
          labelInValue
          onChange={v => handleStartTimeChange(v as unknown as ITimeOption)}
          options={startTimes}
          disabled={!selectedDate || shouldResetTime}
          suffixIcon={null}
          onDropdownVisibleChange={(open) => handleOnDropdownVisibleChange(open, startTime?.label)}
        />
        <SwapRightOutlined />
        <div className="end-time-container">
          <Select<string, ITimeOption>
            popupMatchSelectWidth={100}
            style={{ width: 130 }}
            className="time-select"
            placeholder={<IntlMessages id="components.leaveForm.endTime" />}
            value={endTime as unknown as string}
            labelInValue
            onChange={v => handleEndTimeChange(v as unknown as ITimeOption)}
            options={endTimes}
            suffixIcon={null}
            disabled={!startTime || shouldResetTime || !selectedDate || shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay}
            onDropdownVisibleChange={(open) => handleOnDropdownVisibleChange(open, endTime?.label)}
          />
          {hovered
            ? <CloseCircleFilled className='clear-icon' onClick={handleClear} />
            : <ClockCircleOutlined className='clock-icon' />
          }
        </div>
      </Space>
    </div>
  )
}

export default CustomTimeRangePicker
import { IEventBridgeMessage } from './event-bridge'
import { ILeaveRequest, IPartDay } from './leave-request'
import { INotificationHoliday, INotificationLeave } from './notification-v2'
import { IUser } from './user'

export const dayNames = [
  'app.sunday',
  'app.monday',
  'app.tuesday',
  'app.wednesday',
  'app.thursday',
  'app.friday',
  'app.saturday',
]

export const shortDayNames = [
  'app.sunday.short',
  'app.monday.short',
  'app.tuesday.short',
  'app.wednesday.short',
  'app.thursday.short',
  'app.friday.short',
  'app.saturday.short',
]

export const allDaysAsNumbers = [0, 1, 2, 3, 4, 5, 6]

export enum ISyncedCalendarTypeEnum {
  google = 'google',
  outlook = 'outlook',
  ical = 'ical'
}

export interface ICalendar {
  companyId: string
  id: string
  creator: string
  teamId?: string
  locationId?: string
  teamIds?: string[]
  locationIds?: string[]
  labelIds?: string[]
  updated?: string
  calendarOwnerEmail?: string
  userWhoConnectedCalendar?: string
}

export interface ICalendarError {
  code: string
  message: string
  leaveRequestId?: string
  operation?: ICalendarOperationEnum
  eventId?: string
}

export interface ISyncedCalendar extends ICalendar {
  calendarId: string
  accessToken: string
  refreshToken: string
  type: ISyncedCalendarTypeEnum
  timezone: string
  event: ICalendarEvent
  user?: IUser
  tenant?: string | null | undefined
  error?: ICalendarError | null
  errors?: ICalendarError | null
  deleted?: boolean
  locations?: string[]
  departments?: string[]
  labels?: string[]
  calendarName?: string
  companyIdCalendarType?: string
  created?: string
  ttl?: number
  lastUsed?: string
}

export interface ICalendarRequest {
  companyId: string
  startDate: string
  endDate: string
  locations: string[]
  teams: string[]
  labels: string[]
}

export interface ICalendarRes {
  startDate: string
  endDate: string
  leaves: INotificationLeave[]
  holidays: INotificationHoliday[]
}

export enum LeaveTypeCalendarStatusesEnum {
  free = 'Free',
  busy = 'Busy'
}

export interface ICalendarEvent {
  [leaveRequestId: string]: {
    eventId: string
  }
}

export interface IInsertLeaveInCalendarParams {
  calendarId: string
  start: string
  end: string
  description: string
  transparency: string
  timezone: string
  isPartDay: boolean
  summary: string
  eventId?: string
  calendar?: IConnectedGoogleCalendar | ISyncedCalendar
}

export interface IUpdatedCalendarPayload {
  leaveRequestId: string
  companyId: string
  isEdited: boolean
  created: string
  userId: string
  leaveTypeId: string
  leavePolicyId: string
  startDate: string
  endDate: string
  isPartDay: boolean
  partDayStartHour: number
  partDayEndHour: number
  partDay: IPartDay | null
  reason: string
  creator: string
  source: string
  calendarStatus?: string
}

export interface IExtendedUpdatedCalendarPayload extends IUpdatedCalendarPayload {
  id: string
}

type IIntegrationCalendarMeta = {
  platform: string
}

export enum ICalendarIntegrationTypeEnum {
  googleCalendar = 'google-calendar',
  outlookCalendar = 'outlook-calendar',
  googleCalendarAdd = 'google-calendar-add',
  outlookCalendarAdd = 'outlook-calendar-add',
  googleCalendarDisconnect = 'google-calendar-disconnect',
  outlookCalendarDisconnect = 'outlook-calendar-disconnect',
  googleCalendarReconnect = 'google-calendar-reconnect',
  outlookCalendarReconnect = 'reconnect-outlook-calendar',
  reconnectCalendarForSlack = 'reconnect-calendar-for-slack',
  googleSharedCalendarReconnect = 'google-shared-calendar-reconnect',
  outlookSharedCalendarReconnect = 'reconnect-shared-outlook-calendar',
  reconnectSharedCalendarForSlack = 'reconnect-shared-calendar-for-slack',
}

export type IUpdateGoogleCalendarEvent = IEventBridgeMessage<IIntegrationCalendarMeta, IUpdatedCalendarPayload> & {
  type: ICalendarIntegrationTypeEnum.googleCalendar
}

export type IUpdateOutlookCalendarEvent = IEventBridgeMessage<IIntegrationCalendarMeta, IUpdatedCalendarPayload> & {
  type: ICalendarIntegrationTypeEnum.outlookCalendar
}

export type ExtenedLeaveRequest = ILeaveRequest & {eventType: 'LEAVE_REQUEST_ADDED'}

export type IGooogleCalendarAddEvent = IEventBridgeMessage<IIntegrationCalendarMeta,ExtenedLeaveRequest> & {
  type: ICalendarIntegrationTypeEnum.googleCalendarAdd
}

export type IOutlookCalendarAddEvent = IEventBridgeMessage<IIntegrationCalendarMeta,ExtenedLeaveRequest> & {
  type: ICalendarIntegrationTypeEnum.outlookCalendarAdd
}

export interface IInsertLeaveEvent {
  calendarId: string
  requestBody: {
    start: {
      timeZone: string
      date?: string
      dateTime?: string
    }
    end: {
      timeZone: string
      date?: string
      dateTime?: string
    }
    description: string
    summary: string
    status: string
    transparency: string
  }
  eventId?: string
}

export interface IOutlookCalendar {
  id: string
  name: string
  color: string
  hexColor: string
  isDefaultCalendar: boolean
  changeKey: string
  canShare: boolean
  canViewPrivateItems: boolean
  canEdit: boolean
  defaultOnlineMeetingProvider: string
  isTallyingResponses: boolean
  isRemovable: boolean
  owner: {
    name: string
    address: string
  }
  allowedOnlineMeetingProviders?: string[]
  summary?: string
}

export interface IGoogleCalendarListResponse {
  etag: string
  kind: string
  nextSyncToken: string
  items: IGoogleCalendar[]
}

export interface IGoogleCalendar {
  kind: string
  etag: string
  id: string
  summary: string
  timeZone: string
  summaryOverride: string
  colorId: string
  backgroundColor: string
  foregroundColor: string
  accessRole: string
  defaultReminders?: [
    {
      method: string
      minutes: number
    }
  ]
  conferenceProperties?: {
    allowedConferenceSolutionTypes: string[]
  }
  name?: string
}

export interface IConnectCalendarParams {
  accessToken: string
  refreshToken: string
  calendarId: string
}

export enum ICalendarOperationEnum {
  insert = 'insert',
  update = 'update',
  delete = 'delete'

}

interface IConnectedCalendar {
  calendarId: string
  accessToken: string
  refreshToken: string
  type: string
  timezone: string
  tenant?: string | null | undefined
  calendarOwnerEmail?: string
  userWhoConnectedCalendar?: string
  filters?: {
    locations: string[]
    departmens: string[]
    labels: string[]
  }
  event?: ICalendarEvent
  error?: ICalendarError | null
  creator?: string
}

export type IConnectedGoogleCalendar = Omit<IConnectedCalendar, 'tenant'>
export type IConnectedOutlookCalendar = IConnectedCalendar

export enum ConnectedCalendarTypeEnum {
  user = 'USER',
  group = 'GROUP'
}

export enum InsertFutureLeaveRequestIntegrationCalendarEnum {
  google = 'insert-future-leave-into-google-calendar',
  outlook = 'insert-future-leave-into-outlook-calendar'
}

export enum LatestOperationStatusEnum {
  success = 'success',
  failed = 'failed'
}

export interface ICalendarEventMapping {
  calendarId: string
  leaveRequestId: string
  userId: string
  companyId: string
  latestOperation: ICalendarOperationEnum
  latestOperationStatus: LatestOperationStatusEnum
  eventId?: string
}

export interface IGetSharedCalendarEventMapping {
  calendarEventMapping: ICalendarEventMapping
  connectedCalendarOldSystemEventId: string | undefined
}
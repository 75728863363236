import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App, Typography, Form, Input, InputNumber, Space, Radio, Button, Select, Row, Col, Alert } from 'antd'
import { LoadingOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import uniqBy from 'lodash/uniqBy'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../services/logger'
import { getAutomationsById } from '../../graphql/custom-queries'
import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'
import { notificationStore } from '../../context/notificationsContext/store'
import { openSupportChat } from '../../util/open-support-chat'

import { IAutomationSeniorityEntitlementForm } from '@vacationtracker/shared/types/automations'
import { IGetLocationsShort } from '../../types/locations'
import { IGetAutomationsByIdData, ILeaveTypeListShort, ISenitorityAutomation } from '../../types/custom-queries'
import { FrontendUrls } from '../../types/urls'
import { AddonsStatus, AddonsStatusEnum } from '@vacationtracker/shared/types/addons'
import { DEFAULT_WORKING_HOURS_IN_DAY } from '@vacationtracker/shared/data/app-parameters'
import { QuotaUnits } from '@vacationtracker/shared/types/leave-policy'

const { Text } = Typography
const { Option } = Select

interface ISeniorityEntitlementForm {
  locations: IGetLocationsShort[]
  optionLocations: IGetLocationsShort[]
  leaveTypes: ILeaveTypeListShort[]
  backToAddons: () => void
  automationId?: string
  hourlyLeaveAccounting: boolean
  amIAdmin: boolean
  status?: AddonsStatus
}

const SeniorityEntitlementForm = ({
  locations,
  optionLocations,
  leaveTypes,
  backToAddons,
  automationId,
  hourlyLeaveAccounting,
  amIAdmin,
  status,
}: ISeniorityEntitlementForm) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { modal, notification } = App.useApp()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const defaultSeniorityAwardedDays = 2
  const defaultSeniorityPeriodYears = 5

  const [applyToAllUsers, setApplyToAllUsers] = useState(optionLocations.length === locations.length ? true : false)
  const [isLoading, setIsLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [showPeriodError, setShowPeriodError] = useState(false)
  const [showWarningStartDate, setShowWarningStartDate] = useState(false)
  const [automationSetInHours, setAutomationSetInHours] = useState(hourlyLeaveAccounting)
  const [addon, setAddon] = useState<IAutomationSeniorityEntitlementForm>({
    automationId: '',
    name: '',
    leaveTypeId: '',
    calculateFrom: 'TODAY',
    awardSeniorityEntitlementDaysOn: 'USER_WORK_ANNIVERSARY',
    applyToAllUsers: optionLocations.length === locations.length ? true : false,
    isActive: true,
    prorate: false,
    locations: [],
    teams: [],
    labels: [],
    periods: [
      { 
        amount: defaultSeniorityAwardedDays * (automationSetInHours ? DEFAULT_WORKING_HOURS_IN_DAY : 1),
        units: automationSetInHours ? 'hours': 'days' ,
        years: defaultSeniorityPeriodYears,
      },
    ],
  })
  const [automationPeriods, setAutomationPeriods] = useState<IAutomationSeniorityEntitlementForm['periods']>([
    { 
      amount: defaultSeniorityAwardedDays * (automationSetInHours ? DEFAULT_WORKING_HOURS_IN_DAY : 1),
      units: automationSetInHours ? 'hours': 'days' ,
      years: defaultSeniorityPeriodYears,
    },
  ])
  const [startSeniorityEntitlement] = useState([
    { value: 'TODAY', name: formatMessage({ id: 'app.today'}) },
    { value: 'USER_EMPLOYMENT_DATE', name: formatMessage({ id: 'components.locationForm.userYear'})},
  ])
  const [awardSeniorityEntitlementDaysOn] = useState([
    { value: 'USER_WORK_ANNIVERSARY', name: formatMessage({ id: 'automationsForm.seniorityEntitlement.employeeStartDate'}) },
    { value: 'BEGINNING_OF_THE_FISCAL_YEAR', name: formatMessage({ id: 'automationsForm.seniorityEntitlement.beginningOfTheFiscalYear'})},
  ])
  const [disableProrate, setDisableProrate] = useState(false)
  const [disableAwardSeniorityEntitlement, setDisableAwardSeniorityEntitlement] = useState(false)
  const [getAutomationsByIdQuery] = useManualQuery<IGetAutomationsByIdData, { id: string }>(getAutomationsById)

  useEffect(() => {
    if (automationId) {
      fetchAddons(automationId)
    }
  }, [automationId])

  const fetchAddons = async (id) => {
    try {
      const response = await getAutomationsByIdQuery({ variables: { id }})
      if (!response.data || response.error) throw response.error
      const automation = response.data.getAutomation as ISenitorityAutomation
      setAutomationSetInHours(automation.periods[0].units === 'hours')
      const applyToAllUsers = automation?.locations.length === locations.length
      form.setFieldsValue({ applyToAllUsers })
      form.setFieldsValue({ leaveTypeId: automation.leaveTypeId })
      setApplyToAllUsers(applyToAllUsers)
      
      setAddon({
        automationId: automation.id,
        name: automation.name,
        leaveTypeId: automation.leaveTypeId,
        calculateFrom: automation?.calculateFrom || 'TODAY',
        awardSeniorityEntitlementDaysOn: automation?.awardSeniorityEntitlementDaysOn || 'USER_WORK_ANNIVERSARY',
        applyToAllUsers: applyToAllUsers,
        isActive: automation.isActive,
        locations: automation.locations,
        teams: automation.teams,
        labels: automation.labels,
        periods: automation.periods,
        prorate: automation.prorate,
      } as IAutomationSeniorityEntitlementForm)

      setAutomationPeriods(automation.periods)

      setIsLoading(false)
    } catch (err) {
      logger.error('ERROR FETCH DATA', err)
    }
  }

  const showConfirm = () => {
    if(showWarningStartDate) {
      modal.confirm({
        title: formatMessage({ id: 'components.seniorityEntitlement.confirmTitle' }),
        icon: <ExclamationCircleOutlined />,
        content: formatMessage({ id: 'components.seniorityEntitlement.confirmDescription' }),
        width: 600,
        onOk() {
          onFinish()
        },
      })
    } else {
      onFinish()
    }
  }

  const onFinish = () => {
    if (status === AddonsStatusEnum.CANCELED || !amIAdmin) return

    form
      .validateFields()
      .then(async (values) => {
        setBtnLoading(true)
        let response
        try {
          const data = {
            name: values.name,
            leaveTypeId: values.leaveTypeId,
            calculateFrom: values.calculateFrom,
            periods: values.periods,
            locations: values.locations ? [values.locations] : locations.map(location => location.id),
            prorate: values.prorate,
            awardSeniorityEntitlementDaysOn: values.awardSeniorityEntitlementDaysOn,
          }

          const isExistSameYear = uniqBy(data.periods as IAutomationSeniorityEntitlementForm['periods'], 'years').length !== data.periods.length
          if (isExistSameYear) {
            throw new Error('components.seniorityEntitlement.sameYearError')
          }

          const isSortedDataPeriodsByYear = data.periods.every((period, index) => {
            if(index === 0) return true
            return period.years > data.periods[index - 1].years
          })
          if (!isSortedDataPeriodsByYear) {
            throw new Error('components.seniorityEntitlement.notSortedYearInPeriodError')
          }

          if(!automationId) {
            response = await Api.post('/core/event', {
              eventType: 'SENIORITY_ENTITLEMENT_CREATED',
              eventGroup: 'AUTOMATION',
              ...data,
            })
          } else {
            response = await Api.post('/core/event', {
              eventType: 'SENIORITY_ENTITLEMENT_UPDATED',
              eventGroup: 'AUTOMATION',
              automationId,
              ...data,
              isActive: true,
            })
          }

          notification.open({
            key: response.correlationId,
            message: formatMessage({ id: automationId ? 'automations.seniorityEntitlement.updateInProgress' : 'automations.seniorityEntitlement.createInProgress' }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })
          setActionNotifications([ ...actionNotifications, response.correlationId ])

          setTimeout(() => {
            // It's not necessary, but it looks nicer if it waits a bit because in 90% of cases automation creation will be completed by then, 
            // and it will immediately appear in the view section.
            history.push(`${FrontendUrls.addons}/seniority-entitlement/view`)
          }, 700)
        } catch (error) {
          setBtnLoading(false)
          if (error.response?.data?.message) {
            notification.error({
              message: formatMessage({ id: 'error.generic' }),
              description: formatMessage({ id: error.response.data.message }),
              duration: 0,
            })
          } else if (typeof error?.message === 'string') {
            notification.error({
              message: formatMessage({ id: 'error.generic' }),
              description: formatMessage({ id: error.message }),
              duration: 0,
            })
          } else {
            const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

            notification.error({
              message: formatMessage({ id: 'error.generic' }),
              description,
              duration: 0,
            })
          }
        }
      })
      .catch(info => {
        logger.warning('Validate Failed:', info)
        setBtnLoading(false)
        setShowPeriodError(true)
      })
  }

  const handleApplyToAllUsers = () => {
    setApplyToAllUsers(!applyToAllUsers)
  }

  const layout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 11 }, lg: { span: 11 }, xl: { span: 7 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 13 }, lg: { span: 13 }, xl: { span: 12 } },
  }

  const periods = {
    labelCol: { xs: { span: 0 }, sm: { span: 0 }, md: { span: 3 }, lg: { span: 4 }, xl: { span: 5 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 21 }, lg: { span: 20 }, xl: { span: 19 } },
  }

  const tailLayout = {
    wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 }, md: { span: 12, offset: 7 }, lg: { span: 12, offset: 7 } },
  }

  const validatePeriods = (periods) => {
    if(periods[periods.length - 1].amount === undefined || periods[periods.length - 1].years === undefined) {
      setShowPeriodError(true)
    }
  }

  const onChangePeriodYears = (value, index) => {
    const newAutomationPeriods = automationPeriods.map((period, i) =>
      i === index ? { ...period, years: value } : period
    )
    setAutomationPeriods(newAutomationPeriods)
  }

  const onChangePeriod = (value, index) => {
    const newAutomationPeriods = automationPeriods.map((period, i) =>
      i === index ? { ...period, amount: value, units: automationSetInHours ? 'hours':'days' as QuotaUnits } : period
    )
    setAutomationPeriods(newAutomationPeriods)
  }

  const onChangeStartDate = (value) => {
    if (value === 'USER_EMPLOYMENT_DATE') {
      setShowWarningStartDate(true)
    } else {
      setShowWarningStartDate(false)
    }
  }

  const onChangeLocation = (locationId) => {
    const location = locations.find(location => location.id === locationId)
    if (location?.resetQuotas === 'USER_YEAR') {
      form.setFieldsValue({ awardSeniorityEntitlementDaysOn: 'USER_WORK_ANNIVERSARY' })
      setDisableAwardSeniorityEntitlement(true)
      setDisableProrate(true)
      form.setFieldsValue({ prorate: false })
    } else {
      setDisableAwardSeniorityEntitlement(false)
      setDisableProrate(false)
    }

  }

  const onChangeAwardDaysOn = (value) => {
    if (value === 'BEGINNING_OF_THE_FISCAL_YEAR') {
      setDisableProrate(true)
      form.setFieldsValue({ prorate: false })
    } else {
      setDisableProrate(false)
    }
  }

  const openCrisp = (e) => {
    e.preventDefault()
    openSupportChat()
  }

  let minNumber = 1
  const currentPeriods = form.getFieldValue('periods')
  if (currentPeriods?.length > 1) {
    minNumber = currentPeriods[currentPeriods.length - 1].years as number
  } else {
    minNumber = 1
  }

  return (
    <>
      {(isLoading && automationId) ?
        <CircularProgress /> :
        <>
          {optionLocations.length === 0 &&
            <Alert
              message={<IntlMessages id="components.seniorityEntitlement.noLocations"/>}
              type="warning"
              style={{ marginBottom: 20 }}
            />
          }
          <div style={{ marginBottom: 20 }}>
            <Text> <IntlMessages id="choiceAutomations.seniorityEntitlementInfo" values={{ link: (...chunks) => <a onClick={openCrisp}>{chunks}</a> }}/></Text>
          </div>
          <Form
            layout="horizontal"
            form={form}
            name="automationsForm"
            initialValues={addon}
            onFinish={showConfirm}
            scrollToFirstError
            disabled={(Boolean(automationId) || optionLocations.length === 0) || status === AddonsStatusEnum.CANCELED || !amIAdmin}
            className="automation-seniority-entitlement-form"
            onFinishFailed={(error) => {
              validatePeriods(error.values.periods)
            }}
            {...layout}
          >
            <Form.Item
              label={<IntlMessages id="app.name" />}
              name="name"
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <Input style={{ maxWidth: 300 }}/>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="automationsForm.applyToAllUsers" />}
              tooltip={<IntlMessages id="automationsForm.seniorityEntitlement.applyToAllUsersTooltip" />}
              name="applyToAllUsers"
            >
              <Radio.Group onChange={handleApplyToAllUsers} disabled={optionLocations.length === locations.length ? false : true}>
                <Radio.Button value={true}><IntlMessages id="app.yes" /></Radio.Button>
                <Radio.Button value={false}><IntlMessages id="app.no" /></Radio.Button>
              </Radio.Group>
            </Form.Item>

            {!applyToAllUsers &&
              <Form.Item
                label={<IntlMessages id="app.location" />}
                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                name="locations"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={<IntlMessages id="app.pleaseSelect" />}
                  onChange={onChangeLocation}
                >
                  {(automationId ? locations : optionLocations).map((location) => (
                    <Option value={location.id} key={location.id}>
                      {location.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            <Form.Item
              label={<IntlMessages id="app.leaveType" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              name="leaveTypeId"
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="app.pleaseSelect" />}
              >
                {leaveTypes.map((leaveType) => (
                  <Option value={leaveType.id} key={leaveType.id}>
                    {leaveType.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              name="calculateFrom"
              extra={showWarningStartDate ?
                <span style={{ color: 'red', whiteSpace: 'break-spaces'}}>
                  <WarningOutlined /> <IntlMessages id="components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo" />
                </span> : ''
              }
            >
              <Select
                allowClear
                onChange={onChangeStartDate}
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="app.pleaseSelect" />}
              >
                {startSeniorityEntitlement.map((item) => (
                  <Option value={item.value} key={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            
            <Form.Item 
              label={<IntlMessages id="components.seniorityEntitlement.addDaysFrom" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              name="awardSeniorityEntitlementDaysOn"
            >
              <Select
                allowClear
                onChange={onChangeAwardDaysOn}
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="app.pleaseSelect" />}
                disabled={disableAwardSeniorityEntitlement || Boolean(automationId) || optionLocations.length === 0}
              >
                {awardSeniorityEntitlementDaysOn.map((item) => (
                  <Option value={item.value} key={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="components.seniorityEntitlement.prorateForTheFirstYear" />}
              tooltip={<IntlMessages id="components.seniorityEntitlement.prorateForTheFirstYearTooltip" />}
              name="prorate"
            >
              <Radio.Group disabled={disableProrate || Boolean(automationId)|| optionLocations.length === 0}>
                <Radio.Button value={true}><IntlMessages id="app.yes" /></Radio.Button>
                <Radio.Button value={false}><IntlMessages id="app.no" /></Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Row className="ant-form-item">
              <Col {...periods.labelCol}></Col>
              <Col {...periods.wrapperCol}>
                <Form.List name="periods">
                  {(fields, { remove }) => {
                    return (
                      <>
                        {fields.map((field, index) => {
                          return (
                            <Space key={field.key} style={{ marginBottom: 15, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                              <IntlMessages id="app.add" />
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                }
                              >
                                {() => (
                                  <Form.Item
                                    noStyle
                                    name={[field.name, 'amount']}
                                    rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                                  >
                                    <InputNumber
                                      min={1 as number}
                                      max={automationSetInHours ? 2920 : 365 as number}
                                      width={70}
                                      step={1}
                                      parser={value => Math.round(Number(value)) as number}
                                      onChange={(value) => onChangePeriod(value, index)}
                                    />
                                  </Form.Item>
                                )}
                              </Form.Item>
                              {automationSetInHours ?
                                <IntlMessages id="components.seniorityEntitlement.hoursAfter" /> :
                                <IntlMessages id="components.seniorityEntitlement.daysAfter" />
                              }
                              <Form.Item
                                noStyle
                                name={[field.name, 'years']}
                                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                              >
                                <InputNumber
                                  defaultValue={minNumber}
                                  min={minNumber}
                                  max={100}
                                  width={70}
                                  step={1}
                                  parser={value => Math.round(Number(value) || minNumber)}
                                  onChange={(value) => onChangePeriodYears(value, index)}
                                  disabled={fields.length - 1 !== index || Boolean(automationId) || optionLocations.length === 0}
                                />
                              </Form.Item>
                              <IntlMessages id="components.seniorityEntitlement.yearsInTheCOmpany" />

                              {fields.length > 1 && !((Boolean(automationId) || optionLocations.length === 0) || status === AddonsStatusEnum.CANCELED) &&
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name)
                                    const periods = form.getFieldValue('periods')
                                    setAutomationPeriods(periods as IAutomationSeniorityEntitlementForm['periods'])
                                    const lastPeriod = periods[periods.length - 2]
                                    minNumber = lastPeriod?.years ? lastPeriod.years as number + 1 : 1
                                    setShowPeriodError(false)
                                  }}
                                  style={{ color: 'red' }}
                                />
                              }
                              {(fields.length - 1 === index && fields.length < 20) && 
                               !((Boolean(automationId) || optionLocations.length === 0) || status === AddonsStatusEnum.CANCELED) &&
                                <PlusCircleOutlined
                                  onClick={() => {
                                    const periods = form.getFieldValue('periods')
                                    const lastPeriod = periods[periods.length - 1]
                                    if (!lastPeriod || lastPeriod.amount === undefined || lastPeriod.years === undefined) {
                                      setShowPeriodError(true)
                                      return
                                    }
                                    minNumber = lastPeriod.years as number + 1
                                    
                                    const newPeriods = [
                                      ...periods, 
                                      {
                                        years: minNumber, 
                                        amount: 1 * (automationSetInHours ? DEFAULT_WORKING_HOURS_IN_DAY : 1), 
                                        units: automationSetInHours ? 'hours': 'days',
                                      },
                                    ]
                                    setAutomationPeriods(newPeriods)
                                    form.setFieldsValue({ periods: newPeriods })
                                    setShowPeriodError(false)
                                  }}
                                  style={{ color: 'green'}}
                                />
                              }
                            </Space>
                          )
                        })}
                      </>
                    )
                  }}
                </Form.List>
                {showPeriodError &&
                  <Text type="danger"><br/><IntlMessages id="components.seniorityEntitlement.allFiledsAreRequired" /></Text>
                }
              </Col>
            </Row>
            <Row>
              <Col xs="24">
                <Alert
                  style={{ marginBottom: 20 }}
                  message={
                    <>
                      <IntlMessages key="prefix" id="components.seniorityEntitlement.summaryInfoPrefix" />
                      {automationPeriods[0] &&
                        <IntlMessages
                          key="fistRow"
                          id="components.seniorityEntitlement.summeryInfoFirstRow"
                          values={{
                            amount: automationPeriods[0].amount,
                            year: automationPeriods[0].years,
                            showHour: (...chunks) => automationSetInHours ? <>{chunks}</> : '',
                            showDays: (...chunks) => !automationSetInHours ? <>{chunks}</> : '',
                          }}
                        />
                      }
                      {automationPeriods.length > 1 && automationPeriods.map((period, index) => {
                        if (index === 0) return (<span key="span"></span>)
                        return (
                          <IntlMessages
                            key={`other-${index}`}
                            id="components.seniorityEntitlement.summeryInfoMoreDays"
                            values={{
                              amount: period.amount,
                              year: period.years,
                              showHour: (...chunks) => automationSetInHours ? <>{chunks}</> : '',
                              showDays: (...chunks) => !automationSetInHours ? <>{chunks}</> : '',
                            }}
                          />
                        )
                      })}
                      <IntlMessages
                        key={'suffix'}
                        id="components.seniorityEntitlement.summaryInfoSuffix"
                        values={{
                          amount: automationPeriods.reduce((acc, period) => acc + period.amount, 0),
                          showHour: (...chunks) => automationSetInHours ? <>{chunks}</> : '',
                          showDays: (...chunks) => !automationSetInHours ? <>{chunks}</> : '',
                        }}
                      />
                    </>
                  }
                />
              </Col>
            </Row>
            <Form.Item {...tailLayout}>
              <div>
                <Button type="default" style={{ marginRight: 10 }} onClick={backToAddons}>
                  <IntlMessages id="app.cancel" />
                </Button>
                <Button type="primary"
                  loading={btnLoading}
                  htmlType="submit"
                >
                  {automationId ? <IntlMessages id="app.update" /> : <IntlMessages id="app.create" />}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <IntlMessages 
            id="automations.stepByStepHelpDesk" 
            values={{
              automationName: () => <IntlMessages id="automations.SENIORITY_ENTITLEMENT" />,
              link: (...chunks) => 
                <a 
                  href="https://vacationtracker.crisp.help/en/article/how-do-i-set-up-seniority-based-entitlement-sbe-automation-1mmz4s1/" 
                  target="_blank" 
                  id="helpdesk" 
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>,
            }}
          />
          {Boolean(automationId) &&
            <Alert
              message={<IntlMessages id="components.seniorityEntitlement.disableEditInfo" values={{ link: (...chunks) => <a onClick={openCrisp}>{chunks}</a> }}/>}
              type="warning"
            />
          }
        </>
      }
    </>
  )
}
export default SeniorityEntitlementForm

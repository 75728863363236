import React, { useState } from 'react'
import { Card, Typography, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import {
  format,
  addYears,
  isAfter,
  isBefore,
  isEqual
} from 'date-fns'

import QuotaBox from '../quota-box'
import { DisplayLeaveInDaysAndHours } from '../display-leave-in-days-and-hours'
import FormattedDate from '../formatted-date'
import RolloverExpiry from './rollover-expiry'
import { AccruedTooltip } from './accrued-tooltip'

import { AccrualTypeEnum, IQuotaAmount } from '@vacationtracker/shared/types/leave-policy'
import { LeaveDisplayFormatEnum } from '../../types/display-in-days-hours'
import { IUserLeaveQuotasCompact } from './types'
import { ILeaveTypeResult } from '../../types/calculations'
import { computeDaysAndHours, getQuotaInUnitsIfSet } from '../../functions/get-days-or-hours'
import { deductFromQuota } from '../../functions/calculation-shared'
import { getUserWorkingHoursPerDay } from '../../functions/work-week'

const { Text, Paragraph } = Typography

const UserLeaveQuotasCompact = ({
  IntlMessages,
  location,
  userName,
  setActiveTab,
  hourlyLeaveAccounting,
  locale,
  currentQuota,
  selectedLeaveType,
  selectedLeavePolicy,
  requested = 0,
  partDayLeaveTotalHours,
  title,
  workingHours,
}: IUserLeaveQuotasCompact): React.ReactElement => {
  const { formatMessage } = useIntl()
  const [isAccrualInfoVisible, setIsAccrualInfoVisible] = useState(false)

  const handleGoToUserLogPage = () => {
    setIsAccrualInfoVisible(false)
    if (setActiveTab) {
      setActiveTab({key: 'logs'})
    }
  }

  const getExpiredRolloverFooter = (rolloverExpiryDate: string) => {
    if (rolloverExpiryDate === '') {
      return ''
    }

    let isExpired = false
    if (isAfter(new Date(), new Date(rolloverExpiryDate)) && !isEqual(new Date(), new Date(rolloverExpiryDate))) {
      isExpired = true
    }

    if (!isExpired) {
      return ''
    }

    return (
      <Paragraph>
        <IntlMessages id="components.userLeaveQuotas.expiredDateInfo" values={{
          date: <FormattedDate value={rolloverExpiryDate?.slice(0,10)} format="MMMM Do YYYY." locale={locale} />,
        }}/>
      </Paragraph>
    )
  }

  const getQuotaTitle = (): JSX.Element => {
    if (selectedLeaveType?.accrualType !== AccrualTypeEnum.none && selectedLeaveType?.firstEarningDate) {
      return <>
        {hourlyLeaveAccounting ? <IntlMessages id="components.userLeaveQuotas.accruedHours" /> : <IntlMessages id="components.userLeaveQuotas.accruedDays" />}
        <Tooltip
          open={isAccrualInfoVisible}
          title={
            <AccruedTooltip
              selectedLeaveType={selectedLeaveType}
              userName={userName}
              policyFirstEarningDate={selectedLeaveType?.firstEarningDate as string}
              handleGoToUserLogPage={handleGoToUserLogPage}
              formatMessage={formatMessage}
              IntlMessages={IntlMessages}
              yearStartDay={location.yearStartDay as number}
              accrualPeriodStart={selectedLeaveType?.accrualPeriodStart as string}
              isCurrentYearSelected
              hourlyLeaveAccounting={hourlyLeaveAccounting}
              userLocale={locale}
              capHit={Boolean(selectedLeaveType?.capHit)}
            />
          }
          onOpenChange={() => setIsAccrualInfoVisible(!isAccrualInfoVisible)}
        >
          <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
        </Tooltip>
      </>
    }
    else {
      return <IntlMessages id="components.userLeaveQuotas.quota" />
    }
  }

  const getQuotaFooter = (selectedLeaveType: ILeaveTypeResult): JSX.Element => {
    if (selectedLeaveType?.accrualType !== AccrualTypeEnum.none) {
      return <Paragraph data-testid="quota-default-days-per-year">
        <IntlMessages id="app.of" />&nbsp;
        <DisplayLeaveInDaysAndHours
          value={selectedLeaveType?.quota}
          format={LeaveDisplayFormatEnum.short}
          numberOfWorkingHoursPerDay={getUserWorkingHoursPerDay(workingHours)}
        />
      </Paragraph>
    } else {
      return <></>
    }
  }

  const calculateRemainingQuota = (): IQuotaAmount => {
    return deductFromQuota(selectedLeaveType?.quotas?.remaining, getRequestedDaysAmount().raw.hours, workingHours)
  }

  const getRequestedDaysAmount = (): IQuotaAmount => {
    const requestedHours = partDayLeaveTotalHours > 0 ? partDayLeaveTotalHours : requested * getUserWorkingHoursPerDay(workingHours)
    const resultRequestedDays = computeDaysAndHours(requestedHours, getUserWorkingHoursPerDay(workingHours))
    return {
      raw: {days: requested, hours: requestedHours},
      days: resultRequestedDays.days,
      hours: resultRequestedDays.hours,
      minutes: 0,
      isNegative: false,
    }
  }

  return (
    <div>
      <Card
        title={title}
        styles={{ body: { padding: 0, paddingTop: 10 } }}
        className="user-leave-quotas user-leave-quotas-compact"
        bordered={false}
      >
        <IntlMessages
          id="leaveRequest.simpleQuota.info"
          values={{
            leaveTypeName: selectedLeaveType.leaveTypeName,
            start: format(new Date(typeof currentQuota.yearStart === 'string' ? currentQuota.yearStart.split('T')[0] : currentQuota.yearStart), 'PPP'),
            end: format(new Date(typeof currentQuota.yearEnd === 'string' ? currentQuota.yearEnd.split('T')[0] : currentQuota.yearEnd), 'PPP'),
          }}
        />
        {selectedLeaveType &&
          <>
            <div className="quota-box-container">
              {!selectedLeaveType?.hasUnlimitedDays &&
                <>
                  {(selectedLeaveType?.maxRolloverDays !== 0 || selectedLeaveType?.rolloverFromPreviousYear !== 0) &&
                    <>
                      <QuotaBox
                        title={<IntlMessages id="app.broughtForward" />}
                        dataTestId={'quota-brought-forward-days'}
                        value={selectedLeaveType?.quotas?.rolloverFromPreviousYear}
                        valueUnits={hourlyLeaveAccounting ? 'hours' : 'days'}
                        footer={<RolloverExpiry
                          IntlMessages={IntlMessages}
                          locale={locale}
                          isCurrentYearSelected
                          yearEndDate={typeof currentQuota.yearEnd === 'string' ? new Date(currentQuota.yearEnd) : currentQuota.yearEnd}
                          rolloverExpiryDate={selectedLeaveType.rolloverExpiryDate}
                          isNextYearSelected={false}
                          maxRolloverDays={selectedLeaveType?.maxRolloverDays || 0}
                        />}
                        leaveTypeName={selectedLeaveType.leaveTypeName}
                        locationName={location.name}
                        broughtForwardLimit={getQuotaInUnitsIfSet(
                          selectedLeavePolicy?.maxRolloverDays as number,
                          selectedLeavePolicy?.maxRolloverHours as number,
                          hourlyLeaveAccounting,
                          selectedLeavePolicy?.quotaSetIn
                        )}
                      />
                      <div className="quota-formula">
                        <Text type="secondary" strong>+</Text>
                      </div>
                    </>
                  }
                </>
              }
              <QuotaBox
                title={getQuotaTitle()}
                dataTestId={'quota-value'}
                value={selectedLeaveType?.quotas?.earnedWithoutRollover}
                valueUnits={hourlyLeaveAccounting ? 'hours' : 'days'}
                additionalQuotaDetails={{
                  baseQuota: selectedLeaveType.baseQuota,
                  seniorityEntitlementDaysForYear: selectedLeaveType?.seniorityEntitlementDaysForYear,
                  hasUnlimitedDays: selectedLeaveType.hasUnlimitedDays,
                  entitlementByRoleDaysForYear: selectedLeaveType?.entitlementByRoleDaysForYear,
                  toilDays: selectedLeaveType?.toilDays,
                  toilDaysLapsed: selectedLeaveType?.toilDaysLapsed,
                }}
                footer={getQuotaFooter(selectedLeaveType)}
                doNotShowDisplayInDaysOrHoursTooltip={Boolean(selectedLeaveType?.accrualType !== AccrualTypeEnum.none && selectedLeaveType?.firstEarningDate)}
                workingHours={currentQuota.workingHours}
                broughtForwardLimit={getQuotaInUnitsIfSet(
                  selectedLeavePolicy?.maxRolloverDays as number,
                  selectedLeavePolicy?.maxRolloverHours as number,
                  hourlyLeaveAccounting,
                  selectedLeavePolicy?.quotaSetIn
                ) || 0}
              />
              {/* If rollover never expired in that case we return an empty string or we add 1000 years,
                  that's why we check is rollover Expiry Date emptry string and is rolloverExpiryDate is before the current year plus 5 year */}
              {selectedLeaveType?.rolloverExpiryDate !== '' && isBefore(new Date(selectedLeaveType?.rolloverExpiryDate), addYears(new Date(), 5)) &&
                <>
                  <div className="quota-formula" data-testid="formula">
                    <Text type="secondary" strong>-</Text>
                  </div>
                  <QuotaBox
                    title={<IntlMessages id="app.expired" />}
                    dataTestId={'quota-expired'}
                    value={selectedLeaveType?.quotas?.expired}
                    valueUnits={hourlyLeaveAccounting ? 'hours' : 'days'}
                    footer={getExpiredRolloverFooter(selectedLeaveType.rolloverExpiryDate)}
                    locale={locale}
                    expirationDate={selectedLeaveType.rolloverExpiryDate}
                  />
                </>
              }
              <div className="quota-formula">
                <Text type="secondary" strong>-</Text>
              </div>
              <QuotaBox
                title={<IntlMessages id="components.userLeaveQuotas.used" />}
                dataTestId={'quota-used-days'}
                value={selectedLeaveType?.quotas?.used}
                scheduledDays={selectedLeaveType?.quotas?.scheduled}
                footer={''}
                valueUnits={hourlyLeaveAccounting ? 'hours' : 'days'}
                leaveTypeName={selectedLeaveType.leaveTypeName}
              />
              {requested > 0 && 
                <>
                  <div className="quota-formula">
                    <Text type="secondary" strong>-</Text>
                  </div>
                  <QuotaBox
                    title={<IntlMessages id="components.userLeaveQuotas.requested" />}
                    dataTestId={'quota-requested'}
                    value={getRequestedDaysAmount()}
                    valueUnits={hourlyLeaveAccounting ? 'hours' : 'days'}
                    footer={''}
                    hasRequestedDays={requested > 0}
                  />
                </>
              }
              <div className="quota-formula">
                <Text type="secondary" strong>=</Text>
              </div>
              <QuotaBox
                title={<IntlMessages id={requested > 0 ? 'components.userLeaveQuotas.remainingAfterRequest' : 'components.userLeaveQuotas.remaining'} />}
                dataTestId={'quota-remaining-days'}
                value={calculateRemainingQuota()}
                valueUnits={hourlyLeaveAccounting ? 'hours' : 'days'}
                footer={''}
                hasRequestedDays={requested > 0}
                additionalQuotaDetails={{
                  hasUnlimitedDays: selectedLeaveType.hasUnlimitedDays,
                }}
                broughtForwardLimit={getQuotaInUnitsIfSet(
                  selectedLeavePolicy?.maxRolloverDays as number,
                  selectedLeavePolicy?.maxRolloverHours as number,
                  hourlyLeaveAccounting,
                  selectedLeavePolicy?.quotaSetIn
                )}
              />
            </div>
          </>
        }
      </Card>
    </div>
  )
}

export default UserLeaveQuotasCompact

import React from 'react'
import { Modal, Button, Typography } from 'antd'
import { capitalize } from 'lodash'

import IntlMessages from '../../util/IntlMessages'
import { allPlatforms } from '../../constants'
import { openSupportChat } from '../../util/open-support-chat'
import { sendChatMessage } from '../../util/set-crisp-session-info'

const { Paragraph } = Typography
interface Props {
  visible: boolean
  onCancel: () => void
  platform: typeof allPlatforms[number]
}

export const PlatformSwitchModal: React.FC<Props> = ({ visible, onCancel, platform}: Props) => {

  const sendSupportMessage = (platform: Props['platform']) => {
    openSupportChat()
    sendChatMessage(`I want to switch to ${capitalize(platform)}`)
    onCancel()
  }

  return (
    <Modal
      title={<IntlMessages id="components.platformSwitchModal.title" />}
      open={visible}
      footer={null}
      onCancel={onCancel}
    >

      <Paragraph><IntlMessages id="components.platformSwitchModal.content" values={{ platform: capitalize(platform) }} /></Paragraph>


      <Button type="primary" onClick={() => sendSupportMessage(platform)}>
        <IntlMessages id="app.contactSupport" />
      </Button>

    </Modal>
  )
}

export default PlatformSwitchModal

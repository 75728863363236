import { LoadingOutlined } from '@ant-design/icons'
import Api from '@vacationtracker/shared/services/api'
import { signout } from '@vacationtracker/shared/services/auth'
import { Alert, App, Button, Col, Input, Modal, Radio, Row, Select, Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { RadioChangeEvent } from 'antd/lib/radio'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useManualQuery } from 'graphql-hooks'
import { capitalize } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Redirect, useHistory, useLocation } from 'react-router'
import CircularProgress from '../../components/circular-progress'
import { notificationStore } from '../../context/notificationsContext/store'
import { getAllUserListForMigrationPaginated } from '../../graphql/custom-queries'
import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import * as logger from '../../services/logger'
import { logoutAction } from '../../store/configure'
import { useAppDispatch } from '../../store/hooks'
import { IMicrosoftUser } from '../../types/microsoft'
import { FrontendUrls } from '../../types/urls'
import { IGetAllUserListForMigrationPaginatedData, UserStatus } from '../../types/users'
import IntlMessages from '../../util/IntlMessages'
import { ICompanyPlatformChangedEventBody } from './types'
import { fetchMicrosoftUsers } from './util'

const { Title, Text } = Typography

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID as string)

interface StorageWithTTL {
  value: Record<string, any>
  timestamp: number
  ttl: number
}

type IGetUserListForMigration = {
  id: string
  name: string
  status: UserStatus
  email: string
  isAdmin: boolean
  initialUser: boolean
}

const STORAGE_TTL = 24 * 60 * 60 * 1000

const saveUserMappingsToStorage = (mappings: Record<string, any>) => {
  const storageObject: StorageWithTTL = {
    value: mappings,
    timestamp: new Date().getTime(),
    ttl: STORAGE_TTL,
  }
  localStorage.setItem('userMappings', JSON.stringify(storageObject))
}

const loadUserMappingsFromStorage = () => {
  const savedData = localStorage.getItem('userMappings')
  if (!savedData) return {}

  try {
    const storageObject: StorageWithTTL = JSON.parse(savedData)
    const now = new Date().getTime()

    // Check if data has expired
    if (now - storageObject.timestamp > storageObject.ttl) {
      localStorage.removeItem('userMappings')
      return {}
    }

    return storageObject.value
  } catch (error) {
    localStorage.removeItem('userMappings')
    return {}
  }
}

const normalizeFullName = (name: string) => name.toLowerCase().replace(/\s+/g, '').trim()

const MigrationPage: React.FC = () => {
  const { state } = useLocation()
  const history = useHistory()
  const reduxDispatch = useAppDispatch()
  const { modal, notification } = App.useApp()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [correlationId, setCorrelationId] = useState<string | null>()
  const { formatMessage } = useIntl()
  const [hideInactive, setHideInactive] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [markUnmappedAsDeleted, setMarkUnmappedAsDeleted] = useState(false)
  const [companyUsers, setCompanyUsers] = useState<IGetUserListForMigration[]>([])
  const [companyUsersLoading, setCompanyUsersLoading] = useState(false)
  const [platformUsers, setPlatformUsers] = useState<IMicrosoftUser[]>([])
  const [userMappings, setUserMappings] = useState<Record<string, {
    id: string
    displayName: string | undefined
    mail: string | undefined
    isAdmin: boolean
    initialUser: boolean
  } | null>>(() => loadUserMappingsFromStorage())
  const [searchPlatformUsersText, setSearchPlatformUsersText] = useState('')
  const [, setActiveSelectId] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [unmappedUsers, setUnmappedUsers] = useState(0)
  const [getAllUserListForMigrationPaginatedQuery] = useManualQuery<IGetAllUserListForMigrationPaginatedData, {
    limit: number
    nextToken?: string
  }>(getAllUserListForMigrationPaginated)

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false)
  const [countdown, setCountdown] = useState(15)

  useEffect(() => {
    if (correlationId && !actionNotifications.includes(correlationId) && isSaving) {
      setIsSaving(false)
      setCorrelationId(null)
      setIsLogoutModalVisible(true)
    }
  }, [actionNotifications])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isLogoutModalVisible && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000)
    } else if (countdown === 0) {
      handleLogout()
    }
    return () => clearTimeout(timer)
  }, [isLogoutModalVisible, countdown])

  const handleLogout = async () => {
    await signout(true)
    reduxDispatch(logoutAction)
    history.push(FrontendUrls.signin)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        await Promise.all([
          fetchAllUsersInSystem(),
          fetchUsersFromNewPlatform(),
        ])
      } catch (error) {
        logger.error('Error fetching data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    setUnmappedUsers(Object.values(userMappings).filter(value => value === null).length)
  }, [userMappings])

  useEffect(() => {
    if (platformUsers.length > 0 && companyUsers.length > 0) {
      const newMappings: Record<
      string,
      {
        id: string
        displayName: string | undefined
        mail: string | undefined
        isAdmin: boolean
        initialUser: boolean
      } | null
      > = {}
      companyUsers.forEach(companyUser => {
        const matchedUser = platformUsers.find(
          user => user.mail?.toLowerCase() === companyUser.email.toLowerCase() ||
            user.userPrincipalName?.toLowerCase() === companyUser.email.toLowerCase() ||
            normalizeFullName(user.displayName || '') === normalizeFullName(companyUser.name || '')
        )
        if (matchedUser) {
          newMappings[companyUser.id] = {
            id: matchedUser.id,
            displayName: matchedUser.displayName,
            mail: matchedUser.mail,
            isAdmin: companyUser.isAdmin,
            initialUser: companyUser.initialUser,
          }
        } else {
          newMappings[companyUser.id] = null
        }
      })
      //we want to dsiable overwriting of existing mappings on initial load if they are retrieved from storage

      setUserMappings(prevMappings => {
        return {
          ...newMappings,
          ...prevMappings,
        }
      })
      setUnmappedUsers(Object.values({
        ...newMappings,
        ...userMappings,
      }).filter(value => value === null).length)
    }
  }, [platformUsers, companyUsers])

  useEffect(() => {
    saveUserMappingsToStorage(userMappings)
  }, [userMappings])

  const filteredPlatformUsers = useMemo(() => {
    return platformUsers.filter(user =>
      user.displayName?.toLowerCase().includes(searchPlatformUsersText.toLowerCase()) ||
      user.mail?.toLowerCase().includes(searchPlatformUsersText.toLowerCase()) ||
      user.userPrincipalName?.toLowerCase().includes(searchPlatformUsersText.toLowerCase())
    )
  }, [platformUsers, searchPlatformUsersText])

  const { newPlatform, oldPlatform, tenantId } = state as { newPlatform: string; oldPlatform: string; msUser: IMicrosoftUser; tenantId: string }

  // this needs to reset every time i open select
  const userOptions = useMemo(() => [
    { value: 'unmapped', label: formatMessage({ id: 'company.platformChanged.unmatched' }, { platform: capitalize(newPlatform) }) },
    ...filteredPlatformUsers.map(user => ({ value: user.id, label: user.displayName })),
  ], [filteredPlatformUsers, formatMessage])

  //prevent landing on this page without state
  if (!state) {
    return <Redirect to="/app/settings/general" />
  }


  const fetchAllUsersInSystem = async (nextToken?: string, limit = 200, shortUsers: IGetUserListForMigration[] = []): Promise<void> => {
    setCompanyUsersLoading(true)
    const usersInSystem = await getAllUserListForMigrationPaginatedQuery({ variables: { nextToken, limit } })
    if (!usersInSystem.data || usersInSystem.error) throw usersInSystem.error

    const users: IGetUserListForMigration[] = shortUsers.concat(usersInSystem.data.getAllUserListPaginated.users as IGetUserListForMigration[])

    if (usersInSystem.data.getAllUserListPaginated.nextToken) {
      return await fetchAllUsersInSystem(usersInSystem.data.getAllUserListPaginated.nextToken, limit, users)
    }

    setCompanyUsers(users.filter(user => user.status !== 'DELETED'))
    setCompanyUsersLoading(false)
  }

  const fetchUsersFromNewPlatform = () => {
    if (newPlatform === 'microsoft') {
      const token = sessionStorage.getItem('msTokensAccessToken')
      if (!token) {
        history.push('/app/settings/general')
        return
      }

      fetchMicrosoftUsers(
        msAuth,
        token,
        (users) => setPlatformUsers(users),
        (error) => {
          // Handle error, e.g., show a notification
          logger.error('Failed to fetch Microsoft users:', error)
        }
      )
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleHideInactive = (e: RadioChangeEvent) => {
    setHideInactive(e.target.value === 'yes')
  }

  const handleMarkUnmappedAsDeleted = (e: RadioChangeEvent) => {
    setMarkUnmappedAsDeleted(e.target.value === 'yes')
  }

  const handleSelectChange = (value: string, vtUserId: string) => {
    const newMappings = {
      ...userMappings,
      [vtUserId]: value === 'unmapped' ? null : {
        id: value,
        displayName: platformUsers.find(user => user.id === value)?.displayName,
        mail: platformUsers.find(user => user.id === value)?.userPrincipalName || platformUsers.find(user => user.id === value)?.mail,
        isAdmin: companyUsers.find(user => user.id === vtUserId)?.isAdmin ?? false,
        initialUser: companyUsers.find(user => user.id === vtUserId)?.initialUser ?? false,
      },
    }
    setUserMappings(newMappings)
    saveUserMappingsToStorage(newMappings)
  }

  const handleSelectSearch = (value: string) => {
    setSearchPlatformUsersText(value)
  }

  const handleCancel = () => {
    history.push('/app/settings/general')
  }

  const showConfirmationDialog = () => {
    modal.confirm({
      title: formatMessage({ id: 'company.platformChanged.confirmTitle' }),
      content: (
        <>
          <Paragraph>
            {formatMessage({ id: 'company.platformChanged.confirmDescription' })}
          </Paragraph>
          {markUnmappedAsDeleted && unmappedUsers > 0 && (
            <Alert
              message={formatMessage({ id: 'company.platformChanged.confirmDescription.unmappedUsers.deleted' }, { unmappedUsers, newPlatform: capitalize(newPlatform) })}
              type="warning"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
          {!markUnmappedAsDeleted && unmappedUsers > 0 && (
            <Alert
              message={formatMessage({ id: 'company.platformChanged.confirmDescription.unmappedUsers.unmatched' }, { unmappedUsers, newPlatform: capitalize(newPlatform) })}
              type="warning"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
          <Paragraph strong>
            {formatMessage({ id: 'company.platformChanged.confirmQuestion' })}
          </Paragraph>
        </>
      ),
      onOk: () => startMigrationProcess(),
      okText: formatMessage({ id: 'app.proceed' }),
      cancelText: formatMessage({ id: 'app.cancel' }),
    })
  }

  const startMigrationProcess = () => {
    (async () => {
      setIsSaving(true)
      try {
        const event: ICompanyPlatformChangedEventBody = {
          eventType: 'COMPANY_PLATFORM_CHANGED',
          eventGroup: 'COMPANY',
          newPlatform: newPlatform,
          oldPlatform: oldPlatform,
          userMappings: userMappings,
          organizationId: tenantId,
          deleteUnmapped: markUnmappedAsDeleted,
          token: sessionStorage.getItem('msTokensAccessToken') as string,
        }

        const response = await Api.post('/core/event', {
          ...event,
        })

        notification.open({
          key: response.correlationId,
          message: formatMessage({ id: 'company.platformChanged' }),
          icon: (<LoadingOutlined />),
          duration: 0,
        })
        setActionNotifications([...actionNotifications, response.correlationId])
        setCorrelationId(response.correlationId as string)
        localStorage.removeItem('userMappings')
      } catch (error) {
        logger.error('Error creating event:', error)
        notification.error({
          message: formatMessage({ id: 'error.generic' }),
          description: error.response.data.message,
          duration: 0,
        })
        setIsSaving(false)
      }
    })()
  }

  const handleSave = () => {
    showConfirmationDialog()
  }

  const filteredData = companyUsers.filter(user => {
    const matchesSearch = user.name.toLowerCase().includes(searchText.toLowerCase())
    const matchesStatus = hideInactive ? user.status === 'ACTIVE' : true
    return matchesSearch && matchesStatus
  })

  const columns: ColumnsType<IGetUserListForMigration> = [
    {
      title: <IntlMessages id="company.platformChanged.vacationTrackerUser" />,
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Text>{text}</Text>
      ),
    },
    {
      title: <IntlMessages id="app.email" />,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <IntlMessages id="app.status" />,
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: <IntlMessages id="company.platformChanged.microsoftAccount" />,
      dataIndex: 'microsoftAccount',
      key: 'microsoftAccount',
      render: (text, record) => (
        <Select
          style={{ width: '100%' }}
          value={userMappings[record.id]?.displayName || 'unmapped'}
          options={userOptions}
          onChange={(value) => handleSelectChange(value, record.id)}
          filterOption={false}
          showSearch
          onDropdownVisibleChange={(open) => {
            if (open) {
              setActiveSelectId(record.id)
              setSearchPlatformUsersText('')
            } else {
              setActiveSelectId(null)
            }
          }}
          onSearch={handleSelectSearch}
          key={record.id}
          loading={companyUsersLoading}
        />
      ),
    },
  ]


  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <div style={{ padding: '20px' }}>
      <Title level={3}>
        {
          newPlatform === oldPlatform ?
            <IntlMessages id="company.platformChangedTenant.title" values={{ newPlatform: capitalize(newPlatform) }} /> :
            <IntlMessages id="company.platformChanged.title" values={{ oldPlatform: capitalize(oldPlatform), newPlatform: capitalize(newPlatform) }} />
        }

      </Title>
      <Text><IntlMessages id="company.platformChanged.description" values={{ newPlatform: capitalize(newPlatform) }} /></Text>
      <Row style={{ margin: '20px 0' }} justify="end">
        <Space>
          <Text><IntlMessages id="company.platformChanged.hideInactive" /></Text>
          <Radio.Group onChange={handleHideInactive} value={hideInactive ? 'yes' : 'no'}>
            <Radio.Button value="yes"><IntlMessages id="app.yes" /></Radio.Button>
            <Radio.Button value="no"><IntlMessages id="app.no" /></Radio.Button>
          </Radio.Group>
          <Input placeholder="User name" value={searchText} onChange={handleSearch} style={{ width: 200 }} />
        </Space>
      </Row>
      <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 20 }} rowKey="id" />
      <Row style={{ marginTop: '20px' }}>
        <Col flex={1}>
          <Space>
            <Text><IntlMessages id="company.platformChanged.markUnmappedAsDeleted" /></Text>
            <Radio.Group onChange={handleMarkUnmappedAsDeleted} value={markUnmappedAsDeleted ? 'yes' : 'no'}>
              <Radio.Button value="yes"><IntlMessages id="app.yes" /></Radio.Button>
              <Radio.Button value="no"><IntlMessages id="app.no" /></Radio.Button>
            </Radio.Group>
          </Space>
        </Col>
        <Col>
          <Space>
            <Button onClick={handleCancel}><IntlMessages id="app.cancel" /></Button>
            <Button type="primary" onClick={handleSave} loading={isSaving}>
              <IntlMessages id="app.save" />
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        title="Migration Successful"
        open={isLogoutModalVisible}
        closable={false}
        footer={null}
      >
        <p>You will be logged out in {countdown} seconds.</p>
        <Button onClick={() => void handleLogout()}>{formatMessage({ id: 'app.logoutNow' })}</Button>
      </Modal>
    </div>
  )
}

export default MigrationPage

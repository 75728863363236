import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'
import { App, Breadcrumb, Form, Typography, Modal, Button, Row, Col, Input, Alert } from 'antd'
import { LoadingOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getCompanyBillingInfo } from '../../../graphql/custom-queries'
import * as logger from '../../../services/logger'

import { notificationStore } from '../../../context/notificationsContext/store'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectAuthCompanySlice, setAuthCompanyPlan } from '../../../store/auth-company-slice'
import { PlanPricePerUser } from '../../../util/get-price-plan-bucket-info'
import { selectLocaleSlice } from '../../../store/locale-slice'
import { getDateFnsLocale } from '@vacationtracker/shared/i18n'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import CancelSubscriptionModal from '../../../components/cancel-subscription-modal'
import PricePlanCard from '../../../components/price-plan-card'

import { SubscriptionPlan } from '@vacationtracker/shared/types/company'
import { SubscriptionPeriod, USER_PLAN_LIMIT } from '@vacationtracker/shared/types/billing'
import { IGetCompanyBillingInfo, IGetCompanyBillingInfoData } from '../../../types/company'
import { NUMBER_OF_LOCATIONS_LIMIT, MAX_DEPTH_FOR_NESTED_DEPARMENTS_CORE, NUMBER_OF_TEAMS_LIMIT } from '@vacationtracker/shared/data/app-parameters'
import { buildDepartmentTree, getMaxDepth } from '@vacationtracker/shared/functions/team-tree'
import { FrontendUrls } from '../../../types/urls'
import { openChat, sendChatMessage } from '../../../util/set-crisp-session-info'

interface IBillingSectionProps {
  intlTitleId: string
  intlButtonLabelId: string
  adminCenterLinkArgument: string
}

const { Title } = Typography

const MS_ADMIN_CENTER_LINK = 'https://admin.microsoft.com/Adminportal/Home#'

const goToAdminCenter = (endPoint) =>  window.open(`${MS_ADMIN_CENTER_LINK}${endPoint}`,'_blank')

const billingSections = [
  {
    id: 'billing.info',
    intlTitleId: 'components.billingInfo.title',
    intlButtonLabelId: 'app.edit',
    adminCenterLinkArgument: '/subscriptions',
  },
  {
    id: 'billing.info.payment',
    intlTitleId: 'billing.paymentInformation',
    intlButtonLabelId: 'app.edit',
    adminCenterLinkArgument: '/subscriptions',
  },
  {
    id: 'billing.info.invoices',
    intlTitleId: 'components.billingInvoice.title',
    intlButtonLabelId: 'app.download',
    adminCenterLinkArgument: '/billoverview/invoice-list',
  },
]

const BillingSection = ({
  intlTitleId,
  intlButtonLabelId,
  adminCenterLinkArgument,
}: IBillingSectionProps): ReactElement => {
  const title = <IntlMessages id={intlTitleId} />

  return (
    <>
      <Title level={4}>
        {title} <Button size="large" type="link" onClick={() => goToAdminCenter(adminCenterLinkArgument)}>
          <IntlMessages id={intlButtonLabelId} /> <FormOutlined />
        </Button>
      </Title>

      <Form.Item>
        <IntlMessages id="billing.microsoft.info" values={{
          adminCenterLink: (...chunks) => <a target="_blank" href={`${MS_ADMIN_CENTER_LINK}/subscriptions`} rel="noreferrer">{chunks} </a>,
          labelInfo: title,
        }} />
      </Form.Item>
    </>
  )
}

const MicrosoftBillingPage = () => {
  const { modal, notification } = App.useApp()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const dispatch = useAppDispatch()

  const [isLoadingBillingInfo, setIsLoadingBillingtInfo] = useState(true)
  const [isUpdatingBilling, setIsUpdatingBilling] = useState(false)
  const [currentPlan, setCurrentPlan] = useState<SubscriptionPlan>('Core')
  const [currentPeriod, setCurrentPeriod] = useState<SubscriptionPeriod>('monthly')
  const [newPlan, setNewPlan] = useState<SubscriptionPlan>('Core')
  const [newPeriod, setNewPeriod] = useState<SubscriptionPeriod>('monthly')
  const [billing, setBilling] = useState<IGetCompanyBillingInfo | null>(null)
  const [numberOfLicenses, setNumberOfLicenses] = useState(0)
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = useState(false)
  const [isSwitchPlanModalOpen, setIsSwitchPlanModalOpen] = useState(false)
  const [doNotShowCancelModal, setDoNotShowCancelModal] = useState(false)
  const [autoRenew, setAutoRenew] = useState(true)
  const [disableTeamsLimit, setDisableTeamsLimit] = useState<boolean>(false)
  const [disableLocationsLimit, setDisableLocationsLimit] = useState<boolean>(false)
  const [disableSubdepartmentsLimit, setDisableSubdepartmentsLimit] = useState<boolean>(false)
  const numberOfUsers = billing?.numberOfActiveUsers || 1

  const [ getCompanyBillingInfoQuery ] = useManualQuery<IGetCompanyBillingInfoData>(getCompanyBillingInfo)


  useEffect(() => {
    fetchBilling()
  }, [authCompany?.billing?.quantity, authCompany?.billing?.msPeriodEndDate, authCompany?.billing?.subscriptionPlan])


  const fetchBilling = async () => {
    try {
      const response = await getCompanyBillingInfoQuery()
      if (!response.data || response.error) throw response.error
      const teamTree = buildDepartmentTree(response.data.getTeamList)
      const teamDepth = getMaxDepth(teamTree)
      const subscriptionRes = await Api.get(`/microsoft-billing/get-ms-saas-subscription?subscriptionId=${response?.data?.getCompany?.billing?.msSubscriptionId}`)
      setAutoRenew(subscriptionRes?.autoRenew)
      setDisableLocationsLimit(() => {
        if (response.data?.getCompany.plan === 'Complete' && response.data.getLocationList.length > NUMBER_OF_LOCATIONS_LIMIT) {
          return true
        } else {
          return false
        }
      })
      setDisableTeamsLimit(() => {
        if (response.data?.getCompany.plan === 'Complete' && response.data.getTeamList.length > NUMBER_OF_TEAMS_LIMIT) {
          return true
        } else {
          return false
        }
      })
      setDisableSubdepartmentsLimit(() => {
        const isCompletePlan = response.data?.getCompany.plan === 'Complete'
        return isCompletePlan && teamDepth > MAX_DEPTH_FOR_NESTED_DEPARMENTS_CORE
      })

      dispatch(setAuthCompanyPlan(response.data.getCompany.billing.subscriptionPlan))
      setBilling({
        ...response?.data?.getCompany,
        billing: {
          ...response?.data?.getCompany?.billing,
          msPeriodStartDate: subscriptionRes?.term?.startDate,
          msPeriodEndDate: subscriptionRes?.term?.endDate,
        },
      })
      setCurrentPlan(response?.data?.getCompany?.billing?.subscriptionPlan)
      setNewPlan(response?.data?.getCompany?.billing?.subscriptionPlan)
      setCurrentPeriod(response?.data?.getCompany?.billing?.subscriptionPeriod)
      setNewPeriod(response?.data?.getCompany?.billing?.subscriptionPeriod)
      setNumberOfLicenses(response?.data?.getCompany?.billing?.quantity as number)
      setIsLoadingBillingtInfo(false)
      setIsUpdatingBilling(false)
    } catch (error) {
      setIsLoadingBillingtInfo(false)
      setIsUpdatingBilling(false)
    }

  }

  const handleOnCancelSubscription = async (cancelReason: string, cancelReasonComment?: string, integrationWithHr?: string) => {
    let response
    try {
      response = await Api.post('/core/event', {
        eventType: 'SUBSCRIPTION_CANCELED',
        eventGroup: 'BILLING',
        paymentProcessor: 'microsoft-billing',
        activeUsers: numberOfUsers,
        cancelReason,
        cancelReasonComment,
        integrationWithHr,
      })
      setIsCancelSubscriptionModalOpen(false)
      setDoNotShowCancelModal(true)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'billing.cancelUpdateInProgress' }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
    } catch (error) {
      handleBillingUpdateError(error, response?.correlationId)
    }
  }

  const submitBilling = async () => {
    let response
    try {
      response = await Api.post('/core/event', {
        eventType: 'BILLING_UPDATED',
        eventGroup: 'BILLING',
        paymentProcessor: 'microsoft-billing',
        subscriptionPlan: newPlan,
        subscriptionPeriod: newPeriod,
        quantity: numberOfLicenses,
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'billing.updateInProgress' }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
      setIsUpdatingBilling(false)
    } catch (error) {
      handleBillingUpdateError(error, response?.correlationId)
      setIsUpdatingBilling(false)
      setNumberOfLicenses(billing?.billing?.quantity as number)
      setCurrentPlan(billing?.billing?.subscriptionPlan as SubscriptionPlan)
      setNewPlan(billing?.billing?.subscriptionPlan as SubscriptionPlan)
    }
  }

  const handleBillingUpdateError = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'notifications.error' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'billing.updatedFaild' }),
      description,
      duration: 0,
    })
  }

  const changePlanHandler = (plan: SubscriptionPlan | 'support') => {
    if (plan === 'support' || plan == 'Enterprise') {
      // eslint-disable-next-line max-len
      const text = plan === 'Enterprise' ? 'Hi there! I\'m interested in your Enterprise plan. Tell me more!' : 'Hi there! I am interested in switching from our Core Annual plan to the Complete Monthly plan. Tell me more!'
      openChat()
      sendChatMessage(text)
    } else {
      setNewPlan(plan)
      setIsSwitchPlanModalOpen(!isSwitchPlanModalOpen)
    }
  }

  const cancelPlanChange = () => {
    setNewPlan(currentPlan)
    setIsSwitchPlanModalOpen(false)
  }

  const switchPlan = () => {
    submitBilling()
    setCurrentPlan(newPlan)
    setIsSwitchPlanModalOpen(false)
  }

  const showBuyMoreLicencesConfirmModal = () => {
    const currentNoOfLicenses = billing?.billing?.quantity ?? 0
    const differenceOfLicenses = numberOfLicenses - currentNoOfLicenses
    modal.confirm({
      title: formatMessage({ id: 'app.microsoft.manageLicenses' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: differenceOfLicenses > 0 ? 'billing.microsoft.buyMoreLicensesConfirmModalContent' : 'billing.microsoft.buyLessLicensesConfirmModalContent' }, {
        currentNoOfLicenses,
        additionalLicences: Math.abs(differenceOfLicenses),
      }),
      okText: formatMessage({ id: 'app.yes' }),
      okType: 'primary',
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        setIsUpdatingBilling(true)
        submitBilling()
      },
    })
  }

  const layout = {
    labelCol: {
      md: { span: 4 },
      sm: { span: 24 },
      xs: { span: 24 },
    },
    wrapperCol: { span: 24 },
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="sidebar.billing" /></span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id="sidebar.settings" />,
              },
              {
                title: <IntlMessages id="sidebar.billing" />,
              },
            ]}
          />
        </div>
      </div>

      <div className="main-content-body">
        <div className="billing-body">
          {isLoadingBillingInfo ?
            <CircularProgress /> :
            <Form
              form={form}
              layout="horizontal"
              name="msForm"
              onFinish={() => {
                logger.debug('submitting billing...')
              }}
              {...layout}
            >

              <Title level={4}>
                <IntlMessages id="billing.pricingPlansTitle" />
              </Title>

              <Row justify='center' style={{ marginBottom: 10 }} gutter={{ lg: 16, xl: numberOfUsers > 300 ? 4 : 8 }}>
                <Col xxl={{ span: 7, offset: 0 }} xl={8} lg={14} md={14} sm={24} xs={24} style={{ display: 'flex' }}>
                  <PricePlanCard
                    plan='Core'
                    totalPrice={numberOfUsers > USER_PLAN_LIMIT ? numberOfUsers : USER_PLAN_LIMIT}
                    totalUsers={numberOfUsers}
                    pricePerUser={PlanPricePerUser.Core}
                    currentPeriod={currentPeriod}
                    newPeriod={newPeriod}
                    currentPlan={newPlan}
                    showRevertingInfo={true}
                    isSignup={false}
                    onSelectPLan={changePlanHandler}
                    isTrialPeriod={false}
                    forceDisable={false}
                    disableLimitSubdepartments={disableSubdepartmentsLimit}
                    disableLimitTeam={disableTeamsLimit}
                    disableLimitLocation={disableLocationsLimit}
                  />
                </Col>
                <Col xxl={7} xl={8} lg={14} md={14} sm={24} xs={24} style={{ display: 'flex' }}>
                  <PricePlanCard
                    plan='Complete'
                    totalPrice={numberOfUsers > USER_PLAN_LIMIT ? numberOfUsers * PlanPricePerUser.Complete : USER_PLAN_LIMIT * PlanPricePerUser.Complete}
                    totalUsers={numberOfUsers}
                    pricePerUser={PlanPricePerUser.Complete}
                    currentPeriod={currentPeriod}
                    newPeriod={newPeriod}
                    currentPlan={newPlan}
                    showRevertingInfo={true}
                    isSignup={false}
                    onSelectPLan={changePlanHandler}
                    isTrialPeriod={false}
                    forceDisable={false}
                  />
                </Col>
              </Row>

              <Form.Item
                label={<IntlMessages id='billing.microsoft.billingCycleLabel' />}
                extra={!autoRenew ? <IntlMessages id='billing.microsoft.billingCycleEnds' /> : ''}
              >
                <IntlMessages id='billing.microsoft.billingCycle' values={{
                  date: billing?.billing?.msPeriodEndDate ? format(new Date(billing.billing.msPeriodEndDate), 'MMMM do yyyy', {locale: getDateFnsLocale(locale.locale)}) : '',
                }} />
              </Form.Item>

              <Title level={4}>
                <IntlMessages id="billing.microsoft.licensesTitle" />
              </Title>

              <Form.Item label={<IntlMessages id='billing.microsoft.licensesTitle' />} wrapperCol={{ span: 24 }}>
                <Row gutter={8}>
                  <Col xl={{ span: 3 }} lg={{ span: 3 }} md={{ span: 3 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item>
                      <Input
                        style={{ textAlign: 'center' }}
                        type='number'
                        value={numberOfLicenses}
                        defaultValue={numberOfLicenses}
                        onChange={e => {
                          if (Number(e.target.value) < 25) {
                            return
                          }
                          setNumberOfLicenses(Number(e.target.value))
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl={18} xl={16} lg={14} md={12} sm={24}>
                    <Form.Item>
                      <Button
                        onClick={() => showBuyMoreLicencesConfirmModal()}
                        disabled={numberOfLicenses === billing?.billing?.quantity || isUpdatingBilling}
                      >
                        <IntlMessages id='app.save' />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              {billingSections.map(section => {
                return <BillingSection
                  key={section.id}
                  intlTitleId={section.intlTitleId}
                  intlButtonLabelId={section.intlButtonLabelId}
                  adminCenterLinkArgument={section.adminCenterLinkArgument}
                />
              })}

              <div style={{ marginTop: 40}}>
                {doNotShowCancelModal ?
                  <Alert
                    message={<IntlMessages id="billing.microsoft.canceledSubscriptionMessage" />}
                    type="info"
                    showIcon
                    style={{ marginBottom: '20px' }}
                  /> :
                  <div className="text-center">
                    <Button type="primary" danger onClick={() => setIsCancelSubscriptionModalOpen(true)}>
                      <IntlMessages id="billing.cancelSubscription" />
                    </Button>
                  </div>
                }
              </div>
            </Form>
          }
        </div>
      </div>

      {isSwitchPlanModalOpen &&
        <Modal
          title={<IntlMessages id="subscriptions.switchPlanTitle" values={{
            plan: newPlan,
            strong: (...chunks) => <strong>{chunks}</strong>,
          }} />}
          centered
          open={isSwitchPlanModalOpen}
          onOk={() => switchPlan()}
          okText={<IntlMessages id="app.switch" />}
          onCancel={() => cancelPlanChange()}
          cancelText={<IntlMessages id="app.cancel" />}
        >
          <IntlMessages id="billing.microsoft.switchPlanModal.confirm" />
        </Modal>
      }

      {isCancelSubscriptionModalOpen &&
        <CancelSubscriptionModal
          openCancelSubscriptionModal={isCancelSubscriptionModalOpen}
          handleOnCancelSubscription={handleOnCancelSubscription}
          closeModal={() => setIsCancelSubscriptionModalOpen(false)}
        />
      }

    </div>
  )
}

export default MicrosoftBillingPage

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { useHistory } from 'react-router'
import { Button, Card, Tooltip, Typography } from 'antd'
import { ArrowRightOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

import IntlMessages from '../../../util/IntlMessages'
import { actions } from '../actions'

import { IMicrosoftSaasCreateCompanyState } from '../types'
import { openChat } from '../../../util/set-crisp-session-info'

interface IWelcomeScreenProps {
  state: IMicrosoftSaasCreateCompanyState
  onFinishSetup: Function
  dispatch: Function
}

const { Title, Text } = Typography

export const WelcomeScreen = ({ state, onFinishSetup, dispatch }: IWelcomeScreenProps): React.ReactElement => {
  const history = useHistory()

  const { numberOflicenses, steps, selectedUsers, importAllUsers, isCreateCompanyLoader } = state

  const handleCreateCompany = () => {
    dispatch(actions.setCurrentStep(1))
    history.push('/microsoft-saas/create-company/company-details')
  }

  const handleAssignLicenses = () => {
    dispatch(actions.setCurrentStep(2))
    history.push('/microsoft-saas/create-company/assign-licenses')
  }

  const handleInstallBotAndTabs = () => {
    dispatch(actions.setCurrentStep(3))
    history.push('/microsoft-saas/create-company/setup-ms-bot')
  }

  const assignLicensesClass = () => {
    let data = 'card-box'
    if (steps.assignLicenses) {
      data += ' completed'
    }
    if (!steps.createCompany) {
      data += ' disabled'
    }
    if (Number(selectedUsers.length) + 1 > numberOflicenses) {
      data += ' error'
    }
    return data
  }

  const setupBotAndTabsClass = () => {
    let data = 'card-box'
    if (steps.setupBotAndTabs) {
      data += ' completed'
    }
    if (!steps.assignLicenses) {
      data += ' disabled'
    }
    return data
  }

  const contactSupport = () => {
    openChat()
  }

  return (
    <div className="microsoft-saas-create-company">
      <Title level={5} style={{ marginBottom: 25 }}><IntlMessages id="microsoftSass.createCompany.welcomeTitle" /></Title>
      <Text>
        <IntlMessages id="microsoftSass.createCompany.welcomeMessage"
          values={{
            totalLicenses: numberOflicenses,
            bold: (...chunks) => <strong>{chunks}</strong>,
          }}
        /><br/>
        <IntlMessages id="microsoftSass.createCompany.letsCompleteSetup" />
      </Text>
      <div>
        <Card className={ !steps.createCompany ? 'card-box': 'card-box completed' } onClick={handleCreateCompany} >
          <div className="link">
            <IntlMessages id="microsoftSass.createCompany.stepCreateComapny" />
            {steps.createCompany || <span> (<IntlMessages id="app.required" />)</span>}
          </div>
          <div className="link-right">
            {!steps.createCompany ?
              <ArrowRightOutlined className="link-icon" /> :
              <><IntlMessages id="microsoftSass.createCompany.completed" /> <CheckCircleOutlined className="link-icon" /></>
            }
          </div>
        </Card>
        <Tooltip placement="top" title={!steps.createCompany ? <IntlMessages id="microsoftSass.createCompany.finishFristStep" /> : ''}>
          <Card className={assignLicensesClass()} onClick={() => { if (steps.createCompany) { handleAssignLicenses() } }}>
            <div className="link">
              <IntlMessages id="microsoftSass.createCompany.stepAssignLicenses" />
              {steps.assignLicenses || <span> (<IntlMessages id="app.required" />)</span>}
            </div>
            <div className="link-right">
              {!steps.assignLicenses ?
                <ArrowRightOutlined className="link-icon" /> :
                <>
                  {Number(selectedUsers.length) + 1 > numberOflicenses ? 
                    <Text type="danger">
                      <IntlMessages id="components.manageAssignLicenses.moreUserThenLicenses" values={{ users: (selectedUsers.length + 1) - numberOflicenses }} />
                    </Text> :
                    <>
                      {importAllUsers ?
                        <IntlMessages id="microsoftSass.createCompany.importAllUsers" /> :
                        <IntlMessages id="microsoftSass.createCompany.numberOfLicensesAssigned" values={{ assignedLicenses: selectedUsers.length + 1 }} /> 
                      }
                    </>
                  }
                  {Number(selectedUsers.length) + 1 > numberOflicenses ? 
                    <CloseCircleOutlined className="link-icon error"/> :
                    <CheckCircleOutlined className="link-icon" />
                  }
                </>
              }
            </div>
          </Card>
        </Tooltip>
        <Tooltip placement="top" title={!steps.assignLicenses ? <IntlMessages id="microsoftSass.createCompany.finishSecondStep" /> : ''}>
          <Card className={setupBotAndTabsClass()} onClick={() => { if (steps.assignLicenses) { handleInstallBotAndTabs()}}}>
            <div className="link"><IntlMessages id="microsoftSass.createCompany.stepSetupBotAndTabs" /> <span>(<IntlMessages id="app.recommended" />)</span></div>
            <div className="link-right">
              {!steps.setupBotAndTabs ?
                <ArrowRightOutlined className="link-icon" /> :
                <><IntlMessages id="microsoftSass.createCompany.completed" /> <CheckCircleOutlined className="link-icon"/></>
              }
            </div>
          </Card>
        </Tooltip>
      </div>
      <div className="help-info">
        <IntlMessages id="microsoftSass.createCompany.help"
          values={{
            // eslint-disable-next-line
            helpdesk: (...chunks) => (
              <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noopener noreferrer">
                {chunks}
              </a>
            ),
            // eslint-disable-next-line
            support: (...chunks) => <a onClick={contactSupport}>{chunks}</a>,
          }}
        />
      </div>
      <div className="footer">
        <Button
          type="primary"
          onClick={() => onFinishSetup()}
          disabled={!steps.createCompany || !steps.assignLicenses || selectedUsers.length + 1 > numberOflicenses || isCreateCompanyLoader}
          loading={isCreateCompanyLoader}
        >
          <IntlMessages id="microsoftSass.createCompany.finishSetup" />
        </Button>
      </div>
    </div>
  )
}
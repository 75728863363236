import React, { useState, Fragment } from 'react'
import { Modal, Form, Select, Tooltip, Steps, Button, Divider } from 'antd'
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'
import LeavePolicyForm from '../leave-policy-form'
import * as logger from '../../services/logger'

import { IGetLeaveTypeList, IGetLocation, ILocationGeneralLeavePolicies } from '../../types/locations'
import { decorateLeavePolicyWithQuotaUnits, RolloverStatus } from '../../types/leave-types'
import { AccrualTypeEnum, QuotaUnits, ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import { ILocation } from '@vacationtracker/shared/types/location'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'
import { getQuotaInSettingsUnits } from '@vacationtracker/shared/functions/get-days-or-hours'

interface ILeavePolicyModal {
  visibleModal: boolean
  handleCancel: () => void
  onSave: Function
  leaveTypes: IGetLeaveTypeList[]
  loading: boolean
  location: IGetLocation
  allLeavePolicies: ILocationGeneralLeavePolicies[]
  hourlyLeaveAccounting: boolean
}

const LeavePolicyModal = ({
  visibleModal,
  handleCancel,
  onSave,
  leaveTypes,
  loading,
  location,
  allLeavePolicies,
  hourlyLeaveAccounting,
}: ILeavePolicyModal): React.ReactElement => {
  const [form] = Form.useForm()

  const [currentStep, setCurrentStep] = useState(0)
  const [steps, setSteps] = useState(() => 'selectLeaveType')
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState('')
  const [copypolicy, setCopypolicy] = useState<ILocationGeneralLeavePolicies>()

  const defaultValue = {
    id: null,
    leaveTypeId: null,
    accrualType: AccrualTypeEnum.none,
    shortestLeaveInterval: ShortestLeaveIntervalEnum.fullDay,
    quota: 0,
    maxRollover: 0,
    hasUnlimitedDays: false,
    hideLeaveType: false,
    isApprovalRequired: true,
    isReasonRequired: false,
    negativeBallanceAllowed: false,
    copypolicy: 'default',
    rolloverType: RolloverStatus.no,
    firstEarningDate: dayjs(),
    accrualPeriod: [dayjs(), dayjs().add(13, 'days')],
    allowLeaveInPast: false,
    enableRolloverExpiry: location?.rolloverNeverExpireDays || false,
    rolloverExpiryAfterDays: location.rolloverExpiryAfterDays,
    rolloverExpiryDay: location.rolloverExpiryDay,
    rolloverExpiryMonth: location.rolloverExpiryMonth,
    allowAdvanceAccrualUsage: false,
    pauseAccrualsWhenUserIsDeactivated: true,
    accrualCap: false,
    toil: false,
  }

  const onFinish = async () => {
    const values = await form.validateFields()

    const maxRollover = copypolicy?.quotaSetIn === 'hours' ? copypolicy?.maxRolloverHours : copypolicy?.maxRolloverDays

    if (values.rolloverType === 'all') {
      values.maxRollover = hourlyLeaveAccounting ? 366 * 8 : 366
    } else if (values.rolloverType === 'no') {
      values.maxRollover = 0
    } else {
      values.maxRollover = values.maxRollover || maxRollover || 0
    }

    delete values.copypolicy
    delete values.rolloverType

    if (values.accrualType && values.accrualType !== AccrualTypeEnum.none) {
      values.firstEarningDate = values.firstEarningDate.format('YYYY-MM-DD')
      values.accrualPeriodStart = values.accrualPeriod[0].format('YYYY-MM-DD')
    }

    if (typeof values.enableRolloverExpiry !== 'boolean') {
      values.enableRolloverExpiry = false
    }
    values.quotaSetIn = copypolicy?.quotaSetIn || (hourlyLeaveAccounting ? 'hours' : 'days')

    try {
      onSave({
        locationId: location.id,
        id: defaultValue.id,
        leaveTypeId: selectedLeaveTypeId || defaultValue.leaveTypeId,
        cancelUserSettings: false,
        ...values,
        pauseAccrualsWhenUserIsDeactivated: true,
      })
    } catch(err) {
      logger.debug('Validate Failed:', err)
    }
  }

  const onSelectLeaveType = (id: string) => {
    setSelectedLeaveTypeId(id)
  }

  const onSelectLeavePolicyId = (leavePolicyId: string) => {
    let leavePolicyData: ILocationGeneralLeavePolicies
    if (leavePolicyId === 'default') {
      leavePolicyData = {
        id: '',
        locationId: location.id,
        isActive: true,
        daysPerYear: defaultValue.quota,
        hoursPerYear: defaultValue.quota,
        maxRolloverDays: defaultValue.maxRollover,
        maxRolloverHours: defaultValue.maxRollover,
        quotaSetIn: hourlyLeaveAccounting ? 'hours' : 'days',
        isApprovalRequired: defaultValue.isApprovalRequired,
        hasUnlimitedDays: defaultValue.hasUnlimitedDays,
        shortestLeaveInterval: defaultValue.shortestLeaveInterval,
        hideLeaveType: defaultValue.hideLeaveType,
        isReasonRequired: defaultValue.isReasonRequired,
        negativeBallanceAllowed: defaultValue.negativeBallanceAllowed,
        accrualType: defaultValue.accrualType,
        enableRolloverExpiry: defaultValue.enableRolloverExpiry,
        allowAdvanceAccrualUsage: defaultValue.allowAdvanceAccrualUsage,
        pauseAccrualsWhenUserIsDeactivated: defaultValue.pauseAccrualsWhenUserIsDeactivated,
        toil: defaultValue.toil,
        allowLeaveInPast: false,
        accrualCapRate: 0,
        leaveType: {
          id: '',
          name: '',
          isActive: true,
          position: 0,
          deleted: false,
        },
      }
    } else {
      const leavePolicyDataRaw = allLeavePolicies.find(lp => lp.id === leavePolicyId) as ILocationGeneralLeavePolicies
      leavePolicyData = decorateLeavePolicyWithQuotaUnits(leavePolicyDataRaw, hourlyLeaveAccounting)
    }

    setCopypolicy({
      pauseAccrualsWhenUserIsDeactivated: typeof leavePolicyData.pauseAccrualsWhenUserIsDeactivated === 'undefined' ? true : leavePolicyData.pauseAccrualsWhenUserIsDeactivated,
      ...leavePolicyData,
      enableRolloverExpiry: false,
    })

    const locationWorkingHours = getUserWorkingHoursPerDay(location.workHours)

    form.setFieldsValue({
      accrualType: leavePolicyData.accrualType,
      shortestLeaveInterval: leavePolicyData.shortestLeaveInterval,
      quota: getQuotaInSettingsUnits(
        hourlyLeaveAccounting, locationWorkingHours, leavePolicyData.daysPerYear as number, leavePolicyData.hoursPerYear as number, leavePolicyData.quotaSetIn as QuotaUnits
      ),
      maxRollover: getQuotaInSettingsUnits(
        hourlyLeaveAccounting, locationWorkingHours, leavePolicyData.maxRolloverDays as number, leavePolicyData.maxRolloverHours as number, leavePolicyData.quotaSetIn as QuotaUnits
      ),
      hasUnlimitedDays: leavePolicyData.hasUnlimitedDays,
      hideLeaveType: leavePolicyData.hideLeaveType,
      isActive: leavePolicyData.isActive,
      isApprovalRequired: leavePolicyData.isApprovalRequired,
      isReasonRequired: leavePolicyData.isReasonRequired,
      negativeBallanceAllowed: leavePolicyData.negativeBallanceAllowed,
      accrualCapRate: leavePolicyData.accrualCapRate || 0,
      allowLeaveInPast: leavePolicyData.allowLeaveInPast,
      allowAdvanceAccrualUsage: leavePolicyData.allowAdvanceAccrualUsage,
      pauseAccrualsWhenUserIsDeactivated: typeof leavePolicyData.pauseAccrualsWhenUserIsDeactivated === 'undefined' ? true : leavePolicyData.pauseAccrualsWhenUserIsDeactivated,
      enableRolloverExpiry: leavePolicyData.enableRolloverExpiry,
      rolloverExpiryMonth: leavePolicyData.rolloverExpiryMonth,
      rolloverExpiryDay: leavePolicyData.rolloverExpiryDay,
      rolloverExpiryAfterDays: leavePolicyData.rolloverExpiryAfterDays,
    })
  }

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  return (
    <Modal
      key="leave-policy-form"
      title={<IntlMessages id="app.assignLeavePolicy" />}
      open={visibleModal}
      style={{ top: 20 }}
      width={680}
      onCancel={handleCancel}
      closeIcon={<CloseCircleOutlined />}
      footer={[
        <Button key="cancel" disabled={loading} onClick={handleCancel}>
          <IntlMessages id="app.cancel" />
        </Button>,
        <Fragment key="menu">
          {steps === 'selectLeaveType' &&
            <Button disabled={!selectedLeaveTypeId} key="find" type="primary" onClick={() => {setSteps('setLeavePolicies'); setCurrentStep(1)} }>
              <IntlMessages id="app.assignLeavePolicy" />
            </Button>
          }
          {steps === 'setLeavePolicies' &&
            <>
              <Button key="backToSelectLeaveType" type="default" disabled={loading} onClick={() => { setCurrentStep(0); setSteps('selectLeaveType') }}>
                <IntlMessages id="app.back" />
              </Button>
              <Button key="saveLeavePolicy" type="primary" onClick={onFinish} loading={loading}>
                <IntlMessages id="app.save" />
              </Button>
            </>
          }
        </Fragment>,
      ]}
    >
      {(leaveTypes.length > 0) ?
        <Fragment>
          <Steps
            style={{ marginBottom: 20 }}
            size="small"
            current={currentStep}
            items={[
              {
                title: <IntlMessages id="components.leavePolicyForm.selectLeaveType" />,
              },
              {
                title: <IntlMessages id="app.assignLeavePolicy" />,
              },
            ]}
          />

          {steps === 'selectLeaveType' && <>
            <Form.Item
              label={<IntlMessages id="components.leavePolicyForm.selectLeaveType" />}
            >
              <Select
                style={{ width: 300 }}
                onSelect={onSelectLeaveType}
                value={selectedLeaveTypeId}
                filterOption={filterOptions}
                showSearch
              >
                {leaveTypes.map(leaveType => <Select.Option key={leaveType.id} value={leaveType.id}>{leaveType.name}</Select.Option>)}
              </Select>
            </Form.Item>
          </>}
          {steps === 'setLeavePolicies' && <>
            <Form
              {...formLayout}
              form={form}
              name="LeavePolicyForm"
              onFinish={() => onFinish}
              initialValues={defaultValue}
            >
              <Divider />
              <p><IntlMessages id="components.leavePolicyForm.copyLeavePolicyDivider" /></p>
              <Form.Item
                label={(<>
                  <IntlMessages id="components.leavePolicyForm.copyLeavePolicy" />
                  <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.copyLeavePolicyInfo" />} ><InfoCircleOutlined /></Tooltip>
                </>)}
                name='copypolicy'
              >
                <Select onSelect={onSelectLeavePolicyId}>
                  <Select.Option key="default" value="default"><IntlMessages id="components.leavePolicyForm.defaultLeavePolicy" /></Select.Option>
                  {allLeavePolicies.map(lp => <Select.Option key={lp.id} value={lp.id}>{lp.leaveType?.name}</Select.Option>)}
                </Select>
              </Form.Item>

              <LeavePolicyForm
                leavePolicy={undefined}
                location={location as unknown as ILocation}
                form={form}
                copypolicy={copypolicy}
                showAccruals={true}
                yearStartDay={location.yearStartDay}
                isAssignLeavePolicyForm={true}
              />
            </Form>
          </>}
        </Fragment> :
        <Fragment>
          <IntlMessages id="components.leavePolicyForm.noLeaveTypes" />
        </Fragment>
      }
    </Modal>
  )
}
export default LeavePolicyModal

import React from 'react'
import { Alert } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { openChat } from '../../util/set-crisp-session-info'

export const LegacyPlanAlert = (): React.ReactElement => {

  const openCrisp = (e) => {
    e.preventDefault()
    openChat()
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Alert
        message={
          <IntlMessages id="components.legacyPlanAlert.warning" values={{ link: (...chunks) => <a onClick={openCrisp}>{chunks}</a> }}/>
        }
        type="warning"
        showIcon
        style={{ marginBottom: '20px' }}
      />
    </div>
  )
}

export default LegacyPlanAlert

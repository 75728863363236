import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Select, Button, Space, Typography, InputRef, Modal } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import axios from 'axios'
import IntlMessages from '../../../util/IntlMessages'
import { validEmailWithRegex } from '@vacationtracker/shared/functions/valid-email-with-regex'
import { DepartmentShort, LocationShort } from './types'
import { IGetUserListShort } from '../../../types/teams'
import { NoOfUsersWarrningMessage } from '../ImportUsersPage/components'
import { IUpcomingInvoiceInformation } from '@vacationtracker/shared/types/stripe'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { ICompanyInfo } from '@vacationtracker/shared/types/company'
import { IUserInfo } from '../../../types/users'
import YesNoSwitch from '../../../components/yes-no-switch'

const { Title, Text } = Typography

interface UserFormData {
  name: string
  email: string
  department: string
  location: string
}

interface ImportEmailUsersFormProps {
  departments: DepartmentShort[]
  locations: LocationShort[]
  activeUsers: IGetUserListShort[]
  upcomingInvoiceInfo: IUpcomingInvoiceInformation | null
  isLoadingUpcomingInvoiceInfo: boolean
  authCompany: ICompanyInfo | null
  authUser: IUserInfo
  onGetUpcomingInvoiceInfo: (noOfUsersToImport: number) => Promise<void>
  onFinish: (values: { users: UserFormData[]; shouldInviteUsers: boolean }) => void
}

const ImportEmailUsersForm: React.FC<ImportEmailUsersFormProps> = ({
  departments,
  locations,
  activeUsers,
  upcomingInvoiceInfo,
  isLoadingUpcomingInvoiceInfo,
  onGetUpcomingInvoiceInfo,
  onFinish,
  authCompany,
  authUser,
}) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const [userExistsError, setUserExistsError] = useState(false)
  const [isCheckingIfUserExists, setIsCheckingIfUserExists] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [formValues, setFormValues] = useState<{ users: UserFormData[]; shouldInviteUsers: boolean }>()
  const [totalUsersAfterImport, setTotalUsersAfterImport] = useState(0)
  const emailInputRef = useRef<InputRef | null>(null)
  const defaultLocationId = locations.find(loc => loc.isDefault)?.id
  const defaultDepartmentId = departments.find(dep => dep.isDefault)?.id

  const [users, setUsers] = useState<UserFormData[]>([
    { name: '', email: '', department: defaultDepartmentId as string, location: defaultLocationId as string },
  ])

  useEffect(() => {
    emailInputRef.current?.focus()
  }, [users])

  useEffect(() => {
    form.setFieldsValue({
      users: [{
        name: '',
        email: '',
        department: defaultDepartmentId,
        location: defaultLocationId,
      }],
      shouldInviteUsers: false,
    })
  }, [form, defaultLocationId, defaultDepartmentId])

  useEffect(() => {
    if (totalUsersAfterImport > 0) {
      onGetUpcomingInvoiceInfo(totalUsersAfterImport)
    }
  }, [totalUsersAfterImport, onGetUpcomingInvoiceInfo])

  useEffect(() => {
    const calculatedNumberOfPossibleActiveUsersAfterImport = activeUsers.filter(user => user.status === 'ACTIVE').length + users.length
    setTotalUsersAfterImport(calculatedNumberOfPossibleActiveUsersAfterImport)

    // Only fetch invoice information when users array changes
    if (calculatedNumberOfPossibleActiveUsersAfterImport > 0) {
      onGetUpcomingInvoiceInfo(calculatedNumberOfPossibleActiveUsersAfterImport)
    }
  }, [activeUsers, users.length, onGetUpcomingInvoiceInfo])

  const handleSubmit = (values: { users: UserFormData[]; shouldInviteUsers: boolean }) => {
    setFormValues(values)
    setIsModalVisible(true)
  }

  const handleModalConfirm = () => {
    if (formValues) {
      onFinish(formValues)
      setIsModalVisible(false)
    }
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const addUser = () => {
    const currentFormValues = form.getFieldsValue().users || []
    const lastUserIndex = currentFormValues.length - 1
    const lastUser = currentFormValues[lastUserIndex] || {}

    // Use the last user's department and location if they exist, otherwise use defaults
    const newUser = {
      name: '',
      email: '',
      department: lastUser.department || defaultDepartmentId,
      location: lastUser.location || defaultLocationId,
    }

    form.setFieldsValue({
      users: [...currentFormValues, newUser],
    })
    setUsers([...currentFormValues, newUser])
  }

  const removeUser = (index: number) => {
    const newUsers = [...users]
    newUsers.splice(index, 1)
    setUsers(newUsers)
  }

  const validation = async (rule: any, email: string) => {
    if (!email) {
      return Promise.reject(new Error(formatMessage({ id: 'error.fieldIsRequired' })))
    }
    if (email === authUser.email) {
      return Promise.reject(new Error(formatMessage({ id: 'components.inviteAndManageUsersWithEmail.canNotInviteYourself' })))
    }

    // Get current form values and check for duplicates
    const currentFormValues = form.getFieldValue('users') || []
    const currentFieldName = (rule as any).field
    const [_, currentIndex] = currentFieldName.match(/users\.(\d+)\.email/) || []
    
    const duplicateEmail = currentFormValues.some((user: UserFormData, index: number) => 
      user.email === email && index.toString() !== currentIndex
    )

    if (duplicateEmail) {
      return Promise.reject(new Error(formatMessage({ id: 'components.inviteAndManageUsersWithEmail.duplicateEmail' })))
    }
    setIsCheckingIfUserExists(true)
    setUserExistsError(false)

    if (validEmailWithRegex(email)) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/core/auth-info`, { email })
        setUserExistsError(true)
        setIsCheckingIfUserExists(false)
        return Promise.reject(new Error(formatMessage({ id: 'components.inviteAndManageUsersWithEmail.userAlreadyExists' })))
      } catch (error) {
        setIsCheckingIfUserExists(false)
        if (error.code === 'UserNotFound') {
          return Promise.resolve()
        }
      }
    } else {
      setIsCheckingIfUserExists(false)
      return Promise.reject(new Error(formatMessage({ id: 'connect.pleaseInputValidEmail' })))
    }
  }

  return (
    <>

      <div style={{ marginBottom: 20 }}>
        <Text> <IntlMessages id="users.createUsersDescription" /></Text>
      </div>
      <Form form={form} onFinish={handleSubmit} >
        <Space style={{ display: 'flex', marginBottom: 8, paddingLeft: 8 }} align="baseline">
          <div style={{ width: 200 }}>
            <Text strong><IntlMessages id="app.name" /></Text>
          </div>
          <div style={{ width: 200 }}>
            <Text strong><IntlMessages id="app.emailAddress" /></Text>
          </div>
          <div style={{ width: 200 }}>
            <Text strong><IntlMessages id="app.department" /></Text>
          </div>
          <div style={{ width: 200 }}>
            <Text strong><IntlMessages id="app.location" /></Text>
          </div>
        </Space>
        {users.map((_, index) => (
          <Space
            key={index}
            style={{ display: 'flex', marginBottom: 8 }}
            align='baseline'
          >
            <Form.Item
              name={['users', index, 'name']}
              rules={[{ required: true, message: formatMessage({ id: 'error.fieldIsRequired' }) }]}
            >
              <Input placeholder='Name' style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              name={['users', index, 'email']}
              rules={[{ required: true, type: 'email', validator: validation }]}
            >
              <Input placeholder={formatMessage({ id: 'app.email' })} style={{ width: 200 }} allowClear ref={emailInputRef} />
            </Form.Item>
            <Form.Item
              name={['users', index, 'department']}
              rules={[{ required: true, message: formatMessage({ id: 'error.fieldIsRequired' }) }]}
              initialValue={departments.find(dep => dep.isDefault)?.id}
            >
              <Select style={{ width: 200 }} placeholder='Department'>
                {departments.map(department => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={['users', index, 'location']}
              rules={[{ required: true, message: formatMessage({ id: 'error.fieldIsRequired' }) }]}
              initialValue={locations.find(loc => loc.isDefault)?.id}
            >
              <Select style={{ width: 200 }} placeholder='Location'>
                {locations.map(location => (
                  <Select.Option key={location.id} value={location.id}>
                    {location.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Space>
              {users.length > 1 && (
                <Button
                  icon={<MinusCircleOutlined />}
                  onClick={() => removeUser(index)}
                  type="text"
                  danger
                />
              )}
              {index === users.length - 1 && (
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={addUser}
                  type='text'
                  style={{ color: 'green' }}
                />
              )}
            </Space>
          </Space>
        ))}
        <div style={{ display: 'flex', marginBottom: 8, width: '100%' }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <YesNoSwitch
              name="shouldInviteUsers"
              label={<IntlMessages id="users.inviteUsers" />}
              defaultValue={false}
              required={false}
            />
          </Form.Item>
          <div style={{ flex: 1, display: 'flex', maxWidth: 600 }}>
            <Form.Item style={{ marginBottom: 0, marginLeft: 'auto' }}>
              <Button type="primary" htmlType="submit"
                disabled={isCheckingIfUserExists || userExistsError}>
                <IntlMessages id="users.createUsers" />
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <Modal
        title={formatMessage(
          { id: 'components.importUsersForm.confirmImportUsersModalTitle' },
          { noOfUsers: users.length > 0 ? users.length : '' }
        )}
        open={isModalVisible}
        onOk={handleModalConfirm}
        onCancel={handleModalCancel}
        okText="Confirm"
        cancelText="Cancel"
      >
        {users.length === 0
          ? <></>
          : <NoOfUsersWarrningMessage
            noOfUsers={users.length}
            totalNumberOfUsersAfterImport={totalUsersAfterImport}
            formatMessage={formatMessage}
            upcomingInvoiceInfo={upcomingInvoiceInfo}
            isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
            isAnnualBilling={authCompany?.billing?.subscriptionPeriod === 'annual'}
            showAdditionalBillingInfo={false}
            locale={authUser?.locale as LocaleEnum}
            overSeatsLimit={false}
            seatsLimit={authCompany?.billing?.seats}
            isTrial={authCompany?.subscriptionStatus === 'trialing' && !authCompany?.billing?.paymentMethodAdded}
            stripeCurrentPeriodEnd={authCompany?.billing?.stripeCurrentPeriodEnd as string}
          />}
      </Modal>
    </>
  )
}

export default ImportEmailUsersForm

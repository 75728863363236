import React from 'react'
import { Typography } from 'antd'
import { differenceInDays, isAfter, isEqual } from 'date-fns'
import FormattedDate from '../formatted-date'
import { IRolloverExpiryProps } from './types'
import { roundTo2Decimals } from '../../functions/calculation-shared'
const { Paragraph } = Typography

const RolloverExpiry = ({rolloverExpiryDate, maxRolloverDays, isNextYearSelected, isCurrentYearSelected, yearEndDate, IntlMessages, locale}: IRolloverExpiryProps) => {
  
  if (rolloverExpiryDate === '' || maxRolloverDays === 0) {
    return null
  }

  if (!isNextYearSelected && !isCurrentYearSelected) {
    return null
  }

  let isExpired = false

  if (isAfter(new Date(), new Date(rolloverExpiryDate)) && !isEqual(new Date(), new Date(rolloverExpiryDate))) {
    isExpired = true
  }

  if (isCurrentYearSelected && isExpired) {
    return null
  }

  if (isAfter(new Date(rolloverExpiryDate), yearEndDate)) {
    return null
  }

  let isUpcomingYearSelected = false
  const totalDays = differenceInDays(new Date(rolloverExpiryDate), new Date())
  if (totalDays > 365) {
    isUpcomingYearSelected = true
  }  

  return <Paragraph data-testid="rollover-expiry-footer">
    <IntlMessages id="components.userLeaveQuotas.broughtForwardDaysInfo" values={{
      date: <FormattedDate value={rolloverExpiryDate?.slice(0,10)} format="MMMM Do YYYY." locale={locale} />,
      maxRolloverDays: roundTo2Decimals(Number(maxRolloverDays)),
      rolloverDays: (...chunks) => (isNextYearSelected || isUpcomingYearSelected) && maxRolloverDays !== 366 && !isCurrentYearSelected ? chunks : '',
      expiresDate: (...chunks) => isExpired ? '' : chunks,
      br: () => <br/>,
    }}/>
  </Paragraph>
  
}

export default RolloverExpiry

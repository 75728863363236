import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { App, Alert, Breadcrumb } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useManualQuery } from 'graphql-hooks'
import { isWithinInterval, addYears } from 'date-fns'
import { useFeatureFlagEnabled } from 'posthog-js/react'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../../services/logger'
import {
  getUsersForApprover, getOverlappingUsersInfo, getUsersForAdminWithPagination, getUserForLeaveRequest, getLeaveRequestData, getTeamsForApprover,
  getAutomations,
  getSuggestedSubstituteApprovers
} from '../../../graphql/custom-queries'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectUserIdSlice } from '../../../store/user-id-slice'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../../store/leave-request-action-event-slice'
import { displayLeaveInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'
import { formatDateToLocale } from '@vacationtracker/shared/components/formatted-date'
import { shouldShowFutureAccruedDaysInfo } from '@vacationtracker/shared/functions/should-show-future-accrued-days-info'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import LeaveForm from '@vacationtracker/shared/components/leave-form'

import {
  IGetAutomations,
  IGetLeaveRequest,
  IGetLeaveRequestData,
  IGetUserForLeaveRequest,
  IGetUserForLeaveReueqstUserData,
  IGetUserForLeaveReueqstUserLeaveDays,
  IGetUserForLeaveReueqstUserLeaveDaysLeaveTypes,
  IGetUsersForAdminAddLeaveData,
  IGetUsersForApproverData,
  IUserIdAndName,
  IUserLeaveReuqestLeavePolicy,
  IUserLocationLeavePolicies
} from '../../../types/custom-queries'
import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import {
  getLeavePolicyFromQuota,
  LeaveRequestOverlappingInfo,
  LeaveRequestQuota,
  LeaveRequestSummary
} from '@vacationtracker/shared/components/add-request-leave-additional-info'
import { getLeavePeriod } from '@vacationtracker/shared/functions/get-leave-period'
import { IApprover } from '@vacationtracker/shared/types/leave-request'
import { GetTeamsForApproverData, IGetUserOverlappingInfo, IGetUserOverlappingInfoData } from '@vacationtracker/shared/components/add-request-leave-additional-info/types'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { AccrualType, IQuotaAmount } from '@vacationtracker/shared/types/leave-policy'
import { useSubstituteApprover } from '../../../util/hooks/use-substitute-approver'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'
import { FrontendUrls } from '../../../types/urls'
import { createQuotaFromDays, deductFromQuota } from '@vacationtracker/shared/functions/calculation-shared'

interface IAddLeaveProps {
  history: {
    push: Function
    goBack: Function
  }
}

const AddLeavePage = ({ history }: IAddLeaveProps) => {

  const { notification } = App.useApp()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { userId } = useAppSelector(selectUserIdSlice)
  const shouldEnableSubstituteApprovers = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.substituteApprovers)
  const enableMinutePickerLeave = useFeatureFlagEnabled(FeatureFlagEnum.enableMinutePickerLeave)

  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)

  const [createLoader, setCreateLoader] = useState(false)
  const [userList, setUserList] = useState<IUserIdAndName[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState<IGetUserForLeaveReueqstUserData | null>(null)
  const [isLoadingOverlappingUsersInfo, setIsLoadingOverlappingUsersInfo] = useState(false)
  const [overlappingUsersInfo, setOverlapingUsersInfo] = useState<IGetUserOverlappingInfo | null>(null)
  const [checkForOverlappingUsers, setCheckForOverlappingUsers] = useState(false)
  const [startDate, setStartDate] = useState()
  const [selectedLeaveType, setSelectedLeaveType] = useState<IUserLeaveReuqestLeavePolicy | null>(null)
  const [endDate, setEndDate] = useState()
  const [addedLeave, setAddedLeave] = useState<IGetLeaveRequest>()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [currentQuota, setCurrentQuota] = useState<IGetUserForLeaveReueqstUserLeaveDays | null>(null)
  const [nextYearQuota, setNextYearQuota] = useState<IGetUserForLeaveReueqstUserLeaveDays | null>(null)
  const [showSummary, setShowSummary] = useState(false)
  const [correlationId, setCorrelationId] = useState(null)
  const [userQuotasForCurrentYear, setUserQuotasForCurrentYear] = useState<IGetUserForLeaveReueqstUserLeaveDaysLeaveTypes | null>(null)
  const [userQuotasForNextYear, setUserQuotasForNextYear] = useState<IGetUserForLeaveReueqstUserLeaveDaysLeaveTypes | null>(null)
  const [ getTeamsForApproverQuery ] = useManualQuery<GetTeamsForApproverData, {
    id: string
    date: string
  }>(getTeamsForApprover)
  const [getAutomationsQuery] = useManualQuery<IGetAutomations>(getAutomations)
  const [getSuggestedSubstituteApproversQuery] = useManualQuery<IGetUsersForAdminAddLeaveData>(getSuggestedSubstituteApprovers)
  const {
    setCheckIfSubstituteNeeded,
    suggestedSubstituteApprovers,
    shouldShowSubstituteApprovers,
    isSubstituteListLoading,
    setShouldShowSubstituteApprovers,
  } = useSubstituteApprover(
    {
      userId: selectedUser?.id,
      startDate,
      endDate,
    },
    {
      getTeamsForApproverQuery,
      getAutomationsQuery,
      getSuggestedSubstituteApproversQuery,
    }
  )
  const [showOverlappingInfoAndQuotas, setShowOverlappingInfoAndQuotas] = useState(true)

  const [getLeaveRequestDataQuery] = useManualQuery<IGetLeaveRequestData, { leaveRequestId: string }>(getLeaveRequestData)
  const [getOverlappingUsersInfoQuery] = useManualQuery<IGetUserOverlappingInfoData, {
    teamId: string
    leaveRequest: {
      startDate: string
      endDate: string
      userId: string
      isPartDay: boolean
      partDayStartHour?: string
      partDayEndHour?: string
      leaveTypeId: string
    }
  }>(getOverlappingUsersInfo)
  const [getUsersForAdminWithPaginationQuery] = useManualQuery<IGetUsersForAdminAddLeaveData, {
    limit: number
    nextToken?: string
  }>(getUsersForAdminWithPagination)
  const [getUsersForApproverQuery] = useManualQuery<IGetUsersForApproverData, { id: string }>(getUsersForApprover)
  const [getUserForLeaveRequestQuery] = useManualQuery<IGetUserForLeaveRequest, { id: string }>(getUserForLeaveRequest)
  const [isMinutePickerEnabled, setIsMinutePickerEnabled] = useState(false)

  useEffect(() => {
    if (typeof enableMinutePickerLeave === 'undefined') {
      return
    }
    setIsMinutePickerEnabled(enableMinutePickerLeave)
  }, [enableMinutePickerLeave])


  useEffect(() => {
    setUserQuotasForCurrentYear(getLeavePolicyFromQuota(selectedLeaveType?.id as string, currentQuota as IGetUserForLeaveReueqstUserLeaveDays))
    setUserQuotasForNextYear(getLeavePolicyFromQuota(selectedLeaveType?.id as string, nextYearQuota as IGetUserForLeaveReueqstUserLeaveDays))
  }, [currentQuota, nextYearQuota, selectedLeaveType])

  useEffect(() => {
    if (isLoading && userId) {
      getUsersList(userId)
    }
  }, [isLoading, userId])

  useEffect(() => {
    if (checkForOverlappingUsers) {
      getOverlappingUsers(startTime, endTime)
    }
  }, [checkForOverlappingUsers, startTime, endTime])

  useEffect(() => {
    if (leaveRequestActionEvent && correlationId === leaveRequestActionEvent.correlationId) {
      getLeaveRequest(leaveRequestActionEvent.leaveRequestId)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  useEffect(() => {
    if (addedLeave) {
      setShowSummary(true)
    }
  }, [addedLeave])

  const getLeaveRequest = async (leaveRequestId: string) => {
    try {
      const leaveRequestResponse = await getLeaveRequestDataQuery({ variables: { leaveRequestId }})
      if (!leaveRequestResponse.data || leaveRequestResponse.error) throw leaveRequestResponse.error
      setAddedLeave(leaveRequestResponse.data.getLeaveRequest)
    } catch (err) {
      logger.error('error fetching leave', err)
    }
  }

  const getOverlappingUsers = async (startTime, endTime) => {
    const teamId = selectedUser?.team?.id
    if (!teamId || !startDate || !endDate || !selectedLeaveType) {
      return
    }
    setShowOverlappingInfoAndQuotas(true)
    setIsLoadingOverlappingUsersInfo(true)
    const overlappingResponse = await getOverlappingUsersInfoQuery({ variables: {
      teamId,
      leaveRequest: {
        startDate,
        endDate,
        userId: selectedUser?.id || userId,
        isPartDay: selectedLeaveType?.id.includes('|part-day'),
        partDayStartHour: startTime,
        partDayEndHour: endTime,
        leaveTypeId: selectedLeaveType?.id,
      },
    }})

    if (!overlappingResponse.data || overlappingResponse.error) throw overlappingResponse.error
    setOverlapingUsersInfo(overlappingResponse?.data?.getOverlappingUsersInfo)
    setCheckForOverlappingUsers(false)
    setIsLoadingOverlappingUsersInfo(false)
  }

  const handleOnLeaveTypeSelected = (selectedLeaveType) => {
    setSelectedLeaveType(selectedLeaveType)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
  }

  const resetLeaveRequestForm = () => {
    setShowSummary(false)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
    setUserQuotasForCurrentYear(null)
    setUserQuotasForNextYear(null)
    setCheckIfSubstituteNeeded(false)
    setShouldShowSubstituteApprovers(false)
  }

  const handleOnEndDateSelected = (dateRange) => {
    const {startDate, endDate} = dateRange
    selectedUser?.leaveDays?.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(endDate)
    setCheckForOverlappingUsers(true)
    if (shouldEnableSubstituteApprovers) {
      setCheckIfSubstituteNeeded(true)
    }
  }

  const handleOnStartDateSelected = (startDate) => {
    selectedUser?.leaveDays?.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(startDate)
    setCheckForOverlappingUsers(true)
  }

  const handleOnTimeSelected = ({
    startTime,
    endTime,
  }) => {
    setStartTime(startTime)
    setEndTime(endTime)
    setCheckForOverlappingUsers(true)
  }

  const getUsersWithPagination = async (limit: number, nextToken?: string, accumulatedResults: IUserIdAndName[] = []) => {
    const response = await getUsersForAdminWithPaginationQuery({ variables: { limit, nextToken }})
    if (!response.data || response.error) throw response.error

    const { users, nextToken: newNextToken } = response.data.getActiveUsersWithPagination

    const updatedResults = [...accumulatedResults, ...users]

    if (newNextToken) {
      return getUsersWithPagination(limit, newNextToken, updatedResults)
    } else {
      return updatedResults
    }
  }

  const getUsersList = async (id) => {
    try {
      let users: IUserIdAndName[] = []
      if (authUser.role === 'Admin') {
        users = await getUsersWithPagination(300)
      }
      if (authUser.role === 'Approver') {
        const response = await getUsersForApproverQuery({ variables: { id }})
        if (!response.data || response.error) throw response.error
        users = response.data.getUser.approverTo
      }

      setUserList(users.sort((a: IUserIdAndName, b: IUserIdAndName) => a.name < b.name ? -1 : 1))
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      logger.error(err)
    }
  }

  const handleOnSelectUser = async (userId: string) => {
    const user = userList.find(user => user.id === userId)
    if (user && 'leaveDays' in user) {
      setSelectedUser(user as IGetUserForLeaveReueqstUserData)
      resetLeaveRequestForm()
    } else {
      const response = await getUserForLeaveRequestQuery({ variables: { id: userId }})
      if (!response.data || response.error) throw response.error
      setSelectedUser(response.data.getUser as IGetUserForLeaveReueqstUserData)
      resetLeaveRequestForm()
    }
  }

  const handleSubmit = async (data: ILeaveFormSaveData) => {
    setCreateLoader(true)
    setCorrelationId(null)
    let response
    try {
      response = await Api.post('/core/leave-request-validate', {
        eventType: 'LEAVE_REQUEST_ADDED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
      })
      response = await Api.post('/core/event', {
        eventType: 'LEAVE_REQUEST_ADDED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'addLeave.inProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setCorrelationId(response.correlationId)
      setCreateLoader(false)
    } catch (error) {
      setCorrelationId(null)
      logger.debug('error handle sumbit', error)
      setCreateLoader(false)

      if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: error.response.data.message || error.response?.data?.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description,
          duration: 0,
        })
      }
    }
  }

  return (
    <div className="main-content">
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="app.addLeave"/>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id="app.addLeave" />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {!isLoading && showSummary ? <LeaveRequestSummary
          isLeaveAddition
          userId={userId}
          numberOfWorkingHoursPerDay={getUserWorkingHoursPerDay(overlappingUsersInfo?.userWorkingHours)}
          leaveRequestInfo={{
            requestedDays: addedLeave?.workingDays as number,
            requestedAmountCurrentYear: createQuotaFromDays(Number(overlappingUsersInfo?.year1Days), overlappingUsersInfo?.userWorkingHours),
            requestedAmountNextYear: createQuotaFromDays(Number(overlappingUsersInfo?.year2Days), overlappingUsersInfo?.userWorkingHours),
            remainingAmountCurrentYear: deductFromQuota(
              userQuotasForCurrentYear?.quotas.remaining as IQuotaAmount,
              Number(overlappingUsersInfo?.year1Days) * getUserWorkingHoursPerDay(overlappingUsersInfo?.userWorkingHours),
              overlappingUsersInfo?.userWorkingHours
            ),
            remainingAmountNextYear: deductFromQuota(
              userQuotasForNextYear?.quotas.remaining as IQuotaAmount,
              Number(overlappingUsersInfo?.year2Days) * getUserWorkingHoursPerDay(overlappingUsersInfo?.userWorkingHours),
              overlappingUsersInfo?.userWorkingHours
            ),
            leaveTypeName: selectedLeaveType?.leaveType?.name as string,
            hasUnlimitedDays: Boolean(userQuotasForCurrentYear?.hasUnlimitedDays),
            hasUnlimitedDaysNextYear: Boolean(userQuotasForNextYear?.hasUnlimitedDays),
            leavePeriod: getLeavePeriod({
              isPartDay: selectedLeaveType?.id.includes('|part-day'),
              startDate: addedLeave?.startDate,
              endDate: addedLeave?.endDate,
              partDayStartHour: addedLeave?.partDayStartHour,
              partDayEndHour: addedLeave?.partDayEndHour,
              partDay: addedLeave?.partDay,
              formatMessage,
              locale: selectedUser?.locale,
              leaveRequestWorkingDays: overlappingUsersInfo?.calendarDays,
              hourFormat: authUser?.hourFormat || HourFormatEnum.twentyFour,
            }),
          }}
          approvers={selectedUser?.team.approvers as IApprover[]}
          resetLeaveRequestForm={resetLeaveRequestForm}
          futureAccruals={{
            allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
            endDate: endDate as unknown as string,
            daysOnLeaveEndDates: overlappingUsersInfo?.daysOnLeaveEndDates,
          }}
          locale={authUser?.locale as LocaleEnum}
        /> : null}
        {isLoading ? <CircularProgress /> : showSummary ? null
          : <LeaveForm
            authUserId={authUser.id}
            authUserRole={authUser.role}
            hourFormat={authUser.hourFormat}
            loading={createLoader}
            listOfUsers={userList}
            onSave={(data: ILeaveFormSaveData) => {
              (async () => {
                await handleSubmit(data)
              })()
            }}
            onLeaveTypeSelected={handleOnLeaveTypeSelected}
            onEndDateSelected={handleOnEndDateSelected}
            onStartDateSelected={handleOnStartDateSelected}
            onHandleTimeSelected={handleOnTimeSelected}
            onCancel={() => { history.goBack(); history.goBack() }}
            formType={'add'}
            isMinutePickerEnabled={isMinutePickerEnabled}
            onSelectUserToAddLeaveRequest={handleOnSelectUser}
            selectedUser={selectedUser}
            shouldShowSubstituteApprovers={shouldShowSubstituteApprovers}
            substituteApprovers={suggestedSubstituteApprovers}
            isSubstituteListLoading={isSubstituteListLoading}
            setShowOverlappingInfoAndQuotas={setShowOverlappingInfoAndQuotas}
          />
        }
        {!showSummary && showOverlappingInfoAndQuotas &&
          <LeaveRequestOverlappingInfo
            isLoadingOverlappingUsersInfo={isLoadingOverlappingUsersInfo}
            overlappingUsersInfo={overlappingUsersInfo}
          />
        }
        {!showSummary && showOverlappingInfoAndQuotas &&
          <LeaveRequestQuota
            userQuotasForCurrentYear={userQuotasForCurrentYear}
            userQuotasForNextYear={userQuotasForNextYear}
            leavePolicy={selectedUser?.location.leavePolicies?.find(policy => policy.leaveType.id === selectedLeaveType?.id) as IUserLocationLeavePolicies}
            currentQuota={currentQuota}
            nextYearQuota={nextYearQuota}
            user={selectedUser}
            overlappingUsersInfo={overlappingUsersInfo}
            partDayLeaveTotalHours={Number(endTime) - Number(startTime)}
            authUserLocale={authUser?.locale}
          />
        }

        {!isLoadingOverlappingUsersInfo && showOverlappingInfoAndQuotas && !showSummary && selectedLeaveType?.allowAdvanceAccrualUsage && shouldShowFutureAccruedDaysInfo({
          accrualType: selectedLeaveType?.accrualType as AccrualType,
          allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
          currentYearDays: overlappingUsersInfo?.daysOnLeaveEndDates,
          leaveRequestEndDate: endDate,
          currentDays: userQuotasForCurrentYear?.currentDays,
        }) && <Alert
          message={formatMessage(
            { id: 'email.notification.accruedDaysInfoForApprover' },
            { amount: displayLeaveInDaysAndHours({
              value: userQuotasForCurrentYear?.currentDays as number,
              formatMessage,
              numberOfWorkingHoursPerDay: getUserWorkingHoursPerDay(selectedUser?.workHours),
            }),
            name: selectedUser?.name,
            accrued:  displayLeaveInDaysAndHours({
              value: overlappingUsersInfo?.daysOnLeaveEndDates as number,
              formatMessage,
              numberOfWorkingHoursPerDay: getUserWorkingHoursPerDay(selectedUser?.workHours),
            }),
            earningDate: formatDateToLocale(endDate as unknown as string, 'dddd, MMM D, YYYY', authUser?.locale),
            })}
          type='info' />}
      </div>
    </div>
  )
}

export default AddLeavePage

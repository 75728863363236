import * as Sentry from '@sentry/react'
import { CrispSegmentsEnum } from '../types/crisp'

type CrispSessionData = {
  [key: string]: string | number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

export const setCrispSessionInfo = (segments: CrispSegmentsEnum[], sessionData: CrispSessionData) => {
  try {
    if (!window.$crisp) {
      return
    }

    window.$crisp.push(['set', 'session:segments', [segments, true]])
    window.$crisp.push(['set', 'session:data', Object.entries(sessionData)])
  } catch (err) {
    // Swallow errors
    Sentry.captureException(err)
  }
}

export const openChat = (externalLink = false): void => {
  if (window && window.$crisp) {
    window.$crisp.push(['do', 'chat:open'])
    return
  }
  if (externalLink) {
    window.open('https://vacationtracker.crisp.help/en/', '_blank')?.focus()
  }
}

export const hideChat = (): void => {
  window.$crisp && window.$crisp.push(['do', 'chat:hide'])
}

export const sendChatMessage = (message: string): void => {
  window.$crisp && window.$crisp.push(['do', 'message:send', ['text', message]])
}
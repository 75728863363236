import React from 'react'
import { Modal, Button, Divider } from 'antd'
import { useIntl } from 'react-intl'
import { GoogleOutlined, SlackOutlined, WindowsFilled } from '@ant-design/icons'
import IntlMessages from '../../util/IntlMessages'
import capitalizeString from '@vacationtracker/shared/functions/capitalize-string'
import { openChat } from '../../util/set-crisp-session-info'

interface IOtherPlatformLoginModal {
  closeOtherPlatformLoginModal: () => void
  connectWithPlatform: (platform: string) => void
  otherPlatformLogin: boolean
  existingOrganizationName: string
  existsOnAnotherPlatform: string
  selectedPlatform: string
}

const OtherPlatformLoginModal= ({
  closeOtherPlatformLoginModal,
  connectWithPlatform,
  otherPlatformLogin,
  existingOrganizationName,
  existsOnAnotherPlatform,
}: IOtherPlatformLoginModal): React.ReactElement => {
  const { formatMessage } = useIntl()

  const openCrisp = (e) => {
    e.preventDefault()
    openChat()
  }

  return (
    <Modal
      title={formatMessage({ id: 'connect.doYouWantToLoginInToOrganization' }, { existingOrganizationName} )}
      open={otherPlatformLogin}
      onCancel={closeOtherPlatformLoginModal}
      className="other-platform-exist-modal"
      footer="" //Need to remove default buttons
    >
      <p><IntlMessages id="connect.youAreAlreadyMember" values={{ existingOrganizationName }} /></p>
      <p><IntlMessages id="connect.thisOrganizationUsePlatform" values={{ existingOrganizationName, existsOnAnotherPlatform: capitalizeString(existsOnAnotherPlatform) }} /></p>
      <p>
        {existsOnAnotherPlatform === 'slack' &&
          <Button icon={<SlackOutlined />} type="primary" block onClick={() => connectWithPlatform(existsOnAnotherPlatform)}>
            <IntlMessages id="connect.signInWithSlackToOrganization" values={{existingOrganizationName}} />
          </Button>
        }
        {existsOnAnotherPlatform === 'microsoft' &&
          <Button icon={<WindowsFilled />} type="primary" block onClick={() => connectWithPlatform(existsOnAnotherPlatform)}>
            <IntlMessages id="connect.signInWithMSToOrganization" values={{existingOrganizationName}} />
          </Button>
        }
        {existsOnAnotherPlatform === 'google' &&
          <Button icon={<GoogleOutlined />} type="primary" block onClick={() => connectWithPlatform(existsOnAnotherPlatform)}>
            <IntlMessages id="connect.signInWithGoogleToOrganization" values={{existingOrganizationName}} />
          </Button>
        }
      </p>
      <Divider plain><IntlMessages id="app.or"/></Divider>
      <Button key="cancel" type="link" block onClick={openCrisp}>
        <IntlMessages id="app.contactSupport" />
      </Button>
    </Modal>
  )
}

export default OtherPlatformLoginModal

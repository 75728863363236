import { isNumber } from 'lodash'
import dayjs from 'dayjs'

import { getSafeLocale } from '../i18n'
import getDateInUserTimezone from './get-date-in-user-timezone'
import { convertHourFormats } from './convert-between-hour-formats'

import { HourFormatEnum } from '../types/user'
import { formatTimeString } from './get-days-or-hours'
import { IPartDay } from '../types/leave-request'

export const getLeavePeriod = ({
  isPartDay,
  startDate,
  endDate,
  partDayStartHour,
  partDayEndHour,
  partDay,
  formatMessage,
  leaveRequestWorkingDays,
  locale,
  hourFormat,
}) => {
  const safeLocale = getSafeLocale(locale)
  const beginningDate = dayjs(getDateInUserTimezone(startDate)).locale(safeLocale).format('MMMM Do YYYY')
  let convertedStartHour
  let convertedEndHour
  if (isPartDay) {
    if (!isValidPartDayLeavePeriod(partDay, partDayStartHour, partDayEndHour)) {
      return ''
    }
    const { value: startHourValue, minute: startMinute, amOrPm: startAmOrPm } = convertHourFormats(
      hourFormat as HourFormatEnum,
      partDay ? partDay?.startHour as number : partDayStartHour as number,
      partDay ? partDay?.startMinute as number : 0
    )
    const { value: endHourValue, minute: endMinute, amOrPm: endAmOrPm } = convertHourFormats(
      hourFormat as HourFormatEnum,
      partDay ? partDay?.endHour as number : partDayEndHour as number,
      partDay ? partDay?.endMinute as number : 0
    )
    convertedStartHour = formatTimeString({hour: startHourValue, minute: startMinute, showZeroPadding: false, hourFormat, amOrPm: startAmOrPm})
    convertedEndHour = formatTimeString({hour: endHourValue, minute: endMinute, showZeroPadding: false, hourFormat, amOrPm: endAmOrPm})
  }
  return isPartDay ?
    formatMessage({id: 'components.leaveRequestAction.partDayLeavePeriod'}, {
      date: beginningDate,
      partDayStartHour: `${convertedStartHour}`,
      partDayEndHour: `${convertedEndHour}`,
    }) :
    formatMessage({id: 'components.leaveRequestAction.fullDayLeavePeriod'}, {
      date: beginningDate,
      endDate: leaveRequestWorkingDays > 1 ? ' - ' + dayjs(getDateInUserTimezone(endDate)).locale(safeLocale).format('MMMM Do YYYY') : '',
    })
}

const isValidPartDayLeavePeriod = (partDay?: IPartDay, partDayStartHour?: number, partDayEndHour?: number): boolean => {
  if (partDay) {
    return true
  } else if (isNumber(partDayStartHour) && isNumber(partDayEndHour)) {
    return true
  } else {
    return false
  }
}
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import { useRouteMatch } from 'react-router-dom'
import { CopyrightCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { logout } from '../../services/auth/logout-handler'

import Sidebar from '../Sidebar/index'
import Topbar from '../Topbar/index'
import App from '../../routes/index'

import CircularProgress from '../../components/circular-progress'
import AlertTrial from '../../components/alert-trial'
import AlertPastDue from '../../components/alert-past-due'
import IntlMessages from '../../util/IntlMessages'

import { IUserInfo } from '../../types/users'
import { hideChat } from '../../util/set-crisp-session-info'

const { Content, Footer } = Layout

interface IMainApp {
  user: IUserInfo
}

const MainApp = ({ user }: IMainApp): React.ReactElement  => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [daysToTrialEnd, setDaysToTrialEnd] = useState(0)
  const [showTrialEnd, setShowTrialEnd] = useState(false)
  const [showPastDue, setShowPastDue] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    document.body.classList.remove('flow-b-theme')    
    if (
      authCompany?.subscriptionStatus === 'trialing' &&
      authCompany?.billing?.stripeCurrentPeriodEnd &&
      !authCompany?.billing?.address &&
      !authCompany?.billing?.paymentMethodAdded &&
      authCompany?.billing?.paymentProcessor !== 'microsoft-billing'
    ) {
      const days = dayjs.unix(Number(authCompany.billing.stripeCurrentPeriodEnd)).diff(dayjs(), 'days')
      // We need + 1 because the dayjs diff function does not include the current day (less than 24 hours)
      setDaysToTrialEnd(days + 1)
      if(days >= 0) {
        setShowTrialEnd(true)
      }
    } else if (authCompany?.subscriptionStatus === 'past_due') {
      setShowPastDue(true)
    }
  }, [authCompany])

  useEffect(() => {
    if (user && user.role === 'User') {
      hideChat()
    }
  }, [user])

  const match = useRouteMatch()

  const onLogout = () => {
    logout({
      history,
      reduxDispatch: dispatch,
      userId: user.id,
    })
  }

  return (
    <Layout className="app-layout">
      <Sidebar/>
      <Layout className="content-layout">
        <Topbar onLogout={onLogout} />
        <Content>
          {(showTrialEnd && pathname !== '/app/my-profile' && !pathname.startsWith('/app/users/')) &&
            <AlertTrial daysToTrialEnd={daysToTrialEnd} />
          }
          {(showPastDue && pathname !== '/app/my-profile' && !pathname.startsWith('/app/users/')) &&
            <AlertPastDue />
          }
          {!user ?
            <CircularProgress /> :
            <App match={match} />
          }
          <Footer>
            <IntlMessages id="app.copyright" /> <CopyrightCircleOutlined /> {dayjs().format('YYYY')}
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
}
export default MainApp

